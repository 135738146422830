import { localize } from '@/utils/i18n'

export const defaultFilters = [
    {
        disabled: false,
        id: 'all',
        selected: false,
        text: localize({
            ru: 'Все',
            en: 'All'
        })
    },
    {
        disabled: false,
        id: '0-9',
        selected: false,
        text: '0 — 9'
    },
    {
        disabled: false,
        id: 'a-c',
        selected: false,
        text: 'A — C'
    },
    {
        disabled: false,
        id: 'd-f',
        selected: false,
        text: 'D — F'
    },
    {
        disabled: false,
        id: 'g-i',
        selected: false,
        text: 'G — I'
    },
    {
        disabled: false,
        id: 'j-l',
        selected: false,
        text: 'J — L'
    },
    {
        disabled: false,
        id: 'm-o',
        selected: false,
        text: 'M — O'
    },
    {
        disabled: false,
        id: 'p-r',
        selected: false,
        text: 'P — R'
    },
    {
        disabled: false,
        id: 's-u',
        selected: false,
        text: 'S — U'
    },
    {
        disabled: false,
        id: 'v-x',
        selected: false,
        text: 'V — X'
    },
    {
        disabled: false,
        id: 'y-z',
        selected: false,
        text: 'Y — Z'
    }
]

export const russianFilters = [
    {
        disabled: false,
        id: 'а-в',
        selected: false,
        text: 'A — B'
    },
    {
        disabled: false,
        id: 'г-е',
        selected: false,
        text: 'Г — Е'
    },
    {
        disabled: false,
        id: 'ж-и',
        selected: false,
        text: 'Ж — И'
    },
    {
        disabled: false,
        id: 'к-м',
        selected: false,
        text: 'К — М'
    },
    {
        disabled: false,
        id: 'н-п',
        selected: false,
        text: 'Н — П'
    },
    {
        disabled: false,
        id: 'р-у',
        selected: false,
        text: 'Р — У'
    },
    {
        disabled: false,
        id: 'ф-ч',
        selected: false,
        text: 'Ф — Ч'
    },
    {
        disabled: false,
        id: 'ш-я',
        selected: false,
        text: 'Ш — Я'
    }
]
