/* eslint-disable */
require('./book')
require('./default')
require('./doc')
require('./docx')
require('./empty')
require('./jpg')
require('./mp3')
require('./mp4')
require('./online')
require('./pdf')
require('./png')
require('./ppt')
require('./pptx')
require('./rar')
require('./rtf')
require('./text-doc')
require('./text-docx')
require('./text-jpg')
require('./text-mp3')
require('./text-mp4')
require('./text-pdf')
require('./text-png')
require('./text-ppt')
require('./text-rar')
require('./text-rtf')
require('./text-tif')
require('./text-txt')
require('./text-xls')
require('./text-xlsx')
require('./text-zip')
require('./tif')
require('./txt')
require('./xbrl')
require('./xls')
require('./xlsx')
require('./zip')
