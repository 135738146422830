<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div
            class="cookie-popup col-default-10 col-mobile-12"
            v-if="visible"
        >
            <div class="container cookie-popup__body">

                <div class="u-bottom-margin--m">

                    <div class="cookie-popup__title-container">
                        <h4
                            class="cookie-popup__title u-bottom-margin--xs"
                            v-html="text.title"
                        />
                        <button
                            class="cookie-popup__close"
                            @click="setVisibleFalse"
                        >
                            <z-icon
                                name="close"
                                width="32"
                                height="32"
                            />
                        </button>
                    </div>

                    <vue-raw
                        class="cookie-popup__text"
                        :raw="text.description"
                    />
                </div>

                <transition>
                    <div
                        v-if="visibleSettings"
                    >
                        <div class="cookie-popup__settings row">
                            <div class="cookie-popup__settings-container col-default-3 col-tablet-5 col-mobile-12">
                                <div class="cookie-popup__settings-title">
                                    <z-button
                                        kind="text"
                                        @click.stop="showInfo('policy')"
                                    >
                                        {{ text.cookies.cookies_policy.title }}
                                    </z-button>

                                    <label class="cookie-popup__switch">
                                        <input type="checkbox" disabled checked>
                                        <span class="cookie-popup__switch--slider cookie-popup__switch--locked"></span>
                                    </label>
                                </div>

                                <p
                                    class="cookie-popup__settings-text"
                                    :class="{'cookie-popup__settings-text--show': showText.policy }"
                                    v-html="text.cookies.cookies_policy.description"
                                />
                            </div>

                            <div class="cookie-popup__settings-container col-default-3 col-tablet-5 col-mobile-12 col-mobile-offset-0">
                                <div class="cookie-popup__settings-title">
                                    <z-button
                                        kind="text"
                                        @click.stop="showInfo('performance')"
                                    >
                                        {{ text.cookies.performance.title }}
                                    </z-button>

                                    <label class="cookie-popup__switch">
                                        <input @click.stop type="checkbox" value="performance" v-model="checkedCookies">
                                        <span class="cookie-popup__switch--slider"></span>
                                    </label>
                                </div>

                                <p
                                    class="cookie-popup__settings-text"
                                    :class="{'cookie-popup__settings-text--show': showText.performance }"
                                    v-html="text.cookies.performance.description"
                                />
                            </div>

                            <div class="cookie-popup__settings-container col-default-3 col-tablet-5 col-mobile-12">
                                <div class="cookie-popup__settings-title">
                                    <z-button
                                        kind="text"
                                        @click.stop="showInfo('functional')"
                                    >
                                        {{ text.cookies.functional.title }}
                                    </z-button>

                                    <label class="cookie-popup__switch">
                                        <input @click.stop type="checkbox" value="functional" v-model="checkedCookies">
                                        <span class="cookie-popup__switch--slider"></span>
                                    </label>
                                </div>

                                <p
                                    class="cookie-popup__settings-text"
                                    :class="{'cookie-popup__settings-text--show': showText.functional }"
                                    v-html="text.cookies.functional.description"
                                />
                            </div>

                            <div class="cookie-popup__settings-container col-default-3 col-tablet-5 col-mobile-12 col-mobile-offset-0">
                                <div class="cookie-popup__settings-title">
                                    <z-button
                                        kind="text"
                                        @click.stop="showInfo('marketing')"
                                    >
                                        {{ text.cookies.marketing.title }}
                                    </z-button>

                                    <label class="cookie-popup__switch">
                                        <input @click.stop type="checkbox" value="marketing" v-model="checkedCookies">
                                        <span class="cookie-popup__switch--slider"></span>
                                    </label>
                                </div>

                                <p
                                    class="cookie-popup__settings-text"
                                    :class="{'cookie-popup__settings-text--show': showText.marketing }"
                                    v-html="text.cookies.marketing.description"
                                />
                            </div>
                        </div>
                    </div>
                </transition>

                <div class="cookie-popup__buttons u-top-margin--s">
                    <template v-if="!visibleSettings">
                        <z-button
                            class="cookie-popup__button"
                            size="m"
                            @click="submit"
                            kind="primary"
                            key="confirm"
                        >
                            {{ text.button.confirm }}
                            <template v-slot:right>
                                <z-icon
                                    name="arrow-long"
                                    dir="right"
                                    width="18"
                                    height="18"
                                />
                            </template>
                        </z-button>

                        <z-button
                            class="cookie-popup__button cookie-popup__button--second"
                            size="m"
                            @click="showSettings"
                            kind="secondary"
                            key="settings"
                        >
                            {{ text.button.settings }}
                        </z-button>
                    </template>

                    <template v-else>
                        <z-button
                            class="cookie-popup__button"
                            size="m"
                            @click="submit"
                            kind="primary"
                            key="confirmAll"
                        >
                            {{ text.button.confirm}}
                        </z-button>

                        <z-button
                            class="cookie-popup__button cookie-popup__button--second"
                            size="m"
                            @click="submitSettings"
                            kind="secondary"
                            key="confirmSettings"
                        >
                            {{ text.button.settings}}
                        </z-button>
                    </template>
                </div>
            </div>
        </div>

    </transition>
</template>

<script>
import Velocity from 'velocity-animate'
import { localize } from '@/utils/i18n'
import { deviceEnquire } from '@/utils/device'
const cookie = require('cookie_js')

export default {
    name: 'cookie-popup',
    data () {
        return {
            duration: 250,
            visible: false,
            visibleSettings: false,
            checkedCookies: ['cookie_policy'],
            device: 'desktop',
            showText: {
                policy: false,
                performance: false,
                functional: false,
                marketing: false
            },
            text: {
                title: localize({
                    'ru': 'Использование файлов cookie',
                    'en': 'Cookie policy'
                }),
                description: localize({
                    'ru': 'Мы&nbsp;используем Cookies для того, чтобы сделать ваше пребывание на&nbsp;нашем сайте максимально комфортным. Если вы&nbsp;продолжаете просмотр страниц без изменения настроек, это будет означать, что вы&nbsp;принимаете все Cookies сайта &laquo;Норникель&raquo;. Вы&nbsp;также можете нажать кнопку &laquo;Я&nbsp;принимаю&raquo;, чтобы скрыть это сообщение. Конечно, вы&nbsp;можете изменить ваши настройки Cookies в&nbsp;любое время, настроив браузер соответствующим образом. Детали о&nbsp;Cookies доступны по&nbsp;ссылке <z-link href="/cookies-policy/">подробнее</z-link>',
                    'en': 'We&nbsp;use Cookies to&nbsp;make your visit to&nbsp;our site as&nbsp;convenient a&nbsp;possible. If&nbsp;you continue to&nbsp;view the pages without changing your settings, it&nbsp;will be&nbsp;assumed that you accept all Cookies on&nbsp;the site “Nornickel”. You can also click “I&nbsp;agree” to&nbsp;hide this message. You can, of&nbsp;course, change your Cookie settings at&nbsp;any time by&nbsp;adjusting your browser. You can view details of&nbsp;Cookies by&nbsp;clicking read <z-link href="/cookies-policy/">more</z-link> '
                }),
                cookies: {
                    cookies_policy: {
                        title: localize({
                            'ru': 'Обязательные',
                            'en': 'Strictly necessary cookies'
                        }),
                        description: localize({
                            'ru': 'Эти файлы необходимы для правильной работы сайта и&nbsp;функционала.',
                            'en': 'These cookies are essential for the website and its features to&nbsp;work properly.'
                        })
                    },
                    performance: {
                        title: localize({
                            'ru': 'Эксплуатационные',
                            'en': 'Performance cookies'
                        }),
                        description: localize({
                            'ru': 'Эти файлы собирают информацию о&nbsp;том, как вы&nbsp;используете сайт, и&nbsp;помогают нам улучшать его в&nbsp;соответствии с&nbsp;вашими потребностями.',
                            'en': 'These cookies collect information about how you use the website and help&nbsp;us to&nbsp;adapt the content according to&nbsp;your needs, improving its usability.'
                        })
                    },
                    functional: {
                        title: localize({
                            'ru': 'Функциональные',
                            'en': 'Functionality cookies'
                        }),
                        description: localize({
                            'ru': 'Эти файлы позволяют повысить удобство использования сайта, сохраняя информацию о&nbsp;настройках пользователя.',
                            'en': 'These cookies enable&nbsp;us to&nbsp;improve the comfort and usability of&nbsp;the website and to&nbsp;provide various features.'
                        })
                    },
                    marketing: {
                        title: localize({
                            'ru': 'Маркетинговые',
                            'en': 'Marketing cookies'
                        }),
                        description: localize({
                            'ru': 'Эти файлы используются для показа рекламной информации, которая может быть вам интересна, в&nbsp;частности, на&nbsp;основе посещенных вами сайтов.',
                            'en': 'These cookies are used to&nbsp;send advertising and promotional information that is&nbsp;relevant to&nbsp;you, e.g. based on&nbsp;the web pages you visited.'
                        })
                    }
                },
                button: {
                    confirm: localize({
                        'ru': 'Я принимаю',
                        'en': 'I agree'
                    }),
                    settings: localize({
                        'ru': 'Изменить настройки',
                        'en': 'Сhange settings'
                    })
                }
            }
        }
    },
    mounted () {
        console.log('cookie')
        this.visible = !cookie.get('cookies_policy')
        deviceEnquire((device) => {
            this.device = device
        })

        if (cookie.get('marketing')) {
            // судя переносится ГА и метрика, когда есть понимание придерживаемся ли мы gdpr и прочих политик или нет
        }

        this.$root.$bus.$on('open-cookies', this.checkCookies)
    },
    beforeDestroy () {
        this.$root.$bus.$off('open-cookies', this.checkCookies)
    },
    methods: {
        showInfo (text) {
            if (this.device === 'v-tablet' || this.device === 'mobile') {
                this.showText[text] = !this.showText[text]
            } else {
                return ''
            }
        },
        checkCookies () {
            this.checkedCookies = ['cookie_policy']
            let cookies = cookie.get(['performance', 'functional', 'marketing'])
            Object.keys(cookies).forEach(key => {
                if (cookies[key] === 'true' && !this.checkedCookies.indexOf(key) >= 0) {
                    this.checkedCookies.push(key)
                }
            })
            this.visible = true
            this.visibleSettings = true
        },
        submit () {
            cookie.set({
                cookies_policy: true,
                performance: true,
                functional: true,
                marketing: true
            }, {
                domain: location.hostname === 'localhost' ? '' : '.' + location.host,
                path: '/',
                expires: 365
            })

            // eslint-disable-next-line no-return-assign
            this.$nextTick(this.setVisibleFalse)
        },
        setVisibleFalse () {
            this.visible = false
        },
        showSettings () {
            this.visibleSettings = true
        },
        submitSettings () {
            cookie.set({
                cookies_policy: true,
                performance: this.checkedCookies.indexOf('performance') >= 0,
                functional: this.checkedCookies.indexOf('functional') >= 0,
                marketing: this.checkedCookies.indexOf('marketing') >= 0
            }, {
                domain: '.' + location.host,
                path: '/',
                expires: 365
            })

            if (cookie.get('marketing')) {
                // судя переносится ГА и метрика, когда есть понимание придерживаемся ли мы gdpr и прочих политик или нет
            }

            // eslint-disable-next-line no-return-assign
            this.$nextTick(this.setVisibleFalse)
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        appearAnimationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: this.duration, delay: this.duration }, { complete: done })
        },
        appearAnimationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: this.duration })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
