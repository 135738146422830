<template>
    <a
        :href="data.link" class="clean-page-nav"
        v-if="data && data.link && data.text"
        :style="`background-image: url(${data.image})`"
    >
        <div class="clean-page-nav__subtitle">дальше</div>
        <div class="clean-page-nav__title" v-html="data.text"></div>
        <div class="clean-page-nav__arrow">
            <img src="/images/clean-norilsk/arrow.svg" alt=".">
        </div>
    </a>
</template>

<script>
export default {
    name: 'page-nav',
    data () {
        return {
            data: this.$root.app.components['page-nav']?.next
        }
    }
}
</script>

<style lang="scss">
.clean-page-nav {
    width: 500px;
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 42px;
    flex-shrink: 0;
    text-decoration: none;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(358.88deg, rgba(0, 119, 200, 0.8) 0.99%, rgba(95, 168, 209, 0) 74.94%);
        bottom: 0;
        position: absolute;
        z-index: 1;
    }

    &:after {
        content: '';
        opacity: 0;
        background: rgba(0, 119, 200, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        transition: opacity $transition;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    @include breakpoint(tablet) {
        width: 300px;
        height: 300px;
        padding-bottom: $token-spacers-s;
    }

    @include breakpoint(v-tablet) {
        margin-bottom: $token-spacers-l;
    }

    @include breakpoint(mobile) {
        margin-bottom: 40px;
    }

    &__subtitle,
    &__title,
    &__arrow {
        z-index: 2;
    }

    &__subtitle {
        font-weight: 600;
        font-size: 30px;
        line-height: 120%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;

        @include breakpoint(tablet) {
            font-size: 28px;
        }
    }

    &__title {
        font-weight: 300;
        font-size: 56px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            font-size: 36px;
            margin-bottom: 8px;
        }

        @include breakpoint(v-tablet) {
            font-size: 30px;
        }
    }
}
</style>
