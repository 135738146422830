<template>
<media-album :tags="tags">
    <div class="row u-justify-content--btween _compensate-row-gap--xl">
        <div
            v-for="(data, idx) in componentData" :key='idx'
            class="col-default-6 col-v-tablet-12 u-align-items--start _row-gap--xl"
        >
            <presentation-block
                :data="{
                    image: data.previewPicture ? data.previewPicture.SRC : '',
                    name: data.name,
                    size: getSizeLine(data),
                    link: data.mlDocs.link,
                    externalLink: {
                        title: externalLink,
                        link: data.mlExternalLink.value
                    }
                }"
                pdfIconName='file/text-pdf'
            >

            </presentation-block>
        </div>
    </div>
    <template v-slot:text>
        <slot/>
    </template>
</media-album>

</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'media-album-presentations',
    props: {
        componentData: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            externalLink: localize({
                ru: 'Посмотреть источник',
                en: 'View source'
            })
        }
    },
    methods: {
        formatDate (timestamp) {
            if (!timestamp) return null
            const date = new Date(timestamp * 1000)

            const year = date.getFullYear()
            const month = this.months[date.getMonth()]
            const day = date.getDate()

            const formattedTime = `${day} ${month} ${year} г.`

            return formattedTime
        },
        getSizeLine (data) {
            return `
                <div class="display: flex; align-items: center;">
                    <span>${data.mlDocs.type} (${data.mlDocs.size})</span>
                    <span style="border-radius: 100%; width: 4px; height: 4px; background-color: #858DA6; display: inline-block; margin: 0 3px 2px;"></span>
                    <span>${this.formatDate(data.activeFrom)}</span>
                </div>
                `
        }
    },
    computed: {
        months () {
            return this.$root.lang === 'ru'
                ? ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']
                : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        tags () {
            const uniqueTags = new Set()
            this.componentData.forEach(tags => {
                if (tags.mlSubject.value && Array.isArray(tags.mlSubject.value) && tags.mlSubject.value.length) {
                    tags.mlSubject.value.forEach(tag => {
                        uniqueTags.add(tag)
                    })
                }
            })
            return Array.from(uniqueTags)
        }
    }
}
</script>
