import { localize } from '@/utils/i18n'

export const category = [
    {
        'id': 'sup-cat-0',
        'selected': false,
        'disabled': false,
        'text': 'Общие вопросы по организации закупочной деятельности'
    },
    {
        'id': 'sup-cat-1',
        'selected': false,
        'disabled': false,
        'text': 'Вопросы по отдельной категории закупок'
    },
    {
        'id': 'sup-cat-2',
        'selected': false,
        'disabled': false,
        'text': 'Вопросы по отдельной закупочной процедуре'
    },
    {
        'id': 'sup-cat-3',
        'selected': false,
        'disabled': false,
        'text': 'Вопросы по работе в Системе управления закупками SRM Норникель'
    }
]

export const text = {
    title: localize({
        ru: 'Отправьте ваш вопрос',
        en: 'Send your question'
    }),
    category: localize({
        ru: 'Категория вопроса*',
        en: 'Категория вопроса*'
    }),
    purchaseCategory: localize({
        ru: 'Интересующая категория закупок',
        en: 'Интересующая категория закупок'
    }),
    procurementProcedure: localize({
        ru: 'Интересующая закупочная процедура*',
        en: 'Интересующая закупочная процедура*'
    }),
    fio: localize({
        ru: 'Фамилия Имя Отчество',
        en: 'Full name'
    }),
    company: localize({
        ru: 'Компания*',
        en: 'Company*'
    }),
    phone: localize({
        ru: 'Телефон',
        en: 'Phone'
    }),
    phoneAdd: localize({
        ru: 'доб.',
        en: 'ext.'
    }),
    email: localize({
        ru: 'E-mail',
        en: 'E-mail'
    }),
    message: localize({
        ru: 'Сообщение*',
        en: 'Message*'
    }),
    button: localize({
        ru: 'Отправить',
        en: 'Send'
    }),
    notificationRequiredTextError: localize({
        ru: 'Заполните все обязательные поля',
        en: 'Fill in all required fields'
    }),
    notificationError: localize({
        ru: 'Ошибка отправки',
        en: 'Send error'
    }),
    notificationValidateError: localize({
        ru: 'Неправильный формат полей',
        en: 'Wrong field format'
    }),
    notificationSuccess: localize({
        ru: 'Отправка успешна',
        en: 'Sending successful'
    }),
    notificationCaptchaError: localize({
        ru: 'Неверный код капчи. Попробуйте снова.',
        en: 'Invalid captcha code. Try again.'
    }),
    notificationCaptchaEmpty: localize({
        ru: 'Введите код с картинки*',
        en: 'Enter the code from the image*'
    }),
    policy: localize({
        ru: 'В соответствии с Федеральным законом «О персональных данных» от 27.07.2006 N 152-ФЗ свободно, своей волей и в своем интересе я даю свое согласие ПАО «ГМК Норильский никель» на обработку моих персональных данных, указанных при заполнении формы обратной связи на сайте www.nornickel.ru',
        en: 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com, by PJSC “MMC “Norilsk Nickel”'
    })
}
