/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/ppt': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM14.088 31h1.404v-2.988h2.112c1.668 0 2.616-1.152 2.616-2.508s-.936-2.508-2.616-2.508h-3.516V31zm4.692-5.496c0 .768-.576 1.272-1.368 1.272h-1.92v-2.544h1.92c.792 0 1.368.504 1.368 1.272zM21.39 31h1.403v-2.988h2.112c1.668 0 2.616-1.152 2.616-2.508s-.936-2.508-2.616-2.508H21.39V31zm4.691-5.496c0 .768-.576 1.272-1.368 1.272h-1.92v-2.544h1.92c.792 0 1.368.504 1.368 1.272zM30.598 31h1.404v-6.768h2.424v-1.236h-6.252v1.236h2.424V31z" _fill="#737B91"/>'
  }
})
