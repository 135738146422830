/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Rectangle 9610': {
    width: 16,
    height: 16,
    viewBox: '0 0 200 200',
    data: ''
  }
})
