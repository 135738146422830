<template>
    <div class="page-nav" v-if="dataLength">
        <a
            :href="data.prev.link"
            class="page-nav__item page-nav__item--prev"
            v-if="data.prev"
        >
            <span class="page-nav__icon">
                <z-icon
                    name="arrow-long"
                    width="16"
                    height="16"
                    dir="left"
                />
            </span>
            <span class="page-nav__text" v-html="data.prev.text"></span>
        </a>
        <span class="page-nav__separator"></span>
        <a
            :href="data.next.link"
            class="page-nav__item page-nav__item--next"
            v-if="data.next"
        >
            <span class="page-nav__text" v-html="data.next.text"></span>
            <span class="page-nav__icon">
                <z-icon
                    name="arrow-long"
                    width="16"
                    height="16"
                    dir="right"
                />
            </span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'page-nav',
    data () {
        return {
            data: this.$root.app.components['page-nav']
        }
    },
    computed: {
        dataLength () {
            if (
                this.data.next.link.length > 0 ||
                this.data.next.text.length > 0 ||
                this.data.prev.link.length > 0 ||
                this.data.prev.text.length > 0
            ) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style lang="scss">
.page-nav {
    $parent: &;

    display: flex;
    align-items: center;

    @include breakpoint (mobile) {
        justify-content: space-between;
        width: 100%;
    }

    &__separator {
        width: 12px;
        height: 8px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url(/images/logo/logo-sm.svg);
        display: block;
        margin: 0 $token-spacers-2-xs -3px;

        @include breakpoint (mobile) {
            width: 24px;
            height: 16px;
        }
    }

    &__icon {
        display: flex;
        margin-top: 3px;

        .z-icon {
            @include breakpoint (mobile) {
                width: 32px !important;
                height: 32px !important;
            }
        }
    }

    &__text {
        @include breakpoint (mobile) {
            display: none;
        }
    }

    &__item {
        text-decoration: none;
        color: $token-colors-gray-60;
        transition: color $transition;
        display: flex;
        align-items: center;

        &:hover {
            color: $token-colors-navy-blue;
        }

        &--next {
            #{$parent}__text {
                margin-right: $token-spacers-3-xs;
            }
        }

        &--prev {
            #{$parent}__text {
                margin-left: $token-spacers-3-xs;
            }
        }
    }
}
</style>
