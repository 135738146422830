/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pin': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.889 19.044c0 4.835-2.592 8.727-5.597 11.613-2.886 2.773-6.04 4.503-7.248 5.112-1.207-.609-4.361-2.34-7.247-5.112C10.792 27.771 8.2 23.88 8.2 19.044 8.2 11.951 13.95 6.2 21.044 6.2c7.094 0 12.845 5.75 12.845 12.844zm1.2 0c0 10.585-11.36 16.736-13.646 17.867a.884.884 0 01-.797 0C18.36 35.781 7 29.63 7 19.044 7 11.288 13.288 5 21.044 5c7.757 0 14.045 6.288 14.045 14.044zm-9.226 0a4.819 4.819 0 11-9.638 0 4.819 4.819 0 019.638 0zm1.2 0a6.02 6.02 0 11-12.038 0 6.02 6.02 0 0112.038 0z" _fill="#0077C8"/>'
  }
})
