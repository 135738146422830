<template>
    <div class="significant-facts-detail" v-if="data">
        <z-caption
            tag="h2"
            size="3xl"
        >
            <span v-html="data.name" />
        </z-caption>

        <div class="row">
            <div class="col-default-9 col-tablet-12">
                <article class="html-content" v-html="data.detailText" />
            </div>
        </div>

        <z-link
            :href="data.backLink"
            size="l"
            class="u-top-margin--xl-important"
        >
            <template v-slot:left>
                <z-icon
                    name="round-arrow"
                    dir="left"
                    width="1.6em"
                    height="1.6em"
                />
            </template>
            <span v-html="text.backLink" />
        </z-link>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'significant-facts-detail',
    data () {
        return {
            text: {
                backLink: localize({
                    'ru': 'Вернуться к списку',
                    'en': 'Back to list'
                })
            },
            data: this.$root.app.components['significant-fact-detail']
        }
    }
}
</script>

<style lang="scss">
    .significant-facts-detail .html-content > br {
        display: none;
    }

    .significant-facts-detail .html-content > div[style="margin: 8px 0 8px 0"] {
        display: none;
    }
</style>
