<template>
    <div class="products-menu" :class="{ 'is-opened': isOpen }" v-if="data">
        <button class="products-menu__trigger" @click="isOpen = !isOpen">
            <z-caption
                size="xs"
                :icon="isOpen ? 'round-arrow' : 'products-burger'"
                decor-right
                decor-left
                uppercase
                :theme="isOpen ? 'default' : 'white'"
            >
                <span v-html="text.triggerName"></span>
            </z-caption>
        </button>
        <slot></slot>
        <div class="products-menu__container">
            <transition name="component-fade" mode="out-in">
                <template v-if="isOpen">
                    <div>
                        <div class="products-menu__slot" v-if="$slots['parent-menu']">
                            <slot name="parent-menu" />
                        </div>
                        <parent-menu :data="data.parent" v-if="data.parent" />
                    </div>
                </template>
                <template v-else>
                    <div>
                        <div class="products-menu__slot" v-if="$slots['child-menu']">
                            <slot name="child-menu" />
                        </div>
                        <child-menu :data="data.child" v-if="data.child && !hideChildMenu" />
                        <div class="products-menu__child-footer" v-if="$slots['child-menu-footer']">
                            <slot name="child-menu-footer" />
                        </div>
                    </div>
                </template>
            </transition>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import ChildMenu from './ChildMenu.vue'
import ParentMenu from './ParentMenu.vue'

export default {
    name: 'products-menu',
    components: {
        ChildMenu,
        ParentMenu
    },
    props: {
        data: Object,
        hideChildMenu: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            text: {
                triggerName: localize({
                    ru: 'Продукция',
                    en: 'Products'
                })
            },
            isOpen: false
        }
    }
}
</script>

<style lang="scss">
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity 0.1s linear;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}

.products-menu {
    $parent: &;

    border-radius: 30px 0px 15px 15px;
    @include padding-level(bottom, 2XL);
    @include padding-level(top, M);
    background: linear-gradient(123.73deg, #0077C8 20.82%, rgba(0, 119, 200, 0) 99.26%), #004C97;
    transition: background 0.1s linear;

    padding-left: $token-grids-desktop-offset;
    padding-right: $token-grids-desktop-offset;

    @include breakpoint(laptop) {
        padding-left: $token-grids-laptop-offset;
        padding-right: $token-grids-laptop-offset;
    }

    @include breakpoint(v-tablet) {
        padding-left: $token-grids-v-tablet-offset;
        padding-right: $token-grids-v-tablet-offset;
        border-radius: 15px 0px 15px 15px;
    }

    @include breakpoint(mobile) {
        padding-left: $token-grids-mobile-offset;
        padding-right: $token-grids-mobile-offset;
    }

    &.is-opened {
        background: $token-colors-gray-10;

        #{$parent}__trigger {
            .z-icon {
                transform: rotate(-90deg);
            }
        }
    }

    &__trigger {
        background: none;
        border: none;
        width: 100%;
        @include margin-level(bottom, XS);

        .z-caption {
            margin-bottom: 0;
        }

        .z-icon {
            width: 24px !important;
            height: 24px !important;
        }
    }

    &__slot {
        @include margin-level(bottom, S);
    }

    &__child-footer {
        @include padding-level(top, M);
        @include padding-level(bottom, M);
        @include margin-level(top, L);
        @include margin-level(bottom, 2XL, true);
        background: $token-colors-navy-blue;
        border-radius: 40px 0px 15px;

        padding-left: $token-grids-desktop-offset;
        padding-right: $token-grids-desktop-offset;
        margin-left: -$token-grids-desktop-offset;
        margin-right: -$token-grids-desktop-offset;

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
            padding-right: $token-grids-laptop-offset;
            margin-left: -$token-grids-laptop-offset;
            margin-right: -$token-grids-laptop-offset;
        }

        @include breakpoint(v-tablet) {
            padding-left: $token-grids-v-tablet-offset;
            padding-right: $token-grids-v-tablet-offset;
            margin-left: -$token-grids-v-tablet-offset;
            margin-right: -$token-grids-v-tablet-offset;
            border-radius: 15px 0px 15px;
        }

        @include breakpoint(mobile) {
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
            margin-left: -$token-grids-mobile-offset;
            margin-right: -$token-grids-mobile-offset;
        }
    }

    @media only screen and (max-width: 599px) {
        .z-caption--decor-left.z-caption--decor-right .z-caption__text {
            padding-left: 16px;
        }
    }
}
</style>
