export default function handleYmGoal (name, lang) {
    if (!name || !lang) return

    if (lang === 'ru' && typeof yaCounter45303309 !== 'undefined') {
        // eslint-disable-next-line no-undef
        yaCounter45303309.reachGoal(name)
    } else if (lang === 'en' && typeof yaCounter45303330 !== 'undefined') {
        // eslint-disable-next-line no-undef
        yaCounter45303330.reachGoal(name)
    }
}
