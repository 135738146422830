/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-mp4': {
    width: 37,
    height: 22,
    viewBox: '0 0 37 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.112 16.713h1.494V4.707h-2.142L6.72 13.743 2.994 4.707H.852v12.006h1.494V6.831l4.068 9.882h.612l4.086-9.882v9.882zm4.295 0h1.494v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618s-1.35-3.618-3.762-3.618h-4.824v12.006zm7.038-8.388c0 1.35-.972 2.286-2.394 2.286h-3.15V6.039h3.15c1.422 0 2.394.936 2.394 2.286zm8.404 8.388h1.494v-3.042h1.692v-1.314h-1.692v-7.65h-2.052l-5.148 7.722v1.242h5.706v3.042zm0-10.62v6.264h-4.212l4.212-6.264z" _fill="#0077C8"/>'
  }
})
