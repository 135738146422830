<template>
    <div>
        <z-tabs v-if="data">
            <template v-slot:label v-if="data.tabs">
                <z-tabs-label v-for="tab in data.tabs" :key="tab.value" :id="tab.value">
                    {{ tab.name }}
                </z-tabs-label>
            </template>
            <template v-slot:content v-if="data.tabs">
                <z-tabs-content v-for="tab in data.tabs" :key="tab.value" :id="tab.value">
                    <z-filelist v-if="isVerifyUser">
                        <z-filelist-item
                            v-for="(item, index) in data.items[tab.value]"
                            :key="index"
                            :name="item.name"
                            :date="item.activeFrom"
                            :href="item.fileReport.link"
                            :file-size="item.fileReport.size"
                            :icon-name="`file/${item.fileReport.type}`"
                        ></z-filelist-item>
                    </z-filelist>
                    <z-filelist v-if="!isVerifyUser">
                        <z-filelist-item
                            v-for="(item, index) in data.items[tab.value]"
                            :key="index"
                            :name="item.name"
                            :date="item.activeFrom"
                            :href="'#'"
                            @linkClick.prevent="
                                openModalWithForm(item.fileReport.link, item.fileId)
                            "
                            :file-size="item.fileReport.size"
                            :icon-name="`file/${item.fileReport.type}`"
                        ></z-filelist-item>
                    </z-filelist>
                </z-tabs-content>
            </template>
        </z-tabs>

        <z-modal class="comodity-research-page__modal" id="slotModal">
            <z-caption
                class="comodity-research-page__form-title"
                tag="h3"
                size="2xl"
                icon="message"
            >
                {{ formTitle }}
            </z-caption>
            <commodity-research-form
                @openLink="openLink"
                :fileId="currentFileId"
                :fileLink="currentLink"
            ></commodity-research-form>
        </z-modal>
    </div>
</template>

<script>
import CommodityResearchForm from '@/components/CommodityResearchForm/CommodityResearchForm.vue'
import { localize } from '@/utils/i18n'

export default {
    name: 'commodity-research',
    components: { CommodityResearchForm },
    data () {
        return {
            data: this.$root.app.components['commodity-research'],
            isVerifyUser: false,
            isModalWithFormOpen: false,
            currentLink: '',
            currentFileId: '',
            formTitle: localize({
                ru: 'Для ознакомления с аналитическим отчетом просьба ответить на вопросы',
                en: 'To download our research please complete the form below'
            })
        }
    },
    created () {
        if (this.data) {
            this.data.tabs.sort((a, b) => {
                return parseInt(b.name) - parseInt(a.name)
            })
        }

        if (localStorage.getItem('isFormSubmited')) {
            this.isVerifyUser = true
        }
    },
    methods: {
        openModalWithForm(reportLink, fileId) {
            this.$root.$bus.$emit('open-modal', {id: 'slotModal'})
            this.currentLink = reportLink
            this.currentFileId = fileId
        },
        openLink () {
            window.location.href = this.currentLink
        }
    }
}
</script>

<style lang="scss">
.comodity-research-page {

}
</style>
