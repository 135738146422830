/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tooltip': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17 9A8 8 0 111 9a8 8 0 0116 0zm1 0A9 9 0 110 9a9 9 0 0118 0zM9 4.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0V5A.75.75 0 019 4.25zM8.25 13a.75.75 0 01.75-.75h.01a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75z" _fill="#0077C8"/>'
  }
})
