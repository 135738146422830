<template>
  <div class="sitemap">
    <ul class="sitemap__list">
        <li
            class="sitemap__item"
            v-for="(menuItem,index) in data"
            :key="index"
        >
            <z-link
                class="sitemap__item-title"
                v-if="menuItem.link"
                :href="menuItem.link"
            >
                {{ menuItem.name }}
            </z-link>

            <ul
                v-if="menuItem.items && menuItem.items.length"
            >
                <li
                    class="sitemap__subitem"
                    v-for="(subMenuItem,index) in menuItem.items"
                    :key="index"
                >
                    <z-link
                        class="sitemap__subitem-title"
                        :href="subMenuItem.link"
                        :external="subMenuItem.params && subMenuItem.params.BLANK ? '_blank' : false"
                        underline
                    >
                        <span class="sitemap__subitem-text" v-html="subMenuItem.name"></span>
                        <z-icon v-if="subMenuItem.params.EXTERNAL" name="external" />
                    </z-link>

                     <ul
                        v-if="subMenuItem.items && subMenuItem.items.length"
                        class="sitemap__sublist-third"
                    >
                        <li
                            class="sitemap__subitem"
                            v-for="(subMenuItemThird,index) in subMenuItem.items"
                            :key="index"
                        >
                            <z-link
                                class="sitemap__subitem-title"
                                :href="subMenuItemThird.link"
                                :external="subMenuItemThird.params && subMenuItemThird.params.BLANK ? '_blank' : false"
                                underline
                            >
                                <span class="sitemap__subitem-text" v-html="subMenuItemThird.name"></span>
                                <z-icon v-if="subMenuItemThird.params.EXTERNAL" name="external" />
                            </z-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
    name: 'site-map',
    data () {
        return {
            data: []
        }
    },
    mounted () {
        this.data = this.$root.app.components.sitemap
    }
}
</script>

<style lang="scss">
.sitemap {
    &__title{
        color: $token-colors-navy-blue !important;
        margin-bottom: $token-spacers-l !important;
    }

    &__list {
        columns: 3;

        @include breakpoint (tablet) {
            columns: 2;
        }

        @include breakpoint (v-tablet) {
            columns: 1;
        }
    }

    &__item {
        break-inside: avoid;
        margin-bottom: $token-spacers-xl;

    }

    &__item-title {
        font-weight: 700;
        color: $token-colors-blue;
        margin-bottom: 20px;
        display: block !important;
        line-height: 1.6;
        font-size: 1.65em;
    }

    &__subitem {
        &:not(:last-child) {
            margin-bottom: 18px;
        }
    }

    &__subitem-title {
        font-size: 20px;
        line-height: 120%;
        font-weight: 400;
        text-decoration: none;
        color: $token-colors-blue;
    }

    &__sublist-third {
        margin-top: 18px;
        margin-left: 22px;
    }
}
</style>
