<template>
    <div class="page-404" :class="{'page-404-content': this.type === 'content'}">
        <div class="container">
            <div class="row">
                <div :class="gridClassImg">
                    <div class="page-404__container--ph">
                        <img class="page-404__svg--responsive" :src="image" alt="404 robot"/>
                    </div>
                </div>
                <div :class="gridClassCaption">
                    <div class="u-top-margin--m">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'page-404',
    props: {
        type: {
            type: String,
            default: 'page',
            validator: prop => ['page', 'content'].includes(prop)
        }
    },
    data () {
        return {}
    },
    computed: {
        image () {
            if (this.type === 'content') {
                return require('./images/404-content.svg')
            } else {
                return require('./images/404.svg')
            }
        },
        gridClassImg () {
            if (this.type === 'page') {
                return ['col-default-6', 'col-v-tablet-12']
            }
            if (this.type === 'content') {
                return ['col-default-offset-2', 'col-default-3', 'col-tablet-0', 'col-tablet-offset-0', 'col-tablet-12']
            }
        },
        gridClassCaption () {
            if (this.type === 'page') {
                return ['col-default-6', 'col-v-tablet-12']
            }
            if (this.type === 'content') {
                return ['col-default-offset-1', 'col-default-5', 'col-tablet-7', 'col-tablet-offset-0', 'col-tablet-12']
            }
        }
    }
}
</script>
<style lang="scss">
.page-404 {
    $parent: &;
    display: flex;
    height: 100%;
    width: 100%;

    .z-caption {
        font-weight: 700;
    }

    &__text--xl {
        &-lite {
            //styleName: XL/XL lite/Desktop Tablet;
            @include typo-level(XL);
            font-weight: 400;
        }
    }

    &-page {
        margin-top: 216px;

        &__container--ph {
            height: 0;
            padding-top: 67%;
            position: relative;
            width: 100%;
            max-width: 663px;
            margin: 0 auto;
        }

        &__svg--responsive {
            position: absolute;
            top: 0;
            left: 0;
        }

        @include breakpoint(laptop) {
            margin-top: $token-spacers-4-xl;
        }

        @include breakpoint(tablet) {
            margin-top: $token-spacers-3-xl;
        }

        @include breakpoint(mobile) {
            margin-top: $token-spacers-s;
        }

        @include breakpoint(v-mobile) {
            margin-top: $token-spacers-s;
        }
    }

    &-content {
        #{$parent}__container--ph {
            width: 100%;

            img {
                @include breakpoint (mobile) {
                    width: 250px;
                }
            }

            @include breakpoint (tablet) {
                display: flex;
                justify-content: center;
            }
        }

        .row {
            align-items: center;
        }
    }
}
</style>
