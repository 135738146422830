<template>
    <tippy-tooltip
        placement="right"
        :trigger="getEventForDevice"
        append-to="parent"
        :interactive="true"
        theme="light"
        class="calendar-share"
    >
        <template v-slot:trigger>
            <z-link size="m" href="javascript: void(0);">
                <template #left>
                    <z-icon name="share-2" width="24" height="24" />
                </template>
                <span class="u-bold" v-html="text.share"></span>
            </z-link>
        </template>
        <z-share :services="['vkontakte', 'odnoklassniki']" :lang="$root.lang" bare />
    </tippy-tooltip>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'

export default {
    name: 'calendar-share',
    data () {
        return {
            text: {
                share: localize({
                    ru: 'Поделиться',
                    en: 'Share'
                })
            }
        }
    },
    mixins: [mixinDevice],
    computed: {
        getEventForDevice () {
            if (this.device === 'mobile' || this.device === 'v-tablet') {
                return 'click'
            } else {
                return 'mouseenter focus'
            }
        }
    }
}
</script>

<style lang="scss"></style>
