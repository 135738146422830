/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leaf-air': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_9226_32408)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.9 21.4L2 20.7C6.8 8.3 18.8 0 32 0h2.4l-6.7 6.7-1.4-1.4 3.2-3.2C18.1 3.1 8 10.6 3.9 21.4zm60.1 13l-6.7-6.7 1.4-1.4 3.2 3.2C60.9 18.1 53.4 8 42.6 3.9l.7-1.9C55.7 6.8 64 18.8 64 32v2.4zM29.6 64H32c13.2 0 25.2-8.3 29.9-20.6l-1.9-.7c-4.1 10.7-14.1 18.2-25.5 19.2l3.2-3.2-1.4-1.4-6.7 6.7zm-9-2.1C8.3 57.2 0 45.2 0 32v-2.4l6.7 6.7-1.4 1.4-3.2-3.2c1 11.4 8.5 21.4 19.2 25.5l-.7 1.9zm10.7-6.1l.7.6.7-.7.052-.052C33.66 54.762 44 44.661 44 32c0-12.035-9.34-21.755-11.046-23.53-.137-.142-.224-.233-.254-.27l-.7-.6-.7.7-.052.05C30.34 9.238 20 19.339 20 32c0 12.034 9.34 21.754 11.046 23.529.137.142.224.233.254.27zm-4.223-39.124A43.525 43.525 0 0131 11.488V20.6l-3.923-3.924zm-1.06 1.742L31 23.4v7.2l-7.3-7.3-.011.011a34.22 34.22 0 012.329-4.893zM31 40.6v-7.2l-8.01-8.01c-.577 1.973-.946 4.059-.986 6.213L31 40.6zm0 2.8v9.111c-2.742-3.105-7.898-9.92-8.848-17.959L31 43.4zm2 0l8.848-8.848c-.95 8.04-6.106 14.854-8.848 17.96V43.4zm8.996-11.796L33 40.6v-7.2l8.01-8.01c.577 1.973.946 4.059.986 6.214zM40.3 23.3l.011.011a34.219 34.219 0 00-2.329-4.893L33 23.4v7.2l7.3-7.3zm-3.377-6.624A43.518 43.518 0 0033 11.488V20.6l3.923-3.924z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_32408"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
