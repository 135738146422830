/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'monitor-play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.4 5A2.6 2.6 0 014 2.4h16A2.6 2.6 0 0122.6 5v10a2.6 2.6 0 01-2.6 2.6h-7.4v2.8H16a.6.6 0 110 1.2H8a.6.6 0 110-1.2h3.4v-2.8H4A2.6 2.6 0 011.4 15V5zM12 16.4h8a1.4 1.4 0 001.4-1.4V5A1.4 1.4 0 0020 3.6H4A1.4 1.4 0 002.6 5v10A1.4 1.4 0 004 16.4h8z" _fill="#0077C8"/><path pid="1" d="M14.211 9.106l-2.474-1.238A1.2 1.2 0 0010 8.942v2.116a1.2 1.2 0 001.737 1.074l2.474-1.238a1 1 0 000-1.788z" _fill="#0077C8"/>'
  }
})
