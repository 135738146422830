/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/default': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11 3.066a4.267 4.267 0 00-4.267 4.267v29.333A4.267 4.267 0 0011 40.933h22a4.267 4.267 0 004.267-4.267V14.68v-.014c0-.17-.07-.323-.184-.432L26.099 3.251a.598.598 0 00-.432-.185H11zm14.067 1.2H11a3.067 3.067 0 00-3.067 3.067v29.333A3.067 3.067 0 0011 39.733h22a3.067 3.067 0 003.067-3.067v-21.4h-10.4a.6.6 0 01-.6-.6v-10.4zm10.151 9.8l-8.951-8.95v8.95h8.951zm-21.151 9.768a.6.6 0 01.6-.6h14.667a.6.6 0 010 1.2H14.667a.6.6 0 01-.6-.6zm.6 6.732a.6.6 0 100 1.2h14.667a.6.6 0 000-1.2H14.667zm-.6-14.066a.6.6 0 01.6-.6h3.667a.6.6 0 010 1.2h-3.667a.6.6 0 01-.6-.6z" _fill="#0077C8"/>'
  }
})
