<template>
    <div
        :class="[
            'slider-thumb-item',
            { 'slider-thumb-item--centered': centered }
        ]"
    >

        <slot />
        <div class="slider-thumb-item__preview">
            <div class="slider-thumb-item__preview-container">
                <img v-if="previewImg" :src="previewImg" alt="" class="slider-thumb-item__preview-img">
                <z-caption
                    tag="h3"
                    :size="title.length >  65 ? 'l' :'2xl'"
                    weight="700"
                    :class="[
                        'slider-thumb-item__title',
                        { 'slider-thumb-item__title--small': title.length >  65 }
                    ]"
                    v-if="title"
                >
                    <vue-raw :raw="getFormatedTitle(title)"/>
                </z-caption>
            </div>
            <slot name="preview"/>
        </div>
        <div class="slider-thumb-item__detail">
            <z-caption
                tag="h3"
                :size="title.length >  65 ? '3xl' :'4xl'"
                weight="700"
                v-if="title"
                class="slider-thumb-item__title"
            >
                <vue-raw :raw="title"/>
            </z-caption>
            <slot name="detail"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'slider-thumb-item',
    props: {
        title: String,
        centered: Boolean,
        previewImg: String
    },
    methods: {
        getFormatedTitle (title) {
            let maxLength = 80
            if (window.innerWidth < 1801) maxLength = 65
            if (this.device === 'tablet') maxLength = 55
            let result = title.length > maxLength ? `${this.trimText(title, maxLength)}...` : title

            return result
        },
        trimText (text, length, end = '') {
            let index = text.indexOf(' ', length)
            if (index == -1) index = length

            return text.slice(0, index) + end
        }
    }
}
</script>

<style lang="scss">

.slider-thumbs-container {
    display: flex;
    padding-left: 40%;
    padding-right: $token-grids-desktop-offset - 48px;

    @include breakpoint(laptop) {
        padding-left: 50%;
        padding-right: $token-grids-laptop-offset - 48px;
    }

    @include breakpoint (v-tablet) {
        padding-left: 0;
        padding-right: $token-grids-v-tablet-offset - 48px;
    }

    @include breakpoint (mobile) {
        padding-right: $token-grids-mobile-offset - 48px;
    }
}

.slider-thumb-item {
    $parent: &;

    width: 50%;
    min-height: 120px;
    background: $token-colors-blue;
    opacity: 0.9;
    color: $token-colors-white;
    padding-top: $token-spacers-xs;
    padding-bottom: $token-spacers-xs;
    padding-left: 48px;
    padding-right: 48px;

    @include breakpoint (laptop) {
        width: 100%;
    }

    &__title {
        color: $token-colors-white !important;
        margin-bottom: 0 !important;
    }

    &__detail {
        display: none;
    }

    &__preview-container {
        display: flex;
        align-items: flex-start;
    }

    &__preview-img {
        width: 80px;
        border-top-right-radius: 15px;
        overflow: hidden;
        margin-right: 20px;
        flex-shrink: 0;
    }

    &:first-child {
        background: $token-colors-white;
        color: inherit;
        width: 40%;
        min-height: 170px;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top-right-radius: $boder-radius-s;
        opacity: 1;
        margin-bottom: 0;
        padding-left: $token-grids-desktop-offset;
        padding-right: $token-grids-desktop-offset;
        padding-top: $token-spacers-m;
        padding-bottom: $token-spacers-m;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
            padding-right: $token-grids-laptop-offset;
            padding-top: $token-spacers-s;
            padding-bottom: $token-spacers-s;
            width: 50%;
        }

        @include breakpoint (v-tablet) {
            width: 100%;
            min-height: 150px;
            position: relative;
            padding-left: $token-grids-v-tablet-offset;
            padding-right: $token-grids-v-tablet-offset;
        }

        @include breakpoint (mobile) {
            border-top-right-radius: 0;
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
        }

        #{$parent}__title {
            color: $token-colors-navy-blue !important;

            & + * {
                margin-top: $token-spacers-xs;
            }
        }

        #{$parent}__preview {
            display: none;
        }

        #{$parent}__detail {
            display: block;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 12px;
            width: 90%;
            border-top-left-radius: $boder-radius-m;
            background: $token-colors-blue;

            @include breakpoint (mobile) {
                height: 6px;
                width: 70%;
            }
        }
    }

    &--centered {
        display: flex;
        flex-direction: column;
        justify-content: center;

        #{$parent}__preview-container {
            align-items: center;
        }
    }
}
</style>
