/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cn/award': {
    width: 58,
    height: 58,
    viewBox: '0 0 58 58',
    data: '<path pid="0" d="M31.25 37.25h-1.5v-7.5c0-.3.225-.6.525-.675 3.975-1.35 8.475-8.4 8.475-15.825 0-1.725-.45-5.25-.675-6.75h-18.15c-.225 1.5-.675 5.025-.675 6.75 0 7.425 4.5 14.475 8.475 15.825a.72.72 0 01.525.675v7.5h-1.5v-6.975c-4.875-2.025-9-9.825-9-17.025 0-2.25.75-7.425.75-7.575.075-.375.375-.675.75-.675h19.5c.375 0 .675.3.75.675 0 .15.75 5.325.75 7.575 0 7.2-4.125 15-9 17.025v6.975z" _fill="#ADD1EA"/><path pid="1" d="M37.25 41h-16.5c-.3 0-.6-.15-.675-.45-.15-.3-.075-.6.15-.825l3-3a.68.68 0 01.525-.225h10.5a.68.68 0 01.525.225l3 3c.225.225.3.525.15.825-.075.3-.375.45-.675.45zm-14.7-1.5h12.9l-1.5-1.5h-9.9l-1.5 1.5z" _fill="#ADD1EA"/><path pid="2" d="M38.75 50h-19.5c-.45 0-.75-.3-.75-.75v-9c0-.45.3-.75.75-.75h19.5c.45 0 .75.3.75.75v9c0 .45-.3.75-.75.75zM20 48.5h18V41H20v7.5z" _fill="#ADD1EA"/><path pid="3" d="M41.75 53h-25.5c-.45 0-.75-.3-.75-.75v-3c0-.45.3-.75.75-.75h25.5c.45 0 .75.3.75.75v3c0 .45-.3.75-.75.75zM17 51.5h24V50H17v1.5zM21.875 25.925c-.45-.3-10.125-6.375-10.125-17.175 0-.45.3-.75.75-.75h6v1.5h-5.25c.45 9.525 9.3 15.075 9.375 15.075l-.75 1.35zM36.125 25.925l-.75-1.275c.075-.075 8.925-5.625 9.375-15.15H39.5V8h6c.45 0 .75.3.75.75 0 10.8-9.675 16.875-10.125 17.175zM29.75 44h-1.5v1.5h1.5V44zM26.75 44h-1.5v1.5h1.5V44zM32.75 44h-1.5v1.5h1.5V44zM32.75 22.25c-.15 0-.3-.075-.45-.15L29 19.475 25.7 22.1c-.225.225-.6.225-.9 0a.749.749 0 01-.3-.825l1.35-3.975-3.375-2.7c-.225-.225-.375-.525-.225-.825a.72.72 0 01.675-.525H26.9l1.35-3.975c.15-.3.45-.525.75-.525s.6.225.675.525l1.35 3.975H35c.3 0 .6.225.675.525.075.3 0 .6-.225.825l-3.375 2.7 1.35 3.975c.075.3 0 .675-.3.825a.573.573 0 01-.375.15zM29 17.75c.15 0 .3.075.45.15l1.8 1.425-.675-2.1c-.075-.3 0-.6.225-.825l2.1-1.65h-2.4a.72.72 0 01-.675-.525L29 11.9l-.825 2.4c-.075.225-.375.45-.675.45h-2.325l2.1 1.65c.225.225.375.525.225.825l-.75 2.1 1.8-1.425c.15-.075.3-.15.45-.15z" _fill="#ADD1EA"/><path pid="4" d="M53 51.5H5V53h48v-1.5z" _fill="#ADD1EA"/>'
  }
})
