<template>
<div class="news-filter">
    <filter-container :show-reset-btn="isFilterActive" @clear="clear">
        <datepicker-range
            name="date"
            @change="onChange"
            ref="date"
            :value="{
                end: params.to,
                start: params.from
            }"
        />

        <tags-filter
            name="sources"
            :title="titleTypes"
            v-if="sourcesData.length"
            :data="sourcesData"
            @change="onChange"
            ref="sources"
        />

        <tags-filter
            name="subjects"
            :title="titleSubjects"
            v-if="subjectsData.length"
            :data="subjectsData"
            @change="onChange"
            ref="subjects"
        />
    </filter-container>
</div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { localize } from '@/utils/i18n'

export default {
    name: 'news-filter',
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        initial: Object
    },
    data () {
        return {
            params: {
                from: '',
                to: '',
                sources: [],
                subjects: []
            },
            titleSubjects: localize({
                ru: 'Темы',
                en: 'Topic'
            }),
            titleTypes: localize({
                ru: 'Тип',
                en: 'Type'
            })
        }
    },
    computed: {
        isFilterActive () {
            return Boolean(
                this.params?.from ||
                this.params?.to ||
                this.params?.sources.length ||
                this.params?.subjects.length
            )
        },
        sourcesData () {
            if (!this.data?.sources) return []
            return this.data.sources
        },
        subjectsData () {
            if (!this.data?.subjects) return []
            return this.data.subjects
        }
    },
    methods: {
        onChange (data) {
            if (data.name === 'date') {
                this.params.from = data.value.start
                this.params.to = data.value.end
            } else {
                this.params[data.name] = data.value
            }

            this.send(this)
        },
        clear () {
            this.params = {
                from: '',
                to: '',
                sources: [],
                subjects: []
            }

            if (this.$refs.date) this.$refs.date.clear()
            if (this.$refs.sources) this.$refs.sources.clear()
            if (this.$refs.subjects) this.$refs.subjects.clear()

            this.send(this)
        },
        send: debounce(100, _this => {
            _this.$emit('change', _this.params)
        })
    },
    created () {
        if (this.initial) this.params = { ...this.params, ...this.initial }
    }
}
</script>

<style lang="scss">
</style>
