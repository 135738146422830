<template>
    <div class="documents-and-news">
        <div class="container">
            <div class="row">
                <div class="col-default-7 col-tablet-12">
                    <z-caption
                        tag="h5"
                        size="m"
                        weight="700"
                        uppercase
                        decor-right
                        class="u-top-margin--2xl u-bottom-margin--l"
                    >
                        <vue-raw :raw="this.text.reportsTitle" />
                    </z-caption>
                    <div class="row">
                        <div
                            class="col-default-6 col-mobile-12"
                            v-if="resultsAndReports && resultsAndReports.csr && resultsAndReports.csr.csrFile.link"
                        >
                            <z-filelist theme="fancy" size="s">
                                <z-filelist-item
                                    :name="resultsAndReports.csr.csrFile.name || `${text.csr} ${resultsAndReports.csr.name}`"
                                    :href="resultsAndReports.csr.csrFile.link"
                                    :file-size="resultsAndReports.csr.csrFile.size"
                                    icon-name="file/text-pdf"
                                ></z-filelist-item>
                            </z-filelist>
                        </div>
                        <template v-for="(report, index) in additionalReports">
                            <div class="col-default-6 col-mobile-12" :key="`additional-report-${index}`">
                                <z-filelist theme="fancy" size="s">
                                    <z-filelist-item
                                        :name="report.name"
                                        :href="report.file.link"
                                        :file-size="report.file.size"
                                        icon-name="file/text-pdf"
                                    ></z-filelist-item>
                                </z-filelist>
                            </div>
                        </template>
                    </div>
                    <z-link
                        :href="reportsLink"
                        size="m"
                        target="_blank"
                        class="u-top-margin--m-important"
                    >
                        <template v-slot:left>
                            <z-icon
                                name="round-arrow"
                                dir="right"
                                width="1.6em"
                                height="1.6em"
                            />
                        </template>
                        <vue-raw :raw="text.reportsLink.title" />
                    </z-link>
                </div>

                <div class="col-default-5 col-tablet-12">
                    <div class="documents-and-news__documents-right">
                        <z-caption
                            tag="h5"
                            size="m"
                            weight="700"
                            uppercase
                            decor-right
                            class="u-top-margin--2xl u-bottom-margin--l"
                        >
                            <vue-raw :raw="text.presentationsTitle" />
                        </z-caption>
                        <div class="row">
                            <div class="col-default-12">
                                <z-filelist theme="fancy" size="s">
                                    <template
                                        v-for="presentation in this.app['presentation-and-briefings']"
                                    >
                                        <z-filelist-item
                                            :key="presentation.code"
                                            :name="presentation.name"
                                            :href="presentation.mlType.xmlId === 'presentation' ? presentation.mlDocs.link : presentation.detailPageUrl"
                                            icon-name="monitor-play"
                                        ></z-filelist-item>
                                    </template>

                                </z-filelist>
                            </div>
                        </div>
                        <z-link
                            :href="presentationsLink"
                            size="m"
                            target="_blank"
                            class="u-top-margin--m-important"
                        >
                            <template v-slot:left>
                                <z-icon
                                    name="round-arrow"
                                    dir="right"
                                    width="1.6em"
                                    height="1.6em"
                                />
                            </template>
                            <vue-raw :raw="text.presentationsLink.title" />
                        </z-link>
                    </div>
                </div>

                <div class="col-default-7 col-tablet-12">
                    <div class="row">
                        <div class="col-default-5 col-v-tablet-12">
                            <z-caption
                                tag="h5"
                                size="m"
                                weight="700"
                                uppercase
                                decor-right
                                class="u-top-margin--2xl u-bottom-margin--l"
                            >
                                <vue-raw :raw="text.policiesTitle" />
                            </z-caption>
                            <z-filelist theme="fancy" size="s">
                                <z-filelist-item
                                    :name="text.policies[0].title"
                                    :href="text.policies[0].linkHref"
                                    file-size="2.5 Mb"
                                    icon-name="file/text-pdf"
                                ></z-filelist-item>
                            </z-filelist>
                            <z-link
                                :href="policiesLink"
                                size="m"
                                target="_blank"
                                class="u-top-margin--m-important"
                            >
                                <template v-slot:left>
                                    <z-icon
                                        name="round-arrow"
                                        dir="right"
                                        width="1.6em"
                                        height="1.6em"
                                    />
                                </template>
                                <vue-raw :raw="text.policiesLink.title" />
                            </z-link>
                        </div>
                        <div class="col-default-7 col-v-tablet-12">
                            <div class="documents-and-news__documents-right">
                                <z-caption
                                    tag="h5"
                                    size="m"
                                    weight="700"
                                    uppercase
                                    decor-right
                                    class="u-top-margin--2xl u-bottom-margin--l"
                                >
                                    <vue-raw :raw="text.databookTitle" />
                                </z-caption>
                                <z-filelist theme="fancy" size="l">
                                    <z-filelist-item
                                        :name="text.databook.name"
                                        :href="text.databook.linkHref"
                                        file-size="128 Kb"
                                        icon-name="file/text-xlsx"
                                    ></z-filelist-item>
                                </z-filelist>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-default-5 col-tablet-12">
                    <div class="documents-and-news__documents-right">
                        <z-caption
                            tag="h5"
                            size="m"
                            weight="700"
                            uppercase
                            decor-right
                            class="u-top-margin--2xl u-bottom-margin--l"
                        >
                            <vue-raw :raw="text.newsTitle" />
                        </z-caption>
                        <news-list theme="aside" component="sustainabilityPageNews"></news-list>
                        <z-link
                            :href="newsLink"
                            size="m"
                            target="_blank"
                            class="u-top-margin--m-important"
                        >
                            <template v-slot:left>
                                <z-icon
                                    name="round-arrow"
                                    dir="right"
                                    width="1.6em"
                                    height="1.6em"
                                />
                            </template>
                            <vue-raw :raw="text.newsLink.title" />
                        </z-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'documents-and-news',
    props: {
        reportsLink: {
            type: String,
            default: '/investors/reports-and-results/annual-reports/'
        },
        presentationsLink: {
            type: String,
            default: '/investors/presentations-and-briefings/'
        },
        policiesLink: {
            type: String,
            default: '/sustainability/governance/esg-policies/'
        },
        newsLink: {
            type: String,
            default: '/investors/news/'
        }
    },
    data () {
        return {
            resultsAndReports: this.$root.app?.components?.['annual-reports-block'],
            additionalReports: this.$root.app?.components?.['annual-reports-block']?.additionalReports?.slice(0, 3) || [],
            icon: {
                presentation: 'book-open',
                interview: 'mic'
            },
            text: {
                reportsTitle: localize({
                    'ru': 'Отчеты',
                    'en': 'Annual Reports'
                }),
                reportsLink: {
                    title: localize({
                        'ru': 'Все отчеты',
                        'en': 'Learn more'
                    })
                },
                presentationsTitle: localize({
                    'ru': 'Презентации',
                    'en': 'ESG presentations'
                }),
                presentations: [
                    {
                        title: localize({
                            'ru': 'Владимир Потанин об&nbsp;осознанной ESG-политике &laquo;Норникеля&raquo;',
                            'en': 'Vladimir Potanin on&nbsp;Nornickel ESG-strategy (RUS)'
                        }),
                        linkHref: localize({
                            'ru': '/news-and-media/media-library/?type=interview&limit=all#53675',
                            'en': '/news-and-media/media-library/?type=interview&limit=all#53675'
                        })
                    },
                    {
                        title: localize({
                            'ru': 'Презентация по&nbsp;устойчивому развитию',
                            'en': 'ESG presentation'
                        }),
                        linkHref: localize({
                            'ru': '/upload/iblock/708/Norilsk-Nickel-Sustainable-Development-Update-May-2021.pdf',
                            'en': '/upload/iblock/760/esg_presentation_december_2021.pdf'
                        })
                    }
                ],
                presentationsLink: {
                    title: localize({
                        'ru': 'Все презентации',
                        'en': 'Learn more'
                    })
                },
                policiesTitle: localize({
                    'ru': 'Политики в&nbsp;области УР',
                    'en': 'Corporate Codes and Policies'
                }),
                policies: [
                    {
                        title: localize({
                            'ru': 'Экологическая политика',
                            'en': 'Environmental Policy'
                        }),
                        linkHref: localize({
                            'ru': '/upload/iblock/d77/Ehkologicheskaya_politika.pdf',
                            'en': '/upload/iblock/0bd/Environmental_Policy_220721.pdf'
                        })
                    }
                ],
                policiesLink: {
                    title: localize({
                        'ru': 'Все политики',
                        'en': 'Learn more'
                    })
                },
                databookTitle: localize({
                    'ru': 'ESG DATA BOOK',
                    'en': 'ESG DATA BOOK'
                }),
                databook: {
                    name: 'ESG data book',
                    linkHref: localize({
                        'ru': '/files/ru/sustainability/ESG_Databook_RUS.xlsx',
                        'en': '/files/en/sustainability/ESG_Databook_ENG.xlsx'
                    })
                },
                newsTitle: localize({
                    'ru': 'ESG-новости',
                    'en': 'ESG news'
                }),
                newsLink: {
                    title: localize({
                        'ru': 'Все новости',
                        'en': 'All news'
                    })
                },
                'annual-report': localize({
                    ru: 'Годовой отчет',
                    en: 'Annual report'
                }),
                brochure: localize({
                    ru: 'Брошюра &laquo;Обеспечиваем движение к&nbsp;зеленому будущему&raquo;',
                    en: 'Brochure'
                }),
                csr: localize({
                    ru: 'Отчет об устойчивом развитии',
                    en: 'Sustainability report'
                }),
                'white-book': localize({
                    ru: 'Белая книга о&nbsp;ликвидации последствий аварии на&nbsp;ТЭЦ-3&nbsp;в 2020 году',
                    en: 'White paper on&nbsp;NTEC&rsquo;s HPP-3 incident clean-up and response'
                })
            }
        }
    },
    computed: {
        app () {
            return window.App.components['sustainability-page']
        }
    },
    mounted () {
        console.log(this.resultsAndReports['annual-report'].arFile.link)
    }
}
</script>

<style lang="scss">
.documents-and-news {
    &__documents-right {
        @include padding-level(left, 2XL);

        @include breakpoint(tablet) {
            padding-left: 0;
        }
    }

    &__documents-top {
        @include breakpoint(v-tablet) {
            @include margin-level(top, S);
        }
    }

    .news-list {
        &__body {
            justify-content: center;
        }
    }
}
</style>
