/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/mic': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31970)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M69.65 67.75a3.6 3.6 0 013.6-3.6h1.5a3.6 3.6 0 013.6 3.6v7.75a3.6 3.6 0 01-3.6 3.6h-1.5a3.6 3.6 0 01-3.6-3.6v-7.75zm3.6-2.4a2.4 2.4 0 00-2.4 2.4v7.75a2.4 2.4 0 002.4 2.4h1.5a2.4 2.4 0 002.4-2.4v-7.75a2.4 2.4 0 00-2.4-2.4h-1.5zm8.85 8.8a.6.6 0 01.6.6 8.7 8.7 0 01-17.4 0 .6.6 0 011.2 0 7.5 7.5 0 0015 0 .6.6 0 01.6-.6zm-7.5 10.6a.6.6 0 10-1.2 0v2.5a.6.6 0 001.2 0v-2.5z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31970" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31970"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31970" result="effect2_dropShadow_9226_31970"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31970" result="shape"/></filter></defs>'
  }
})
