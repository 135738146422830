<template>
    <div class="cmd-materials" v-if="data">
        <z-caption
            tag="h2"
            decor-left
            decor-right
            size="m"
            weight="700"
            uppercase
        >
            <span v-html="text.materials"></span>
        </z-caption>

        <z-filelist size="l" theme="fancy">
            <z-filelist-item
                :name="text.presentation"
                :href="data.presentations[0].link"
                :file-size="data.presentations[0].size"
                icon-name="monitor"
                :file-extension="data.presentations[0].type"
                v-if="data.presentations && data.presentations.length"
            >
            </z-filelist-item>
            <z-filelist-item
                :name="text['press-release']"
                :href="data['press-release'].link"
                icon-name="book-open"
                show-file-info
                v-if="data['press-release'] && data['press-release'].link"
            ></z-filelist-item>
            <z-filelist-item
                :name="text.broadcast"
                :href="data.broadcast.link"
                icon-name="mic"
                show-file-info
                v-if="data.broadcast && data.broadcast.link"
            >
                <template v-slot:header>
                    <z-icon name="external" width="24" height="24" />
                </template>
                <template v-slot:fileinfo>
                    <span
                        class="u-normal"
                        v-if="data.broadcast.text"
                        v-html="data.broadcast.text"
                    ></span>
                </template>
            </z-filelist-item>
        </z-filelist>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'cmd-materials',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                materials: localize({
                    ru: 'Материалы',
                    en: 'Materials'
                }),
                broadcast: localize({
                    ru: 'Трансляция',
                    en: 'Webcast'
                }),
                presentation: localize({
                    ru: 'Презентация',
                    en: 'Presentation'
                }),
                'press-release': localize({
                    ru: 'Пресс-релиз',
                    en: 'Press-release'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.cmd-materials {

}
</style>
