/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Odnoklassniki': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M20.97 16.549l.01-.007.009-.007c.253-.19.49-.305.71-.362.543-.104 1.002.11 1.262.523.348.582.26 1.072-.077 1.41-.616.616-1.373 1.1-2.428 1.453l-.023.007-.022.01c-.61.271-1.304.413-1.951.485l-1.034.115.736.735 1.274 1.275.008.008.01.008c.54.492 1.04 1.008 1.54 1.526.253.26.506.522.766.781l.013.014.015.012a1 1 0 01.253 1.196l-.005.01-.005.01c-.22.495-.773.805-1.212.756l-.027-.003h-.028a.834.834 0 01-.376-.073 1.463 1.463 0 01-.395-.305l-.45-.45-.44-.44c-.306-.343-.63-.667-.942-.979l-.005-.005c-.295-.296-.58-.58-.85-.878a.563.563 0 00-.421-.31.597.597 0 00-.077-.008h-.016 0l-.001.5v-.5l-.13.52-.014.055-.023.092-.035.036c-.072.013-.133-.038-.17-.128a.616.616 0 01-.024-.321.817.817 0 01.017-.08l.012-.014.007-.007.003-.003-.006.006-.066.066L13.44 26.2l-.02.02-.017.021a.948.948 0 01-.734.337c-.235 0-.313-.01-.397-.066l-.036-.023-.038-.017a1.297 1.297 0 01-.753-1.193c0-.323.184-.576.378-.77l2.549-2.55.974-.974.713-.713-.998-.136c-1.563-.213-2.744-.703-3.709-1.454l-.007-.007a1.165 1.165 0 00-.234-.26l-.086-.072a1.19 1.19 0 01-.101-.089l-.354.354.354-.354c-.271-.27-.394-.542-.418-.791-.025-.248.044-.524.249-.817l.01-.015.01-.015c.157-.264.365-.402.592-.454.234-.054.53-.025.862.124l.216.108.196.098c2.482 1.664 5.837 1.745 8.33.056zm-7.85-2.317l-.012-.013-.011-.012c-1.032-1.031-1.576-2.327-1.578-3.763.072-2.885 2.408-5.278 5.347-5.278 1.36 0 2.66.542 3.694 1.578a5.396 5.396 0 011.578 3.844c0 2.939-2.391 5.272-5.347 5.272-1.44 0-2.723-.544-3.672-1.628zm3.671-6.543c-.723 0-1.453.272-2.003.821-.55.55-.82 1.28-.82 2.003 0 1.54 1.187 2.824 2.823 2.824 1.54 0 2.824-1.188 2.824-2.824 0-1.54-1.188-2.824-2.824-2.824z" _fill="#0077C8" _stroke="#0077C8"/>'
  }
})
