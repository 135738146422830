/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-text': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 1.4A2.6 2.6 0 003.4 4v16A2.6 2.6 0 006 22.6h12a2.6 2.6 0 002.6-2.6V8a.6.6 0 00-.6-.6H18.55L14.6 3.45V2a.598.598 0 00-.6-.6.6.6 0 00-.6.6v.6H6A1.4 1.4 0 004.6 4v16A1.4 1.4 0 006 21.4h12a1.4 1.4 0 001.4-1.4V8.6H14a.6.6 0 01-.6-.6V2.6M7.4 13a.6.6 0 01.6-.6h8a.6.6 0 110 1.2H8a.6.6 0 01-.6-.6zm.6 3.4a.6.6 0 100 1.2h8a.6.6 0 100-1.2H8zM7.4 9a.6.6 0 01.6-.6h2a.6.6 0 110 1.2H8a.6.6 0 01-.6-.6z" _fill="#0077C8"/>'
  }
})
