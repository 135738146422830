<template>
    <form @submit.prevent="submitForm">
        <div class="row u-collapse--top">
            <div class="col-default-6 col-tablet-12">
                <z-input
                    v-model="formData.fio"
                    type="text"
                    name="fio"
                    required
                    ref="fio"
                    :title="text.name"
                    class="question-form__input"
                ></z-input>
            </div>
            <div class="col-default-6 col-tablet-12">
                <z-input
                    v-model="formData.email"
                    type="email"
                    ref="email"
                    required
                    name="email"
                    :title="text.email"
                    class="question-form__input"
                ></z-input>
            </div>

            <div class="col-default-12 u-top-margin--2xl-important">
                <z-textarea
                    v-model="formData.question"
                    required
                    name="question"
                    validateOnBlur
                    ref="question"
                    :title="text.message"
                    class="question-form__input"
                ></z-textarea>
            </div>

            <div class="col-default-12">
                <div class="question-form__captcha-container">
                    <z-checkbox
                        name="policy"
                        ref="policy"
                        v-model="isChecked"
                        class="question-form__checkbox"
                        required
                        :data="[{ value: '1' }]"
                    >
                        <template #text>
                            <z-caption tag="p" weight="400" size="xs">
                                <vue-raw :raw="text.policy"/>
                            </z-caption>
                        </template>
                    </z-checkbox>
                </div>

                <div class="form-captcha">
                    <div class="form-captcha__wrapper">
                        <div class="form-captcha__captcha">
                            <img
                                class="form-captcha__img"
                                v-if="isUpdatedCapcha"
                                :src="'/api/captcha/?' + capchaSalt"
                                width="100"
                                height="50"
                                alt=""
                            />
                            <div v-else class="form-captcha__img"></div>

                            <z-button
                                class="form-captcha__reset-btn"
                                type="button"
                                rounded
                                kind="secondary"
                                @click="updateCapcha"
                            >
                                <z-icon name="refresh" width="24" height="24"></z-icon>
                            </z-button>
                        </div>

                        <z-input
                            v-model="captcha"
                            type="text"
                            name="captcha"
                            ref="captcha"
                            required
                            class="question-form__input form-captcha__input"
                        ></z-input>
                    </div>
                </div>
            </div>

            <div class="col-default-12 u-relative u-top-margin--s">
                <p class="question-form__message" :class="{[`question-form__message--${this.message.type}`]: this.message.type}">{{ message.text }}</p>
            </div>

            <div class="col-default-12 u-top-margin--s">
                <z-button>
                    {{ text.button }}
                </z-button>
            </div>
        </div>
    </form>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendQuestionForm } from '@/api/question-form.js'
import { sendCaptcha } from '@/api/captcha.js'
import { text } from './content'

export default {
    name: 'question-form',
    props: {
        fileId: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            isChecked: [],
            isLoading: false,
            message: {
                text: '',
                type: ''
            },
            formData: {
                link: window.location.href,
                fio: '',
                email: '',
                question: ''
            },
            text,
            capchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCapcha: true
        }
    },
    watch: {
        captcha: function (value) {
            if (value) {
                this.clearMessage()
            }
        },
        isChecked: {
            handler () {
                this.clearMessage()
            },
            deep: true
        },
        formData: {
            handler () {
                this.clearMessage()
            },
            deep: true
        }
    },

    methods: {
        validateInputFormat () {
            if (this.formData.email) {
                const requiredFields = ['email']
                let isValid = true

                requiredFields.forEach((item) => {
                    const field = this.$refs[item]
                    if (field) {
                        field.validate()
                        if (field.error) isValid = false
                    }
                })
                return isValid
            }

            return true
        },
        clearMessage () {
            this.message.text = ''
            this.message.type = ''
        },
        validateRequiredInputs () {
            const requiredFields = ['fio', 'email', 'policy', 'captcha', 'question']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },

        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },

        async submitForm () {
            if (this.isLoading) {
                return
            }

            const isValidInputFormat = this.validateInputFormat()

            if (!isValidInputFormat) {
                showNotyfications(this.text.notificationValidateError, { type: 'error' })
                this.message.text = this.text.notificationValidateError
                this.message.type = 'error'
                return
            }

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        this.message.text = this.text.notificationCaptchaEmpty
                        this.message.type = 'error'
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }
                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                        this.message.text = this.text.notificationCaptchaError
                        this.message.type = 'error'
                    } else {
                        const formResponse = await sendQuestionForm(data)
                        if (formResponse.status === 'success') {
                            showNotyfications(this.text.notificationSuccess, { type: 'success' })
                            this.formData.fio = ''
                            this.formData.email = ''
                            this.captcha = ''
                            this.formData.question = ''
                            this.isChecked = []
                            // this.$nextTick(() => {
                            //     this.message.text = this.text.notificationSuccess
                            //     this.message.type = 'success'
                            // })
                        } else {
                            showNotyfications(this.text.notificationError, { type: 'error' })
                            this.message.text = this.text.notificationError
                            this.message.type = 'error'
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                    this.message.text = this.text.notificationError
                    this.message.type = 'error'
                } finally {
                    this.isLoading = false
                }
            } else {
                this.message.text = this.text.notificationRequiredTextError
                this.message.type = 'error'
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }

            this.message.clear = true
        }
    }
}
</script>

<style lang="scss">
.question-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .z-site-selector__active-site {
        display: none;
    }

    &__container {
        padding-right: 0;
        padding-left: 0;
    }

    &__message {
        position: absolute;
        font-size: 16px;

        &--error {
            color: $token-colors-alert-text;
        }

        &--success {
            color: $token-colors-success-text;
        }
    }

    &__warning {
        font-size: 13px;
        color: $token-colors-gray-60;
        line-height: 1.4;

        &-list {
            font-size: 12px;
            margin-bottom: 40px !important;
        }
    }

    &__input {
        position: relative;

        .z-input__error {
            position: absolute;
            bottom: -4px;
            transform: translate(0, 100%);
        }

        textarea {
            font-size: 16px !important;
            font-weight: 600 !important;
            padding: 12px 20px;
        }

        .z-input__title {
            margin-bottom: 8px !important;
        }

        .z-textarea__title {
            top: -32px;
        }
    }

    .z-textarea {
        &__title {
            transform: translate(0, -100%);
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 16px;
        }
    }

    &__captcha-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 0;
    }

    &__captcha-img {
        margin: 0 16px 0 0;
        width: 100px;
        height: 50px;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-button {
        margin: 0 8px 0 0;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__checkbox {
        margin-bottom: $token-spacers-s;
        position: relative;
        color: $token-colors-gray-50 !important;

        .z-checkbox__text {
            font-size: 13px !important;
        }

        .z-checkbox-group__error {
            position: absolute;
            bottom: -4px;
            transform: translate(0, 100%);
        }
    }
}
</style>
