/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'two-arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.024 10.976a.6.6 0 00-.849 0l-6 6a.6.6 0 00.849.848l5.576-5.576 5.575 5.576a.6.6 0 00.849-.848l-6-6zm0-5a.6.6 0 00-.849 0l-6 6a.6.6 0 00.849.848L11.6 7.25l5.575 5.575a.6.6 0 00.849-.848l-6-6z" _fill="#0077C8"/>'
  }
})
