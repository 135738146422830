import { localize } from '@/utils/i18n'

export const text = {
    name: localize({
        ru: 'Фамилия Имя Отчество',
        en: 'Full name'
    }),
    category: localize({
        ru: 'Категория вопроса*',
        en: 'Category*'
    }),
    phone: localize({
        ru: 'Телефон',
        en: 'Phone'
    }),
    phoneExtra: localize({
        ru: 'доб.',
        en: 'extra'
    }),
    email: localize({
        ru: 'E-mail',
        en: 'E-mail'
    }),
    message: localize({
        ru: 'Ваш вопрос*',
        en: 'Question*'
    }),
    button: localize({
        ru: 'Отправить',
        en: 'Send'
    }),
    notificationRequiredTextError: localize({
        ru: 'Заполните все обязательные поля',
        en: 'Fill in all required fields'
    }),
    notificationError: localize({
        ru: 'Ошибка отправки',
        en: 'Send error'
    }),
    notificationValidateError: localize({
        ru: 'Неправильный формат полей',
        en: 'Wrong field format'
    }),
    notificationSuccess: localize({
        ru: 'Отправка успешна',
        en: 'Sending successful'
    }),
    notificationCaptchaError: localize({
        ru: 'Неверный код капчи. Попробуйте снова.',
        en: 'Invalid captcha code. Try again.'
    }),
    notificationCaptchaEmpty: localize({
        ru: 'Введите код с картинки*',
        en: 'Enter the code from the image*'
    }),
    policy: localize({
        ru: 'В соответствии с Федеральным законом «О персональных данных» от 27.07.2006 N 152-ФЗ свободно, своей волей и в своем интересе я даю свое согласие ПАО «ГМК Норильский никель» на обработку моих персональных данных, указанных при заполнении формы обратной связи на сайте www.nornickel.ru',
        en: 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com, by PJSC “MMC “Norilsk Nickel”'
    })
}

export const categoryOptions = [
    {
        id: localize({
            ru: 'Обращение граждан',
            en: `Сitizens' appeal`
        }),
        selected: true,
        disabled: false,
        text: localize({
            ru: 'Обращение граждан',
            en: `Сitizens' appeal`
        })
    },
    {
        id: localize({
            ru: 'Коммерческие предложения',
            en: 'Commercial proposal'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Коммерческие предложения',
            en: 'Commercial proposal'
        })
    },
    {
        id: localize({
            ru: 'Резюме',
            en: 'CV'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Резюме',
            en: 'CV'
        })
    },
    {
        id: localize({
            ru: 'Запрос на покупку металла',
            en: 'The request for the purchase of metal'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Запрос на покупку металла',
            en: 'The request for the purchase of metal'
        })
    },
    {
        id: localize({
            ru: 'Официальные письма из федеральных и региональных органов власти',
            en: 'Official letters from Federal and regional authorities'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Официальные письма из федеральных и региональных органов власти',
            en: 'Official letters from Federal and regional authorities'
        })
    },
    {
        id: localize({
            ru: 'Прочее',
            en: 'Etc'
        }),
        selected: false,
        disabled: false,
        text: localize({
            ru: 'Прочее',
            en: 'Etc'
        })
    }
]
