<template>
    <div class="local-tenders-list" v-if="items.length">
        <template v-for="(item, index) in items">
            <div class="local-tenders-card" :key="`tender-${index}`">
                <div class="local-tenders-card__header">
                    <span
                        class="local-tenders-card__tag"
                        :style="`background-color: ${getColor(item.method.xmlId)}`"
                        v-html="item.method.value"
                    />
                    <div class="local-tenders-card__date-wrapper">
                        <span class="local-tenders-card__date-key">
                            Срок подачи заявки:
                        </span>
                        <span
                            class="local-tenders-card__date-value"
                            :class="{'local-tenders-card__date-value--hot': checkDate(item.activeTo)}"
                        >
                            {{ item.activeTo | momentFilter('DD.MM.YYYY') }}
                        </span>
                    </div>

                </div>
                <div class="local-tenders-card__body">
                    <p class="local-tenders-card__text" v-html="item.name"/>
                    <div class="local-tenders-card__file-wrapper">
                        <z-link :href="item.documentation.link" size="m" weight="600">
                            <template #left>
                                <span class="local-tenders-card__icon-download"/>
                            </template>
                            Скачать документацию
                        </z-link>
                        <span class="local-tenders-card__file-info" v-html="item.documentation.type"/>
                        <span class="local-tenders-card__file-info" v-html="item.documentation.size"/>
                    </div>
                </div>
                <local-tenders-table>
                    <local-tenders-table-item title="Заказчик" max-width="201px">
                        <span v-html="item.customer.value ? item.customer.value : '-'"/>
                    </local-tenders-table-item>
                    <local-tenders-table-item title="Адрес заказчика" max-width="201px">
                        <span v-html="item.address.value ? item.address.value : '-'"/>
                    </local-tenders-table-item>
                    <local-tenders-table-item title="Организатор" max-width="315px">
                        <span v-html="item.organizer.value ? item.organizer.value : '-'"/>
                    </local-tenders-table-item>
                    <local-tenders-table-item title="Контакты" max-width="201px" :is-copy="Boolean(item.email.value || item.phone.value)" :copy-content="adaptTOString(item)">
                        <template v-if="item.email.value || item.phone.value">
                            <div v-if="item.email.value" class="local-tenders-card__contact">
                                <span v-html="item.email.value"/>
                            </div>
                            <div v-if="item.phone.value" class="local-tenders-card__contact">
                                <span v-html="item.phone.value"/>
                            </div>
                        </template>
                        <span v-else>-</span>
                    </local-tenders-table-item>
                    <local-tenders-table-item
                        :title="`${item.applicationMethod.xmlId === 'procurement_system' ? '' : 'Способ подачи заяки'}`"
                        max-width="302px"
                    >
                        <template v-if="item.applicationMethod.xmlId === 'procurement_system'">
                            <z-button size="s" tag="a" href="http://srm.nornik.ru/" kind="secondary" target="_blank">
                                Подача заявки через SRM
                                <template v-slot:right>
                                    <z-icon name="external" width="20" height="20"/>
                                </template>
                            </z-button>
                        </template>
                        <template v-else>
                            <span v-html="item.applicationMethod.value ? item.applicationMethod.value : '-'"/>
                        </template>
                    </local-tenders-table-item>
                </local-tenders-table>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import LocalTendersTable from '@/views/LocalTenders/components/LocalTendersTable.vue'
import LocalTendersTableItem from '@/views/LocalTenders/components/LocalTendersTableItem.vue'

export default {
    name: 'LocalTendersList',
    components: {
        LocalTendersTableItem,
        LocalTendersTable
    },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        checkDate (timestamp) {
            const date = timestamp * 1000
            const today = moment().valueOf()
            const result = date - today
            const limit = 86400000 * 3
            return result < limit
        },
        getColor (id) {
            const colors = {
                'offers_req': '#8AD29D',
                'prices_req': '#4DA7B2',
                'reduction': '#8D81AD',
                'qualification_selection': '#654EA3',
                'preview': '#E18836',
                'quotes_req': '#0077C8',
                'quotes_req_for_SMSP': '#0077C8',
                'tenders': '#80BBE3',
                'tenders_for_SMSP': '#80BBE3'
            }

            return colors[id] ? colors[id] : '#ffffff'
        },
        adaptTOString (item) {
            if (!item.email.value && !item.phone.value) {
                return '-'
            }
            return `${item.email.value} ${item.phone.value}`
        }
    }
}
</script>

<style lang="scss">
.local-tenders-list {
    @include margin-level(bottom, XS);
}

.local-tenders-card {
    padding: $token-spacers-s $token-spacers-2-xs;
    border-top: 1px solid $token-colors-gray-20;

    @include breakpoint(v-tablet) {
        padding: $token-spacers-s 0;
    }

    &:last-child {
        border-bottom: 1px solid $token-colors-gray-20;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $token-spacers-xs;

        @include breakpoint(mobile) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__tag {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-white;
        padding: 2px 16px;
        border-radius: 20px;
        font-family: $robotoMono;
    }

    &__date-wrapper {
        margin-left: $token-spacers-xs;
        display: flex;
        align-items: center;

        @include breakpoint(mobile) {
            margin-left: 0;
            margin-top: $token-spacers-3-xs;
        }
    }

    &__date-key {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-right: $token-spacers-3-xs;
        font-family: $robotoMono;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__date-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black;
        font-family: $robotoMono;

        &--hot {
            color: $token-colors-alert-text;
        }
    }

    &__text {
        width: 100%;
        max-width: 1200px;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        color: $token-colors-gray-60;
        margin-bottom: $token-spacers-3-xs;
        font-family: $robotoMono;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }

    &__file-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: $token-spacers-xs;

        .z-link {
            @include breakpoint(mobile) {
                font-size: 14px;
            }
        }
    }

    &__icon-download {
        margin-right: $token-spacers-3-xs;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.6 2a.6.6 0 0 0-1.2 0V8.55L5.091 6.242a.6.6 0 0 0-.849.849l3.334 3.333a.6.6 0 0 0 .848 0l3.334-3.333a.6.6 0 0 0-.849-.849L8.6 8.552V2zm-6 8a.6.6 0 1 0-1.2 0v2.667A1.933 1.933 0 0 0 3.333 14.6h9.334a1.933 1.933 0 0 0 1.933-1.933V10a.6.6 0 1 0-1.2 0v2.667a.733.733 0 0 1-.733.733H3.333a.733.733 0 0 1-.733-.733V10z' fill='%230077C8'/%3E%3C/svg%3E");
    }

    &__file-info {
        padding-left: 20px;
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-gray-30;
        text-transform: uppercase;

        &:before {
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
            background-color: $token-colors-gray-30;
        }
    }

    &__contact {
        &:not(:last-child) {
            margin-bottom: $token-spacers-3-xs;
        }
    }
}
</style>
