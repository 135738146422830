import { localize } from '@/utils/i18n'

export const text = {
    name: localize({
        ru: 'ФИО',
        en: 'Full name'
    }),
    email: localize({
        ru: 'E-mail',
        en: 'E-mail'
    }),
    message: localize({
        ru: 'Вопрос',
        en: 'Question'
    }),
    button: localize({
        ru: 'Отправить',
        en: 'Send'
    }),
    notificationRequiredTextError: localize({
        ru: 'Заполните все обязательные поля',
        en: 'Fill in all required fields'
    }),
    notificationError: localize({
        ru: 'Ошибка отправки',
        en: 'Send error'
    }),
    notificationValidateError: localize({
        ru: 'Неправильный формат полей',
        en: 'Wrong field format'
    }),
    notificationSuccess: localize({
        ru: 'Отправка успешна',
        en: 'Sending successful'
    }),
    notificationCaptchaError: localize({
        ru: 'Неверный код капчи. Попробуйте снова.',
        en: 'Invalid captcha code. Try again.'
    }),
    notificationCaptchaEmpty: localize({
        ru: 'Введите код с картинки',
        en: 'Enter the code from the image*'
    }),
    policy: localize({
        ru: 'Я&nbsp;ознакомлен (а) с&nbsp;<z-link href="/files/ru/corporate_documents/policies/Politika-v-oblasti-obrabotki-personalnih-dannih-2017.pdf" target="_blank">Политикой</z-link> в&nbsp;области обработки персональных данных и&nbsp;даю <z-link href="/files/ru/investors/shareholders/agreement-shareholders-faq.pdf" target="_blank">согласие</z-link> на&nbsp;обработку своих персональных данных.',
        en: 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com, by PJSC “MMC “Norilsk Nickel”'
    })
}
