<template>
    <z-list
        class="z-filelist"
        :class="buildClass"
        :styled="false"
        tag="ul"
    >
        <slot/>
    </z-list>
</template>

<script>
export default {
    name: 'z-filelist',
    props: {
        horizontal: Boolean,
        border: Boolean,
        columns: {
            type: [Number, String],
            default: 1
        },
        theme: {
            type: String,
            validator: prop => [
                'default',
                'fancy'
            ].includes(prop),
            default: 'default'
        },
        size: {
            type: String,
            validator: prop => ['s', 'm', 'l', 'xl'].includes(prop),
            default: 'm'
        }
    },
    computed: {
        buildClass () {
            return [
                `z-filelist--size-${this.size}`,
                `z-filelist--theme-${this.theme}`,
                `z-filelist--columns-${this.columns}`,
                {
                    'z-filelist--horizontal': this.horizontal
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
