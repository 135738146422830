<template>
    <div :class="['content-container', classes]">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'content-container',
    props: {
        noGutters: {
            type: Boolean,
            default: false
        },
        noShadow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes () {
            return {
                'content-container--no-gutters': this.noGutters,
                'content-container--no-shadow': this.noShadow
            }
        }
    }
}
</script>

<style lang="scss">
.content-container {
    @include padding-level(top, XL);
    @include padding-level(bottom, XL);
    @include padding-level(left, 2XL);
    @include padding-level(right, 2XL);
    box-shadow: 0px 8px 8px rgba(0, 32, 51, 0.04), 0px 12px 28px rgba(0, 32, 51, 0.12);
    border-radius: 0px $boder-radius-l;
    background: $token-colors-white;

    @include breakpoint (mobile) {
        padding-left: 0;
        padding-right: 0;
        box-shadow: none;
        border-radius: 0;
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--no-gutters {
        padding: 0;
    }
}
</style>
