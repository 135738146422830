/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'm3': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<g clip-path="url(#clip0_7812_67524)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24.064 1a.6.6 0 00-1.2 0v1.35H11.363V1a.6.6 0 10-1.2 0v1.35H5.888a.6.6 0 00-.6.6v18.565a2.544 2.544 0 002.55 2.55h18.564a2.552 2.552 0 002.55-2.55V2.95a.6.6 0 00-.6-.6h-4.288V1zM10.163 4.913V3.551H6.488v17.964c0 .75.6 1.35 1.35 1.35h18.564c.74 0 1.35-.602 1.35-1.35V8.438H8.374a.6.6 0 010-1.2H27.75V3.551h-3.687v1.362a.6.6 0 01-1.2 0V3.551H11.363v1.362a.6.6 0 11-1.2 0zM1 6.263a.6.6 0 00-.6.6V26.4c0 1.4 1.15 2.55 2.55 2.55h18.564c1.4 0 2.55-1.15 2.55-2.55V25.33a.6.6 0 00-1.2 0v1.073a1.36 1.36 0 01-1.35 1.35H2.95a1.36 1.36 0 01-1.35-1.35v-14.05h2.423a.6.6 0 000-1.2H1.6v-3.69h2.423a.6.6 0 000-1.2H1zm7.812 6.088h1.95v-1.2h-1.95v1.2zm6.839 0H13.7v-1.2h1.95v1.2zm2.925 0h1.963v-1.2h-1.963v1.2zm1.963 3.9h-1.963v-1.2h1.963v1.2zm-1.963 3.914h1.963v-1.2h-1.963v1.2zm6.838-7.814h-1.95v-1.2h1.95v1.2zm-1.95 3.9h1.95v-1.2h-1.95v1.2zm1.95 3.914h-1.95v-1.2h1.95v1.2zM8.812 16.25h1.95v-1.2h-1.95v1.2zm6.839 0H13.7v-1.2h1.95v1.2zm-6.839 3.914h1.95v-1.2h-1.95v1.2zm6.839 0H13.7v-1.2h1.95v1.2z" _fill="#1A1B25"/></g><defs><clipPath id="clip0_7812_67524"><path pid="1" _fill="#fff" d="M0 0h29v29H0z"/></clipPath></defs>'
  }
})
