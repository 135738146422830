<template>
    <div class="equipment-filter" v-if="filtersData">
        <div class="equipment-filter__switcher equipment-filter__switcher--opened">
            <z-caption uppercase decor-right size="m" weight="700">Выбор параметров</z-caption>
        </div>
        <div class="equipment-filter__body">
            <div class="row">
                <div class="col-default-6 col-v-tablet-12">
                    <span class="equipment-filter__title">Поиск</span>
                    <z-input name="search" icon-name="magnifier"
                             placeholder="Введите ключевое слово" v-model="filters.q"/>
                </div>
                <div class="col-default-6 col-v-tablet-12">
                    <span class="equipment-filter__title">Порядок реализации</span>
                    <z-select-multi
                        v-model="filters.order"
                        with-search
                        :data="filtersData.order"
                        placeholder="Все"
                        :is-disabled="isDisabled(filtersData.order)"
                    />
                </div>
            </div>
            <div class="equipment-filter__reset-wrapper">
                <button
                    class="equipment-filter__tag equipment-filter__tag--button"
                    :disabled="!haveParams"
                    @click="reset"
                >
                    Сбросить фильтры
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {queryString} from '@/utils/queryString'
import {clearEmptyParams} from '@/utils/clearEmptyParams'

export default {
    name: 'EquipmentFilter',
    props: {
        filtersData: {
            type: Object
        }
    },
    data () {
        return {
            filterState: false,
            filterName: 'filters-equipment',
            filters: {
                order: [],
                q: ''
            }
        }
    },
    computed: {
        haveParams () {
            return Object.keys(clearEmptyParams(this.filters)).length
        }
    },
    created () {
        this.init()
    },
    methods: {
        reset () {
            this.filters.q = ''
            this.filters.order = []
        },
        toggleFilter () {
            this.filterState = !this.filterState
        },
        setFilters () {
            localStorage.setItem(this.filterName, JSON.stringify(this.filters))
        },
        getFilters () {
            return JSON.parse(localStorage.getItem(this.filterName))
        },
        deleteFilters () {
            localStorage.removeItem(this.filterName)
        },
        isDisabled (data) {
            return data.length <= 1
        },
        checkParams (params) {
            return Boolean(params.get('q') ||
                params.getAll('order[]').length
            )
        },
        init () {
            const params = new URL(document.location).searchParams
            const haveParams = this.checkParams(params)
            this.filters.q = params.get('q') ? params.get('q') : ''
            this.filters.order = params.getAll('order[]').length ? params.getAll('order[]') : []

            if (haveParams) {
                this.setFilters()
            } else if (localStorage.getItem(this.filterName)) {
                this.filters = this.getFilters()
                queryString(clearEmptyParams(this.filters))
            }

            this.$watch('filters', function () {
                queryString(clearEmptyParams(this.filters))
                if (this.haveParams) {
                    this.setFilters()
                } else {
                    this.deleteFilters()
                }
                this.$emit('update-filters', this.filters)
            }, {deep: true})
        }
    }
}
</script>

<style lang="scss">
.equipment-filter {
    $parent: &;
    margin-bottom: $token-spacers-l;

    &__switcher {
        padding: 0;
        width: 100%;
        background: none;
        border: none;
        position: relative;
        margin: 0;

        .z-caption {
            margin-bottom: 0;
        }

        &--opened {
            #{$parent}__icon {
                transform: rotate(0);
            }
        }

        .z-caption {
            &--decor-right {
                &:after {
                    @include breakpoint(mobile) {
                        display: block !important;
                    }
                }
            }
        }
    }

    &__body {
        margin-top: $token-spacers-xs;
    }

    &__tag {
        position: absolute;
        right: 0;
        top: 0;
        border: 1px solid $token-colors-gray-60;
        border-radius: 40px;
        padding: 6px 46px 6px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        color: $token-colors-gray-60;
        background-color: $token-colors-white;
        box-shadow: -16px 0 0 rgba(255, 255, 255, 1);

        @include breakpoint(v-tablet) {
            padding: 0;
            border: none;
        }

        &--button {
            position: relative;
            top: 0;
            padding: 6px 20px;
            box-shadow: 0 0 0 rgba(255, 255, 255, 0);

            &:disabled {
                opacity: 0.6;
            }

            @include breakpoint(v-tablet) {
                border: 1px solid $token-colors-gray-60;
            }
        }
    }

    &__reset-wrapper {
        margin-top: $token-spacers-xs;
        display: flex;
        justify-content: flex-end;
    }

    &__icon {
        position: absolute;
        top: -1px;
        right: -1px;
        border: 1px solid $token-colors-gray-60;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        transition: transform 300ms ease;
        background-color: $token-colors-white;

        .z-icon {
            path {
                fill: $token-colors-gray-60;
            }
        }
    }

    &__title {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.4;
        color: $token-colors-gray-50;
        margin-bottom: $token-spacers-3-xs;
    }

    &__container {
        display: flex;
        gap: $token-spacers-s;

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }
    }

    &__date-container {
        width: 100%;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        color: $token-colors-gray-35;

        .z-date-picker {
            width: 100%;

            &:first-child {
                margin-right: $token-spacers-3-xs;
            }

            &:last-child {
                margin-left: $token-spacers-3-xs;
            }
        }

        @include breakpoint(mobile) {
            flex-direction: column;

            .z-date-picker {
                &:first-child {
                    margin-right: 0;
                }

                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }

    &__select {
        width: 100%;
    }
}

</style>
