/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pause_bold': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.5 4.877a1.25 1.25 0 10-2.5 0v24.935a1.25 1.25 0 102.5 0V4.877zm12 0a1.25 1.25 0 10-2.5 0v24.935a1.25 1.25 0 102.5 0V4.877z" _fill="#0290F0"/>'
  }
})
