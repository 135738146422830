<template>
    <div class="welcome-screen content-container">
        <z-caption
            size="5xl"
            class="welcome-screen__title">
            <span>Регистрация</span>
        </z-caption>
        <form
            @submit.prevent="submitForm"
            class="welcome-screen__form">
            <z-caption
                size="m"
                weight="700"
                class="welcome-screen__form-title"
                uppercase
                decor-left
                decor-right>
                <span>Сведения о&nbsp;контрагенте:</span>
            </z-caption>
            <div class="welcome-screen__form-top">
                <z-input
                    v-model="formData.name"
                    @input="hideErrorMsg"
                    class="welcome-screen__form-input"
                    type="text"
                    ref="name"
                    required
                    name="name"
                    placeholder="Полное наименование организации" />
                <z-input
                    v-model="formData.inn"
                    @input="hideErrorMsg"
                    class="welcome-screen__form-input"
                    type="number"
                    ref="inn"
                    required
                    name="inn"
                    placeholder="ИНН" />
            </div>
            <z-caption
                size="m"
                weight="700"
                class="welcome-screen__form-title"
                uppercase
                decor-left
                decor-right>
                <span>Организация Группы компаний &laquo;Норильский никель&raquo;</span>
            </z-caption>
            <div class="welcome-screen__form-bottom">
                <z-select
                    @change="changeOrganizationNameHandler"
                    @input="hideErrorMsg"
                    required
                    ref="organizationName"
                    :data="companiesList"
                    with-search
                    placeholder="Выберите организацию из списка" />
            </div>
            <z-button
                size="l"
                kind="primary"
                class="welcome-screen__form-btn">
                <span>Начать просмотр</span>
            </z-button>
        </form>
        <p
            v-if="isCertificateError"
            class="welcome-screen__error alert-text-text">
            Регистрация невозможна, так как контрагент с&nbsp;такими данными уже зарегистрирован.
            Пожалуйста, обратитесь на&nbsp;<z-link @click="handleErrorEmailClick" href="mailto: anticorruption@nornik.ru">anticorruption@nornik.ru</z-link>
            для получения дополнительной информации или поддержки.
        </p>
        <p
            v-else-if="errorMsg"
            class="welcome-screen__error alert-text-text"
            v-html="errorMsg"></p>
        <div class="welcome-screen__info">
            <p>
                Обращаем ваше внимание, что для целей ознакомления с&nbsp;принципами и&nbsp;требованиями в&nbsp;области противодействия коррупции Группы компаний <z-tooltip :content="tooltipContent" isTriggerAligned><span>&laquo;Норильский никель&raquo;<span style="position: absolute;margin-left: 22px;">,</span></span></z-tooltip>&ensp;необходимо заполнить все поля.
            </p>
            <!-- <p>
                В&nbsp;случае, если вы&nbsp;привлекаете третьих лиц (подрядчиков, агентов, представителей, посредников и&nbsp;т.д.) для целей исполнения обязательств по&nbsp;договору с&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или с&nbsp;организацией корпоративной структуры, входящей в&nbsp;Группу компаний <z-tooltip :content="tooltipContent" isTriggerAligned><span>&laquo;Норильский никель&raquo;<span style="position: absolute;margin-left: 22px;">,</span></span></z-tooltip>&ensp;просьба направить ссылку для ознакомления с&nbsp;данным материалом третьему лицу.
            </p> -->
        </div>
    </div>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { checkCertificate } from '@/api/anti-corruption-form.js'
import handleYmGoal from '../utils/analytics.js'
import list from '../utils/companies-list.js'

export default {
    name: 'welcome-screen',
    data () {
        return {
            isLoading: false,
            isCertificateError: false,
            errorMsg: '',
            formData: {
                form: 'anti-corruption-form',
                name: '',
                inn: '',
                organizationName: ''
            },
            errorsText: {
                notification: 'Ошибка отправки'
            },
            companiesList: list,
            tooltipContent: 'Под Группой компаний &laquo;Норильский никель&raquo; следует понимать ПАО &laquo;ГМК &laquo;Норильский никель&raquo; и&nbsp;российские организации корпоративной структуры, входящие в&nbsp;Группу компаний &laquo;Норильский никель&raquo;. В&nbsp;тексте используются термины &laquo;Компания&raquo; и&nbsp;&laquo;Норникель&raquo;, которые обозначают Группу компаний &laquo;Норильский никель&raquo;.'
        }
    },
    methods: {
        validateRequiredInputs () {
            const requiredFields = ['name', 'inn', 'organizationName']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        async submitForm () {
            if (this.isLoading) {
                return
            }

            handleYmGoal('click-presentation-start')
            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                try {
                    this.isLoading = true

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }

                    const response = await checkCertificate(data)
                    if (response.status === 'success') {
                        this.$emit(
                            'startPresentation',
                            this.formData.name,
                            this.formData.inn,
                            this.formData.organizationName,
                            response.pdf
                        )
                        this.formData.name = ''
                        this.formData.inn = ''
                        this.formData.organizationName = ''
                    } else {
                        if (
                            response.message ===
                            'Невозможно сгенерировать новый сертификат для текущего ИНН, так как по данному ИНН уже был выдан сертификат. Пожалуйста, обратитесь на указанный email для получения дополнительной информации или поддержки'
                        ) {
                            this.isCertificateError = true
                        } else {
                            this.errorMsg = this.errorsText.notification
                            showNotyfications(response.message, { type: 'error' })
                        }

                        handleYmGoal('presentation-show-error')
                    }
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.errorsText.notification, { type: 'error' })
                    handleYmGoal('presentation-show-error')
                } finally {
                    this.isLoading = false
                }
            } else {
                handleYmGoal('presentation-show-error')
            }
        },
        hideErrorMsg () {
            this.isCertificateError = false
            this.errorMsg = ''
        },
        handleErrorEmailClick () {
            handleYmGoal('click-email-error')
        },
        changeOrganizationNameHandler (id) {
            const organizationObject = this.companiesList.find(
                (item) => item.id === id
            )
            this.formData.organizationName = organizationObject?.text
        }
    }
}
</script>

<style scoped lang="scss">
.welcome-screen {
    background-image: url('/images/suppliers/presentation/presentation-bg.jpg');
    background-position: 100% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 148px !important;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #e3e3e3;
        opacity: 0.75;
        z-index: 0;
        mix-blend-mode: color-dodge;
    }

    & > * {
        z-index: 1;
    }

    &__title {
        @include margin-level(bottom, M);
        color: $token-colors-gray-35 !important;
    }

    &__form {
        z-index: 2;
        display: flex;
        flex-direction: column;
        background-color: $token-colors-white;
        border-radius: 20px 20px 20px 0px;
        @include padding-level(left, M);
        @include padding-level(right, M);
        @include padding-level(top, XS);
        @include padding-level(bottom, XS);
    }

    &__form-title {
        @include margin-level(bottom, S);
    }

    &__form-top {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        @include margin-level(bottom, M);

        @include breakpoint(tablet) {
            flex-direction: column;
        }
    }
    &__form-bottom {
        @include margin-level(bottom, S);

        .z-select-wrapper .z-select .z-select__placeholder {
            max-width: calc(100% - 55px);
            font-size: 0.7em;
        }
    }

    &__form-input {
        min-width: 280px;
        max-width: 50%;

        @include breakpoint(laptop) {
            max-width: 100%;
        }

        @include breakpoint(v-tablet) {
            min-width: unset;
        }
    }

    &__form-btn {
        flex-shrink: 0;
        max-width: 255px;

        @include breakpoint(v-tablet) {
            max-width: 100%;
        }
    }

    &__error {
        @include margin-level(top, S);
        @include typo-level(M);
        margin-bottom: -24px;
    }
    &__form,
    &__error,
    &__info {
        width: 64%;

        @include breakpoint(laptop) {
            width: 100%;
        }
    }

    &__info {
        @include typo-level(L);
        font-weight: 400 !important;
        @include margin-level(top, M);

        p {
            @include margin-level(bottom, 3XS);
        }
    }
}
</style>
