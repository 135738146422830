import { localize } from '@/utils/i18n'

export const text = {
    commodityFormCompany: localize({
        ru: 'Компания',
        en: 'Company'
    }),
    commodityFormName: localize({
        ru: 'ФИО*',
        en: 'Full name*'
    }),
    commodityFormEmail: localize({
        ru: 'E-mail*',
        en: 'Business E-mail*'
    }),
    button: localize({
        ru: 'Отправить',
        en: 'Send'
    }),
    notificationRequiredTextError: localize({
        ru: 'Заполните все обязательные поля',
        en: 'Fill in all required fields'
    }),
    notificationError: localize({
        ru: 'Ошибка отправки',
        en: 'Send error'
    }),
    notificationValidateError: localize({
        ru: 'Неправильный формат полей',
        en: 'Wrong field format'
    }),
    notificationSuccess: localize({
        ru: 'Отправка успешна',
        en: 'Sending successful'
    }),
    notificationCaptchaError: localize({
        ru: 'Неверный код капчи. Попробуйте снова.',
        en: 'Invalid captcha code. Try again.'
    }),
    notificationCaptchaEmpty: localize({
        ru: 'Введите код с картинки*',
        en: 'Enter the code from the image*'
    }),
    policy: localize({
        ru: 'Заполняя поля данной формы Вы добровольно передаете свои персональные данные (имя, фамилия, адрес электронной почты) ПАО «ГМК «Норильский никель».',
        en: 'By filling this form you agree to transfer your personal data (name, surname, email address) to PJSC MMC Norilsk Nickel.»'
    }),
    warning: {
        start: localize({
            ru: 'Эти данные собираются в целях обратной связи с Вами для предоставления аналитической информации, предоставляемой Компанией. Данные будут использоваться до получения от Вас сообщения об отзыве согласия на обработку данных. Порядок обработки персональных данных, включающий в том числе порядок отзыва согласия на обработку персональных данных, размещен в разделе «Персональные данные» на странице',
            en: 'This personal data is collected with the purpose to provide you analytical information of the Company. Personal data will be used until you give your consent to the processing of your personal data. The procedure of the personal data processing, including the procedure of revocation the consent to the processing of personal data, is placed in the section "Personal data" on the page'
        }),
        link: localize({
            ru: '«Условия использования»',
            en: '"Terms, conditions and policies"'
        }),
        end: localize({
            ru: 'официального сайта Компании.',
            en: 'of the official website of the Company.'
        }),
        agrees: {
            presonalData: {
                start: localize({
                    ru: 'Подтверждаю, что ознакомлен (ознакомлена) с порядком обработки персональных данных ПАО «ГМК «Норильский никель» в разделе «Персональные данные» на странице',
                    en: 'I confirm that I read the section "Personal data" on the page'
                }),
                end: localize({
                    ru: 'официального сайта Компании.',
                    en: 'about the procedure of the personal data processing by PJSC MMC Norilsk Nickel.'
                })
            },
            transfer: localize({
                ru: 'Согласен (согласна) передать мои персональные данные ПАО «ГМК «Норильский никель» для получения аналитической информации по электронной почте.',
                en: 'I agree to transfer my personal data to PJSC MMC Norilsk Nickel to receive analytical information by my email.'
            })
        }
    }
}
