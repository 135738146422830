/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_9226_31769)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.928 2.826a9.4 9.4 0 015.898.582.6.6 0 00.488-1.096 10.6 10.6 0 106.286 9.689v-.92a.6.6 0 10-1.2 0V12A9.4 9.4 0 119.928 2.826zm12.496 1.598a.6.6 0 10-.849-.848L12 13.162l-2.576-2.576a.6.6 0 00-.848.849l3 3a.6.6 0 00.848 0l10-10.01z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_31769"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
