/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/jpg': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM18.08 24.468c0 1.824-1.152 2.676-2.7 2.676-.828 0-1.56-.24-2.124-.84l.648-1.068c.324.348.756.66 1.368.66.864 0 1.404-.576 1.404-1.452v-5.448h1.404v5.472zm3.114-.456V27H19.79v-8.004h3.515c1.68 0 2.617 1.152 2.617 2.508s-.948 2.508-2.617 2.508h-2.111zm0-1.236h1.92c.791 0 1.367-.504 1.367-1.272s-.576-1.272-1.368-1.272h-1.92v2.544zm13.036 2.928c-.792.876-1.908 1.452-3.3 1.452-2.316 0-4.2-1.644-4.2-4.152s1.884-4.14 4.2-4.14c1.56 0 2.58.744 3.228 1.668l-1.152.648a2.548 2.548 0 00-2.076-1.068c-1.584 0-2.76 1.212-2.76 2.892s1.176 2.904 2.76 2.904c.816 0 1.536-.384 1.896-.72V23.94h-2.388v-1.236h3.792v3z" _fill="#737B91"/>'
  }
})
