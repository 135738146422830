/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-small/usd': {
    width: 50,
    height: 60,
    viewBox: '0 0 50 60',
    data: '<path pid="0" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="1" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="2" d="M13.71 35.192c-2.897 0-4.353-1.712-4.353-4.336v-6.528h1.328v6.496c0 1.952 1.056 3.184 3.024 3.184s3.024-1.232 3.024-3.184v-6.496h1.328v6.512c0 2.656-1.456 4.352-4.352 4.352zm10.306 0c-1.855 0-3.215-.704-4.095-1.696l.784-1.008c.704.784 1.856 1.52 3.36 1.52 1.903 0 2.511-1.024 2.511-1.84 0-2.752-6.32-1.232-6.32-5.04 0-1.76 1.569-2.96 3.665-2.96 1.631 0 2.896.544 3.791 1.488l-.8.976c-.8-.88-1.92-1.28-3.087-1.28-1.28 0-2.192.688-2.192 1.696 0 2.4 6.32 1.024 6.32 5.008 0 1.552-1.056 3.136-3.936 3.136zM33.584 35h-3.649V24.328h3.649c3.311 0 5.471 2.32 5.471 5.344 0 3.04-2.16 5.328-5.471 5.328zm0-1.184c2.591 0 4.095-1.856 4.095-4.144 0-2.304-1.456-4.16-4.096-4.16h-2.32v8.304h2.32z" _fill="#0077C8"/>'
  }
})
