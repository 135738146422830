/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play_bold': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.582 4.649C8.25 3.793 6.5 4.749 6.5 6.331v23.337c0 1.582 1.75 2.538 3.082 1.682l18.15-11.668a2 2 0 000-3.365L9.582 4.65zM8.5 6.331L26.65 18 8.5 29.668V6.331z" _fill="#0290F0"/>'
  }
})
