<template>
    <div class="remind">
        <tippy-tooltip
            @show="onShow"
            trigger="click"
            :placement="placement"
            :interactive="true"
            theme="light"
        >
            <template v-slot:trigger>
                <slot></slot>
            </template>
            <z-link
                v-for="(item, index) in data"
                :href="item.link"
                :key="index"
                :target="item.type === 'google' ? '_blank' : false"
                :download="item.type !== 'google' ? item.type : ''"
            >
                <template #left>
                    <z-icon
                        :name="item.type"
                        width="24"
                        height="24"
                    />
                </template>
                <span class="u-bold" v-html="item.type"></span>
            </z-link>
        </tippy-tooltip>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'remind',
    mixins: [mixinDevice],
    props: {
        data: [Array, Object],
        placement: {
            type: String,
            default: 'left'
        }
    },
    methods: {
        onShow (tippy) {
            tippy.popper.classList.add('remind-popup')
        }
    }
}
</script>

<style lang="scss">
.remind {

    &-popup {

        .z-icon {
            margin-right: .25rem;
        }

        .z-link {
            display: block;
            text-transform: capitalize;
            text-align: left;

            &+.z-link {
                margin-top: $token-spacers-3-xs;
            }
        }
    }
}
</style>
