<template>
    <div class="row" v-if="data.length">
        <div
            class="col-default-4 col-v-tablet-6 col-mobile-12"
            v-for="(item, index) in data"
            :key="index"
        >
            <announce
                :image="item.previewPicture.link"
                :date="item.activeFrom"
                class="u-bottom-margin--xs"
            />
            <z-caption size="xl" tag="p">
                <z-link class="link" :href="item.detailPageUrl">
                    <vue-raw :raw="item.shortTitle.value ? item.shortTitle.value : item.name"></vue-raw>
                </z-link>
            </z-caption>
        </div>
    </div>
</template>

<script>
export default {
    name: 'results',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="scss" scoped>
.link {
    color: $token-colors-gray-60;
    transition: color $transition;
    
    &:hover {
        color: $token-colors-link-dark-hover;
    }

    &:active {
        color :$token-colors-link-dark-active;
    }
}
</style>