import moment from 'moment'
import { mapState } from 'vuex'
import { deviceEnquire, DeviceType } from '@/utils/device'

const mixinDevice = {
    computed: {
        ...mapState({
            device: state => state.app.device
        })
    },
    methods: {
        isMobile () {
            return this.device === DeviceType.mobile
        },
        isVTablet () {
            return this.device === DeviceType.vtablet
        },
        isTablet () {
            return this.device === DeviceType.tablet
        },
        isLaptop () {
            return this.device === DeviceType.laptop
        },
        isDesktop () {
            return this.device === DeviceType.desktop
        }
    }
}

const AppDeviceEnquire = {
    mounted () {
        const { $store } = this
        deviceEnquire(deviceType => {
            switch (deviceType) {
            case DeviceType.desktop:
                $store.commit('ToggleDevice', 'desktop')
                break
            case DeviceType.laptop:
                $store.commit('ToggleDevice', 'laptop')
                break
            case DeviceType.tablet:
                $store.commit('ToggleDevice', 'tablet')
                break
            case DeviceType.vtablet:
                $store.commit('ToggleDevice', 'v-tablet')
                break
            case DeviceType.mobile:
            default:
                $store.commit('ToggleDevice', 'mobile')
                break
            }
        })
    }
}

const calendarFormatDate = {
    methods: {
        formatedDate (date, showYear = true) {
            moment.locale(this.$root.lang, {
                months: this.$root.lang === 'ru'
                    ? ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']
                    : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            })

            let start = {
                day: this.fixTimezone(date.start).format('D'),
                year: this.fixTimezone(date.start).format('YYYY'),
                month: this.fixTimezone(date.start).format('MMMM'),
                full: this.fixTimezone(date.start).format('D MMMM YYYY')
            }

            let end = {
                day: this.fixTimezone(date.end).format('D'),
                year: this.fixTimezone(date.end).format('YYYY'),
                month: this.fixTimezone(date.end).format('MMMM'),
                full: this.fixTimezone(date.end).format('D MMMM YYYY')
            }

            if (start.full === end.full) {
                return showYear ? `${start.day} ${start.month} ${start.year}` : `${start.day} ${start.month}`
            }
            if (start.full !== end.full) {
                if (start.year === end.year) {
                    if (start.month === end.month) {
                        if (start.day === end.day) {
                            return showYear ? `${start.day} ${start.month} ${start.year}` : `${start.day} ${start.month}`
                        } else {
                            return showYear ? `${start.day} – ${end.day} ${start.month} ${start.year}` : `${start.day} – ${end.day} ${start.month}`
                        }
                    }
                    if (start.month !== end.month) {
                        return showYear ? `${start.day} ${start.month} – ${end.day} ${end.month} ${start.year}` : `${start.day} ${start.month} – ${end.day} ${end.month}`
                    }
                } else {
                    return `${start.full} – ${end.full}`
                }
            }
        },
        fixTimezone (date) {
            return moment.unix(date).utc().add(3, 'hours')
        }
    }
}

export { mixinDevice, AppDeviceEnquire, calendarFormatDate }
