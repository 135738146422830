<template>
    <div>
        <z-caption tag="h2" size="2xl" class="u-bottom-margin--m-important" weight="600" v-html="text.lead">            
        </z-caption>
        <z-tabs>
            <template v-slot:label>
                <z-tabs-label id="1" v-html="text.north"></z-tabs-label>
                <z-tabs-label id="2" v-html="text.arctic"></z-tabs-label>
                <!-- <z-tabs-label id="3" v-html="text.headquarter"></z-tabs-label> -->
            </template>
            <template v-slot:content>
                <z-tabs-content id="1">
                    <div class="beginning__content">
                        <z-caption tag="p" icon="pin-nn" weight="600" size="l" class="u-bottom-margin--xs-important"><span v-html="text.enterprise"></span></z-caption>
                        <z-caption tag="h2" size="3xl" weight="600" decor-right v-html="text.northWho"></z-caption>
                        <div class="row u-bottom-margin--xl">
                            <div class="col-default-4 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered><span  v-html="text.northStudents"></span></icon-block>
                            </div>
                            <div class="col-default-4 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered><span v-html="text.northExcellent"></span></icon-block>
                            </div>
                            <div class="col-default-4 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered><span v-html="text.northSubjects"></span></icon-block>
                            </div>
                        </div>
                        <z-figure class="u-bottom-margin--xl" image="/images/career/begining/dudinsky-port-1.jpg"
                                  corner-radius="left">
                            <template #caption>
                                <div class="beginning__fig-caption">
                                    <z-caption class="u-bottom-margin--xs" tag="h3" decor-left decor-right uppercase
                                               size="m" weight="700" v-html="text.conditions"></z-caption>
                                    <div class="row">
                                        <div class="col-default-3 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/union.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.northPaidInternship"></span>
                                            </icon-block>
                                        </div>
                                        <div class="col-default-3 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/calendar.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.northDuration"></span>
                                            </icon-block>
                                        </div>
                                        <div class="col-default-3 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/armchair.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.employment"></span>
                                            </icon-block>
                                        </div>
                                        <div class="col-default-3 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/shields.svg" size="xs" centered>
                                            <span class="beginning__block-text" v-html="text.northCities"></span>
                                            </icon-block>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </z-figure>
                        <z-caption class="u-bottom-margin--s-important" tag="h2" decor-right size="2xl" weight="600" v-html="text.northReceive"></z-caption>
                        <div class="row u-bottom-margin--2xl">
                            <div class="col-default-4 col-v-tablet-12">
                                <z-caption class="beginning__get-title u-bottom-margin--3xs-important" tag="h4" size="2xl"
                                           weight="600" v-html="text.northCasesTitle"></z-caption>
                                <z-caption tag="p" size="m" v-html="text.northCases"></z-caption>
                            </div>
                            <div class="col-default-4 col-v-tablet-12">
                                <z-caption class="beginning__get-title u-bottom-margin--3xs-important" tag="h4" size="2xl"
                                           weight="600" v-html="text.northScholarshipTitle"></z-caption>
                                <z-caption tag="p" size="m" v-html="text.northScholarship"></z-caption>
                            </div>
                            <div class="col-default-4 col-v-tablet-12">
                                <z-caption class="beginning__get-title u-bottom-margin--3xs-important" tag="h4" size="2xl"
                                           weight="600" v-html="text.northExperienceTitle">
                                </z-caption>
                                <z-caption tag="p" size="m" v-html="text.northExperience"></z-caption>
                            </div>
                        </div>
                        <!-- <div class="beginning__selected-block">
                            <z-caption class="u-bottom-margin--m-important" tag="h3" uppercase decor-right decor-left
                                       size="s" weight="600" v-html="text.howToGet"></z-caption>
                            <z-button tag="a" href="https://hr2.nornik.ru/ispecialist/resume/" class="u-bottom-margin--xs" v-html="text.sendResume">                                
                                <template v-slot:right>
                                    <z-icon name="arrow-long" dir="right" width="18" height="18"/>
                                </template>
                            </z-button>
                            <z-link class="u-left-margin--2xs" theme="grey" underline href="https://hr2.nornik.ru/">
                                HR2.NORNIK.RU
                            </z-link>
                        </div> -->
                    </div>
                </z-tabs-content>
                <z-tabs-content id="2">
                    <div class="beginning__content">
                        <z-caption tag="p" icon="pin-nn" weight="600" size="l" class="u-bottom-margin--xs-important"><span v-html="text.enterprise"></span></z-caption>
                        <z-caption tag="h2" size="3xl" weight="600" decor-right v-html="text.arcticWho"></z-caption>
                        <div class="row u-bottom-margin--xl">
                            <div class="col-default-6 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered><span v-html="text.arcticGraduates"></span>
                                </icon-block>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered><span v-html="text.arcticBeginners"></span></icon-block>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered><span v-html="text.arcticExcellent"></span></icon-block>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered><span v-html="text.arcticSpecialty"></span></icon-block>
                            </div>
                        </div>
                        <z-figure class="u-bottom-margin--xl" image="/images/career/begining/arctic.jpg"
                                  corner-radius="left">
                            <template #caption>
                                <div class="beginning__fig-caption">
                                    <z-caption class="u-bottom-margin--xs" tag="h3" decor-left decor-right uppercase
                                               size="m" weight="700" v-html="text.conditions">Условия работы для участников программы
                                    </z-caption>
                                    <div class="row">
                                        <div class="col-default-4 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/armchair.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.employment"></span>
                                            </icon-block>
                                        </div>
                                        <div class="col-default-4 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/calendar.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.arcticDuration"></span>
                                            </icon-block>
                                        </div>
                                        <div class="col-default-4 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/shields.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.arcticRelocation"></span>
                                            </icon-block>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </z-figure>
                        <z-caption class="u-bottom-margin--s-important" tag="h2" decor-right size="2xl" weight="600" v-html="text.arcticOffer"></z-caption>
                        <div class="row u-bottom-margin--2xl">
                            <div class="col-default-4 col-v-tablet-12">
                                <z-caption class="beginning__get-title u-bottom-margin--3xs-important" tag="h4" size="2xl"
                                           weight="600" v-html="text.arcticPersonalTitle"></z-caption>
                                <z-caption tag="p" size="m" v-html="text.arcticPersonal"></z-caption>
                            </div>
                            <div class="col-default-4 col-v-tablet-12">
                                <z-caption class="beginning__get-title u-bottom-margin--3xs-important" tag="h4" size="2xl"
                                           weight="600" v-html="text.arcticKnowledgeTitle"></z-caption>
                                <z-caption tag="p" size="m" v-html="text.arcticKnowledge"></z-caption>
                            </div>
                            <div class="col-default-4 col-v-tablet-12">
                                <z-caption class="beginning__get-title u-bottom-margin--3xs-important" tag="h4" size="2xl"
                                           weight="600" v-html="text.arcticMentoringTitle"></z-caption>
                                <z-caption tag="p" size="m" v-html="text.arcticMentoring"></z-caption>
                            </div>
                            <div class="col-default-4 col-v-tablet-12">
                                <z-caption class="beginning__get-title u-bottom-margin--3xs-important" tag="h4" size="2xl"
                                           weight="600" v-html="text.arcticWageTitle"></z-caption>
                                <z-caption tag="p" size="m" v-html="text.arcticWage"></z-caption>
                            </div>
                            <div class="col-default-4 col-v-tablet-12">
                                <z-caption class="beginning__get-title u-bottom-margin--3xs-important" tag="h4" size="2xl"
                                           weight="600" v-html="text.arcticLeadingTitle"></z-caption>
                                <z-caption tag="p" size="m" v-html="text.arcticLeading">
                                </z-caption>
                            </div>
                        </div>
                        <!-- <div class="beginning__selected-block">
                            <z-caption class="u-bottom-margin--m-important" tag="h3" uppercase decor-right decor-left
                                       size="s" weight="600" v-html="text.howToGet"></z-caption>
                            <z-button tag="a" href="https://hr2.nornik.ru/ispecialist/resume/" class="u-bottom-margin--xs" v-html="text.sendResume"><template v-slot:right>
                                    <z-icon name="arrow-long" dir="right" width="18" height="18"/>
                                </template>
                            </z-button>
                            <z-link class="u-left-margin--2xs" theme="grey" underline href="https://hr2.nornik.ru/">
                                HR2.NORNIK.RU
                            </z-link>
                        </div> -->
                    </div>
                </z-tabs-content>
                <!-- <z-tabs-content id="3">
                    <div class="beginning__content">
                        <z-caption tag="p" icon="pin-nn" weight="600" size="l" class="u-bottom-margin--xs-important"><span v-html="text.moscow"></span>
                        </z-caption>
                        <z-caption tag="h2" size="3xl" weight="600" decor-right v-html="text.moscowTitle"></z-caption>
                        <div class="beginning__selected-block u-bottom-margin--m-important">
                            <z-caption tag="p" size="xl" weight="400" v-html="text.moscowLead"></z-caption>
                        </div>
                        <z-caption tag="h2" size="3xl" weight="600" decor-right v-html="text.moscowWho"></z-caption>
                        <div class="row u-bottom-margin--xl">
                            <div class="col-default-6 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered>
                                    <span v-html="text.moscowStudents"></span>
                                </icon-block>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered>
                                    <span v-html="text.moscowExcellent"></span>
                                </icon-block>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered>
                                    <span v-html="text.moscowSubjects"></span>
                                </icon-block>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <icon-block icon-name="check-circle" size="xs" centered>
                                    <span v-html="text.moscowEnglish"></span>
                                </icon-block>
                            </div>
                        </div>
                        <z-figure class="u-bottom-margin--xl" image="/images/career/begining/moscow-lecture.jpg"
                                  corner-radius="left">
                            <template #caption>
                                <div class="beginning__fig-caption">
                                    <z-caption class="u-bottom-margin--xs" tag="h3" decor-left decor-right uppercase
                                               size="m" weight="700" v-html="text.conditions"></z-caption>
                                    <div class="row">
                                        <div class="col-default-3 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/union.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.moscowPaid"></span>
                                            </icon-block>
                                        </div>
                                        <div class="col-default-3 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/calendar.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.moscowDuration"></span>
                                            </icon-block>
                                        </div>
                                        <div class="col-default-3 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/clock.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.moscowHours"></span>
                                            </icon-block>
                                        </div>
                                        <div class="col-default-3 col-desktop-6 col-mobile-12">
                                            <icon-block icon-url="/images/career/begining/armchair.svg" size="xs" centered>
                                                <span class="beginning__block-text" v-html="text.employment"></span>
                                            </icon-block>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </z-figure>
                        <div class="beginning__selected-block">
                            <z-caption class="u-bottom-margin--m-important" tag="h3" uppercase decor-right decor-left
                                       size="s" weight="600" v-html="text.howToGet"></z-caption>
                            <z-button tag="a" href="https://hr2.nornik.ru/ispecialist/resume/" class="u-bottom-margin--xs" v-html="text.sendResume">
                                <template v-slot:right>
                                    <z-icon name="arrow-long" dir="right" width="18" height="18"/>
                                </template>
                            </z-button>
                            <z-link class="u-left-margin--2xs" theme="grey" underline href="https://hr2.nornik.ru/">
                                HR2.NORNIK.RU
                            </z-link>
                        </div>
                    </div>
                </z-tabs-content> -->
            </template>
        </z-tabs>
    </div>

</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'Beginning',
    data () {
        return {
            text: {
                lead: localize({
                    ru: 'Начать карьеру в&nbsp;компании можно с&nbsp;одной из&nbsp;программ для студентов, выпускников и&nbsp;молодых специалистов:',
                    en: 'You can begin your career in&nbsp;the company with one of&nbsp;our programs for students, graduates and young professionals:'
                }),
                north: localize({
                    ru: '&laquo;Покорители Севера&raquo; Программа производственной практики',
                    en: '“Conquerors of&nbsp;the North” Program of&nbsp;industrial practice'
                }),
                arctic: localize({
                    ru: 'Лидерская программа Первая Арктическая',
                    en: 'Leadership Program “First Arctic”'
                }),
                headquarter: localize({
                    ru: 'Стажерская программа главного офиса &laquo;Норникеля&raquo;',
                    en: '“Nornickel” headquarters internship program'
                }),
                enterprise: localize({
                    ru: 'На&nbsp;предприятиях',
                    en: 'In&nbsp;enterprise'
                }),
                northWho: localize({
                    ru: 'Кто может попасть на&nbsp;&laquo;Покорители Севера&raquo;?',
                    en: 'Who can participate in&nbsp;“Conquerors of&nbsp;the North”?'
                }),
                northStudents: localize({
                    ru: 'Студенты 3&nbsp;и&nbsp;старших курсов из&nbsp;профильных ВУЗов',
                    en: 'Students in&nbsp;their 3rd year or&nbsp;higher from specialized universities'
                }),
                northExcellent: localize({
                    ru: 'Имеющие высокий средний балл по&nbsp;успеваемости',
                    en: 'Having a&nbsp;high grade point average'
                }),
                northSubjects: localize({
                    ru: 'Отлично знающие профильные предметы',
                    en: 'Excelling in&nbsp;specialized subjects'
                }),
                conditions: localize({
                    ru: 'Условия работы для участников программы',
                    en: 'Working conditions for program participants'
                }),
                northPaidInternship: localize({
                    ru: 'Оплачиваемая практика',
                    en: 'Paid internship'
                }),
                northDuration: localize({
                    ru: 'Продолжительность 2&nbsp;месяца',
                    en: 'Duration of&nbsp;2&nbsp;months'
                }),
                employment: localize({
                    ru: 'Официальное трудоустройство',
                    en: 'Official employment'
                }),
                northCities: localize({
                    ru: 'г.&nbsp;Норильск <br class="hide-me--v-tablet"> и&nbsp;г.&nbsp;Мончегорск',
                    en: 'Norilsk <br class="hide-me--v-tablet"> and Monchegorsk cities'
                }),
                northReceive: localize({
                    ru: 'Вы&nbsp;можете получить',
                    en: 'You can receive:'
                }),
                northCasesTitle: localize({
                    ru: 'Реальные бизнес-кейсы',
                    en: 'Real business cases'
                }),
                northCases: localize({
                    ru: 'Научиться решать бизнес-кейсы на&nbsp;основе реальных ситуаций в&nbsp;компании',
                    en: 'Learn to&nbsp;solve business cases based on&nbsp;real situations in&nbsp;the company'
                }),
                northScholarshipTitle: localize({
                    ru: 'Именную стипендию',
                    en: 'Nominal scholarship'
                }),
                northScholarship: localize({
                    ru: 'Выиграть именную стипендию за&nbsp;лучшие результаты',
                    en: 'Win a&nbsp;nominal scholarship for best results'
                }),
                northExperienceTitle: localize({
                    ru: 'Опыт',
                    en: 'Experience'
                }),
                northExperience: localize({
                    ru: 'Получить практический опыт работы и&nbsp;профессию',
                    en: 'Gain practical work experience and a&nbsp;profession'
                }),
                howToGet: localize({
                    ru: 'Как попасть на&nbsp;программу?',
                    en: 'How to&nbsp;get into the program?'
                }),
                sendResume: localize({
                    ru: 'Отправить свое резюме/анкету',
                    en: 'Send your resume/application form'
                }),
                arcticWho: localize({
                    ru: 'Кто может попасть на&nbsp;Первую Арктическую?',
                    en: 'Who can participate in&nbsp;the First Arctic program?'
                }),
                arcticGraduates: localize({
                    ru: 'Выпускники профильных ВУЗов',
                    en: 'Graduates of&nbsp;specialized universities'
                }),
                arcticBeginners: localize({
                    ru: 'Молодые специалисты до&nbsp;3-х лет опыта работы',
                    en: 'Young specialists with up&nbsp;to&nbsp;3&nbsp;years of&nbsp;work experience'
                }),
                arcticExcellent: localize({
                    ru: 'Имеющие высокий средний балл по&nbsp;успеваемости',
                    en: 'Having a&nbsp;high grade point average'
                }),
                arcticSpecialty: localize({
                    ru: 'Имеющие профильную для Норникеля специальность',
                    en: 'Having a&nbsp;specialty relevant to&nbsp;Norilsk Nickel'
                }),
                arcticDuration: localize({
                    ru: 'Продолжительность 2,5&nbsp;года',
                    en: 'Duration of&nbsp;2.5&nbsp;years'
                }),
                arcticRelocation: localize({
                    ru: 'Релокация в&nbsp;г.&nbsp;Норильск и&nbsp;г.&nbsp;Мончегорск',
                    en: 'Relocation to&nbsp;Norilsk and Monchegorsk cities'
                }),
                arcticOffer: localize({
                    ru: 'Мы&nbsp;предлагаем',
                    en: 'We offer:'
                }),
                arcticPersonalTitle: localize({
                    ru: 'Индивидуальный подход',
                    en: 'An&nbsp;individualised approach'
                }),
                arcticPersonal: localize({
                    ru: 'Персональная программа обучения и&nbsp;развития',
                    en: 'Personal training and development programme'
                }),
                arcticKnowledgeTitle: localize({
                    ru: 'Знания и&nbsp;опыт',
                    en: 'Knowledge and experience'
                }),
                arcticKnowledge: localize({
                    ru: 'Новые знания и&nbsp;кросс-функциональный опыт работы',
                    en: 'New knowledge and cross functional work experience'
                }),
                arcticMentoringTitle: localize({
                    ru: 'Поддержку наставника',
                    en: 'Mentoring'
                }),
                arcticMentoring: localize({
                    ru: 'из&nbsp;числа 100 топ-менеджеров Норникеля',
                    en: 'by&nbsp;one of&nbsp;100 top managers of&nbsp;Nornickel'
                }),
                arcticWageTitle: localize({
                    ru: 'Конкурентную заработная плата',
                    en: 'Competitive wage'
                }),
                arcticWage: localize({
                    ru: 'и соцпакет',
                    en: 'and social package'
                }),
                arcticLeadingTitle: localize({
                    ru: 'Руководящую позицию',
                    en: 'Leading position'
                }),
                arcticLeading: localize({
                    ru: 'в&nbsp;первоклассном активе компании по&nbsp;окончании программы',
                    en: 'in&nbsp;a&nbsp;top company asset after program completion'
                }),
                moscow: localize({
                    ru: 'В&nbsp;офисе в&nbsp;Москве',
                    en: 'In the Moscow office'
                }),
                moscowLead: localize({
                    ru: 'Если ты&nbsp;учишься в&nbsp;Москве и&nbsp;хотел&nbsp;бы работать в&nbsp;офисе компании, для тебя&nbsp;&mdash; Стажерская программа главного офиса &laquo;Норникеля&raquo;',
                    en: 'If&nbsp;you are a&nbsp;student in&nbsp;Moscow and would like to&nbsp;work in&nbsp;the company&rsquo;s office, the Nornickel Headquarters Internship Program is&nbsp;for you'
                }),
                moscowTitle: localize({
                    ru: 'Начало карьеры в&nbsp;офисе компании в&nbsp;Москве',
                    en: 'The beginning of&nbsp;a&nbsp;career in&nbsp;the company&rsquo;s Moscow office'
                }),
                moscowWho: localize({
                    ru: 'Кто может попасть на&nbsp;Стажерскую программу?',
                    en: 'Who can participate in&nbsp;the Internship Program?'
                }),
                moscowStudents: localize({
                    ru: 'Студенты выпускных курсов очных отделений профильных ВУЗов',
                    en: 'Students in&nbsp;their final year of&nbsp;full-time study at&nbsp;relevant universities'
                }),
                moscowExcellent: localize({
                    ru: 'Имеющие высокий средний балл по&nbsp;успеваемости',
                    en: 'Having a&nbsp;high GPA'
                }),
                moscowSubjects: localize({
                    ru: 'Отлично знающие профильные предметы',
                    en: 'Having excellent knowledge of&nbsp;relevant subjects'
                }),
                moscowEnglish: localize({
                    ru: 'Обладающие хорошим знанием английского языка',
                    en: 'Having a&nbsp;good command of&nbsp;the English language'
                }),
                moscowPaid: localize({
                    ru: 'Оплачиваемая стажировка',
                    en: 'Paid internship'
                }),
                moscowHours: localize({
                    ru: 'Минимум 12&nbsp;часов в&nbsp;неделю',
                    en: 'Minimum of&nbsp;12&nbsp;hours per week'
                }),
                moscowDuration: localize({
                    ru: 'Продолжительность 6&nbsp;месяцев',
                    en: 'Duration of&nbsp;6&nbsp;months'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.beginning {
    &__fig-caption {
        width: 100%;
    }

    &__block-text {
        font-weight: 400;
    }

    &__get-title {
        color: $token-colors-navy-blue !important;
    }

    &__selected-block {
        background: linear-gradient(112.26deg, #F4F6F9 8.89%, #E6E7EE 62.24%), #F5F5F5;
        padding: $token-spacers-l $token-spacers-xl;

        @include breakpoint(mobile) {
            display: flex;
            flex-direction: column;
        }

        .z-caption__text {
            @include breakpoint(mobile) {
                font-size: 16px !important;
            }
        }

        @include breakpoint(tablet) {
            padding: $token-spacers-s $token-spacers-xs;
        }

        .z-link {
            @include breakpoint(mobile) {
                display: flex;
                align-self: center;
            }
        }
        .z-button {
            align-self: flex-start;

            @include breakpoint(mobile) {
                align-self: center;
            }
        }
    }

    &__content {
        margin-left: -40px;
        margin-right: -40px;

        .icon-block__content {
            @include breakpoint(v-tablet) {
                text-align: center;
            }
        }
        h2, h3 {
            .z-caption__text {
                @include breakpoint(mobile) {
                    font-size: 20px;
                    max-width: 100% !important;
                    padding-right: 0 !important;
                    text-align: center;
                }
            }
        }
    }
}
</style>
