<template>
    <div class="events-list">
        <div
            v-for="(item, index) in sortedEvents"
            :key="index"
            class="events-list__item"
        >
            <div v-if="item.date" class="events-list__item-date">
                <date-block v-if="isFuture(item.date.start, item.date.end)" :date="item.date.start" :date-end="item.date.end" theme="inline" :icon="!deviceWithoutIcon.includes(device)"></date-block>
                <tag
                    v-else
                    theme="event-light-green"
                    size="s"
                    :name="end"
                    selected
                    disabled
                    :interactive="interactive"
                ></tag>
            </div>
            <a v-if="item.name && item.link" :href="item.link" class="events-list__item-title">
                {{item.name}}
            </a>

            <p v-else class="events-list__item-title">
                {{item.name}}
            </p>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'
import moment from 'moment'

export default {
    name: 'events-list',
    data () {
        return {
            end: localize({
                ru: 'Завершено',
                en: 'Finished'
            }),
            dateNow: moment().startOf('minute').unix()
        }
    },
    mixins: [mixinDevice],
    props: {
        items: {
            type: Array,
            default: () => []
        },
        deviceWithoutIcon: {
            type: Array,
            default: () => []
        },
        interactive: {
            type: Boolean,
            default: true
        },
        canBeClosed: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        isFuture(start, end) {
            if (!this.canBeClosed) return true

            return start >= this.dateNow || end >= this.dateNow
        },
        isArchive(end) {
            return end < this.dateNow
        },
    },
    computed: {
        devidedItemsOnArchiveAndFuture() {
            const future = []
            const archive = []
            this.items.forEach(el => {
                if (this.isArchive(el.date.end)) {
                    archive.push(el)
                    return
                }
                else if (this.isFuture(el.date.start, el.date.end)) {
                    future.push(el)
                    return
                }
                else {
                    console.error('Дата конца не может быть до "Сейчас", когда дата начала после "Сейчас"')
                }
            })

            return {
                future,
                archive
            }
        },

        sortedEvents () {
            const future = this.devidedItemsOnArchiveAndFuture.future
            const archive = this.devidedItemsOnArchiveAndFuture.archive

            future.sort((cur, prev) => {
                return cur.date.start - prev.date.start
            })

            archive.sort((cur, prev) => {
                return prev.date.start - cur.date.start
            })

            const resultArray = [...future, ...archive]

            return resultArray.slice(0, 3)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
