/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map-all': {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    data: '<path pid="0" d="M25 49.39c13.47 0 24.39-10.92 24.39-24.39S38.47.61 25 .61.61 11.53.61 25 11.53 49.39 25 49.39z" _fill="#0077C8" _stroke="#004C97" stroke-width="1.2" stroke-miterlimit="10"/><path pid="1" d="M33.963 29.746a6.469 6.469 0 01-2.823 1.663v-12.52a6.475 6.475 0 012.823 1.66 6.465 6.465 0 011.903 4.599 6.459 6.459 0 01-1.903 4.598zm-12.878-4.043v-6.691l8.277 5.585v6.689l-8.277-5.584zm-1.775 5.706a6.45 6.45 0 01-2.824-1.663 6.456 6.456 0 01-1.905-4.597c0-1.737.677-3.371 1.905-4.6a6.47 6.47 0 012.824-1.66v12.52zm10.052-14.54v5.585l-8.277-5.584a8.28 8.28 0 100 16.557v-5.584l8.277 5.584a8.279 8.279 0 100-16.558z" _fill="#fff"/>'
  }
})
