<template>
    <div class="sale-page-table-item">
        <span v-if="title" class="sale-page-table-item__title">
            <span v-html="title"/>
        </span>
        <div :class="['sale-page-table-item__text', {'sale-page-table-item__text--scaled' : scaledText} ]">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sale-page-table-item',
    props: {
        title: {
            type: String
        },
        scaledText: Boolean
    }
}
</script>

<style lang="scss">
.sale-page-table-item {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: $token-spacers-xs;
    width: 100%;

    @include breakpoint(mobile) {
        max-width: 100% !important;
    }

    &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-bottom: $token-spacers-3-xs;
        display: flex;
        align-items: center;
        position: relative;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black;

        @include breakpoint(mobile) {
            font-size: 16px;
        }

        &--scaled {
            font-size: 18px;
        }
    }
}
</style>
