/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'smartlab': {
    width: 88,
    height: 29,
    viewBox: '0 0 88 29',
    data: '<rect pid="0" width="88" height="29" rx="14.5" _fill="#0077C8"/><path pid="1" d="M16.692 18.644c-2.364 0-4.2-1.68-4.2-4.14s1.836-4.14 4.2-4.14c1.62 0 2.616.84 3.216 1.8l-1.2.612a2.376 2.376 0 00-2.016-1.164c-1.584 0-2.76 1.212-2.76 2.892s1.176 2.892 2.76 2.892a2.35 2.35 0 002.016-1.164l1.2.612c-.612.96-1.596 1.8-3.216 1.8zm12.656-.144h-1.404v-5.94l-2.46 5.94h-.6l-2.448-5.94v5.94h-1.404v-8.004h1.98l2.172 5.268 2.184-5.268h1.98V18.5zm8.903 0h-1.596l-.588-1.536h-3.672l-.588 1.536h-1.596l3.144-8.004h1.752l3.144 8.004zm-2.58-2.772l-1.44-3.816-1.44 3.816h2.88zM40.53 18.5h-1.404v-8.004h3.516c1.68 0 2.616 1.152 2.616 2.508s-.948 2.508-2.616 2.508H40.53V18.5zm1.92-4.224c.792 0 1.368-.504 1.368-1.272s-.576-1.272-1.368-1.272h-1.92v2.544h1.92zm7.29 4.224h-1.405v-6.768H45.91v-1.236h6.252v1.236h-2.424V18.5zm2.312.144v-1.248c.864 0 1.308-.42 1.584-2.784l.468-4.116h5.436V18.5h-1.404v-6.768h-2.772l-.336 2.976c-.384 3.396-1.428 3.936-2.976 3.936zm16.388-.144h-1.596l-.588-1.536h-3.672l-.588 1.536h-1.596l3.144-8.004h1.752l3.144 8.004zm-2.58-2.772l-1.44-3.816-1.44 3.816h2.88zm3.454-5.232h5.484v1.236h-4.08v1.752h2.112c1.668 0 2.616 1.152 2.616 2.508s-.936 2.508-2.616 2.508h-3.516v-8.004zm3.324 4.224h-1.92v2.544h1.92c.792 0 1.368-.504 1.368-1.272s-.576-1.272-1.368-1.272z" _fill="#fff"/>'
  }
})
