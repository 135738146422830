<template>
    <component
        :is="componentName"
        :componentData="componentData"
    >
        <slot><div v-html="app.section.description"></div></slot>
    </component>
</template>

<script>
import MediaAlbumExpedition from './components/MediaAlbumExpedition.vue'
import MediaAlbumIdentity from './components/MediaAlbumIdentity.vue'
import MediaAlbumPresentations from './components/MediaAlbumPresentations.vue'

export default {
    name: 'media-album-controller',
    components: {
        MediaAlbumExpedition,
        MediaAlbumIdentity,
        MediaAlbumPresentations
    },

    data () {
        return {
            componentName: 'MediaAlbumExpedition',
            itemsType: 'photo'
        }
    },

    provide () {
        return {
            navigation: this.albumNavigation,
            locations: this.locations,
            date: this.formattedDate,
            error: this.error
        }
    },

    methods: {
        setCurrentType () {
            this.contentTypesWithMaxCounts.forEach(el => {
                const allMaxCounts = Object.keys(el)

                // Идут в порядке приоритета !
                if (allMaxCounts.includes('imagesCount')) {
                    this.componentName = 'MediaAlbumExpedition'
                    this.itemsType = 'photo'
                    return
                }
                if (allMaxCounts.includes('videoCount')) {
                    this.componentName = 'MediaAlbumExpedition'
                    this.itemsType = 'video'
                    return
                }
                if (allMaxCounts.includes('interviewCount')) {
                    this.componentName = 'MediaAlbumExpedition'
                    this.itemsType = 'interview'
                    return
                }
                if (allMaxCounts.includes('financialResultsCount')) {
                    this.componentName = 'MediaAlbumPresentations'
                    this.itemsType = 'financial-results'
                    return
                }
                if (allMaxCounts.includes('presentationCount')) {
                    this.componentName = 'MediaAlbumPresentations'
                    this.itemsType = 'presentation'
                    return
                }
                if (allMaxCounts.includes('historyCount')) {
                    this.componentName = ''
                    this.itemsType = 'history'
                    return
                }
                if (allMaxCounts.includes('identityCount')) {
                    this.componentName = 'MediaAlbumIdentity'
                    this.itemsType = 'identity'
                }
            })
        }
    },

    computed: {
        contentTypesWithMaxCounts () {
            const contentCounts = []

            const countNames = [
                'imagesCount',
                'videoCount',
                'identityCount',
                'interviewCount',
                'financialResultsCount',
                'presentationCount',
                'historyCount'
            ]

            countNames.forEach((name) => {
                contentCounts.push({[name]: this.app.section[name]})
            })

            contentCounts.sort((prev, next) => {
                return Object.values(next)[0] - Object.values(prev)[0]
            })

            const maxCount = contentCounts[0]

            const allMaxCounts = contentCounts.filter((el) => {
                return Object.values(el)[0] === Object.values(maxCount)[0]
            })

            return allMaxCounts
        },

        error () {
            return !(Array.isArray(this.app.items) && this.app.items.length > 0)
        },

        app () {
            return window.App.components['media-library-section']
        },

        tags () {
            // this.app.section.items.forEach(el => {
            //     el.mlSubject.xmlId.forEach(el => tags.add(el))
            // })
            return []
        },

        locations () {
            return this.app.section?.cities || []
        },
        months () {
            return this.$root.lang === 'ru'
                ? ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']
                : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },

        formattedDate () {
            if (!this.app.section?.date) return null
            const date = new Date(this.app.section.date * 1000)

            const year = date.getFullYear()
            const month = this.months[date.getMonth()]
            const day = date.getDate()

            const formattedTime = `${day} ${month} ${year} г.`

            return formattedTime
        },

        componentData () {
            return this.app.items.filter(item => {
                if (this.itemsType === 'photo') {
                    return item.mlType.xmlId === this.itemsType || item.mlType.xmlId === 'video'
                }
                return item.mlType.xmlId === this.itemsType
            })
        },

        albumNavigation () {
            return this.app.nav
        }
    },
    mounted () {
        this.setCurrentType()
    }
}
</script>
