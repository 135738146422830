<template>
    <div class="z-logo">
        <template v-if="isIndex">
            <img :src="buildSrc" class="z-logo__img">
        </template>
        <template v-else>
            <a :href="siteDir" class="z-logo__link" :title="title">
                <img :src="buildSrc" class="z-logo__img">
            </a>
        </template>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-logo',
    mixins: [mixinDevice],
    props: {
        siteDir: {
            type: String,
            default: '/',
            required: true
        },
        theme: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        },
        inverted: Boolean
    },
    data () {
        return {
            title: this.$root.lang === 'ru' ? 'Перейти на главную страницу' : 'Go to mainpage',
            isIndex: window.location.pathname === '/'
        }
    },
    computed: {
        buildSrc () {
            let src = '/images/logo/logo'

            if (this.size === 'sm') {
                src += '-sm'
            }

            if (this.inverted) {
                src += '-inverted'
            }

            if (this.size !== 'sm') {
                src += `-${this.$root.lang}`
            }

            return `${src}.svg`
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
