<template>
    <div class="non-industrial-assets-page">
        <non-industrial-assets-filter class="u-bottom-margin--l" :filters-data="filtersData" @update-filters="filtersHandler"></non-industrial-assets-filter>

        <download-excel-btn
        class="u-bottom-margin--s"
        urlSections="assets/non-industrial-assets"
        :filters="filters"
        :disabled="!items.length"
        />

        <template v-if="items.length && !loading">
            <non-industrial-assets-list :items="items"/>
            <z-pagination
                v-if="nav"
                :data="{
                    currentPage: nav.current,
                    totalPages: nav.total,
                    pageRange: 1
                }"
                @change-page="pageHandler"
            />
        </template>
        <template v-else-if="loading">
            <z-preloader v-if="loading"/>
        </template>
        <template v-else>
            <z-not-found/>
        </template>
    </div>
</template>

<script>
import NonIndustrialAssetsFilter from '@/views/NonIndustrialAssets/components/NonIndustrialAssetsFilter.vue'
import NonIndustrialAssetsList from '@/views/NonIndustrialAssets/components/NonIndustrialAssetsList.vue'
import {getNonIndustrialAssets} from '@/api/non-industrial-assets'
import {debounce} from 'throttle-debounce'
import {showNotyfications} from '@/utils/notifications'
export default {
    name: 'non-industrial-assets-page',
    components: {
        NonIndustrialAssetsFilter, NonIndustrialAssetsList
    },
    data () {
        return {
            filtersData: this.$root.app?.components['non-industrial-assets']?.filters || [],
            items: this.$root.app?.components['non-industrial-assets']?.items || [],
            nav: this.$root.app?.components['non-industrial-assets']?.nav || null,
            page: 1,
            isMore: false,
            loading: false,
            filters: null
        }
    },
    methods: {
        send: debounce(300, function (params) {
            this.loading = true
            getNonIndustrialAssets(params)
                .then((response) => {
                    this.filtersData = response.filters
                    this.nav = response.nav
                    this.page = this.nav.current

                    if (this.isMore) {
                        this.items = [...this.items, ...response.items]
                        this.isMore = false
                    } else {
                        this.items = response.items
                    }
                })
                .catch((err) => {
                    showNotyfications('error', err)
                })
                .finally(() => {
                    this.loading = false
                })
        }),
        filtersHandler (payload) {
            this.page = 1
            this.filters = payload
            this.send({
                ...this.filters,
                page: this.page
            })
        },
        pageHandler (payload) {
            this.isMore = payload.type === 'load-more'
            this.page = payload.page
            this.send({
                ...this.filters,
                page: this.page
            })
        }
    }
}
</script>

<style lang="scss">
.non-industrial-assets-page {
    max-width: 1360px;
    width: 100%;
}
</style>
