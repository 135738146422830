/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort-up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 16h8M5 12h6M5 8h4" _stroke="#0077C8" stroke-width="1.2" stroke-linecap="round"/><path pid="1" d="M19 6l3 3m-3-3l-3 3m3-3v12" _stroke="#0077C8" stroke-width="1.2"/>'
  }
})
