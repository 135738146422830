/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'statistics': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_2646_21308)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 39H3V2h58v10h-2V4H5v35zm56 23H3V51h2v9h54V24h2v38zM44 38c0-.74-.203-1.434-.557-2.03l14.528-14.527c.595.354 1.29.557 2.029.557 2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4c0 .74.203 1.433.556 2.029L42.03 34.556a3.98 3.98 0 00-4.64.42l-8.946-8.947c.354-.596.557-1.29.557-2.029 0-2.2-1.8-4-4-4s-4 1.8-4 4c0 .74.203 1.433.557 2.03L6.029 41.556A3.966 3.966 0 004 41c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4c0-.74-.203-1.433-.557-2.03l15.528-15.527c.596.354 1.29.557 2.029.557.74 0 1.433-.203 2.029-.556l9.207 9.206A3.963 3.963 0 0036 38c0 2.2 1.8 4 4 4s4-1.8 4-4zM4 43c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm19-19c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm17 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm18-18c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zM11 8H9v2h2V8zm-2 4h2v2H9v-2zM7 50v8h2v-8H7zm4-8h2v16h-2V42zm24 1v15h2V43h-2zm8 0v15h2V43h-2zm-4 1h2v14h-2V44zm8-9h2v23h-2V35zm6-4h-2v27h2V31zm2 27V27h2v31h-2zM17 38h-2v20h2V38zm2-4h2v24h-2V34zm14 2h-2v22h2V36zm-6-4h2v26h-2V32zm-2-2h-2v28h2V30zm2-22h26v2H27V8zm-2 0H13v2h12V8zm-12 4h30v2H13v-2z" _fill="#0077C8"/></g><defs><clipPath id="clip0_2646_21308"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
