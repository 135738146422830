/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'm1': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<g clip-path="url(#clip0_7812_67525)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.305 20.436l.701.467.526-.338.779-.61a6158.97 6158.97 0 014.019-3.144l.01-.008.01-.007a2.05 2.05 0 00.08-.065l.079-.067c.038-.032.065-.054.097-.083l.005-.005.005-.004a6.766 6.766 0 001.84-2.64c.153-.392.242-.727.293-.957.025-.115.04-.202.05-.257l.001-.011V3.616a2.962 2.962 0 01-.88-.525l-.007-.006-.006-.006a2.735 2.735 0 01-.33-.349V2.73c-.266-.34-.412-.7-.49-1.029H4.913a2.64 2.64 0 01-1.02 1.54c-.21.152-.44.277-.694.376v9.09l.001.01c.009.052.023.137.048.249a6.5 6.5 0 00.283.934c.296.77.826 1.746 1.768 2.6.053.048.107.095.165.143l.01.008.01.009c.053.046.114.095.187.154 1.317 1.028 2.833 2.214 4.019 3.142l.614.48zm-2.683-.576c-.883-.691-1.83-1.43-2.69-2.104a6.363 6.363 0 01-.235-.192 6.38 6.38 0 01-.203-.176C2.266 15.368 2 12.786 2 12.786V2.67c.54-.055.92-.205 1.188-.402a1.445 1.445 0 00.551-.817c.128-.497 0-.952 0-.952H18.261s-.128.456 0 .952c.047.181.128.368.262.54.053.067.116.132.185.194.273.238.68.423 1.292.485V6h6.5V19.5c0 .928-.382 1.852-.818 2.601a9.043 9.043 0 01-1.328 1.753l-.03.029-.033.024-7 5-.314.224-.297-.247c-1.987-1.656-6.213-5.21-7.034-6.03C8.84 22.046 8 20.592 7.622 19.86zm2.317 1.813c.139.176.278.337.415.473.742.743 4.57 3.97 6.67 5.723l6.65-4.751a8.106 8.106 0 001.144-1.52c.397-.683.682-1.426.682-2.098V7h-5v6.118l-.053.106a34.59 34.59 0 01-1.155 2.107c-.461.773-.99 1.573-1.438 2.023-.232.23-.653.56-1.154.926-.51.373-1.131.805-1.782 1.246-.263.179-.532.36-.8.539l-3.11 2.435h-.013l-1.056-.827z" _fill="#1A1B25"/></g><defs><clipPath id="clip0_7812_67525"><path pid="1" _fill="#fff" d="M0 0h29v29H0z"/></clipPath></defs>'
  }
})
