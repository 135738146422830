/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Cu': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32273)"/><path pid="2" d="M30.225 40.3c-4.925 0-8.75-3.5-8.75-8.625s3.825-8.625 8.75-8.625c3.375 0 5.45 1.75 6.7 3.75l-2.5 1.275c-.8-1.375-2.4-2.425-4.2-2.425-3.3 0-5.75 2.525-5.75 6.025 0 3.5 2.45 6.025 5.75 6.025 1.8 0 3.4-1.025 4.2-2.425l2.5 1.275c-1.275 2-3.325 3.75-6.7 3.75zM49.84 40h-2.625v-1.6a5.787 5.787 0 01-4.275 1.9c-2.6 0-3.875-1.35-3.875-3.825v-8.55h2.625v7.6c0 1.875.95 2.45 2.425 2.45 1.325 0 2.475-.75 3.1-1.575v-8.475h2.625V40z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32273" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
