/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mining': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.467 4.964a7.433 7.433 0 00-9.291.928l-6.992 6.83H1.5v9.988h3.25l1.628 8.93H2.774v4.084h4.349l.426 2.336.112.615H10.414a4.872 4.872 0 00-.057.746c0 2.654 2.131 4.829 4.774 4.829 2.642 0 4.774-2.175 4.774-4.83 0-.253-.02-.502-.058-.745h8.305a4.872 4.872 0 00-.057.746c0 2.654 2.131 4.829 4.774 4.829 2.642 0 4.774-2.175 4.774-4.83 0-.253-.02-.502-.057-.745H40.339l.112-.615.426-2.336h4.348V31.64h-3.603l1.628-8.93h3.25v-9.987h-3.78L35.148 6.45a7.433 7.433 0 00-7.715-1.112l-1.553.67a3.424 3.424 0 01-3.23-.276l-1.182-.768zm18.902 7.759H7.331l5.893-5.758-.484-.494.484.494a5.933 5.933 0 017.425-.744l.387-.595-.387.595 1.182.769.381-.586-.38.586a4.924 4.924 0 004.642.395l1.552-.67a5.933 5.933 0 016.164.89l6.18 5.118zM7.903 31.64l-1.629-8.93h35.451l-1.628 8.93h-3.954v4.084h3.21l-.266 1.451h-3.573l.399 1.023c.148.379.23.79.23 1.223 0 1.845-1.48 3.329-3.274 3.329-1.795 0-3.274-1.484-3.274-3.33 0-.431.082-.843.23-1.222l.4-1.023H17.775l.399 1.023c.148.379.23.79.23 1.223 0 1.845-1.48 3.329-3.274 3.329-1.795 0-3.274-1.484-3.274-3.33 0-.431.082-.843.23-1.222l.399-1.023H8.912l-.264-1.451h3.209V31.64H7.903zM3 21.21v-6.987h42v6.987H3zm2.549-1.834v-3.32h-1.5v3.32h1.5zm36.902-3.32v3.32h1.5v-3.32h-1.5zM4.274 34.225V33.14h6.083v1.084H4.274zm33.369 0V33.14h6.082v1.084H37.643zM14.406 39.42c0-.42.335-.746.725-.746.39 0 .725.326.725.746s-.335.745-.725.745a.737.737 0 01-.725-.745zm.725-2.246c-1.237 0-2.225 1.017-2.225 2.246s.988 2.245 2.225 2.245 2.225-1.016 2.225-2.245c0-1.23-.988-2.246-2.225-2.246zm17.013 2.246c0-.42.336-.746.725-.746.39 0 .725.326.725.746s-.335.745-.725.745a.737.737 0 01-.725-.745zm.725-2.246c-1.237 0-2.225 1.017-2.225 2.246s.988 2.245 2.225 2.245 2.225-1.016 2.225-2.245c0-1.23-.988-2.246-2.225-2.246z" _fill="#0077C8"/>'
  }
})
