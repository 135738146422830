<template>
<div class="pr-metal-card" v-if="data">
    <div class="pr-metal-card__img" :style="`background-image: url(${data.previewPicture})`">
        <div class="pr-metal-card__atom-info">
            <span
                class="pr-metal-card__number"
                v-html="data.atomNumber.value"
                v-if="data.atomNumber && data.atomNumber.value"
            ></span>
            <span
                class="pr-metal-card__mass"
                v-html="data.atomMass.value"
                v-if="data.atomMass && data.atomMass.value"
            ></span>
        </div>
    </div>
    <footer class="pr-metal-card__footer">
        <span
            class="pr-metal-card__formula"
            v-html="data.formula.value"
            v-if="data.formula && data.formula.value"
        ></span>
        <span class="pr-metal-card__name" v-html="data.name"></span>
    </footer>
</div>
</template>
<script>
export default {
    name: 'pr-metal-card',
    props: {
        data: Object
    }
}
</script>
<style lang="scss">
.pr-metal-card {
    overflow: hidden;
    border-top-left-radius: $boder-radius-l;
    border-bottom-right-radius: $boder-radius-l;

    @include breakpoint (v-tablet) {
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    &__img {
        background-position: 50% 50%;
        background-size: cover;
        padding: $token-spacers-xs;
        display: flex;
        background-color: $token-colors-gray-20;
        position: relative;

        &:after {
            content: '';
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        // это штука нужна для сохранения соотношения сторон
        &:before {
            content: '';
            padding-top: 42%;
            width: 0;
        }
    }

    &__atom-info {
        position: relative;
        z-index: 2;
        color: $token-colors-white;
        text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
    }

    &__number {
        color: inherit;
        @include typo-level(5XL);
        font-weight: 600;
        line-height: 1;
        @include margin-level(bottom, XL);
    }

    &__mass {
        color: inherit;
        @include typo-level(3XL);
        font-weight: 600;
    }

    &__footer {
        background: $token-colors-white;
        display: flex;
        align-items: flex-end;
        padding: 0 $token-spacers-s;
    }

    &__name {
        @include padding-level(top, 2XS);
        @include padding-level(bottom, XS);
        color: $token-colors-gray-60;
        @include typo-level(L);
        font-weight: 600;
        line-height: 1 !important;
    }

    &__formula {
        margin-right: $token-spacers-xs;
        color: $token-colors-white;
        background: linear-gradient(123.73deg, #0077C8 20.82%, rgba(0, 119, 200, 0) 99.26%), #004C97;
        border-radius: 80px 80px 0px 80px;
        @include typo-level(2XL);
        line-height: 1;
        font-weight: 600;
        display: inline-block;
        padding: 18px 22px;
        margin-top: -30px;
        position: relative;
        display: block;
        z-index: 3;
        @include margin-level(bottom, XS);
    }
}
</style>
