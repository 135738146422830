/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-group': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_9435_2383)" fill-rule="evenodd" clip-rule="evenodd" _fill="#0077C8"><path pid="0" d="M12 2.55a4.45 4.45 0 100 8.9 4.45 4.45 0 000-8.9zM7.508 13.797c-1.17.78-1.958 1.904-1.958 3.203s.789 2.423 1.958 3.203c1.17.78 2.759 1.247 4.492 1.247s3.323-.468 4.492-1.247c1.17-.78 1.958-1.904 1.958-3.203 0-1.3-.788-2.424-1.958-3.203-1.169-.78-2.759-1.247-4.492-1.247s-3.323.467-4.492 1.247zM8.75 7a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zm-2 10c0-.776.472-1.57 1.423-2.205.947-.63 2.298-1.045 3.827-1.045 1.53 0 2.88.414 3.827 1.045.951.635 1.423 1.429 1.423 2.205s-.472 1.57-1.423 2.204c-.947.631-2.297 1.046-3.827 1.046-1.53 0-2.88-.415-3.827-1.046C7.222 18.57 6.75 17.776 6.75 17zM21.6 7A3.6 3.6 0 0018 3.4a.6.6 0 100 1.2 2.4 2.4 0 110 4.8.6.6 0 100 1.2A3.6 3.6 0 0021.6 7zM4 13.4a3.577 3.577 0 00-2.626 1.157A3.594 3.594 0 00.4 17c0 .867.326 1.746.974 2.444A3.577 3.577 0 004 20.6a.6.6 0 100-1.2c-.693 0-1.31-.302-1.747-.773A2.394 2.394 0 011.6 17c0-.565.213-1.152.653-1.626A2.377 2.377 0 014 14.6a.6.6 0 100-1.2zm16 0c1.055 0 1.982.463 2.626 1.157.648.698.974 1.577.974 2.443 0 .867-.326 1.746-.974 2.444A3.577 3.577 0 0120 20.6a.6.6 0 110-1.2c.693 0 1.31-.302 1.747-.773.44-.474.653-1.06.653-1.627 0-.565-.213-1.152-.653-1.626A2.377 2.377 0 0020 14.6a.6.6 0 110-1.2zM6 3.4a3.6 3.6 0 000 7.2.6.6 0 100-1.2 2.4 2.4 0 010-4.8.6.6 0 000-1.2z"/></g><defs><clipPath id="clip0_9435_2383"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
