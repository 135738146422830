/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/mp3': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM19.659 31h1.404v-8.004h-1.98l-2.184 5.268-2.173-5.268h-1.98V31h1.404v-5.94L16.6 31h.6l2.46-5.94V31zm3.107 0h1.404v-2.988h2.112c1.668 0 2.616-1.152 2.616-2.508s-.936-2.508-2.616-2.508h-3.516V31zm4.692-5.496c0 .768-.576 1.272-1.368 1.272h-1.92v-2.544h1.92c.792 0 1.368.504 1.368 1.272zm2.045 4.344c.54.72 1.596 1.296 3.024 1.296 1.848 0 3.012-.972 3.012-2.328 0-1.236-1.08-1.872-1.86-1.944.828-.144 1.74-.768 1.74-1.836 0-1.344-1.164-2.16-2.892-2.16-1.332 0-2.304.54-2.916 1.26l.72.876c.576-.576 1.248-.888 2.064-.888.9 0 1.62.384 1.62 1.128 0 .732-.708 1.056-1.644 1.056-.312 0-.78 0-.924-.012v1.26c.12-.012.588-.012.924-.012 1.14 0 1.776.336 1.776 1.128 0 .744-.612 1.224-1.668 1.224-.852 0-1.704-.372-2.22-.972l-.756.924z" _fill="#737B91"/>'
  }
})
