/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Variant9': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<rect pid="0" width="38" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M19 9.25A9.75 9.75 0 1028.75 19 9.76 9.76 0 0019 9.25zm8.215 9h-3.734c-.132-2.668-.963-5.2-2.355-7.219a8.27 8.27 0 016.09 7.219zM19 27.24c-1.756-1.9-2.82-4.594-2.978-7.49h5.956c-.157 2.894-1.222 5.59-2.978 7.49zm-2.978-8.99c.157-2.894 1.218-5.59 2.978-7.49 1.756 1.9 2.82 4.594 2.978 7.49h-5.956zm.852-7.219c-1.392 2.019-2.223 4.55-2.355 7.219h-3.734a8.269 8.269 0 016.089-7.219zm-6.09 8.719h3.735c.132 2.668.963 5.2 2.355 7.219a8.27 8.27 0 01-6.09-7.219zm10.342 7.219c1.392-2.021 2.223-4.554 2.355-7.219h3.734a8.269 8.269 0 01-6.089 7.219z" _fill="#fff"/>'
  }
})
