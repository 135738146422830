/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'сopy_link': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.749 3.15a.6.6 0 100 1.2h12.9v12.9a.6.6 0 101.2 0V3.75a.6.6 0 00-.6-.6H6.75zm-3 3a.6.6 0 00-.6.6v13.5a.6.6 0 00.6.6h13.5a.6.6 0 00.6-.6V6.75a.6.6 0 00-.6-.6H3.75zm.6 13.5V7.35h12.3v12.3H4.35z" _fill="#0077C8"/>'
  }
})
