<template>
    <div :class="[
        'slider',
        {
            'slider--with-thumbs': $slots.thumbs,
            'slider--with-further': this.further
        }
    ]">
        <div class="swiper-container slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <div class="slider__footer">
            <div class="container u-relative">
                <div class="slider__controls">
                    <button class="slider__nav-btn slider__nav-btn--prev" ref="prev">
                        <z-icon
                            name="arrow-long"
                            dir="left"
                        />
                    </button>
                    <div class="slider__pagination" ref="pagination"></div>
                    <button class="slider__nav-btn slider__nav-btn--next" ref="next">
                        <z-icon
                            name="arrow-long"
                            dir="right"
                        />
                    </button>
                </div>
            </div>
            <div class="slider__thumbs" v-if="!!$slots.thumbs">
                <div class="container u-all-padding--no u-relative">
                    <slider-thumbs-container :data="thumbs" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
import SliderThumbsContainer from './components/SliderThumbsContainer.js'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'slider',
    mixins: [mixinDevice],
    components: {
        SliderThumbsContainer
    },
    data () {
        return {
            slider: undefined,
            activeIndex: 0
        }
    },
    props: {
        swiperOptions: {
            type: Object
        },
        further: {
            type: Boolean,
            default: false
        },
        effect: {
            type: String,
            default: 'slide'
        }
    },
    methods: {
        init () {
            const _this = this

            let options = {
                allowTouchMove: false, // ОДУМАЙТЕСЬ!
                observer: true,
                loop: true,
                observeParents: true,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                effect: this.effect,
                pagination: {
                    clickable: true,
                    el: this.$refs.pagination
                },
                slidesPerView: 1,
                on: {
                    slideChange () {
                        let index
                        this.activeIndex > _this.$slots?.thumbs?.length ? index = 0 : index = this.activeIndex - 1
                        _this.activeIndex = index
                    }
                }
            }

            this.slider = new Swiper(this.$refs.slider, Object.assign({}, this.swiperOptions, options))
        }
    },
    computed: {
        thumbs () {
            if (!this.$slots.thumbs) return []
            let arr = this.$slots.thumbs
            const part1 = arr.slice(0, this.activeIndex)
            const part2 = arr.slice(this.activeIndex)

            arr = part2.concat(part1)
            if (arr.length > this.navCount) arr = arr.slice(0, this.navCount)

            return arr
        },
        navCount () {
            if (this.device === 'laptop' || this.device === 'tablet') return 2
            if (this.device === 'v-tablet' || this.device === 'mobile') return 1
            return 3
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
.slider {
    $parent: &;
    position: relative;
    width: 100%;

    @include breakpoint (mobile) {
        width: auto;
    }

    &--with-further {
        #{$parent}__container {
            height: 100%;
        }

        @include breakpoint (mobile) {
            margin: 0;
            width: 100%;
        }

        #{$parent}__thumbs {
            opacity: 1;
        }

        #{$parent}__thumbs {
            background: rgba(0, 120, 200, 0.9);
            opacity: 1;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 2;
    }

    &__controls {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: calc(100% + 40px);
        right: $token-grids-desktop-offset;
        z-index: 3;

        @include breakpoint(laptop) {
            right: $token-grids-laptop-offset;
        }

        @include breakpoint (v-tablet) {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            justify-content: center;
        }
    }

    &__nav-btn {
        pointer-events: all;
        background: none;
        border: 1px solid $token-colors-blue;
        padding: 0;
        transition: all $transition;
        cursor: pointer;
        background: $token-colors-white;
        border-radius: 50%;
        margin-left: 50px;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(tablet) {
            width: 40px;
            height: 40px;
        }

        @include breakpoint(mobile) {
            margin-left: 20px;
            width: 23px;
            height: 23px;
        }

        .z-icon {
            width: 45%;
            height: 45%;

            path {
                fill: $token-colors-blue;
            }
        }

        &--prev {
            margin-left: 0;
            margin-right: 50px;

            &:hover {
                transform: translateX(-2px);
            }

            @include breakpoint(mobile) {
                margin-right: 20px;
            }
        }

        &--next {
            &:hover {
                transform: translateX(2px);
            }
        }

        &.swiper-button-disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }

    &__pagination {
        display: flex;
        align-items: center;

        .swiper-pagination-bullet {
            background: $token-colors-blue-10;
            margin: 0 8px;
            width: 6px;
            height: 6px;
            vertical-align: middle;
            opacity: 1;

            &-active {
                background: white;
                width: 12px;
                height: 12px;
            }
        }
    }

    .swiper-slide {
        height: auto;

        &__content,
        &__wrapper {
            height: 100%;
        }

        .z-image {
            display: block;
            &__img {
                display: block;
                width: 100%;
            }
        }
    }

    &__thumbs {
        position: relative;
        background: $token-colors-blue;
        opacity: 0.9;
    }
}
</style>
