<template>
    <component
        :is="tag"
        :class="buildClass"
    >
        <slot/>
    </component>
</template>

<script>
export default {
    name: 'z-list',
    props: {
        nested: Boolean,
        styled: {
            type: Boolean,
            default: true
        },
        tag: {
            type: String,
            validator: prop => ['ul', 'ol'].includes(prop),
            default: 'ul'
        },
        theme: {
            type: String
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buildClass () {
            if (!this.styled) return []

            return [
                'z-list',
                `z-list--${this.tag}`,
                {
                    [`z-list--theme-${this.theme}`]: this.theme,
                    'z-list--nested': this.nested,
                    'z-list--no-styled': !this.styled,
                    'z-list--horizontal': this.horizontal
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
