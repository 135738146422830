/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/online': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zm-2.857 13.936l.889.875a7.424 7.424 0 000-10.625c-2.984-2.938-7.81-2.938-10.794 0a7.424 7.424 0 000 10.625l.889-.875a6.23 6.23 0 010-8.875c2.476-2.438 6.54-2.438 9.016.062a6.156 6.156 0 010 8.813zm-1.777-1.748l.889.875c1.968-2 1.968-5.125 0-7.063-1.969-1.938-5.207-1.938-7.175 0a4.962 4.962 0 000 7.063l.889-.875c-1.46-1.5-1.46-3.875 0-5.313 1.524-1.438 3.936-1.438 5.397 0 1.46 1.5 1.46 3.875 0 5.313zm-4.07-1.334a1.853 1.853 0 01-.565-1.354c0-1.063.825-1.875 1.905-1.875 1.079 0 1.904.813 1.904 1.875 0 .53-.221.999-.57 1.335l3.745 10.415V34h-1.27v-.5l-.635-1.778-6.984 2.113V34h-1.27v-.75l.064-.063 1.627-4.573-.084-.167.174-.085 1.96-5.508zm3.853 7.02l-3.911-1.05 3.006-1.48.905 2.53zm-2.323-6.499l.994 2.78-2.874 1.415 1.5-4.195h.38zm1.351 7.533l-4.037-1.084-.924 2.585 4.961-1.5zm-1.541-8.783c.38 0 .634-.25.634-.625a.631.631 0 00-.634-.625c-.381 0-.635.25-.635.625s.254.625.635.625z" _fill="#737B91"/>'
  }
})
