/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tank-storage': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<g clip-path="url(#clip0_2646_22786)"><path pid="0" d="M47.107 43.5H1.893m18.436-6.913l.01-3.729-.01-.01m0 3.739h-4.845l-.01.01m4.855-.01l.01.01v3.425m0 0v3.479m0-3.479l-.01-.01H15.484l-.01.01m0 0l.01 3.479m-.01-3.479v-3.425m0 0v-3.739l.01-.01m-7.536-8.534h3.758m-3.758 0l-.01-1.347a2.441 2.441 0 012.433-2.445h1.335m-3.758 3.792H6.603A2.441 2.441 0 004.17 26.76V43.5m3.778-19.186l-.01.01v19.177m7.536-33.4l.01 3.783m-.01-3.782l-2.277-.01c-.826 0-1.49.674-1.49 1.497V43.5m3.767-33.4l.01-.01h4.845l.01.01m21.655-.009l.01-2.296c0-.823-.665-1.497-1.49-1.497H16.021c-.825 0-1.49.674-1.49 1.497l.01 2.301m5.787 22.751l.01-3.783-.01-.01m0 3.793h-4.845m0 0l-.01-3.783.01-.01m0 0l-.01-3.782.01-.01m0 3.792h4.845m0 0l.01-3.782-.01-.01m-4.845-11.379h4.845m-4.845 0l-.01.01.01 3.783m4.855.01l-.01 3.783m.01-3.783v-3.793l-.01-.01m.01 3.803l-.01-.01h-4.845m0 0l-.01.01.01 3.783m0 3.793l-.01-3.783.01-.01m0 3.793h4.845m0 0l.01-3.783-.01-.01m-4.845 0h4.845m.01-11.368l23-.01c.825 0 1.49.674 1.49 1.497V43.5M20.34 10.1l-.01 3.783" _stroke="#0077C8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_2646_22786"><path pid="1" _fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs>'
  }
})
