<template>
    <div class="ecology-section">
        <div class="container">
            <div class="row">
                <div class="col-default-6 col-tablet-12">
                    <z-caption
                        tag="h2"
                        class="white-text"
                        size="3xl"
                        decor-left
                        decor-right
                    >
                        <vue-raw :raw="text.ecology.title" />
                    </z-caption>
                    <z-caption
                        tag="p"
                        class="white-text"
                        size="2xl"
                    >
                        <vue-raw :raw="text.ecology.text" />
                    </z-caption>

                    <z-button
                        tag="a"
                        size="m"
                        :href="text.ecology.linkHref"
                        target="_blank"
                    >
                        <vue-raw :raw="text.ecology.linkTitle" />
                        <template v-slot:right>
                            <z-icon name="arrow-long" dir="right" width="24" height="24" />
                        </template>
                    </z-button>
                </div>
                <div class="col-default-5 col-default-offset-1 col-tablet-12 col-tablet-offset-0">
                    <div class="ecology-section__glass-block">
                        <div class="glass-block">
                            <div class="glass-block__inner">
                                <z-caption
                                    tag="h3"
                                    class="white-text"
                                    size="2xl"
                                    decor-left
                                    decor-right
                                >
                                    <vue-raw :raw="text.approach.title" />
                                </z-caption>
                                <z-caption
                                    tag="p"
                                    class="white-text"
                                    size="l"
                                >
                                    <vue-raw :raw="text.approach.text" />
                                </z-caption>

                                <z-button
                                    tag="a"
                                    size="m"
                                    :href="text.approach.linkHref"
                                >
                                    <vue-raw :raw="text.approach.linkTitle" />
                                    <template v-slot:right>
                                        <z-icon name="arrow-long" dir="right" width="24" height="24" />
                                    </template>
                                </z-button>
                            </div>
                        </div>
                        <!-- END .glass-block -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'ecology',
    data () {
        return {
            text: {
                ecology: {
                    title: localize({
                        'ru': 'Экологическая стратегия',
                        'en': 'Environmental strategy'
                    }),
                    text: localize({
                        'ru': 'В&nbsp;2020 году &laquo;Норникель&raquo; разработал комплексную экологическую стратегию, в&nbsp;которой поставлены четкие цели по&nbsp;основным направлениям: изменение климата, воздух, вода, почва, отходы и&nbsp;биоразнообразие. В&nbsp;2021 году стратегия детализирована и&nbsp;утверждена Советом директоров',
                        'en': 'In&nbsp;2020, Norilsk Nickel developed a&nbsp;comprehensive environmental strategy with clear goals for the areas: climate change, air, water, land, waste and biodiversity. In&nbsp;2021, the Strategy was specificated and approved by&nbsp;the Board of&nbsp;Directors'
                    }),
                    linkTitle: localize({
                        'ru': 'Подробнее об&nbsp;экологической стратегии',
                        'en': 'Environmental and Climate Change Strategy'
                    }),
                    linkHref: localize({
                        'ru': 'https://www.nornickel.ru/upload/iblock/1cd/Norilsk_Nickel_Environmental_Strategy_2021_ru.pdf',
                        'en': 'https://www.nornickel.com/upload/iblock/7b2/Norilsk_Nickel_Environmental_Strategy_2021_en.pdf'
                    })
                },
                approach: {
                    title: localize({
                        'ru': 'Наш подход',
                        'en': 'Our approach'
                    }),
                    text: localize({
                        'ru': 'Компания стремится снизить собственное воздействие на&nbsp;окружающую среду, а&nbsp;также проводит социальную политику, направленную на&nbsp;укрепление отношений с&nbsp;местными сообществами в&nbsp;регионах присутствия.',
                        'en': 'The Company strives to&nbsp;reduce its own impact on&nbsp;the environment, and pursues a&nbsp;social policy aimed at&nbsp;strengthening partnerships with local communities in&nbsp;the regions where we&nbsp;operate.'
                    }),
                    linkTitle: localize({
                        'ru': 'Подробнее о&nbsp;программе устойчивого развития',
                        'en': 'Sustainability approach'
                    }),
                    linkHref: localize({
                        'ru': '/sustainability/sdg-support/',
                        'en': '/sustainability/sdg-support/'
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss">
.ecology-section {
    &__glass-block {
        @include breakpoint(tablet) {
            @include margin-level(top, XL);
        }
    }
}

.glass-block {
    border-radius: 0px $boder-radius-l $boder-radius-l $boder-radius-l;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    @include padding-level(top, XL);
    @include padding-level(bottom, XL);
    @include padding-level(left, XL);
    @include padding-level(right, XL);

    &__inner {
        position: relative;
        z-index: 1;
    }

    @include breakpoint(mobile) {
        @include padding-level(left, XS);
        @include padding-level(right, XS);
    }
}
</style>
