/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Rh': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32278)"/><path pid="2" d="M35.784 40h-3.375l-3.7-6.225h-2.9V40h-2.925V23.325h7.325c3.3 0 5.45 2.15 5.45 5.225 0 2.975-1.95 4.575-3.95 4.9L35.784 40zm-5.975-8.8c1.65 0 2.85-1.05 2.85-2.65 0-1.6-1.2-2.65-2.85-2.65h-4v5.3h4zM49.182 40h-2.625v-7.6c0-1.875-.975-2.45-2.45-2.45-1.325 0-2.475.8-3.1 1.625V40h-2.625V23.325h2.625v6.25c.8-.95 2.375-1.95 4.275-1.95 2.6 0 3.9 1.35 3.9 3.825V40z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32278" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
