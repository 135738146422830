/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_labor_productivity': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<rect pid="0" width="38" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M28.75 26.5a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75v-15a.75.75 0 111.5 0v8.847l4.756-4.16a.75.75 0 01.944-.035l5.513 4.135 5.543-4.85a.75.75 0 11.988 1.126l-6 5.25a.75.75 0 01-.944.035l-5.513-4.133-5.287 4.625v3.41H28a.75.75 0 01.75.75z" _fill="#fff"/>'
  }
})
