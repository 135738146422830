<template>
    <div class="media-album">
        <page-404 v-if="error" type="content" class="u-top-margin--3xl u-bottom-margin--3xl">
            <z-caption
                size="m"
                tag="h3"
                weight="700"
                uppercase
                decor-left
                decor-right
            >К сожалению, ничего не найдено</z-caption>
            <p class="page-404__text page-404__text--xl-lite">
                Воспользуйтесь фильром еще раз или вернитесь на <z-link size="2xl" href="/">главную страницу медиатеки.</z-link>
            </p>
        </page-404>
        <div class="container">
            <div class="media-album__info u-entire-width u-bottom-margin--xs u-flex u-justify-content--between u-align-items--center">
                <span class="media-album__date">{{date}}</span>
                <div v-if="locations" class="media-album__places u-flex u-align-items--center">
                    <div
                        v-for="(location, id) of locations"
                        :key="id"
                        class="media-album__place u-flex u-align-items--center u-left-margin--xs"
                    >
                        <z-icon
                            class="u-right-margin--3xs"
                            name="pin"
                            dir="top"
                            width="24"
                            height="24"
                        >
                        </z-icon>
                        <span>{{location}}</span>
                    </div>
                </div>
            </div>
            <p class="media-album__text u-bottom-margin--l text-size-l u-normal">
                <slot name="text"/>
            </p>

            <slot />

            <div v-if="tags.length" class="media-album__tags u-top-margin--m u-bottom-margin--m">
                <tag
                    v-for="(tag, idx) in tags"
                    :key="idx"
                    class="u-right-margin--3xs u-top-margin--2xs"
                    theme="light-blue"
                    :name="tag"
                    :interactive="false"
                ></tag>
            </div>

            <page-changer
                :data="pageChangerData"
            ></page-changer>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'media-album',
    props: {
        tags: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        pageChangerData () {
            return {
                prev: {
                    // name: 'Предыдущая',
                    url: this.navigation.prev
                },
                next: {
                    // name: 'Следующая',
                    url: this.navigation.next
                },
                'back-link': this.navigation['back-link']
            }
        }
    },

    inject: ['navigation', 'locations', 'date', 'error'],

    data () {
        return {
            enter: localize({
                ru: 'Введите',
                en: 'Enter'
            })
        }
    }
}
</script>

<style lang="scss">
.media-album {
    .presentation-block {
        align-items: start;

        &__name {
            color: $token-colors-gray-60;
        }
    }

    .z-filelist-item__icon {
        margin-top: 0;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .presentation-block__size {
        text-transform: uppercase;
        @include margin-level(bottom, S);
    }

    .presentation-block__name {
        font-size: 20px;
    }

    .file-list-wrapper  {
        min-height: 384px;
    }

    .presentation-block__info {
        .z-link {
            font-size: 16px;

            * {
                font-weight: 400;
            }
        }
    }

    .page-changer {
        @include margin-level(top, M, false, true);
    }

    &__date,
    &__place {
        font-size: 13px;
        font-weight: 600;
        line-height: 1.4;
        color: $token-colors-gray-35;
        text-transform: uppercase;
    }

    .z-link span {
        display: flex;
    }

    ._row-gap {
        margin-top: $token-spacers-m !important;
    }

    ._compensate-row-gap {
        margin-top: -$token-spacers-m !important;
    }

    ._row-gap--xl {
        margin-top: $token-spacers-xl !important;
    }

    ._compensate-row-gap--xl {
        margin-top: -$token-spacers-xl !important;
    }
}
</style>
