/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-pdf': {
    width: 34,
    height: 22,
    viewBox: '0 0 34 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.977 16.713h1.494v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618s-1.35-3.618-3.762-3.618H.977v12.006zm7.038-8.388c0 1.35-.972 2.286-2.394 2.286h-3.15V6.039h3.15c1.422 0 2.394.936 2.394 2.286zm3.526 8.388h4.104c3.727 0 6.157-2.574 6.157-5.994 0-3.402-2.43-6.012-6.156-6.012H11.54v12.006zm8.712-5.994c0 2.574-1.692 4.662-4.607 4.662h-2.61V6.039h2.61c2.97 0 4.607 2.088 4.607 4.68zm3.892 5.994h1.494v-5.472h6.246V9.909h-6.246v-3.87h6.372V4.707h-7.866v12.006z" _fill="#0077C8"/>'
  }
})
