<template>
    <div class="news-detail" v-if="newsData.detailText">
        <date-block
            :date="newsData.activeFrom"
            theme="inline"
            class="u-bottom-margin--s"
        />
        <div class="row" v-if="newsData.detailText">
            <div class="col-default-10 col-default-offset-1 col-v-tablet-offset-0 col-v-tablet-12">
                <div class="news-detail__detail html-content">
                    <vue-raw :raw="newsData.detailText" />
                </div>
                <z-filelist
                    size="l"
                    theme="fancy"
                    v-if="newsData.file.link"
                    class="u-top-margin--s"
                >
                    <z-filelist-item
                        :name="`Сохранить пресс-релиз в ${newsData.file.type}`"
                        :href="newsData.file.link"
                        :file-size="newsData.file.size"
                        icon-name="upload"
                    ></z-filelist-item>
                </z-filelist>
                <page-changer
                    v-if="nav"
                    :data="nav"
                    class="u-top-margin--l"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'news-detail-clean-norilsk',
    data () {
        return {
            newsData: this.$root.app.components['clean-norilsk-news-detail'].item,
            nav: this.$root.app.components['clean-norilsk-news-detail'].nav
        }
    },
    mounted () {
        this.SetHeroTitle(this.newsData.name)
    },
    methods: {
        ...mapActions(['SetHeroTitle'])
    }
}
</script>

<style lang="scss">
</style>
