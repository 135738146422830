/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'm5': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15 4.052c-5.853 0-10.618 4.67-10.618 10.448C4.382 20.28 9.147 24.95 15 24.95c5.853 0 10.619-4.67 10.619-10.449 0-5.778-4.766-10.448-10.619-10.448zM5.582 14.5c0-5.096 4.209-9.248 9.418-9.248 5.21 0 9.419 4.152 9.419 9.248 0 5.097-4.21 9.249-9.419 9.249-5.21 0-9.418-4.152-9.418-9.249zm9.848-5.625a.6.6 0 10-1.2 0v7.471a.6.6 0 00.6.6h5.094a.6.6 0 000-1.2H15.43V8.875z" _fill="#1A1B25"/>'
  }
})
