/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/empty': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.25 1.25a2.5 2.5 0 00-2.5 2.5v16a2.5 2.5 0 002.5 2.5h12a2.5 2.5 0 002.5-2.5v-14a.5.5 0 00-.146-.354l-4-4a.5.5 0 00-.354-.146h-10zM19.293 5.5L16.75 2.957V5.5h2.543zM15.75 2.25V6a.5.5 0 00.5.5h3.5v13.25a1.5 1.5 0 01-1.5 1.5h-12a1.5 1.5 0 01-1.5-1.5v-16a1.5 1.5 0 011.5-1.5h9.5z" _fill="#0077C8"/>'
  }
})
