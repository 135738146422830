<template>
    <div class="key-facts-page">
        <section class="u-block--fluid gray-gradient-bg u-right-top-round--l u-top-padding--xl u-bottom-padding--xl u-bottom-margin--2xl">
            <z-caption tag="h2" decor-left decor-right><slot name="main-title"/></z-caption>
            <div class="u-bold key-facts-page__text key-facts-page__text--size-2xl u-bottom-margin--xl">
                <slot name="main-text"/>
            </div>
            <z-button tag="a" size="m" href="/company/strategy/">
                <slot name="main-button"/>
                <template v-slot:right>
                    <z-icon
                        name="arrow-long"
                        dir="right"
                        width="24"
                        height="24"
                    />
                </template>
            </z-button>
        </section>
        <section>
            <z-caption tag="h2" decor-right><slot name="timeline-main-title"/></z-caption>
            <div class="key-facts-page__timeline">
                <article class="key-facts-page__timeline-item key-facts-page__timeline-item--iconed">
                    <z-icon name="check-circle" width="24" height="24"></z-icon>
                    <div class="key-facts-page__timeline-content">
                        <div class="u-bottom-margin--2xs">
                            <z-caption size="xl"><slot name="timeline-1-title"/></z-caption>
                        </div>
                        <slot name="timeline-1-content"/>
                        <svg viewBox="0 0 972 259" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <text fill="#1A1B25" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="18" font-weight="bold" letter-spacing="0em"><tspan x="81.036" y="16.7524">&#x41f;&#x41e;&#x417;&#x418;&#x426;&#x418;&#x418; &#x41d;&#x410; &#x420;&#x42b;&#x41d;&#x41a;&#x415; &#x41c;&#x415;&#x422;&#x410;&#x41b;&#x41b;&#x41e;&#x412;:</tspan></text>
                            <path d="M76.5 176C98.8675 176 117 157.868 117 135.5C117 113.132 98.8675 95 76.5 95C54.1325 95 36 113.132 36 135.5C36 157.868 54.1325 176 76.5 176Z" fill="#858DA6"/>
                            <path d="M76.5 176C98.8675 176 117 157.868 117 135.5C117 113.132 98.8675 95 76.5 95C54.1325 95 36 113.132 36 135.5C36 157.868 54.1325 176 76.5 176Z" fill="white" fill-opacity="0.6"/>
                            <text fill="#1A1B25" xml:space="preserve" style="white-space: pre" font-family="Roboto Mono" font-size="18" letter-spacing="0em"><tspan x="63" y="142.982">44%</tspan></text>
                            <path d="M97 120.5H62C53.9919 120.5 47.5 126.992 47.5 135C47.5 143.008 53.9919 149.5 62 149.5H97C105.008 149.5 111.5 143.008 111.5 135C111.5 126.992 105.008 120.5 97 120.5Z" stroke="#1A1B25"/>
                            <text fill="#08AD83" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="18" letter-spacing="0em"><tspan x="132" y="107.252">&#x2116;1 &#x43c;&#x435;&#x441;&#x442;&#x43e;</tspan></text>
                            <text fill="#1A1B25" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="32" font-weight="bold" letter-spacing="0em"><tspan x="132" y="142.887">Pd</tspan></text>
                            <text fill="#737B91" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="16" letter-spacing="0em"><tspan x="132" y="170.949">&#x41f;&#x430;&#x43b;&#x43b;&#x430;&#x434;&#x438;&#x439;</tspan></text>
                            <path d="M284 157C296.15 157 306 147.15 306 135C306 122.85 296.15 113 284 113C271.85 113 262 122.85 262 135C262 147.15 271.85 157 284 157Z" fill="#8BBEE2"/>
                            <text fill="#1A1B25" xml:space="preserve" style="white-space: pre" font-family="Roboto Mono" font-size="18" letter-spacing="0em"><tspan x="310" y="142.982">22%</tspan></text>
                            <path d="M344 120.5H309C300.992 120.5 294.5 126.992 294.5 135C294.5 143.008 300.992 149.5 309 149.5H344C352.008 149.5 358.5 143.008 358.5 135C358.5 126.992 352.008 120.5 344 120.5Z" stroke="#1A1B25"/>
                            <text fill="#08AD83" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="18" letter-spacing="0em"><tspan x="379" y="107.252">&#x2116;1 &#x43c;&#x435;&#x441;&#x442;&#x43e;</tspan></text>
                            <text fill="#4F4F4F" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="32" font-weight="bold" letter-spacing="0em"><tspan x="379" y="142.887">Ni</tspan></text>
                            <text fill="#737B91" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="16" letter-spacing="0em"><tspan x="379" y="170.949">&#x41d;&#x438;&#x43a;&#x435;&#x43b;&#x44c;&#10;</tspan><tspan x="379" y="189.949">(&#x432;&#x44b;&#x441;&#x43e;&#x43a;&#x43e;&#x441;&#x43e;&#x440;&#x442;&#x43d;&#x44b;&#x439;)</tspan></text>
                            <path d="M527 153C536.941 153 545 144.941 545 135C545 125.059 536.941 117 527 117C517.059 117 509 125.059 509 135C509 144.941 517.059 153 527 153Z" fill="#C1C6D2"/>
                            <text fill="#1A1B25" xml:space="preserve" style="white-space: pre" font-family="Roboto Mono" font-size="18" letter-spacing="0em"><tspan x="547" y="142.982">15%</tspan></text>
                            <path d="M581 120.5H546C537.992 120.5 531.5 126.992 531.5 135C531.5 143.008 537.992 149.5 546 149.5H581C589.008 149.5 595.5 143.008 595.5 135C595.5 126.992 589.008 120.5 581 120.5Z" stroke="#1A1B25"/>
                            <text fill="#08AD83" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="18" letter-spacing="0em"><tspan x="616" y="107.252">&#x2116;3 &#x43c;&#x435;&#x441;&#x442;&#x43e;</tspan></text>
                            <text fill="#1A1B25" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="32" font-weight="bold" letter-spacing="0em"><tspan x="616" y="142.887">Pt</tspan></text>
                            <text fill="#737B91" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="16" letter-spacing="0em"><tspan x="616" y="170.949">&#x41f;&#x43b;&#x430;&#x442;&#x438;&#x43d;&#x430;</tspan></text>
                            <path d="M763 140C765.761 140 768 137.761 768 135C768 132.239 765.761 130 763 130C760.239 130 758 132.239 758 135C758 137.761 760.239 140 763 140Z" fill="#FED441"/>
                            <text fill="#1A1B25" xml:space="preserve" style="white-space: pre" font-family="Roboto Mono" font-size="18" letter-spacing="0em"><tspan x="762" y="142.982">  2%</tspan></text>
                            <path d="M806 120.5H761C752.992 120.5 746.5 126.992 746.5 135C746.5 143.008 752.992 149.5 761 149.5H806C814.008 149.5 820.5 143.008 820.5 135C820.5 126.992 814.008 120.5 806 120.5Z" stroke="#4F4F4F"/>
                            <text fill="#08AD83" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="18" letter-spacing="0em"><tspan x="841" y="107.252">&#x2116;11 &#x43c;&#x435;&#x441;&#x442;&#x43e;</tspan></text>
                            <text fill="#1A1B25" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="32" font-weight="bold" letter-spacing="0em"><tspan x="841" y="142.887">&#x421;u</tspan></text>
                            <text fill="#737B91" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="16" letter-spacing="0em"><tspan x="841" y="170.949">&#x41c;&#x435;&#x434;&#x44c;</tspan></text>
                            <path d="M67 12H11C5.477 12 1 16.48 1 22V258H961C966.52 258 971 253.52 971 248V22C971 16.48 966.52 12 961 12H449" stroke="#1A1B25" stroke-width="1.2"/>
                        </svg>
                    </div>
                </article>
                <article class="key-facts-page__timeline-item key-facts-page__timeline-item--iconed">
                    <z-icon name="check-circle" width="24" height="24"></z-icon>
                    <div class="key-facts-page__timeline-content">
                        <div class="u-bottom-margin--2xs">
                            <z-caption size="xl"><slot name="timeline-2-title"/></z-caption>
                        </div>
                        <p class="key-facts-page__text key-facts-page__text--size-l u-bottom-margin--m"><slot name="timeline-2-subtitle"/></p>
                        <z-image src="" alt="chart"></z-image>
                    </div>
                </article>
                <article class="key-facts-page__timeline-item key-facts-page__timeline-item--iconed">
                    <z-icon name="check-circle" width="24" height="24"></z-icon>
                    <div class="key-facts-page__timeline-content">
                        <div class="u-bottom-margin--2xs">
                            <z-caption size="xl"><slot name="timeline-3-title"/></z-caption>
                        </div>
                        <p class="key-facts-page__text key-facts-page__text--size-l u-bottom-margin--2xs"><slot name="timeline-3-subtitle"/></p>
                        <div class="u-top-margin--m u-top-right-round--l">
                            <div class="row">
                                <div class="col-default-8 col-mobile-12">
                                    <z-image src="/images/investors/key-facts/image-1.png" alt="chart"></z-image>
                                </div>
                                <div class="col-default-4 col-mobile-12">
                                    <slot name="timeline-3-component-1"/>
                                </div>
                            </div>
                        </div>
                        <div class="u-bottom-margin--m">
                            <div class="row">
                                <div class="col-default-6 col-mobile-12">
                                    <div class="u-top-padding--m">
                                        <icon-block
                                            icon-name="mining-processing"
                                            columnItem
                                            size="s"
                                        >
                                            <slot name="timeline-3-component-2-1"/>
                                        </icon-block>
                                    </div>
                                </div>
                                <div class="col-default-6 col-mobile-12">
                                    <div class="u-top-padding--m">
                                        <icon-block
                                            icon-name="storage"
                                            columnItem
                                            size="s"
                                        >
                                            <slot name="timeline-3-component-2-2"/>
                                        </icon-block>
                                    </div>
                                </div>
                                <div class="col-default-6 col-mobile-12">
                                    <div class="u-top-padding--m">
                                        <icon-block
                                            icon-name="niokr"
                                            columnItem
                                            size="s"
                                        >
                                            <slot name="timeline-3-component-2-3"/>
                                        </icon-block>
                                    </div>
                                </div>
                                <div class="col-default-6 col-mobile-12">
                                    <div class="u-top-padding--m">
                                        <icon-block
                                            icon-name="financial-results"
                                            columnItem
                                            size="s"
                                        >
                                            <slot name="timeline-3-component-2-4"/>
                                        </icon-block>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="key-facts-page__timeline-content-item">
                            <card-link link="/company/profile/" image="/images/investors/key-facts/image-3.png" style="max-width: 1100px">
                                <slot name="timeline-3-component-3"/>
                            </card-link>
                        </div>
                    </div>
                </article>
                <article class="key-facts-page__timeline-item key-facts-page__timeline-item--iconed">
                    <z-icon name="check-circle" width="24" height="24"></z-icon>
                    <div class="key-facts-page__timeline-content">
                        <div class="u-bottom-margin--2xs">
                            <z-caption size="xl"><slot name="timeline-4-title"/></z-caption>
                        </div>
                        <p class="key-facts-page__text key-facts-page__text--size-l u-bottom-margin--m"><slot name="timeline-4-subtitle"/></p>
                        <div>
                            <div class="row">
                                <div class="col-default-6 col-mobile-12">
                                    <icon-block
                                        icon-name="logistics-sales"
                                        columnItem
                                        size="s"
                                    >
                                        <p>Интеграция добычи и&nbsp;производства рафинированных металлов в&nbsp;совокупности с&nbsp;собственными объектами логистической инфраструктуры (морской и&nbsp;речной флот)</p>
                                    </icon-block>
                                </div>
                                <div class="col-default-6 col-mobile-12">
                                    <icon-block
                                        icon-name="metal-production"
                                        columnItem
                                        size="s"
                                    >
                                        <p>Cамообеспеченность в&nbsp;отношении энергетики, топлива, водоснабжения и&nbsp;ключевого сырья&nbsp;&mdash; значительно более низкая доля в&nbsp;денежных затратах в&nbsp;сравнении с&nbsp;конкурентами</p>
                                    </icon-block>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article class="key-facts-page__timeline-item key-facts-page__timeline-item--iconed">
                    <z-icon name="check-circle" width="24" height="24"></z-icon>
                    <div class="key-facts-page__timeline-content">
                        <div class="u-bottom-margin--2xs">
                            <z-caption size="xl"><slot name="timeline-5-title"/></z-caption>
                        </div>
                        <p v-if="$slots['timeline-5-subtitle']" class="key-facts-page__text key-facts-page__text--size-l u-bottom-margin--m"><slot name="timeline-5-subtitle"/></p>
                        <div class="u-top-margin--m u-top-right-round--l">
                            <div class="row">
                                <div class="col-default-8 col-mobile-12">
                                    <z-image src="/images/investors/key-facts/image-2.png" alt="chart"></z-image>
                                </div>
                                <div class="col-default-4 col-mobile-12">
                                    <slot name="timeline-5-component-1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article class="key-facts-page__timeline-item key-facts-page__timeline-item--iconed">
                    <z-icon name="check-circle" width="24" height="24"></z-icon>
                    <div class="key-facts-page__timeline-content">
                        <div class="u-bottom-margin--2xs">
                            <z-caption size="xl"><slot name="timeline-6-title"/></z-caption>
                        </div>
                        <p class="key-facts-page__text key-facts-page__text--size-l u-bottom-margin--m"><slot name="timeline-6-subtitle"/></p>
                        <div class="gray-gradient-bg u-left-padding--2xl u-right-padding--2xl u-top-padding--xs u-bottom-padding--xs u-all-round--s">
                            <div class="row">
                                <div class="col-default-5 col-v-tablet-12 col-mobile-12">
                                    <div class="u-flex u-flex--row u-flex--no-wrap ">
                                        <div class="key-facts-page__text key-facts-page__text--size-5xl">64</div>
                                        <div class="key-facts-page__text--size-3xl u-left-margin--3xs">%</div>
                                        <div class="key-facts-page__text--size-m u-left-margin--2xs">
                                            {{localize.article6.fact1.part1}} <z-link size="l" href="/investors/reports-and-results/#2019">EBITDA</z-link> {{localize.article6.fact1.part2}}
                                            <div class="key-facts-page__text--color-gray key-facts-page__text--size-sm">{{localize.article6.fact1.part3}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-default-5 col-v-tablet-12 col-mobile-12">
                                    <div class="u-flex u-flex--row u-flex--no-wrap ">
                                        <div class="key-facts-page__text key-facts-page__text--size-5xl">0.7</div>
                                        <div class="key-facts-page__text--size-3xl u-left-margin--3xs">x</div>
                                        <div class="key-facts-page__text--size-m u-left-margin--2xs">
                                            {{localize.article6.fact2.part1}} <z-link size="m" href="#">{{localize.article6.fact2.part2}}</z-link>
                                            <div class="key-facts-page__text--color-gray key-facts-page__text--size-sm">{{localize.article6.fact2.part3}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article class="key-facts-page__timeline-item key-facts-page__timeline-item--iconed">
                    <z-icon name="check-circle" width="24" height="24"></z-icon>
                    <div class="key-facts-page__timeline-content">
                        <div class="u-bottom-margin--2xs">
                            <z-caption size="xl"><slot name="timeline-7-title"/></z-caption>
                        </div>
                        <p class="key-facts-page__text key-facts-page__text--size-l u-bottom-margin--2xs"><slot name="timeline-7-subtitle"/></p>
                        <div class="row">
                            <div class="col-default-6 col-v-tablet-12 col-mobile-12">
                                <div class="u-top-padding--2xs u-bottom-padding--2xs">
                                    <z-list>
                                        <z-list-item><slot name="timeline-7-list-1"/></z-list-item>
                                        <z-list-item><slot name="timeline-7-list-2"/></z-list-item>
                                    </z-list>
                                </div>
                            </div>
                            <div class="col-default-5 col-v-tablet-12 col-mobile-12">
                                <div class="u-flex u-flex--row u-flex--no-wrap ">
                                    <div class="key-facts-page__text key-facts-page__text--size-5xl">20</div>
                                    <div class="key-facts-page__text--size-3xl u-left-margin--3xs">%</div>
                                    <div class="key-facts-page__text--size-m u-left-margin--2xs"><slot name="timeline-7-component-1"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    </div>
</template>
<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'key-facts-page',
    data () {
        return {
            localize: {
                article6: {
                    fact1: {
                        part1: localize({
                            'ru': 'рентабельность по',
                            'en': ''
                        }),
                        part2: localize({
                            'ru': '',
                            'en': 'margin'
                        }),
                        part3: localize({
                            'ru': 'По итогам 1П 2023',
                            'en': 'as of 1H 2023'
                        })
                    },
                    fact2: {
                        part1: localize({
                            'ru': 'показатель',
                            'en': ''
                        }),
                        part2: localize({
                            'ru': 'Чистый долг/EBITDA',
                            'en': 'Net Debt/EBITDA'
                        }),
                        part3: localize({
                            'ru': 'На конец 1П 2023',
                            'en': 'by the end of 1H 2023'
                        })
                    }
                },
                article7: {
                    fact: {
                        part1: localize({
                            'ru': 'пороговый',
                            'en': 'Minimum'
                        }),
                        part2: localize({
                            'ru': 'для новых проектов',
                            'en': 'Hurdle Rate for New projects'
                        })
                    }
                }
            }
        }
    }
}
</script>
<style lang="scss" src="./index.scss"></style>
