/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text/rub': {
    width: 44,
    height: 22,
    viewBox: '0 0 44 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.257 16.715h1.764l-3.24-4.896c1.638-.144 3.114-1.332 3.114-3.492 0-2.214-1.566-3.618-3.762-3.618H5.309v12.006h1.494v-4.77h2.394l3.06 4.77zm.09-8.388c0 1.35-.972 2.304-2.394 2.304h-3.15v-4.59h3.15c1.422 0 2.394.936 2.394 2.286zm3.913 3.726c0 2.952 1.638 4.878 4.896 4.878s4.896-1.908 4.896-4.896V4.709h-1.494v7.308c0 2.196-1.188 3.582-3.402 3.582-2.214 0-3.402-1.386-3.402-3.582V4.709H16.26v7.344zm12.603 4.662h5.472c2.196 0 3.474-1.314 3.474-3.24 0-1.44-1.062-2.754-2.34-2.952 1.098-.234 2.106-1.242 2.106-2.754 0-1.8-1.278-3.06-3.366-3.06h-5.346v12.006zm7.164-8.73c0 1.098-.72 1.926-2.07 1.926h-3.6v-3.87h3.6c1.35 0 2.07.864 2.07 1.944zm.234 5.328c0 1.278-.828 2.07-2.232 2.07h-3.672v-4.14h3.672c1.476 0 2.232.972 2.232 2.07z" _fill="#0077C8"/>'
  }
})
