/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/nornik': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9911_1003)"/><path pid="2" d="M44.6 36.524a6.364 6.364 0 01-2.779 1.636V25.84a6.37 6.37 0 012.779 1.635 6.36 6.36 0 011.871 4.524c0 1.71-.664 3.318-1.871 4.525zm-12.673-3.979v-6.583l8.145 5.495v6.582l-8.145-5.494zM30.18 38.16a6.347 6.347 0 01-2.778-1.636A6.353 6.353 0 0125.527 32a6.36 6.36 0 011.875-4.526 6.367 6.367 0 012.778-1.634v12.32zm9.892-14.306v5.495l-8.145-5.495a8.147 8.147 0 100 16.293V34.65l8.145 5.495a8.146 8.146 0 100-16.293z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9911_1003" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
