/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'img': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.951 4.2H5.002A1.8 1.8 0 003.2 6v8.905l2.39-1.853a1.356 1.356 0 011.534-.127l3.237 1.88L15.07 10.7h.001c.194-.17.433-.273.681-.307.248-.035.504-.002.735.1l.006.002 4.259 1.913v-6.41a1.8 1.8 0 00-1.8-1.799zm1.8 9.524l-4.745-2.132a.169.169 0 00-.087-.01.122.122 0 00-.06.024l-5.033 4.386-.324.282-.371-.216-3.609-2.095a.158.158 0 00-.09-.017.127.127 0 00-.07.025l-.012.01-.013.01L3.2 16.425v2.197a1.8 1.8 0 001.8 1.8h13.951a1.8 1.8 0 001.8-1.8v-4.897zM5.002 3a3 3 0 00-3 3V18.62a3 3 0 003 3h13.95a3 3 0 003-3V6a3 3 0 00-3-3H5.002zm1.791 4.792a.546.546 0 10-.772-.772.546.546 0 00.772.772zm.849.848a1.746 1.746 0 10-2.47-2.469 1.746 1.746 0 002.47 2.47z" _fill="#0077C8"/>'
  }
})
