/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search/white': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<circle pid="0" cx="18" cy="18" r="18" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16.75 10a6.75 6.75 0 104.213 12.024l2.757 2.756a.75.75 0 001.06-1.06l-2.756-2.757A6.75 6.75 0 0016.75 10zm3.782 10.392a5.25 5.25 0 10-.14.14.76.76 0 01.14-.14z" _fill="#0077C8"/>'
  }
})
