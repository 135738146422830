<template>
    <div class="search-page-content">
        <div v-for="(item, index) in items" class="search-page-content__card" :key="`search-${index}`">
            <z-link class="search-page-content__title" :href="item.url" v-html="item.title"/>
            <p class="search-page-content__description" v-html="item.text"/>
            <span class="search-page-content__date">{{item.date | momentFilter('DD MMMM YYYY')}} г.</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'search-content',
    props: {
        items: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss">
.search-page-content {
    &__card {
        &:not(:last-child) {
            @include margin-level(bottom, XL)
        }
        &:last-child {
            @include margin-level(bottom, M)
        }
    }
    &__title {
        display: block !important;
        font-weight: 600;
        font-size: 20px;
        @include margin-level(bottom, XS);
    }
    &__description {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
        color: $token-colors-gray-60;
        margin-bottom: $token-spacers-2-xs;

        @include breakpoint(mobile) {
            font-size: 18px;
        }
    }
    &__date {
        font-weight: 600;
        font-size: 13px;
        line-height: 1.4;
        text-transform: uppercase;
        color: $token-colors-gray-35;
    }
}

</style>
