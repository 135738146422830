/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-png': {
    width: 37,
    height: 22,
    viewBox: '0 0 37 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.86 16.716h1.494v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618S8.096 4.71 5.684 4.71H.86v12.006zm7.038-8.388c0 1.35-.972 2.286-2.394 2.286h-3.15V6.042h3.15c1.422 0 2.394.936 2.394 2.286zm12.023 8.388h1.44V4.71h-1.494v9.378L12.955 4.71h-1.53v12.006h1.494V7.14l7.002 9.576zm3.762-5.994c0 3.726 2.754 6.228 6.174 6.228 1.962 0 3.6-.828 4.716-2.07v-4.338h-5.616v1.332h4.122v2.448c-.594.594-1.782 1.296-3.222 1.296-2.628 0-4.626-2.052-4.626-4.896 0-2.862 1.998-4.878 4.626-4.878 1.44 0 2.718.684 3.474 1.656l1.188-.738c-1.062-1.314-2.538-2.25-4.662-2.25-3.42 0-6.174 2.484-6.174 6.21z" _fill="#0077C8"/>'
  }
})
