var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",class:_vm.classObject},[_c('div',{class:[
            'modal-image__img',
            { 'modal-image__img--wide' : _vm.wide }
        ]},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.imgLoaded),expression:"imgLoaded"}],ref:"img",style:(("max-height: " + _vm.maxHeight + "px")),attrs:{"alt":_vm.data.name,"src":_vm.data.image.full}}),_vm._v(" "),(!_vm.imgLoaded)?_c('z-preloader'):_vm._e()],1),_vm._v(" "),(_vm.data.name || _vm.data.detail)?_c('div',{staticClass:"modal-image__info"},[(_vm.data.name)?_c('h4',{ref:"name",staticClass:"modal-image__name",domProps:{"innerHTML":_vm._s(_vm.data.name)}}):_vm._e(),_vm._v(" "),_c('div',{ref:"detail",staticClass:"modal-image__detail",domProps:{"innerHTML":_vm._s(_vm.data.detail)}})]):_vm._e(),_vm._v(" "),(_vm.download)?_c('span',{staticClass:"modal-image__download"},[_c('z-link',{directives:[{name:"tippy-tooltip",rawName:"v-tippy-tooltip",value:({
                arrow: true,
                theme : 'transparent',
                placement: 'top-start'
            }),expression:"{\n                arrow: true,\n                theme : 'transparent',\n                placement: 'top-start'\n            }"}],staticClass:"ar-card__icon u-inline",attrs:{"download":"","blank":"","href":_vm.data.image.full,"content":_vm.$t('text.download')}},[_c('z-icon',{attrs:{"width":"48","height":"48","name":"download"}})],1),_vm._v(" "),(_vm.data.size)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.data.size)}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }