/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minilogo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.612 15.11a5.079 5.079 0 01-2.216 1.305v-9.83c.828.234 1.59.678 2.216 1.305a5.075 5.075 0 011.494 3.61c0 1.364-.53 2.646-1.494 3.61zM8.501 11.935V6.682L15 11.067v5.252l-6.5-4.384zm-1.394 4.48A5.064 5.064 0 014.89 15.11a5.069 5.069 0 01-1.496-3.61c0-1.363.532-2.646 1.496-3.61a5.08 5.08 0 012.217-1.304v9.83zM15 5v4.384L8.5 5a6.5 6.5 0 100 13v-4.384L15 18a6.5 6.5 0 100-13z" _fill="#0077C8"/>'
  }
})
