<template>
    <z-filelist>
        <z-filelist-item
            v-for="(item, index) in items" :key="`file-${index}`"
            :name="item.name"
            :href="item.file.link"
            :icon-name="`file/${item.file.type}`"
            :file-size="item.file.size"
         ></z-filelist-item>
    </z-filelist>
</template>

<script>
export default {
    name: 'procurement-plan-page',
    data () {
        return {
            items: this.$root.app.components?.['procurement-plan'] || []
        }
    }
}
</script>
