/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/png': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM12.582 32h1.404v-2.988H16.1c1.668 0 2.616-1.152 2.616-2.508s-.936-2.508-2.616-2.508h-3.517V32zm4.692-5.496c0 .768-.576 1.272-1.368 1.272h-1.92v-2.544h1.92c.792 0 1.368.504 1.368 1.272zM25.465 32h1.355v-8.004h-1.404v5.556l-4.092-5.556h-1.44V32h1.404v-5.712L25.463 32zm2.696-3.996c0 2.508 1.884 4.152 4.2 4.152 1.392 0 2.508-.576 3.3-1.452v-3h-3.792v1.236h2.388v1.248c-.36.336-1.08.72-1.896.72-1.584 0-2.76-1.224-2.76-2.904s1.176-2.892 2.76-2.892c.9 0 1.656.468 2.076 1.068l1.152-.648c-.648-.924-1.668-1.668-3.228-1.668-2.316 0-4.2 1.632-4.2 4.14z" _fill="#737B91"/>'
  }
})
