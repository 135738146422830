<template>
    <div class="central-tenders">
        <!-- <z-caption tag="h2" class="u-bottom-margin--m-important" size="2xl" weight="400">
            Наша компания продает имущество для разных компаний, сюда нужен текст о&nbsp;том что предлагает эта
            страница.
        </z-caption> -->
        <card-link class="u-bottom-margin--m" link="/suppliers/warning/" theme="warning" image="/images/spy.svg">
            Предупреждение о&nbsp;подложных обращениях от&nbsp;лица компании
        </card-link>
        <z-caption v-if="filters.value.archive" tag="h3">Архив закупок</z-caption>
        <central-tenders-filter :filters-data="filtersData" @update-filters="filtersHandler" ref="filter"/>
        <template v-if="items.length && !loading">
            <central-tenders-list :items="items"/>
            <z-button
                :class="filters.value.archive ? 'u-bottom-margin--xs': 'u-bottom-margin--xl'"
                kind="text"
                @click="archiveHandler">
                <template #left>
                    <z-icon name="arrow-long" dir="right"/>
                </template>
                {{filters.value.archive ? 'Вернутся к&nbsp;актуальным закупкам' : 'Посмотреть архив закупок'}}
            </z-button>
            <div v-if="filters.value.archive" class="u-bottom-margin--xl">
                <z-link href="/suppliers/tenders/central/archive/">
                    Централизованные закупки Группы, срок подачи предложений которых истек до&nbsp;29.01.2018
                </z-link>
            </div>
            <z-pagination
                v-if="nav"
                :data="{
                    currentPage: nav.current,
                    totalPages: nav.total,
                    pageRange: 1
                }"
                @change-page="pageHandler"
            />
        </template>
        <template v-else-if="loading">
            <z-preloader v-if="loading"/>
        </template>
        <template v-else>
            <z-not-found class="u-bottom-margin--xl"/>
            <z-button kind="text" @click="archiveHandler">
                <template #left>
                    <z-icon name="arrow-long" dir="right"/>
                </template>
                {{filters.value.archive ? 'Вернутся к&nbsp;актуальным закупкам' : 'Посмотреть архив закупок'}}
            </z-button>
        </template>
    </div>
</template>

<script>
import CentralTendersFilter from '@/views/CentralTenders/components/CentralTendersFilter.vue'
import CentralTendersList from '@/views/CentralTenders/components/CentralTendersList.vue'
import {getCentralTenders} from '@/api/centralTenders'
import {debounce} from 'throttle-debounce'
import {showNotyfications} from '@/utils/notifications'

export default {
    name: 'CentralTenders',
    components: {
        CentralTendersList,
        CentralTendersFilter
    },
    provide () {
        return {
            filters: this.filters
        }
    },
    data () {
        return {
            filtersData: this.$root.app.components?.['central-tenders']?.filters || null,
            items: this.$root.app.components?.['central-tenders']?.items || [],
            nav: this.$root.app.components?.['central-tenders']?.nav || null,
            page: 1,
            isMore: false,
            loading: false,
            filters: {
                value: {
                    methods: [],
                    customers: [],
                    organizers: [],
                    applicationMethod: [],
                    q: '',
                    dateFrom: '',
                    dateTo: '',
                    archive: null
                }
            }
        }
    },
    methods: {
        send: debounce(300, function (params) {
            this.loading = true
            getCentralTenders(params)
                .then((response) => {
                    this.filtersData = response.filters
                    this.nav = response.nav
                    this.page = this.nav.current

                    if (this.isMore) {
                        this.items = [...this.items, ...response.items]
                        this.isMore = false
                    } else {
                        this.items = response.items
                    }
                })
                .catch((err) => {
                    showNotyfications('error', err)
                })
                .finally(() => {
                    this.loading = false
                })
        }),
        archiveHandler () {
            this.page = 1
            if (this.filters.value.archive) {
                this.filters.value.archive = null
            } else {
                this.filters.value.archive = 'Y'
            }

            this.$refs.filter.reset()
        },
        filtersHandler () {
            this.page = 1
            this.send({
                ...this.filters.value,
                page: this.page
            })
        },
        pageHandler (payload) {
            this.isMore = payload.type === 'load-more'
            this.page = payload.page
            this.send({
                ...this.filters.value,
                page: this.page
            })
        }
    }
}
</script>

<style lang="scss">
.central-tenders {
    max-width: 1360px;
}
</style>
