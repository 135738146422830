<template>
    <button class="pdf-btn" @click="getPdf" v-if="apiUrl">
        <z-icon width="42" height="42" name="file/pdf"></z-icon>
        <span class="pdf-btn__text" v-html="text.downloadFlyer"></span>
    </button>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'pdf-button',
    data () {
        return {
            data: this.$root.app.components?.products,
            text: {
                downloadFlyer: localize({
                    ru: 'Cкачать листовку',
                    en: 'Download leaflet'
                })
            }
        }
    },
    computed: {
        apiUrl () {
            const metal = this.data.item?.metal.code
            const marka = this.data.item?.code

            if (!(metal && marka)) return ''

            return `/api/products/pdf/${metal}/${marka}/`
        }
    },
    methods: {
        getPdf () {
            fetch(this.apiUrl)
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    console.log(data)
                    if (data.link) {
                        this.downloadPdf(data.link)
                    } else {
                        console.log('error data from back')
                    }
                })
        },
        downloadPdf (url) {
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('target', '_blank')
            link.click()
        }
    }
}
</script>

<style lang="scss">
.pdf-btn {
    border: 1px solid $token-colors-blue;
    border-radius: 50px;
    padding: 10px 32px 10px 20px;
    flex-shrink: 0;
    margin-left: $token-spacers-2-xs;
    margin-top: -6px;
    background: none;
    color: $token-colors-blue;

    @include breakpoint (tablet) {
        margin: 0;
        margin-top: 16px;
        padding: 8px 16px 8px 16px;
    }

    &__text {
        line-height: 1.2 !important;
        font-weight: 600;
    }

    .z-icon {
        margin: 0;

        @include breakpoint (tablet) {
            width: 32px !important;
            height: 32px !important;
        }
    }
}
</style>
