<template>
    <div
        class="governance-detail container"
        v-if="person">
        <layout>
            <template v-slot:content>
                <content-container>
                    <div class="person-block">
                        <div class="person-header">
                            <z-image
                                class="person-header__img-holder"
                                :src="person.image"
                                :alt="person.name"></z-image>
                            <div class="person-header__content">
                                <img
                                    class="person-header__content-logo"
                                    :src="getLogoSrc"
                                    alt="nornickel-logo" />
                                <h2
                                    class="person-header__content-title u-collapse--all"
                                    v-html="person.name"></h2>
                                <p
                                    v-if="person.position"
                                    class="person-header__content-position gray-40-text u-collapse--bottom u-top-margin--2xs"
                                    v-html="person.position"></p>
                            </div>
                        </div>
                        <div class="person-content">
                            <div class="person-content__inner">
                                <div
                                    class="person-content__inner-detail u-top-margin--m"
                                    v-if="person.detail"
                                    v-html="person.detail"></div>
                                <template v-if="person.positions && person.positions.length">
                                    <z-caption
                                        tag="h3"
                                        size="3xl"
                                        decor-right
                                        class="u-top-margin--xl u-bottom-margin--s">
                                        <vue-raw :raw="text.position" />
                                    </z-caption>
                                    <z-list>
                                        <z-list-item
                                            v-for="(item, index) in person.positions"
                                            :key="index">
                                            <vue-raw :raw="item" />
                                        </z-list-item>
                                    </z-list>
                                </template>

                                <template v-if="person.education && person.education.length">
                                    <z-caption
                                        tag="h3"
                                        size="3xl"
                                        decor-right
                                        class="u-top-margin--xl u-bottom-margin--s">
                                        <vue-raw :raw="text.education" />
                                    </z-caption>
                                    <div class="person-content__education">
                                        <z-caption
                                            v-for="(item, index) in person.education"
                                            :key="index"
                                            tag="p"
                                            size="m"
                                            class="u-bottom-margin--s">
                                            <vue-raw :raw="item" />
                                        </z-caption>
                                    </div>
                                </template>

                                <template v-if="person.experience && person.experience.length">
                                    <z-caption
                                        tag="h3"
                                        size="3xl"
                                        decor-right
                                        class="u-top-margin--xl u-bottom-margin--s">
                                        <vue-raw :raw="text.experience" />
                                    </z-caption>
                                    <z-list
                                        class="person-timeline"
                                        theme="timeline">
                                        <z-list-item
                                            v-for="(item, index) in person.experience"
                                            :key="index">
                                            <vue-raw :raw="item"></vue-raw>
                                        </z-list-item>
                                    </z-list>
                                </template>

                                <template v-if="person['add-info'] && person['add-info'].length">
                                    <z-caption
                                        tag="h3"
                                        size="3xl"
                                        decor-right
                                        class="u-top-margin--xl u-bottom-margin--s">
                                        <vue-raw :raw="person['title-add-info']" />
                                    </z-caption>
                                    <div class="person-content__education">
                                        <p class="u-bottom-margin--s">
                                            <vue-raw :raw="person['add-info']" />
                                        </p>
                                    </div>
                                </template>

                                <bods-rewards
                                    class="u-top-margin--xl hide-me--default show-me--tablet" />
                            </div>
                        </div>
                    </div>
                </content-container>
            </template>
            <template v-slot:aside>
                <div
                    v-if="person['position-file'] && person['position-file'].link"
                    class="u-bottom-margin--xl">
                    <z-link
                        :href="person['position-file'].link"
                        :title="text.protocol"
                        target="_blank">
                        <vue-raw :raw="text.protocol" />
                    </z-link>
                </div>
                <bods-rewards class="hide-me--tablet" />
                <slot name="aside" />
            </template>
        </layout>
        <page-changer
            v-if="person.nav && pageChanger"
            :data="person.nav"
            class="governance-detail__page-changer u-top-margin--l" />
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'governance-detail',
    mixins: [mixinDevice],
    props: {
        pageChanger: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            person: {},
            text: {
                position: localize({
                    ru: 'Должность',
                    en: 'Position'
                }),
                education: localize({
                    ru: 'Образование',
                    en: 'Education'
                }),
                experience: localize({
                    ru: 'Опыт работы',
                    en: 'Experience'
                }),
                protocol: localize({
                    ru: 'Выписка из протокола',
                    en: 'Protocol excerpt'
                })
            }
        }
    },
    created () {
        if (this.$root.app.components['governance-detail']) {
            this.person = this.$root.app.components['governance-detail'] || {}
        }
    },
    computed: {
        getLogoSrc () {
            return `/images/logo/logo-${this.lang}.svg`
        }
    }
}
</script>

<style lang="scss">
.governance-detail {
    &__page-changer {
        .page-changer__link.is-disabled {
            opacity: 0;

            @include breakpoint(v-tablet) {
                display: none;
            }
        }
    }
}

.person-header {
    $parent: &;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    border-top-left-radius: 80px;
    background: linear-gradient(110.75deg, #f4f6f9 23.84%, #e6e9ee 100%),
        linear-gradient(0deg, #f5f5f5, #f5f5f5);
    align-items: flex-start;
    @include padding-level(left, XL);
    @include padding-level(bottom, XL);
    @include margin-level(top, 2XL);

    @include breakpoint(laptop) {
        @include margin-level(top, XL);
    }
    @include breakpoint(v-tablet) {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        @include padding-level(bottom, M);
    }

    &__img-holder {
        flex: 0 0 31%;
        border-radius: 50%;
        overflow: hidden;
        max-width: 300px;
        min-width: 200px;
        margin-top: -80px;

        @include breakpoint(laptop) {
            max-width: 200px;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: auto;
        @include padding-level(top, XL);
        @include padding-level(right, XL);
        @include margin-level(left, 2XL);

        @include breakpoint(laptop) {
            @include padding-level(top, M);
            @include margin-level(left, M);
        }

        @include breakpoint(v-tablet) {
            margin-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            @include padding-level(right, M);
            @include padding-level(left, M);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 11px;
            border-radius: 22px 0px 22px 0px;
            background: linear-gradient(
                    124deg,
                    $token-colors-blue 20.82%,
                    rgba(0, 119, 200, 0) 99.26%
                ),
                $token-colors-navy-blue;

            @include breakpoint(laptop) {
                height: 8px;
            }
            @include breakpoint(v-tablet) {
                display: none;
            }
        }
    }

    &__content-logo {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        transform: translateY(-130%);

        @include breakpoint(laptop) {
            height: 22px;
        }
        @include breakpoint(v-tablet) {
            display: none;
        }
    }

    &__content-title {
        font-weight: 600;
        @include typo-level(4XL);

        @include breakpoint(laptop) {
            @include typo-level(2XL);
        }
    }

    &__content-position {
        @include typo-level(XL);

        @include breakpoint(laptop) {
            @include typo-level(S);
        }
    }
}

.person-content {
    &__inner-detail {
        @include typo-level(L);
        font-weight: normal !important;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        gap: 20px 90px;

        @include breakpoint(laptop) {
            gap: 10px 60px;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }

        & > p:first-child {
            flex: 0 0 35%;
            max-width: 354px;

            @include breakpoint(laptop) {
                max-width: 250px;
            }

            @include breakpoint(v-tablet) {
                max-width: 100%;
                flex: 1;
            }
        }

        & > * {
            margin: 0 !important;
        }

        b,
        strong {
            color: $token-colors-gray-60;
            font-weight: 600;
        }
    }
}

.person-timeline {
    @include margin-level(left, M);

    @include breakpoint(mobile) {
        margin-left: 0;
    }
}
</style>
