<template>
    <div class="search-page-input">
        <z-input name="search" :placeholder="placeholderText" v-model="value" @enter="enterHandler"/>
        <z-button @click="searchHandler" :disabled="isDisabled">{{buttonText}}</z-button>
    </div>
</template>

<script>
import {localize} from '@/utils/i18n'
export default {
    name: 'search-input',
    props: {
        data: String
    },
    created () {
        if (this.data) {
            this.value = this.data
        }
    },
    data () {
        return {
            value: '',
            placeholderText: localize({
                ru: 'Введите ключевое слово',
                en: 'Enter keyword'
            }),
            buttonText: localize({
                ru: 'Искать',
                en: 'Search'
            })
        }
    },
    computed: {
        isDisabled () {
            return this.value === ''
        }
    },
    methods: {
        searchHandler () {
            this.$emit('change', this.value)
        },
        enterHandler () {
            this.$emit('change', this.value)
        }
    }
}
</script>

<style lang="scss">
.search-page-input {
    display: flex;

    @include breakpoint(mobile) {
        flex-direction: column;
    }
    @include margin-level(bottom, 2XL);
    .z-input {
        @include margin-level(right, S);

        @include breakpoint(mobile) {
            margin-right: 0;
            @include margin-level(bottom, S)
        }
    }
}

</style>
