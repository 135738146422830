/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-small/press-release': {
    width: 50,
    height: 60,
    viewBox: '0 0 50 60',
    data: '<path pid="0" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M17.172 22.6a5.14 5.14 0 00-.557.016 5.14 5.14 0 00-.015.556v10.656c0 .288.001.447.015.557.11.014.27.015.557.015H22.03c.555 0 1.015 0 1.388.036.354.034.681.104.982.269v-9.189c0-.846-.001-1.42-.06-1.849-.055-.412-.152-.6-.28-.728-.127-.127-.315-.224-.727-.28-.43-.057-1.003-.059-1.849-.059h-4.312zM25 22.188a1.907 1.907 0 00-.09-.097c-.388-.388-.872-.548-1.417-.62-.519-.07-1.174-.07-1.966-.07H17.138c-.248 0-.49 0-.69.026-.226.03-.486.105-.702.32-.216.217-.29.476-.32.702-.027.2-.027.443-.027.69v10.722c0 .248 0 .49.027.69.03.227.104.486.32.702.216.216.476.29.702.32.2.027.442.027.69.027H22c.593 0 .995.001 1.303.03.3.03.447.081.55.144.151.094.279.222.373.374.063.102.115.25.144.55.03.308.03.71.03 1.302h1.2c0-.592 0-.994.03-1.303.029-.299.08-.447.144-.549.094-.152.222-.28.374-.374.102-.063.25-.115.549-.143.308-.03.71-.03 1.303-.03h4.86c.248 0 .49 0 .69-.027.227-.03.487-.105.703-.321.215-.216.29-.475.32-.702.027-.2.027-.442.027-.69V23.14c0-.248 0-.49-.027-.69-.03-.227-.105-.486-.32-.702-.216-.216-.476-.29-.702-.32a5.51 5.51 0 00-.69-.028h-4.388c-.792 0-1.447 0-1.966.07-.545.073-1.029.233-1.417.621a1.907 1.907 0 00-.09.097zm.6 3.328v9.189c.3-.165.628-.235.982-.269.373-.036.833-.036 1.389-.036h4.857c.288 0 .447 0 .557-.015a5.14 5.14 0 00.015-.556V23.171a5.14 5.14 0 00-.015-.556 5.14 5.14 0 00-.557-.016h-4.313c-.845 0-1.419.002-1.848.06-.413.055-.6.152-.728.28-.127.127-.225.315-.28.727-.058.43-.06 1.003-.06 1.849z" _fill="#0077C8"/>'
  }
})
