<template>
    <div class="mp-slider" v-if="data && data.length">
        <modern-slider
            :count-slides="data.length"
            :thumbs-data="data"
            :thumbs-title="text.topStories"
            :goal-name="goalName"
            :goal-name-thumb="goalNameThumb"
            class="u-bottom-margin--xl"
        >
            <slide
                v-for="(slide, index) in data"
                :key="index"
                class="mp-slider__slide"
            >
                <video
                    v-if="slide.previewVideo"
                    autoplay
                    loop
                    muted
                    playsinline
                    class="mp-slider__video"
                >
                    <source :src="slide.previewVideo" type="video/mp4">
                </video>

                <z-image
                    v-else
                    class="mp-slider__image"
                    :src="slide.previewPicture"
                    background
                ></z-image>
            </slide>
        </modern-slider>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'mp-slider',
    mixins: [mixinDevice],
    props: {
        data: {
            type: Array,
            default: () => []
        },
        goalName: {
            type: String
        },
        goalNameThumb: {
            type: String
        }
    },
    data () {
        return {
            text: {
                topStories: localize({
                    ru: 'Самое важное',
                    en: 'Top stories'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.mp-slider {

    &__slide {
        max-height: 860px;
        min-height: 520px;
        padding-top: 45%;
        position: relative;
        overflow: hidden;
    }

    &__image {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__video {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        object-fit: cover;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}
</style>
