<template>
    <div class="control-page">
        <section>
            <z-caption size="3xl" tag="h2" v-html="title">
            </z-caption>
            <div class="control-page__container--ph scrollable">
                <shema-eng v-if="pageLang === 'en'" class="min-width-v-tablet"/>
                <shema-ru v-if="pageLang === 'ru'" class="min-width-v-tablet"/>
            </div>
        </section>
    </div>
</template>
<script>
import { localize } from '@/utils/i18n'
import ShemaEng from './svg/ShemaEng.vue'
import ShemaRu from './svg/ShemaRu.vue'

export default {
    name: 'control-page',
    components: {
        ShemaEng,
        ShemaRu
    },
    data () {
        return {
            title: localize({
                ru: 'Корпоративное управление: распределения ответственности по&nbsp;наиболее значимым вопросам устойчивого развития',
                en: 'Corporate Governance: split of&nbsp;responsibilities for key areas of&nbsp;sustainable development'
            })
        }
    },
    computed: {
        pageLang () {
            return this.$root.app.page.lang
        }
    }
}
</script>
<style lang="scss" src="./index.scss"></style>
