<template>
    <div class="local-tenders">
        <!-- <z-caption tag="h2" class="u-bottom-margin--m-important" size="2xl" weight="400">
            Наша компания продает имущество для разных компаний, сюда нужен текст о&nbsp;том что предлагает эта
            страница.
        </z-caption> -->
        <card-link
            class="u-bottom-margin--m"
            :link="warningLink"
            :target="isFrame ? '_blank' : '_self'"
            theme="warning"
            image="/images/spy.svg"
        >
            Предупреждение о&nbsp;подложных обращениях от&nbsp;лица компании
        </card-link>
        <z-caption v-if="filters.value.archive" tag="h3">Архив закупок</z-caption>
        <div v-if="isShownSectionText && sectionText.value" class="local-tenders__section">
            <vue-raw :raw="sectionText.value"/>
        </div>
        <local-tenders-filter :filters-data="filtersData" @update-filters="filtersHandler" ref="filter"/>
        <template v-if="items.length && !loading">
            <local-tenders-list :items="items"/>
            <z-button
                :class="filters.value.archive ? 'u-bottom-margin--xs': 'u-bottom-margin--xl'"
                kind="text"
                @click="archiveHandler"
            >
                <template #left>
                    <z-icon name="arrow-long" dir="right"/>
                </template>
                {{filters.value.archive ? 'Вернутся к&nbsp;актуальным закупкам' : 'Посмотреть архив закупок'}}
            </z-button>
            <div v-if="filters.value.archive && !isFrame" class="u-bottom-margin--xl">
                <z-link href="/suppliers/tenders/local/archive/">
                    Закупки Мурманского транспортного филиала, срок подачи предложений которых истек до&nbsp;29.01.2018
                </z-link>
            </div>
            <z-pagination
                v-if="nav"
                :data="{
                    currentPage: nav.current,
                    totalPages: nav.total,
                    pageRange: 1
                }"
                @change-page="pageHandler"
            />
        </template>
        <template v-else-if="loading">
            <z-preloader v-if="loading"/>
        </template>
        <template v-else>
            <z-not-found class="u-bottom-margin--xl"/>
            <z-button kind="text" @click="archiveHandler">
                <template #left>
                    <z-icon name="arrow-long" dir="right"/>
                </template>
                {{filters.value.archive ? 'Вернутся к&nbsp;актуальным закупкам' : 'Посмотреть архив закупок'}}
            </z-button>
        </template>
    </div>
</template>

<script>
import LocalTendersFilter from '@/views/LocalTenders/components/LocalTendersFilter.vue'
import LocalTendersList from '@/views/LocalTenders/components/LocalTendersList.vue'
import {getLocalTenders} from '@/api/localTenders'
import {debounce} from 'throttle-debounce'
import {showNotyfications} from '@/utils/notifications'

export default {
    name: 'localTenders',
    components: {
        LocalTendersList,
        LocalTendersFilter
    },
    provide () {
        return {
            filters: this.filters
        }
    },
    data () {
        return {
            filtersData: this.$root.app.components?.['local-tenders']?.filters || null,
            items: this.$root.app.components?.['local-tenders']?.items || [],
            nav: this.$root.app.components?.['local-tenders']?.nav || null,
            sectionText: this.$root.app.components?.['local-tenders']?.sectionText || null,
            isFrame: false,
            page: 1,
            isMore: false,
            loading: false,
            filters: {
                value: {
                    methods: [],
                    customers: [],
                    applicationMethod: [],
                    address: [],
                    companies: [],
                    q: '',
                    dateFrom: '',
                    dateTo: '',
                    archive: null
                }
            }
        }
    },
    created () {
        // For iframe visual
        if (window.self !== window.top) {
            this.isFrame = true
            setTimeout(() => {
                try {
                    document.querySelector('.header').remove()
                    document.querySelector('.footer').remove()
                    document.querySelector('.z-hero').remove()
                    document.querySelector('.main__page-nav').remove()
                    document.querySelector('.main__container').style.cssText = 'padding: 0 !important; margin: 0 !important;'
                    document.querySelector('.main__container .content-container').style.cssText = 'padding: 0 !important; margin: 0 !important; border-radius: 0 !important; box-shadow: none !important;'
                    document.querySelector('.local-tenders').style.cssText = 'overflow: hidden;'
                } catch (error) {
                    console.log(error)
                }
            }, 0)
        }
    },
    methods: {
        send: debounce(300, function (params) {
            this.loading = true
            getLocalTenders(params)
                .then((response) => {
                    this.filtersData = response.filters
                    this.nav = response.nav
                    this.page = this.nav.current
                    this.sectionText.value = response.sectionText.value

                    if (this.isMore) {
                        this.items = [...this.items, ...response.items]
                        this.isMore = false
                    } else {
                        this.items = response.items
                    }
                })
                .catch((err) => {
                    showNotyfications('error', err)
                })
                .finally(() => {
                    this.loading = false
                })
        }),
        archiveHandler () {
            this.page = 1
            if (this.filters.value.archive) {
                this.filters.value.archive = null
            } else {
                this.filters.value.archive = 'Y'
            }

            this.$refs.filter.reset(false)
        },
        filtersHandler () {
            this.page = 1
            this.send({
                ...this.filters.value,
                page: this.page
            })
        },
        pageHandler (payload) {
            this.isMore = payload.type === 'load-more'
            this.page = payload.page
            this.send({
                ...this.filters.value,
                page: this.page
            })
        }
    },
    computed: {
        isShownSectionText () {
            return this.filters?.value?.companies.length === 1
        },
        warningLink () {
            return this.isFrame ? 'https://www.nornickel.ru/suppliers/warning/' : '/suppliers/warning/'
        }
    }
}
</script>

<style lang="scss">
.local-tenders {
    max-width: 1360px;

    &__section {
        @include padding-level(top, M);
        @include padding-level(bottom, M);
        @include padding-level(right, XL);
        @include padding-level(left, XL);
        background-color: $token-colors-gray-10;
        border-radius: 0 40px 0 0;
        margin-bottom: $token-spacers-m;

        @include breakpoint(mobile) {
            padding: $token-spacers-xs;
        }
    }

    &__section-text {
        font-size: 16px;
        line-height: 1.4;
        @include margin-level(bottom, XS);

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }
}
</style>
