/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_demography': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<rect pid="0" width="38" height="38" rx="19" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M25.8 20.871V23a6.8 6.8 0 01-13.264 2.118c2.1.056 5.1-.03 7.844-.83 2.093-.609 4.1-1.655 5.42-3.417zm0-2.6c-.838 2.638-3.086 4.088-5.755 4.865-2.704.788-5.72.844-7.785.773-.04-.297-.06-.6-.06-.909v-8a6.8 6.8 0 0113.6 0v3.27zm1.2-.146V15a8 8 0 10-16 0v8a8 8 0 1016 0v-4.55c.029-.1.056-.202.082-.305l-.082-.02zM17.616 14a.624.624 0 00-.616.62c0 .34.28.622.616.622a.624.624 0 00.617-.621c0-.339-.28-.621-.617-.621zm2.929 0a.624.624 0 00-.617.62c0 .34.28.622.617.622a.624.624 0 00.617-.621c0-.339-.28-.621-.617-.621zM19 18.8a3.8 3.8 0 100-7.6 3.8 3.8 0 000 7.6zm0 1.2a5 5 0 100-10 5 5 0 000 10z" _fill="#fff"/>'
  }
})
