<template>
    <media-album>
        <div class="row u-justify-content--btween _compensate-row-gap">
            <div
                class="col-default-4 col-tablet-6 col-mobile-12 sg-bg _row-gap"
                v-for="data in componentData"
                :key='data.code'
            >
                <media-card
                    type="item"
                    :image="data.previewPicture ? data.previewPicture.SRC : ''"
                    :href="data.detailPageUrl"
                    :content-type="getContentType(data.mlType.xmlId)"
                />
            </div>
        </div>
        <template v-slot:text>
            <slot/>
        </template>
    </media-album>
</template>

<script>

export default {
    name: 'media-album-expedition',
    props: {
        componentData: {
            type: Array,
            required: true
        }
    },
    methods: {
        getContentType (data) {
            const coontentTypes = {
                presentation: ['presentations'],
                interview: ['audio'],
                history: ['history'],
                photo: ['image'],
                video: ['video'],
                finanses: ['results'],
                identity: ['nornik']
            }

            return coontentTypes[data]
        }
    }
}
</script>
