<template>
<div class="cn-timeline container">
    <div ref="slider" class="swiper-container">
        <div class="cn-timeline__pagination container">
            <tl-play-progress
                :progress="getProgress"
                @play="(data) => play(data)"
                :pause="!getAutoplayRunning"
            />
            <div class="cn-timeline__wrapper">
                <div class="cn-timeline__years" ref="pagination"></div>
            </div>
        </div>
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
        <div class="cn-timeline__vertical">
            <slot></slot>
        </div>
    </div>
</div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
import TlPlayProgress from './components/TlPLayProgress.vue'

export default {
    name: 'timeline',
    components: {
        TlPlayProgress
    },
    data () {
        return {
            slider: null,
            speed: 5000,
            isOnce: false,
            childLength: null,
            firstYear: 2021
        }
    },
    methods: {
        play (boolean) {
            if (boolean) {
                this.slider.autoplay.stop()
            } else {
                this.slider.autoplay.start()
            }
        },
        handleScroll () {
            const elementPosition = this.$refs.slider.getBoundingClientRect().top
            const elementHeight = this.$refs.slider.offsetHeight
            const windowHeight = window.innerHeight
            const elementMiddle = elementPosition + (elementHeight / 2)

            if (elementMiddle < windowHeight && !this.isOnce) {
                this.isOnce = true
                this.slider.autoplay.start()
            }
        },
        cycleByYears (element, year) {
            this.childLength = element.childNodes.length
            let yearFrom = Number(element.lastChild.dataset.year)
            const yearTo = Number(year)
            while (yearFrom < yearTo) {
                yearFrom++
                this.renderBulletYear(element, yearFrom)
            }
        },
        renderBulletYear (parent, year) {
            const button = document.createElement('button')
            button.classList.add('cn-timeline__fake-bullet')
            button.innerText = year
            parent.appendChild(button)
        }
    },
    computed: {
        getProgress () {
            return Number(this.slider?.progress)
        },
        getAutoplayRunning () {
            return this.slider?.autoplay.running
        }
    },
    watch: {
        childLength () {
            if (this.childLength < 6) {
                this.cycleByYears(this.$refs.pagination, 2031)
            }
        }
    },
    mounted () {
        this.slider = new Swiper(this.$refs.slider, {
            speed: this.speed,
            freeMode: {
                enabled: true,
                momentum: false,
                momentumBounce: false,
                momentumRatio: 0,
                momentumBounceRatio: 0,
                momentumVelocityRatio: 0
            },
            loop: false,
            spaceBetween: 80,
            slidesPerView: 'auto',
            autoplay: false,
            resizeObserver: false,
            updateOnWindowResize: false,
            pagination: {
                clickable: true,
                el: this.$refs.pagination,
                renderBullet: (index, className) => {
                    let year = this.firstYear + index
                    return `<button class="${className}" data-year="${year}"><span>${year}</span></button>`
                }
            },
            on: {
                update: () => {
                    setTimeout(() => {
                        this.childLength = this.$refs.pagination.childNodes.length
                    }, 0)
                },
                beforeResize: () => {
                    setTimeout(() => {
                        this.childLength = this.$refs.pagination.childNodes.length
                    }, 0)
                }
            }
        })

        window.addEventListener('scroll', () => {
            this.handleScroll()
        })
    }
}
</script>

<style lang="scss">
@mixin year-bullet {
    background: transparent;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 15px;

    &:before {
        content: '';
        width: 1px;
        height: 24px;
        background: $token-colors-gray-35;
        order: 1;
        display: block;
        flex-shrink: 0;
    }

    &:after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: transparent;
        order: 1;
        display: block;
        flex-shrink: 0;
        transition: all 0.5s;
    }
}

.cn-timeline {

    &__item {
        flex-shrink: 0;

        @include breakpoint (v-tablet) {
            width: 100%;
            max-width: 534px;
            margin: 0 auto;

            &--head {
                display: flex;
                gap: 15px;
            }
        }

        @include breakpoint (mobile) {
            width: max-content;

            &--head {
                gap: 8px;
                flex-direction: column;
            }
        }
    }

    &__pagination {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: $token-spacers-s;
        gap: 100px;
        align-items: flex-end;

        @include breakpoint (v-tablet) {
            display: none;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 39px;
        flex: 1;

        @include breakpoint (v-tablet) {
            gap: 64px;
        }

        &:before,
        &:after {
            font-family: 'Proxima Nova Extra Condensed';
            width: min-content;
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            color: $token-colors-gray-35;

            @media (max-width: 1200px) {
                display: none;
            }

            @include breakpoint (v-tablet) {
                display: block;
            }
        }

        &:before {
            content: 'начало программы';
            text-align: right;
        }

        &:after {
            content: 'завершение программы';
            text-align: left;
        }
    }

    &__years {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex: 1;
        height: 95px;
    }

    &__title, &__subtitle {
        font-size: 64px;
        line-height: 100%;
        letter-spacing: -0.03em;
        background: linear-gradient(180deg, $token-colors-blue-50 0%, $token-colors-navy-blue 100%), $token-colors-white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @include breakpoint (v-tablet) {
            font-size: 48px;
        }
    }

    &__title {
        font-weight: 300;
    }

    &__subtitle {
        font-weight: 700;
        margin-bottom: $token-spacers-m;

        @include breakpoint (v-tablet) {
            margin-bottom: 24px;
        }
    }

    .z-button {
        font-weight: 700;
    }

    .swiper-slide, .swiper-wrapper {
        width: auto;
        height: auto;
    }

    &__vertical {
        display: none;
        flex-direction: column;
        gap: $token-spacers-m;

        @include breakpoint (v-tablet) {
            display: flex;
        }

        @include breakpoint (mobile) {
            gap: $token-spacers-s;
        }

        .swiper-slide {
            @include breakpoint (v-tablet) {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: $token-spacers-m;
            }

            @include breakpoint (mobile) {
                gap: $token-spacers-s;
            }
        }
    }

    .swiper-wrapper {
        @include breakpoint (v-tablet) {
            opacity: 0;
            height: 0;
            visibility: hidden;
        }
    }

    .swiper-slide {
        display: flex;
        gap: $token-spacers-2-xl;

        @include breakpoint (tablet) {
            gap: $token-spacers-m;
        }
    }

    &__fake-bullet {
        font-family: 'Proxima Nova Extra Condensed';
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        color: $token-colors-gray-30;
        border: none;
        cursor: none;
        pointer-events: none;
        opacity: 0.9;

        @include year-bullet;
    }

    .swiper-pagination-bullet {
        font-style: normal;
        width: auto;
        height: auto;
        opacity: 1;
        transition: all 0.5s;

        @include year-bullet;

        span {
            font-family: 'Proxima Nova Extra Condensed';
            font-weight: 400;
            font-size: 24px;
            line-height: 120%;
            color: $token-colors-gray-60;
            transition: all 0.5s;
            transform: scale(1.0);
        }
    }

    .swiper-pagination-bullet-active {
        span {
            color: $token-colors-blue-50;
            transform: scale(1.2);
        }

        &:after {
            background: $token-colors-blue-50;
        }
    }
}
</style>
