<template>
    <div class="ya-map">
        <div class="ya-map__header">
            <button class="ya-map__header-button" @click="toggleFilter"/>
            Карта объектов программы &laquo;Чистый Норильск&raquo;
        </div>
        <div class="ya-map__wrapper">
            <transition
                @enter="animationEnter"
                @leave="animationLeave"
            >
                <div v-show="showFilter" class="ya-map__filters">
                    <button class="ya-map__back-button" @click="toggleFilter">
                        <z-icon name="round-arrow" width="24" height="24" dir="left"/>Назад
                    </button>
                    <div v-if="showPreview" class="ya-map__preview-block">
                        <z-image :src="previewItem.imageLink"/>
                        <div class="ya-map__preview-head">
                            <button class="ya-map__preview-button" @click="backIntoPreview">
                                <z-icon name="arrow-long" dir="left" width="24" height="24" color="white"/>
                            </button>
                            <span v-html="previewItem.name"
                                  class="ya-map__preview-text"
                            />
                        </div>
                        <div class="ya-map__preview-body">
                            <vue-raw :raw="previewItem.content"/>
                        </div>
                    </div>
                    <template v-show="Boolean(!showPreview)">
                        <div class="ya-map__filter">
                            <div class="ya-map__checkbox-wrapper">
                                <z-checkbox name="completed" v-model="selectedPlaces"
                                            :data="[{value: 'completed', disabled: false}]">
                                    <template #text>
                                        Готово
                                    </template>
                                </z-checkbox>
                            </div>
                            <ya-map-filter-button v-if="selectedPlaces.includes('completed')"
                                                  :all-items="this.completed"
                                                  @select-item="selectPoint"
                            />
                        </div>
                        <div class="ya-map__filter">
                            <div class="ya-map__checkbox-wrapper">
                                <z-checkbox name="in-process" v-model="selectedPlaces"
                                            :data="[{value: 'in-process', disabled: false}]">
                                    <template #text>
                                        В процессе
                                    </template>
                                </z-checkbox>
                            </div>
                            <ya-map-filter-button v-if="selectedPlaces.includes('in-process')"
                                                  :all-items="this.inProcess"
                                                  @select-item="selectPoint"
                            />
                        </div>
                        <div class="ya-map__filter">
                            <div class="ya-map__checkbox-wrapper">
                                <z-checkbox name="coming-soon" v-model="selectedPlaces"
                                            :data="[{value: 'coming-soon', disabled: false}]">
                                    <template #text>
                                        2022-2032
                                    </template>
                                </z-checkbox>
                            </div>
                            <ya-map-filter-button v-if="selectedPlaces.includes('coming-soon')"
                                                  :all-items="this.comingSoon"
                                                  @select-item="selectPoint"
                            />
                        </div>
                    </template>
                </div>
            </transition>
            <map-container
                :center="[69.4891634, 88.4581742]"
                :coords="result"
                :selected="selected"
                @click-point="pinHandler"
            />
        </div>
    </div>
</template>

<script>
import MapContainer from './components/MapContainer.vue'
import YaMapFilterButton from './components/YaMapFilterButton.vue'
import Velocity from 'velocity-animate'
import {completed, inProcess, comingSoon} from '@/components/YaMap/data'

export default {
    name: 'ya-map',
    components: {MapContainer, YaMapFilterButton},
    data () {
        return {
            selectedPlaces: ['completed', 'in-process', 'coming-soon'],
            selected: [],
            previewItem: null,
            showPreview: false,
            showFilter: false,
            completed: this.adaptItems(completed, 'completed'),
            inProcess: this.adaptItems(inProcess, 'in-process'),
            comingSoon: this.adaptItems(comingSoon, 'coming-soon')
        }
    },
    computed: {
        result () {
            return [...this.completed, ...this.inProcess, ...this.comingSoon].filter((item) => this.selectedPlaces.includes(item.type))
        }
    },

    methods: {
        /*
        функция для ховера, в процессе...
        updateActive (payload) {
            const {id, type, status} = payload
            const updateItem = (items) => {
                const index = items.findIndex((item) => item.id === id)
                if (status === 'set') {
                    items[index].active = true
                    return
                }
                items[index].active = false
            }

            switch (type) {
            case ('completed'): {
                updateItem(this.completed)
                break
            }
            case ('in-process'): {
                updateItem(this.inProcess)
                break
            }
            case ('coming-soon'): {
                updateItem(this.comingSoon)
                break
            }
            default: {
                break
            }
            }
        }
         */
        pinHandler (item) {
            this.selected = item.coords
            this.previewItem = item
            this.showFilter = true
            this.showPreview = true
        },
        selectPoint (item) {
            this.selected = item.coords
            this.previewItem = item
            this.showPreview = !this.showPreview
        },
        backIntoPreview () {
            this.selected = []
            this.showPreview = !this.showPreview
        },
        toggleFilter () {
            this.showFilter = !this.showFilter
        },
        animationEnter (el, done) {
            Velocity(el, 'fadeIn', { duration: 500, display: 'flex' })
        },
        animationLeave (el, done) {
            Velocity(el, 'fadeOut', { duration: 500 })
        },
        adaptItems (items, type) {
            return items.slice().map((item, index) => ({
                ...item,
                id: `${type}-${index}`,
                type: type,
                active: false
            }))
        }
    }
}
</script>

<style lang="scss">
.ya-map {
    background-image: url('/images/clean-norilsk/banner-bottom-line.png');
    background-position: center 0;
    background-repeat: repeat-x;
    padding-top: 62px;

    &__wrapper {
        position: relative;
    }

    &__header {
        padding: $token-spacers-2-xs $token-spacers-m;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.4;
        color: $token-colors-black;
        display: flex;
        align-items: center;
        background-color: $token-colors-white;

        @include breakpoint(mobile) {
            font-size: 14px;
            padding: $token-spacers-3-xs $token-spacers-s;
            padding-bottom: $token-spacers-s;
        }
    }

    &__header-button {
        height: 18px;
        width: 22px;
        border: none;
        flex-shrink: 0;
        padding: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/images/ya-map/header-button.png");
        background-size: 22px 18px;
        margin-right: $token-spacers-2-xs;
        transition: opacity 300ms ease;

        &:hover {
            opacity: 0.7;
        }
    }

    &__filters {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        max-width: 355px;
        width: 100%;
        height: 75%;
        padding: $token-spacers-2-xs;
        background: linear-gradient(110.75deg, #F4F6F9 23.84%, #E6E9EE 100%), #F5F5F5;
        z-index: 5;
        overflow: auto;

        @include breakpoint(mobile) {
            max-width: 100%;
        }

        &::-webkit-scrollbar {
            height: 5px;
            background: $token-colors-blue-20;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: $token-colors-blue;
            border-radius: 2px;
        }

        @include breakpoint(mobile) {
            background: $token-colors-white;
            position: fixed;
            top: 50px;
            height: calc(100vh - 50px);
        }
    }

    &__back-button {
        padding: 0;
        border: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.4;
        color: $token-colors-blue;
        margin: $token-spacers-xs 0 $token-spacers-xs;
        display: none;
        background-color: transparent;

        .z-icon {
            margin-right: $token-spacers-2-xs;
        }

        @include breakpoint(mobile) {
            display: flex;
            justify-self: flex-start;
            align-items: center;
        }
    }

    &__preview-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        background: linear-gradient(110.75deg, #F4F6F9 23.84%, #E6E9EE 100%), #F5F5F5;
        z-index: 1;
    }

    &__preview-head {
        padding: $token-spacers-2-xs $token-spacers-s;
        background-color: $token-colors-blue;
        display: flex;
        align-items: center;
    }

    &__preview-body {
        padding: $token-spacers-xs;
    }

    &__preview-text {
        margin-left: 20px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 260px;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.2;
        color: $token-colors-white;
    }

    &__preview-button {
        padding: 0;
        border: 0;
        background-color: transparent;
    }

    &__filter {
        margin-bottom: $token-spacers-xs;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__checkbox-wrapper {
        margin-bottom: $token-spacers-2-xs;
    }
}
</style>
