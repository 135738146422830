<template>
    <div class="search-page-sorting">
        <div class="search-page-sorting__item">
            <span class="search-page-sorting__title" v-html="sorting.title" />
            <button class="search-page-sorting__button"
                    :class="{'search-page-sorting__button--active': checkActive('r')}"
                    :disabled="checkActive('r')"
                    @click="sortHandler('r')" v-html="sorting.relevance"/>
            <button class="search-page-sorting__button"
                    :class="{'search-page-sorting__button--active': checkActive('d')}"
                    @click="sortHandler('d')" v-html="sorting.byDate"/>
        </div>
        <div v-if="sections.length" class="search-page-sorting__item">
            <span class="search-page-sorting__title" v-html="section" />
            <button v-for="(item, index) in sections"
                    class="search-page-sorting__button"
                    :class="{'search-page-sorting__button--active': item.selected}"
                    :disabled="item.disabled"
                    :key="`section-${index}`"
                    @click="sectionHandler(item.xmlId)">
                {{item.text}} <span class="search-page-sorting__count">{{item.count}}</span>
            </button>
        </div>
    </div>
</template>

<script>
import {localize} from '@/utils/i18n'
export default {
    name: 'search-sorting',
    props: {
        sections: {
            type: Array
        },
        sort: {
            type: String
        }
    },
    data () {
        return {
            params: {
                currentSort: 'r',
                section: ''
            },
            sorting: {
                title: localize({
                    ru: 'Сортировка:',
                    en: 'Sorting:'
                }),
                byDate: localize({
                    ru: 'По&nbsp;дате',
                    en: 'By&nbsp;date'
                }),
                relevance: localize({
                    ru: 'По&nbsp;релевантности',
                    en: 'Relevance'
                })
            },
            section: localize({
                ru: 'Раздел:',
                en: 'Section:'
            })
        }
    },
    created () {
        if (this.sections.length) {
            this.sections.forEach((item) => {
                if (item.selected) {
                    this.params.section = item.xmlId
                }
            })
        }
        if (this.sort) {
            this.params.currentSort = this.sort
        }
    },
    methods: {
        checkActive (data) {
            return this.params.currentSort === data
        },
        sortHandler (data) {
            this.params.currentSort = data
            this.sendParams()
        },
        sectionHandler (data) {
            this.params.section = data
            this.sendParams()
        },
        sendParams () {
            this.$emit('change', this.params)
        }
    }
}
</script>

<style lang="scss">
.search-page-sorting {
    display: flex;
    @include margin-level(bottom, XS);
    @include breakpoint(laptop) {
        flex-direction: column;
    }

    &__item {
        &:first-child {
            @include margin-level(right, 4XL);

            @include breakpoint(laptop) {
                margin-bottom: $token-spacers-xs;
                margin-right: 0;
            }
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.4;
        color: $token-colors-gray-60;
        @include margin-level(right, S);

        @include breakpoint(mobile) {
            display: block;
        }
    }

    &__button {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: $token-colors-blue;
        transition: color ease 300ms;
        padding: 0;
        background-color: transparent;
        border: none;

        &:hover {
            color: $token-colors-navy-blue;
        }

        &--active,
        &:disabled {
            color: $token-colors-gray-35;
        }

        &:not(:last-child) {
            @include margin-level(right, S);
        }
    }

    &__count {
        font-weight: 600;
    }
}
</style>
