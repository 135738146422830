import { render, staticRenderFns } from "./UpdatesList.vue?vue&type=template&id=28c2c1c8&"
import script from "./UpdatesList.vue?vue&type=script&lang=js&"
export * from "./UpdatesList.vue?vue&type=script&lang=js&"
import style0 from "./UpdatesList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports