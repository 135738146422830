/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/NaCl': {
    width: 100,
    height: 63,
    viewBox: '0 0 100 63',
    data: '<path pid="0" d="M0 31.5C0 14.103 14.103 0 31.5 0h35.167C85.077 0 100 14.924 100 33.333V63H31.5C14.103 63 0 48.897 0 31.5z" _fill="#004C97"/><path pid="1" d="M0 31.5C0 14.103 14.103 0 31.5 0h35.167C85.077 0 100 14.924 100 33.333V63H31.5C14.103 63 0 48.897 0 31.5z" _fill="url(#paint0_linear_9226_32276)"/><path pid="2" d="M39.611 39H36.9l-8.352-11.424V39H25.74V22.992h2.88l8.183 11.112V22.992h2.809V39zm12.81 0H49.9v-1.248c-.864.984-2.208 1.536-3.744 1.536-1.896 0-4.032-1.272-4.032-3.84 0-2.664 2.136-3.768 4.032-3.768 1.56 0 2.904.504 3.744 1.488V31.44c0-1.344-1.104-2.16-2.712-2.16-1.296 0-2.424.48-3.432 1.464l-1.032-1.752c1.344-1.272 3.024-1.872 4.872-1.872 2.568 0 4.824 1.08 4.824 4.2V39zm-5.28-1.44c1.104 0 2.184-.408 2.76-1.2v-1.752c-.576-.792-1.656-1.2-2.76-1.2-1.416 0-2.472.816-2.472 2.088 0 1.248 1.056 2.064 2.472 2.064zm16.18 1.728c-4.729 0-8.4-3.36-8.4-8.28s3.671-8.28 8.4-8.28c3.24 0 5.231 1.68 6.431 3.6l-2.4 1.224c-.768-1.32-2.304-2.328-4.032-2.328-3.168 0-5.52 2.424-5.52 5.784s2.352 5.784 5.52 5.784a4.701 4.701 0 004.032-2.328l2.4 1.224c-1.224 1.92-3.192 3.6-6.432 3.6zM74.327 39h-2.52V22.992h2.52V39z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32276" x1="34.729" y1="0" x2="96.333" y2="65.298" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
