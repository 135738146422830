<template>
    <figure :class="[
        'z-blockquote',
        { [`z-blockquote--theme-${this.theme}`]: this.theme },
        { 'z-blockquote--horizontal': this.horizontal }
    ]">
        <div class="z-blockquote__wrapper">
            <div class="z-blockquote__header">
                <template v-if="author">
                    <div class="z-blockquote__author-photo" v-if="!!this.$slots['avatar']">
                        <slot name="avatar"/>
                    </div>
                    <div class="z-blockquote__author-info" v-if="author.name || author.info">
                        <div class="z-blockquote__author-name" v-if="author.name">
                            <z-caption
                                tag="h2"
                                size="3xl"
                                class="u-collapse--all"
                            >
                                <vue-raw :raw="author.name"></vue-raw>
                            </z-caption>
                        </div>
                        <div class="z-blockquote__author-name" v-else-if="!author.name && this.$slots['author-name']">
                            <slot name="author-name"/>
                        </div>
                        <div class="z-blockquote__author-description" v-if="author.info">
                            <vue-raw class="gray-40-text text-size-l u-normal" :raw="author.info"/>
                        </div>
                    </div>
                </template>

                <template v-if="title">
                    <div class="z-blockquote__title">
                        <a v-if="sourceUrl" :href="sourceUrl" :title="sourceUrl">
                            <z-caption
                                tag="h2"
                                size="xl"
                                class="u-collapse--all"
                            >
                                <vue-raw :raw="title"/>
                            </z-caption>
                        </a>

                        <z-caption
                            v-else
                            size="xl"
                            class="u-collapse--all"
                        >
                            <vue-raw :raw="title"/>
                        </z-caption>
                    </div>
                </template>
            </div>

            <div class="z-blockquote__body" v-if="(!!this.$slots['text'] || text)">
                <blockquote
                    class="gray-60-text text-size-xl u-normal"
                >
                    <p v-if="text">
                        <vue-raw :raw="text"/>
                    </p>
                    <slot name="text"></slot>
                </blockquote>
            </div>
        </div>

        <div class="z-blockquote__footer" v-if="(date || source)">
            <div
                v-if="date"
                class="z-blockquote__date"
            >
                {{ date | momentFilter('DD MMMM YYYY') }}
            </div>
            <div class="z-blockquote__footer-divider" v-if="date && source"></div>
            <div
                v-if="source"
                class="z-blockquote__source"
            >
                <vue-raw :raw="localize.source"/>
                <a v-if="sourceUrl" :href="sourceUrl" :title="sourceUrl">
                    <vue-raw :raw="source"/>
                </a>
                <vue-raw v-else :raw="source"/>
            </div>
        </div>
    </figure>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'z-blockquote',
    props: {
        author: {
            name: String,
            info: String
        },
        title: {
            type: String,
            default: ''
        },
        date: {
            type: [String, Number],
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        sourceUrl: {
            type: String,
            default: ''
        },
        theme: String,
        size: {
            type: String,
            default: ''
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            localize: {
                source: localize({
                    'ru': 'Источник:&nbsp;',
                    'en': 'Source:&nbsp;'
                })
            }
        }
    },
    computed: {
        mobile () {
            return this.$root.isMobile()
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
