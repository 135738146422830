/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dometory': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.799 22.789c2.589-2.506 4.791-5.855 4.791-9.997 0-6.125-4.965-11.09-11.09-11.09S3.41 6.667 3.41 12.792c0 4.142 2.202 7.491 4.791 9.997 2.474 2.394 5.192 3.908 6.299 4.47 1.107-.562 3.825-2.076 6.299-4.47zm-5.903 5.615c2.107-1.05 11.894-6.42 11.894-15.612 0-6.788-5.502-12.29-12.29-12.29-6.788 0-12.29 5.502-12.29 12.29 0 9.191 9.787 14.562 11.894 15.612a.872.872 0 00.792 0zM14.807 6.78a.6.6 0 00-.614 0l-6.07 3.617a.6.6 0 10.614 1.03l.693-.412v6.923a1.6 1.6 0 001.6 1.6h6.98a1.6 1.6 0 001.6-1.6v-6.9l.653.39a.6.6 0 00.614-1.031l-6.07-3.617zm3.603 3.544l-3.91-2.33-3.87 2.306v7.638c0 .22.18.4.4.4h6.98a.4.4 0 00.4-.4v-7.614zm-5.426 1.866a.8.8 0 00-.8.8v3.031a.8.8 0 00.8.8h3.032a.8.8 0 00.8-.8V12.99a.8.8 0 00-.8-.8h-3.032zm.4 3.431V13.39h2.232v2.231h-2.232z" _fill="#1A1B25"/>'
  }
})
