<template>
<div class="media-library-card" :class="classArray">
    <z-icon
        v-if="contentType[0] === 'video' && type === 'item'"
        class="play-icon"
        name="play-block"
        dir="top"
        width="60"
        height="60"
    >
    </z-icon>
    <figure>
        <a
            v-if="href"
            :href="href"
            class="media-library-card__overlay-link"
        ></a>
        <div class="media-library-card__overlay">
            <div class="media-library-card__content-type content-type">
                <ul class="content-type__list">
                    <li
                        class="content-type__item"
                        v-for="(icon, idx) in contentType"
                        :key="idx"
                    >
                        <z-icon
                            :name="getIconName(contentType)[idx]"
                            class="content-type__icon"
                            width="1.75em"
                            height="1.75em"
                        />
                        <span v-if="contentCount && contentCount[idx]" class="content-type__count">{{ contentCount[idx] }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="media-library-card__preview">
            <div v-if="image" class="media-library-card__image" :style="`background-image: url('${image}');`"></div>
            <div class="media-library-card__image--plaseholder" style="background-image: url('/images/media-library-card__bg--hover.jpg');">
                <div class="media-library-card__type">
                    <z-icon
                        :name="getIconName(contentType)[0]"
                        width="1.75em"
                        height="1.75em"
                    />
                </div>
            </div>
        </div>

        <figcaption
            v-if="caption"
            class="media-library-card__caption"
            :class="{'media-library-card__caption--white': this.bg === 'white'}"
        >
            <span v-html="caption"></span>
        </figcaption>
    </figure>
</div>
</template>

<script>

export default {
    name: 'media-card',
    props: {
        type: {
            type: String,
            default: 'item',
            validator: prop => ['list', 'item'].includes(prop)
        },
        bg: {
            type: String,
            default: 'gray',
            validator: prop => ['gray', 'white'].includes(prop)
        },
        image: String,
        caption: String,
        href: String,
        contentCount: {
            type: [Number, Array],
            required: false
        },
        contentType: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {}
    },
    computed: {
        classArray () {
            let classArray = []

            if (this.type) classArray.push(`media-library-card--${this.type}`)

            return classArray
        }
    },
    methods: {
        getIconName (type) {
            const typeArray = []

            for (const image of type) {
                switch (image) {
                case 'image':
                    typeArray.push('img')
                    break
                case 'audio':
                    typeArray.push('mic')
                    break
                case 'video':
                    typeArray.push('video')
                    break
                case 'history':
                    typeArray.push('mini_history')
                    break
                case 'results':
                    typeArray.push('briefcase')
                    break
                case 'presentations':
                    typeArray.push('monitor')
                    break
                case 'nornik':
                    typeArray.push('minilogo')
                    break
                case 'mixed':
                default:
                    typeArray.push('folder')
                }
            }

            return typeArray
        }
    }
}
</script>

<style lang="scss">
@import '~/src/styles/base/utilities';

.media-library-card {
    $parent: &;
    position: relative;

    height: 100%;
    border-top-left-radius: $boder-radius-l;
    border-bottom-right-radius: $boder-radius-m;
    overflow: hidden;
    transition: all $transition;

    .play-icon {
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate( -50%, -50%);
        z-index: 4;
    }

    figure {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__overlay-link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 6;
    }

    &__overlay {
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        @include padding-level(left, S);
        @include padding-level(right, S);

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 4;
        min-height: 200px;
        background: linear-gradient(180deg, rgba(0, 76, 151, 0.5) 0%, rgba(0, 76, 151, 0) 75%);
    }

    &__preview {
        position: relative;
    }

    &__image {
        position: absolute;
        width: 100%;
        padding-top: 48%;
        background-size: cover;
        background-position: center 100%;
        background-repeat: no-repeat;
        z-index: 3;
    }

    &__image--plaseholder {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding-top: 48%;
        background-size: cover;
        background-position: center;
        z-index: 1;
    }

    &__type {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            display: block;
            height: 1px;
            width: 88px;
            background-color: $token-colors-navy-blue;
        }

        &:before {
            left: -18px;
            transform: translate(-100%, -50%);
        }

        &:after {
            right: -18px;
            transform: translate(100%, -50%);
        }

        .z-icon * {
            fill: $token-colors-navy-blue;
        }
    }

    &__caption {
        background-color: $token-colors-gray-10;
        color: $token-colors-blue;
        font-weight: 600;
        display: flex;
        flex: 1 0 auto;
        align-items: start;
        justify-content: space-between;
        @include typo-level(2XL);
        padding: $token-spacers-s 44px;

        @include breakpoint (mobile) {
            padding: $token-spacers-2-xs !important;
        }
    }

    &__caption--white {
        background-color: $token-colors-white;
    }

    &:hover,
    &:target {
        filter: drop-shadow(0px 8px 8px rgba(0, 32, 51, 0.04)) drop-shadow(0px 12px 28px rgba(0, 32, 51, 0.1));

        #{$parent}__caption {
            color: $token-colors-navy-blue;
        }

        .content-type {
            &__item {
                color: $token-colors-navy-blue;
            }

            &__icon * {
                fill: $token-colors-navy-blue;
            }
        }

        .play-icon {
            opacity: 0.6;
            transition: $transition;
        }
    }

    &--item {
        #{$parent}__image {
            &:before {
                content: '';
                display: block;
                height: 92.8px;
            }
        }

        .media-library-card__image--plaseholder {
            padding-top: 69.55%;
        }

        .content-type__count {
            display: none;
        }

         .media-library-card__image {
            padding-top: 0;
            height: 100%;
        }
    }

    &--theme-dark {
        #{$parent}__caption {
            background-color: white;
        }
    }
}

.content-type {

    &__list {
        @extend %u-reset-list;
        margin: -4px -12px;

        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        padding: 4px 12px;

        flex: 0 0 auto;
        display: flex;
        align-items: center;

        @include typo-level(M);
        font-weight: normal;
        color: white;
        transition: color $transition;
    }

    &__icon {
        * {
            transition: fill $transition;
            fill: white;
        }
    }

    &__count {
        margin-left: 0.45em;
    }
}
</style>
