<template>
    <div class="tl-year">
        {{ year ? year : '2021' }}
        <span>год</span>
    </div>
</template>

<script>
export default {
    name: 'tl-year',
    props: {
        year: String
    }
}
</script>

<style lang="scss">
.tl-year {
    font-family: 'Proxima Nova Extra Condensed';
    font-weight: 300;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #0290F0;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: $token-spacers-m;
    gap: 0;

    @include breakpoint (v-tablet) {
        padding-top: 0;
    }

    &:after, &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: #0290F0;
        border-radius: 50%;
    }

    &:after {
        display: block;
        margin-top: $token-spacers-m;
    }

    &:before {
        display: none;
    }

    @include breakpoint (v-tablet) {
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        gap: $token-spacers-2-xs;

        &:after {
            display: none;
        }

        &:before {
            display: block;
        }
    }
}
</style>
