<template>
    <div
        class="certificate"
        :class="getCertificateClass">
        <div class="certificate__container">
            <logo
                class="certificate__logo"
                type="blue" />
            <div class="certificate__decoration"></div>
            <div class="certificate__info">
                <div class="certificate__info-container">
                    <div class="certificate__info-block">
                        <p class="certificate__info-title">Организация:</p>
                        <p
                            class="certificate__info-text"
                            v-html="companyName"></p>
                    </div>
                </div>
                <div class="certificate__info-container">
                    <div class="certificate__info-block">
                        <p class="certificate__info-title">ИНН:</p>
                        <p
                            class="certificate__info-text"
                            v-html="companyInn"></p>
                    </div>
                    <div class="certificate__info-block">
                        <p class="certificate__info-title">Дата выдачи:</p>
                        <p
                            class="certificate__info-text"
                            v-html="currentDate"></p>
                    </div>
                </div>
            </div>
            <div class="certificate__decoration"></div>
            <div class="certificate__content">
                <h1 class="certificate__content-title">
                    Подтверждение
                    <br />
                    о&nbsp;том, что
                </h1>
                <p class="certificate__content-text">
                    {{ companyName }} и&nbsp;его работники, привлекаемые в&nbsp;целях выполнения обязательств по&nbsp;договорам и&nbsp;соглашениям, заключенным с&nbsp;{{ organizationName }}, ознакомились с&nbsp;принципами и&nbsp;требованиями в&nbsp;области противодействия коррупции, изложенными в&nbsp;информационном материале &laquo;Основы противодействия коррупции для контрагентов&raquo;.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Logo from './Logo.vue'

export default {
    name: 'certificate',
    components: {
        Logo
    },
    props: {
        companyName: {
            type: String,
            required: true
        },
        organizationName: {
            type: String,
            required: true
        },
        companyInn: {
            type: String,
            required: true
        },
        isSmall: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currentDate () {
            return moment(new Date()).format('DD.MM.YYYY')
        },
        getCertificateClass () {
            return { 'certificate--small': this.isSmall }
        }
    }
}
</script>

<style scoped lang="scss">
.certificate {
    background-image: url('/images/suppliers/presentation/presentation-bg.jpg');
    background-position: 100% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 160px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #e3e3e3;
        opacity: 0.75;
        z-index: 0;
        mix-blend-mode: color-dodge;
    }

    & > * {
        z-index: 1;
        position: relative;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $token-colors-navy-blue;
        padding: 80px;
    }

    &__logo {
        height: 120px;
        width: 570px;
        margin-bottom: 100px;
    }

    &__decoration {
        position: relative;
        width: 100%;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='20' viewBox='0 0 31 20' fill='none'%3E%3Cpath d='M26.3561 15.5532C25.3918 16.5188 24.2212 17.2015 22.946 17.5619V2.43941C24.2212 2.79914 25.3918 3.48179 26.3561 4.4455C27.8382 5.92882 28.654 7.90255 28.654 9.99936C28.654 12.0987 27.8382 14.0712 26.3561 15.5532ZM10.8003 10.6693V2.58787L20.7984 9.33384V17.4134L10.8003 10.6693ZM8.65594 17.5619C7.38009 17.2034 6.20892 16.5188 5.24521 15.5532C3.76189 14.0712 2.9441 12.0987 2.9441 10.0006C2.9441 7.90255 3.76189 5.92882 5.24521 4.4455C6.20892 3.48179 7.38009 2.79914 8.65594 2.43941V17.5619ZM20.7984 0V6.74534L10.8003 0.000634437C5.27693 0.000634437 0.799072 4.47786 0.799072 10.0006C0.799072 15.5247 5.27693 20 10.8003 20V13.2547L20.7984 20C26.3231 20 30.801 15.5247 30.801 9.99936C30.801 4.47786 26.3231 0 20.7984 0Z' fill='%23004C97'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: flex;
        align-items: center;
        height: 20px;

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

        &:before,
        &:after {
            content: '';
            height: 1px;
            width: calc(50% - 30px);
            display: block;
            background: $token-colors-navy-blue;
            position: absolute;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin-top: 114px;
        margin-bottom: 114px;
        width: 100%;
        justify-content: space-between;
        padding: 0 32px;
        gap: 32px;
    }

    &__info-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 32px;
    }

    &__info-block {
        display: flex;
        flex-direction: column;
        color: $token-colors-gray-56;
        line-height: 1;
        max-width: 290px;
        overflow-wrap: anywhere;
    }

    &__info-title {
        margin-bottom: 16px;
        font-weight: 600;
        letter-spacing: -0.12px;
        line-height: 1.2;
        font-size: 24px;
    }
    &__info-text {
        font-weight: 600;
        letter-spacing: -0.36px;
        line-height: 1.2;
        font-size: 36px;
    }

    &__content {
        margin-top: 200px;
        margin-bottom: 200px;
    }

    &__content-title {
        color: $token-colors-navy-blue;
        font-size: 96px;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 104px;
    }

    &__content-text {
        color: $token-colors-gray-60;
        font-size: 50px;
        font-weight: 400;
        line-height: 130%;
    }

    &__note {
        color: $token-colors-gray-60;
        font-size: 32px;
        line-height: 130%;
        align-self: flex-start;
        margin-bottom: 274px;
    }

    &--small {
        width: 350px;
        height: 495px;

        padding: 33px;
        border-radius: 0px 18px;
        box-shadow: 0px 1.653px 4.96px 0px rgba(0, 32, 51, 0.12),
            0px 1px 0.1px 0px rgba(0, 32, 51, 0.04);
        overflow: hidden;

        @include breakpoint(mobile) {
            height: auto;
            width: 100%;
            max-width: 350px;
        }

        .certificate {
            &__container {
                padding: 16px;
                border-width: 1px;
            }

            &__logo {
                height: 25px;
                width: 118px;
                margin-bottom: 21px;
            }

            &__decoration {
                height: 4px;

                &:before,
                &:after {
                    height: 0.5px;
                    width: calc(50% - 6px);
                }
            }

            &__info {
                margin-top: 24px;
                margin-bottom: 24px;
                padding: 0 6px;
                gap: 7px;
            }

            &__info-container {
                gap: 10px;
            }

            &__info-title {
                font-size: 5.456px;
                margin-bottom: 3px;
            }
            &__info-text {
                font-size: 7.274px;
            }

            &__content {
                margin-top: 41px;
                margin-bottom: 41px;
            }
            &__content-title {
                font-size: 19.838px;
                margin-bottom: 21px;
            }

            &__content-text {
                font-size: 10px;
            }

            &__note {
                font-size: 6px;
                margin-bottom: 57px;
            }
        }
    }
}
</style>
