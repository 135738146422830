/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort-down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.424 18.424l3-3-.848-.848-1.976 1.976V6h-1.2v10.552l-1.976-1.976-.848.848 3 3 .424.425.424-.425zM5 7.4a.6.6 0 000 1.2h8a.6.6 0 100-1.2H5zm0 4a.6.6 0 100 1.2h6a.6.6 0 100-1.2H5zM4.4 16a.6.6 0 01.6-.6h4a.6.6 0 110 1.2H5a.6.6 0 01-.6-.6z" _fill="#0077C8"/>'
  }
})
