<template>
    <contact-card
        v-if="contact"
        :photo="showPhoto ? contact.photo : ''"
        :phone="showPhone ? contact.phone : []"
        :fax="showFax? contact.fax : []"
        :email="showEmail ? customEmail || contact.email : []"
        :site="showSite ? contact.site : []"
        :address="showAddress ? contact.address : []"
        :horizontal="horizontal"
    >
        <template v-if="showName" v-slot:name>
            <span v-html="contact.name"></span>
        </template>
        <template v-if="showDescription" v-slot:description>
            <span v-html="contact.description"></span>
        </template>
        <slot/>
    </contact-card>
</template>

<script>
import { localize } from '@/utils/i18n'
import {mapGetters} from 'vuex'

export default {
    name: 'contact-card-hoc',
    props: {
        id: {
            type: String,
            required: true
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        customEmail: {
            type: Array
        },
        // Нижеперечисленные пропсы для контроля вывода данных
        showPhoto: {
            type: Boolean,
            default: true
        },
        showPhone: {
            type: Boolean,
            default: true
        },
        showFax: {
            type: Boolean,
            default: true
        },
        showAddress: {
            type: Boolean,
            default: true
        },
        showEmail: {
            type: Boolean,
            default: true
        },
        showSite: {
            type: Boolean,
            default: true
        },
        showName: {
            type: Boolean,
            default: true
        },
        showDescription: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters(['contacts']),
        contact () {
            if (!this.contacts) {
                return
            }

            const currentLangData = localize({
                ru: this.contacts.contacts.ru,
                en: this.contacts.contacts.en
            })

            const contact = currentLangData.find((item) => item.id === this.id)

            if (contact) {
                return contact
            }

            console.log(`Контакт с данным ID: ${this.id} отсутствует`)
        }
    }
}
</script>

<style lang="scss">

</style>
