<template>
    <z-list-item class="z-filelist-item" v-bind="$attrs">
        <div class="z-filelist-item__body">
            <div class="z-filelist-item__image">
                <div class="z-filelist-item__decor" v-if="iconName">
                    <div class="z-filelist-item__decor-svg">
                        <z-icon :name="iconName" />
                    </div>
                </div>
                <div class="z-filelist-item__icon" v-if="iconName">
                    <z-icon :name="iconName" />
                </div>
            </div>

            <div class="z-filelist-item__text">
                <div class="z-filelist-item__header" v-if="!!this.$slots['header']">
                    <slot name="header" />
                </div>
                <z-link
                    class="z-filelist-item__name"
                    :href="href"
                    :target="target"
                    @click="$emit('linkClick', $event)"
                    v-if="name || this.$slots['default']"
                >
                    <slot v-if="!name" />
                    <vue-raw :raw="name"/>
                </z-link>
                <template v-if="showFileInfo">
                    <div
                        class="z-filelist-item__details"
                        v-if="date || fileInfoArray.length || !!this.$slots['fileinfo']"
                    >
                        <time
                            v-if="date"
                            :pubdate="pubDate ? buildPubDate(pubDate) : ''"
                            class="z-filelist-item__date"
                            >{{ buildDate(date) }}</time
                        >

                        <template v-if="fileInfo || !!this.$slots['fileinfo']">
                            <vue-raw
                                v-html="fileInfo"
                                class="z-filelist-item__info"
                            />
                        </template>
                        <span v-if="md5" class="z-filelist-item__info z-filelist-item__info--md5">
                            MD5 HASH: {{ md5.toLowerCase() }}
                        </span>
                        <span v-if="!!this.$slots['fileinfo']" class="z-filelist-item__info">
                            <slot name="fileinfo" />
                        </span>
                    </div>
                </template>

                <div class="z-filelist-item__footer" v-if="!!this.$slots['footer']">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </z-list-item>
</template>

<script>
export default {
    name: 'z-filelist-item',
    props: {
        name: {
            type: String
        },
        href: {
            type: String
        },
        fileExtension: {
            type: String
        },
        fileSize: {
            type: String,
            default: ''
        },
        fileType: {
            type: String,
            default: ''
        },
        showFileInfo: {
            type: Boolean,
            default: true
        },
        date: {
            type: [String, Number]
        },
        pubDate: {
            type: [String, Number]
        },
        iconName: {
            type: String
        },
        target: {
            type: String,
            default: '_blank'
        },
        md5: {
            type: String
        }
    },
    created () {
        if (this.icon === 'docx') this.icon = 'doc'
    },
    methods: {
        buildDate (str) {
            return typeof str === 'number'
                ? this.$options.filters.momentFilter(str, 'DD.MM.YYYY')
                : str
        },
        buildPubDate (str) {
            return typeof str === 'number'
                ? this.$options.filters.momentFilter(str, 'DD.MM.YYYY HH:mm')
                : str
        }
    },
    computed: {
        fileInfo () {
            return this.fileInfoArray.join(', ')
        },
        fileInfoArray () {
            let infoArray = []
            if (this.fileExtension && !this.iconName) infoArray.push(this.fileExtension)
            if (this.fileType) infoArray.push(this.fileType)
            if (this.fileSize) infoArray.push(this.fileSize)
            return infoArray
        }
    }
}
</script>
