<template>
    <div class="updates-table">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'UpdatesTable'
}
</script>

<style lang="scss">
.updates-table {
    display: flex;
    gap: $token-spacers-xs;
    font-family: $robotoMono;

    @include breakpoint(mobile) {
        flex-direction: column;
    }
}
</style>
