<template>
<div :class="['row-filter', classObject]">
    <button
        v-for="option in options"
        :key="option.id"
        class="row-filter__item"
        @click="clickHandler(option)"
        :class="{
            'disabled': isDisable(option),
            'selected': isEnable(option),
        }"

    >
        <span v-html="option.text"></span>
    </button>
</div>
</template>

<script>
export default {
    name: 'row-filter',
    props: {
        filters: Array,
        fullWidth: Boolean,
        size: {
            type: String,
            validator: prop => ['m', 's'].includes(prop)
        },
        default: {
            type: String
        }
    },
    data: function () {
        return {
            selected: this.default,
            options: this.filters
        }
    },
    computed: {
        classObject () {
            return {
                [`row-filter--size-${this.size}`]: this.size,
                'row-filter--full-width': this.fullWidth
            }
        }
    },
    watch: {
        filters (array) {
            this.options = array
        },
        default (value) {
            this.selected = value
        }
    },
    methods: {
        clickHandler (filter) {
            if (filter.selected) {
                filter.selected = false
                this.$emit('change', this.selected)
                return
            }
            if (this.isDisable(filter)) {
                return
            }
            this.selected = filter.id
            this.$emit('change', this.selected)
        },
        isEnable (filter) {
            return filter.id === this.selected || filter.selected
        },
        isDisable (filter) {
            return filter.disabled
        },
        clear () {
            this.options = this.options.map(item => ({...item, selected: false}))
            this.selected = ''
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
