<template>
    <div class="swiper-slide">
        <div class="swiper-slide__content">
            <div class="swiper-slide__wrapper">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'slide'
}
</script>

<style lang="scss"></style>
