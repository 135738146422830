<template>
    <z-button kind="text" :disabled="disabled" @click="clickHandler">
        <template #left>
            <z-icon name="download" width="20" height="20" />
        </template>
        Скачать Excel
    </z-button>
</template>

<script>
import { getExcelLink } from '@/api/excel-link'
import {debounce} from 'throttle-debounce'
import {showNotyfications} from '@/utils/notifications'

export default {
    name: 'download-excel-btn',
    props: {
        urlSections: {
            type: String,
            default: ''
        },
        filters: {
            type: Object,
            default: () => {}
        },
        disabled: Boolean
    },
    methods: {
        clickHandler: debounce(300, function (params) {
            getExcelLink(this.urlSections, this.filters)
                .then((response) => {
                    if (response.status !== 'success') {
                        showNotyfications(response.message, {type: 'error'})
                        return
                    }

                    const downloadLink = document.createElement('a')
                    downloadLink.href = window.location.origin + response.link
                    downloadLink.click()
                })
        })
    }
}
</script>

<style lang="scss"></style>
