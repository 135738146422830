<template>
    <div class="bonds-page-item">
        <span v-if="title" class="bonds-page-item__title">
            <span v-html="title"/>
        </span>
        <div class="bonds-page-item__text" :style="`font-size: ${fontSize}px`">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bonds-page-item',
    props: {
        title: {
            type: String
        },
        fontSize: {
            type: String,
            default: '16'
        }
    },
    data () {
        return {
            copied: false
        }
    }
}
</script>

<style lang="scss">
.bonds-page-item {
    width: 100%;

    &__title {
        font-weight: 700;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-bottom: $token-spacers-3-xs;
        display: flex;
        align-items: center;
        position: relative;

        sup {
            font-size: 0.7em;
        }

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__text {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.4;
        color: $token-colors-black;
    }

    .z-filelist-item__date + .z-filelist-item__info:after,
    .z-filelist-item__info + .z-filelist-item__info:after {
        left: -0.7em;
    }
}
</style>
