<template>
<div class="results-presentations" v-if="data && data.length">
    <z-caption
        decor-right
        tag="h2"
        size="3xl"
        class="u-bottom-margin--m"
    >
        <span v-html="text.presentation"></span>
    </z-caption>
    <div class="row">
        <div
            class="col-default-4 col-desktop-6 col-v-tablet-12"
            v-for="(item, index) in data"
            :key="index"
        >
            <presentation-block
                pdfIconName="file/text-pdf"
                :pdfIconSize="{
                    width: '50px',
                    height: '18px',
                }"
                :data="{
                    name: item.name,
                    size: item.mlDocs.size,
                    link: item.mlDocs.link,
                    image: item.mlAlbumImg.SRC

                }"
            ></presentation-block>
        </div>
    </div>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'results-presentations',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            text: {
                presentation: localize({
                    ru: 'Презентации',
                    en: 'Presentations'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.results-presentations {
    .presentation-block {
        align-items: start;
    }
}
</style>
