/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Co': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32272)"/><path pid="2" d="M30.151 40.3c-4.924 0-8.75-3.5-8.75-8.625s3.826-8.625 8.75-8.625c3.375 0 5.45 1.75 6.7 3.75l-2.5 1.275c-.8-1.375-2.4-2.425-4.2-2.425-3.3 0-5.75 2.525-5.75 6.025 0 3.5 2.45 6.025 5.75 6.025 1.8 0 3.4-1.025 4.2-2.425l2.5 1.275c-1.274 2-3.325 3.75-6.7 3.75zm14.292 0c-3.825 0-6.2-2.875-6.2-6.35 0-3.45 2.374-6.325 6.2-6.325 3.875 0 6.224 2.875 6.224 6.325 0 3.475-2.35 6.35-6.224 6.35zm0-2.325c2.25 0 3.5-1.875 3.5-4.025 0-2.125-1.25-4-3.5-4-2.225 0-3.475 1.875-3.475 4 0 2.15 1.25 4.025 3.474 4.025z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32272" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
