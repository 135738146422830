<template>
    <div class="z-lead" :class="classObject">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'z-lead',
    props: {
        theme: {
            type: String,
            default: 'blue',
            validator: (prop) => ['blue', 'white'].includes(prop)
        }
    },
    computed: {
        classObject () {
            let arr = []
            if (this.theme) arr.push(`z-lead--theme-${this.theme}`)
            return arr
        }
    }
}
</script>

<style lang="scss" src="./index.scss">
</style>
