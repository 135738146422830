<template>
    <div class="local-tenders-table">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'LocalTendersTable'
}
</script>

<style lang="scss">
.local-tenders-table {
    display: flex;
    gap: $token-spacers-xs;
    font-family: $robotoMono;

    @include breakpoint(laptop) {
        flex-wrap: wrap;
    }

    @include breakpoint(v-tablet) {
        flex-direction: column;
    }
}
</style>
