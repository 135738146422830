/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  ' map_delivery/truck': {
    width: 25,
    height: 19,
    viewBox: '0 0 25 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 .593C0 .265.265 0 .593 0h12.635a2.277 2.277 0 012.275 2.275v2.45a.593.593 0 01.165-.023h5.322c.756 0 1.43.398 1.793 1.064l.003.005 2.048 3.838.07.131V16.09h-2.218a3.44 3.44 0 01-6.798-.001H9.382a3.44 3.44 0 01-6.798 0H.593a.593.593 0 010-1.186h2.014a3.44 3.44 0 016.753 0h4.957V2.275c0-.6-.49-1.09-1.089-1.09H.593A.593.593 0 010 .594zm15.503 14.309V5.865a.593.593 0 00.165.023h2.768v4.616h5.282v4.399h-1.055a3.44 3.44 0 00-6.752-.001h-.408zm7.831-5.584L21.741 6.33a.834.834 0 00-.75-.443h-1.37v3.43h3.713zM3.75 15.266a2.254 2.254 0 11-.011.483.59.59 0 00.011-.483zm15.538-1.96a2.253 2.253 0 100 4.507 2.253 2.253 0 000-4.506zM0 4.585c0-.328.265-.593.593-.593h9.142a.593.593 0 110 1.186H.593A.593.593 0 010 4.584zM.593 6.9a.593.593 0 100 1.186h9.142a.593.593 0 000-1.186H.593z" _fill="#004C97"/>'
  }
})
