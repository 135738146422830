/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/rtf': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM18.883 31h1.62l-1.956-3.144c.96-.156 1.896-.924 1.896-2.352 0-1.476-1.032-2.508-2.616-2.508h-3.516V31h1.404v-2.988h1.392L18.883 31zm.12-5.496c0 .768-.576 1.272-1.368 1.272h-1.92v-2.544h1.92c.792 0 1.368.504 1.368 1.272zM23.637 31h1.404v-6.768h2.424v-1.236h-6.252v1.236h2.424V31zm5.018 0h1.404v-3.456h3.996v-1.236h-3.996v-2.076h4.08v-1.236h-5.484V31z" _fill="#737B91"/>'
  }
})
