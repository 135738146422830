/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-small/rar': {
    width: 50,
    height: 60,
    viewBox: '0 0 50 60',
    data: '<path pid="0" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="1" d="M17.992 35l-2.88-4.352c1.456-.128 2.768-1.184 2.768-3.104 0-1.968-1.392-3.216-3.344-3.216h-4.288V35h1.328v-4.24h2.128l2.72 4.24h1.568zm-3.616-5.408h-2.8v-4.08h2.8c1.264 0 2.128.832 2.128 2.032 0 1.2-.864 2.048-2.128 2.048zM29.118 35l-4.304-10.672h-1.648L18.878 35h1.52l.944-2.368h5.312L27.598 35h1.52zm-2.864-3.552h-4.528l2.272-5.744 2.256 5.744zM38.258 35l-2.88-4.352c1.456-.128 2.768-1.184 2.768-3.104 0-1.968-1.392-3.216-3.344-3.216h-4.288V35h1.328v-4.24h2.128L36.69 35h1.568zm-3.616-5.408h-2.8v-4.08h2.8c1.264 0 2.128.832 2.128 2.032 0 1.2-.864 2.048-2.128 2.048z" _fill="#0077C8"/>'
  }
})
