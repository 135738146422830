<template>
<div class="phys-chars" v-if="data">
    <z-caption
        decor-left
        decor-right
        uppercase
        tag="p"
        size="m"
        weight="700"
    >
        <span v-html="data.name"></span>
    </z-caption>
    <div class="phys-chars__container">
        <div
            class="phys-chars__image"
            :style="`background-image: url(${data.image})`"
            v-if="data.image"
        ></div>
        <div class="phys-chars__content">
            <div class="product-chars">
                <div class="product-chars__item">
                    <p class="product-chars__prop" v-html="text.sieve"></p>
                    <p
                        class="product-chars__value"
                        v-html="data.sieve ? data.sieve : '–'"
                    ></p>
                </div>
                <div class="product-chars__item">
                    <p class="product-chars__prop" v-html="text.fisher"></p>
                    <p
                        class="product-chars__value"
                        v-html="data.fisher ? data.fisher : '–'"
                    ></p>
                </div>
                <div class="product-chars__item">
                    <p class="product-chars__prop" v-html="text.specific"></p>
                    <p
                        class="product-chars__value"
                        v-html="data.specific ? data.specific : '–'"
                    ></p>
                </div>
                <div class="product-chars__item">
                    <p class="product-chars__prop" v-html="text.density"></p>
                    <p
                        class="product-chars__value"
                        v-html="data.density ? data.density : '–'"
                    ></p>
                </div>
            </div>
            <div class="chemical-composition hide-me--tablet" v-if="data['chemical-composition'].length">
                <p class="chemical-composition__title" v-html="text['chemical-composition']"></p>
                <div class="chemical-composition__list">
                    <div
                        class="chemical-composition__item"
                        v-for="(item, index) in data['chemical-composition']"
                        :key="index"
                    >
                        <p class="chemical-composition__prop" v-html="item.prop"></p>
                        <p class="chemical-composition__value" v-html="item.value"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="chemical-composition hide-me--default show-me--tablet" v-if="data['chemical-composition'].length">
        <p class="chemical-composition__title" v-html="text['chemical-composition']"></p>
        <div class="chemical-composition__list">
            <div
                class="chemical-composition__item"
                v-for="(item, index) in data['chemical-composition']"
                :key="index"
            >
                <p class="chemical-composition__prop" v-html="item.prop"></p>
                <p class="chemical-composition__value" v-html="item.value"></p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'phys-chars',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                sieve: localize({
                    en: 'Sieve test, wt% +20 mµ:',
                    ru: 'Гранулометрический состав, %&nbsp;масс + 20&nbsp;мкм'
                }),
                fisher: localize({
                    en: 'Fisher sub-sieve size, mkm:',
                    ru: 'Средний размер частиц по&nbsp;Фишеру, мкм'
                }),
                specific: localize({
                    en: 'Specific surface, m<sup>2</sup>/g:',
                    ru: 'Удельная поверхность, м<sup>2</sup>/г'
                }),
                density: localize({
                    en: 'Bulk density, g/cc:',
                    ru: 'Насыпная плотность, г/см<sup>3</sup>'
                }),
                'chemical-composition': localize({
                    en: 'Typical Chemical Composition&nbsp;(%)',
                    ru: 'Химические характеристики'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.phys-chars {
    & + & {
        margin-top: $token-spacers-xs;
    }

    &__container {
        display: flex;
        align-items: flex-start;
        margin-top: -$token-spacers-xs;

        @include breakpoint (mobile) {
            display: block;
            overflow: hidden;
            margin-top: 0;
        }
    }

    &__image {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        width: 170px;
        flex-shrink: 0;
        margin-right: 12px;
        margin-top: $token-spacers-xs;

        &:after {
            content: '';
            padding-top: 100%;
            display: block;
        }
    }

    &__content {
        flex-grow: 1;
        margin-top: $token-spacers-xs;
    }
}

.product-chars {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -$token-spacers-2-xs;
    margin-left: -$token-spacers-2-xs;
    margin-right: -$token-spacers-2-xs;

    &__item {
        max-width: 25%;
        margin-top: $token-spacers-2-xs;
        padding: 0 $token-spacers-2-xs;

        @include breakpoint (tablet) {
            width: 50%;
            max-width: none;
        }

        @include breakpoint (mobile) {
            width: 100%;
        }
    }

    &__prop {
        color: $token-colors-gray-50;
        @include typo-level(XS);
        margin: 0;
        font-weight: 600;

        sup,
        sub {
            font-size: 0.7em;
            font-weight: 600;
        }
    }

    &__value {
        color: $token-colors-black;
        @include typo-level(S);
        font-weight: 600;
        margin: 0;
        font-family: $robotoMono;
    }
}

.chemical-composition {
    margin-top: $token-spacers-3-xs;

    &__title {
        color: $token-colors-gray-50;
        @include typo-level(XS);
        margin: 0;
        font-weight: 600;
        margin-bottom: $token-spacers-3-xs;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: -24px;
    }

    &__item {
        padding: 8px;
        min-width: 75px;
        max-width: 110px;
        width: 10%;
        flex-shrink: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 93px;
        background: #EEEFF1;
        margin-top: 24px;

        &:nth-child(even) {
            background: $token-colors-white;
        }
    }

    &__prop {
        color: $token-colors-navy-blue;
        font-family: $robotoMono;
        @include typo-level(XS);
        margin-bottom: 4px;
    }

    &__value {
        font-family: $robotoMono;
        @include typo-level(S);
        font-weight: 600;
        margin: 0;
    }
}
</style>
