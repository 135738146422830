/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/pptx': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM12.935 31h1.17v-2.49h1.76c1.39 0 2.18-.96 2.18-2.09s-.78-2.09-2.18-2.09h-2.93V31zm3.91-4.58c0 .64-.48 1.06-1.14 1.06h-1.6v-2.12h1.6c.66 0 1.14.42 1.14 1.06zM19.019 31h1.17v-2.49h1.76c1.39 0 2.18-.96 2.18-2.09s-.78-2.09-2.18-2.09h-2.93V31zm3.91-4.58c0 .64-.48 1.06-1.14 1.06h-1.6v-2.12h1.6c.66 0 1.14.42 1.14 1.06zM26.693 31h1.17v-5.64h2.02v-1.03h-5.21v1.03h2.02V31zm8.641 0h1.4l-2.49-3.43 2.34-3.24h-1.41l-1.71 2.45-1.72-2.45h-1.4l2.35 3.25-2.5 3.42h1.4l1.87-2.61 1.87 2.61z" _fill="#737B91"/>'
  }
})
