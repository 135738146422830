<template>
    <div
        class="presentation-block"
        :class="{
            'presentation-block--vertical': this.vertical
        }"
        v-if="data"
    >
        <div class="presentation-block__image">
            <img src="/images/presentation-block.png" alt="" />
            <div
                class="presentation-block__cover"
                v-if="data.image"
                :style="`background-image: url(${data.image})`"
            ></div>
        </div>
        <div class="presentation-block__info">
            <div class="presentation-block__header" v-if="this.$slots.header">
                <slot name="header" />
            </div>
            <p
                class="presentation-block__name"
                v-if="data.name"
            >
                <vue-raw :raw="data.name"/>
            </p>
            <p
                class="presentation-block__size"
                v-if="data.size"
            >
                <vue-raw :raw="data.size"/>
            </p>
            <div class="presentation-block__links">
                <z-button v-if="fileExtansion" kind="secondary" size="s" tag="a" :href="data.link">
                    <span class="u-bold">{{fileExtansion}}</span>
                </z-button>
                <z-link v-else :href="data.link" size="s" target="_blank">
                    <template v-slot:left>
                        <div class="presentation-block__icon">
                            <z-icon :name="pdfIconName" :width="pdfIconSize.width" :height="pdfIconSize.height" />
                        </div>
                    </template>
                    <span class="u-bold">
                        <vue-raw :raw="text.savePdf"/>
                    </span>
                </z-link>
                <z-link
                    v-if="data.externalLink && data.externalLink.link"
                    class="u-top-margin--2xs u-flex"
                    :href="data.externalLink.link"
                >
                    <template v-slot:left>
                        <z-icon name="external" dir="top" width="24" height="24"/>
                    </template>
                    {{data.externalLink.title}}
                </z-link>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'presentation-block',
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
                size: '',
                link: '',
                image: '',
                externalLink: {
                    title: '',
                    link: ''
                }
            })
        },
        pdfIconName: {
            type: String,
            default: 'monitor'
        },
        pdfIconSize: {
            type: Object,
            default: () => {
                return {
                    width: '20px',
                    height: '20px'
                }
            }
        },
        fileExtansion: {
            type: String,
            default: ''
        },
        vertical: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            text: {
                savePdf: localize({
                    ru: 'Сохранить PDF',
                    en: 'Save PDF'
                })
            }
        }
    }
}
</script>

<style lang="scss">
    .presentation-block {
        $parrent: &;

        max-width: 540px;
        display: flex;
        align-items: center;

        @include breakpoint(mobile) {
            display: block;
        }

        &--vertical {
            flex-direction: column;

            #{$parrent}__image {
                @include margin-level(bottom, XS);
                max-width: 100%;
            }

            #{$parrent}__name {
                @include margin-level(bottom, 2XS);
            }

            #{$parrent}__info {
                padding-left: 0;
            }
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $token-colors-blue;
            border-radius: 20px;
            min-width: 36px;
            height: 36px;
            @include margin-level(right, 2XS);
        }

        &__name {
            line-height: 1.2;
            font-weight: 600;
            margin: 0;
        }

        &__header {
            margin-bottom: 4px;
        }

        &__size {
            color: $token-colors-gray-30;
            @include typo-level(XS);
            font-weight: 400;
            @include margin-level(bottom, 3XS);
            margin-top: 4px;
        }

        &__info {
            @include padding-level(left, S);

            @include breakpoint(mobile) {
                padding-left: 0;
                @include margin-level(top, XS);
            }
        }

        &__links {
            display: flex;
            flex-direction: column;
            align-items: start;

            .z-link + .z-link {
                margin-top: 16px;
            }

            .z-button {
                padding: 8px 10px !important;
                font-size: 14px;
                line-height: 1.4;
                letter-spacing: 0.1em;

                @include breakpoint (mobile) {
                    width: initial !important;
                    margin-top: 8px;
                }
            }
        }

        &__image {
            position: relative;
            flex-shrink: 0;
            max-width: 50%;

            img {
                width: 100%;
            }
        }

        &__cover {
            position: absolute;
            width: 86%;
            top: 6.5%;
            left: 50%;
            padding-top: 48%;
            transform: translateX(-50%);
            border-radius: 2px;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
    }
</style>
