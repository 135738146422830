<template>
    <div class="media-album-detail">
        <div class="container">
            <div class="media-album__info u-entire-width u-bottom-margin--m u-bottom-padding--m u-flex u-justify-content--between u-align-items--center">
                <span class="media-album__date">{{date}}</span>
                <div v-if="app.city" class="media-album__places u-flex u-align-items--center">
                    <div
                        v-for="(location, id) of app.city"
                        :key="id"
                        class="media-album__place u-flex u-align-items--center u-left-margin--xs"
                    >
                        <z-icon
                            class="u-right-margin--3xs"
                            name="pin"
                            dir="top"
                            width="24"
                            height="24"
                        >
                        </z-icon>
                        <span>{{location.value}}</span>
                    </div>
                </div>
            </div>

            <div class="row u-justify-content--center">
                <div class="col-default-10 u-align-center">
                    <div
                        v-if="(app.mlVideoLink && app.mlVideoLink.value) || (app.detailPicture && app.detailPicture.SRC) || (app.previewPicture && app.previewPicture.SRC)"
                        class="media-album-detail__image-wrapper u-bottom-margin--xs u-right-top-round--xl"
                    >
                        <div
                            v-if="(app.mlType.xmlId === 'photo' && (app.detailPicture || app.previewPicture)) || (app.mlType.xmlId === 'interview' && !app.mlVideoLink.value)"
                            class="media-album-detail__image-content media-album-detail__image-content--img "
                            :style="app.detailPicture && app.detailPicture.value
                                ? `background-image: url(${app.detailPicture.SRC})`
                                : `background-image: url(${app.previewPicture.SRC})`"
                        >
                        </div>
                        <z-video
                            v-else-if="(app.mlType.xmlId === 'video' || (app.mlType.xmlId === 'interview'))"
                            :autoplay="false"
                            class="media-album-detail__image-content media-album-detail__image-content--video "
                            :src="app.mlVideoLink.value"
                            :poster="app.detailPicture && app.detailPicture.value ? app.detailPicture.SRC : app.previewPicture.SRC"
                        ></z-video>
                    </div>
                </div>
            </div>

            <div class="media-album-detail__content col-default-11 col-mobile-12 u-left-padding--2xl u-collapse--top">
                <p v-html="app.previewText"></p>
                <div class="media-album-detail__links u-top-margin--2xl">
                    <!-- <z-button v-if="app.mlDocs.link" :href="app.mlDocs.link" kind="secondary" class="u-right-margin--2xl">
                        {{app.mlType.xmlId === 'photo' ? download : download2}}
                        <template v-slot:right>
                            <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                        </template>
                    </z-button> -->

                    <z-button v-if="app.mlDocs.link && app.mlType.xmlId !== 'photo'" tag="a" :href="app.mlDocs.link" kind="secondary" class="u-right-margin--2xl">
                        {{download2}}
                        <template v-slot:right>
                            <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                        </template>
                    </z-button>
                    <z-button v-if="((app.detailPicture && app.detailPicture.SRC) || (app.previewPicture && app.previewPicture.SRC)) && app.mlType.xmlId === 'photo'" download :href="app.detailPicture && app.detailPicture.SRC ? app.detailPicture.SRC : app.previewPicture.SRC" tag="a" kind="secondary" class="u-right-margin--2xl">
                        {{download}}
                        <template v-slot:right>
                            <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                        </template>
                    </z-button>
                    <z-link
                        v-if="app.mlExternalLink.value"
                        class="u-flex"
                        :href="app.mlExternalLink.value"
                    >
                        <template v-slot:left>
                            <z-icon name="external" dir="top" width="24" height="24"/>
                        </template>
                        {{app.mlExternalLink.description || mlExternalLink}}
                    </z-link>
                </div>
                <div v-if="app.mlSubject.xmlId && app.mlSubject.xmlId.length > 0" class="media-album-detail__tags u-top-margin--m u-bottom-margin--m">
                    <tag
                        v-for="(tag, id) of app.mlSubject.value"
                        :key="id"
                        class="media-album-detail__tag u-right-margin--3xs"
                        theme="light-blue"
                        :name="localizeTag(tag)"
                        :interactive="false"
                    ></tag>
                </div>
            </div>
            <page-changer
                class="u-top-margin--m"
                :data="pageChangerData"
            ></page-changer>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'media-album-detail',
    data () {
        return {
            enter: localize({
                ru: 'Введите',
                en: 'Enter'
            }),
            mlExternalLink: localize({
                ru: 'Посмотреть источник',
                en: 'Original'
            }),
            download: localize({
                ru: 'Скачать',
                en: 'Download'
            }),
            download2: localize({
                ru: 'Скачать расшифровку',
                en: 'Download the transcript'
            })
        }
    },
    methods: {
        localizeTag (tag) {
            const tagArray = tag?.split(' / ')
            return this.$root.app.page.lang === 'ru' ? tagArray[0] : tagArray[1]
        }
    },
    computed: {
        app () {
            return window.App.components['media-library-detail'].item
        },

        months () {
            return this.$root.lang === 'ru'
                ? ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']
                : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },

        date () {
            const date = new Date(this.app.activeFrom * 1000)

            const year = date.getFullYear()
            const month = this.months[date.getMonth()]
            const day = date.getDate()

            const formattedTime = `${day} ${month} ${year} г.`
            return formattedTime
        },

        navigation () {
            return window.App.components['media-library-detail']
        },

        pageChangerData () {
            return {
                prev: {
                    // name: this.navigation.nav.prev.name, // предпросмотр названия траницы
                    url: this.navigation.nav.prev?.url
                },
                next: {
                    // name: this.navigation.nav.next.name, // предпросмотр названия траницы
                    url: this.navigation.nav.next?.url
                },
                'back-link': this.navigation['back-link']
            }
        }
    }
}
</script>

<style lang="scss" >
.media-album-detail {
    .container {
        display: flex;
        flex-direction: column;
    }

    &__image-wrapper {
        position: relative;
        padding-top: 56.3%;
        width: 100%;
        height: 0;
        background-color: #999;

        @include breakpoint (mobile) {
            border-top-right-radius: $boder-radius-m;
        }
    }

    &__image-content--img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: center;
    }

    &__image-content--video {
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }

    &__content {
        @include breakpoint (v-tablet) {
            padding-left: 0;
        }
    }

    &__tag {
        @include margin-level(top, XS);
    }

    &__links {
        display: flex;
        align-items: center;

        @include breakpoint (mobile) {
            flex-direction: column;
        }

        .z-button {
            @include breakpoint (mobile) {
                margin-right: 0;
                margin-bottom: 16px;
            }
        }
    }
}
</style>
