<template>
    <div
        class="z-tabs"
        :class="buildClass"
    >
        <div class="z-tabs__container">
            <div class="z-tabs__header">
                <slot name="label"/>
            </div>
        </div>
        <slot name="content"/>
    </div>
</template>

<script>
export default {
    name: 'z-tabs',
    props: {
        nested: Boolean,
        counter: Boolean,
        padding: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            validator: prop => ['s', 'm', 'l'].includes(prop),
            default: 'l'
        },
        kind: {
            type: String,
            validator: prop => [
                'default',
                'drop'
            ].includes(prop),
            default: 'default'
        }
    },
    data () {
        return {
            name: 'z-tabs',
            hash: window.location.hash,
            openedItem: {
                id: {
                    type: String,
                    default: ''
                }
            }
        }
    },
    mounted () {
        this.defaultState()
        this.$nextTick(() => this.checkHash())
        this.$on('toggle-item', data => this.openHandler(data))
    },
    beforeDestroy () {
        this.$off('toggle-item', data => this.openHandler(data))
    },
    computed: {
        currentHash () {
            return this.hash ? this.hash.split('#')[1] : ''
        },
        buildClass () {
            return [
                `z-tabs--size-${this.size}`,
                `z-tabs--kind-${this.kind}`,
                {
                    'z-tabs--counter': this.counter,
                    'z-tabs--wide': this.wide,
                    'z-tabs--top': this.top,
                    'z-tabs--without-padding': !this.padding
                }
            ]
        }
    },
    methods: {
        openHandler (data) {
            this.openedItem.id = data

            for (let i = 0; i < this.$children.length; i++) {
                if (this.$children[i].itemId === this.openedItem.id) {
                    this.$children[i].state = true
                } else {
                    this.$children[i].state = false
                }
            }

            this.$emit('open-tab', this.openedItem.id)
        },
        checkHash () {
            if (this.currentHash.length) {
                let matched = this.$children.filter(item => item.itemId === this.currentHash)

                if (matched.length) this.openItem()
            }
        },
        defaultState () {
            // добавил проверку на disabled, по умолчанию ставиться активным первый таб у которого disabled = false
            let label, content
            let index = 0
            let labelsArr = this.$children.filter(item => item.name === 'z-tabs-label')
            let contentArr = this.$children.filter(item => item.name === 'z-tabs-content')

            for (let i = 0; i < labelsArr.length; i++) {
                if (!labelsArr[i].disabled) {
                    index = i
                    break
                }
            }

            label = labelsArr[index]
            content = contentArr[index]

            if (label) label.state = true
            if (content) content.state = true
        },
        closeAll () {
            for (let i = 0; i < this.$children.length; i++) {
                this.$children[i].state = false
            }
        },
        openItem () {
            for (let i = 0; i < this.$children.length; i++) {
                if (this.$children[i].itemId === this.currentHash) {
                    this.$children[i].state = true

                    this.openedItem.id = this.$children[i].itemId

                    if (this.nested) this.openParent(this.$parent)
                    if (this.$parent.name === 'z-accordion-item') this.openAccordion(this.$parent)
                } else {
                    this.$children[i].state = false
                }
            }

            this.$emit('open-tab', this.openedItem.id)
        },
        openParent (parent) {
            let grandParent = parent.$parent
            let grandParentChildrens = grandParent.$children
            let grandParentTabs = grandParentChildrens.filter(item => item.name === 'z-tabs-label')
            let grandParentContent = grandParentChildrens.filter(item => item.name === 'z-tabs-content')

            for (let i = 0; i < grandParentTabs.length; i++) {
                if (grandParentTabs[i].itemId === parent.itemId) {
                    grandParentTabs[i].state = true
                    grandParentContent[i].state = true
                } else {
                    grandParentTabs[i].state = false
                    grandParentContent[i].state = false
                }
            }

            if (grandParent.$parent.name === 'z-accordion-item') this.openAccordion(grandParent.$parent)

            if (grandParent.name === 'z-tabs' && grandParent.nested) {
                grandParent.$parent.state = true
                this.openParent(grandParent.$parent)
            }
        },
        openAccordion (parent) {
            let accordion = parent.$parent

            accordion.openParent(parent)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
