<template>
    <div class="reports-and-results" v-if="data">
        <div class="container">
            <z-tabs
                v-if="data.tabs"
                size="m"
                @open-tab="onTabChange"
            >
                <template v-slot:label>
                    <z-tabs-label
                        v-for="(item, index) in data.tabs"
                        :key="index"
                        :id="item.value"
                    >
                        {{ item.name }}
                    </z-tabs-label>
                </template>
            </z-tabs>

            <div class="reports-and-results__content u-top-padding--m">
                <results :data="data" />
                <z-preloader class="annual-reports__preloader" v-if="isLoading"/>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import Results from './components/Results.vue'
import getData from '@/api/reports-and-results'

export default {
    name: 'reports-and-results',
    components: {
        Results
    },
    data () {
        return {
            isLoading: false,
            data: null,
            currentInfo: null,
            text: {
                results: localize({
                    ru: 'Финансовые и операционные результаты',
                    en: 'Financial and Operation Results'
                }),

                annualReports: localize({
                    ru: 'Годовые отчеты',
                    en: 'Annual reports'
                })
            }
        }
    },
    created () {
        if (this.$root.app.components['reports-and-results']) {
            this.data = this.$root.app.components['reports-and-results']
            this.currentInfo = this.$root.app.components['reports-and-results']['reports-and-results']
        }
    },
    methods: {
        onTabChange (tab) {
            const year = tab.replace('tabs-', '')
            this.isLoading = true

            getData({ year: year })
                .then(res => {
                    this.isLoading = false
                    this.currentInfo = res['reports-and-results']
                    this.data = res
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss">
.reports-and-results {
    &__content {
        position: relative;
    }

    &__preloader {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($token-colors-white, 0.7);
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
