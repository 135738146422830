<template>
    <div class="property-complexes-detail container">
        <span
            class="property-complexes-detail__tag"
            :style="`background-color: ${getColor(item.order.xmlId)}`"
            v-html="item.order.value"
        />
        <layout>
            <template #content>
                <content-container>
                    <div class="property-complexes-detail__table">
                        <div v-if="item.address.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Адрес</div>
                            <div class="property-complexes-detail__table-value" v-html="item.address.value"/>
                        </div>
                        <div v-if="item.biddingDate.timestamp" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Дата проведения торгов</div>
                            <div class="property-complexes-detail__table-value">{{item.biddingDate.timestamp | momentFilter('DD.MM.YYYY hh:mm:ss')}}</div>
                        </div>
                        <div v-if="item.registrationDate.timestamp" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Дата регистрации</div>
                            <div class="property-complexes-detail__table-value">{{item.registrationDate.timestamp | momentFilter('DD.MM.YYYY')}}</div>
                        </div>
                        <div v-if="item.type.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Вид деятельности</div>
                            <div class="property-complexes-detail__table-value" v-html="item.type.value"/>
                        </div>
                        <div v-if="item.cleanSize.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Размер чистых активов</div>
                            <div class="property-complexes-detail__table-value" v-html="item.cleanSize.value"/>
                        </div>
                        <div v-if="item.capitalSize.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Размер уставного капитала</div>
                            <div class="property-complexes-detail__table-value" v-html="item.capitalSize.value"/>
                        </div>
                        <div v-if="item.number.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">ОГРН</div>
                            <div class="property-complexes-detail__table-value" v-html="item.number.value"/>
                        </div>
                        <div v-if="item.member.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Участник, доля участия (%)</div>
                            <div class="property-complexes-detail__table-value" v-html="item.member.value"/>
                        </div>
                        <div v-if="item.owner.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Данные о&nbsp;собственнике&nbsp;/ балансодержателе</div>
                            <div class="property-complexes-detail__table-value" v-html="item.owner.value"/>
                        </div>
                        <div v-if="item.object.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Объект продажи (акции (шт), доля в&nbsp;уставном капитале (%))</div>
                            <div class="property-complexes-detail__table-value" v-html="item.object.value"/>
                        </div>
                        <div v-if="item.cost.value" class="property-complexes-detail__table-item">
                            <div class="property-complexes-detail__table-key">Стоимость</div>
                            <div class="property-complexes-detail__table-value" v-html="item.cost.value"/>
                        </div>
                    </div>
                    <z-caption v-if="item.images && item.images.length" uppercase decor-right size="s" weight="700">Изображения</z-caption>
                    <div class="row" v-if="item.images && item.images.length">
                        <div
                            v-for="(image, index) in item.images"
                            :key="index"
                            class="col-default-4 col-tablet-6 col-v-tablet-12">
                            <z-figure :image="image.link"></z-figure>
                        </div>
                    </div>
                    <vue-raw :raw="item.detailText"/>
                    <div v-if="item.files && item.files.length" class="u-top-margin--xl">
                        <z-caption tag="h3" size="2xl" weight="600">Прикреплённые файлы</z-caption>
                        <z-filelist>
                            <template v-for="(file, index) in item.files">
                                <z-filelist-item
                                    :href="file.link"
                                    :name="file.name"
                                    :file-size="file.size"
                                    :icon-name="`file/${file.type}`"
                                    :key="`file-${index}`"/>
                            </template>
                        </z-filelist>
                    </div>
                </content-container>
            </template>
        </layout>
        <page-changer
            v-if="nav"
            :data="nav"
            class="u-top-margin--l"
            default-btn-name
        />
    </div>
</template>

<script>
export default {
    name: 'PropertyComplexesDetail',
    data () {
        return {
            item: this.$root.app?.components['property-complexes-detail'].item,
            nav: this.$root.app?.components['property-complexes-detail'].nav
        }
    },
    methods: {
        getColor (id) {
            const colors = {
                'public-sale': '#654EA3',
                'trades': '#8AD29D'
            }
            return colors[id] ? colors[id] : '#4DA7B2'
        }
    }
}
</script>

<style scoped lang="scss">
.property-complexes-detail {
    &__tag {
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-white;
        text-align: center;
        padding: 2px 16px;
        border-radius: 20px;
        font-family: $robotoMono;
        margin-bottom: $token-spacers-xs;
    }

    &__table {
        margin-bottom: $token-spacers-xl;
    }

    &__table-item {
        display: flex;
        align-items: center;
        gap: $token-spacers-xl;
        padding-top: $token-spacers-xs;
        padding-bottom: $token-spacers-xs;
        border-bottom: 1px solid $token-colors-gray-20;

        &:first-child {
            padding-top: 0;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
            align-items: flex-start;
            gap: $token-spacers-xs;
        }
    }

    &__table-key {
        width: 220px;
        min-width: 220px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.5;

        @include breakpoint(v-tablet) {
            width: 100%;
        }
    }

    &__table-value {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;
    }
}
</style>
