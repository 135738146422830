<template>
    <form @submit.prevent="submitForm">
        <div class="row">
            <div class="col-default-6 col-tablet-12">
                <z-input
                    v-model="formData.fio"
                    type="text"
                    name="fio"
                    ref="fio"
                    :title="text.name"
                    class="contacts-nca-form__input"></z-input>

                <z-input
                    v-model="formData.company"
                    type="text"
                    ref="company"
                    required
                    name="company"
                    :title="text.company"
                    class="contacts-nca-form__input"></z-input>

                <z-input
                    v-model="formData.email"
                    type="email"
                    ref="email"
                    name="email"
                    :title="text.email"
                    class="contacts-nca-form__input"></z-input>

                <z-input
                    v-model="formData.phone"
                    ref="phone"
                    type="tel"
                    name="phone"
                    :title="text.phone"
                    class="corporate-hotline-form__input"></z-input>

                <p class="contacts-nca-form__text">{{ text.notificationCaptchaEmpty }}</p>

                <div class="form-captcha">
                    <div class="form-captcha__wrapper">
                        <div class="form-captcha__captcha">
                            <img
                                class="form-captcha__img"
                                v-if="isUpdatedCaptcha"
                                :src="'/api/captcha/?' + captchaSalt"
                                width="100"
                                height="50"
                                alt="" />
                            <div
                                v-else
                                class="form-captcha__img"></div>

                            <z-button
                                class="form-captcha__reset-btn"
                                type="button"
                                rounded
                                kind="secondary"
                                @click="updateCaptcha">
                                <z-icon
                                    name="refresh"
                                    width="24"
                                    height="24"></z-icon>
                            </z-button>
                        </div>

                        <z-input
                            v-model="captcha"
                            type="text"
                            name="captcha"
                            ref="captcha"
                            required
                            class="form-captcha__input"></z-input>
                    </div>
                </div>
            </div>

            <div class="col-default-6 col-tablet-12">
                <z-caption
                    class="z-input__title"
                    tag="p"
                    size="s">
                    {{ text.message }}
                </z-caption>
                <z-textarea
                    v-model="formData.msg"
                    required
                    name="msg"
                    ref="msg"
                    class="contacts-nca-form__input"></z-textarea>

                <div class="contacts-nca-form__captcha-container">
                    <z-checkbox
                        name="policy"
                        ref="policy"
                        v-model="isChecked"
                        class="contacts-nca-form__checkbox"
                        required
                        :data="[{ value: '1' }]">
                        <template #text>
                            <z-caption
                                tag="p"
                                weight="400"
                                size="xs">
                                {{ text.policy }}
                            </z-caption>
                        </template>
                    </z-checkbox>
                </div>

                <z-button>
                    {{ text.button }}
                </z-button>
            </div>
        </div>
    </form>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendContactsForm } from '@/api/contacts-form.js'
import { sendCaptcha } from '@/api/captcha.js'
import { text } from './constants'

export default {
    name: 'contacts-nca-form',
    data () {
        return {
            isChecked: [],
            isLoading: false,
            formData: {
                form: 'non-core-assets-contacts',
                pageLink: window.location.href,
                fio: '',
                company: '',
                phone: '',
                email: '',
                msg: '',
                ajax_call: 'Y'
            },
            text,
            captchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCaptcha: true
        }
    },
    methods: {
        validateInputFormat () {
            if (this.formData.phone || this.formData.email) {
                const requiredFields = ['email', 'phone']
                let isValid = true

                requiredFields.forEach((item) => {
                    const field = this.$refs[item]
                    if (field) {
                        field.validate()
                        if (field.error) isValid = false
                    }
                })
                return isValid
            }

            return true
        },

        validateRequiredInputs () {
            const requiredFields = ['company', 'policy', 'captcha', 'msg']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },

        changeTextarea (data) {
            this.formData[data.name] = data.value
        },

        updateCaptcha () {
            this.isUpdatedCaptcha = false
            this.captcha = ''
            this.captchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCaptcha = true
            }, 500)
        },

        async submitForm () {
            if (this.isLoading) {
                return
            }

            const isValidInputFormat = this.validateInputFormat()

            if (!isValidInputFormat) {
                showNotyfications(this.text.notificationValidateError, { type: 'error' })
                return
            }

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }
                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await sendContactsForm(data)

                        if (formResponse.success === true) {
                            showNotyfications(this.text.notificationSuccess, { type: 'success' })

                            this.$emit('closeModal')

                            this.formData.fio = ''
                            this.formData.company = ''
                            this.formData.phone = ''
                            this.formData.email = ''
                            this.captcha = ''
                            this.formData.msg = ''
                            this.isChecked = []
                        } else {
                            showNotyfications(this.text.notificationError, { type: 'error' })
                        }
                    }
                    this.updateCaptcha()
                } catch (error) {
                    console.error(`Error has occurred: ${error}`)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.contacts-nca-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .z-site-selector__active-site {
        display: none;
    }

    &__container {
        padding-right: 0;
        padding-left: 0;
    }

    &__input {
        margin-bottom: $token-spacers-s;
    }

    &__text {
        margin-bottom: 20px;
        font-size: 16px;
    }

    &__warning {
        font-size: 13px;
        color: $token-colors-gray-60;
        line-height: 1.4;

        &-list {
            font-size: 12px;
            margin-bottom: 40px !important;
        }
    }

    &__captcha-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 0;
    }

    &__captcha-img {
        margin: 0 16px 0 0;
        width: 100px;
        height: 50px;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-button {
        margin: 0 8px 0 0;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-input {
        &.is-errored {
            margin-top: 21px;

            .contacts-nca-form__text {
                margin-bottom: 0;

                @media screen and (max-width: 511px) {
                    margin-bottom: 20px;
                }
            }
        }

        .z-input__title {
            margin-bottom: 0 !important;
        }

        @media screen and (max-width: 495px) {
            padding: 0 !important;
        }
    }

    &__checkbox {
        margin-bottom: $token-spacers-s;
        color: $token-colors-gray-50 !important;

        .z-checkbox__text {
            font-size: 13px !important;
        }
    }
}
</style>
