<template>
    <div class="yandex-map" ref="map"></div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'yandex-map',
    mixins: [mixinDevice],
    props: {
        center: Array,
        coords: Array,
        iconTitle: String,
        zoom: {
            type: Number,
            default: 16
        }
    },
    computed: {
        deviceComputed () {
            if (this.isMobile() || this.isTablet()) {
                return 'mobile'
            } else {
                return 'desktop'
            }
        },
        mapLocale () {
            let lang
            this.$root.lang === 'en' ? lang = 'en_Es' : lang = 'ru_Ru'

            return lang
        }
    },
    data () {
        return {
            isApiLoaded: false,
            map: undefined,
            key: '24f1bde5-5b31-4172-8954-346fbb6112a4', // нужно заменить, взял с evraz
            icon: {
                mobile: {
                    iconImageSize: [24, 29],
                    iconImageOffset: [-12, -24],
                    iconContentOffset: [26, 0]
                },
                desktop: {
                    iconImageSize: [39, 48],
                    iconImageOffset: [-24, -48],
                    iconContentOffset: [48, 5]
                }
            }
        }
    },
    methods: {
        async init () {
            const map = this.$refs.map
            await this.addYandexApi()

            if (this.isApiLoaded) {
                /* eslint-disable no-undef */
                await ymaps.ready()
                this.map = new ymaps.Map(map, {
                    zoom: this.zoom,
                    center: this.center,
                    controls: ['zoomControl']
                }, {
                    suppressMapOpenBlock: true
                })

                // Создаём макет содержимого.
                let iconTitleLayout = ymaps.templateLayoutFactory.createClass(
                    '<div class="yandex-map__icon-title">$[properties.iconContent]</div>'
                )

                let point = new ymaps.Placemark(this.coords, {
                    iconContent: this.iconTitle
                }, {
                    // Опции.
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#imageWithContent',
                    // Своё изображение иконки метки. png - потому что IE
                    iconImageHref: '/images/main-page/map-pin.png',
                    // Размеры метки.
                    iconImageSize: this.icon[this.deviceComputed].iconImageSize,
                    // Смещение левого верхнего угла иконки относительно
                    // её "ножки" (точки привязки).
                    iconImageOffset: this.icon[this.deviceComputed].iconImageOffset,
                    // Смещение слоя с содержимым относительно слоя с картинкой.
                    iconContentOffset: this.icon[this.deviceComputed].iconContentOffset,
                    // Макет содержимого.
                    iconContentLayout: iconTitleLayout
                })

                this.map.geoObjects.add(point)
                this.map.behaviors.disable('scrollZoom')
            }
        },
        addYandexApi () {
            return new Promise((resolve, reject) => {
                this.apiUrl = `https://api-maps.yandex.ru/2.1/?apikey=${this.key}&lang=${this.mapLocale}`
                const script = document.createElement('script')
                script.async = true
                script.src = this.apiUrl
                document.head.appendChild(script)
                script.onload = () => {
                    this.isApiLoaded = true
                    resolve()
                }
            })
        }
    },
    mounted () {
        this.init()
    },
    beforeDestroy () {
        this.map.destroy()
        const apiScript = document.querySelector(`script[src="${this.apiUrl}"]`)
        if (apiScript) apiScript.remove()
    }
}
</script>

<style lang="scss">
    .yandex-map {
        height: 100%;
        min-height: 200px;
        width: 100%;

        &__icon-title {
            font-weight: bold;
            font-size: 24px;
            color: $token-colors-black;
            letter-spacing: 0.01em;
            font-family: "Futura PT", sans-serif;

            @include breakpoint(v-tablet) {
                font-size: 16px;
            }
        }
    }
</style>
