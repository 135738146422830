/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-ppt': {
    width: 33,
    height: 22,
    viewBox: '0 0 33 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.838 16.713h1.494v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618s-1.35-3.618-3.762-3.618H.838v12.006zm7.038-8.388c0 1.35-.972 2.286-2.394 2.286h-3.15V6.039h3.15c1.422 0 2.394.936 2.394 2.286zm3.527 8.388h1.494v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618s-1.35-3.618-3.762-3.618h-4.824v12.006zm7.038-8.388c0 1.35-.972 2.286-2.394 2.286h-3.15V6.039h3.15c1.422 0 2.394.936 2.394 2.286zm6.496 8.388h1.494V6.039h3.816V4.707H21.14v1.332h3.798v10.674z" _fill="#0077C8"/>'
  }
})
