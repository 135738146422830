/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'product-lme': {
    width: 34,
    height: 34,
    viewBox: '0 0 34 34',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.313 4.544l1.562.071 1.35.213c16.43 3.916 15.377 26.585-1.35 28.97l-1.562.071-1.49-.07C1.092 31.571-.072 8.787 16.33 4.828l1.491-.214 1.491-.07L8.52 19.241l.071 1.136.143.994c2.953 11.975 19.525 11.197 21.301-.994l.071-1.136-.071-1.065C28.44 5.927 11.699 5.052 8.734 17.041l-.143 1.136-.07 1.065" _fill="#82A2BE"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.313 0h-.994C8.49.601.547 8.38 0 18.248v.994h3.053l.142-1.704c.562-7.426 7.049-13.853 14.485-14.343l1.633-.142V0zm0 10.083l-.994.07c-4.17.364-7.854 3.949-8.094 8.166l-.071.923h9.16v-9.16z" _fill="#EE2722"/>'
  }
})
