<template>
<div class="products__results" v-if="item">
    <template v-if="
        !(item.htmlBlocksOrder && item.htmlBlocksOrder.chemicalComposition) && order === 1 ||
        item.htmlBlocksOrder && item.htmlBlocksOrder.chemicalComposition && item.htmlBlocksOrder.chemicalComposition.col === order
    ">
        <div
            class="products__block products__chemical"
            :class="item.htmlBlocksOrder.chemicalComposition && item.htmlBlocksOrder.chemicalComposition.col ? `products__block--order-${item.htmlBlocksOrder.chemicalComposition.order}` : ''"
            v-if="item.isPowder.value !== 'Y' && item.chemicalComposition"
        >
            <z-caption size="2xl" decor-right tag="h3" class="u-bottom-margin--xs-important">
                <span v-html="text.chemicalComposition"></span>&nbsp;<sup v-if="item.chemicalCompositionNoteString.value !== '' && lang !== 'ru'">1</sup>
            </z-caption>
            <vue-raw :raw="item.chemicalComposition.value" />

            <p class="u-flex text-size-s gray-40-text u-normal u-top-margin--xs" v-if="item.chemicalCompositionNoteString.value !== '' && lang !== 'ru'">
                <span>1&nbsp;</span><span v-html="item.chemicalCompositionNoteString.value"></span>
            </p>
        </div>
    </template>

    <template v-if="
        !(item.htmlBlocksOrder && item.htmlBlocksOrder.package) && order === 1 ||
        item.htmlBlocksOrder && item.htmlBlocksOrder.package && item.htmlBlocksOrder.package.col === order
    ">
        <div
            class="products__block"
            :class="item.htmlBlocksOrder.package && item.htmlBlocksOrder.package.col ? `products__block--order-${item.htmlBlocksOrder.package.order}` : ''"
            v-if="item.package"
        >
            <z-caption size="2xl" decor-right tag="h3" class="u-bottom-margin--2xs-important">
                <span v-html="text.packing"></span>
            </z-caption>
            <vue-raw :raw="item.package.value" />
        </div>
    </template>

    <template
        v-if="!(item.htmlBlocksOrder && item.htmlBlocksOrder.applicationScope) && order === 2 ||
        item.htmlBlocksOrder && item.htmlBlocksOrder.applicationScope && item.htmlBlocksOrder.applicationScope.col === order
    ">
        <div
            class="products__block"
            :class="item.htmlBlocksOrder.applicationScope && item.htmlBlocksOrder.applicationScope.col ? `products__block--order-${item.htmlBlocksOrder.applicationScope.order}` : ''"
            v-if="item.applicationScope && item.applicationScope.value"
        >
            <vue-raw :raw="item.applicationScope.value" />
        </div>
    </template>

    <template
        v-if="!(item.htmlBlocksOrder && item.htmlBlocksOrder.productDescription) && order === 2 ||
        item.htmlBlocksOrder && item.htmlBlocksOrder.productDescription && item.htmlBlocksOrder.productDescription.col === order
    ">
        <div
            class="products__block"
            :class="item.htmlBlocksOrder.productDescription && item.htmlBlocksOrder.productDescription.col ? `products__block--order-${item.htmlBlocksOrder.productDescription.order}` : ''"
            v-if="item.productDescription && item.productDescription.value
        ">
            <z-caption size="2xl" decor-right tag="h3" class="u-bottom-margin--xs-important">
                <span v-html="text.description"></span>
            </z-caption>
            <vue-raw :raw="item.productDescription.value" />
        </div>
    </template>

    <template
        v-if="!(item.htmlBlocksOrder && item.htmlBlocksOrder.carbonFootprint) && order === 2 ||
        item.htmlBlocksOrder && item.htmlBlocksOrder.carbonFootprint && item.htmlBlocksOrder.carbonFootprint.col === order
    ">
        <div
            class="products__block"
            :class="item.htmlBlocksOrder.carbonFootprint && item.htmlBlocksOrder.carbonFootprint.col ? `products__block--order-${item.htmlBlocksOrder.carbonFootprint.order}` : ''"
            v-if="item.carbonFootprint && item.carbonFootprint.value"
        >
            <z-caption size="2xl" decor-right tag="h3" class="u-bottom-margin--xs-important">
                <span v-html="text.carbonFootprint"></span>
            </z-caption>
            <div class="carbon-footprint u-bottom-margin--s">
                <span v-html="item.carbonFootprint.value"></span>
                <span v-html="text.metalInProduct"></span>
            </div>
            <template v-if="lang === 'ru'">
                <z-list>
                    <z-list-item>Принцип CRADLE-TO-GATE </z-list-item>
                    <z-list-item>Стандарты: ISO 14040:2006, ISO 14044:2006, ISO 14067:2018, ISO 14064-1:2018, A Guide to Calculate Nickel’s Carbon Footprint (LME passport guidance – Nickel Institute), GHG Protocol</z-list-item>
                    <z-list-item>Отчетный год: 2023</z-list-item>
                    <z-list-item>Независимая верификация: TUV Austria</z-list-item>
                    <z-list-item>Углеродный след учитывает прогнозные выбросы, связанные с&nbsp;запуском Серной программы в&nbsp;Заполярном филиале Компании, объем которых валидирован TUV Austria</z-list-item>
                </z-list>
            </template>
            <template v-else>
                <z-list>
                    <z-list-item>CRADLE-TO-GATE principle</z-list-item>
                    <z-list-item>Standards: ISO 14040:2006, ISO 14044:2006, ISO 14067:2018, ISO 14064-1:2018, A Guide to Calculate Nickel’s Carbon Footprint (LME passport guidance – Nickel Institute), GHG Protocol</z-list-item>
                    <z-list-item>Reference year: 2023</z-list-item>
                    <z-list-item>Independently audited by TUV Austria</z-list-item>
                    <z-list-item>Product Carbon footprint includes the modeled GHG emissions from the Sulphur Programme 2.0 aimed to recover of Sulphur dioxide at Polar Division. The amount of these emissions is independently validated by TUV Austria</z-list-item>
                </z-list>
            </template>
        </div>
    </template>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'products-result',
    props: {
        item: Object,
        order: {
            type: Number,
            default: 1
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            text: {
                packing: localize({
                    ru: 'Упаковка',
                    en: 'Packing'
                }),
                description: localize({
                    ru: 'Описание продукта',
                    en: 'Product description'
                }),
                carbonFootprint: localize({
                    ru: 'Углеродный след',
                    en: 'CO<sub>2</sub> Footprint'
                }),
                chemicalComposition: localize({
                    en: 'Chemical Composition, %',
                    ru: 'Химический состав, %'
                }),
                metalInProduct: localize({
                    en: '&nbsp;of&nbsp;metal in&nbsp;the product',
                    ru: '&nbsp;металла в&nbsp;продукте'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.products__chemical {
    .table-head {
        font-family: 'Roboto Mono', Helvetica, sans-serif;
        font-size: 14px;
        color: #626262;
        display: flex;
        justify-content: flex-end;
    }
}

.products__block {
    sup,
    sub {
        font-weight: 600;
    }
}
</style>
