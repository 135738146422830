<template>
<div
    :class="[
        'calendar-item',
        `calendar-item--theme-${colorTheme[data.type.id]}`
    ]"
    v-if="data"
>
    <header class="calendar-item__header">
        <span class="calendar-item__date" v-html="date"></span>
        <span
            v-if="data['show-time']"
            class="calendar-item__time"
        >
            {{ data.start | momentFilter('HH:mm') }} - {{ data.end | momentFilter('HH:mm') }} ({{ text.timezone }})
        </span>
        <span class="calendar-item__tag" v-if="data.type.value" v-html="data.type.value"></span>
    </header>
    <div class="calendar-item__content">
        <z-caption
            size="l"
        >
            <span v-html="data.name"></span>
        </z-caption>
        <p class="calendar-item__location" v-html="data.location"></p>
    </div>
    <footer class="calendar-item__footer">
        <div class="calendar-item__footer-wrapper">
            <div class="calendar-item__materials">
                <z-link v-if="data.pressRelease" size="m" target="_blank" :href="data.pressRelease">
                    <template #left>
                        <z-icon
                            name="file/empty"
                            width="24"
                            height="24"
                        />
                    </template>
                    <span class="u-bold" v-html="text.pressRelease"></span>
                </z-link>
                <z-link v-if="data.presentation" size="m" :href="data.presentation" target="_blank">
                    <template #left>
                        <z-icon
                            name="monitor"
                            width="24"
                            height="24"
                        />
                    </template>
                    <span class="u-bold" v-html="text.presentation"></span>
                </z-link>
                <z-link v-if="data.video" size="m" target="_blank" :href="data.video">
                    <template #left>
                        <z-icon
                            name="video"
                            width="24"
                            height="24"
                        />
                    </template>
                    <span class="u-bold" v-html="text.video"></span>
                </z-link>
            </div>
            <div v-if="typeOfCards === 'future'" class="calendar-item__actions">
                <remind :data="data.remind">
                    <z-link size="m" href="javascript: void(0);">
                        <template #left>
                            <z-icon
                                name="calendar"
                                width="26"
                                height="26"
                                color="#0077c8"
                            />
                        </template>
                        <span class="u-bold" v-html="text.add"></span>
                    </z-link>
                </remind>
                <calendar-share />
            </div>
        </div>

        <z-button
            class="u-top-margin--s"
            kind="secondary"
            size="s"
            v-if="data.link"
            :href="data.link"
            tag="a"
        >
            <span v-html="text.more"></span>
            <template v-slot:right>
                <z-icon
                    name="arrow-long"
                    width="14"
                    height="14"
                    dir="right"
                />
            </template>
        </z-button>
    </footer>
</div>
</template>

<script>
import { calendarFormatDate } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import CalendarShare from './CalendarShare.vue'

export default {
    name: 'calendar-item',
    mixins: [calendarFormatDate],
    components: {
        CalendarShare
    },
    props: {
        data: Object,
        typeOfCards: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            colorTheme: {
                '73': 'ir',
                '74': 'bussiness',
                '75': 'production',
                '77': 'corp',
                '76': 'fin',
                '147': 'esg'
            },
            text: {
                presentation: localize({
                    ru: 'Презентация',
                    en: 'Presentation'
                }),
                pressRelease: localize({
                    ru: 'Пресс-релиз',
                    en: 'Press-release'
                }),
                video: localize({
                    ru: 'Видео',
                    en: 'Video'
                }),
                add: localize({
                    ru: 'Добавить в календарь',
                    en: 'Add to calendar'
                }),
                more: localize({
                    ru: 'Узнать больше',
                    en: 'Learn more'
                }),
                timezone: localize({
                    ru: 'МСК',
                    en: 'MSK'
                })
            }
        }
    },
    computed: {
        date () {
            const dateObj = {
                start: this.data.start,
                end: this.data.end ? this.data.end : this.data.start
            }

            return this.formatedDate(dateObj, false).toLowerCase()
        },
        classObject () {
            return this.data.types.map(item => `calendar-item--${this.colorTheme[item.id]}`)
        }
    }
}
</script>

<style lang="scss">
$ir: #4DA7B2;
$irLight: rgba($ir, 0.2);
$bussiness: #9D81EC;
$bussinessLight: #F4F0FE;
$production:#0077C8;
$productionLight: rgba($production, 0.1);
$corp: #D25C5C;
$corpLight: rgba($corp, 0.1);
$fin: #E18836;
$finLight: rgba($fin, 0.1);
$esg: #7BC18E;
$esgLight: #E8F6EB;

.calendar-item {
    @include padding-level(left, S);
    @include padding-level(right, S);
    @include padding-level(top, XS);
    @include padding-level(bottom, XS);
    background: $token-colors-gray-10;
    position: relative;
    margin-left: 8px;
    border-radius: 0px 10px 10px 0px;

    &:before {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        top: 0;
        right: calc(100% - 3px);
        border-radius: 10px 0px 0px 10px;
        display: block;
        background: $token-colors-blue-10;
    }

    &__location {
        color: $token-colors-navy-blue;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        @include margin-level(top, 3XS);
    }

    &__time,
    &__date {
        color: $token-colors-navy-blue;
        font-size: 13px;
        font-weight: 600;

        @include breakpoint (mobile) {
            order: 2;
        }
    }

    &__header {
        display: flex;
        @include margin-level(left, XS, true);
        @include margin-level(right, XS, true);
        @include margin-level(top, 2XS, true);
        @include margin-level(bottom, 2XS);

        @include breakpoint (mobile) {
            flex-direction: column;
        }

        span {
            @include margin-level(top, 2XS);
            @include margin-level(left, XS);
            @include margin-level(right, XS);
        }
    }

    &__tag {
        margin-left: auto !important;
        color: $token-colors-gray-30;
        padding-left: 14px;
        position: relative;
        font-weight: 600;
        font-size: 14px;

        @include breakpoint (mobile) {
            order: 1;
            @include margin-level(left, XS, false, true);
        }

        &:before {
            content: '';
            display: block;
            border-radius: 50%;
            background: $token-colors-gray-30;
            width: 6px;
            height: 6px;
            position: absolute;
            top: 0.5em;
            left: 0;
        }
    }

    &__footer-wrapper {
        @include margin-level(top, 3XS);
        display: flex;
        flex-wrap: wrap;
    }

    &__materials {
        @include margin-level(left, XS, true);
        margin-right: auto;

        .z-link {
            @include margin-level(top, XS);
            @include margin-level(left, XS);
            @include margin-level(right, XS);
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;

        .remind {
            @include margin-level(right, M);
        }

        .remind,
        .calendar-share {
            @include margin-level(top, XS);
        }
    }
}

.calendar-item--theme {
    &-ir {
        background: $irLight;

        &:before {
            background: $ir;
        }

        .calendar-item__tag {
            &:before {
                background: $ir;
            }
        }
    }

    &-bussiness {
        background: $bussinessLight;

        &:before {
            background: $bussiness;
        }

        .calendar-item__tag {
            &:before {
                background: $bussiness;
            }
        }
    }

    &-production {
        background: $productionLight;

        &:before {
            background: $production;
        }

        .calendar-item__tag {
            &:before {
                background: $production;
            }
        }
    }

    &-corp {
        background: $corpLight;

        &:before {
            background: $corp;
        }

        .calendar-item__tag {
            &:before {
                background: $corp;
            }
        }
    }

    &-fin {
        background: $finLight;

        &:before {
            background: $fin;
        }

        .calendar-item__tag {
            &:before {
                background: $fin;
            }
        }
    }

    &-esg {
        background: $esgLight;

        &:before {
            background: $esg;
        }

        .calendar-item__tag {
            &:before {
                background: $esg;
            }
        }
    }
}
</style>
