<template>
    <div class="cmd-future-event" v-if="data">
        <section v-if="data.agenda">
            <z-caption
                tag="h4"
                size="xl"
                decor-left
                icon="check-circle"
                v-if="data.agenda.title"
            >
                <span v-html="data.agenda.title"></span>
            </z-caption>
            <z-list class="cmd-future-event__content cmd-future-event__content--list">
                <z-list-item
                    v-for="(item, index) in data.agenda.items"
                    :key="index"
                >
                    <span v-html="item"></span>
                </z-list-item>
            </z-list>
        </section>
        <section v-if="data['management-presentation']" class="u-top-margin--s">
            <z-caption
                tag="h3"
                size="xl"
                decor-left
                icon="time"
                class="u-bototm-margin--2xs"
            >
                <span v-html="text['management-presentation']"></span>
            </z-caption>
            <div class="cmd-future-event__content" v-html="data['management-presentation']"></div>
        </section>
        <section v-if="data['day-format']" class="u-top-margin--s">
            <z-caption
                tag="h3"
                size="xl"
                decor-left
                icon="monitor"
                class="u-bototm-margin--2xs"
            >
                <span v-html="text.format"></span>
            </z-caption>
            <div class="cmd-future-event__content" v-html="data['day-format']"></div>
        </section>
        <section v-if="data.location && data.location.full" class="u-top-margin--s">
            <z-caption
                tag="h3"
                size="xl"
                decor-left
                icon="pin"
                class="u-bototm-margin--2xs"
            >
                <span v-html="text.place"></span>
            </z-caption>
            <div class="cmd-future-event__content" v-html="data.location.full"></div>
        </section>
        <section v-if="data.broadcast && data.broadcast.detail" class="u-top-margin--s">
            <z-caption
                tag="h3"
                size="xl"
                decor-left
                icon="eye"
                class="u-bototm-margin--2xs"
            >
                <span v-html="text.broadcast"></span>
            </z-caption>
            <div class="cmd-future-event__content" v-html="data.broadcast.detail"></div>
        </section>

        <section
            class="cmd-future-event__contacts u-block--fluid gray-10-bg"
            v-if="data.contacts && data.contacts.length"
        >
            <z-caption
                tag="h2"
                size="2xl"
                class="u-bototm-margin--xs"
            >
                <span v-html="text.contacts"></span>
            </z-caption>
            <div class="row">
                <template v-for="(item, index) in data.contacts">
                    <div
                        class="col-default-4 col-desktop-6 col-v-tablet-12"
                        :key="index"
                        v-if="item.phone"
                    >
                        <p class="u-bottom-margin--3xs" v-html="item.name"></p>
                        <z-link :href="`tel:${item.phone}`">
                            <template v-slot:left>
                                <div class="cmd-future-event__contacts-icon">
                                    <z-icon
                                        name="phone"
                                        width="32"
                                        height="32"
                                    />
                                </div>
                            </template>
                            <span v-html="item.phone"></span>
                        </z-link>
                    </div>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'cmd-future-event',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                'management-presentation': localize({
                    ru: 'Презентация менеджмента, вопросы и ответы',
                    en: 'Management presentation, Q&A session'
                }),
                place: localize({
                    ru: 'Место',
                    en: 'Venue'
                }),
                broadcast: localize({
                    ru: 'Трансляция',
                    en: 'Webcast'
                }),
                contacts: localize({
                    ru: 'Контакты',
                    en: 'Contacts'
                }),
                format: localize({
                    ru: 'Формат мероприятия',
                    en: 'Event format'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.cmd-future-event {
    &__content {
        @include margin-level(left, L);

        @include breakpoint (mobile) {
            margin-left: 0;
        }

        &--list {
            @include margin-level(left, S);
        }
    }

    &__contacts {
        @include margin-level(top, M);
        @include padding-level(top, M);
        @include padding-level(bottom, M);
    }

    &__contacts-icon {
        border: 1px solid $token-colors-gray-20;
        border-radius: 50%;
        overflow: hidden;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include margin-level(right, 2XS);
    }
}
</style>
