/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.01 4.01A1.4 1.4 0 014 3.6h4.679L10.5 6.333A.6.6 0 0011 6.6h9A1.4 1.4 0 0121.4 8v11a1.4 1.4 0 01-1.4 1.4H4A1.4 1.4 0 012.6 19V5a1.4 1.4 0 01.41-.99zM4 2.4A2.6 2.6 0 001.4 5v14A2.6 2.6 0 004 21.6h16a2.6 2.6 0 002.6-2.6V8A2.6 2.6 0 0020 5.4H11.32L9.499 2.668A.6.6 0 009 2.4H4z" _fill="#0077C8"/>'
  }
})
