<template>
    <section class="quote-block" :class="`quote-block--size-${size}`">
        <p class="quote-block__header">
            <span class="quote-block__quotes"/>
            <span v-html="quote"/>
        </p>
        <div class="quote-block__content">
            <img v-if="imgSrc && showImg" class="quote-block__avatar" :src="imgSrc" alt="Фотография."/>
            <div class="quote-block__wrapper">
                <p class="quote-block__title" v-html="title"/>
                <p class="quote-block__about-text" v-html="aboutText"/>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'quote-block',
    props: {
        quote: {
            type: String,
            required: true
        },
        title: String,
        aboutText: String,
        imgSrc: String,
        size: {
            type: String,
            validator: prop => ['default', 'small'].includes(prop),
            default: 'default'
        },
        showImg: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.quote-block {
    $parent: &;
    padding-left: 145px;
    padding-right: 136px;

    @include breakpoint(laptop) {
        padding-left: 98px;
        padding-right: 98px;
    }

    @include breakpoint(tablet) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @include breakpoint(mobile) {
        padding-left: 16px;
        padding-right: 16px;
    }

    &__header {
        text-indent: 120px;
        background: linear-gradient(123.73deg, #0077C8 20.82%, rgba(0, 119, 200, 0) 99.26%), #004C97;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 600;
        font-size: 36px;
        line-height: 1.2;
        @include margin-level(bottom, S, false, true);

        @include breakpoint(laptop) {
            font-weight: 700;
            font-size: 32px;
        }

        @include breakpoint(tablet) {
            text-indent: 35px;
            font-weight: 600;
            font-size: 24px;
        }

        @include breakpoint(mobile) {
            text-indent: 19px;
            font-size: 20px;
        }
    }

    &__quotes {
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='34' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.962 24.304V12.635S20.39 4.074 28.154 0l2.932 3.107s-4.517 3.038-4.754 9.528h7.527v11.669H18.962zm-3.644 0V12.635H7.869c.238-6.49 4.754-9.528 4.754-9.528L9.692 0C1.846 4.074.5 12.635.5 12.635v11.669h14.818z' fill='%23004C97'/%3E%3Cpath d='M18.962 24.304V12.635S20.39 4.074 28.154 0l2.932 3.107s-4.517 3.038-4.754 9.528h7.527v11.669H18.962zm-3.644 0V12.635H7.869c.238-6.49 4.754-9.528 4.754-9.528L9.692 0C1.846 4.074.5 12.635.5 12.635v11.669h14.818z' fill='url(%23a)'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='22.274' y1='24.304' x2='-1.442' y2='2.566' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230077C8'/%3E%3Cstop offset='1' stop-color='%230077C8' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
        width: 34px;
        height: 25px;

        @include breakpoint(tablet) {
            width: 27px;
            height: 20px;
        }
    }

    &__content {
        display: flex;
        padding-left: $token-spacers-l;

        @include breakpoint(laptop) {
            padding-left: 39px;
            @include margin-level(bottom, S);
        }

        @include breakpoint(tablet) {
            padding-left: 0;
            @include margin-level(bottom, 2XS);
        }
    }

    &__avatar {
        width: 138px;
        height: 138px;
        border-radius: 50%;
        @include margin-level(right, S);

        @include breakpoint(laptop) {
            width: 114px;
            height: 114px;
        }

        @include breakpoint(tablet) {
            width: 80px;
            height: 80px;
            @include margin-level(right, XS);
        }

        @include breakpoint(mobile) {
            display: none;
        }
    }

    &__title {
        display: inline-block;
        font-weight: 600;
        font-size: 30px;
        line-height: 1.2;
        color: $token-colors-gray-35;
        position: relative;
        @include margin-level(bottom, 2XS, false, true);

        @include breakpoint(laptop) {
            font-size: 24px;
        }

        @include breakpoint(v-tablet) {
            margin-bottom: 0;
            font-size: 16px;
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 100px;
            height: 1px;
            background-color: $token-colors-gray-35;
            transform: translateX(110%);

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__about-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
        color: $token-colors-gray-35;

        @include breakpoint(laptop) {
            font-size: 18px;
        }

        @include breakpoint(v-tablet) {
            font-size: 15px;
        }
    }

    &--size-small {
        #{$parent}__header {
            font-weight: 600;
            font-size: 30px;

            @include breakpoint(tablet) {
                font-size: 28px;
            }

            @include breakpoint(v-tablet) {
                font-size: 24px;
            }

            @include breakpoint(mobile) {
                font-size: 20px;
            }
        }

        #{$parent}__quotes {
            width: 27px;
            height: 20px;
        }
    }
}
</style>
