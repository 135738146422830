/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'worker': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M57 25h-2c0-4.428-2.583-8.224-6.332-9.972A2.008 2.008 0 0047 17v8h-2v-8c0-1.084.437-2.071 1.144-2.793A11.164 11.164 0 0044 14c-.734 0-1.45.071-2.144.207A3.986 3.986 0 0143 17v8h-2v-8c0-.987-.725-1.813-1.668-1.972A10.968 10.968 0 0033 25h-2c0-7.2 5.8-13 13-13s13 5.8 13 13zm0 0h2c0 3.9-7.7 6-15 6s-15-2.1-15-6h2c0 1.6 5.1 4 13 4s13-2.4 13-4zM39 45.738c1.385.789 3.041 1.262 5 1.262s3.615-.473 5-1.262v2.78L43.9 54.5 39 48.752v-3.014zM58.7 54.9a51.35 51.35 0 01-2.7-.94V76h-2V53.135c-1.741-.779-3.27-1.649-4.165-2.542L44.6 56.7c0 .2-.3.3-.6.3s-.6-.1-.8-.3l-5.098-5.947c-.902.873-2.4 1.72-4.102 2.482V76h-2V54.059c-.903.344-1.82.66-2.7.94-.434.143-.868.289-1.3.44V76h-2V56.18c-4.85 1.917-9 4.565-9 8.82v11h-2V65c0-6.5 6.9-9.7 13.7-11.9 4.1-1.4 8-3.3 8.3-4.1v-4.781c-2.698-2.65-3.877-6.687-4-9.819l-.7-.7c-.2-.2-.3-.4-.3-.7v-2h2v1.6l.7.7c.2.2.3.4.3.7 0 4.3 2.4 11 9 11s9-6.7 9-11c0-.3.1-.5.3-.7l.7-.7V31h2v2c0 .3-.1.5-.3.7l-.7.7c-.123 3.132-1.302 7.17-4 9.819V49c.3.7 4.2 2.7 8.3 4.1C66.1 55.3 73 58.5 73 65v11h-2V65c0-4.254-4.15-6.902-9-8.875V76h-2V55.359c-.432-.157-.866-.31-1.3-.459zM36 62v-2h16v2H36zm0 4v2h16v-2H36zm0 8v-2h16v2H36z" _fill="#0077C8"/>'
  }
})
