/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-brand-safety': {
    width: 43,
    height: 49,
    viewBox: '0 0 43 49',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.5.003v48.186C9.053 44.389 0 32.815 0 19.125V6.261L21.5.003z" _fill="#004C97"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M21.5.003v48.186C9.053 44.389 0 32.815 0 19.125V6.261L21.5.003z" _fill="url(#paint0_linear_10270_2423)"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M21.5.003v48.186C33.947 44.389 43 32.815 43 19.125V6.261L21.5.003z" _fill="#0077C8"/><defs><linearGradient id="paint0_linear_10270_2423" x1="7.467" y1=".003" x2="33.298" y2="7.699" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
