/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/mp3': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_3830_22448)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" d="M68.158 82V69.994h-2.142l-3.744 9.036-3.726-9.036h-2.142V82h1.494v-9.882L61.966 82h.612l4.086-9.882V82h1.494zm6.095 0v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618s-1.35-3.618-3.762-3.618h-4.824V82h1.494zm3.15-6.102h-3.15v-4.572h3.15c1.422 0 2.394.936 2.394 2.286s-.972 2.286-2.394 2.286zm11.104 6.318c2.646 0 4.248-1.458 4.248-3.456 0-1.926-1.674-2.862-2.772-2.952 1.134-.198 2.592-1.062 2.592-2.79 0-2.034-1.764-3.204-4.068-3.204-1.854 0-3.24.81-4.104 1.854l.828.936c.882-.954 1.854-1.458 3.168-1.458 1.458 0 2.682.72 2.682 2.052 0 1.386-1.278 1.98-2.79 1.98-.486 0-1.044 0-1.242-.018v1.368c.18-.018.738-.018 1.242-.018 1.764 0 2.988.63 2.988 2.124 0 1.386-1.062 2.25-2.808 2.25-1.422 0-2.646-.648-3.348-1.584l-.882.936c.738 1.08 2.232 1.98 4.266 1.98z" _fill="#0077C8"/><defs><filter id="filter0_dd_3830_22448" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3830_22448"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_3830_22448" result="effect2_dropShadow_3830_22448"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_3830_22448" result="shape"/></filter></defs>'
  }
})
