import tippy from 'tippy.js'
import 'tippy.js/themes/light.css'

export function initTooltips () {
    const tooltips = Array.from(document.querySelectorAll('.js-tooltip'))
    tooltips.forEach(item => {
        const tooltipText = item.dataset['content']
        if (tooltipText) {
            tippy(item, {
                theme: 'light',
                trigger: 'mouseenter focus',
                placement: 'bottom',
                content: tooltipText,
                allowHTML: true
            })
        }
    })
}
