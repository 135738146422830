<template>
    <div
        class="timer"
        v-if="getIsTimerActive"
        ref="timer">
        <p
            class="timer__text"
            v-html="timerText"></p>
        <div
            class="timer__time"
            data-timer></div>
    </div>
</template>

<script>
import Timer from './timer'

export default {
    name: 'Timer',
    props: {
        timerText: {
            type: String,
            required: true
        },
        dateStartTimestamp: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            timer: null,
            lang: this.$root.lang
        }
    },
    created () {
        this.dateStart = new Date(this.dateStartTimestamp.replace(/-/g, '/'))
        this.dateCurrent = new Date()
    },
    mounted () {
        this.initTimer()
    },
    methods: {
        initTimer () {
            this.timer = new Timer(this.$refs.timer, this.dateStart, this.lang)
            if (this.getIsTimerActive) {
                this.timer.startTicking()
            } else {
                this.$emit('timerEnded')
            }
            this.timer.onTimeIsOver(() => {
                this.$emit('timerEnded')
            })
        }
    },
    computed: {
        getIsTimerActive () {
            return this.dateCurrent.getTime() < this.dateStart.getTime()
        }
    }
}
</script>

<style scoped lang="scss">
.timer {
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(v-tablet) {
        align-items: flex-start;
    }

    &__text {
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        line-height: 1.3;
        margin-bottom: 2px;

        @include breakpoint(tablet) {
            font-size: 9px;
        }
    }

    &__time {
        @include typo-level(XL);
        font-weight: 700;
        line-height: 1.3;
        letter-spacing: 0.02em;

        @include breakpoint(tablet) {
            font-size: 18px;
        }
    }
}
</style>
