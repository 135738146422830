<template>
    <div>
        <div class="bods" v-if="list.length">
            <div class="bods__list">
                <div class="bods__item" v-for="(person, index) in list" :key="index">
                    <component
                        :is="person.link ? 'a' : 'div'"
                        :href="person.link"
                        :title="person.name"
                        class="bod"
                    >
                        <div class="bod__image" :style="`background-image: url(${person.image})`"></div>
                        <div class="bod__content">
                            <z-caption
                                tag="h3"
                                size="m"
                                class="u-collapse--all"
                            >
                                {{ person.name }}
                            </z-caption>
                            <z-caption
                                v-if="person.position"
                                tag="p"
                                size="s"
                                class="u-top-margin--3xs gray-35-text"
                            >
                                <z-link
                                    v-if="person['position_file']"
                                    :href="person['position_file']"
                                    :title="person.position"
                                    target="_blank"
                                >
                                    <span v-html="person.position" />
                                </z-link>
                                <span v-else v-html="person.position" />
                            </z-caption>
                        </div>
                    </component>
                </div>
            </div>
        </div>

        <committees class="u-top-margin--2xl" :data="committees"/>
    </div>
</template>

<script>
import Committees from './components/Committees.vue'

export default {
    name: 'board-of-directors-list',
    props: {
        source: {
            type: String,
            default: 'board-of-directors'
        }
    },
    components: {
        Committees
    },
    data () {
        return {
            list: [],
            committees: []
        }
    },
    created () {
        if (this.$root.app.components[this.source]) this.list = this.$root.app.components[this.source].items || []
        if (this.$root.app.components[this.source]) this.committees = this.$root.app.components[this.source].committees || []
    }
}
</script>

<style lang="scss">
.bods {
    &__list {
        display: flex;
        flex-wrap: wrap;
        @include margin-level(top, XS, true);
        @include margin-level(bottom, XS, true);
        @include margin-level(left, XS, true);
        @include margin-level(right, XS, true);
    }

    &__item {
        flex: 0 1 20%;
        max-width: 20%;
        @include padding-level(top, XS);
        @include padding-level(bottom, XS);
        @include padding-level(left, XS);
        @include padding-level(right, XS);

        @include breakpoint(laptop) {
            flex: 0 1 20%;
            max-width: 20%;
        }

        @include breakpoint(tablet) {
            flex-basis: 33.333%;
            max-width: 33.333%;
        }

        @include breakpoint(v-tablet) {
            flex-basis: 50%;
            max-width: 50%;
        }

        @include breakpoint(mobile) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

.bod {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    transition: box-shadow 0.3s ease;

    &__image {
        background-color: #eee;
        padding-top: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @include padding-level(top, 2XS);
        @include padding-level(bottom, 2XS);
        @include padding-level(left, 2XS);
        @include padding-level(right, 2XS);
    }
}

a.bod {
    &:hover {
        color: #000;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    }
}
</style>
