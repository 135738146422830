<template>
    <form @submit.prevent="submitForm">
        <div class="row">
            <div class="col-default-6 col-tablet-12">
                <z-input
                    v-model="formData.commodityFormName"
                    type="text"
                    name="commodityFormName"
                    ref="name"
                    required
                    :title="text.commodityFormName"
                    class="commodity-research-form__input"
                ></z-input>

                <z-input
                    v-model="formData.commodityFormCompany"
                    type="text"
                    name="commodityFormCompany"
                    :title="text.commodityFormCompany"
                    class="commodity-research-form__input"
                ></z-input>

                <z-input
                    v-model="formData.commodityFormEmail"
                    type="email"
                    ref="email"
                    required
                    name="commodityFormEmail"
                    :title="text.commodityFormEmail"
                    class="commodity-research-form__input"
                ></z-input>

                <p class="commodity-research-form__text">{{ text.notificationCaptchaEmpty }}</p>

                <div class="form-captcha">
                    <div class="form-captcha__wrapper">
                        <div class="form-captcha__captcha">
                            <img
                                class="form-captcha__img"
                                v-if="isUpdatedCapcha"
                                :src="'/api/captcha/?' + capchaSalt"
                                width="100"
                                height="50"
                                alt=""
                            />
                            <div v-else class="form-captcha__img"></div>

                            <z-button
                                class="form-captcha__reset-btn"
                                type="button"
                                rounded
                                kind="secondary"
                                @click="updateCapcha"
                            >
                                <z-icon name="refresh" width="24" height="24"></z-icon>
                            </z-button>
                        </div>
                        <z-input
                            v-model="captcha"
                            type="text"
                            name="captcha"
                            ref="captcha"
                            required
                            class="form-captcha__input"
                        ></z-input>
                    </div>
                </div>
            </div>

            <div class="col-default-6 col-tablet-12">
                <z-checkbox
                    name="policy"
                    ref="policy"
                    v-model="isChecked"
                    class="commodity-research-form__checkbox"
                    required
                    :data="[{ value: '1' }]"
                >
                    <template #text>
                        <z-caption tag="p" weight="400" size="xs">{{ text.policy }} </z-caption>
                    </template>
                </z-checkbox>

                <p class="commodity-research-form__warning">
                    {{ text.warning.start }}
                    <z-link href="/terms/">{{ text.warning.link }}</z-link>
                    {{ text.warning.end }}
                </p>

                <z-list class="commodity-research-form__warning-list">
                    <z-list-item>
                        {{ text.warning.agrees.presonalData.start }}
                        <z-link href="/terms/">{{ text.warning.link }}</z-link>
                        {{ text.warning.agrees.presonalData.end }}
                    </z-list-item>
                    <z-list-item>
                        {{ text.warning.agrees.transfer }}
                    </z-list-item>
                </z-list>

                <z-button>
                    {{ text.button }}
                </z-button>
            </div>
        </div>
    </form>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendCommodityResearchForm } from '@/api/commodity-research-form.js'
import { sendCaptcha } from '@/api/captcha.js'
import { text } from './constans'
import moment from 'moment'

export default {
    name: 'commodity-research-form',
    props: {
        fileId: {
            type: String,
            default: ''
        },
        fileLink: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            isChecked: [],
            isLoading: false,
            formData: {
                form: 'commodity-research',
                commodityFormName: '',
                commodityFormCompany: '',
                commodityFormEmail: '',
                phrase: this.captcha,
                ajax_call: 'Y',
                commodityFormFileID: this.fileId,
                commodityFormFileHREF: this.fileLink,
                date_download: '01.01.2022, 00:00:00'
            },
            text,
            capchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCapcha: true
        }
    },
    methods: {
        validateInputFormat () {
            if (this.formData.commodityFormEmail) {
                let isValid = true
                const field = this.$refs.email
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
                return isValid
            }

            return true
        },

        validateRequiredInputs () {
            const requiredFields = ['name', 'policy', 'captcha', 'email']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },

        changeTextarea (data) {
            this.formData[data.name] = data.value
        },

        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },

        async submitForm () {
            if (this.isLoading) {
                return
            }

            const isValidInputFormat = this.validateInputFormat()

            if (!isValidInputFormat) {
                showNotyfications(this.text.notificationValidateError, { type: 'error' })
                return
            }

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    this.formData.date_download = moment().format('DD.MM.YYYY hh:mm:ss')

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }
                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await sendCommodityResearchForm(data)

                        if (formResponse.success === true) {
                            localStorage.setItem('isFormSubmited', 'true')

                            this.$emit('openLink')

                            showNotyfications(this.text.notificationSuccess, { type: 'success' })

                            this.formData.commodityFormName = ''
                            this.formData.commodityFormSurname = ''
                            this.formData.commodityFormCompany = ''
                            this.formData.commodityFormCountry = ''
                            this.captcha = ''
                            this.formData.commodityFormEmail = ''
                            this.formData.commodityFormPosition = ''
                            this.formData.commodityIDMessage = ''
                            this.isChecked = []
                        } else {
                            showNotyfications(this.text.notificationError, { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.commodity-research-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .z-site-selector__active-site {
        display: none;
    }

    &__container {
        padding-right: 0;
        padding-left: 0;
    }

    &__input {
        margin-bottom: $token-spacers-s;

        &--textarea {
            margin-top: 44px;

            @media screen and (max-width: 1358px) {
                margin-top: 0;
            }
        }
    }

    &__text {
        margin-bottom: 20px;
        font-size: 16px;
    }

    &__warning {
        font-size: 13px;
        color: $token-colors-gray-60;
        line-height: 1.4;

        &-list {
            font-size: 12px;
            margin-bottom: 40px !important;
        }
    }

    &__captcha-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 0;
    }

    &__captcha-img {
        margin: 0 16px 0 0;
        width: 100px;
        height: 50px;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-button {
        margin: 0 8px 0 0;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-input {
        &.is-errored {
            margin-top: 21px;

            .commodity-research-form__text {
                margin-bottom: 0;

                @media screen and (max-width: 511px) {
                    margin-bottom: 20px;
                }
            }
        }

        .z-input__title {
            margin-bottom: 0 !important;
        }

        @media screen and (max-width: 495px) {
            padding: 0 !important;
        }
    }

    &__checkbox {
        margin-bottom: $token-spacers-s;
        color: $token-colors-gray-50 !important;

        .z-checkbox__text {
            font-size: 13px !important;
        }
    }
}
</style>
