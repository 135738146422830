/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/tif': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM18.375 30h1.404v-6.768h2.424v-1.236h-6.252v1.236h2.424V30zm5.018 0h1.404v-8.004h-1.404V30zm3.105 0h1.404v-3.456h3.996v-1.236h-3.995v-2.076h4.08v-1.236h-5.485V30z" _fill="#737B91"/>'
  }
})
