<template>
    <div class="presentation container">
        <div class="presentation__header">
            <z-caption
                size="2xl"
                decor-left
                decor-right
                class="presentation__title">
                <span>Основы противодействия коррупции для Контрагентов</span>
            </z-caption>
            <div class="presentation__logo-container">
                <logo class="presentation__logo" />
                <z-caption
                    decor-left
                    decor-right
                    class="presentation__logo-mobile">
                    <logo />
                </z-caption>
            </div>
        </div>
        <transition-fade>
            <welcome-screen
                key="welcome-screen"
                v-if="!isPresentationStarted"
                @startPresentation="handlePresentationStart" />
            <slides-screen
                v-else-if="isPresentationStarted && !isPresentationViewed"
                :current-slide-index="currentSlideIndex"
                :slides-count="slidesCount">
                <template #controls>
                    <z-button
                        kind="secondary"
                        size="l"
                        @click="currentSlideIndex === 0 ? goToWelcomeScreen() : goToPreviousSlide()">
                        Назад
                    </z-button>
                    <z-button
                        v-if="isNotLastSlide"
                        class="presentation__control-next"
                        @click="goToNextSlide"
                        kind="primary"
                        size="l">
                        Далее
                    </z-button>
                    <z-button
                        v-else
                        @click="submitPresentationComplete"
                        kind="primary"
                        size="l">
                        Подтверждаю ознакомление
                    </z-button>
                </template>
            </slides-screen>
            <result-screen
                v-else-if="isPresentationStarted && isPresentationViewed"
                :company-name="companyName"
                :company-inn="companyInn"
                :organization-name="organizationName"
                :certificate-pdf="certificatePdf"
                key="result-screen"
                @removeResultScreen="goToWelcomeScreen" />
        </transition-fade>
    </div>
</template>

<script>
import Logo from './components/Logo'
import WelcomeScreen from './components/WelcomeScreen.vue'
import SlidesScreen from './components/SlidesScreen.vue'
import ResultScreen from './components/ResultScreen.vue'
import handleYmGoal from './utils/analytics.js'
import TransitionFade from './components/TransitionFade.vue'

export default {
    name: 'Presentation',
    components: {
        Logo,
        WelcomeScreen,
        SlidesScreen,
        ResultScreen,
        TransitionFade
    },
    data () {
        return {
            isPresentationStarted: false,
            isPresentationViewed: false,
            currentSlideIndex: 0,
            slidesCount: 14,
            companyName: '',
            companyInn: '',
            organizationName: '',
            certificatePdf: null
        }
    },
    computed: {
        isNotLastSlide () {
            return this.currentSlideIndex + 1 < this.slidesCount
        }
    },
    methods: {
        handlePresentationStart (name, taxNumber, organizationName, pdf) {
            this.companyName = name
            this.companyInn = taxNumber
            this.organizationName = organizationName
            this.certificatePdf = pdf
            this.isPresentationStarted = true
        },
        goToWelcomeScreen () {
            this.isPresentationStarted = false
            this.isPresentationViewed = false
            this.currentSlideIndex = 0
        },
        goToPreviousSlide () {
            if (this.currentSlideIndex > 0) {
                this.currentSlideIndex--
            }
        },
        goToNextSlide () {
            if (this.currentSlideIndex === 0) {
                handleYmGoal('click-presentation-next')
                handleYmGoal('click-presentation-next-step1')
            }
            this.currentSlideIndex++
        },
        submitPresentationComplete () {
            this.isPresentationViewed = true
            handleYmGoal('click-presentation-result')
            handleYmGoal('click-presentation-result-step2')
        }
    }
}
</script>

<style lang="scss">
.presentation {
    .content-container {
        @include padding-level(top, S);
        @include padding-level(bottom, S);

        @include breakpoint(mobile) {
            @include padding-level(left, XS);
            @include padding-level(right, XS);
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }
    }

    &__logo {
        @include breakpoint(v-tablet) {
            display: none;
        }
    }
    &__logo-mobile {
        display: none !important;

        @include breakpoint(v-tablet) {
            display: flex !important;

            &:before,
            &:after {
                display: block !important;
            }

            .z-caption__text {
                padding-left: 16px !important;
                padding-right: 16px !important;
            }
        }
    }

    &__title {
        color: $token-colors-gray-35 !important;
        flex: 1;

        @include breakpoint(v-tablet) {
            margin-bottom: 16px;

            &::before,
            &::after {
                display: none;
            }

            .z-caption__text {
                padding-left: 0 !important;
            }
        }
    }

    &__control-next {
        min-width: 220px;
    }
}
</style>
