/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paperclip': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.043 7.557a.6.6 0 10-1.2 0v10.5a3.9 3.9 0 11-7.8 0V4.37c0-1.27 1.06-2.326 2.4-2.326 1.34 0 2.4 1.055 2.4 2.326v13.5a.9.9 0 11-1.8 0V7.37a.6.6 0 00-1.2 0v10.5a2.1 2.1 0 004.2 0V4.37c0-1.962-1.626-3.526-3.6-3.526-1.974 0-3.6 1.565-3.6 3.526v13.687a5.1 5.1 0 0010.2 0v-10.5z" _fill="#0077C8"/>'
  }
})
