<template>
    <z-tabs
        v-if="data && data.years && data.years.length"
        size="m"
        @open-tab="onTabChange"
    >
        <template v-slot:label>
            <z-tabs-label
                v-for="(item, index) in data.years"
                :key="index"
                :id="item.id"
            >
                {{ item.text }}
            </z-tabs-label>
        </template>
    </z-tabs>
</template>

<script>
export default {
    name: 'filters',
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            params: {
                year: null
            }
        }
    },
    methods: {
        onTabChange (tab) {
            const year = tab.replace('tabs-', '')

            this.params.year = year

            this.$emit('change', this.params)
        }
    }
}
</script>