/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-small/zip': {
    width: 50,
    height: 60,
    viewBox: '0 0 50 60',
    data: '<path pid="0" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="1" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="2" d="M21.787 35v-1.184h-6.112l6-8.384v-1.104h-7.696v1.184h6l-6 8.384V35h7.808zm3.36 0V24.328h-1.329V35h1.328zm3.827 0v-4.24h2.96c2.112 0 3.344-1.472 3.344-3.216 0-1.744-1.2-3.216-3.344-3.216h-4.288V35h1.328zm2.8-5.424h-2.8v-4.064h2.8c1.264 0 2.128.832 2.128 2.032 0 1.2-.864 2.032-2.128 2.032z" _fill="#0077C8"/>'
  }
})
