<template>
    <div class="filter-container">
        <button class="filter-container__trigger" @click="openFilter">
            <span v-html="text.filter"></span>
            <z-icon
                name="arrow-long"
                dir="right"
                width="22"
                height="22"
            />
        </button>
        <div
            :class="[
                'filter-container__wrapper',
                { 'is-open': isFilterOpen }
            ]"
        >
            <z-button
                kind="text"
                size="m"
                @click="closeFilter"
                class="filter-container__close-btn"
            >
                <span v-html="text.back"></span>
                <template v-slot:left>
                    <z-icon
                        name="arrow-long"
                        dir="left"
                        width="22"
                        height="22"
                    />
                </template>
            </z-button>

            <div class="filter-container__header">
                <z-caption
                    tag="h2"
                    decor-right
                    size="m"
                    weight="700"
                    uppercase
                    class="filter-container__title"
                >
                    <span v-html="text.filter"></span>
                </z-caption>
                <z-button
                    kind="secondary"
                    size="s"
                    class="filter-container__reset-btn"
                    v-if="showResetBtn"
                    @click="onClear"
                >
                    <span v-html="resetButtonText"></span>
                </z-button>
            </div>

            <div class="filter-container__content">
                <slot />
            </div>

            <div class="filter-container__apply-btn">
                <z-button
                    kind="text"
                    size="m"
                    @click="applyFilter"
                >
                    <span v-html="text.apply"></span>
                    <template v-slot:right>
                        <z-icon
                            name="arrow-long"
                            dir="right"
                            width="22"
                            height="22"
                        />
                    </template>
                </z-button>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'filter-container',
    props: {
        showResetBtn: {
            type: Boolean,
            default: false
        },
        resetText: {
            type: String
        }
    },
    data () {
        return {
            isFilterOpen: false,
            text: {
                filter: localize({
                    ru: 'Фильтр',
                    en: 'Filter'
                }),
                reset: localize({
                    ru: 'Показать все',
                    en: 'Show all'
                }),
                back: localize({
                    ru: 'Назад',
                    en: 'Back'
                }),
                apply: localize({
                    ru: 'Применить',
                    en: 'Apply'
                })
            }
        }
    },
    computed: {
        resetButtonText () {
            if (this.resetText) return this.resetText
            return this.text.reset
        }
    },
    methods: {
        applyFilter () {
            this.$emit('apply')
            this.closeFilter()
        },
        onClear () {
            this.$emit('clear')
        },
        openFilter () {
            this.isFilterOpen = true
            this.disableScroll()
        },
        closeFilter () {
            this.isFilterOpen = false
            this.enableScroll()
        },
        disableScroll () {
            this.scrollY = window.pageYOffset
            document.body.style.position = 'fixed'
            document.body.style.top = `-${this.scrollY}px`
        },
        enableScroll () {
            document.body.style.position = ''
            document.body.style.top = ''
            window.scroll(0, this.scrollY)
        }
    }
}
</script>

<style lang="scss">
.filter-container {
    .datepicker-range__row-filter {
        max-width: 768px;

        @media (max-width: 1440px) and (min-width: $breakpoint-tablet), (max-width: $breakpoint-mobile)  {
            .row-filter__item {
                flex-basis: calc(50% - 1px);
            }
        }
    }

    @media (max-width: 1750px) and (min-width: $breakpoint-tablet) {
        .z-date-picker {
            .mx-input {
                padding: 8px 10px;
                padding-right: 32px;
                font-size: 14px;
            }

            .mx-icon-calendar {
                width: 18px;
                height: 18px;
            }
        }

        .z-date-picker-popup {
            .mx-btn-text {
                font-size: 18px;
            }

            .mx-calendar {
                padding: 16px;
            }

            .mx-calendar-header {
                margin-bottom: 16px;
            }

            .mx-icon-left:before,
            .mx-icon-right:before,
            .mx-icon-double-left:before,
            .mx-icon-double-right:before,
            .mx-icon-double-left:after,
            .mx-icon-double-right:after {
                width: 14px;
                height: 14px;
            }

            .cell {
                padding: 6px;
                font-size: 14px;

                div {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                }
            }
        }
    }

    &__reset-btn {
        flex-shrink: 0;

        @include breakpoint (mobile) {
            width: auto !important;
        }

        &.z-button {
            color: $token-colors-gray-60;
        }

        .z-icon {
            path {
                fill: $token-colors-gray-60 !important;
            }
        }
    }

    &__apply-btn {
        display: none;
        @include margin-level(top, L);

        @include breakpoint (tablet) {
            display: block;
        }
    }

    &__trigger {
        background: none;
        border: none;
        width: 100%;
        @include padding-level(top, XS);
        @include padding-level(bottom, XS);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid $token-colors-gray-60;
        border-top: 1px solid $token-colors-gray-60;
        display: none;

        .z-icon {
            path {
                fill: $token-colors-gray-60 !important;
            }
        }

        @include breakpoint (tablet) {
            display: flex;
        }
    }

    &__close-btn {
        display: none !important;
        @include margin-level(bottom, M);

        @include breakpoint (tablet) {
            display: inline-flex !important;
        }
    }

    &__wrapper {
        @include breakpoint (tablet) {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $token-colors-white;
            z-index: 10;
            padding: $token-spacers-s $token-spacers-2-xs;
            overflow-y: auto;

            &.is-open {
                display: block;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        @include margin-level(bottom, 2XL);

        @include breakpoint (tablet) {
            @include margin-level(bottom, M);
        }
    }

    &__title {
        flex-grow: 1;
        margin: 0 !important;
        padding-right: $token-spacers-2-xs;
    }
}
</style>
