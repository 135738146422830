/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pulse': {
    width: 64,
    height: 29,
    viewBox: '0 0 64 29',
    data: '<rect pid="0" width="64" height="29" rx="14.5" _fill="#0077C8"/><path pid="1" d="M19.824 18.5H18.42v-6.768h-4.164V18.5h-1.404v-8.004h6.972V18.5zm2.66.144c-.72 0-1.392-.288-1.644-.552l.504-1.152c.288.252.66.456 1.044.456.648 0 .924-.276 1.308-1.02l-3.012-5.88h1.596l2.208 4.668 2.208-4.668h1.596L24.956 17c-.492.96-1.104 1.644-2.472 1.644zm5.567 0v-1.248c.864 0 1.308-.42 1.584-2.784l.468-4.116h5.436V18.5h-1.404v-6.768h-2.772l-.336 2.976c-.384 3.396-1.428 3.936-2.976 3.936zm9.188-8.148h1.404v2.988h2.112c1.668 0 2.616 1.152 2.616 2.508s-.936 2.508-2.616 2.508h-3.516v-8.004zm3.324 4.224h-1.92v2.544h1.92c.792 0 1.368-.504 1.368-1.272s-.576-1.272-1.368-1.272zm7.395 3.924c-2.364 0-4.2-1.68-4.2-4.14s1.836-4.14 4.2-4.14c1.62 0 2.616.84 3.216 1.8l-1.2.612a2.376 2.376 0 00-2.016-1.164c-1.584 0-2.76 1.212-2.76 2.892s1.176 2.892 2.76 2.892a2.35 2.35 0 002.016-1.164l1.2.612c-.612.96-1.596 1.8-3.216 1.8z" _fill="#fff"/>'
  }
})
