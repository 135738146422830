/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-small/rub': {
    width: 50,
    height: 60,
    viewBox: '0 0 50 60',
    data: '<path pid="0" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="1" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="2" d="M17.992 35h-1.568l-2.72-4.24h-2.128V35h-1.328V24.328h4.288c1.952 0 3.344 1.248 3.344 3.216 0 1.92-1.312 2.976-2.768 3.104L17.992 35zm-3.616-5.408c1.264 0 2.128-.848 2.128-2.048 0-1.2-.864-2.032-2.128-2.032h-2.8v4.08h2.8zm9.958 5.6c-2.896 0-4.352-1.712-4.352-4.336v-6.528h1.328v6.496c0 1.952 1.056 3.184 3.024 3.184s3.024-1.232 3.024-3.184v-6.496h1.328v6.512c0 2.656-1.456 4.352-4.352 4.352zM36.05 35h-4.864V24.328h4.752c1.855 0 2.991 1.12 2.991 2.72 0 1.344-.896 2.24-1.872 2.448 1.136.176 2.08 1.344 2.08 2.624 0 1.712-1.136 2.88-3.087 2.88zm-.336-6.048c1.2 0 1.84-.736 1.84-1.712 0-.96-.64-1.728-1.84-1.728h-3.2v3.44h3.2zm.064 4.864c1.248 0 1.983-.704 1.983-1.84 0-.976-.672-1.84-1.983-1.84h-3.264v3.68h3.264z" _fill="#0077C8"/>'
  }
})
