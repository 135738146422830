<template>
    <div
        :class="classObject"
    >
        <div
        	class="z-chart__el"
        	ref="chart"
        ></div>
        <div
            :class="[
                'z-chart__legend',
            ]"
            :style="`columns: ${legendColumns}`"

            ref="legend"
        ></div>
    </div>
</template>

<script>
import { bb } from 'billboard.js/dist/billboard.pkgd'
const d3 = require('d3')

export default {
    name: 'z-chart',
    props: {
        options: {
            type: Object,
            required: true
        },
        media: {
            type: Object
        },
        legend: {
            type: Object,
            default: () => {
                return {
                    position: 'left-bottom'
                }
            }
        },
        theme: {
            type: Array,
            default: () => {
                return []
            }
        },
        legendColumns: {
            type: String
        },
        gradient: Boolean,
        extendedOptions: Object
    },
    data () {
        return {
            $chart: null,
            themeChart: {
                grid: {
                    y: {
                        show: true
                    }
                },
                axis: {
                    x: {
                        tick: {
                            show: false
                        },
                        label: {
                            color: 'black'
                        }
                    },
                    y: {
                        tick: {
                            show: false
                        }
                    }
                },
                padding: {
                    top: 10,
                    right: 0,
                    bottom: 0,
                    left: 50
                },
                color: {
                    pattern: [
                        '#004C97',
                        '#51B9FF',
                        '#0077C8',
                        '#19B88F',
                        '#356F97',
                        '#8CB1CA',
                        '#06828A',
                        '#47E1BA'
                    ]
                },
                legend: {
                    contents: {
                        template: `<span class="z-chart__legend-item">
                                <span class="z-chart__legend-square" style='background-color:{=COLOR}'"></span>
                                <span class="z-chart__legend-title">{=TITLE}</span>
                            </span>`
                    }
                },
                bar: {
                    width: {
                        ratio: 0.7,
                        max: 50
                    }
                },
                // labels: {
                //     format: value => this.localizeValues(value)
                // },
                tooltip: {
                    format: {
                        value: value => this.localizeValues(value)
                    }
                }
            }
        }
    },
    mounted () {
        this.$chart = this.generateChart()
        this.$emit('mounted', this.$chart)

        window.addEventListener('resize', () => {
            this.resizeChart()
        })
    },
    unmounted () {
        this.destroyChart()
    },
    watch: {
        'options.data': {
            deep: true,
            handler (newData) {
                this.loadDataChart(newData)
            }
        }
    },
    methods: {
        resizeChart () {
            if (this.media && window.innerWidth < this.media.point) {
                this.$chart.internal.config = Object.assign(this.$chart.internal.config, this.media.options)
            }
        },
        generateChart () {
            /**
             * @function generateChart
             *
             * @description
             * generates the charts bases on the options this
             *
             * @returns {Object} the generated chart this
             */

            let options = Object.assign({
                ...this.themeChart,
                bindto: this.$refs.chart
            }, this.options)

            if (options.legend.contents) {
                options.legend.contents.bindto = this.$refs.legend
            }

            if (this.extendedOptions) {
                options = Object.assign(options, this.extendedOptions)
            }

            if (this.gradient) {
                options.color.tiles = function () {
                    var gradient = d3.select(document.createElementNS(d3.namespaces.svg, 'linearGradient'))
                        .attr('patternUnits', 'userSpaceOnUse')
                        .attr('x2', '0')
                        .attr('y2', '100%')

                    gradient
                        .append('stop')
                        .attr('offset', '0%')
                        .attr('stop-color', '#1FBB93')

                    gradient
                        .append('stop')
                        .attr('offset', '100%')
                        .attr('stop-color', '#0077C8')

                    return [
                        gradient.node()
                    ]
                }
            }

            if (options.data.labels && !options.data.labels.format) {
                options.data.labels = {
                    format: (v, id, i, j) => {
                        return this.$options.filters.numberFilter(v)
                    }
                }
            }

            return bb.generate(options)
        },
        destroyChart () {
            /**
             * @function destroyChart
             *
             * @description
             * destroys the chart and sets ref to null
             */
            return () => {
                try {
                    this.$chart.destroy()
                    this.$chart = null
                } catch (error) {
                    console.error('Internal Billboard.js error', error)
                }
            }
        },
        loadDataChart (data) {
            /**
             * @function loadDataChart
             *
             * @description
             * Updates the chart with the new data
             *
             * @param {object} options from this to update the chart with
             */
            return data => {
                if (!this.$chart) {
                    this.$chart = this.generateChart(this.options)
                }
                this.$chart.load(data)
            }
        },
        localizeValues (value) {
            // return this.$root.lang === 'ru' ? value.toString().replace('.', ',') : value
            return this.$options.filters.numberFilter(value)
        }
    },
    computed: {
        classObject () {
            let arrClass = [
                'z-chart'
            ]

            if (this.legend.position) {
                arrClass.push(`z-chart--legend-position-${this.legend.position}`)
            }

            if (this.theme.length) {
                this.theme.forEach(theme => {
                    arrClass.push(`z-chart--theme-${theme}`)
                })
            }

            return arrClass
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
