/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/xls': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM19.575 31h1.68l-2.988-4.116 2.808-3.888h-1.692l-2.052 2.94-2.064-2.94h-1.68l2.82 3.9-3 4.104h1.68l2.244-3.132L19.575 31zm2.582 0h4.932v-1.236H23.56v-6.768h-1.404V31zm5.683-1.128c.708.756 1.776 1.272 3.252 1.272 2.16 0 3.096-1.14 3.096-2.472 0-1.752-1.6-2.159-2.925-2.496-.934-.238-1.731-.44-1.731-1.056 0-.6.528-.996 1.356-.996.84 0 1.692.288 2.316.912l.804-1.044c-.744-.72-1.752-1.116-3-1.116-1.752 0-2.916 1.02-2.916 2.34 0 1.683 1.517 2.067 2.82 2.397.985.25 1.848.469 1.848 1.191 0 .528-.456 1.092-1.608 1.092a3.453 3.453 0 01-2.532-1.104l-.78 1.08z" _fill="#737B91"/>'
  }
})
