/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'profit': {
    width: 75,
    height: 29,
    viewBox: '0 0 75 29',
    data: '<rect pid="0" width="75" height="29" rx="14.5" _fill="#0077C8"/><path pid="1" d="M19.824 18.5H18.42v-6.768h-4.164V18.5h-1.404v-8.004h6.972V18.5zm3.104 0h-1.404v-8.004h3.516c1.68 0 2.616 1.152 2.616 2.508s-.948 2.508-2.616 2.508h-2.112V18.5zm1.92-4.224c.792 0 1.368-.504 1.368-1.272s-.576-1.272-1.368-1.272h-1.92v2.544h1.92zm7.709 4.368c-2.4 0-4.092-1.752-4.092-4.14s1.692-4.14 4.092-4.14c2.4 0 4.092 1.752 4.092 4.14s-1.692 4.14-4.092 4.14zm0-1.248c1.608 0 2.652-1.248 2.652-2.892 0-1.656-1.044-2.892-2.652-2.892-1.62 0-2.652 1.236-2.652 2.892 0 1.644 1.032 2.892 2.652 2.892zM42.848 18.5h-1.404v-.792c-2.376-.192-3.804-1.368-3.804-3.204 0-1.812 1.428-3 3.804-3.192v-.816h1.404v.804c2.364.192 3.804 1.392 3.804 3.204 0 1.836-1.44 3.012-3.804 3.204v.792zm2.364-3.996c0-1.056-.84-1.776-2.364-1.944v3.888c1.524-.156 2.364-.876 2.364-1.944zm-6.132 0c0 1.056.84 1.776 2.364 1.944V12.56c-1.524.168-2.364.888-2.364 1.944zM49.364 18.5h-1.356v-8.004h1.404v5.556l4.092-5.556h1.44V18.5H53.54v-5.712L49.364 18.5zm10.593 0h-1.404v-6.768h-2.424v-1.236h6.252v1.236h-2.424V18.5z" _fill="#fff"/>'
  }
})
