<template>
    <div
        class="non-industrial-assets-table-item"
        :style="`max-width: ${maxWidth}`"
    >
        <span v-if="title" class="non-industrial-assets-table-item__title">
            <span v-html="title"/>
        </span>
        <div :class="['non-industrial-assets-table-item__text', {'non-industrial-assets-table-item__text--scaled' : scaledText} ]">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'non-industrial-assets-table-item',
    props: {
        title: {
            type: String
        },
        maxWidth: {
            type: String,
            default: 'auto'
        },
        scaledText: Boolean
    }
}
</script>

<style lang="scss">
.non-industrial-assets-table-item {
    width: 100%;

    @include breakpoint(mobile) {
        width: 100%;
    }

    &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-bottom: $token-spacers-3-xs;
        display: flex;
        align-items: center;
        position: relative;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black;

        @include breakpoint(mobile) {
            font-size: 16px;
        }

        &--scaled {
            font-size: 18px;
        }
    }
}
</style>
