<template>
    <div v-if="data" class="search-page">
        <layout class="container">
            <template #aside>
                <search-filter :from="params.from"
                               :to="params.to"
                               :tags="tags"
                               :tags-params="params.tags"
                               class="hide-me--tablet"
                               @change="filterHandler"/>
            </template>
            <template #content>
                <search-input :data="params.q" @change="inputHandler"/>
                <search-filter :from="params.from"
                               :to="params.to"
                               :tags="tags"
                               :tags-params="params.tags"
                               class="show-me--tablet hide-me--default u-bottom-margin--xs"
                               @change="filterHandler"/>
                <search-sorting v-if="items.length" :sections="sections" :sort="params.how" @change="sortingHandler"/>
                <content-container>
                    <template v-if="items.length">
                        <search-content :items="items"/>
                        <z-preloader v-if="isLoading"/>
                        <z-pagination :data="page" @change-page="paginationHandler"/>
                    </template>
                    <z-not-found v-else-if="isInit" only-image>
                        <template #image>
                            <z-image src="/images/not-found/search-2.svg" width="590" height="400"/>
                        </template>
                    </z-not-found>
                    <z-not-found v-else>
                        <template #image>
                            <z-image src="/images/not-found/error-search.svg" width="590" height="400"/>
                        </template>
                        <template #description>
                            <z-caption size="m" decor-left decor-right weight="900" uppercase v-html="notFound.title"/>
                            <p class="text-size-xl u-normal" v-html="notFound.text"/>
                        </template>
                    </z-not-found>
                </content-container>
            </template>
        </layout>
    </div>
</template>

<script>
import {throttle} from 'throttle-debounce'
import {queryString} from '@/utils/queryString'
import {clearEmptyParams} from '@/utils/clearEmptyParams'
import SearchFilter from './components/SearchFilter.vue'
import SearchInput from './components/SearchInput.vue'
import SearchSorting from './components/SearchSorting.vue'
import SearchContent from './components/SearchContent.vue'
import {getSearch} from '@/api/search'
import {localize} from '@/utils/i18n'

export default {
    name: 'search-page',
    components: {
        SearchContent,
        SearchSorting,
        SearchInput,
        SearchFilter
    },
    data () {
        return {
            isInit: true,
            data: this.$root.app?.components?.search,
            items: this.$root.app?.components?.search?.items,
            sections: this.$root.app?.components?.search?.filters?.sections,
            tags: this.$root.app?.components?.search?.filters?.tags,
            isLoading: false,
            page: {
                currentPage: this.$root.app?.components?.search?.nav?.current,
                totalItems: this.$root.app?.components?.search?.nav?.count,
                totalPages: this.$root.app?.components?.search?.nav?.total,
                pageRange: 1
            },
            params: {
                tags: [],
                q: '',
                how: '',
                section: '',
                from: '',
                to: ''
            },
            notFound: {
                title: localize({
                    ru: 'Ничего не&nbsp;найдено',
                    en: 'NOTHING FOUND'
                }),
                text: localize({
                    ru: 'По&nbsp;вашему запросу ничего не&nbsp;найдено, попробуйте изменить формулировку или выполнить поиск по&nbsp;ключевым словам\n',
                    en: 'No&nbsp;results were found for your search, try changing the wording or&nbsp;searching for keywords'
                })
            }
        }
    },
    created () {
        this.init()
    },
    methods: {
        filterHandler (data) {
            this.page.currentPage = 1
            this.params.tags = data.tags
            this.params.from = data.from
            this.params.to = data.to
            this.updateParams()
            this.send()
        },
        inputHandler (data) {
            this.page.currentPage = 1
            this.params.q = data
            this.updateParams()
            this.send()
        },
        paginationHandler (data) {
            this.page.currentPage = data.page
            this.send(data.type)
        },
        sortingHandler (data) {
            this.page.currentPage = 1
            this.params.how = data.currentSort
            this.params.section = data.section
            this.updateParams()
            this.send()
        },
        updateParams () {
            queryString(clearEmptyParams(this.params))
        },
        send: throttle(100, function (more = null) {
            this.isInit = false
            this.isLoading = true
            getSearch({...this.params, page: this.page.currentPage})
                .then((response) => {
                    if (more === 'load-more') {
                        this.items = [...this.items, ...response.items]
                    } else {
                        this.items = response.items
                    }
                    this.data = response
                    this.sections = response.filters.sections
                    this.tags = response.filters.tags
                    this.page.currentPage = response.nav.current
                    this.page.totalItems = response.nav.count
                    this.page.totalPages = response.nav.total
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        }),
        init () {
            const params = new URL(document.location).searchParams
            this.params.tags = params.getAll('tags[]') ? params.getAll('tags[]') : []
            this.params.q = params.get('q') ? params.get('q') : ''
            this.params.how = params.get('how') ? params.get('how') : ''
            this.params.from = params.get('from') ? params.get('from') : ''
            this.params.to = params.get('to') ? params.get('to') : ''
            this.params.section = params.get('section') ? params.get('section') : ''
        }
    }
}
</script>

<style lang="scss">
.search-page {
    &__input-container {
        display: flex;
    }
}
</style>
