/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hands': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<g clip-path="url(#clip0_9226_32283)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.6 12l8.7 8.7 1.4-1.4-9-9c-.2-.2-.4-.3-.7-.3h-2c-.3 0-.5.1-.7.3l-2 2a1 1 0 00-.243.357L12.7 11.3c-.2-.2-.4-.3-.7-.3h-2c-.3 0-.5.1-.7.3l-2 2a1 1 0 00-.243.357L5.7 12.3c-.2-.2-.4-.3-.7-.3H3c-.3 0-.5.1-.7.3l-2 2c-.2.2-.3.4-.3.7v2c0 .3.1.5.3.7l2.357 2.357a1 1 0 00-.357.243l-2 2c-.2.2-.3.4-.3.7v2c0 .3.1.5.3.7l8.035 8.035C7.038 33.95 5.94 34.66 5.3 35.3l-1 1c-.4.4-.4 1 0 1.4l11 11C18.6 52 23.4 54 28 54c1.388 0 2.731-.205 4-.586 1.268.381 2.611.586 4 .586 4.6 0 9.4-2 12.7-5.3l11-11c.4-.4.4-1 0-1.4l-1-1c-.1-.1-1-1-2.3-1.4a4.635 4.635 0 00-.723-.177L63.7 25.7c.2-.2.3-.4.3-.7v-2c0-.3-.1-.5-.3-.7l-2-2a1 1 0 00-.357-.243L63.7 17.7c.2-.2.3-.4.3-.7v-2c0-.3-.1-.5-.3-.7l-2-2c-.2-.2-.4-.3-.7-.3h-2c-.3 0-.5.1-.7.3l-1.357 1.357a1 1 0 00-.243-.357l-2-2c-.2-.2-.4-.3-.7-.3h-2c-.3 0-.5.1-.7.3l-1.357 1.357a1 1 0 00-.243-.357l-2-2c-.2-.2-.4-.3-.7-.3h-2c-.3 0-.5.1-.7.3l-17 17C24 30.6 22 35.4 22 40c0 5.06 2.72 9.515 6.77 11.975-.255.017-.511.025-.77.025-4.1 0-8.3-1.8-11.3-4.7L6.4 37l.3-.3s.6-.6 1.5-.9c1.09-.396 2.08-.106 3.071.871L16.3 41.7l1.4-1.4-5-5-.032-.032L2 24.6v-1.2L3.4 22h1.2l12.7 12.7 1.4-1.4L2 16.6v-1.2L3.4 14h1.2l14.7 14.7 1.4-1.4L9 15.6v-1.2l1.4-1.4h1.2l11.7 11.7 1.4-1.4-8.7-8.7v-1.2l1.4-1.4h1.2zm16.63 39.975C39.28 49.515 42 45.06 42 40c0-4.6-2-9.4-5.3-12.7l-1.4 1.4c2.9 3 4.7 7.2 4.7 11.3 0 5.2-3.351 9.654-8 11.31-4.65-1.656-8-6.11-8-11.31 0-4.1 1.8-8.3 4.7-11.3L45.4 12h1.2l1.4 1.4v1.2l-8.7 8.7 1.4 1.4L52.4 13h1.2l1.4 1.4v1.2L43.3 27.3l1.4 1.4L59.4 14h1.2l1.4 1.4v1.2L45.3 33.3l1.4 1.4L59.4 22h1.2l1.4 1.4v1.2L51.316 35.284l-.016.016-5 5 1.4 1.4 5-5a1.6 1.6 0 01.013-.013c2.196-2.18 4.387-.187 4.587.013l.3.3-10.3 10.3c-3 2.9-7.2 4.7-11.3 4.7-.259 0-.516-.008-.77-.025z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_32283"><path pid="1" _fill="#fff" d="M0 0h64v64H0z"/></clipPath></defs>'
  }
})
