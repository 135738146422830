/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filtering': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.429 6.273c-.246 0-.456.05-.585.115a.468.468 0 00-.058.034v.989c.014.01.033.021.058.034.13.064.34.114.585.114.245 0 .455-.05.584-.114a.47.47 0 00.058-.034V6.422a.47.47 0 00-.058-.034 1.374 1.374 0 00-.584-.115zM9.07 7.488v-.13h12.072a.5.5 0 100-1H9.07v-.013c0-.437-.334-.713-.61-.851-.3-.15-.672-.22-1.032-.22-.361 0-.732.07-1.032.22-.276.138-.611.414-.611.85v.013H2.857a.5.5 0 000 1h2.929v.13c0 .438.335.714.61.852.3.15.672.22 1.033.22.36 0 .732-.07 1.031-.22.277-.138.611-.414.611-.851zM7.43 17.643c-.246 0-.456.05-.585.114a.468.468 0 00-.058.034v.989c.014.01.033.021.058.034.13.064.34.114.585.114.245 0 .455-.05.584-.114a.469.469 0 00.058-.034v-.989a.469.469 0 00-.058-.034 1.374 1.374 0 00-.584-.114zm1.642.142v-.071c0-.437-.334-.713-.61-.851-.3-.15-.672-.22-1.032-.22-.361 0-.732.07-1.032.22-.276.138-.611.414-.611.851v.071H2.857a.5.5 0 100 1h2.929v.072c0 .437.335.713.61.851.3.15.672.22 1.033.22.36 0 .732-.07 1.031-.22.277-.138.611-.414.611-.851v-.072h12.072a.5.5 0 100-1H9.07zm6.916-5.743c.129-.064.34-.114.585-.114.245 0 .455.05.584.114a.466.466 0 01.058.034v.989a.466.466 0 01-.058.034 1.374 1.374 0 01-.584.114c-.246 0-.456-.05-.585-.114a.468.468 0 01-.058-.034v-.989a.468.468 0 01.058-.034zm2.227 1.028v.072c0 .437-.334.713-.61.851-.3.15-.672.22-1.032.22-.361 0-.732-.07-1.032-.22-.276-.138-.611-.414-.611-.851v-.072H2.857a.5.5 0 110-1H14.93V12c0-.438.335-.714.61-.852.3-.15.672-.22 1.033-.22.36 0 .732.07 1.031.22.277.138.611.414.611.851v.071h2.929a.5.5 0 110 1h-2.929z" _fill="#0077C8"/>'
  }
})
