export const completed = [
    {
        coords: [69.4891634, 88.4581742],
        name: 'Гора отдельная',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>01.12.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>31.12.2021</p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>450&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 4;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>7</p>'
    },
    {
        coords: [69.5225602, 88.4705599],
        name: 'р-к &laquo;Скалистый&raquo; Пешеходная галерея ВС-9',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>09.11.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>02.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>664,2&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>875,18&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>24,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Автокран&nbsp;&mdash; 1;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>6</p>'
    },
    {
        coords: [69.5211902, 88.4691295],
        name: 'р-к &laquo;Скалистый&raquo; Здание пожбаков ВС-9',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>09.11.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>02.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2&nbsp;236,5&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>74,93&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Автокран&nbsp;&mdash; 1;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>6</p>'
    },
    {
        coords: [69.5037207, 88.4320714],
        name: 'Склад сырья р-к &laquo;Комсомольский&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>10.06.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>07.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>53&nbsp;157,6&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>2&nbsp;280,75&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>178&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 4;</li><li>Погрузчик&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>9</p>'
    },
    {
        coords: [69.4896631, 88.4584547],
        name: 'Домик для отдыха р-к &laquo;Комсомольский&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>19.10.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>11.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1&nbsp;009&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>176,41&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>2,94&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.486547, 88.3795925],
        name: 'Р-н Талнах, ростверк ул.&nbsp;Спортивная&nbsp;14',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>11.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>12.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2&nbsp;052&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>953&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.3248979, 87.3352639],
        name: '5-ти этажное здание, поселок Алыкель',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>08.06.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>09.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>29&nbsp;568&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>11&nbsp;969&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 4;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    },
    {
        coords: [69.5332378, 88.2955997],
        name: 'Здание мусоросборника ЗКПД-1',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>02.08.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>12.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>85,86&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>50,63&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>1&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 3;</li><li>Самосвал&nbsp;&mdash; 8;</li><li>Погрузчик&nbsp;&mdash; 1;</li><li>Автокран&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>17</p>'
    },
    {
        coords: [69.4961272, 88.4116215],
        name: 'Здание подъемной машины р-к &laquo;Комсомольский&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>10.07.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>08.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1&nbsp;655&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>925,5&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>4,79&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.5310797, 88.2939631],
        name: 'з/у с&nbsp;кадастровым &#8470;&nbsp;24:55:0201001:18',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>02.08.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>12.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>156&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>390&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 3;</li><li>Самосвал&nbsp;&mdash; 8;</li><li>Погрузчик&nbsp;&mdash; 1;</li><li>Автокран&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>17</p>'
    },
    {
        coords: [69.5391217, 88.3019874],
        name: 'Мачты освещения',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>29.08.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>11.2022</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>446,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>11,3&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Самосвал&nbsp;&mdash; 6;</li><li>Погрузчик&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    },
    {
        coords: [69.5383017, 88.2952019],
        name: 'Здание противопожарной насосоной станции',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>22.09.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>11.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>941,6&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>585&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>27&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 6;</li><li>Самосвал&nbsp;&mdash; 10;</li><li>Погрузчик&nbsp;&mdash; 2;</li><li>Бульдозер&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>24</p>'
    },
    {
        coords: [69.3504981, 87.8038446],
        name: 'Сан. очистка з/у &#8470;&nbsp;24:55:0601002:26 Красноярский край район города Норильска, в&nbsp;районе Каларгонского месторождения (шахта &laquo;Известняков&raquo;)',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>1.02.2023</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>3.2023</p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>715&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.4865449, 88.378477],
        name: 'Р-н Талнах, ростверк ул.&nbsp;Спортивная&nbsp;16',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>11.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>02.2023</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>3&nbsp;078&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1&nbsp;009&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    },
    {
        coords: [69.4873547, 88.3796571],
        name: 'Р-н Талнах, ростверк ул.&nbsp;Пионерская&nbsp;4',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>11.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>03.2023</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>633,6&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1&nbsp;264,8&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    },
    {
        coords: [69.4857136, 88.4168343],
        name: 'Домик ОТК р-к &laquo;Маяк&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>06.08.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>31.10.2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>135&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>18,42&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>1,12&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.5223203, 88.3445478],
        name: 'Бесхозное строение, территория РТ КС-3',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>09.11.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>02.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>54&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>27,4&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.5223203, 88.3445478],
        name: 'Бесхозное строение (Слесарная мастерская)',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>09.11.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>02.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>930&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>175,45&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.4836046, 88.4021424],
        name: 'Склад сырья р-к &laquo;Маяк&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>3.12.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>04.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>45&nbsp;041&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>3&nbsp;614,61&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>167,79&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 3;</li><li>Самосвал&nbsp;&mdash; 6;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>11</p>'
    },
    {
        coords: [69.5526923, 88.3141191],
        name: 'р-к &laquo;Октябрьский&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>01.06.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>10.2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>3&nbsp;562,5&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>860,48&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Самосвал&nbsp;&mdash; 4;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    },
    {
        coords: [69.5263889, 88.3331806],
        name: 'р-к &laquo;Октябрьский&raquo; Здание надшахтного КС-1&nbsp;с галереей (сооружение галереи опрокида)',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>01.06.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>10.2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>10&nbsp;617,3&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1&nbsp;380&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>22&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Самосвал&nbsp;&mdash; 4;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    },
    {
        coords: [69.5369284, 88.3318329],
        name: 'Здание (РИП) хранения изотопных материалов',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>01.06.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>10.2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>12&nbsp;312&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>2&nbsp;809,2&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Самосвал&nbsp;&mdash; 5;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>9</p>'
    },
    {
        coords: [69.5429157, 88.3251825],
        name: 'Здание ПЗК р-к &laquo;Октябрьский&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>01.06.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>10.2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>4&nbsp;586,4&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1&nbsp;376,4&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>1&nbsp;216,6&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Самосвал&nbsp;&mdash; 4;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    },
    {
        coords: [69.5432933, 88.3571339],
        name: 'Установка подогрева воздуха ВС5, 6 р-к Таймырский',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>01.10.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>12.2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1&nbsp;940,4&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>787,5&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>26,4&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.4853411, 88.423816],
        name: 'Осветительная мачта р-к &laquo;Маяк&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>01.06.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>01.2022</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>6&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.3195486, 88.2461552],
        name: 'Здание &laquo;Стрелочный пост&raquo; цем. Завод',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>11.11.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>12.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>83,6&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>75,68&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>5</p>'
    },
    {
        coords: [69.5693136, 88.3103334],
        name: 'Здание узла перегруза ООО &laquo;НОК&raquo;',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>12.10.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>01.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>5&nbsp;123,2&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1&nbsp;147&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>124&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 4;</li><li>Погрузчик&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    },
    {
        coords: [69.538049, 88.3202664],
        name: 'Гараж СДО ШП ПХО',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>30.12.2023</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>03.2023</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1&nbsp;117,8&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>689,6&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>6</p>'
    },
    {
        coords: [69.5392621, 88.32294],
        name: 'Поверхностный пункт слива и&nbsp;хранения ГСМ',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>30.12.2023</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>03.2023</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>5&nbsp;065&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>2&nbsp;583,3&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>5,64&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>6</p>'
    },
    {
        coords: [69.5364673, 88.3275752],
        name: 'ЦЕХ ТВГС',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>30.12.2023</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>03.2023</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2&nbsp;051&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1&nbsp;152,1&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 2;</li><li>Погрузчик&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>6</p>'
    },
    {
        coords: [69.313496, 87.9854656],
        name: 'Здание производственной котельной &#8470;&nbsp;2&nbsp;на площадке НМЗ, в&nbsp;т.ч. отдельно стоящие фундаменты, вспомогательные строения (з/у c&nbsp;кадастровым &#8470;&nbsp;24:55:0700001:703)',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>03.2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>09.2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>7&nbsp;344&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>11&nbsp;900&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>4&nbsp;482,35&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>1&nbsp;559,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвал&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    },
    {
        coords: [69.3206234, 87.9754819],
        name: 'ЦЭПС НМЗ',
        imageLink: '/images/ya-map/sad.jpg',
        content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>03.2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>06.2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1&nbsp;818&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>728&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    },
    // {
    //     coords: [69.349665, 87.954200],
    //     name: 'Строение №2 на&nbsp;территории ЦПЭС.<br>Санитарная очистка',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2880&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>500&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>868,89&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.359665, 87.954200],
    //     name: 'Строение №1 на&nbsp;территории ЦПЭС.<br>Санитарная очистка',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>3072&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>550&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1063,62&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.369665, 87.954200],
    //     name: 'Демонтаж здания ЦПЭС №7',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>4536&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>1010&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>826,66&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.379665, 87.954200],
    //     name: 'Строение №8 на&nbsp;территории ЦПЭС.<br>Санитарная очистка',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2178&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>730&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>983,33&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.389665, 87.954200],
    //     name: 'Санитарная очистка ЗУ с&nbsp;кадастровым №24:50:0404006:63,<br>демонтаж строения №10 (склад материалов) на&nbsp;территории ЦПЭС',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>6141,8&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>1127&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1143,1&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>10,6&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.399665, 87.954200],
    //     name: 'Санитарная очистка ЗУ с&nbsp;кадастровым №24:50:0404006:63,<br>демонтаж строения №13 на&nbsp;территории ЦПЭС',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1272&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>500&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>224,5&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>2,9&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.409665, 87.954200],
    //     name: 'Санитарная очистка ЗУ с&nbsp;кадастровым №24:50:0404006:63,<br>демонтаж строения №14 на&nbsp;территории ЦПЭС',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1069,2&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>400&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>31&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>7,9&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.419665, 87.954200],
    //     name: 'Санитарная очистка ЗУ с&nbsp;кадастровым №24:50:0404006:63,<br>демонтаж строения №15 на&nbsp;территории ЦПЭС',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1113,9&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>350&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>294,1&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>0,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.429665, 87.954200],
    //     name: 'Санитарная очистка ЗУ с&nbsp;кадастровым №24:50:0404006:63,<br>демонтаж строения №17 на&nbsp;территории ЦПЭС',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1566&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>400&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>433,2&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>10&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.429665, 87.964200],
    //     name: 'Склад спецодежды (район НМЗ)',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>декабрь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>3340,8&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>576&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1164,49&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>8,64&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.429665, 87.974200],
    //     name: 'Здание Колерного цеха (площадка НМЗ &laquo;Яма&raquo;).<br>Демонтаж',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>декабрь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>4462&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>619,72&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1187,9&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>23,5&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.429665, 87.984200],
    //     name: 'Санитарная очистка ЗУ с&nbsp;кадастровым №24:55:0404006:53, демонтаж неэксплуатируемого здания, отдельного фундамента, район насосной №18А, территория НМЗ',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>16020&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>1800&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>2868,1&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>37,3&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.429665, 87.994200],
    //     name: 'Ампулохранилище (г.&nbsp;Норильск, площадка стройбазы)',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>50,2&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>35,8&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>75,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.429665, 87.894200],
    //     name: 'Санитарная очистка з/у с&nbsp;кадастровым №24:55:0700001:683, в&nbsp;т.ч.&nbsp;демонтаж строения, территория НМЗ, район Стройбаза «Надежда» СТРОЕНИЕ&nbsp;№1',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1128&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>725&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>585&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>3&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.439665, 87.894200],
    //     name: 'Санитарная очистка з/у с&nbsp;кадастровым &#8470;&nbsp;24:55:0700001:280 и&nbsp;демонтаж здания ПЧ6. Стройбаза &laquo;Надежда&raquo;',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>май 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>сентябрь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>6487&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>2660&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>3276&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.449665, 87.894200],
    //     name: 'Здание в&nbsp;р-не Голгофы',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>май 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>8118&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>918&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1304,86&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.459665, 87.894200],
    //     name: 'Здание &laquo;прачечной спец.одежд&raquo;, инв. &#8470;&nbsp;44',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>октябрь 2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>февраль 2023</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1547,9&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>3095&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>4195,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.459665, 87.894200],
    //     name: 'Здание БПО-2, инв. &#8470;&nbsp;228',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>апрель 2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>декабрь 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>156123&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>12000&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>18941,21&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>333,48&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.469665, 87.894200],
    //     name: 'Здание склада хранения металла холодным способом, инв. &#8470;&nbsp;79000000368',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>апрель 2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>декабрь 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>29160&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>8560&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1033,45&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>83,4&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.489665, 87.894200],
    //     name: '&laquo;Пути ж/д и&nbsp;подкрановые&raquo; инв. &#8470;&nbsp;2250002272',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>май 2022</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>август 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2153,62&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>55334,5&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>294,006&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>172,91&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>15</p>'
    // },
    // {
    //     coords: [69.499665, 87.894200],
    //     name: '&laquo;Бетонно-растворный узел&raquo;, инв. &#8470;&nbsp;149',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>декабрь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>май 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>8304&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>2500&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>2744,89072&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>39&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.894200],
    //     name: '&laquo;Здание цеха окраски УПП&raquo;, инв. &#8470;&nbsp;230',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>декабрь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>март 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>739,2&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>700&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>817,63&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>46,84&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.884200],
    //     name: '&laquo;Здание: временные механические мастерские &raquo; (Красноярский край, г.&nbsp;Норильск, территория НМЗ)',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>май 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июль 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>5243&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>700&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1024&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>28&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.874200],
    //     name: 'Здание ЗТНП (Норильск, район площади ТЭЦ-3)',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>май 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июль 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>26383,3&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>850&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>13786&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>19,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.864200],
    //     name: 'Здание Пожарного депо (Норильск, район пл.&nbsp;ТЭЦ-3)',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>май 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июль 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2701&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1250&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.854200],
    //     name: 'Здание АБК на территории базы МК-2 (район НМЗ)',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>октябрь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>май 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>3125&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>406,9&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1906&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.844200],
    //     name: 'Склад &#8470;&nbsp;3&nbsp;на территории базы МК-2',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>октябрь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>май 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>362,62&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>2311&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>909,5&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>77,2&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.834200],
    //     name: 'Санитарная очистка&nbsp;ЗУ кадастровый &#8470;&nbsp;24:55:01:00001:1 и&nbsp;демонтаж зданий: насосная, ТП&nbsp;и&nbsp;резервуары на&nbsp;территории базы МК-2, (район НМЗ)',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>октябрь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>май 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1176,5&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>268&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1109,84&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>21,83&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.824200],
    //     name: 'Склад-ангар на&nbsp;территории базы МК-2',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>октябрь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>май 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>3802&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>853,6&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1021&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>17,71&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.814200],
    //     name: 'Склад &#8470;&nbsp;4&nbsp;на территории базы МК-2',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>октябрь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>май 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>3802&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>853,6&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>744,76&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>17,71&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 89.814200],
    //     name: 'Вокзал станции &laquo;Юбилейная&raquo; с&nbsp;постом и&nbsp;пунктом обогрева &#8470;&nbsp;1, район НМЗ',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>октябрь 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>май 2022</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>4215&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>607&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>2234,5&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>6,5&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 3.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>8</p>'
    // },
    // {
    //     coords: [69.499665, 85.814200],
    //     name: 'Санитарная очистка з/у &#8470;&nbsp;24:55:0404006:53 и&nbsp;демонтаж строений, р-н Насосной &#8470;&nbsp;18А, площадка &laquo;НМЗ&raquo;',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>4450&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>1522&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>3889&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>13,27&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.499665, 85.824200],
    //     name: 'Здание ремонтно-механических мастерских (НМЗ &laquo;Яма&raquo;). Демонтаж',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>11692&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>1080&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>4047,51&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.499665, 85.834200],
    //     name: 'Здание ремонтно-механических мастерских (НМЗ &laquo;Яма&raquo;). Демонтаж',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>3968&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>626&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1405,2&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>16,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.499665, 85.844200],
    //     name: 'Здание временных мастерских (НМЗ, &laquo;Яма&raquo;). Демонтаж',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2520&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>568&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>851,1&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>12,7&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.499665, 85.854200],
    //     name: 'Демонтаж механизированной мойки, Стройбаза &laquo;Надежда&raquo;',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2523,2&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>350&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1314,14&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.499665, 85.864200],
    //     name: 'Демонтаж диспетчерского пункта (Диспетчерская АТУ), Стройбаза &laquo;Надежда&raquo;',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>2378,5&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>407&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>1641,62&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.499665, 85.874200],
    //     name: 'Демонтаж сооружения по&nbsp;сбору сточных вод, Стройбаза &laquo;Надежда&raquo;',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>1080,8&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>350&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>862,04&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [69.499665, 85.894200],
    //     name: 'На&nbsp;санитарную очистку з/у с&nbsp;кадастровым &#8470;&nbsp;24:55:0700001:681, в&nbsp;т.ч. демонтаж строений 2,3&nbsp;на территории НМЗ, район Стройбаза &laquo;Надежда&raquo;',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: '<h3 class="u-bottom-margin--3xs">Дата начала</h3><p>март 2021</p><h3 class="u-bottom-margin--3xs">Дата окончания</h3><p>июнь 2021</p><h3 class="u-bottom-margin--3xs">Строительный объем</h3><p>362&nbsp;м<sup>3</sup></p><h3 class="u-bottom-margin--3xs">Площадь очищенной территории</h3><p>300&nbsp;м<sup>2</sup></p><h3 class="u-bottom-margin--3xs">Количество образовавшегося строительного мусора</h3><p>259,8&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество образовавшегося металлолома</h3><p>10,4&nbsp;т</p><h3 class="u-bottom-margin--3xs">Количество задействованной техники и&nbsp;ее наборка</h3><ul><li>Экскаватор&nbsp;&mdash; 2;</li><li>Экскаватор с&nbsp;длинной стрелой&nbsp;&mdash; 1;</li><li>Бульдозер&nbsp;&mdash; 1;</li><li>Фронтальный погрузчик&nbsp;&mdash; 1;</li><li>Самосвалы&nbsp;&mdash; 6;</li><li>Ломовоз&nbsp;&mdash; 2.</li></ul><h3 class="u-bottom-margin--3xs">Количество задействованного персонала</h3><p>12</p>'
    // },
    // {
    //     coords: [55.750484, 37.539527],
    //     name: 'Здание ЦАТК демонтировано',
    //     imageLink: '/images/ya-map/previewImage.png',
    //     content: ''
    // },
    // {
    //     coords: [55.700484, 37.599527],
    //     name: 'Очистка территории, снос зданий, вывоз мусора, работы закончены',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: ''
    // },
    // {
    //     coords: [55.720484, 37.549527],
    //     name: 'Склад красок и химикатов, демонтировано',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: ''
    // }
]

export const inProcess = [
    // {
    //     coords: [55.822931, 37.500961],
    //     name: 'Склад вм рудника каерканский',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: ''
    // }
]

export const comingSoon = [
    // {
    //     coords: [55.827585, 37.660793],
    //     name: 'Цементный завод, санитарная очистка',
    //     imageLink: '/images/ya-map/sad.jpg',
    //     content: ''
    // }
]
