/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/S': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32280)"/><path pid="2" d="M36.053 40.3c-3.075 0-5.3-1.075-6.775-2.65l1.625-2.25c1.15 1.225 2.95 2.3 5.275 2.3 2.4 0 3.35-1.175 3.35-2.275 0-3.5-9.725-1.325-9.725-7.475 0-2.75 2.425-4.875 6.075-4.875 2.6 0 4.7.825 6.25 2.325l-1.675 2.175c-1.3-1.3-3.075-1.9-4.825-1.9-1.725 0-2.825.825-2.825 2.075 0 3.1 9.7 1.175 9.7 7.4 0 2.775-1.95 5.15-6.45 5.15z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32280" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
