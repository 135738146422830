/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pin-nn': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M29.853 60l17.573-17.574a24.852 24.852 0 10-35.147 0L29.853 60z" _fill="#0077C8"/><path pid="1" d="M43.224 31.512a10.157 10.157 0 01-4.433 2.611V14.464a10.165 10.165 0 014.433 2.608 10.15 10.15 0 012.988 7.22c0 2.73-1.061 5.293-2.988 7.22zm-20.222-6.349V14.657l12.997 8.77V33.93l-12.997-8.767zm-2.788 8.96a10.127 10.127 0 01-4.434-2.61 10.139 10.139 0 01-2.991-7.22c0-2.727 1.063-5.293 2.991-7.22a10.16 10.16 0 014.434-2.609v19.66zm15.785-22.83v8.769l-12.997-8.768c-7.18 0-13.002 5.82-13.002 13 0 7.181 5.821 12.999 13.002 12.999v-8.769l12.997 8.769c7.182 0 13.003-5.818 13.003-13 0-7.179-5.82-13-13.003-13z" _fill="#fff"/>'
  }
})
