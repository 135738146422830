/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'windmill': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 5.375a.625.625 0 00-.625.625v12.76c-1.643-3.199-4.749-5.385-8.375-5.385-5.386 0-9.625 4.824-9.625 10.625 0 .345.28.625.625.625h12.76c-3.199 1.643-5.385 4.749-5.385 8.375 0 5.386 4.824 9.625 10.625 9.625.345 0 .625-.28.625-.625V29.24c1.643 3.199 4.749 5.385 8.375 5.385 5.386 0 9.625-4.824 9.625-10.625a.625.625 0 00-.625-.625H29.24c3.199-1.643 5.385-4.749 5.385-8.375 0-5.386-4.824-9.625-10.625-9.625zM33.375 15c0 4.39-3.81 8.069-8.75 8.357V6.643c4.94.288 8.75 3.967 8.75 8.357zM33 33.375c-4.39 0-8.069-3.81-8.357-8.75h16.714c-.288 4.94-3.966 8.75-8.357 8.75zm-9.625-8.732c-4.94.288-8.75 3.966-8.75 8.357 0 4.39 3.81 8.069 8.75 8.357V24.643zM15 14.625c4.39 0 8.069 3.81 8.357 8.75H6.643c.288-4.94 3.967-8.75 8.357-8.75z" _fill="#0077C8"/>'
  }
})
