import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

export const usePageAnimation = (container) => {
    if (!container) {
        return
    }
    // const {$gsap: gsap, $ScrollTrigger: ScrollTrigger, $SplitText: SplitText} = useNuxtApp()

    const transformedEls = container.querySelectorAll('[data-scroll-translate]')
    const scaledEls = container.querySelectorAll('[data-scroll-scale]')
    const revealedEls = container.querySelectorAll('[data-scroll-reveal]')
    const cards = container.querySelectorAll('[data-scroll-card]')
    const headings = container.querySelectorAll('[data-scroll-heading]')

    // transform and reveal element from bottom:
    // set 'data-scroll-translate="direction"' attribute to an element to trigger animation.
    // It can have "bottom/top/left/right value"
    transformedEls.forEach((el) => {
        const direction = el.dataset.scrollTranslate
        let yOffset
        let xOffset
        switch (direction) {
            case 'bottom':
                yOffset = '-10%'
                break
            case 'top':
                yOffset = '10%'
                break
            case 'left':
                xOffset = '-10%'
                break
            case 'right':
                xOffset = '10%'
                break
            default:
                yOffset = '-10%'
                xOffset = ''
        }
        gsap.set(el, {
            autoAlpha: 0,
            y: yOffset,
            x: xOffset
        })
        gsap.to(el, {
            scrollTrigger: {
                trigger: el,
                start: '10% 80%',
                toggleActions: 'play none none',
                // markers: true
            },
            autoAlpha: 1,
            y: 0,
            x: 0,
            duration: 0.6,
            ease: 'linear'
        })
    })

    // scale element: set 'data-scroll-scale' attribute to an element to trigger animation
    scaledEls.forEach((el) => {
        gsap.set(el, {
            scale: 0.95
        })
        gsap.to(el, {
            scrollTrigger: {
                trigger: el,
                start: 'center 80%',
                toggleActions: 'play none none',
                // markers: true
            },
            scale: 1,
            duration: 0.6,
            ease: 'back.out'
        })
    })

    // reveal element from opacity: 0: set 'data-scroll-reveal' attribute to an element to trigger animation
    revealedEls.forEach((el) => {
        gsap.set(el, { autoAlpha: 0 })
        gsap.to(el, {
            scrollTrigger: {
                trigger: el,
                start: 'center 90%',
                toggleActions: 'play none none',
                // markers: true
            },
            autoAlpha: 1,
            duration: 0.3,
            ease: 'power3.out'
        })
    })

    // animate cards: set 'data-scroll-card' attribute to a card element to trigger animation
    cards.forEach((card) => {
        gsap.set(card, { opacity: 0, y: '40%', scale: 0.95 })
    })

    ScrollTrigger.batch('[data-scroll-card]', {
        start: 'top 80%',
        onEnter: (batch, index) => {
            gsap.to(batch, {
                opacity: 1,
                scale: 1,
                y: 0,
                stagger: 0.1,
                delay: index * 0.15
            })
        },
        onLeaveBack: (batch, index) => {
            gsap.to(batch, {
                opacity: 0,
                scale: 0.95,
                y: '40%',
                stagger: 0.1,
                delay: index * 0.15
            })
        },
        ease: 'power3.out',
        // markers: true
    })

    // animate text lines: set 'data-scroll-heading' attribute to a text element to trigger animation
    headings.forEach((el) => {
        const split = new SplitText(el, {
            type: 'lines,words,chars',
            linesClass: 'u-overflow-hidden'
        })
        gsap.fromTo(
            split.words,
            {
                y: '100%',
                autoAlpha: 0
            },
            {
                scrollTrigger: {
                    trigger: el,
                    toggleActions: 'restart pause resume',
                    start: 'top 90%'
                    // markers: true
                },
                ease: 'circ.out',
                y: 0,
                autoAlpha: 1,
                stagger: 0.3
            }
        )
    })
}
