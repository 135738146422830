<template>
<div class="irp-presentations" v-if="data">
    <z-caption
        href="/investors/presentations-and-briefings/"
        size="3xl"
        decor-right
    >
        <vue-raw :raw="text.presentation"/>
    </z-caption>
    <div class="u-top-padding--s u-bottom-padding--m">
        <z-filelist class="row" size="l">
            <z-filelist-item
                class="col-default-4 col-v-tablet-6 col-mobile-12"
                v-if="item.mlDocs.link"
                v-for="(item, index) in data"
                :key="index"
                :name="item.name"
                :href="item.mlDocs.link"
                icon-name="file/pdf"
                :file-size="item.mlDocs.size"
            />
        </z-filelist>
    </div>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'presentation',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            presentationIcon: {
                presentation: 'file/text-pdf',
                interview: 'mic'
            },
            text: {
                presentation: localize({
                    ru: 'Презентации',
                    en: 'Presentations'
                }),
                tags: {
                    interview: localize({
                        ru: 'Выступления',
                        en: 'Briefings'
                    }),
                    presentation: localize({
                        ru: 'Презентации',
                        en: 'Presentations'
                    })
                }
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
