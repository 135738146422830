<template>
<div class="annual-reports" v-if="data">
    <z-tabs size="m" @open-tab="onTabChange" v-if="data.tabs">
        <template v-slot:label>
            <z-tabs-label
                v-for="(item, index) in data.tabs"
                :key="index"
                :id="item.value"
            >{{ item.name }}</z-tabs-label>
        </template>
    </z-tabs>

    <div class="annual-reports__content u-top-padding--m" v-if="currentReports">
        <annual-report-block
            :props-data="currentReports"
            col-class="col-default-4 col-tablet-6 col-mobile-12"
        />
        <z-preloader class="annual-reports__preloader" v-if="isLoading"/>
    </div>
</div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import getData from '@/api/annual-reports'

export default {
    name: 'annual-reports',
    mixins: [mixinDevice],
    data () {
        return {
            data: null,
            isLoading: false,
            currentReports: null,
            additionalReports: null,
            text: {
                'annual-report': localize({
                    ru: 'Годовой отчет',
                    en: 'Annual report'
                }),
                brochure: localize({
                    ru: 'Брошюра',
                    en: 'Brochure'
                }),
                csr: localize({
                    ru: 'Отчет об устойчивом развитии',
                    en: 'Sustainability report'
                }),
                'white-book': localize({
                    ru: 'Белая книга',
                    en: 'White paper'
                }),
                'corporate-social-politic': localize({
                    ru: 'Корпоративная социальная политика',
                    en: 'Сorporate social politic'
                })
            }
        }
    },
    created () {
        if (this.$root.app.components['annual-reports']) {
            this.data = this.$root.app.components['annual-reports']
            this.currentReports = this.$root.app.components['annual-reports'].report
            this.additionalReports = this.$root.app.components['annual-reports'].report['additional-reports']
        }
    },
    methods: {
        onTabChange (tab) {
            const year = tab.replace('tabs-', '')
            this.isLoading = true

            getData({ year: year })
                .then(res => {
                    this.isLoading = false
                    this.currentReports = res.report
                    this.additionalReports = res.report['additional-reports']
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss">
.annual-reports {
    &__content {
        position: relative;

        .report-block {
            width: 100%;
            flex-shrink: 0;
        }

        &::-webkit-scrollbar {
            height: 8px;
            background: $token-colors-gray-10;
        }

        &::-webkit-scrollbar-thumb {
            background: $token-colors-gray-20;
        }
    }

    &__preloader {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($token-colors-white, 0.7);
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .report-block {
        flex-direction: row;
        gap: $token-spacers-xs;
        max-width: 360px;

        @include breakpoint (v-tablet) {
            flex-direction: column;
            gap: 0;
            max-width: 210px;
        }

        &__links {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
</style>
