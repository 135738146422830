<template>
    <div :class="['tag', classObject]"
        @click="onClickHandler($event)"
        @mouseenter="addIconMouseEnter"
        @mouseleave="changeIconMouseLeave"
    >
        <div class="tag__title">{{ name }}</div>
        <div class="tag__append-inner" v-if="interactive">
            <z-icon
                v-if="isSelected"
                width="100%"
                height="100%"
                :name="iconName"
                :color="disabled ? '#858DA6' : '#ffffff'"
            ></z-icon>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Tag',
    auth: false,
    props: {
        theme: {
            type: String,
            default: 'blue',
            validator: prop => [
                'blue',
                'light-blue',
                'event-green',
                'event-purple',
                'event-red',
                'event-blue',
                'event-orange',
                'event-light-green',
                'event-light-blue'
            ].includes(prop)
        },
        id: String,
        name: String,
        size: {
            type: String,
            validator: prop => ['m', 's', 'xs'].includes(prop),
            default: 'm'
        },
        disabled: Boolean,
        decoration: Boolean,
        selected: Boolean,
        interactive: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            isSelected: false,
            iconName: 'check'
        }
    },
    watch: {
        selected () {
            this.isSelected = this.selected
        }
    },
    computed: {
        classObject () {
            return {
                'tag--selected': this.selected,
                'tag--disabled': this.disabled,
                'tag--decor': this.decoration,
                'tag--active': this.isSelected,
                'tag--non-interactive': !this.interactive,
                [`tag--theme-${this.theme}`]: this.theme,
                [`tag--size-${this.size}`]: this.size
            }
        }
    },
    methods: {
        onClickHandler (evt) {
            if (!this.disabled) {
                this.isSelected = !this.isSelected
                this.send()
            }
        },
        clear (inner = false) {
            this.isSelected = false
            if (inner) {
                this.isSelected = false
                return
            }
            this.send()
        },
        addIconMouseEnter () {
            this.iconName = 'close'
        },
        changeIconMouseLeave () {
            this.iconName = 'check'
        },
        send () {
            this.$emit('change', {
                name: this.name,
                value: this.isSelected,
                id: this.id
            })
        }
    },
    created () {
        if (typeof this.selected !== 'undefined') {
            this.isSelected = this.selected
        }
    },
    beforeDestroy () {
        this.iconName = 'check'
    }
}
</script>
<style lang="scss" src="./index.scss"></style>
