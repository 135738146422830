/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cu': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" d="M9.5 18.24A8.74 8.74 0 109.5.76a8.74 8.74 0 000 17.48z" _fill="#D9A85F" _stroke="#D9A85F" stroke-width="1.5" stroke-miterlimit="10"/><path pid="1" d="M6.979 12.788c-2.027 0-3.598-1.418-3.598-3.496 0-2.077 1.571-3.496 3.598-3.496 1.49 0 2.35.81 2.827 1.652l-1.236.608c-.284-.547-.892-.983-1.591-.983-1.226 0-2.118.942-2.118 2.22 0 1.276.892 2.219 2.118 2.219.699 0 1.307-.426 1.59-.984l1.237.598c-.486.841-1.338 1.662-2.827 1.662zm8.345-.121h-1.287v-.618c-.335.375-.922.74-1.723.74-1.074 0-1.58-.588-1.58-1.541V7.772h1.286v2.97c0 .678.355.901.902.901a1.44 1.44 0 001.115-.557V7.772h1.287v4.895z" _fill="#fff"/>'
  }
})
