/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'time': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 1.55C6.147 1.55 1.382 6.222 1.382 12c0 5.778 4.765 10.448 10.618 10.448 5.853 0 10.619-4.67 10.619-10.449 0-5.778-4.766-10.448-10.619-10.448zM2.582 12c0-5.097 4.209-9.25 9.418-9.25 5.21 0 9.419 4.153 9.419 9.25 0 5.096-4.21 9.248-9.419 9.248-5.21 0-9.418-4.152-9.418-9.249zm9.848-5.626a.6.6 0 10-1.2 0v7.471a.6.6 0 00.6.6h5.094a.6.6 0 000-1.2H12.43V6.374z" _fill="#0077C8"/>'
  }
})
