<template>
    <div class="updates">
        <updates-filter class="u-bottom-margin--l" :filters-data="filtersData" @update-filters="filtersHandler"/>

        <download-excel-btn
            class="u-bottom-margin--s"
            urlSections="updates"
            :filters="filters"
            :disabled="!items.length"
        />

        <template v-if="items.length">
            <updates-list :items="items"/>
            <z-pagination
                v-if="nav"
                :data="{
                    currentPage: nav.current,
                    totalPages: nav.total,
                    pageRange: 1
                }"
                @change-page="pageHandler"
            />
        </template>
        <template v-else-if="loading">
            <z-preloader v-if="loading"/>
        </template>
        <template v-else>
            <z-not-found/>
        </template>
    </div>
</template>

<script>
import UpdatesFilter from '@/views/UpdatesPage/components/UpdatesFilter.vue'
import UpdatesList from '@/views/UpdatesPage/components/UpdatesList.vue'
import {getUpdates} from '@/api/updates'
import {debounce} from 'throttle-debounce'
import {showNotyfications} from '@/utils/notifications'

export default {
    name: 'UpdatesPage',
    components: {
        UpdatesFilter,
        UpdatesList
    },
    data () {
        return {
            filtersData: this.$root.app?.components?.updates?.filters || [],
            items: this.$root.app?.components?.updates?.items || [],
            nav: this.$root.app?.components?.updates?.nav || null,
            page: 1,
            isMore: false,
            loading: false,
            filters: null
        }
    },
    methods: {
        send: debounce(300, function (params) {
            this.loading = true
            getUpdates(params)
                .then((response) => {
                    this.filtersData = response.filters
                    this.nav = response.nav
                    this.page = this.nav.current

                    if (this.isMore) {
                        this.items = [...this.items, ...response.items]
                        this.isMore = false
                    } else {
                        this.items = response.items
                    }
                })
                .catch((err) => {
                    showNotyfications('error', err)
                })
                .finally(() => {
                    this.loading = false
                })
        }),
        filtersHandler (payload) {
            this.page = 1
            this.filters = payload
            this.send({
                ...this.filters,
                page: this.page
            })
        },
        pageHandler (payload) {
            this.isMore = payload.type === 'load-more'
            this.page = payload.page
            this.send({
                ...this.filters,
                page: this.page
            })
        }
    }
}
</script>

<style lang="scss">
.updates {
    max-width: 1360px;
}
</style>
