/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round_pdf': {
    width: 62,
    height: 36,
    viewBox: '0 0 62 36',
    data: '<rect pid="0" x=".5" y=".5" width="61" height="35" rx="17.5" _stroke="#0077C8"/><path pid="1" d="M19.073 23h-1.638v-9.338h4.102c1.96 0 3.052 1.344 3.052 2.926s-1.106 2.926-3.052 2.926h-2.464V23zm2.24-4.928c.924 0 1.596-.588 1.596-1.484s-.672-1.484-1.596-1.484h-2.24v2.968h2.24zM30.824 23h-3.472v-9.338h3.472c2.912 0 4.886 1.932 4.886 4.676S33.736 23 30.824 23zm0-1.442c2.03 0 3.206-1.456 3.206-3.22 0-1.806-1.12-3.234-3.206-3.234H28.99v6.454h1.834zM40.33 23h-1.638v-9.338h6.398v1.442h-4.76v2.422h4.662v1.442H40.33V23z" _fill="#0077C8"/>'
  }
})
