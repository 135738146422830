<template>
    <a class="tl-link" :href="href" target="_blank" v-if="href">
        <z-icon
            name="round-arrow"
            width="32"
            height="32"
            dir="right"
        />
        <span class="tl-link__text">
            Подробнее
        </span>
    </a>
</template>

<script>
export default {
    name: 'tl-link',
    props: {
        href: String
    }
}
</script>

<style lang="scss">
.tl-link {
    $parent: &;

    margin-top: $token-spacers-s;
    display: flex;
    align-items: center;
    gap: $token-spacers-2-xs;
    text-decoration: none;
    width: max-content;
    margin-right: auto;

    @include breakpoint (v-tablet) {
        margin-top: $token-spacers-xs;
    }

    @include breakpoint (mobile) {
        margin-top: 20px;
    }

    &__text {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: $token-colors-blue;
        display: none;

        @include breakpoint (v-tablet) {
            display: block;
        }
    }

    &:hover {
        #{$parent}__text {
            display: block;
        }
    }
}
</style>
