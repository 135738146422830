/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reanimobiles': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<g clip-path="url(#clip0_3962_22712)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.406 21.656h-.75V59.72h7.075a6.364 6.364 0 002.223 4.125H10v1.5h67.5v-1.5h-7.142a6.364 6.364 0 002.223-4.125h4.263V49.477l-.27-.225-4.03-3.359-2.754-11.012-.142-.569H55.656V42.75H37.47V30.094H19.094V42.75h-6.938V23.156H58.47v6.938H55v1.5h4.969v-9.938H11.406zm.75 22.594v13.969h5.575a6.376 6.376 0 0112.663 0h25.262V44.25H37.47V48.469H19.094V44.25h-6.938zm49.986 19.594H28.17a6.364 6.364 0 002.223-4.125h29.525a6.364 6.364 0 002.223 4.125zm-33.205-4.875a4.875 4.875 0 11-9.75 0 4.875 4.875 0 019.75 0zm42.188 0a4.875 4.875 0 11-9.75 0 4.875 4.875 0 019.75 0zm4.219-.75H72.58a6.376 6.376 0 00-12.662 0h-2.763V47.062h14.447l3.74 3.117v8.04zm-4.43-12.657l-2.437-9.75h-11.32v9.75h13.757zm-50.32 1.407V31.594h15.375v15.375H20.594zm6.937-13.313v4.875h-4.875v1.5h4.875v4.875h1.5v-4.875h4.875v-1.5h-4.875v-4.875h-1.5zm36.657-5.625a2.07 2.07 0 012.062-2.062 2.07 2.07 0 012.063 2.062v2.063h-4.126V28.03zm2.062-3.562a3.57 3.57 0 00-3.563 3.562v3.563h7.126V28.03a3.57 3.57 0 00-3.563-3.562z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3962_22712"><path pid="1" _fill="#fff" transform="translate(10 21)" d="M0 0h67.5v45H0z"/></clipPath></defs>'
  }
})
