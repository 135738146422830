<template>
    <div
        class="navigation-left"
        v-if="data.items.length"
        role="navigation"
        aria-label="Left navigation"
    >
        <div class="navigation-left__list">
            <div
                class="navigation-left__item"
                v-for="(item, index) in data.items"
                :key="index"
                :class="{'is-active': item.active}"
            >
                <a
                    :href="item.link"
                    :title="item.name"
                    v-html="item.name"
                ></a>
                <div
                    v-if="item.items"
                    class="navigation-left__item"
                    v-for="(item, index) in item.items"
                    :key="index"
                    :class="{'is-active': item.active}"
                >
                    <a
                        :href="item.link"
                        :title="item.name"
                        v-html="item.name"
                    ></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'navigation-left',
    data () {
        return {
            data: this.$root.app.components['navigation-left']
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
