/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Ir': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32274)"/><path pid="2" d="M33.194 40H30.27V23.325h2.925V40zm5.97 0h-2.625V27.925h2.625V29.7c.9-1.15 2.35-2.05 3.925-2.05v2.6a3.87 3.87 0 00-.825-.075c-1.1 0-2.575.75-3.1 1.6V40z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32274" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
