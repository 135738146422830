/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quote': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M18.871 44.258v-8.452c-5.42 0-9.871-4.451-9.871-9.935C9 20.387 13.452 16 18.871 16c5.42 0 9.87 4.452 9.87 9.871 0 1.613-.386 3.097-1.031 4.452-2.516 6.774-8.839 13.935-8.839 13.935zm31.097-13.935C50.613 28.968 51 27.483 51 25.87 51 20.451 46.548 16 41.129 16c-5.42 0-9.935 4.452-9.935 9.871 0 5.42 4.451 9.87 9.87 9.87v8.453c.065.064 6.323-7.097 8.904-13.871z" _fill="#ADD1EA"/>'
  }
})
