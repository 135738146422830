/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-rar': {
    width: 35,
    height: 22,
    viewBox: '0 0 35 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.556 16.713H9.32l-3.24-4.896c1.638-.144 3.114-1.332 3.114-3.492 0-2.214-1.566-3.618-3.762-3.618H.608v12.006h1.494v-4.77h2.394l3.06 4.77zm.09-8.388c0 1.35-.972 2.304-2.394 2.304h-3.15v-4.59h3.15c1.422 0 2.394.936 2.394 2.286zm12.481 8.388h1.71L16.995 4.707h-1.854l-4.824 12.006h1.71l1.062-2.664h5.976l1.062 2.664zm-4.05-10.458l2.538 6.462h-5.094l2.556-6.462zm14.278 10.458h1.764l-3.24-4.896c1.638-.144 3.114-1.332 3.114-3.492 0-2.214-1.566-3.618-3.762-3.618h-4.824v12.006H24.9v-4.77h2.394l3.06 4.77zm.09-8.388c0 1.35-.972 2.304-2.394 2.304H24.9v-4.59h3.15c1.422 0 2.394.936 2.394 2.286z" _fill="#0077C8"/>'
  }
})
