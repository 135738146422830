<template>
    <form @submit.prevent="submitForm">
        <div class="row">
            <div class="col-default-6 col-tablet-12">
                <p class="contacts-form__select-title text-size-s">
                    {{ text.category }}
                </p>

                <z-select
                    v-model="formData.category"
                    :data="categoryOptions"
                    ref="category"
                    required
                    :value="defaultValue"
                    :deleteSelected="deleteSelected"
                    class="contacts-form__input"
                ></z-select>

                <z-input
                    v-model="formData.fio"
                    type="text"
                    name="fio"
                    ref="fio"
                    :title="text.name"
                    class="contacts-form__input"
                ></z-input>

                <z-input
                    v-model="formData.email"
                    type="email"
                    ref="email"
                    name="email"
                    :title="text.email"
                    class="contacts-form__input"
                ></z-input>

                <div class="contacts-form__flex-container contacts-form__flex-container--phone">
                    <z-input
                        v-model="formData.phone"
                        ref="phone"
                        type="tel"
                        name="phone"
                        :title="text.phone"
                        class="contacts-form__input contacts-form__input--phone"
                    ></z-input>

                    <z-input
                        v-model="formData.allContactsPhoneAdd"
                        ref="phoneExtra"
                        name="phoneExtra"
                        :title="text.phoneExtra"
                        :placeholder="text.phoneExtra"
                        class="contacts-form__input contacts-form__input--extra-phone"
                    ></z-input>
                </div>

                <p class="contacts-form__text">{{ text.notificationCaptchaEmpty }}</p>

                <div class="form-captcha">
                    <div class="form-captcha__wrapper">
                        <div class="form-captcha__captcha">
                            <img
                                class="form-captcha__img"
                                v-if="isUpdatedCapcha"
                                :src="'/api/captcha/?' + capchaSalt"
                                width="100"
                                height="50"
                                alt=""
                            />
                            <div v-else class="form-captcha__img"></div>

                            <z-button
                                class="form-captcha__reset-btn"
                                type="button"
                                rounded
                                kind="secondary"
                                @click="updateCapcha"
                            >
                                <z-icon name="refresh" width="24" height="24"></z-icon>
                            </z-button>
                        </div>
                        <z-input
                            v-model="captcha"
                            type="text"
                            name="captcha"
                            ref="captcha"
                            required
                            class="form-captcha__input"
                        ></z-input>
                    </div>
                </div>

            </div>

            <div class="col-default-6 col-tablet-12">
                <p class="contacts-form__select-title text-size-s">
                    {{ text.message }}
                </p>
                <z-textarea
                    v-model="formData.msg"
                    name="msg"
                    required
                    ref="msg"
                    class="contacts-form__input"
                ></z-textarea>

                <div class="contacts-form__flex-container">
                    <z-checkbox
                        name="policy"
                        ref="policy"
                        v-model="isChecked"
                        class="contacts-form__checkbox"
                        required
                        :data="[{ value: '1' }]"
                    >
                        <template #text>
                            <p weight="400" class="text-size-xs">{{ text.policy }} </p>
                        </template>
                    </z-checkbox>
                </div>

                <z-button>
                    {{ text.button }}
                </z-button>
            </div>
        </div>
    </form>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendMainContactsForm } from '@/api/main-contacts-form.js'
import { sendCaptcha } from '@/api/captcha.js'
import { text, categoryOptions } from './constans'

export default {
    name: 'contacts-form',
    props: {
        fileId: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            isChecked: [],
            isLoading: false,
            defaultValue: '',
            formData: {
                form: 'main-contacts',
                pageLink: window.location.href,
                fio: '',
                category: '',
                phone: '',
                allContactsPhoneAdd: '',
                email: '',
                msg: '',
                phrase: this.captcha,
                ajax_call: 'Y'
            },
            text,
            categoryOptions,
            capchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCapcha: true,
            deleteSelected: false
        }
    },

    mounted () {
        this.formData.category = this.categoryOptions.find((x) => x.selected === true)?.id
    },

    methods: {
        validateInputFormat () {
            if (this.formData.phone || this.formData.email) {
                const requiredFields = ['email', 'phone', 'phoneExtra']
                let isValid = true

                requiredFields.forEach((item) => {
                    const field = this.$refs[item]
                    if (field) {
                        field.validate()
                        if (field.error) isValid = false
                    }
                })
                return isValid
            }

            return true
        },

        validateRequiredInputs () {
            const requiredFields = ['category', 'policy', 'captcha', 'msg']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },

        changeTextarea (data) {
            this.formData[data?.name] = data?.value
        },

        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },

        async submitForm () {
            if (this.isLoading) {
                return
            }

            const isValidInputFormat = this.validateInputFormat()

            if (!isValidInputFormat) {
                showNotyfications(this.text.notificationValidateError, { type: 'error' })
                return
            }

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }
                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await sendMainContactsForm(data)

                        if (formResponse.success === true) {
                            showNotyfications(this.text.notificationSuccess, { type: 'success' })

                            this.$emit('closeModal')

                            this.defaultValue = ''
                            this.formData.fio = ''
                            this.formData.phone = ''
                            this.formData.allContactsPhoneAdd = ''
                            this.formData.email = ''
                            this.captcha = ''
                            this.formData.msg = ''
                            this.deleteSelected = true
                            this.isChecked = []
                        } else {
                            showNotyfications(this.text.notificationError, { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.contacts-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .z-site-selector__active-site {
        display: none;
    }

    &__container {
        padding-right: 0;
        padding-left: 0;
    }

    &__select-title {
        margin-bottom: 20px !important;
        font-weight: 400 !important;
    }

    &__input {
        margin-bottom: $token-spacers-s;

        &--phone {
            max-width: 386px;
            margin-right: 12px;
        }

        &--extra-phone {
            max-width: 108px;

            .z-input__title {
                opacity: 0;
                visibility: hidden;

                @include breakpoint (mobile) {
                    display: none;
                }
            }

            &.is-filled,
            &.is-focused {

                .z-input__placeholder {
                    display: none;
                }
            }
        }
    }

    &__text {
        margin-bottom: 20px;
        font-size: 16px;
    }

    &__warning {
        font-size: 13px;
        color: $token-colors-gray-60;
        line-height: 1.4;

        &-list {
            font-size: 12px;
            margin-bottom: 40px !important;
        }
    }

    &__flex-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 0;

        &--phone {
            align-items: flex-start;
            flex-wrap: nowrap;

            @include breakpoint (mobile) {
                flex-wrap: wrap;
            }
        }
    }

    &__captcha-img {
        margin: 0 16px 0 0;
        width: 100px;
        height: 50px;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-button {
        margin: 0 8px 0 0;

        @media screen and (max-width: 512px) {
            margin: 0 16px 16px 0;
        }
    }

    &__captcha-input {
        &.is-errored {
            margin-top: 21px;

            .contacts-form__text {
                margin-bottom: 0;

                @media screen and (max-width: 511px) {
                    margin-bottom: 20px;
                }
            }
        }

        .z-input__title {
            margin-bottom: 0 !important;
        }

        @media screen and (max-width: 495px) {
            padding: 0 !important;
        }
    }

    &__checkbox {
        margin-bottom: $token-spacers-s;
        color: $token-colors-gray-50 !important;

        .z-checkbox__text {
            font-size: 13px !important;
        }
    }
}
</style>
