/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ore': {
    width: 45,
    height: 44,
    viewBox: '0 0 45 44',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.94 7.27a.978.978 0 01.58-.269.98.98 0 01.093 0h9.775a.978.978 0 01.962.84l.977 6.843a.977.977 0 01-.962 1.115h-11.73a.978.978 0 01-.962-1.115l.977-6.843a.977.977 0 01.29-.57zm9.608 1.686h-8.095l-.703 4.888h9.5l-.702-4.888zM9.12 17.046a.978.978 0 01.58-.27 1 1 0 01.092 0h9.775a.978.978 0 01.963.84l.977 6.843a.978.978 0 01-.962 1.115H8.815a.977.977 0 01-.963-1.115l.978-6.843a.978.978 0 01.29-.57zm15.64 0a.977.977 0 01.581-.27h9.867a.978.978 0 01.962.84l.978 6.843a.978.978 0 01-.963 1.115h-11.73a.977.977 0 01-.962-1.115l.977-6.843a.978.978 0 01.29-.57zm-6.033 1.685h-8.095L9.93 23.62h9.5l-.703-4.888zm15.64 0h-8.094l-.703 4.888h9.5l-.702-4.888zm-32.09 8.09a.978.978 0 01.58-.27 1 1 0 01.093 0h9.775a.977.977 0 01.962.84l.978 6.843a.978.978 0 01-.963 1.115H1.972a.977.977 0 01-.962-1.115l.977-6.842a.978.978 0 01.29-.571zm14.663 0a.977.977 0 01.58-.27h9.868a.977.977 0 01.962.84l.977 6.843a.978.978 0 01-.962 1.115h-11.73a.977.977 0 01-.962-1.115l.977-6.842a.977.977 0 01.29-.571zm14.663 0a.977.977 0 01.58-.27h9.868a.977.977 0 01.962.84l.977 6.843a.978.978 0 01-.962 1.115h-11.73a.977.977 0 01-.962-1.115l.977-6.842a.977.977 0 01.29-.571zm-19.718 1.686H3.79l-.703 4.887h9.5l-.702-4.887zm14.663 0h-8.095l-.703 4.887h9.5l-.702-4.887zm14.663 0h-8.096l-.702 4.887h9.5l-.702-4.887zM3 37.1a2.9 2.9 0 000 5.8h39a2.9 2.9 0 000-5.8H3zM1.9 40A1.1 1.1 0 013 38.9h39a1.1 1.1 0 010 2.2H3A1.1 1.1 0 011.9 40z" _fill="#0077C8"/>'
  }
})
