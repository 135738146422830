import Vue from 'vue'

export default Vue.component('slider-thumbs-container', {
    props: ['data'],
    render (h) {
        return h('div', {
            attrs: {
                class: 'slider-thumbs-container'
            }
        }, this.data)
    }
})
