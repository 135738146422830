/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cn/plan': {
    width: 58,
    height: 58,
    viewBox: '0 0 58 58',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.5 53H31v-8.25h-1.5v-1.5h6.75c2.1 0 3.75-1.65 3.75-3.75V32h4.5v-.6l-2.03-4.397-20.545-3.253H20.5v-1.5h1.65l19.556 3.096L40 21.65v-.15c0-2.268-.51-4.423-1.422-6.356L22.075 17.75H20.5v-1.5h1.425l15.9-2.504C35.19 9.41 30.419 6.5 25 6.5c-3.6 0-7.05 1.275-9.825 3.675l-.975-1.2C17.2 6.425 21.025 5 25 5c6.22 0 11.63 3.418 14.443 8.491L50.5 11.75H52v1.5h-1.425l-10.438 1.648A16.474 16.474 0 0141.5 21.35l1.974 4.276 7.101 1.124H52v1.5h-1.65l-6.112-.968L46 31.1v2.4h-4.5v6a5.218 5.218 0 01-5.25 5.25H32.5V53zm-15-29.25H19v-7.8l-4.2-4.2h-2.1l-4.2 4.2v7.8H10v-7.2l3.3-3.3h.9l2.25 2.25-1.95 1.95v6.3H16v-5.7l1.5-1.5v7.2zM7 25.25h13.5v1.5H19v1.5h1.5v1.5H19V47h1.5v6H19v-4.5H7V47h10.5v-1.5H10V44h7.5v-1.5H10V41h7.5v-1.5H10V38h7.5v-8.25H7v-1.5h1.5v-1.5H7v-1.5zm3 1.5v1.5h1.5v-1.5H10zm7.5 1.5H16v-1.5h1.5v1.5zm-3-1.5v1.5H13v-1.5h1.5zM16 50h1.5v1.5H16V50zm-1.5 0H13v1.5h1.5V50zM10 50h1.5v1.5H10V50z" _fill="#ADD1EA"/>'
  }
})
