<template>
  <div class="z-select-multi-wrapper">
    <div class="z-select-multi__title-container">
        <p class="z-select-multi__title" v-if="title" v-html="title"></p>
    </div>
    <div
        :class="[
            'z-select-multi',
            {
                'is-opened': opened,
                'is-errored': isValid === false,
                'is-filled': selected.length,
                'is-valid': isValid,
                'is-disabled': isDisabled
            }
        ]"
    >
        <div class="z-select-multi__container" @click.self="opened = !opened">
            <span
                class="z-select-multi__placeholder"
                v-show="placeholder"
                v-html="placeholder"
            ></span>
            <div class="z-select-multi__wrap">
                <div class="z-select-multi__items" ref="items">
                    <span
                        class="z-select-multi__selected"
                        v-for="(item, index) in selected"
                        :key="index"
                    >
                        <span v-html="item.text"></span>
                        <span
                            class="z-select-multi__delete"
                            @click.prevent="changeSelected(item)"
                        ></span>
                    </span>
                </div>
                <span class="z-select-multi__count" v-if="selected.length">
                    {{ selected.length }}
                </span>
            </div>
            <span
                class="z-select-multi__clear"
                v-if="selected.length && clearable"
                @click.self="clearSelected"
            ></span>
            <span class="z-select-multi__arrow"></span>
        </div>
        <div class="z-select-multi__dropdown" v-show="opened">
            <div v-if="withSearch" class="z-select-multi__input-wrapper">
                <z-input v-model="search" name="search" icon-name="magnifier"/>
            </div>
            <ul
                class="z-select-multi__options"
                v-for="(group, groupIndex) in groupedOptions"
                :key="groupIndex"
            >
                <li>
                    <span
                        class="z-select-multi__caption"
                        v-html="group.name"
                        v-if="group.name"
                    ></span>
                    <ul
                        v-if="group.items && group.items.length"
                    >
                        <li
                            class="z-select-multi__option"
                            v-for="option in group.items"
                            :class="buildClass(option)"
                            @click="changeSelected(option)"
                            v-html="option.text"
                            :key="option.id"
                        ></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <span
        :class="['z-select-multi__error', errorClass]"
        v-if="required && error && !selected.length"
    >
        {{ text.error[$root.lang] }}
    </span>
  </div>
</template>

<script>
export default {
    name: 'z-select-multi',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        name: String,
        placeholder: String,
        required: Boolean,
        value: Array,
        isDisabled: {
            type: Boolean
        },
        errorClass: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: true
        },
        withSearch: {
            type: Boolean,
            default: false
        },
        title: String
    },
    data () {
        return {
            search: '',
            selected: [],
            opened: false,
            duration: 300,
            error: false,
            options: this.data,
            isValid: null,
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '填项目'
                }
            }
        }
    },
    computed: {
        filteredOptions () {
            if (this.search === '' || !this.options.length) {
                return this.options
            }

            return this.options.filter((item) => item.text.toLowerCase().includes(this.search.toLowerCase()))
        },
        groupedOptions () {
            let result = {}
            this.filteredOptions.forEach(item => {
                if (item.group) {
                    if (!result[item.group]) result[item.group] = {}
                    if (!result[item.group].items) result[item.group].items = []
                    result[item.group].name = item.group
                    result[item.group].items.push(item)
                } else {
                    if (!result.nogroup) result.nogroup = {}
                    if (!result.nogroup.items) result.nogroup.items = []
                    result.nogroup.name = ''
                    result.nogroup.items.push(item)
                }
            })

            return result
        }
    },
    mounted () {
        this.onLoad()

        document.addEventListener('click', e => this.hideDropdown(e))
    },
    beforeDestroy () {
        document.removeEventListener('click', e => this.hideDropdown(e))
    },
    watch: {
        opened () {
            if (!this.opened) {
                this.search = ''
            }
        },
        // data (array) {
        //     this.options = array
        // },
        data: {
            handler (val) {
                this.options = val
            },
            deep: true
        },
        value (value) {
            // нужно вернуться и сделать нормально, переработать работу селекта, сделать нормальный v-model и унифицировать отдачу value со остальными элементами форм
            // console.log(value);
            if (value && value.length) {
                let selectedOptions = this.options.filter(item => {
                    return value.includes(item.id)
                }).map(item => {
                    return {...item, selected: true}
                })

                // console.log(selectedOptions);

                this.options = this.options.map(item => {
                    if (value.includes(item.id)) {
                        return {...item, selected: true}
                    } else {
                        return {...item, selected: false}
                    }
                })
                this.selected = selectedOptions

                // console.log(this.selected);
                // console.log(this.options);

                return
            }

            this.selected = []
            this.options = this.options.map(item => {
                return {...item, selected: false}
            })
        }
    },
    methods: {
        onLoad () {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].selected === true) this.initSelected(this.options[i])
            }
        },
        buildClass (option) {
            return {
                'is-selected': option.selected,
                'is-disabled': option.disabled
            }
        },
        hideDropdown (e) {
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target)
            // eslint-disable-next-line no-unused-expressions
            isOutside ? this.opened = false : false
        },
        changeSelected (option) {
            // let isSelected = this.selected.find(item => item.id === option.id)
            option.selected ? this.removeSelected(option) : this.addSelected(option)
        },
        initSelected (option) {
            this.selected.push(option)
            this.toggleSelected(option, true)
        },
        addSelected (option) {
            this.initSelected(option)
            this.send()
        },
        removeSelected (option) {
            const selectedOpt = this.selected.find(item => item.id === option.id)
            let index = this.selected.indexOf(selectedOpt)
            if (index > -1) this.selected.splice(index, 1)

            this.toggleSelected(option, false)
            this.send()
        },
        toggleSelected (option, value) {
            this.options = this.options.map(item => {
                if (item.id === option.id) {
                    return { ...item, selected: value }
                }
                return item
            })
        },
        clearSelected () {
            this.selected = []
            this.opened = false
            this.options = this.options.map(item => {
                return { ...item, selected: false }
            })
            this.send(null)
        },
        send (value) {
            let data = []

            if (value !== null) {
                for (let i = 0; i < this.selected.length; i++) data.push(this.selected[i].id)
            }

            this.$nextTick(() => {
                this.$emit('input', data)
                this.$emit('change', data)
            })
            this.validate()
        },
        validate () {
            if (this.required && !this.selected.length) {
                this.isValid = false
                this.error = true
            } else {
                this.error = false
                this.isValid = true
            }
        }
    }
}
</script>

<style lang="scss">
$errorColor: #d70c1e;
$errorColorLight: #d70c1e;
$hoverColor: $token-colors-gray-35;
$focusColor: $token-colors-blue;

.z-select-multi-wrapper {
    font-size: 16px;
}

.z-select-multi {
    $parent: &;

    position: relative;
    width: 100%;
    background-color: transparent;
    user-select: none;
    transition: box-shadow 0.2s ease-in;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px $token-colors-gray-30;

    &__title-container {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        margin-bottom: 20px !important;
    }

    &__input-wrapper {
        padding: $token-spacers-3-xs;
    }

    &__caption {
        font-weight: 600;
        padding: 6px 12px;
        display: block;
        color: $token-colors-gray-35;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 1.4;
    }

    &__container {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        padding: 12px 20px;
        overflow: visible;
        height: 48px;
        padding-right: 80px;
    }

    &__wrap {
        display: flex;
        width: 100%;
        pointer-events: none;
    }

    &__items {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 16px;
    }

    &__selected {
        color: $token-colors-black;
        font-size: 16px;
        line-height: 1;
        margin-right: 16px;
        transition: color 0.2s ease-in;

        &:last-child {
            margin-right: 0;
        }
    }

    &__selected,
    &__placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $token-colors-black;
    }

    &__placeholder {
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translateY(-50%) scale(1);
        left: 16px;
        padding: 0 4px;
    }

    &__delete {
        position: relative;
        display: inline;
        margin-left: 5px;
        line-height: 1;
        pointer-events: auto;

        &:before {
            content: '';
            width: 12px;
            height: 12px;
            display: inline-block;
            z-index: 1;
            transition: background-color 0.2s ease-in;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L1 9' stroke='%23000000' stroke-linejoin='round'/%3E%3Cpath d='M1 1L9 9' stroke='%23000000' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 50% 50%;
        }
    }

    &__count {
        font-size: 14px;
        font-weight: 600;
        padding: 2px 5px;
        background: #eee;
        line-height: 1;
        border-radius: 3px;
        align-self: center;
        margin-left: auto;
    }

    &__clear {
        position: absolute;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: middle;
        z-index: 2;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L1 9' stroke='%23000000' stroke-linejoin='round'/%3E%3Cpath d='M1 1L9 9' stroke='%23000000' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 12px 12px;
        background-position: 50% 50%;
        width: 16px;
        height: 16px;
        transform: translateY(-50%);
        box-shadow: none;
        right: 48px;
    }

    &__arrow {
        display: block;
        margin: auto;
        position: absolute;
        z-index: 2;
        pointer-events: none;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23858DA6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        top: 50%;
        right: 20px;
        width: 24px;
        height: 24px;
        background-size: 14px auto;
        background-position: 50% 50%;
        transform: translateY(-50%);

        .is-opened & {
            transform: rotate(180deg) translateY(50%);
        }
    }

    &__dropdown {
        width: 100%;
        position: absolute;
        left: 0;
        background-color: #fff;
        overflow: auto;
        opacity: 0;
        transition: opacity 0.2s ease-in;
        z-index: 10;
        max-height: 340px;
        top: 100%;
        margin-top: 1px;
        box-shadow: 0px 8px 8px rgba(0, 32, 51, 0.04), 0px 12px 28px rgba(0, 32, 51, 0.12);
        border-radius: 6px;
        background: $token-colors-white;
        border-top: none;
        padding: 0;
        filter: none;
    }

    &__options {
        margin: 0;

        & + #{$parent}__options {
            margin-top: 8px;
        }
    }

    &__option {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
        cursor: pointer;
        padding: 12px 20px 12px 56px;
        color: $token-colors-gray-50;
        transition: background 0.2s ease-in;

        &:hover {
            background: $token-colors-gray-10;

            &:before {
                background-color: $token-colors-gray-10;
            }
        }

        &:before {
            content: '';
            display: block;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            transition: all 0.2s ease-in;
            left: 20px;
            background: $token-colors-white;
            background-size: 50%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            box-shadow: 0 0 0 1px $token-colors-gray-30;
            width: 24px;
            height: 24px;
            border-radius: 4px;
        }

        &:hover:before,
        &.is-selected:before {
            background-position: center;
            box-shadow: 0 0 0 1px $token-colors-blue;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.0909 1.42429C15.3252 1.18997 15.3252 0.810075 15.0909 0.57576C14.8566 0.341446 14.4767 0.341446 14.2424 0.57576L5.5 9.31816L1.7576 5.57576C1.52329 5.34145 1.14339 5.34145 0.909073 5.57576C0.674759 5.81007 0.674759 6.18997 0.909073 6.42429L5.07574 10.591C5.31005 10.8253 5.68995 10.8253 5.92427 10.591L15.0909 1.42429Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
            background-color: $token-colors-gray-10;
            color: $token-colors-black;
        }

        &.is-disabled {
            opacity: .5;
            pointer-events: none;
        }
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.is-opened {
        #{$parent}__arrow {
            transform: translateY(-50%) rotate(180deg);
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%230077c8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }

        #{$parent}__dropdown {
            opacity: 1;
        }
    }

    &__error {
        display: block;
        margin-top: 4px;
        position: relative;
        font-size: 12px;
        line-height: 1.2;
        color: $errorColor;
        left: 16px;
    }

    //hover state
    &:hover {
        box-shadow: inset 0 0 0 1px $hoverColor;
    }

    // filled state
    &.is-filled {
        box-shadow: inset 0 0 0 1px $focusColor;

        #{$parent}__placeholder {
            width: auto;
            transform: translateY(-2.4em);
            font-size: 0.75em;
            line-height: 1.2;
            background-color: #fff;
            font-weight: 600;
        }

        #{$parent}__arrow {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%230077c8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
    }

    // errored state
    &.is-errored {
        box-shadow: inset 0 0 0 1px $errorColorLight;

        #{$parent}__placeholder {
            color: $errorColor;
        }

        #{$parent}__arrow {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23858DA6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }

        #{$parent}__container {
            box-shadow: none;
        }
    }

    // valid state
    &.is-valid {
    }
}
</style>
