<template>
    <div class="z-preloader">
        <img :src="buildSrc" title="preloader">
    </div>
</template>

<script>
export default {
    name: 'z-preloader',
    computed: {
        buildSrc () {
            return require('./images/preloader.svg')
        }
    }
}
</script>

<style lang="scss">
    .z-preloader {
        padding: 2rem 0;
        text-align: center;

        > img {
            height: .75rem;
        }
    }
</style>
