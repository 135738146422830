<template>
<div class="news">
    <priority-news class="u-bottom-margin--2xl" :data="data['priority-news']"/>
    <layout class="container" :without-aside="withoutAside">
        <template #content>
            <div class="news_content" ref="container">
                <news-filter
                    v-show="isMobileView && isFilterShown"
                    :data="filters"
                    @change="onFilterChange"
                    class="u-bottom-margin--m"
                    :initial="initial"
                />
                <p
                    :class="[
                        'gray-35-text text-size-l u-normal',
                        { 'u-collapse--all': device === 'mobile' }
                    ]"
                    v-if="countText"
                >
                    <vue-raw :raw="countText"/>
                </p>
                <content-container>
                    <news-list
                        v-if="formatedList.length"
                        :items='formatedList'
                    />
                    <z-not-found v-else />
                    <z-preloader v-if="isLoading"></z-preloader>
                </content-container>
            </div>
        </template>
        <template #aside>
            <news-filter
                class="news__filter"
                v-show="!isMobileView && isFilterShown"
                :data="filters"
                @change="onFilterChange"
                :initial="initial"
            />

            <slot name="aside" />
        </template>
    </layout>
</div>
</template>

<script>
import { throttle } from 'throttle-debounce'
import { mixinDevice } from '@/utils/mixin'
import { getNews } from '@/api/news'
import { getCovidNews } from '@/api/covid-news'
import PriorityNews from './components/PriorityNews.vue'
import NewsFilter from './components/Filter.vue'
import { queryString } from '@/utils/queryString'

export default {
    name: 'news',
    mixins: [mixinDevice],
    components: {
        PriorityNews,
        NewsFilter
    },
    props: {
        source: {
            type: String,
            default: 'news'
        },
        isFilterShown: {
            type: Boolean,
            default: true
        },
        initial: Object,
        withoutAside: Boolean
    },
    data () {
        return {
            isLoading: false,
            data: null,
            filters: null,
            list: [],
            nav: {
                current: 1,
                total: 0
            },
            params: {
                page: 1,
                from: '',
                to: '',
                sources: [],
                subjects: []
            }
        }
    },
    computed: {
        formatedList () {
            return this.list.map(item => ({
                date: item.activeFrom,
                link: item.detailPageUrl,
                name: item.name,
                text: this.getPreviewText(item),
                tag: item.newsSource?.value ? item.newsSource.value : ''
            }))
        },
        isMobileView () {
            return (
                this.device === 'mobile' ||
                this.device === 'v-tablet' ||
                this.device === 'tablet'
            )
        },
        countText () {
            if (!this.formatedList.length) return ''

            const str = this.buildString({
                value: this.nav.count,
                news: {
                    ru: ['новость', 'новости', 'новостей'],
                    en: ['news', 'news', 'news']
                },
                found: {
                    ru: ['Найдена', 'Найдено', 'Найдено'],
                    en: ['Found', 'Found', 'Found']
                }

            })

            return str
        }
    },
    methods: {
        getPreviewText (item) {
            let str = ''
            let index = ''
            str = item.previewText ? item.previewText : item.detailText
            if (str.length > 300) {
                str = str.slice(0, 300)
                index = str.lastIndexOf('.')
                if (index === -1) index = str.lastIndexOf(' ')
                str = str.slice(0, index) + '...'
            }

            return str
        },
        buildString (obj) {
            if (obj.value === null) return ''
            if (obj.value.length === 0) return ''

            let foundStr = ''
            let newsStr = ''

            if (this.$root.lang === 'ru') {
                foundStr = this.declOfNum(obj.value, obj.found.ru)
                newsStr = this.declOfNum(obj.value, obj.news.ru)
            } else {
                foundStr = this.declOfNum(obj.value, obj.found.en)
                newsStr = this.declOfNum(obj.value, obj.news.en)
            }
            return `${foundStr} ${obj.value} ${newsStr}`
        },
        declOfNum (number, titles) {
            let cases = [2, 0, 1, 1, 1, 2]

            return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
        },

        clearEmptyParams (filters) {
            let params = Object.assign({}, filters)

            Object.keys(params).forEach(function (key) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        onFilterChange (data) {
            this.params = { ...this.params, ...data }
            this.params.page = 1
            console.log(this.clearEmptyParams(data))
            queryString(this.clearEmptyParams(data))
            this.submit()
        },
        getData (params) {
            if (this.source === 'covid-news') return getCovidNews(params)
            return getNews(params)
        },
        submit () {
            this.isLoading = true

            this.getData(this.params)
                .then(res => {
                    this.isLoading = false

                    if (this.params.page < 2) {
                        this.list = res.items
                    } else {
                        this.list = this.list.concat(res.items)
                    }
                    this.filters = res.filters
                    this.nav = res.nav
                })
                .catch(error => {
                    this.isLoading = false
                    console.log(error)
                })
        },
        loadMore () {
            this.params.page++
            this.submit(this)
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, e => {
                if (!this.$refs.container) return
                const offsetTop = this.$refs.container.offsetTop
                const offsetHeight = this.$refs.container.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (
                    scrolled >= (bottom - 100) &&
                    !this.isLoading &&
                    this.nav.current < this.nav.total
                ) {
                    this.loadMore()
                }
            }))
        }
    },
    created () {
        if (this.$root.app.components[this.source]) {
            this.data = this.$root.app.components[this.source]
            this.list = this.$root.app.components[this.source].items
            this.filters = this.$root.app.components[this.source].filters
            this.nav = this.$root.app.components[this.source].nav
        }
        if (this.initial) this.params = { ...this.params, ...this.initial }
    },
    mounted () {
        this.listenScroll()
    }
}
</script>

<style lang="scss">
</style>
