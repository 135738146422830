<template>
    <div class="anchors u-bottom-padding--m u-top-padding--m u-left-padding--2xl u-right-padding--2xl">
        <div class="anchors__container">
            <a
                :href="anchor.id"
                v-for="(anchor, idx) in anchors"
                :key="anchor.title"
                class="anchors__anchor anchor"
            >
                <z-icon
                    class="anchor__icon u-right-margin--xs"
                    :name="anchor.icon"
                    width="64"
                    height="64"
                />
                <div class="anchor__text text-size-2xl">
                    <div class="anchor__num blue-50-text">0{{idx+1}}</div>
                    <p class="anchor__value text-size-2xl u-bold black-text">
                        <vue-raw :raw="anchor.title"/>
                    </p>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'anchors',
    data () {
        return {
            anchors: [
                {
                    title: localize({
                        ru: 'Минерально-сырьевая база и геологоразведка',
                        en: 'Mineral resource base and exploration'
                    }),
                    id: '#1',
                    icon: 'geological-exploration'
                },
                {
                    title: localize({
                        ru: 'Добыча <br> и производство',
                        en: 'Mining <br> and production '
                    }),
                    id: '#2',
                    icon: 'metal-production'
                },
                {
                    title: localize({
                        ru: 'Логистика<br> и сбыт',
                        en: 'Logistics and sales '
                    }),
                    id: '#3',
                    icon: 'logistics-sales'
                },
                {
                    title: localize({
                        ru: 'Финансовые <br>результаты',
                        en: 'Financial performance'
                    }),
                    id: '#4',
                    icon: 'financial-results'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.anchors {
    border-radius: 0 $boder-radius-l $boder-radius-l $boder-radius-l;
    background: $token-colors-gray-10;

    &__container {
        display: flex;
        margin-top: -$token-spacers-xs;
        margin-left: -$token-spacers-xs/2;
        margin-right: -$token-spacers-xs/2;

        @include breakpoint (tablet) {
            flex-wrap: wrap;
        }
    }

    @include breakpoint (mobile) {
        padding: 24px;
    }

    &__anchor {
        display: flex;
        align-items: flex-start;
        text-decoration: none;
        max-width: 25%;
        margin-top: $token-spacers-xs;
        padding-left: $token-spacers-xs/2;
        padding-right: $token-spacers-xs/2;
        align-items: flex-end;
        margin-right: auto;

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint (tablet) {
            margin-right: 0;
            width: 50%;
            max-width: none;
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }

}

.anchor {
    &__num {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
    }

    &__text {
        line-height: 1.2 !important;
    }
}
</style>
