/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/zip': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM15.792 31h6v-1.236h-4.176l4.092-5.628v-1.14h-5.916v1.236h4.092l-4.092 5.628V31zm7.39 0h1.404v-8.004h-1.404V31zm3.106 0h1.404v-2.988h2.111c1.668 0 2.616-1.152 2.616-2.508s-.935-2.508-2.615-2.508h-3.516V31zm4.692-5.496c0 .768-.576 1.272-1.369 1.272h-1.92v-2.544h1.92c.793 0 1.369.504 1.369 1.272z" _fill="#737B91"/>'
  }
})
