/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/rub': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31977)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" d="M65.116 82h-1.764l-3.06-4.77h-2.394V82h-1.494V69.994h4.824c2.196 0 3.762 1.404 3.762 3.618 0 2.16-1.476 3.348-3.114 3.492L65.116 82zm-4.068-6.084c1.422 0 2.394-.954 2.394-2.304 0-1.35-.972-2.286-2.394-2.286h-3.15v4.59h3.15zm13.003 6.3c-3.258 0-4.896-1.926-4.896-4.878v-7.344h1.494v7.308c0 2.196 1.188 3.582 3.402 3.582 2.214 0 3.402-1.386 3.402-3.582v-7.308h1.494v7.326c0 2.988-1.638 4.896-4.896 4.896zM89.031 82h-5.472V69.994h5.346c2.088 0 3.366 1.26 3.366 3.06 0 1.512-1.008 2.52-2.106 2.754 1.278.198 2.34 1.512 2.34 2.952 0 1.926-1.278 3.24-3.474 3.24zm-.378-6.804c1.35 0 2.07-.828 2.07-1.926 0-1.08-.72-1.944-2.07-1.944h-3.6v3.87h3.6zm.072 5.472c1.404 0 2.232-.792 2.232-2.07 0-1.098-.756-2.07-2.232-2.07h-3.672v4.14h3.672z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31977" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31977"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31977" result="effect2_dropShadow_9226_31977"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31977" result="shape"/></filter></defs>'
  }
})
