/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'enrichment': {
    width: 127,
    height: 48,
    viewBox: '0 0 127 48',
    data: '<path pid="0" d="M112.128 35.084v3.134m-6.901-3.134v3.134m-6.678-3.134v3.134m-6.9-3.134v3.134m-6.68-3.134v3.134m27.159-8.956v3.135m-6.901-3.135v3.135m-6.678-3.135v3.135m-6.9-3.135v3.135m-6.68-3.135v3.135m27.159-8.733v3.135m-6.901-3.135v3.135m-6.678-3.135v3.135m-6.9-3.135v3.135m-6.68-3.135v3.135m30.498-6.27a34.362 34.362 0 010 22.168m0-22.168H82.076m33.391 0l5.12 5.374m-5.12-5.374v22.168m0 0H82.076m33.391 0l5.12-5.15V25.903M82.076 42.697a34.366 34.366 0 010-22.168m0 22.168l-5.12-5.15V25.903l5.12-5.374m0 22.168V20.529m38.511 5.374h5.121v21.496h-5.121V25.903zM19.077 40.682h18.7v2.91h-18.7v-2.91zm0 0L.601 35.979l41.85-10.748v21.496l-12.498-3.134M.601 46.727V.6h19.812v30.23L.6 46.726zm0 0h71.235M20.413 9.11l15.51 13.977m-12.437 3.157l-3.073-2.803M12.62 13.363h4.23v4.927h-4.23v-4.926zm-8.459 0h4.23v4.927h-4.23v-4.926zm8.46-7.837h4.23v4.927h-4.23V5.526zm-8.46 0h4.23v4.927h-4.23V5.526zM76.956 47.4h-5.12V25.231h5.12V47.4zm-29.162-6.717h18.7v2.91h-18.7v-2.91zm0-5.375h18.7v2.911h-18.7v-2.91zm-28.717 0h18.7v2.911h-18.7v-2.91zm57.879 11.42H91.87v-4.03l-14.915 4.03zM91.87 20.53V8.885L20.413 27.023l71.458-6.494z" _stroke="#1A1B25" stroke-width="1.2" stroke-miterlimit="10" stroke-linejoin="round"/>'
  }
})
