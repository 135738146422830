/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-long-3': {
    width: 9,
    height: 65,
    viewBox: '0 0 9 65',
    data: '<path pid="0" d="M4.873.176a.604.604 0 00-.854 0L.177 4.018a.604.604 0 00.854.854l3.415-3.415L7.86 4.872a.604.604 0 10.854-.854L4.873.176zM5.05 65V.603H3.842V65H5.05z" _fill="#0077C8"/>'
  }
})
