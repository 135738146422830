<template>
    <div class="central-tenders-filter" v-if="filtersData">
        <button
            class="central-tenders-filter__switcher"
            :class="{'central-tenders-filter__switcher--opened': filterState}"
            @click="toggleFilter"
        >
            <z-caption uppercase decor-right size="m" weight="700">Выбор параметров</z-caption>
            <span class="central-tenders-filter__tag">
                <span class="hide-me--v-tablet">
                    <template v-if="filterState">
                        Свернуть фильтры
                    </template>
                    <template v-else>
                        Развернуть фильтры
                    </template>
                </span>
                <span class="central-tenders-filter__icon">
                    <z-icon name="arrow-long"/>
                </span>
            </span>
        </button>
        <div v-show="filterState" class="central-tenders-filter__body">
            <div class="row">
                <div class="col-default-6 col-v-tablet-12">
                    <span class="central-tenders-filter__title">Поиск</span>
                    <z-input
                        name="search"
                        icon-name="magnifier"
                        placeholder="Введите ключевое слово"
                        v-model="filters.value.q"
                    />
                </div>
                <div class="col-default-6 col-v-tablet-12 central-tenders-filter__date-container">
                    <span class="central-tenders-filter__title">Срок подачи заявки</span>
                    <div class="central-tenders-filter__wrapper">
                        <z-date-picker v-model="filters.value.dateFrom" placeholder="Введите"/>
                        —
                        <z-date-picker v-model="filters.value.dateTo" placeholder="Введите"/>
                    </div>
                </div>
                <div class="col-default-6 col-v-tablet-12">
                    <span class="central-tenders-filter__title">Заказчик</span>
                    <z-select-multi
                        v-model="filters.value.customers"
                        with-search
                        :data="filtersData.customers"
                        placeholder="Все"
                        :is-disabled="isDisabled(filtersData.customers)"
                    />
                </div>
                <div class="col-default-6 col-v-tablet-12">
                    <span class="central-tenders-filter__title">Организатор</span>
                    <z-select-multi
                        v-model="filters.value.organizers"
                        with-search
                        :data="filtersData.organizers"
                        placeholder="Все"
                        :is-disabled="isDisabled(filtersData.organizers)"
                    />
                </div>
                <div class="col-default-6 col-v-tablet-12 central-tenders-filter__select">
                    <span class="central-tenders-filter__title">Способ проведения закупочной процедуры</span>
                    <z-select-multi
                        v-model="filters.value.methods"
                        with-search
                        :data="filtersData.methods"
                        placeholder="Все"
                        :is-disabled="isDisabled(filtersData.methods)"
                    />
                </div>
                <div class="col-default-6 col-v-tablet-12">
                    <span class="central-tenders-filter__title">Способ подачи заявки</span>
                    <z-select-multi
                        v-model="filters.value.applicationMethod"
                        with-search
                        :data="filtersData.applicationMethod"
                        placeholder="Все"
                        :is-disabled="isDisabled(filtersData.applicationMethod)"
                    />
                </div>
            </div>
            <div class="central-tenders-filter__reset-wrapper">
                <button
                    class="central-tenders-filter__tag central-tenders-filter__tag--button"
                    :disabled="!haveParams"
                    @click="reset"
                >
                    Сбросить фильтры
                </button>
            </div>
        </div>
        <div v-if="!filterState" class="u-top-margin--xs u-bottom-margin--2xl">
            <span class="central-tenders-filter__title">Поиск</span>
            <z-input
                name="search"
                icon-name="magnifier"
                placeholder="Введите ключевое слово"
                v-model="filters.value.q"
            />
        </div>

    </div>
</template>

<script>
import {queryString} from '@/utils/queryString'
import {clearEmptyParams} from '@/utils/clearEmptyParams'

export default {
    name: 'CentralTendersFilter',
    inject: ['filters'],
    props: {
        filtersData: {
            type: Object
        }
    },
    data () {
        return {
            filterState: false
        }
    },
    computed: {
        haveParams () {
            return Object.keys(clearEmptyParams(this.filters.value)).length
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        reset () {
            this.filters.value.q = ''
            this.filters.value.dateFrom = ''
            this.filters.value.dateTo = ''
            this.filters.value.methods = []
            this.filters.value.customers = []
            this.filters.value.organizers = []
            this.filters.value.applicationMethod = []
        },
        toggleFilter () {
            this.filterState = !this.filterState
        },
        isDisabled (data) {
            return !data
        },
        checkParams (params) {
            return Boolean(params.get('q') ||
                params.get('dateFrom') ||
                params.get('dateTo') ||
                params.get('archive') ||
                params.getAll('methods[]').length ||
                params.getAll('customers[]').length ||
                params.getAll('organizers[]').length ||
                params.getAll('applicationMethod[]').length
            )
        },
        init () {
            const params = new URL(document.location).searchParams
            const haveParams = this.checkParams(params)
            this.filters.value.q = params.get('q') ? params.get('q') : ''
            this.filters.value.dateFrom = params.get('dateFrom') ? params.get('dateFrom') : ''
            this.filters.value.dateTo = params.get('dateTo') ? params.get('dateTo') : ''
            this.filters.value.archive = params.get('archive') ? params.get('archive') : null
            this.filters.value.methods = params.getAll('methods[]').length ? params.getAll('methods[]') : []
            this.filters.value.applicationMethod = params.getAll('applicationMethod[]').length ? params.getAll('applicationMethod[]') : []
            this.filters.value.customers = params.getAll('customers[]').length ? params.getAll('customers[]') : []
            this.filters.value.organizers = params.getAll('organizers[]').length ? params.getAll('organizers[]') : []

            if (haveParams) {
                queryString(clearEmptyParams(this.filters.value))
                this.$emit('update-filters', this.filters.value)
            }

            this.$watch(
                'filters.value',
                function () {
                    queryString(clearEmptyParams(this.filters.value))
                    this.$emit('update-filters', this.filters.value)
                },
                { deep: true }
            )
        }
    }
}
</script>

<style lang="scss">
.central-tenders-filter {
    $parent: &;
    margin-bottom: $token-spacers-l;

    &__switcher {
        padding: 0;
        width: 100%;
        background: none;
        border: none;
        position: relative;
        margin: 0;

        .z-caption {
            margin-bottom: 0;
        }

        &--opened {
            #{$parent}__icon {
                transform: rotate(0);
            }
        }

        .z-caption {
            &--decor-right {
                &:after {
                    @include breakpoint(mobile) {
                        display: block !important;
                    }
                }
            }
        }
    }

    &__body {
        margin-top: $token-spacers-xs;
    }

    &__tag {
        position: absolute;
        right: 0;
        top: 0;
        border: 1px solid $token-colors-gray-60;
        border-radius: 40px;
        padding: 6px 46px 6px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        color: $token-colors-gray-60;
        background-color: $token-colors-white;
        box-shadow: -16px 0 0 rgba(255, 255, 255, 1);

        @include breakpoint(v-tablet) {
            padding: 0;
            border: none;
        }

        &--button {
            position: relative;
            top: 0;
            padding: 6px 20px;
            box-shadow: 0 0 0 rgba(255, 255, 255, 0);

            &:disabled {
                opacity: 0.6;
            }

            @include breakpoint(v-tablet) {
                border: 1px solid $token-colors-gray-60;
            }
        }
    }

    &__reset-wrapper {
        margin-top: $token-spacers-xs;
        display: flex;
        justify-content: flex-end;
    }

    &__icon {
        position: absolute;
        top: -1px;
        right: -1px;
        border: 1px solid $token-colors-gray-60;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        transition: transform 300ms ease;
        background-color: $token-colors-white;

        .z-icon {
            path {
                fill: $token-colors-gray-60;
            }
        }
    }

    &__title {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.4;
        color: $token-colors-gray-50;
        margin-bottom: $token-spacers-3-xs;
    }

    &__container {
        display: flex;
        gap: $token-spacers-s;

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }
    }

    &__date-container {
        width: 100%;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        color: $token-colors-gray-35;

        .z-date-picker {
            width: 100%;

            &:first-child {
                margin-right: $token-spacers-3-xs;
            }

            &:last-child {
                margin-left: $token-spacers-3-xs;
            }
        }

        @include breakpoint(mobile) {
            flex-direction: column;

            .z-date-picker {
                &:first-child {
                    margin-right: 0;
                }

                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }

    &__select {
        width: 100%;
    }
}

</style>
