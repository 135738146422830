<template>
    <div class="sale-page-filter" v-if="filtersData">
        <div class="sale-page-filter__switcher">
            <z-caption uppercase decor-right size="m" weight="700">Выбор параметров</z-caption>

            <z-button size="s" kind="bordered-dark" @click="toggleFilter">Cвернуть фильтры
                <template v-slot:right>
                    <z-icon name="arrow" :dir="!filterState ? 'top' : 'down'" width="20" height="20"></z-icon>
                </template>
            </z-button>
        </div>
        <div class="sale-page-filter__body" :class="{'sale-page-filter__body--hide': !filterState}">
            <div class="row">
                <div class="col-default-6 col-v-tablet-12">
                    <span class="sale-page-filter__title">Поиск</span>
                    <z-input
                        name="search"
                        icon-name="magnifier"
                        placeholder="Введите ключевое слово"
                        v-model="filters.q"
                    />
                </div>
                <div class="col-default-6 col-v-tablet-12">
                    <span class="sale-page-filter__title">РОКС</span>
                    <z-select-multi
                        v-model="filters.roks"
                        :data="filtersData.roks"
                        placeholder="Все"
                        :is-disabled="isDisabled(filtersData.roks)"
                    />
                </div>
                <div class="col-default-6 col-v-tablet-12">
                    <span class="sale-page-filter__title">Наименование группы материалов</span>
                    <z-select-multi
                        v-model="filters.type"
                        :data="filtersData.type"
                        placeholder="Все"
                        :is-disabled="isDisabled(filtersData.type)"
                    />
                </div>
                <div class="col-default-6 col-v-tablet-12">
                    <span class="sale-page-filter__title">Наименование подгруппы материалов</span>
                    <z-select-multi
                        v-model="filters['sub-type']"
                        :data="filtersData['sub-type']"
                        placeholder="Все"
                        :is-disabled="isDisabled(filtersData['sub-type'])"
                    />
                </div>
                <div class="col-default-12">
                    <div class="sale-page-filter__reset-wrapper">
                        <button
                            class="sale-page-filter__tag sale-page-filter__tag--button"
                            :disabled="!haveParams"
                            @click="reset"
                        >
                            Сбросить фильтры
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryString } from '@/utils/queryString'
import { clearEmptyParams } from '@/utils/clearEmptyParams'

export default {
    name: 'sale-page-filter',
    props: {
        filtersData: {
            type: Object
        }
    },
    data () {
        return {
            filterState: true,
            filterName: 'illiquid-property-filter',
            filters: {
                q: '',
                roks: [],
                type: [],
                'sub-type': []
            }
        }
    },
    computed: {
        haveParams () {
            return Object.keys(clearEmptyParams(this.filters)).length
        }
    },
    created () {
        this.init()
    },
    methods: {
        reset () {
            this.filters.q = ''
            this.filters.roks = []
            this.filters.type = []
            this.filters['sub-type'] = []
        },
        toggleFilter () {
            this.filterState = !this.filterState
        },
        setFilters () {
            localStorage.setItem(this.filterName, JSON.stringify(this.filters))
        },
        getFilters () {
            return JSON.parse(localStorage.getItem(this.filterName))
        },
        deleteFilters () {
            localStorage.removeItem(this.filterName)
        },
        isDisabled (data) {
            return data.length <= 1
        },
        checkParams (params) {
            return Boolean(params.get('q') ||
                params.getAll('roks[]').length ||
                params.getAll('type[]').length ||
                params.getAll('sub-type[]').length
            )
        },
        init () {
            const params = new URL(document.location).searchParams
            const haveParams = this.checkParams(params)
            this.filters.q = params.get('q') ? params.get('q') : ''
            this.filters.roks = params.getAll('roks[]').length ? params.getAll('roks[]') : []
            this.filters.type = params.getAll('type[]').length ? params.getAll('type[]') : []
            this.filters['sub-type'] = params.getAll('sub-type[]').length ? params.getAll('sub-type[]') : []

            if (haveParams) {
                this.setFilters()
            } else if (localStorage.getItem(this.filterName)) {
                this.filters = this.getFilters()
                queryString(clearEmptyParams(this.filters))
            }

            this.$watch('filters', function () {
                queryString(clearEmptyParams(this.filters))
                console.log(this.filters)
                if (this.haveParams) {
                    this.setFilters()
                } else {
                    this.deleteFilters()
                }
                this.$emit('update-filters', this.filters)
            }, {deep: true})
        }
    }
}
</script>

<style lang="scss">
.sale-page-filter {
    $parent: &;
    margin-bottom: $token-spacers-l;

    &__switcher {
        padding: 0;
        width: 100%;
        background: none;
        border: none;
        position: relative;
        margin: 0;
        display: flex;
        gap: $token-spacers-2-xs;

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }

        .z-caption {
            margin-bottom: 0;
            width: 100%;
        }

        .z-button {
            flex-shrink: 0;
            border-color: $token-colors-gray-60;
            color: $token-colors-gray-60;
            font-size: 16px;
            font-weight: 600;
            border-radius: 20px;

            @include breakpoint(v-tablet) {
                width: fit-content;
            }

            .z-icon path {
                fill: $token-colors-gray-60;
            }

            &:hover {
                border-color: $token-colors-gray-60;
                color: $token-colors-gray-60;

                .z-icon path {
                    fill: $token-colors-gray-60;
                }
            }
        }

        &--opened {
            #{$parent}__icon {
                transform: rotate(0);
            }
        }

        .z-caption {
            &--decor-right {
                &:after {
                    @include breakpoint(mobile) {
                        display: block !important;
                    }
                }
            }
        }
    }

    &__body {
        margin-top: $token-spacers-xs;
        display: block;

        &--hide {
            display: none;
        }
    }

    &__tag {
        position: absolute;
        right: 0;
        top: 0;
        border: 1px solid $token-colors-gray-60;
        border-radius: 40px;
        padding: 6px 46px 6px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
        color: $token-colors-gray-60;
        background-color: $token-colors-white;
        box-shadow: -16px 0 0 rgba(255, 255, 255, 1);

        @include breakpoint(v-tablet) {
            padding: 0;
            border: none;
        }

        &--button {
            position: relative;
            top: 0;
            padding: 6px 20px;
            box-shadow: 0 0 0 rgba(255, 255, 255, 0);

            &:disabled {
                opacity: 0.6;
            }

            @include breakpoint(v-tablet) {
                border: 1px solid $token-colors-gray-60;
            }
        }
    }

    &__reset-wrapper {
        margin-top: $token-spacers-xs;
        display: flex;
        justify-content: flex-end;
    }

    &__icon {
        position: absolute;
        top: -1px;
        right: -1px;
        border: 1px solid $token-colors-gray-60;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        transition: transform 300ms ease;
        background-color: $token-colors-white;

        .z-icon {
            path {
                fill: $token-colors-gray-60;
            }
        }
    }

    &__title {
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.4;
        color: $token-colors-gray-50;
        margin-bottom: $token-spacers-3-xs;
    }

    &__container {
        display: flex;
        gap: $token-spacers-s;

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }
    }

    &__date-container {
        width: 100%;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        color: $token-colors-gray-35;

        .z-date-picker {
            width: 100%;

            &:first-child {
                margin-right: $token-spacers-3-xs;
            }

            &:last-child {
                margin-left: $token-spacers-3-xs;
            }
        }

        @include breakpoint(mobile) {
            flex-direction: column;

            .z-date-picker {
                &:first-child {
                    margin-right: 0;
                }

                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }

    &__select {
        width: 100%;
    }
}

</style>
