<template>
    <div class="general-meeting-item">
        <z-caption tag="h2" v-html="data.name"></z-caption>

        <section
            class="general-meeting-item__section"
            v-if="data.materials && data.materials.items.length > 0"
        >
            <z-caption
                tag="h2"
                decor-left
                decor-right
                size="m"
                weight="700"
                uppercase>
                {{ text.materials }}
            </z-caption>
            <z-filelist>
                <z-filelist-item
                    v-for="(item, index) in data.materials.items"
                    :key="index"
                    :name="item.name"
                    :href="item.url"
                    :icon-name="`file/${item.extension}`"
                    :file-size="item.size"
                ></z-filelist-item>
            </z-filelist>
        </section>
        <section
            class="general-meeting-item__section"
            v-if="data['annual-report'] && data['annual-report'].items.length > 0"
        >
            <z-caption
                tag="h2"
                decor-left
                decor-right
                size="m"
                weight="700"
                uppercase>
                {{ text.annualReport }}
            </z-caption>
            <z-filelist>
                <z-filelist-item
                    v-for="(item, index) in data['annual-report'].items"
                    :key="index"
                    :name="item.name"
                    :href="item.url"
                    :icon-name="`file/${item.extension}`"
                    :file-size="item.size"
                ></z-filelist-item>
            </z-filelist>
        </section>
        <section
            class="general-meeting-item__section"
            v-if="data.results && data.results.items.length > 0"
        >
            <z-caption
                tag="h2"
                decor-left
                decor-right
                size="m"
                weight="700"
                uppercase>
                {{ text.results }}
            </z-caption>
            <z-filelist>
                <z-filelist-item
                    v-for="(item, index) in data.results.items"
                    :key="index"
                    :name="item.name"
                    :href="item.url"
                    :icon-name="`file/${item.extension}`"
                    :file-size="item.size"
                ></z-filelist-item>
            </z-filelist>
        </section>
        <section
            class="general-meeting-item__section"
            v-if="data.protocol && data.protocol.items.length > 0"
        >
            <z-caption
                tag="h2"
                decor-left
                decor-right
                size="m"
                weight="700"
                uppercase>
                {{ text.protocol }}
            </z-caption>
            <z-filelist>
                <z-filelist-item
                    v-for="(item, index) in data.protocol.items"
                    :key="index"
                    :name="item.name"
                    :href="item.url"
                    :icon-name="`file/${item.extension}`"
                    :file-size="item.size"
                ></z-filelist-item>
            </z-filelist>
        </section>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
export default {
    name: 'general-meeting-item',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                materials: localize({
                    ru: 'Материалы',
                    en: 'Materials'
                }),
                results: localize({
                    ru: 'Итоги',
                    en: 'Voting Results'
                }),
                protocol: localize({
                    ru: 'Протокол',
                    en: 'Protocol'
                }),
                annualReport: localize({
                    ru: 'Годовой отчет',
                    en: 'Annual Report'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.general-meeting-item {
    &__section {
        margin-top: $token-spacers-m;

        @include breakpoint (mobile) {
            margin-top: $token-spacers-xs;
        }
    }

    + .general-meeting-item {
        margin-top: $token-spacers-xl;
    }
}
</style>
