<template>
    <div class="dash">
        <div class="dash__focus u-right-margin--2xs blue-gradient-bg text-size-2xl u-bold">
            <p><slot name="value"></slot></p>
        </div>
        <div class="navy-blue-text u-bold text-size-xl dash__text">
            <slot name="text"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'dash'
}
</script>

<style lang="scss">
.dash {
    display: flex;
    align-items: center;

    &__focus {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 80px 80px 0px 80px;
        padding: 0 15px;
        min-width: 72px;
        min-height: 64px;
        color: $token-colors-white;
    }
}
</style>
