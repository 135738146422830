<template>
<ul class="parent-menu" v-if="(data && data.length)">
    <li
        class="parent-menu__item"
        v-for="(item, index) in data"
        :key="index"
    >
        <a class="parent-menu__link" :href="item.link">
            <span v-html="item.name"></span>
            <z-icon
                name="round-arrow"
                dir="right"
                width="30"
                height="30"
            />
        </a>
    </li>
</ul>
</template>
<script>
export default {
    name: 'parent-menu',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    }
}
</script>
<style lang="scss">
.parent-menu {
    margin: 0;

    &__link {
        text-decoration: none;
        color: $token-colors-gray-60;
        display: flex;
        justify-content: space-between;
        align-content: center;
        @include typo-level(M);
        font-weight: 600;
        line-height: 1.2;
        @include padding-level(bottom, 2XS);
        @include padding-level(top, 2XS);
        transition: color $transition;

        &:hover {
            color: $token-colors-blue;
        }

        .z-icon {
            margin-right: 8px;
        }
    }

    &__item {
        margin: 0;

        & + & {
            border-top: 1px solid rgba($token-colors-gray-20, 0.5);
        }
    }
}
</style>
