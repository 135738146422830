<template>
<media-album>
    <div class="file-list-wrapper">
        <z-filelist size="l" horizontal class="u-entire-width row u-justify-content--btween">
            <z-filelist-item
                v-for="data in componentData" :key='data.code'
                class="col-default-6 col-mobile-12 u-align-items--start"
                :name="data.name"
                :href="data.mlDocs.link"
                :icon-name="data.mlDocs.type ? `file/${data.mlDocs.type}` : 'file/pdf'"
                :file-size="data.mlDocs.size"
            ></z-filelist-item>
        </z-filelist>
    </div>

    <template v-slot:text>
        <slot/>
    </template>
</media-album>
</template>

<script>
export default {
    name: 'media-album-identity',
    props: {
        componentData: {
            type: Array,
            required: true
        }
    }
}
</script>
