<template>
<div class="irp-fin-results container" v-if="operation || financial">
    <div class="u-right-padding--xl">
        <z-caption
            href="/investors/reports-and-results/current-results/"
            tag="a"
            size="3xl"
            decor-right
        >
            <vue-raw :raw="text.caption"/>
        </z-caption>
    </div>

    <div class="u-top-padding--2xl">
        <template v-if="financial">
            <z-caption
                size="m"
                weight="700"
                uppercase
                decor-left
                class="u-bottom-margin--l-important"
            >
                {{text.finResults}} {{ isFullFinYear ? '12M' : '6M' }} {{ financial.name }}
            </z-caption>

            <div class="row">
                <div class="col-default-6 col-v-tablet-12" v-if="finPressRelease.detailPageUrl">
                    <result-book
                        :cover="image.press"
                        :title="text.pressRelease"
                        :href="finPressRelease.detailPageUrl"
                    ></result-book>
                </div>
                <div class="col-default-6 col-v-tablet-12" v-if="finPresentation.link">
                    <result-book
                        horizontal
                        :cover="finPresentation.image ? finPresentation.image : '/images/book-cover-presentation.png'"
                        :title="text.presentation"
                        :href="finPresentation.link"
                        :size="finPresentation.size"
                    ></result-book>
                </div>
                <div class="col-default-6 col-v-tablet-12" v-if="finReportUds.link">
                    <result-book
                    :cover="image.report"
                    :title="text.finResUsd"
                    :href="finReportUds.link"
                    :size="finReportUds.size"
                    ></result-book>
                </div>
                <div class="col-default-6 col-v-tablet-12" v-if="finReportDataBook.link">
                    <result-book
                        cover="/images/book-cover-data.png"
                        title="Data book"
                        :href="finReportDataBook.link"
                        :size="finReportDataBook.size"
                    ></result-book>
                </div>
            </div>
        </template>

        <template v-if="operation">
            <div class="u-top-padding--2xl">
                <z-caption
                    size="m"
                    weight="700"
                    uppercase
                    decor-left
                >
                    {{ text.prodResults }} {{prodMonth}}M {{ operation.name }}
                </z-caption>
            </div>

            <div class="u-top-padding--s u-bottom-padding--m">
                <result-book
                    v-if="prodPressRelease.detailPageUrl"
                    :cover="image.press"
                    :title="text.pressRelease"
                    :href="prodPressRelease.detailPageUrl"
                ></result-book>
            </div>
        </template>

    </div>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'fin-results',
    props: {
        operation: Object,
        financial: Object
    },
    data () {
        return {
            image: {
                press: localize({
                    ru: '/images/book-cover-press.png',
                    en: '/images/book-cover-press-en.png'
                }),
                report: localize({
                    ru: '/images/book-cover-report.png',
                    en: '/images/book-cover-report-en.png'
                })
            },
            text: {
                caption: localize({
                    ru: 'Финансовые и операционные результаты',
                    en: 'Financial and Operation Results'
                }),
                finResults: localize({
                    ru: 'Финансовые результаты',
                    en: 'Financial results'
                }),
                prodResults: localize({
                    ru: 'Производственные результаты',
                    en: 'Production results'
                }),
                pressRelease: localize({
                    ru: 'Пресс-релиз',
                    en: 'Press release'
                }),
                finResUsd: localize({
                    ru: 'Финансовая отчетность (USD)',
                    en: 'Financial reporting (USD)'
                }),
                finResRub: localize({
                    ru: 'Финансовая отчетность (RUB)',
                    en: 'Financial reporting (RUB)'
                }),
                presentation: localize({
                    ru: 'Презентация',
                    en: 'Presentation'
                })
            }
        }
    },
    computed: {
        isFullFinYear () {
            return (
                this.financial.fr4Presentation.link ||
                this.financial.fr4PressReleases.detailPageUrl ||
                this.financial.fr4FinancialStatementsRub.link ||
                this.financial.fr4FinancialStatementsUsd.link
            )
        },
        finPresentation () {
            if (this.isFullFinYear) return this.financial.fr4Presentation
            return this.financial.fr2Presentation
        },
        finPressRelease () {
            if (this.isFullFinYear) return this.financial.fr4PressReleases
            return this.financial.fr2PressReleases
        },
        finReportUds () {
            if (this.isFullFinYear) return this.financial.fr4FinancialStatementsUsd
            return this.financial.fr2FinancialStatementsUsd
        },
        finReportRub () {
            if (this.isFullFinYear) return this.financial.fr4FinancialStatementsRub
            return this.financial.fr2FinancialStatementsRub
        },
        finReportDataBook () {
            if (this.isFullFinYear) return this.financial.ar4DataBook
            return this.financial.ar2DataBook
        },
        prodMonth () {
            if (this.operation.pr4PressRelease.detailPageUrl) return 12
            if (this.operation.pr3PressRelease.detailPageUrl) return 9
            if (this.operation.pr2PressRelease.detailPageUrl) return 6
            if (this.operation.pr1PressRelease.detailPageUrl) return 3
        },
        prodPressRelease () {
            if (this.prodMonth === 12) return this.operation.pr4PressRelease
            if (this.prodMonth === 9) return this.operation.pr3PressRelease
            if (this.prodMonth === 6) return this.operation.pr2PressRelease
            if (this.prodMonth === 3) return this.operation.pr1PressRelease
        }
    }
}
</script>

<style lang="scss">
.irp-fin-results {
    background-image: url('/images/investors/ir-highlights/bg-img.png'), $light-blue-gradient;
    background-repeat: no-repeat;
    background-position: 100% 100%, 50% 50%;
    background-size: 43% auto, 100% 100%;
}
</style>
