<template>
    <div class="media" ref="container" >
        <div  class="gray-gradient-bg" v-if="listPriority && listPriority.length !== 0">
            <div class="container media__priority">
                <div class="row">
                    <div class="col-default-4 col-tablet-6 col-mobile-12" v-for="item in listPriority" :key="item.code">
                        <media-card
                            type="list"
                            :image="item.picture ? item.picture : ''"
                            :caption="item.name"
                            :href="item.sectionPageUrl"
                            bg="white"
                            :content-count="currentCount(item)"
                            :content-type="currentType(item).image"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="container u-top-margin--m">
            <div class="row">
                <MediaFilter @change="onFilterChange" class="col-default-12"/>

                <page-404 v-if="!list || list.length === 0 && !isLoading" type="content" class="u-top-margin--3xl u-bottom-margin--3xl">
                    <z-caption
                        size="m"
                        tag="h3"
                        weight="700"
                        uppercase
                        decor-left
                        decor-right
                    >{{notFound}}</z-caption>
                    <p class="page-404__text page-404__text--xl-lite">
                        {{notFoundText}}<z-link size="xl" href="/news-and-media/media-library/#filter">{{notFoundText2}}</z-link>
                    </p>
                </page-404>

                <div
                    v-else-if="showCards"
                    class="col-default-4 col-tablet-6 col-mobile-12 _row-gap"
                    v-for="item in list"
                    :key="item.code"
                >
                    <media-card
                        type="list"
                        :image="item.picture ? item.picture : ''"
                        :caption="item.name"
                        :href="item.sectionPageUrl"
                        :content-count="currentCount(item)"
                        :content-type="currentType(item).image"
                    />
                </div>
                <div class="col-default-12 u-justify-content--center u-collapse--top">
                    <z-preloader v-if="isLoading" class="u-top-margin--l"></z-preloader>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { throttle } from 'throttle-debounce'
import { mixinDevice } from '@/utils/mixin'
import getMedia from '@/api/media'
import MediaFilter from './components/MediaFilter'
import { localize } from '@/utils/i18n'
import { queryString } from '@/utils/queryString'

export default {
    name: 'media',
    mixins: [mixinDevice],
    components: {
        MediaFilter
    },
    data () {
        return {
            isLoading: false,
            data: null,
            filters: null,
            list: [],
            listPriority: [],
            nav: {
                current: 1,
                total: 0
            },
            params: {
                page: 1,
                from: null,
                to: null,
                search: null,
                topic: null,
                type: null
            },
            showCards: true,
            notFound: localize({
                ru: 'К сожалению, ничего не найдено',
                en: 'Sorry, nothing found'
            }),
            notFoundText: localize({
                ru: 'Воспользуйтесь фильтром еще раз или ',
                en: 'Use the filter again or '
            }),
            notFoundText2: localize({
                ru: 'сбросьте параметры фильтра.',
                en: 'reset the filter parameters.'
            })
        }
    },

    methods: {
        currentType (item) {
            let image = 'mixed'
            let count = 'imagesCount'

            this.contentMaxCounterTypes(item).forEach(el => {
                const allMaxTypes = Object.keys(el)
                // Идут в порядке приоритета !
                if (allMaxTypes.includes('imagesCount')) {
                    image = ['image']
                    count = ['imagesCount']

                    if (item.videoCount && item.videoCount > 0) {
                        image = ['image', 'video']
                        count = ['imagesCount', 'videoCount']
                    }

                    return {count, image}
                }
                if (allMaxTypes.includes('videoCount')) {
                    image = ['video']
                    count = ['videoCount']
                    return {count, image}
                }
                if (allMaxTypes.includes('identityCount')) {
                    image = ['nornik']
                    count = ['identityCount']
                    return {count, image}
                }
                if (allMaxTypes.includes('interviewCount')) {
                    image = ['audio']
                    count = ['interviewCount']
                    return {count, image}
                }
                if (allMaxTypes.includes('financialResultsCount')) {
                    image = ['results']
                    count = ['financialResultsCount']
                    return {count, image}
                }
                if (allMaxTypes.includes('presentationCount')) {
                    image = ['presentations']
                    count = ['presentationCount']
                    return {count, image}
                }
                if (allMaxTypes.includes('historyCount')) {
                    image = ['history']
                    count = ['historyCount']
                    return {count, image}
                }
            })

            return {count, image}
        },
        currentCount (item) {
            const counts = []
            this.currentType(item).count.forEach(el => {
                counts.push(item[el])
            })
            return counts
        },
        contentMaxCounterTypes (item) {
            const contentCounts = []

            this.countNames.forEach((name) => {
                contentCounts.push({[name]: item[name]})
            })

            contentCounts.sort((prev, next) => {
                return Object.values(next)[0] - Object.values(prev)[0]
            })

            const maxCount = contentCounts[0]

            const allMaxCounts = contentCounts.filter((el) => {
                return Object.values(el)[0] === Object.values(maxCount)[0]
            })
            return allMaxCounts
        },
        clearEmptyParams (filters) {
            let params = Object.assign({}, filters)

            Object.keys(params).forEach(function (key) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        onFilterChange (data) {
            this.params = { ...this.params, ...data }
            this.params.page = 1
            queryString(this.clearEmptyParams(data))
            this.submit(true)
        },
        submit (filterChanged) {
            if (filterChanged) {
                this.showCards = false
            }
            this.isLoading = true
            getMedia(this.params)
                .then((res) => {
                    this.isLoading = false
                    this.showCards = true
                    if (this.params.page < 2) {
                        this.list = res.items
                    } else {
                        this.list = this.list.concat(res.items)
                    }
                    this.filters = res.filters
                    this.nav = res.nav
                })
                .catch((error) => {
                    this.isLoading = false
                    console.log(error)
                })
        },
        loadMore () {
            this.params.page++
            this.submit()
        },
        listenScroll () {
            window.addEventListener(
                'scroll',
                throttle(500, (e) => {
                    if (!this.$refs.container) return
                    const offsetTop = this.$refs.container.offsetTop
                    const offsetHeight = this.$refs.container.offsetHeight
                    const bottom = offsetTop + offsetHeight
                    const scroll =
                        window.pageYOffset || document.documentElement.scrollTop
                    const scrolled =
                        scroll + document.documentElement.clientHeight

                    if (
                        scrolled >= bottom - 100 &&
                        !this.isLoading &&
                        this.nav.current < this.nav.total
                    ) {
                        this.loadMore()
                    }
                })
            )
        }
    },

    computed: {
        countNames () {
            return [
                'imagesCount',
                'videoCount',
                'identityCount',
                'interviewCount',
                'financialResultsCount',
                'presentationCount',
                'historyCount'
            ]
        }
    },
    created () {
        if (this.$root.app.components['media-library']) {
            const response = this.$root.app.components['media-library']
            this.list = response.items
            this.listPriority = response.priority
            this.filters = response.filters
            this.nav = response.nav
        }
    },
    mounted () {
        this.listenScroll()
        this.submit()
    }
}
</script>

<style lang="scss" scoped>
    .media {
        &__priority {
            padding-top: 104px;
            padding-bottom: 104px;

            @include breakpoint (v-tablet) {
                padding-top: 48px;
                padding-bottom: 48px;
            }
        }
    }

    ._row-gap {
        margin-top: $token-spacers-l !important;
    }
</style>
