<template>
    <div
        class="banner"
        :class="getBannerClass"
        :style="getBannerStyle">
        <div class="banner__content">
            <div
                v-if="hasTitleSlot"
                class="banner__content-title">
                <slot name="title" />
            </div>
            <div
                v-if="hasContentSlot"
                class="banner__content-slot">
                <slot name="content" />
            </div>
            <z-button
                v-if="url && linkText"
                kind="white"
                :href="url"
                tag="a"
                :target="isExternalUrl && '_blank'"
                class="banner__content-btn">
                {{ linkText }}
                <template v-slot:right>
                    <z-icon
                        :name="icon"
                        width="24"
                        height="24"></z-icon>
                </template>
            </z-button>
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'banner',
    mixins: [mixinDevice],
    props: {
        padding: {
            type: String,
            validator: (prop) => ['s', 'm'].includes(prop),
            default: 'm'
        },
        linkText: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: 'external'
        },
        isExternalUrl: {
            type: Boolean,
            default: true
        },
        imgSrc: {
            type: String,
            default: ''
        },
        imgSrcTab: {
            type: String,
            default: ''
        },
        imgSrcMob: {
            type: String,
            default: ''
        }
    },
    computed: {
        getBannerStyle () {
            if (['mobile', 'v-tablet'].includes(this.device) && this.imgSrcMob) {
                return `background-image: url(${this.imgSrcMob})`
            } else if (['tablet'].includes(this.device) && this.imgSrcTab) {
                return `background-image: url(${this.imgSrcTab})`
            } else {
                return `background-image: url(${this.imgSrc})`
            }
        },
        getBannerClass () {
            return [this.padding && `banner--padding-${this.padding}`]
        },
        hasContentSlot () {
            return !!this.$slots['content']
        },
        hasTitleSlot () {
            return !!this.$slots['title']
        }
    }
}
</script>

<style lang="scss">
.banner {
    $parent: &;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 250px;
    border-top-right-radius: 40px;
    overflow: hidden;
    position: relative;

    @include breakpoint(v-tablet) {
        min-height: 620px;
    }
    @include breakpoint(mobile) {
        min-height: 440px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 32px;
        color: $token-colors-gray-60;

        @include breakpoint(mobile) {
            gap: 24px;
        }
    }

    &__content-title {
        & .z-caption {
            margin-bottom: 0;

            @include breakpoint(mobile) {
                &__text {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                    max-width: 100% !important;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    &__content-slot {
        max-width: 50%;

        @include breakpoint(v-tablet) {
            max-width: 100%;
        }
    }

    &__content-btn {
        width: fit-content;
        font-weight: 600;
    }

    &--padding-s {
        background-position: 80% 90%;
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        @include padding-level(left, S);
        @include padding-level(right, S);

        @include breakpoint(mobile) {
            @include padding-level(left, XS);
            @include padding-level(right, XS);
        }
    }

    &--padding-m {
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        @include padding-level(left, 2XL);
        @include padding-level(right, 2XL);

        @include breakpoint(mobile) {
            @include padding-level(left, XS);
            @include padding-level(right, XS);
        }
    }
}
</style>
