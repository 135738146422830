/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-small/pdf': {
    width: 50,
    height: 60,
    viewBox: '0 0 50 60',
    data: '<path pid="0" d="M0 11.695C0 5.236 5.236 0 11.695 0H50v48.304C50 54.764 44.764 60 38.304 60H0V11.695z" _fill="#fff"/><path pid="1" d="M11.576 35h-1.328V24.328h4.288c2.144 0 3.344 1.472 3.344 3.216 0 1.744-1.232 3.216-3.344 3.216h-2.96V35zm2.8-5.424c1.264 0 2.128-.832 2.128-2.032 0-1.2-.864-2.032-2.128-2.032h-2.8v4.064h2.8zM23.286 35H19.64V24.328h3.648c3.312 0 5.472 2.32 5.472 5.344 0 3.04-2.16 5.328-5.472 5.328zm0-1.184c2.593 0 4.097-1.856 4.097-4.144 0-2.304-1.456-4.16-4.096-4.16h-2.32v8.304h2.32zM32.17 35h-1.328V24.328h6.992v1.184H32.17v3.44h5.552v1.184H32.17V35z" _fill="#0077C8"/>'
  }
})
