<template>
    <div class="sale-page-list" v-if="items.length">
        <div class="sale-page-list-actions">
            <z-button
                kind="text"
                class="sale-page-list-actions__item"
                @click="toggleSelectAllCheckboxes"
            >
                <template #left>
                    <z-icon name="check-circle" width="20" height="20"/>
                </template>
                {{ isAllSelected ? 'Очистить все позиции' : 'Выбрать все позиции' }}
            </z-button>
            <z-button
                kind="text"
                class="sale-page-list-actions__item"
                @click="toggleShowSelected"
                :disabled="!checkedItems.length"
            >
                <template #left>
                    <z-icon name="eye" width="20" height="20"/>
                </template>
                {{ !showSelectedOnly ? 'Посмотреть выбранные' : 'Посмотреть все' }}
            </z-button>

            <download-excel-btn
                class="sale-page-list-actions__item"
                urlSections="illiquid/sale"
                :filters="{id: [...checkedItems]}"
                :disabled="!checkedItems.length"
            />

        </div>
        <template v-for="(item, index) in items">
            <div class="sale-page-card" :key="`sale-${index}`">
                <div class="sale-page-card__header">
                    <span class="sale-page-card__tag" v-html="item.roks" />
                </div>
                <div class="sale-page-card__wrapper">
                    <z-checkbox
                        :data="[{ value: item.id }]"
                        @change="setCheckboxArray"
                        v-model="checkedItems"
                        name="checkbox"
                    />
                    <div class="sale-page-card__body">
                        <span class="sale-page-card__text" v-html="item.name" />
                        <sale-page-table>
                            <sale-page-table-item class="sale-page-card__type" title="Наименование группы материалов">
                                <span v-if="item.type" v-html="item.type" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__subtype" title="Наименование подгруппы материалов">
                                <span v-if="item.subType" v-html="item.subType" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__tech-props" title="Технические характеристики">
                                <span v-if="item.techProps" v-html="item.techProps" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__brand" title="Марка">
                                <span v-if="item.brand" v-html="item.brand" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__gost" title="ГОСТ">
                                <span v-if="item.gost" v-html="item.gost" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__code" title="Код ЕНС">
                                <span v-if="item.code" v-html="item.code" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__amount" title="Кол-во к&nbsp;реал-ии">
                                <span v-if="item.amount" v-html="item.amount" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__amount-name" title="ЕИ кол.">
                                <span v-if="item.amountName" v-html="item.amountName" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                            <sale-page-table-item class="sale-page-card__price" title="Цена за&nbsp;единицу">
                                <span v-if="item.price" v-html="formattedNumber(item.price)" />
                                <span v-else>—</span>
                            </sale-page-table-item>
                        </sale-page-table>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import SalePageTable from './SalePageTable.vue'
import SalePageTableItem from './SalePageTableItem.vue'

export default {
    name: 'sale-page-list',
    components: {
        SalePageTable,
        SalePageTableItem
    },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            checkedItems: [], // чекбокс не работает без v-model
            isAllSelected: false,
            showSelectedOnly: false,
            unCheckedItems: []
        }
    },
    watch: {
        items (newVal) {
            if (this.isAllSelected) {
                let saleCheckboxArrayUncheckedPrev = JSON.parse(localStorage.getItem('saleCheckboxArrayUnchecked')) || []

                this.checkedItems = newVal.map(item => item.id).filter(x => !saleCheckboxArrayUncheckedPrev.includes(x))
            } else {
                this.checkedItems = []
            }
        }
    },
    methods: {
        setCheckboxArray () {
            if (this.isAllSelected) {
                this.unCheckedItems = this.items.map(item => item.id).filter(x => !this.checkedItems.includes(x))

                let saleCheckboxArrayUncheckedPrev = JSON.parse(localStorage.getItem('saleCheckboxArrayUnchecked')) || []
                let saleCheckboxArrayUnchecked = this.unCheckedItems.filter(x => !saleCheckboxArrayUncheckedPrev.includes(x))

                localStorage.setItem('saleCheckboxArrayUnchecked', JSON.stringify(saleCheckboxArrayUncheckedPrev.concat(saleCheckboxArrayUnchecked)))
            } else {
                let saleCheckboxArrayPrev = JSON.parse(localStorage.getItem('saleCheckboxArray')) || []
                localStorage.setItem('saleCheckboxArray', JSON.stringify(saleCheckboxArrayPrev.concat(this.checkedItems)))
            }
        },
        toggleSelectAllCheckboxes () {
            this.isAllSelected = !this.isAllSelected

            if (!this.isAllSelected) {
                this.checkedItems = []
                localStorage.setItem('saleCheckboxArrayUnchecked', JSON.stringify([]))
            } else {
                this.checkedItems = this.items.map(item => item.id)
            }

            localStorage.setItem('saleCheckboxArray', JSON.stringify(this.isAllSelected ? 'all' : []))

            this.$emit('all-selected', {
                id: JSON.parse(localStorage.getItem('saleCheckboxArray')),
                excludeId: JSON.parse(localStorage.getItem('saleCheckboxArrayUnchecked')) || []
            })
        },
        toggleShowSelected () {
            this.$emit('show-selected', {
                id: JSON.parse(localStorage.getItem('saleCheckboxArray')),
                excludeId: JSON.parse(localStorage.getItem('saleCheckboxArrayUnchecked')) || []
            })
        },
        formattedNumber (value) {
            const formattedValue = this.$options.filters.numberFilter(value)
            const normalizedValue = formattedValue.replace(',', '.').replace(/\s+/g, '');
            const numberValue = parseFloat(normalizedValue);
            if (!isNaN(numberValue)) {
                return numberValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
            return formattedValue;

        }
    },
    created () {
        localStorage.removeItem('saleCheckboxArray')
        localStorage.removeItem('saleCheckboxArrayUnchecked')
        // const saleCheckboxArray = localStorage.getItem('saleCheckboxArray')

        // if (saleCheckboxArray !== null) {
        //     this.checkedItems = JSON.parse(saleCheckboxArray)
        // }
    }
}
</script>

<style lang="scss">
.sale-page-list {
    @include margin-level(bottom, L);
}

.sale-page-list-actions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $token-spacers-s;
    margin-top: -$token-spacers-2-xs;

    &__item {
        margin-top: $token-spacers-2-xs;
        margin-right: $token-spacers-m;
        justify-content: flex-start !important;

        &:first-child {
            min-width: 240px;
        }

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint(mobile) {
            &.z-button {
                text-align: left;
            }
        }
    }
}

.sale-page-card {
    padding: $token-spacers-s $token-spacers-2-xs;
    border-top: 1px solid $token-colors-gray-20;

    @include breakpoint(v-tablet) {
        padding: $token-spacers-s 0;
    }

    &:last-child {
        border-bottom: 1px solid $token-colors-gray-20;
    }

    &__wrapper {
        display: flex;

        .z-checkbox-group {
            min-width: 40px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $token-spacers-xs;

        @include breakpoint(mobile) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__tag {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-white;
        text-align: center;
        padding: 2px 16px;
        border-radius: 20px;
        font-family: $robotoMono;
        background-color: $token-colors-blue;
        margin-left: $token-spacers-m;
    }

    &__date-wrapper {
        margin-left: $token-spacers-xs;
        display: flex;
        align-items: center;

        @include breakpoint(mobile) {
            margin-left: 0;
            margin-top: $token-spacers-3-xs;
        }
    }

    &__date-key {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-right: $token-spacers-3-xs;
        font-family: $robotoMono;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__date-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black;
        font-family: $robotoMono;

        &--hot {
            color: $token-colors-alert-text;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        margin-left: $token-spacers-3-xs;
    }

    &__text {
        display: block;
        color: $token-colors-gray-60;
        width: 100%;
        max-width: 1040px;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        font-family: $robotoMono;
        margin-bottom: $token-spacers-xs;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }

    &__type,
    &__subtype,
    &__tech-props,
    &__code {
        max-width: 33.3333%;

        @include breakpoint (tablet) {
            max-width: 50%;
        }
    }

    &__brand,
    &__gost {
        max-width: 16.66665%;

        @include breakpoint (tablet) {
            max-width: 50%;
        }
    }

    &__amount,
    &__price {
        max-width: 12.66665%;
    }

    &__amount-name {
        max-width: 8%;
    }

    &__amount,
    &__price,
    &__amount-name {
        @include breakpoint (tablet) {
            max-width: 50%;
        }
    }
}
</style>
