var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.length)?_c('div',{staticClass:"results-presentations"},[_c('z-caption',{staticClass:"u-bottom-margin--m",attrs:{"decor-right":"","tag":"h2","size":"3xl"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.text.presentation)}})]),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"col-default-4 col-desktop-6 col-v-tablet-12"},[_c('presentation-block',{attrs:{"pdfIconName":"file/text-pdf","pdfIconSize":{
                    width: '50px',
                    height: '18px',
                },"data":{
                    name: item.name,
                    size: item.mlDocs.size,
                    link: item.mlDocs.link,
                    image: item.mlAlbumImg.SRC

                }}})],1)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }