<template>
    <div
        :class="['tl-play-progress', {'tl-play-progress__pause' : !pause}]"
        @click="play"
    >
        <svg
            :height="radius * 2"
            :width="radius * 2"
        >
            <circle
                stroke="#0290F0"
                :stroke-dasharray="circumference + ' ' + circumference"
                :style="{ strokeDashoffset: strokeDashoffset }"
                :stroke-width="stroke"
                fill="transparent"
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'tl-play-progress',
    props: {
        radius: {
            type: Number,
            default: 37
        },
        progress: Number,
        stroke: {
            type: Number,
            default: 3
        },
        pause: {
            type: Boolean,
            default: false
        }
    },
    data () {
        const normalizedRadius = this.radius - this.stroke * 2
        const circumference = normalizedRadius * 2 * Math.PI

        return {
            normalizedRadius,
            circumference
        }
    },
    methods: {
        play () {
            this.$emit('play', !this.pause)
        }
    },
    computed: {
        strokeDashoffset () {
            return this.circumference - this.progress * this.circumference
        }
    },
    watch: {
        progress () {
            if (this.progress >= 1) {
                this.$emit('play', true)
            }
        }
    }
}
</script>

<style lang="scss">
.tl-play-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 76px;

    &:before {
        content: '';
        position: absolute;
        display: block;
        border: 1px solid $token-colors-gray-20;
        border-radius: 50%;
        width: 60px;
        height: 60px;
    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 23px;
        height: 28px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-left: 6px;
        background-image: url("data:image/svg+xml,%3Csvg width='23' height='28' viewBox='0 0 23 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.33151 0.649039C2.00049 -0.206618 0.25 0.749063 0.25 2.3314V25.6681C0.25 27.2504 2.00049 28.2061 3.33152 27.3504L21.4823 15.6821C22.7069 14.8948 22.7069 13.1046 21.4823 12.3174L3.33151 0.649039ZM2.25 2.3314L20.4007 13.9997L2.25 25.6681V2.3314Z' fill='%230290F0'/%3E%3C/svg%3E");
    }

    &__pause {
        &:after {
            width: 15px;
            height: 29px;
            margin-left: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='15' height='29' viewBox='0 0 15 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.5 1.87598C2.5 1.18562 1.94036 0.625977 1.25 0.625977C0.559644 0.625977 0 1.18562 0 1.87598V26.811C0 27.5014 0.559644 28.061 1.25 28.061C1.94036 28.061 2.5 27.5014 2.5 26.811V1.87598ZM14.5 1.87598C14.5 1.18562 13.9404 0.625977 13.25 0.625977C12.5596 0.625977 12 1.18562 12 1.87598V26.811C12 27.5014 12.5596 28.061 13.25 28.061C13.9404 28.061 14.5 27.5014 14.5 26.811V1.87598Z' fill='%230290F0'/%3E%3C/svg%3E");
        }
    }

    circle {
        position: relative;
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
}
</style>
