<template>
<div class="tags-filter">
    <z-caption
        tag="h2"
        decor-left
        decor-right
        size="m"
        weight="700"
        uppercase
        class="u-top-margin--m"
        v-if="title"
    >
        <span v-html="title"></span>
    </z-caption>
    <div v-if="showFilter" class="tags-filter__nav">
        <button v-for="(item) in filterButtons"
                class="tags-filter__nav-item"
                :class="[{'tags-filter__nav-item--active': checkActive(item)},
                {'tags-filter__nav-item--not-active': checkDisabled(item)}]"
                :disabled="checkActive(item)"
                @click="buttonHandler(item)"
                :key="item">
            {{text[item]}}
        </button>
    </div>
    <div class="tags-filter__list">
        <tag
            class="tags-filter__item"
            v-for="(item) in tags"
            ref="tag"
            :key="item.id"
            :id="item.id"
            theme="light-blue"
            size="m"
            :name="item.text"
            @change="onChange"
            :disabled="item.disabled"
            :selected="item.selected"
        />
    </div>
    <z-button
        class="u-top-margin--s"
        kind="secondary"
        size="s"
        @click="clear"
        v-if="selected.length"
    >
        <template #right>
            <z-icon name="close" width="16" height="16" />
        </template>
        <span class="u-bold" v-html="clearText"></span>
    </z-button>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'tags-filter',
    props: {
        title: String,
        name: {
            type: String,
            required: true
        },
        data: {
            type: Array,
            default: () => []
        },
        clearText: {
            type: String,
            default: localize({
                ru: 'Сбросить теги',
                en: 'Reset tags'
            })
        },
        showFilter: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            selected: [],
            selectedButton: '',
            filterButtons: ['one', 'two', 'three', 'four', 'five'],
            text: {
                one: localize({
                    ru: 'A – Ё',
                    en: 'A – F'
                }),
                two: localize({
                    ru: 'Ж – Л',
                    en: 'G – K'
                }),
                three: localize({
                    ru: 'М – С',
                    en: 'L – P'
                }),
                four: localize({
                    ru: 'Т – Ч',
                    en: 'Q – U'
                }),
                five: localize({
                    ru: 'Ш – Я',
                    en: 'V – Z'
                })
            },
            regExp: {
                one: localize({
                    ru: /^[а-её]/i,
                    en: /^[a-f]/i
                }),
                two: localize({
                    ru: /^[ж-л]/i,
                    en: /^[g-k]/i
                }),
                three: localize({
                    ru: /^[м-с]/i,
                    en: /^[l-p]/i
                }),
                four: localize({
                    ru: /^[т-ч]/i,
                    en: /^[q-u]/i
                }),
                five: localize({
                    ru: /^[ш-я]/i,
                    en: /^[v-z]/i
                })
            }
        }
    },
    computed: {
        tags () {
            const result = this.data.slice()
            if (this.showFilter) {
                if (this.selectedButton === '') {
                    return []
                }
                return result.filter((item) => this.regExp[this.selectedButton].test(item.text))
            }
            return result
        }
    },
    methods: {
        onChange (data) {
            const index = this.selected.indexOf(data.id)
            if (!data.value && index !== -1) this.selected.splice(index, 1)
            if (data.value && index === -1) this.selected.push(data.id)

            this.sendTags()
        },
        clear (inner = true) {
            if (this.showFilter) {
                this.selected = []
                if (inner) {
                    if (this.$refs.tag) {
                        this.$refs.tag.forEach(item => item.clear(true))
                    }
                    this.sendTags()
                }
                return
            }
            if (this.$refs.tag) {
                this.$refs.tag.forEach(item => item.clear())
            }
        },
        buttonHandler (button) {
            if (this.selectedButton !== button) {
                this.selectedButton = button
            }
        },
        sendTags () {
            this.$emit('change', {
                name: this.name,
                value: this.selected
            })
        },
        checkActive (data) {
            return this.selectedButton === data
        },
        checkDisabled (data) {
            const result = this.data.slice()
            if (result.length) {
                return result.filter((item) => this.regExp[data].test(item.text)).length === 0
            }
            return true
        },
        initSelectedButton () {
            const data = this.data.slice()
            if (data.length === 0) {
                return
            }

            if (this.showFilter) {
                let result = []
                let i = 0

                while (result.length === 0) {
                    result = data.filter((item) => this.regExp[this.filterButtons[i]].test(item.text))
                    if (this.filterButtons.length - 1 === i) {
                        break
                    }
                    if (result.length) {
                        this.selectedButton = this.filterButtons[i]
                        break
                    }
                    i++
                }
                return result
            }
        }
    },
    created () {
        this.selected = this.data.filter(item => item.selected).map(item => item.id)
        this.initSelectedButton()
    }
}
</script>

<style lang="scss">
.tags-filter {
    &__item {
        margin-top: $token-spacers-2-xs;
        margin-left: $token-spacers-3-xs/2;
        margin-right: $token-spacers-3-xs/2;

        &.tag--active {
            padding: 7px 16px;
        }
    }

    &__nav {
        display: flex;
        margin-bottom: $token-spacers-m;

        @include breakpoint(laptop) {
            flex-wrap: wrap;
        }
    }

    &__nav-item {
        padding: $token-spacers-3-xs $token-spacers-2-xs;
        border: none;
        background-color: $token-colors-gray-10;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $token-colors-blue;
        transition: background-color ease 300ms;

        @include breakpoint(laptop) {
            flex-grow: 1;
            flex-shrink: 1;
            width: calc(50% - 1px);
            border-radius: 10px !important;
            margin-bottom: 1px;
        }

        &:nth-child(even) {
            @include breakpoint(laptop) {
                margin-right: 1px;
            }
        }

        &:first-child {
            border-radius: 10px 0 0 10px;
        }
        &:last-child {
            border-radius: 0 10px 10px 0;
        }

        &:hover {
            background-color: $token-colors-blue-20;
        }

        &--active,
        &:disabled {
            background-color: $token-colors-blue;
            color: $token-colors-white;
        }

        &--not-active {
            pointer-events: none;
            background-color: $token-colors-blue-10;
            color: $token-colors-gray-30;
        }
    }
}
</style>
