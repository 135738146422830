<template>
<div class="calendar-results" v-if="data.length">
    <template v-for="(item, index) in data">
        <z-caption
            class="u-capitalize"
            size="3xl"
            tag="h3"
            decor-right
            v-if="!isSameMonth(item, index)"
            :key="index"
        >
            {{ item.start | momentFilter('MMMM YYYY') }}
        </z-caption>

        <item
            class="calendar-results__item"
            :key="`${index}-item`"
            :data="item"
            :typeOfCards="typeOfCards"
        />
    </template>
</div>
</template>

<script>
import moment from 'moment'
import Item from './Item.vue'

export default {
    name: 'calendar-results',
    components: {
        Item
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        typeOfCards: {
            type: String,
            required: true
        }
    },
    methods: {
        isSameMonth (item, index) {
            if (index === 0) return false
            const currentMonth = moment.unix(this.data[index].start).utc().add(3, 'hours').format('MM')
            const prevMonth = moment.unix(this.data[index - 1].start).utc().add(3, 'hours').format('MM')

            return currentMonth === prevMonth
        },

        editMonthName () {
            moment.locale(this.$root.lang, {
                months: this.$root.lang === 'ru'
                    ? ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
                    : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            })
        }
    },
    created () {
        this.editMonthName()
    },
    watch: {
        data () {
            this.editMonthName()
        }
    }
}
</script>

<style lang="scss">
.calendar-results {
    min-height: 400px;

    &__item {
        @include margin-level(bottom, S);

        & + .z-caption {
            @include margin-level(top, XL);
        }
    }
}
</style>
