
<template>
<div class="products-applications" v-if="data">
    <z-figure :image="data.image">
        <template #caption>
            <div>
                <z-caption size="l" tag="h3" class="u-bottom-margin--xs-important">
                    <span v-html="text.applications"></span>
                </z-caption>
                <z-list class="products-applications__list">
                    <z-list-item
                        v-for="(item, index) in data.list"
                        :key="index"
                    >
                        <span v-html="item"></span>
                    </z-list-item>
                </z-list>
            </div>
        </template>
    </z-figure>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'products-applications',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                applications: localize({
                    ru: 'Сфера применения',
                    en: 'Applications'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.products-applications {
    .z-figure__image {
        background-size: cover;
        background-position: 50% 50%;
    }

    .z-figure__caption {
        @include breakpoint (v-tablet) {
            border-bottom-left-radius: 15px;
        }
    }

    &__list {
        margin-top: -$token-spacers-3-xs;
        margin-left: -$token-spacers-3-xs;
        margin-right: -$token-spacers-3-xs;
        font-weight: 400;

        .z-list-item {
            padding-left: $token-spacers-2-xs !important;
            display: inline-block;
            margin-top: $token-spacers-3-xs !important;
            margin-left: $token-spacers-3-xs;
            margin-right: $token-spacers-3-xs;

            &:before {
                left: 0 !important;
            }
        }
    }
}
</style>
