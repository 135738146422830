/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'man': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.15a3.85 3.85 0 100 7.7 3.85 3.85 0 000-7.7zM9.35 7a2.65 2.65 0 115.3 0 2.65 2.65 0 01-5.3 0zM12 13.15c-1.912 0-3.635.444-4.875 1.152C5.877 15.015 5.15 15.975 5.15 17c0 1.026.727 1.986 1.975 2.699 1.24.708 2.963 1.151 4.875 1.151s3.635-.443 4.875-1.151c1.247-.713 1.975-1.673 1.975-2.699 0-1.025-.728-1.985-1.975-2.698-1.24-.708-2.963-1.152-4.875-1.152zm-4.28 2.194c1.028-.587 2.538-.994 4.28-.994 1.742 0 3.252.407 4.28.994 1.063.608 1.37 1.243 1.37 1.656 0 .413-.306 1.049-1.37 1.657-1.028.587-2.539.993-4.28.993-1.742 0-3.252-.406-4.28-.993-1.064-.608-1.37-1.244-1.37-1.657 0-.413.306-1.048 1.37-1.656z" _fill="#0077C8"/>'
  }
})
