<template>
    <div class="map-container" ref="map"></div>
</template>

<script>
import {mixinDevice} from '@/utils/mixin'
import {localize} from '@/utils/i18n'

export default {
    name: 'map-container',
    mixins: [mixinDevice],
    props: {
        center: Array,
        coords: Array,
        zoom: {
            type: Number,
            default: 10
        },
        selected: {
            type: Array
        }
    },
    computed: {
        currentDevice () {
            if (this.isMobile() || this.isTablet()) {
                return 'mobile'
            } else {
                return 'desktop'
            }
        },
        mapLocale () {
            let lang
            this.$root.lang === 'en' ? lang = 'en_Es' : lang = 'ru_Ru'

            return lang
        }
    },
    data () {
        return {
            isApiLoaded: false,
            map: undefined,
            key: 'a2150a92-986c-468b-b534-e3b9f4f1f102',
            collection: null,
            layout: null,
            icon: {
                mobile: {
                    iconImageSize: [24, 29],
                    iconImageOffset: [-12, -24],
                    iconContentOffset: [26, 0]
                },
                desktop: {
                    iconImageSize: [39, 48],
                    iconImageOffset: [-24, -48],
                    iconContentOffset: [48, 5]
                }
            },
            iconContent: localize({
                ru: '',
                en: ''
            })
        }
    },
    methods: {
        async init () {
            const map = this.$refs.map
            await this.addYandexApi()

            if (this.isApiLoaded) {
                /* eslint-disable no-undef */
                await ymaps.ready()
                this.map = new ymaps.Map(map, {
                    zoom: this.zoom,
                    center: this.center,
                    controls: [],
                    type: 'yandex#satellite'
                }, {
                    suppressMapOpenBlock: true
                })
                // Создаём макет содержимого.
                this.layout = ymaps.templateLayoutFactory.createClass(
                    '<div>$[properties.iconContent]</div>'
                )
                this.map.controls.add('zoomControl', {position: {right: '40px', top: '100px'}})
                this.map.behaviors.disable('scrollZoom')
                this.collection = new ymaps.GeoObjectCollection({}, {})
                this.addPoints()
            }
        },
        addYandexApi () {
            return new Promise((resolve, reject) => {
                this.apiUrl = `https://api-maps.yandex.ru/2.1/?apikey=${this.key}&lang=${this.mapLocale}`
                const script = document.createElement('script')
                script.async = true
                script.src = this.apiUrl
                document.head.appendChild(script)
                script.onload = () => {
                    this.isApiLoaded = true
                    resolve()
                }
            })
        },
        addPoints () {
            this.collection.removeAll()
            if (this.coords.length) {
                const self = this
                this.coords.forEach((item) => {
                    const point = new ymaps.Placemark(item.coords,
                        {
                            iconContent: this.iconContent
                        },
                        {
                            // Опции.
                            // Необходимо указать данный тип макета.
                            iconLayout: 'default#imageWithContent',
                            // Своё изображение иконки метки. png - потому что IE
                            iconImageHref: this.getPin(item.type),
                            // Размеры метки.
                            iconImageSize: this.icon[this.currentDevice].iconImageSize,
                            // Смещение левого верхнего угла иконки относительно
                            // её "ножки" (точки привязки).
                            iconImageOffset: this.icon[this.currentDevice].iconImageOffset,
                            // Смещение слоя с содержимым относительно слоя с картинкой.
                            iconContentOffset: this.icon[this.currentDevice].iconContentOffset,
                            // Макет содержимого.
                            iconContentLayout: this.layout
                        })
                    point.events.add(['click'], function () {
                        self.selectItem(item)
                    })

                    this.collection.add(point)
                })
            }
            this.map.geoObjects.add(this.collection)
        },
        selectItem (item) {
            this.$emit('click-point', item)
        },
        getPin (type) {
            switch (type) {
            case ('completed'): {
                return '/images/ya-map/green-pin-min.svg'
            }
            case ('in-process'): {
                return '/images/ya-map/yellow-pin-min.svg'
            }
            case ('coming-soon'): {
                return '/images/ya-map/blue-pin-min.svg'
            }
            default: {
                return '/images/ya-map/blue-pin-min.svg'
            }
            }
        }
    },
    watch: {
        coords () {
            this.addPoints()
        },
        selected () {
            if (!this.selected.length) {
                this.map.setCenter(this.center, this.zoom, {
                    duration: 300,
                    checkZoomRange: true
                })
                return
            }
            this.map.setCenter(this.selected, 18, {
                duration: 300,
                checkZoomRange: true
            })
        }
    },
    mounted () {
        this.init()
    },
    beforeDestroy () {
        this.map.destroy()
        const apiScript = document.querySelector(`script[src="${this.apiUrl}"]`)
        if (apiScript) apiScript.remove()
    }
}
</script>

<style lang="scss">
.map-container {
    height: calc(90vh - 55px);
    width: 100%;
}
</style>
