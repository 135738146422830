<template>
    <div
        :class="[
            'z-date-picker',
            {
                'is-disabled': this.disabled,
                'is-filled': isFilled,
                'is-errored': isValid === false,
                'is-valid': isValid
            }
        ]"
    >
        <date-picker
            v-bind="$attrs"
            :key="lang"
            :type="type"
            :lang="locale"
            v-model="date"
            :range="range"
            :format="format"
            @change="change"
            :title-format="format"
            :clearable="clearable"
            :value-type="valueType"
            :placeholder="placeholder"
            :disabled-date="disabledDate"
            popup-class="z-date-picker-popup"
            :append-to-body="appendToBody"
        >
        </date-picker>
        <span
            :class="['z-date-picker__error', errorClass]"
            v-html="error"
            v-if="error && !isValid"
        ></span>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import DatePicker from 'vue2-datepicker'
import { localize } from '@/utils/i18n'
const ruLangDatePicker = require(`vue2-datepicker/locale/ru`)
const enLangDatePicker = require(`vue2-datepicker/locale/en`)

DatePicker.methods.displayPopup = function () {
    this.position = {
        left: 0,
        bottom: '100%'
    }
}

export default {
    name: 'z-date-picker',
    components: {
        DatePicker
    },
    props: {
        range: Boolean,
        disabled: Boolean,
        clearable: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'date',
            validator: (prop) =>
                ['date', 'datetime', 'year', 'month', 'time', 'week'].includes(prop)
        },
        valueType: {
            type: String,
            default: 'X'
        },
        placeholder: {
            type: String,
            default: ''
        },
        format: {
            type: String,
            default: 'DD.MM.YYYY'
        },
        value: [Array, String],
        disabledDate: {
            type: Function,
            default: () => {}
        },
        errorClass: {
            type: String,
            default: ''
        },
        appendToBody: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            isValid: null,
            date: this.value,
            lang: this.$root.lang,
            locale: this.$root.lang === 'ru' ? ruLangDatePicker : enLangDatePicker,
            error: '',
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field'
                }
            }
        }
    },
    watch: {
        value (value) {
            this.date = value
            this.$emit('input', this.date)
            this.$emit('change', this.date)
        }
    },
    computed: {
        isFilled () {
            if (Array.isArray(this.date)) {
                return Boolean(this.date[0] && this.date[1])
            }

            return Boolean(this.date)
        }
    },
    methods: {
        change (data) {
            let result = data

            if (Array.isArray(data) && !(data[0] && data[1])) {
                result = undefined
            }

            this.$emit('input', result)
            this.$emit('change', result)
            this.validate()
        },
        validate () {
            if (this.required && !this.isFilled) {
                this.isValid = false
                this.error = localize(this.text.error)
                return
            }

            this.isValid = true
            this.error = ''
        }
    }
}
</script>

<style lang="scss">
$errorColorLight: $token-colors-alert;
$errorColor: $token-colors-alert-text;
$validColor: $token-colors-success;
$hoverColor: $token-colors-gray-35;
$focusColor: $token-colors-blue;
$defaultColor: $token-colors-gray-30;

.z-date-picker {
    font-size: 16px;

    &__error {
        font-size: 0.8125em;
        color: $errorColor;
        position: relative;
        left: 20px;
    }

    .mx-datepicker {
        width: 100%;
        position: static;
    }

    .mx-input {
        border: 1px solid $defaultColor;
        border-radius: 0;
        box-shadow: none;
        padding: 10px 18px;
        padding-right: 52px;
        box-sizing: border-box;
        font-size: 1em;
        height: auto;
        transition: border 0.2s ease-in;
        line-height: 1.5;
        border-radius: 4px;
        color: $token-colors-gray-60;

        @include breakpoint(v-tablet) {
            padding: 8px 10px;
            padding-right: 32px;
            font-size: 14px;
        }

        &:hover {
            border-color: $hoverColor;
        }

        &:focus {
            border-color: $focusColor;
        }
    }

    .mx-icon-calendar {
        display: block !important;
        transition: background 0.2s ease-in;
        width: 24px;
        height: 24px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.5999 2.00002C16.5999 1.66865 16.3313 1.40002 15.9999 1.40002C15.6685 1.40002 15.3999 1.66865 15.3999 2.00002V3.40002H8.5999V2.00002C8.5999 1.66865 8.33127 1.40002 7.9999 1.40002C7.66853 1.40002 7.3999 1.66865 7.3999 2.00002V3.40002H4.9999C3.56396 3.40002 2.3999 4.56408 2.3999 6.00002V10V20C2.3999 21.436 3.56396 22.6 4.9999 22.6H18.9999C20.4358 22.6 21.5999 21.436 21.5999 20V10V6.00002C21.5999 4.56408 20.4358 3.40002 18.9999 3.40002H16.5999V2.00002ZM20.3999 9.40002V6.00002C20.3999 5.22683 19.7731 4.60002 18.9999 4.60002H16.5999V6.00002C16.5999 6.3314 16.3313 6.60002 15.9999 6.60002C15.6685 6.60002 15.3999 6.3314 15.3999 6.00002V4.60002H8.5999V6.00002C8.5999 6.3314 8.33127 6.60002 7.9999 6.60002C7.66853 6.60002 7.3999 6.3314 7.3999 6.00002V4.60002H4.9999C4.2267 4.60002 3.5999 5.22683 3.5999 6.00002V9.40002H20.3999ZM3.5999 10.6H20.3999V20C20.3999 20.7732 19.7731 21.4 18.9999 21.4H4.9999C4.2267 21.4 3.5999 20.7732 3.5999 20V10.6Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
        right: 10px;

        @include breakpoint(v-tablet) {
            width: 18px;
            height: 18px;
        }

        svg {
            display: none;
        }
    }

    .mx-icon-clear {
        display: block;
        right: 44px;
        font-size: 1.125em;
        transition: color 0.2s ease-in;

        &:hover {
            color: $hoverColor;
        }
    }

    // filled state
    &.is-filled {
        .mx-input {
            border-color: $focusColor;
        }
    }

    // valid state
    &.is-valid {
        .mx-input {
            // border-color: $validColor;
        }

        .mx-icon-calendar {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.5999 2.00002C16.5999 1.66865 16.3313 1.40002 15.9999 1.40002C15.6685 1.40002 15.3999 1.66865 15.3999 2.00002V3.40002H8.5999V2.00002C8.5999 1.66865 8.33127 1.40002 7.9999 1.40002C7.66853 1.40002 7.3999 1.66865 7.3999 2.00002V3.40002H4.9999C3.56396 3.40002 2.3999 4.56408 2.3999 6.00002V10V20C2.3999 21.436 3.56396 22.6 4.9999 22.6H18.9999C20.4358 22.6 21.5999 21.436 21.5999 20V10V6.00002C21.5999 4.56408 20.4358 3.40002 18.9999 3.40002H16.5999V2.00002ZM20.3999 9.40002V6.00002C20.3999 5.22683 19.7731 4.60002 18.9999 4.60002H16.5999V6.00002C16.5999 6.3314 16.3313 6.60002 15.9999 6.60002C15.6685 6.60002 15.3999 6.3314 15.3999 6.00002V4.60002H8.5999V6.00002C8.5999 6.3314 8.33127 6.60002 7.9999 6.60002C7.66853 6.60002 7.3999 6.3314 7.3999 6.00002V4.60002H4.9999C4.2267 4.60002 3.5999 5.22683 3.5999 6.00002V9.40002H20.3999ZM3.5999 10.6H20.3999V20C20.3999 20.7732 19.7731 21.4 18.9999 21.4H4.9999C4.2267 21.4 3.5999 20.7732 3.5999 20V10.6Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
        }
    }

    // errored state
    &.is-errored {
        .mx-input {
            border-color: $errorColorLight;
        }

        .mx-icon-calendar {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.5999 2.00002C16.5999 1.66865 16.3313 1.40002 15.9999 1.40002C15.6685 1.40002 15.3999 1.66865 15.3999 2.00002V3.40002H8.5999V2.00002C8.5999 1.66865 8.33127 1.40002 7.9999 1.40002C7.66853 1.40002 7.3999 1.66865 7.3999 2.00002V3.40002H4.9999C3.56396 3.40002 2.3999 4.56408 2.3999 6.00002V10V20C2.3999 21.436 3.56396 22.6 4.9999 22.6H18.9999C20.4358 22.6 21.5999 21.436 21.5999 20V10V6.00002C21.5999 4.56408 20.4358 3.40002 18.9999 3.40002H16.5999V2.00002ZM20.3999 9.40002V6.00002C20.3999 5.22683 19.7731 4.60002 18.9999 4.60002H16.5999V6.00002C16.5999 6.3314 16.3313 6.60002 15.9999 6.60002C15.6685 6.60002 15.3999 6.3314 15.3999 6.00002V4.60002H8.5999V6.00002C8.5999 6.3314 8.33127 6.60002 7.9999 6.60002C7.66853 6.60002 7.3999 6.3314 7.3999 6.00002V4.60002H4.9999C4.2267 4.60002 3.5999 5.22683 3.5999 6.00002V9.40002H20.3999ZM3.5999 10.6H20.3999V20C20.3999 20.7732 19.7731 21.4 18.9999 21.4H4.9999C4.2267 21.4 3.5999 20.7732 3.5999 20V10.6Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
        }
    }
}

.z-date-picker-popup {
    max-width: 400px !important;
    width: 100%;

    .mx-btn-text {
        font-weight: inherit;
        color: inherit;
        font-size: inherit;
        text-transform: capitalize;
        font-size: 22px;
        font-weight: 400;

        @include breakpoint(v-tablet) {
            font-size: 18px;
        }
    }

    .mx-calendar {
        padding: 24px 24px 16px;
        width: 100% !important;

        @include breakpoint(v-tablet) {
            padding: 16px;
        }
    }

    .mx-calendar-header {
        padding: 0;
        margin-bottom: 24px;
        height: auto;
        line-height: 1;

        @include breakpoint(v-tablet) {
            margin-bottom: 16px;
        }
    }

    .mx-calendar-header-label {
        font-weight: 700;
        color: #000;
        font-size: 14px;
    }

    .mx-calendar-content {
        height: auto;
    }

    .mx-icon-left:before,
    .mx-icon-right:before,
    .mx-icon-double-left:before,
    .mx-icon-double-right:before,
    .mx-icon-double-left:after,
    .mx-icon-double-right:after {
        width: 16px;
        height: 16px;
        color: $token-colors-blue-50;
        border-width: 3px 0 0 3px;

        @include breakpoint(v-tablet) {
            width: 14px;
            height: 14px;
        }
    }

    .cell {
        color: $token-colors-gray-60;
        font-weight: 400;
        font-size: 20px;
        padding: 5px;
        line-height: 1;
        background: $token-colors-white;

        @include breakpoint(v-tablet) {
            padding: 6px;
            font-size: 14px;
        }

        div {
            transition: background 0.2s ease-in;
            height: 32px;
            min-height: 32px;
            line-height: 32px;
            background: $token-colors-white;
            border-radius: 4px;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            width: 40px;
        }

        &.today {
            background: $token-colors-white;
            color: $token-colors-white;
            padding: 5px;

            div {
                background: $token-colors-blue-50 !important;
                width: 40px;
                margin: 0 auto;
            }
        }

        &.active {
            background: $token-colors-white;
            color: $token-colors-white;
            padding: 5px;

            div {
                background: $token-colors-blue !important;
                width: 40px;
                margin: 0 auto;
            }
        }

        &.not-current-month {
            color: $token-colors-gray-35;
        }

        &.in-range {
            background: $token-colors-white;
            padding: 5px;

            div {
                background: $token-colors-blue-10;
                color: $token-colors-white;
                width: 40px;
                margin: 0 auto;
            }
        }

        &:hover {
            background: $token-colors-white;
            padding: 5px;

            div {
                background: $token-colors-blue-10;
                width: 40px;
                margin: 0 auto;
            }
        }
    }

    .mx-table-month,
    .mx-table-year {
        .cell {
            div {
                width: auto;
            }

            &.active {
                background: $token-colors-white;
                color: $token-colors-white;
                padding: 5px;

                div {
                    background: $token-colors-blue !important;
                    width: 70px;
                    margin: 0 auto;
                }
            }

            &:hover {
                background: $token-colors-white;

                div {
                    background: $token-colors-blue-10 !important;
                    width: 70px;
                    margin: 0 auto;
                }
            }
        }
    }

    .mx-table {
        margin: 0;

        th {
            color: $token-colors-gray-30;
            font-weight: 400;
            font-size: 16px;
            text-transform: capitalize;
        }
    }

    /* nav arrows */
    .mx-icon-right,
    .mx-btn-icon-double-right,
    .mx-btn-icon-left,
    .mx-btn-icon-double-left {
        color: #000;
        transition: color 0.2s ease-in;

        &:hover {
            color: $hoverColor;
        }
    }
    /* /nav arrows */
}
</style>
