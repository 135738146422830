/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_ecology': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<rect pid="0" width="38" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M30.215 10.74a.75.75 0 00-.704-.705c-4.853-.281-8.75 1.194-10.424 3.958-1.106 1.827-1.104 4.046-.015 6.163a6.69 6.69 0 00-1.328 2.532l-1.525-1.531c.733-1.531.705-3.127-.094-4.453-1.238-2.042-4.094-3.137-7.64-2.93a.75.75 0 00-.706.706c-.209 3.546.887 6.403 2.93 7.64.674.412 1.449.63 2.239.63a5.116 5.116 0 002.208-.531l2.344 2.343v2.688a.75.75 0 101.5 0v-2.764a5.213 5.213 0 011.15-3.282 6.93 6.93 0 003.123.788 5.709 5.709 0 002.984-.83c2.764-1.673 4.243-5.57 3.958-10.422zM11.482 20.838c-1.438-.871-2.252-2.933-2.232-5.588 2.655-.022 4.717.794 5.588 2.233.454.75.528 1.624.23 2.525L12.78 17.72a.75.75 0 00-1.06 1.06l2.288 2.288c-.901.299-1.775.224-2.526-.23zm13.998-.958c-1.256.76-2.733.818-4.233.187l5.034-5.034a.75.75 0 00-1.06-1.06L20.186 19c-.634-1.5-.576-2.977.188-4.232 1.306-2.157 4.406-3.359 8.374-3.266.09 3.968-1.11 7.07-3.268 8.378z" _fill="#fff"/>'
  }
})
