/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refining': {
    width: 83,
    height: 67,
    viewBox: '0 0 83 67',
    data: '<path pid="0" d="M7.364 12.32H3.308m4.056 0l-.226-3.154m.226 3.154l.45 5.182M3.308 12.32l.225-3.154m-.225 3.154l-.225 5.182m.45-8.336h3.605m-3.605 0l.226-3.83h3.154l.225 3.83m.902 11.49H2.857m5.183 0l-.226-3.154m.226 3.155l3.38 45.738M2.856 20.657l.226-3.155m-.226 3.155L.604 66.395H11.42M3.083 17.502h4.731m3.605 48.893h40.782M18.63 46.568l2.479-34.473h3.83l2.253 34.473m-8.562 0h8.562m-8.562 0H9.954m17.237 0h25.01v19.827m0 0h29.967v-25.46H33.275v5.633M62.79 12.546h-2.478m.225-4.957h2.028m11.04 18.25h-2.253m.225-4.28h1.803M59.186 40.935L60.763.604h1.577l1.577 40.331M8.716 29.669h-6.31l.164-3.176 5.903.022.243 3.154zm.589 8.112H2.014l.155-3.155h6.903l.233 3.155zm.616 8.336H1.603l.156-3.154h7.929l.233 3.154zm.666 9.013H1.159l.156-3.155h9.039l.233 3.155zm.585 7.913H.769l.15-3.051h10.027l.226 3.051zm64.462-5.435h-1.577V47.47h1.577v10.14zm-6.76-.901h-1.577v-10.14h1.577v10.14zm-6.76-1.352h-1.576v-10.14h1.577v10.14zm9.014-14.42l.675-21.405h1.578l.9 21.405h-3.154z" _stroke="#1A1B25" stroke-width="1.2" stroke-miterlimit="10" stroke-linejoin="round"/>'
  }
})
