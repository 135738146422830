/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attention': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<g clip-path="url(#clip0_9226_31689)"><path pid="0" d="M19 16.56c0 .468-.157.825-.47 1.071-.312.246-.697.369-1.152.369H1.622c-.455 0-.84-.126-1.153-.378C.156 17.37 0 17.016 0 16.56c0-.36.102-.726.307-1.098L8.177.99C8.553.33 8.997 0 9.509 0c.512 0 .944.33 1.297.99l7.887 14.49c.205.384.307.744.307 1.08zm-8.314-9.198V5.734a1 1 0 00-1-1h-.372a1 1 0 00-1 1v1.628c0 .168.01.327.034.477.022.15.054.318.094.504s.07.345.093.477l.444 2.916h1.008l.46-2.916c.023-.12.057-.276.103-.468.045-.192.08-.363.102-.513.023-.15.034-.309.034-.477zm0 6.966c0-.348-.116-.642-.35-.882a1.136 1.136 0 00-.845-.36c-.318 0-.594.12-.827.36-.234.24-.35.534-.35.882s.116.645.35.891c.233.246.509.369.827.369.33 0 .612-.123.845-.369.234-.246.35-.543.35-.891z" _fill="#D70C1E"/></g><defs><clipPath id="clip0_9226_31689"><path pid="1" _fill="#fff" d="M0 0h19v18H0z"/></clipPath></defs>'
  }
})
