/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/docx': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM11.123 30h2.48c2.08 0 3.49-1.37 3.49-3.33s-1.41-3.34-3.49-3.34h-2.48V30zm4.77-3.33c0 1.26-.84 2.3-2.29 2.3h-1.31v-4.61h1.31c1.49 0 2.29 1.02 2.29 2.31zm2.03 0c0 1.99 1.41 3.45 3.41 3.45s3.41-1.46 3.41-3.45c0-1.99-1.41-3.45-3.41-3.45s-3.41 1.46-3.41 3.45zm5.62 0c0 1.37-.87 2.41-2.21 2.41-1.35 0-2.21-1.04-2.21-2.41 0-1.38.86-2.41 2.21-2.41 1.34 0 2.21 1.03 2.21 2.41zm2.026 0c0 2.05 1.53 3.45 3.5 3.45 1.35 0 2.17-.7 2.68-1.5l-1-.51c-.32.56-.96.97-1.68.97-1.32 0-2.3-1.01-2.3-2.41 0-1.4.98-2.41 2.3-2.41.72 0 1.36.42 1.68.97l1-.51c-.5-.8-1.33-1.5-2.68-1.5-1.97 0-3.5 1.4-3.5 3.45zM37.146 30h1.4l-2.49-3.43 2.34-3.24h-1.41l-1.71 2.45-1.72-2.45h-1.4l2.35 3.25-2.5 3.42h1.4l1.87-2.61 1.87 2.61z" _fill="#737B91"/>'
  }
})
