/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Ni': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32286)"/><path pid="2" d="M40.276 40H37.45l-8.7-11.9V40h-2.925V23.325h3L37.35 34.9V23.325h2.925V40zm4.643-13.55a1.638 1.638 0 01-1.625-1.625c0-.9.75-1.625 1.625-1.625.9 0 1.625.725 1.625 1.625s-.725 1.625-1.625 1.625zM46.244 40h-2.625V27.925h2.625V40z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32286" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
