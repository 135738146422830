/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.576 8.951a.6.6 0 11.848.849l-5 5a.6.6 0 01-.848 0l-5-5a.6.6 0 11.848-.849l3.976 3.976V3.125a.6.6 0 011.2 0v9.802l3.976-3.976zm1.676 13.226a6.851 6.851 0 01-12.504 0 16.35 16.35 0 00-.307-.662 1.216 1.216 0 00-.105-.18l-.001-.002.001.001c-.04-.037-.052-.045-.056-.047a.425.425 0 00-.066-.032.594.594 0 00-.16-.022 9.911 9.911 0 00-.554-.008 4.35 4.35 0 010-8.7H9.07l1.2 1.2H7.5a3.15 3.15 0 000 6.3h.018c.238 0 .444 0 .613.01.175.012.36.036.541.111.203.084.329.166.488.316.152.144.26.333.35.507.091.18.197.416.326.702l.006.015a5.651 5.651 0 0010.315 0l.007-.015c.129-.287.234-.522.327-.702.088-.174.197-.363.349-.507.159-.15.285-.232.487-.316.182-.075.367-.1.542-.11.169-.011.375-.011.613-.011h.018a3.15 3.15 0 100-6.3H19.728l1.2-1.2H22.5a4.35 4.35 0 010 8.7c-.261 0-.429 0-.555.008a.595.595 0 00-.16.022.42.42 0 00-.066.032c-.003.002-.016.01-.055.047h.001-.001c-.006.009-.04.052-.105.18-.076.15-.17.358-.307.663z" _fill="#0077C8"/>'
  }
})
