<template>
    <section class="mp-reports container">
        <div class="u-collapse--top col-default-8 col-desktop-12 u-all-padding--no">
            <div class="mp-reports__results">
                <z-caption
                    :href="
                        device === 'mobile'
                            ? false
                            : '/investors/reports-and-results/current-results/'
                    "
                    size="3xl"
                    decor-left
                    decor-right
                    tag="a"
                    @click="handlerTrackGoal('main-4screen-reports_and_results')"
                    class="main-page__title u-top-margin--l-important u-bottom-margin--m-important"
                >
                    <span data-scroll-heading v-html="text.reportSection.title"></span>
                </z-caption>

                <div class="mp-reports__reports" data-scroll-translate>
                    <report-block
                        v-if="reports['annual-report']"
                        class="mp-reports__reports-item"
                        :data="{
                            image: reports['annual-report'].arImg.link,
                            name: `${text.finrez.annualReports} ${reports['annual-report'].name} ${text.finrez.year}`,
                            fileLink: reports['annual-report'].arFile.link,
                            onlinelink: reports['annual-report'].arUrl.value,
                            taggedFileLink: reports['annual-report'].tvArFile.link
                        }"
                        :goals="{
                            goalNameBlockImage: 'main-4screen-report_block-image',
                            goalNameBtnOnline: 'main-4screen-report_block-btn_online',
                            goalNameBtnPdf: 'main-4screen-report_block-btn_pdf',
                            goalNameBtnXbrl: 'main-4screen-report_block-btn_xbrl'
                        }"
                    ></report-block>
                    <report-block
                        class="mp-reports__reports-item"
                        v-if="reports['sustability-report']"
                        :data="{
                            image: reports['sustability-report'].csrImg.link,
                            name: `${text.finrez.sustainabilityReports} ${reports['sustability-report'].name} ${text.finrez.year}`,
                            fileLink: reports['sustability-report'].csrFile.link,
                            onlinelink: reports['sustability-report'].csrUrl.value
                        }"
                        :goals="{
                            goalNameBlockImage: 'main-4screen-report_block-image',
                            goalNameBtnOnline: 'main-4screen-report_block-btn_online',
                            goalNameBtnPdf: 'main-4screen-report_block-btn_pdf',
                            goalNameBtnXbrl: 'main-4screen-report_block-btn_xbrl'
                        }"
                    ></report-block>
                    <report-block
                        v-if="reports['press-release']"
                        class="mp-reports__reports-item reports__reports-item--manufacture-results"
                        :isTargetBlank="false"
                        :data="{
                            image: imageUrl.report,
                            learnMoreLink:
                                reports['press-release'].pr4PressRelease.detailPageUrl ||
                                reports['press-release'].pr3PressRelease.detailPageUrl ||
                                reports['press-release'].pr2PressRelease.detailPageUrl ||
                                reports['press-release'].pr1PressRelease.detailPageUrl,
                            name:
                                reports['press-release'].pr4PressRelease.name ||
                                reports['press-release'].pr3PressRelease.name ||
                                reports['press-release'].pr2PressRelease.name ||
                                reports['press-release'].pr1PressRelease.name
                        }"
                        :goals="{
                            goalNameBlockImage: 'main-4screen-report_block-image',
                            goalNameBtnDetails: 'main-4screen-report_block-btn_details'
                        }"
                    >
                    </report-block>
                </div>
            </div>
        </div>
        <div class="mp-reports__interview">
            <z-caption
                tag="h2"
                decor-left
                decor-right
                size="m"
                weight="700"
                uppercase
                class="main-page__title u-bottom-margin--m-important"
            >
                <span data-scroll-heading v-html="text.interviewSection.title"></span>
            </z-caption>

            <div class="mp-reports__interview-wrapper" data-scroll-translate>
                <div
                    v-for="(media, index) in media"
                    :key="index"
                    class="mp-reports__interview-container">
                    <z-icon
                        class="mp-reports__interview-icon"
                        name="monitor-play"
                        width="48"
                        height="48" />
                    <div class="mp-reports__interview-item">
                        <date-block
                            :date="media.activeFrom"
                            theme="inline">
                            <template v-if="lang === 'ru'">&nbsp;г.</template>
                        </date-block>
                        <a
                            class="mp-reports__interview-link"
                            :href="media.detailPageUrl">
                            {{ media.name }}
                        </a>
                    </div>
                </div>
            </div>

            <z-button
                href="/news-and-media/media-library/"
                tag="a"
                kind="text"
                size="l"
                class="u-align-self--start"
                @click="handlerTrackGoal('main-4screen-interview_block')">
                <template v-slot:left>
                    <z-icon
                        name="arrow-long"
                        dir="right"
                        width="16"
                        height="16"></z-icon>
                </template>
                <span v-html="text.interviewSection.button"></span>
            </z-button>
        </div>
    </section>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import { trackGoal } from '@/utils/analytics'

export default {
    name: 'section-reports',
    mixins: [mixinDevice],
    props: {
        media: {
            type: Array,
            required: true
        },
        reports: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            imageUrl: {
                report: localize({
                    ru: '/images/main-page/manufacture-results-new.png',
                    en: '/images/main-page/manufacture-results-new-en.png'
                })
            },
            text: {
                finrez: {
                    annualReports: localize({
                        ru: 'Годовой отчет',
                        en: 'Annual report'
                    }),
                    sustainabilityReports: localize({
                        ru: 'Отчет об устойчивом развитии',
                        en: 'Sustainability report'
                    }),
                    year: localize({
                        ru: 'год',
                        en: 'year'
                    })
                },
                reportSection: {
                    title: localize({
                        ru: 'Отчеты и результаты',
                        en: 'Reports and results'
                    }),
                    arTitle: localize({
                        ru: 'Годовой отчет за 2020 год',
                        en: 'Annual report'
                    }),
                    csrTitle: localize({
                        ru: 'Отчет об устойчивом развитии за 2020 г.',
                        en: 'Sustainability report'
                    })
                },
                interviewSection: {
                    title: localize({
                        ru: 'Интервью',
                        en: 'Interview'
                    }),
                    button: localize({
                        ru: 'Все интервью',
                        en: 'All interviews'
                    })
                }
            }
        }
    },
    methods: {
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    }
}
</script>

<style lang="scss">
.mp-reports {
    display: flex;
    $custom-transition: 0.4s ease-out;

    @include breakpoint(laptop) {
        flex-direction: column;
    }

    &__results {
        height: 100%;
        @include padding-level(top, M);

        @include breakpoint(tablet) {
            @include padding-level(top, XL);
        }

        @include breakpoint(mobile) {
            @include padding-level(top, M);
        }

        .report-block {
            flex: 1 1;
        }

        .z-caption {
            @include breakpoint(tablet) {
                margin-top: 0 !important;
                @include margin-level(bottom, M, false, true);
            }

            @include breakpoint(mobile) {
                &.z-caption--decor-right.z-caption--decor-left {
                    @include margin-level(bottom, S, false, true);
                    .z-caption__text {
                        max-width: 100%;
                        padding-right: 0;
                    }
                }
            }
        }
    }

    &__reports {
        display: flex;
        justify-content: space-between;

        @include breakpoint(laptop) {
            justify-content: space-around;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
            gap: $token-spacers-s;
        }

        @include breakpoint(mobile) {
            gap: $token-spacers-2-xs;
        }
    }

    &__reports-item {
        margin-right: $token-spacers-xs;
        max-width: 302px;

        @include breakpoint(v-tablet) {
            max-width: 100%;

            .report-block__image {
                display: none;
            }
        }

        .report-block__info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .report-block__link.z-button--rounded {
            @media screen and (min-width: 1023px) {
                transform: none;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &--manufacture-results {
            .report-block__image {
                background-position: 30% 0;
            }
        }
    }

    &__interview {
        border-radius: 0px 40px 0px 20px;
        background-color: $token-colors-gray-10;
        display: flex;
        flex-direction: column;
        height: auto;
        margin-left: 90px;
        @include margin-level(top, XL);
        @include padding-level(top, M);
        @include padding-level(bottom, S);
        @include padding-level(right, M);
        @include padding-level(left, M);

        @include breakpoint(laptop) {
            margin-left: 0;
        }

        @include breakpoint(tablet) {
            @include padding-level(top, S);
            @include padding-level(bottom, S);
            @include padding-level(right, S);
            @include padding-level(left, S);
        }

        @include breakpoint(tablet) {
            @include margin-level(top, M);
        }

        .main-page__title {
            margin-top: -4px;
        }

        .z-link {
            font-weight: 600;
        }
    }

    &__interview-icon {
        @include margin-level(right, S);
    }

    &__interview-container {
        display: flex;
        .mp-reports__interview-icon path {
            transition: fill $custom-transition, stroke-opacity $custom-transition;

            &:nth-of-type(2) {
                stroke: $token-colors-navy-blue;
                stroke-opacity: 0;
            }
        }

        &:hover {
            .mp-reports__interview-icon path {
                &:nth-of-type(1) {
                    fill: $token-colors-navy-blue;
                }
                &:nth-of-type(2) {
                    fill: transparent;
                    stroke-opacity: 1;
                }
            }
        }
    }

    &__interview-wrapper {
        flex: 1 1 auto;

        @include breakpoint(laptop) {
            display: flex;
            justify-content: space-between;
            gap: $token-spacers-xl;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
            gap: 0;
        }
    }

    &__interview-item {
        @include margin-level(bottom, M);
    }

    &__interview-link {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: $token-colors-gray-60;
        text-decoration: none;
        transition: color 0.3s ease-out;

        &:hover {
            color: $token-colors-navy-blue;
        }
    }
}
</style>
