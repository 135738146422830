<template>
    <tippy-tooltip
        placement="right"
        :trigger="getEventForDevice"
        append-to="parent"
        :interactive="true"
        theme="light"
        class="share"
    >
        <template v-slot:trigger>
            <z-link size="m" href="javascript: void(0);">
                <template #left>
                    <z-icon :name="iconName" :width="iconSize" :height="iconSize" />
                </template>
                <span v-if="showText" class="u-bold" v-html="text.share"></span>
            </z-link>
        </template>
        <!-- Временно удалил 'facebook' -->
        <!-- https://tracker.yandex.ru/NORNICKEL-3127#62440c939a48c816ad645b07 -->
        <z-share :services="['vkontakte', 'odnoklassniki']" :lang="$root.lang" bare />
    </tippy-tooltip>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'

export default {
    name: 'calendar-share',
    props: {
        showText: {
            type: Boolean,
            default: true
        },
        iconSize: {
            type: [String, Number],
            default: '32'
        },
        iconName: {
            type: String,
            default: 'share-2'
        }
    },
    data () {
        return {
            text: {
                share: localize({
                    ru: 'Поделиться',
                    en: 'Share'
                })
            }
        }
    },
    mixins: [mixinDevice],
    computed: {
        getEventForDevice () {
            if (this.device === 'mobile' || this.device === 'v-tablet') {
                return 'click'
            } else {
                return 'mouseenter focus'
            }
        }
    }
}
</script>

<style lang="scss"></style>
