<template>
    <div :class="['z-hero', heroClass]" v-if="!error">
        <div class="z-hero__content">
            <div class="container">
                <page-nav class="u-bottom-margin--xs hide-me--mobile" v-if="pageNav" />
                <z-caption
                    tag="h1"
                    :size="cleanNorilsk ? '5xl' : '4xl'"
                    :weight="cleanNorilsk ? '700' : undefined"
                    class="u-collapse--all"
                    :class="cleanNorilsk ? 'blue-text' : ''"
                    :decor-right="!cleanNorilsk"
                    v-if="titleShow"
                >
                    <span v-html="heroTitle"></span>
                    <share-popup
                        class="z-hero__share"
                        icon-name="share"
                        icon-size="24"
                        :show-text="false"
                        v-if="share"
                    />
                </z-caption>
            </div>
        </div>
        <div
            :class="[
                'z-hero__bg',
                { 'z-hero__bg--with-animation' : animation },
                { 'is-animated' : animated }
            ]"
            :style="heroStyle"
            v-if="src"
        >
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'z-hero',
    props: {
        position: {
            type: String,
            default: '50% 50%'
        },
        src: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        titleShow: {
            type: Boolean,
            default: true
        },
        pageNav: {
            type: Boolean,
            default: true
        },
        animation: {
            type: Boolean,
            default: true
        },
        share: {
            type: Boolean,
            default: false
        },
        cleanNorilsk: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            pageHero: this.$root.app.page.hero,
            error: this.$root.app.page.error.state,
            animated: false
        }
    },
    mounted () {
        let img = new Image()
        img.src = this.heroSrc
        img.addEventListener('load', () => {
            this.animated = true
        })
    },
    created () {
        this.SetHeroTitle(this.title)
        this.SetHeroSrc(this.src)

        if (this.pageHero) {
            if (this.pageHero.image && this.pageHero.image !== '') {
                this.SetHeroSrc(this.pageHero.image)
            }

            if (this.pageHero.title && this.pageHero.title !== '') {
                this.SetHeroTitle(this.pageHero.title)
            }
        }
    },
    methods: {
        ...mapActions([
            'SetHeroTitle',
            'SetHeroSrc'
        ])
    },
    computed: {
        ...mapGetters([
            'heroTitle',
            'heroSrc'
        ]),
        heroClass () {
            return [
                { 'z-hero--with-image': this.src },
                { 'z-hero--clean-norilsk': this.cleanNorilsk }
            ]
        },
        heroStyle () {
            let heroStyle = ''

            if (this.src) {
                heroStyle = `background-image: url('${this.heroSrc}'); background-position: ${this.position};`
            };

            return heroStyle
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
