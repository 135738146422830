<template>
    <div class="result-screen content-container">
        <z-preloader v-if="isLoading"></z-preloader>
        <div
            v-if="!isLoading && showCertificate && certificatePdf"
            class="result-screen__container">
            <div class="result-screen__certificate">
                <certificate
                    is-small
                    :company-name="companyName"
                    :organization-name="organizationName"
                    :company-inn="companyInn" />
            </div>
            <div class="result-screen__content">
                <z-caption
                    tag="p"
                    size="4xl">
                    Ваше ознакомление с&nbsp;антикоррупционными принципами и&nbsp;требованиями Группы компаний &laquo;Норильский никель&raquo; завершено
                </z-caption>
                <z-button
                    size="l"
                    tag="a"
                    @click="handleCertificateDownload"
                    :href="certificatePdf"
                    target="_blank"
                    download>
                    <span>Скачать подтверждающий документ</span>
                </z-button>
            </div>
        </div>
    </div>
</template>

<script>
import Certificate from './Certificate.vue'
import { showNotyfications } from '@/utils/notifications'
import { submitPresentationComplete } from '@/api/anti-corruption-complete.js'
import handleYmGoal from '../utils/analytics.js'

export default {
    name: 'result-screen',
    components: {
        Certificate
    },
    props: {
        companyName: {
            type: String,
            required: true
        },
        organizationName: {
            type: String,
            required: true
        },
        companyInn: {
            type: String,
            required: true
        },
        certificatePdf: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            isLoading: false,
            showCertificate: false,
            errorsText: {
                notification: 'Ошибка отправки'
            }
        }
    },
    methods: {
        async submitPresentationComplete () {
            if (this.isLoading) {
                return
            }

            if (this.companyInn) {
                try {
                    this.isLoading = true

                    const data = new FormData()
                    data.append('inn', this.companyInn)

                    const response = await submitPresentationComplete(data)
                    if (response.status === 'success') {
                        this.showCertificate = true
                        handleYmGoal('presentation-page-loaded')
                    } else {
                        this.$emit('removeResultScreen')
                        showNotyfications(response.message, { type: response.status })
                    }
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.errorsText.notification, { type: 'error' })
                    this.$emit('removeResultScreen')
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.errorsText.notification, { type: 'error' })
                this.$emit('removeResultScreen')
            }
        },
        handleCertificateDownload () {
            handleYmGoal('certificate-download')
        }
    },
    created () {
        this.submitPresentationComplete()
    }
}
</script>

<style scoped lang="scss">
.result-screen {
    &__container {
        @include padding-level(top, XL);
        @include padding-level(bottom, XL);
        display: flex;
        width: 61%;
        margin: 0 auto;

        @include breakpoint(tablet) {
            width: 100%;
        }
        @include breakpoint(v-tablet) {
            flex-direction: column-reverse;
            gap: 36px;
        }
    }
    &__certificate {
        @include margin-level(right, XL);

        @include breakpoint(v-tablet) {
            margin-right: 0;
        }
    }
}
</style>
