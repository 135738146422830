var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'z-input-file',
  {
    'is-disabled': _vm.disabled,
    'is-errored': _vm.isValid === false,
    'is-valid': _vm.isValid
  }
]},[_c('label',{staticClass:"z-input-file__label"},[_c('input',{ref:"file",staticClass:"z-input-file__input",attrs:{"type":"file","name":_vm.name,"multiple":_vm.multiple,"disabled":_vm.disabled,"accept":_vm.accept},on:{"change":function($event){return _vm.onChange($event.target)}}}),_vm._v(" "),_c('span',{staticClass:"z-input-file__icon"}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.labelText)}})]),_vm._v(" "),(_vm.files.length)?_c('ul',{staticClass:"z-input-file__list"},_vm._l((_vm.files),function(file,index){return _c('li',{key:index,staticClass:"z-input-file__item"},[_c('p',{staticClass:"z-input-file__item-name"},[_c('span',[_vm._v(_vm._s(file.name))]),_vm._v(" "),_c('span',{staticClass:"z-input-file__item-clear",on:{"click":function($event){return _vm.remove(file)}}})]),_vm._v(" "),_c('p',{staticClass:"z-input-file__item-info"},[(file.type)?[_vm._v(_vm._s(file.type))]:_vm._e(),_vm._v(" "),(file.size)?[_vm._v("("+_vm._s(_vm.getFormatedSize(file.size))+")")]:_vm._e()],2)])}),0):_vm._e(),_vm._v(" "),(_vm.error && !_vm.isValid)?_c('span',{class:[
      'z-input-file__error',
      _vm.errorClass
    ],domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }