var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('media-album',{attrs:{"tags":_vm.tags},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._t("default")]},proxy:true}],null,true)},[_c('div',{staticClass:"row u-justify-content--btween _compensate-row-gap--xl"},_vm._l((_vm.componentData),function(data,idx){return _c('div',{key:idx,staticClass:"col-default-6 col-v-tablet-12 u-align-items--start _row-gap--xl"},[_c('presentation-block',{attrs:{"data":{
                    image: data.previewPicture ? data.previewPicture.SRC : '',
                    name: data.name,
                    size: _vm.getSizeLine(data),
                    link: data.mlDocs.link,
                    externalLink: {
                        title: _vm.externalLink,
                        link: data.mlExternalLink.value
                    }
                },"pdfIconName":"file/text-pdf"}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }