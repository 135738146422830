<template>
    <div class="container">
        <z-tabs size="s">
            <template v-slot:label>
                <z-tabs-label id="highlights"><vue-raw :raw="text.results.tabTitle" /></z-tabs-label>
                <z-tabs-label id="goals"><vue-raw :raw="text.goals.tabTitle" /></z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content id="highlights">
                    <div class="goals-block">
                        <div class="row">
                            <div
                                v-for="(result, index) in text.results.cards"
                                :key="index"
                                class="col-default-3 col-tablet-6 col-v-tablet-12"
                            >
                                <z-card
                                    full-height theme="blue"
                                >
                                    <template v-slot:title>
                                        <vue-raw :raw="result.text" />
                                    </template>
                                    <template v-slot:bottom>
                                        <span class="goals-block__bottom">
                                            <z-caption
                                                size="4xl"
                                                tag="span"
                                                class="blue-20-text u-collapse--all u-flex-align--center"
                                                line-height="1"
                                            >
                                                <vue-raw :raw="result.number" />
                                            </z-caption>
                                            <z-caption
                                                size="2xl"
                                                tag="span"
                                                class="blue-20-text u-collapse--all"
                                                line-height="1"
                                            >
                                                <vue-raw :raw="result.numberSmall" v-if="result.numberSmall" />
                                            </z-caption>
                                        </span>
                                    </template>
                                </z-card>
                            </div>
                        </div>
                    </div>
                    <z-link
                        :href="text.results.linkHref"
                        size="l"
                        target="_blank"
                        class="u-top-margin--m-important"
                        external
                    >
                        <span v-html="text.results.linkTitle" />
                    </z-link>
                </z-tabs-content>
                <!-- END z-tabs-content id="1" -->

                <z-tabs-content id="goals">
                    <div class="goals-block">
                        <div class="row">
                            <div
                                v-for="(goal, index) in text.goals.cards"
                                :key="index"
                                class="col-default-3 col-tablet-6 col-v-tablet-12"
                            >
                                <z-card
                                    full-height theme="blue"
                                >
                                    <template v-slot:title>
                                        <vue-raw :raw="goal.text" />
                                    </template>
                                    <template v-slot:bottom>
                                        <span class="goals-block__bottom">
                                            <z-caption
                                                v-if="goal.number"
                                                size="4xl"
                                                tag="span"
                                                class="blue-20-text u-collapse--all u-flex-align--center"
                                                line-height="1"
                                            >
                                                <vue-raw :raw="goal.number" />
                                            </z-caption>
                                            <z-caption
                                                v-if="goal.numberSmall"
                                                size="2xl"
                                                tag="span"
                                                class="blue-20-text u-collapse--all"
                                                line-height="1"
                                            >
                                                <vue-raw :raw="goal.numberSmall" />
                                            </z-caption>
                                        </span>
                                    </template>
                                </z-card>
                            </div>
                        </div>
                    </div>
                    <z-link
                        :href="text.goals.linkHref"
                        size="l"
                        target="_blank"
                        class="u-top-margin--m-important"
                    >
                        <template v-slot:left>
                            <z-icon
                                name="file/pdf"
                                width="1.6em"
                                height="1.6em"
                            />
                        </template>
                        <vue-raw :raw="text.goals.linkTitle" />
                    </z-link>
                </z-tabs-content>
                <!-- END z-tabs-content id="2" -->
            </template>
        </z-tabs>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'results-and-goals',
    data () {
        return {
            text: {
                results: {
                    tabTitle: localize({
                        'ru': 'Основные результаты работы Компании в&nbsp;области устойчивого развития',
                        'en': 'Sustainable Development Highlights'
                    }),
                    cards: [
                        {
                            text: localize({
                                'ru': 'Доля электроэнергии, полученной из&nbsp;ВИЭ по&nbsp;Группе',
                                'en': 'Share of&nbsp;renewables across the&nbsp;Group'
                            }),
                            number: localize({
                                'ru': '55% <z-tooltip content="по&nbsp;итогам 2023&nbsp;г."></z-tooltip>',
                                'en': '55% <z-tooltip content="as&nbsp;of&nbsp;2023"></z-tooltip>'
                            })
                        },
                        {
                            text: localize({
                                'ru': 'Один из&nbsp;самых низких производственных уровней СО<sub>2</sub> (Scope 1&amp;2) среди международных компаний горно-металлургической отрасли',
                                'en': 'Industry’s Lowest Carbon Footprint in&nbsp;Terms of&nbsp;Scope 1&amp;2&nbsp;Emissions'
                            }),
                            number: localize({
                                'ru': '9,7',
                                'en': '9.7'
                            }),
                            numberSmall: localize({
                                'ru': '&nbsp;млн тонн СО<sub>2</sub>e <z-tooltip content="по&nbsp;итогам 2023&nbsp;г., включая выбросы от&nbsp;населения и&nbsp;объектов инфраструктуры (Охват&nbsp;1)"></z-tooltip>',
                                'en': '&nbsp;mt CO<sub>2</sub>e <z-tooltip content="1&nbsp;GHG emissions were calculated as&nbsp;per the GHG Protocol Guidelines. Estimates of&nbsp;greenhouse gas emissions for the Group included direct emissions of&nbsp;carbon dioxide (CO<sub>2</sub>), nitrogen oxide, methane (CH<sub>4</sub>), mostly from gas transportation, including the Sulphur Project, and heat and electricity supplies to&nbsp;household consumers"></z-tooltip>'
                            })
                        },
                        {
                            text: localize({
                                'ru': 'Затрат на&nbsp;проекты, связанные с&nbsp;ЦУР ООН',
                                'en': 'Spending on&nbsp;SDG-linked project'
                            }),
                            number: localize({
                                'ru': '339',
                                'en': '339'
                            }),
                            numberSmall: localize({
                                'ru': '&nbsp;млрд. руб. <z-tooltip content="по&nbsp;итогам 2023&nbsp;г. или 27,5% от&nbsp;консолидированной выручки по&nbsp;МСФО"></z-tooltip>',
                                'en': '&nbsp;bn RUB <z-tooltip content="as&nbsp;of&nbsp;2023 or 27.5% of&nbsp;consolidated IFRS revenue"></z-tooltip>'
                            })
                        },
                        {
                            text: localize({
                                'ru': 'Вес командных KPI в&nbsp;сфере ESG',
                                'en': 'Share of&nbsp;annual KPIs linked to&nbsp;ESG performance'
                            }),
                            number: '50%'
                        }
                    ],
                    linkTitle: localize({
                        'ru': 'Более подробно о&nbsp;результатах компании в&nbsp;области устойчивого развития',
                        'en': 'Learn more about our results'
                    }),
                    linkHref: localize({
                        'ru': 'https://csr2020.nornickel.ru/ru/',
                        'en': 'https://csr2020.nornickel.ru/en/'
                    })
                },
                goals: {
                    tabTitle: localize({
                        'ru': 'Ключевые стратегические цели',
                        'en': 'Our Main Strategic Goals'
                    }),
                    cards: [
                        {
                            text: localize({
                                'ru': 'Нулевая смертность на&nbsp;производстве&nbsp;&mdash; политика недопущения смертельных случаев на&nbsp;производстве',
                                'en': 'Zero-fatalities on&nbsp;production sites, zero-tolerance policy on&nbsp;work-related fatalities'
                            }),
                            number: '0'
                        },
                        {
                            text: localize({
                                'ru': 'Сокращение выбросов SO<sub>2</sub> на&nbsp;основных производственных площадках Норильского дивизиона',
                                'en': 'Reduction in&nbsp;total SO<sub>2</sub> emissions of&nbsp;Norilsk Division'
                            }),
                            number: localize({
                                'ru': '-90% <z-tooltip content="по&nbsp;итогам выхода проекта на&nbsp;Надеждинском заводе на&nbsp;проектную мощность и&nbsp;закрытие Медного завода"></z-tooltip>',
                                'en': '-90% <z-tooltip content="compare to&nbsp;the baseline year of&nbsp;2015 after the project at&nbsp;Nadezhda Metallurgical Plant will reach its design capacity and the closure of&nbsp;Copper Plant"></z-tooltip>'
                            })
                        },
                        {
                            text: localize({
                                'ru': 'Снижение негативного воздействия на&nbsp;окружающую<br>среду и на&nbsp;биоразнообразие, местные сообщества в&nbsp;регионах присутствия и&nbsp;улучшение здравоохранения',
                                'en': 'Reducing the negative impact on&nbsp;the environment and biodiversity, local communities in&nbsp;the regions of&nbsp;presence and improving healthcare'
                            })
                        },
                        {
                            text: localize({
                                'ru': 'Вес долгосрочных KPI в&nbsp;области устойчивого развития',
                                'en': 'Share of&nbsp;long-term KPIs linked to&nbsp;ESG performance'
                            }),
                            number: localize({
                                'ru': '30% <z-tooltip content="Достижение целей экологической стратегии, включая сокращение выбросов SO<sub>2</sub>"></z-tooltip>',
                                'en': '30% <z-tooltip content="Achieving the goals of the environmental strategy, including the reduction of SO<sub>2</sub> emissions"></z-tooltip>'
                            })
                        }
                    ],
                    linkTitle: localize({
                        'ru': 'Более подробно о целях',
                        'en': 'Learn more about the goals'
                    }),
                    linkHref: localize({
                        'ru': '/upload/iblock/1cd/Norilsk_Nickel_Environmental_Strategy_2021_ru.pdf',
                        'en': '/upload/iblock/7b2/Norilsk_Nickel_Environmental_Strategy_2021_en.pdf'
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss">
.goals-block {
    @include padding-level(top, 3XL);
    @include padding-level(bottom, 3XL);
    @include padding-level(left, 3XL);
    @include padding-level(right, 3XL);

    background-image: url('/images/sustainability/bg_2.jpg');
    background-size: cover;
    background-position: center;
    border-top-left-radius: $boder-radius-l;

    .z-card {
        min-height: 420px;

        @include breakpoint (laptop) {
            min-height: 360px;
        }

        @include breakpoint (tablet) {
            min-height: 260px;
        }

        @include breakpoint (v-tablet) {
            min-height: auto;
        }
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: flex-end;
    }

    &__bottom-text {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        flex-wrap: wrap;
        text-align: right;

        & + & {
            margin-top: .5em;
        }
    }

    @include breakpoint(mobile) {
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        @include padding-level(left, XS);
        @include padding-level(right, XS);
    }
}
</style>
