<template>
    <form @submit.prevent="submitHandler">
        <z-caption tag="h2" size="3xl" v-html="text.title"/>
        <div class="u-bottom-margin--l">
            <z-input ref="email" v-model="email" name="email" type="email" :placeholder="text.inputPlaceholder" required/>
            <p class="manage__error-message" v-if="responseMessage"><vue-raw :raw="responseMessage"/></p>
        </div>
        <z-button class="u-bottom-margin--s" size="l" type="submit" v-html="text.button"/>
        <div class="u-bottom-margin--m">
            <vue-raw :raw="text.description"/>
        </div>
        <div>
            <z-link href="/news-and-media/press-releases-and-news/" size="l">
                <template v-slot:right>
                    <z-icon name="arrow-long" dir="right" width="24" height="24"></z-icon>
                </template>
                <span v-html="text.toNews"/>
            </z-link>
        </div>
    </form>
</template>

<script>
import {localize} from '@/utils/i18n'
import {subscribeEditRequest} from '@/api/subscribeEditRequest'

export default {
    name: 'manage',
    data () {
        return {
            email: '',
            isLoading: false,
            responseMessage: '',
            text: {
                title: localize({
                    ru: 'Управление подпиской',
                    en: 'Subscription Management'
                }),
                inputPlaceholder: localize({
                    ru: 'Введите свой email',
                    en: 'Enter your email'
                }),
                button: localize({
                    ru: 'Отправить',
                    en: 'Send'
                }),
                description: localize({
                    ru: '<p>Если вы&nbsp;еще не&nbsp;подписаны и&nbsp;хотите изменить параметры подписки, то&nbsp;<z-link href="/news-and-media/subscription/">подпишитесь.</z-link></p>',
                    en: '<p>If&nbsp;you are not subscribed yet and want to&nbsp;change your subscription options, then <z-link href="/news-and-media/subscription/">subscribe.</z-link></p>'
                }),
                toNews: localize({
                    ru: 'Перейти к&nbsp;новостям',
                    en: 'Go&nbsp;to&nbsp;news'
                })
            }
        }
    },
    methods: {
        submitHandler () {
            this.responseMessage = ''
            this.$refs.email.validate()
            if (this.$refs.email.error) {
                return
            }
            this.send()
        },
        send () {
            this.isLoading = true
            const formData = this.appendToFormData(new FormData(), {
                email: this.email
            })
            subscribeEditRequest(formData)
                .then((response) => {
                    if (response.status === 'error') {
                        this.responseMessage = response.message
                    } else {
                        this.$emit('change-component', {
                            component: 'edit-message',
                            email: this.email
                        })
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        appendToFormData (formData, params) {
            for (let key in params) {
                if (Array.isArray(params[key])) {
                    params[key].forEach((item) => {
                        formData.append(`${key}[]`, item)
                    })
                } else {
                    formData.append(key, params[key])
                }
            }
            return formData
        }
    }
}
</script>

<style lang="scss">
.manage {
    &__error-message {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: $token-colors-alert-text;
        margin-top: $token-spacers-2-xs;
        padding-left: $token-spacers-2-xs;

        @include breakpoint(tablet) {
            padding-left: 12px;
        }

        a {
            color: $token-colors-alert-text;

            &:visited {
                color: $token-colors-alert-text;
            }

            &:focus,
            &:hover {
                text-decoration: none;
            }
        }
    }
}
</style>
