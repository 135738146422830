<template>
    <div class="specialized-machinery-list" v-if="items.length">
        <template v-for="(item, index) in items">
            <div class="specialized-machinery-card" :key="`tender-${index}`">
                <div class="specialized-machinery-card__header">
                    <span
                        class="specialized-machinery-card__tag"
                        :style="`background-color: ${getColor(item.order.xmlId)}`"
                        v-html="item.order.value"
                    />
                    <div class="specialized-machinery-card__date-wrapper">
                        <span class="specialized-machinery-card__date-key">
                            Дата ввода в&nbsp;экслуатацию:
                        </span>
                        <span
                            class="specialized-machinery-card__date-value"
                            :class="{'specialized-machinery-card__date-value--hot': checkDate(item.date.timestamp)}"
                        >
                            {{ item.date.timestamp | momentFilter('DD.MM.YYYY') }}
                        </span>
                    </div>

                </div>
                <div class="specialized-machinery-card__body">
                    <z-link :href="item.detailPageUrl">
                        <span class="specialized-machinery-card__text" v-html="item.name"/>
                    </z-link>
                </div>
                <specialized-machinery-table>
                    <specialized-machinery-table-item title="Адрес">
                        <span v-if="item.address && item.address.value" v-html="item.address.value"/>
                        <span v-else>—</span>
                    </specialized-machinery-table-item>
                    <specialized-machinery-table-item title="Собственник" max-width="200px">
                        <span v-if="item.owner && item.owner.value" v-html="item.owner.value"/>
                        <span v-else>—</span>
                    </specialized-machinery-table-item>
                    <specialized-machinery-table-item title="Площадь/протяжённость" max-width="200px">
                        <span v-if="item.totalArea && item.totalArea.value" v-html="item.totalArea.value"/>
                        <span v-else>—</span>
                    </specialized-machinery-table-item>
                    <specialized-machinery-table-item title="Функциональное назначение" max-width="200px">
                        <span v-if="item.functionalPurpose && item.functionalPurpose.value" v-html="item.functionalPurpose.value"/>
                        <span v-else>—</span>
                    </specialized-machinery-table-item>
                    <specialized-machinery-table-item title="Начальная цена реализации без НДС" max-width="200px">
                        <span v-if="item.cost && item.cost.value" v-html="item.cost.value + ' руб.'"/>
                        <span v-else>—</span>
                    </specialized-machinery-table-item>
                </specialized-machinery-table>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import {adaptDate} from '@/utils/filter'
import SpecializedMachineryTable from './SpecializedMachineryTable.vue'
import SpecializedMachineryTableItem from './SpecializedMachineryTableItem.vue'

export default {
    name: 'SpecializedMachineryList',
    components: {
        SpecializedMachineryTable,
        SpecializedMachineryTableItem
    },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        checkDate (timestamp) {
            const date = timestamp * 1000
            const today = moment().valueOf()
            const result = date - today
            const limit = 86400000 * 3
            return result < limit
        },
        formattedDate (date) {
            const time = adaptDate(date, 'HH:mm')
            const pattern = /^00:00$/

            if (pattern.test(time)) {
                return adaptDate(date, 'DD.MM.YYYY')
            }
            return adaptDate(date, 'DD.MM.YYYY hh:mm')
        },
        getColor (id) {
            const colors = {
                'public-sale': '#654EA3',
                'trades': '#8AD29D'
            }
            return colors[id] ? colors[id] : '#ffffff'
        }
    }
}
</script>

<style lang="scss">
.specialized-machinery-list {
    @include margin-level(bottom, L);
}

.specialized-machinery-card {
    padding: $token-spacers-s $token-spacers-2-xs;
    border-top: 1px solid $token-colors-gray-20;

    @include breakpoint(v-tablet) {
        padding: $token-spacers-s 0;
    }

    &:last-child {
        border-bottom: 1px solid $token-colors-gray-20;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $token-spacers-xs;

        @include breakpoint(mobile) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__tag {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-white;
        text-align: center;
        padding: 2px 16px;
        border-radius: 20px;
        font-family: $robotoMono;
    }

    &__date-wrapper {
        margin-left: $token-spacers-xs;
        display: flex;
        align-items: center;

        @include breakpoint(mobile) {
            margin-left: 0;
            margin-top: $token-spacers-3-xs;
        }
    }

    &__date-key {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-right: $token-spacers-3-xs;
        font-family: $robotoMono;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__date-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black;
        font-family: $robotoMono;

        &--hot {
            color: $token-colors-alert-text;
        }
    }

    &__body {
        margin-bottom: $token-spacers-xs;
    }

    &__text {
        width: 100%;
        max-width: 1200px;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        font-family: $robotoMono;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }
}
</style>
