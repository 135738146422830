/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/doc': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM11.915 31h2.976c2.496 0 4.187-1.644 4.187-3.996 0-2.352-1.691-4.008-4.188-4.008h-2.976V31zm5.724-3.996c0 1.512-1.008 2.76-2.748 2.76h-1.572v-5.532h1.571c1.788 0 2.748 1.224 2.748 2.772zm2.435 0c0 2.388 1.692 4.14 4.092 4.14 2.4 0 4.092-1.752 4.092-4.14s-1.692-4.14-4.092-4.14c-2.4 0-4.092 1.752-4.092 4.14zm6.744 0c0 1.644-1.044 2.892-2.652 2.892-1.62 0-2.652-1.248-2.652-2.892 0-1.656 1.032-2.892 2.652-2.892 1.608 0 2.652 1.236 2.652 2.892zm2.432 0c0 2.46 1.836 4.14 4.2 4.14 1.62 0 2.604-.84 3.216-1.8l-1.2-.612a2.35 2.35 0 01-2.016 1.164c-1.584 0-2.76-1.212-2.76-2.892s1.176-2.892 2.76-2.892c.864 0 1.632.504 2.016 1.164l1.2-.612c-.6-.96-1.596-1.8-3.216-1.8-2.364 0-4.2 1.68-4.2 4.14z" _fill="#737B91"/>'
  }
})
