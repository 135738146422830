/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cn/pin-cnn': {
    width: 58,
    height: 58,
    viewBox: '0 0 58 58',
    data: '<mask id="svgicon_cn_pin-cnn_a" fill="#fff"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M28.882 53l14.06-14.059a19.881 19.881 0 10-28.119 0L28.883 53z"/></mask><path pid="1" d="M42.941 38.941l1.06 1.06-1.06-1.06zM28.882 53l-1.06 1.06 1.06 1.061 1.06-1.06L28.883 53zm19.5-24.239l-1.47-.293 1.47.293zm-1.131-11.487l1.386-.574-1.386.574zM17.836 8.35l.834 1.247-.834-1.247zm-7.322 8.923L9.128 16.7l1.386.574zM9.382 28.76l-1.471.293 1.471-.293zm5.441 10.18l-1.06 1.06 1.06-1.06zm27.058-1.06L27.82 51.938l2.122 2.122L44.002 40l-2.121-2.12zm5.03-9.413a18.382 18.382 0 01-5.03 9.412L44 40.002c2.991-2.99 5.028-6.8 5.853-10.948l-2.943-.586zm-1.046-10.62a18.382 18.382 0 011.046 10.62l2.943.586A21.383 21.383 0 0048.637 16.7l-2.772 1.148zm-6.77-8.25a18.382 18.382 0 016.77 8.25l2.772-1.148a21.382 21.382 0 00-7.875-9.596l-1.667 2.494zM28.882 6.5c3.636 0 7.19 1.078 10.213 3.098l1.667-2.494A21.382 21.382 0 0028.882 3.5v3zM18.67 9.598A18.382 18.382 0 0128.882 6.5v-3a21.383 21.383 0 00-11.88 3.604l1.668 2.494zm-6.77 8.25a18.382 18.382 0 016.77-8.25l-1.667-2.494A21.382 21.382 0 009.128 16.7l2.771 1.148zm-1.047 10.62a18.382 18.382 0 011.046-10.62L9.128 16.7A21.382 21.382 0 007.91 29.054l2.942-.586zm5.031 9.412a18.382 18.382 0 01-5.03-9.412l-2.943.586a21.382 21.382 0 005.852 10.948l2.121-2.122zm14.059 14.06L15.884 37.88l-2.121 2.122L27.822 54.06l2.12-2.122z" _fill="#ADD1EA" mask="url(#svgicon_cn_pin-cnn_a)"/><path pid="2" d="M37.444 29.442a6.25 6.25 0 01-2.728 1.607V18.95a6.256 6.256 0 012.728 1.605A6.246 6.246 0 0139.283 25a6.24 6.24 0 01-1.839 4.443zM25 25.535V19.07l7.998 5.397v6.463l-7.997-5.395zm-1.715 5.514a6.232 6.232 0 01-2.728-1.607A6.238 6.238 0 0118.716 25c0-1.678.654-3.257 1.84-4.444a6.252 6.252 0 012.73-1.605V31.05zM32.998 17v5.396L25.002 17A8 8 0 1025 33v-5.396L32.998 33a8 8 0 100-15.999z" _fill="#ADD1EA"/>'
  }
})
