var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"objects-switcher"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"objects-switcher__controls"},[_c('span',{staticClass:"objects-switcher__title",class:{'objects-switcher__title--active': !_vm.state}},[_vm._v("\n            Объект программы")]),_vm._v(" "),_c('z-switch',{attrs:{"id":"objects","check":_vm.state},on:{"change":_vm.switchHandler}}),_vm._v(" "),_c('span',{staticClass:"objects-switcher__title",class:{'objects-switcher__title--active': _vm.state}},[_vm._v("\n            Объекты на карте")])],1)]),_vm._v(" "),_c('pseudo-slider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.state),expression:"!state"}],attrs:{"links":[
                {
                    id: 'one',
                    name: 'Аварийный пятиэтажный дом'
                },
                {
                    id: 'two',
                    name: 'Бывшая база «Норильскснаба»'
                },
                {
                    id: 'three',
                    name: 'Депо «Север»'
                }
            ],"current":"one"}}),_vm._v(" "),_c('ya-map',{directives:[{name:"show",rawName:"v-show",value:(_vm.state),expression:"state"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }