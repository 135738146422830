<template>
    <div :class="['z-radio', { 'is-error': error, 'is-disabled': disabled }]">
        <div class="z-radio__list">
            <div class="z-radio__item" v-for="(item, index) in data" :key="index">
                <label class="z-radio__label">
                    <input
                        class="z-radio__input"
                        type="radio"
                        :name="name"
                        :value="item.value"
                        v-model="radioValue"
                        @change="onChange"
                    />
                    <span class="z-radio__circle"></span>
                    <span class="z-radio__text" v-html="item.text"></span>
                </label>
            </div>
        </div>
        <span :class="['z-radio__error', errorClass]" v-if="error" v-html="error"></span>
    </div>
</template>

<script>
export default {
    name: 'z-radio',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        errorClass: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            localValue: this.value,
            error: '',
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '填项目'
                }
            }
        }
    },
    computed: {
        radioValue: {
            get () {
                if (typeof this.value !== undefined) return this.value // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.localValue = value
            }
        }
    },
    methods: {
        onChange () {
            this.$nextTick(this.validate)
            this.$emit('change', this.localValue)
        },
        validate () {
            if (this.required && !this.radioValue) {
                this.error = this.$root.localize(this.text.error)
            } else {
                this.error = ''
            }
        }
    }
}
</script>

<style lang="scss">
$errorColor: #ca3d21;

.z-radio {
    $parent: &;
    overflow: hidden;
    font-size: 16px;

    &__list {
        margin-top: -1em;
    }

    &__item {
        display: flex;
        margin-top: 1em;
    }

    &__label {
        display: inline-flex;
        cursor: pointer;

        &:hover {
            #{$parent}__circle {
                background-color: $token-colors-blue-30;
                border-color: $token-colors-blue-30;

                &:after {
                    background: $token-colors-navy-blue;
                }
            }

            #{$parent}__text {
                color: $token-colors-black;
            }
        }
    }

    &__circle {
        display: block;
        width: 1.5em;
        height: 1.5em;
        background-color: $token-colors-white;
        border: 1px solid $token-colors-gray-30;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 0.75em auto;
        transition: all 0.2s ease-in;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
    }

    &__input {
        display: none;

        &:checked {
            + #{$parent}__circle {
                border-color: $token-colors-blue-30;
                border: 1px solid $token-colors-blue;
                background: radial-gradient(circle, #0077c8 30%, #fff 30%);
            }
        }
    }

    &__text {
        font-size: 1em;
        margin-left: 1em;
    }

    &__error {
        display: block;
        margin-top: 10px;
        font-size: 0.8125em;
        color: $errorColor;
        position: relative;
        left: 20px;
    }

    // error state
    &.is-error {
        #{$parent}__circle {
            border-color: $errorColor;
            background-color: #ff9f8c99;
        }

        #{$parent}__text {
            color: $errorColor;
        }
    }

    &.is-disabled {
        #{$parent}__circle {
            border-color: #858da6;
        }

        #{$parent}__text {
            color: #858da6;
        }
    }
}
</style>
