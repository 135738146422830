<template>
    <div class="equipment-detail container">
        <span
            v-if="item.order"
            class="equipment-detail__tag"
            :style="`background-color: ${getColor(item.order.xmlId)}`"
            v-html="item.order.value"
        />
        <layout>
            <template #content>
                <content-container>
                    <div class="equipment-detail__table">
                        <div v-if="item.inventoryNumber.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Инвентарный номер</div>
                            <div class="equipment-detail__table-value" v-html="item.inventoryNumber.value"/>
                        </div>
                        <div v-if="item.liquidity.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Ликвидность актива</div>
                            <div class="equipment-detail__table-value" v-html="item.liquidity.value"/>
                        </div>
                        <div v-if="item.encumbrance.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Вид обременения</div>
                            <div class="equipment-detail__table-value" v-html="item.encumbrance.value"/>
                        </div>
                        <div v-if="item.encumbranceDate.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Срок обременения (окончание)</div>
                            <div class="equipment-detail__table-value" v-html="item.encumbranceDate.value"/>
                        </div>
                        <div v-if="item.activeTo.timestamp" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Дата проведения торгов</div>
                            <div class="equipment-detail__table-value">{{item.activeTo.timestamp | momentFilter('DD.MM.YYYY')}}</div>
                        </div>
                        <div v-if="item.address.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Адрес местонахождения имущества</div>
                            <div class="equipment-detail__table-value" v-html="item.address.value"/>
                        </div>
                        <div v-if="item.owner.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Данные о&nbsp;собственнике&nbsp;/ балансодержателе</div>
                            <div class="equipment-detail__table-value" v-html="item.owner.value"/>
                        </div>
                        <div v-if="item.functionalPurpose.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Функциональное назначение</div>
                            <div class="equipment-detail__table-value" v-html="item.functionalPurpose.value"/>
                        </div>
                        <div v-if="item.date && item.date.timestamp" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Дата ввода в&nbsp;эксплуатацию</div>
                            <div class="equipment-detail__table-value">{{item.date.timestamp | momentFilter('DD.MM.YYYY')}}</div>
                        </div>
                        <div v-if="item.totalArea.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Общая площадь, м<sup>2</sup>/протяженность, пог.м.</div>
                            <div class="equipment-detail__table-value" v-html="item.totalArea.value"/>
                        </div>
                        <div v-if="item.objectState.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Состояние объекта</div>
                            <div class="equipment-detail__table-value" v-html="item.objectState.value"/>
                        </div>
                        <div v-if="item.cost.value" class="equipment-detail__table-item">
                            <div class="equipment-detail__table-key">Стоимость, руб.&nbsp;без&nbsp;НДС</div>
                            <div class="equipment-detail__table-value equipment-detail__table-value--semibold" v-html="item.cost.value"/>
                        </div>
                    </div>
                    <z-caption v-if="item.images && item.images.length" uppercase decor-right size="s" weight="700">Изображения</z-caption>
                    <div class="row" v-if="item.images && item.images.length">
                        <div
                            v-for="(image, index) in item.images"
                            :key="index"
                            class="col-default-4 col-tablet-6 col-v-tablet-12">
                            <z-figure :image="image.link"></z-figure>
                        </div>
                    </div>
                    <vue-raw :raw="item.detailText"/>
                    <div v-if="item.files && item.files.length" class="u-top-margin--xl">
                        <z-caption tag="h3" size="3xl" weight="600">Прикреплённые файлы</z-caption>
                        <z-filelist>
                            <template v-for="(file, index) in item.files">
                                <z-filelist-item
                                    :href="file.link"
                                    :name="file.name"
                                    :file-size="file.size"
                                    :icon-name="`file/${file.type}`"
                                    :key="`file-${index}`"/>
                            </template>
                        </z-filelist>
                    </div>
                </content-container>
            </template>
        </layout>
        <page-changer
            v-if="nav"
            :data="nav"
            class="u-top-margin--l"
            default-btn-name
        />
    </div>
</template>

<script>
export default {
    name: 'equipment-detail',
    data () {
        return {
            item: this.$root.app?.components['equipment-detail'].item || [],
            nav: this.$root.app?.components['equipment-detail'].nav || null
        }
    },
    methods: {
        getColor (id) {
            const colors = {
                'public-sale': '#654EA3',
                'trades': '#8AD29D'
            }
            return colors[id] ? colors[id] : '#654EA3'
        }
    }
}
</script>

<style scoped lang="scss">
.equipment-detail {
    &__tag {
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-white;
        text-align: center;
        padding: 2px 16px;
        border-radius: 20px;
        font-family: $robotoMono;
        margin-bottom: $token-spacers-xs;
    }

    &__table {
        margin-bottom: $token-spacers-xl;
    }

    &__table-item {
        display: flex;
        align-items: center;
        gap: $token-spacers-xl;
        padding-top: $token-spacers-xs;
        padding-bottom: $token-spacers-xs;
        border-bottom: 1px solid $token-colors-gray-20;

        &:first-child {
            padding-top: 0;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
            align-items: flex-start;
            gap: $token-spacers-xs;
        }
    }

    &__table-key {
        width: 220px;
        min-width: 220px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.5;

        @include breakpoint(v-tablet) {
            width: 100%;
        }
    }

    &__table-value {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;

        &--semibold {
            font-weight: 600;
            font-size: 20px;
        }
    }

    &__cost {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: $token-colors-gray-60;
    }
}
</style>
