/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-brand-efficiency': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M48 12H36v12H24v12H12v12h36V12z" _fill="#004C97"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M48 12H36v12H24v12H12v12h36V12z" _fill="url(#paint0_linear_10270_2481)"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M48 0H36v12H24v12H12v12H0v12h12V36h12V24h12V12h12V0z" _fill="#0077C8"/><defs><linearGradient id="paint0_linear_10270_2481" x1="24.503" y1="12" x2="57.073" y2="33.75" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
