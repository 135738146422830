<template>
    <div class="bonds-page-list" v-if="items.length">
        <template v-for="(item, index) in items">
            <div class="bonds-page-list__body" :key="index">
                <bonds-page-table>
                    <bonds-page-column>
                        <bonds-page-item :title="text.tools" font-size="18">
                            <span v-html="item.tools"/>
                        </bonds-page-item>
                        <bonds-page-item :title="isinFootnote ? `${text.isin}<sup>1<sup/>` : text.isin" font-size="18">
                            <span v-html="item.isin"/>
                        </bonds-page-item>
                    </bonds-page-column>
                    <bonds-page-column>
                        <bonds-page-item :title="text.placement">
                            <span v-html="item.placement"/>
                        </bonds-page-item>
                        <bonds-page-item :title="text.redemption">
                            <span v-html="item.redemption"/>
                        </bonds-page-item>
                        <bonds-page-item :title="text.coupon_payment">
                            <span v-html="item.coupon_payment"/>
                        </bonds-page-item>
                        <bonds-page-item v-if="item.nearest" :title="text.nearest">
                            <span v-html="item.nearest"/>
                        </bonds-page-item>
                    </bonds-page-column>
                    <bonds-page-column>
                        <bonds-page-item v-if="item.showProspect" :title="item.showUpdate ? text.issue_documents : ''">
                            <z-filelist size="s">
                                <z-filelist-item
                                    v-if="item.prospect"
                                    :name='text.prospect_name'
                                    :href="'#'"
                                    icon-name="upload"
                                    :file-size="item.prospect_size"
                                    file-type="pdf"
                                    @linkClick.prevent="openModal(item.prospect)"
                                ></z-filelist-item>
                                <z-filelist-item
                                    v-if="item.showUpdate"
                                    :name="text.updated_release"
                                    :href="item.update_link"
                                    icon-name="upload"
                                    :file-size="item.update_size"
                                    file-type="pdf"
                                ></z-filelist-item>
                            </z-filelist>
                        </bonds-page-item>
                    </bonds-page-column>
                    <bonds-page-column>
                        <bonds-page-item :title="text.issuer">
                            <span v-html="item.issuer"/>
                        </bonds-page-item>
                        <bonds-page-item :title="text.volume">
                            <span v-html="item.volume"/>
                        </bonds-page-item>
                        <bonds-page-item :title="text.coupon_rate">
                            <span v-html="item.coupon_rate"/>
                        </bonds-page-item>
                    </bonds-page-column>
                    <bonds-page-column v-if="item.showLink">
                        <bonds-page-item :title="text.quotes_securities">
                            <z-link
                                v-if="item.link"
                                :href="item.link"
                                size="m"
                                target="_blank"
                            >
                                <template v-slot:left>
                                    <z-icon name="external" width="24" height="24"></z-icon>
                                </template>
                                {{ text.link }}
                            </z-link>
                            <span v-else>N/A</span>
                        </bonds-page-item>
                    </bonds-page-column>
                </bonds-page-table>
            </div>
        </template>
        <z-modal
            class="bonds-page-list__modal"
            :id="idModal"
        >
            <p>{{ text.agreementText.start }}</p>
            <p class="u-bottom-margin--m">{{ text.agreementText.end }}</p>

            <z-button tag="a" target="_blank" size="m" @click="agreeProspectusTerms">{{ text.agreementButtonText }}</z-button>
        </z-modal>
    </div>
</template>

<script>
import BondsPageTable from './BondsPageTable.vue'
import BondsPageItem from './BondsPageItem.vue'
import BondsPageColumn from './BondsPageColumn.vue'
import { localize } from '@/utils/i18n'

export default {
    name: 'bonds-page-list',
    components: {
        BondsPageTable,
        BondsPageItem,
        BondsPageColumn
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        isinFootnote: {
            type: Boolean,
            default: false
        },
        idModal: {

        }
    },
    data () {
        return {
            lang: this.$root.app.page.lang,
            currentLinkModal: '',
            text: {
                tools: localize({
                    ru: 'Инструмент',
                    en: 'Instrument'
                }),
                issuer: localize({
                    ru: 'Эмитент',
                    en: 'Issuer'
                }),
                placement: localize({
                    ru: 'Дата размещения',
                    en: 'Offering date'
                }),
                redemption: localize({
                    ru: 'Дата погашения',
                    en: 'Maturity date'
                }),
                coupon_payment: localize({
                    ru: 'Даты выплаты купона',
                    en: 'Coupon dates'
                }),
                volume: localize({
                    ru: 'Объем выпуска',
                    en: 'Issue size'
                }),
                coupon_rate: localize({
                    ru: 'Ставка купона',
                    en: 'Coupon rate'
                }),
                quotes_securities: localize({
                    ru: 'Котировки бумаг',
                    en: 'Quotes of securities'
                }),
                issue_documents: localize({
                    ru: 'Эмиссионные документы',
                    en: 'Issue documents'
                }),
                updated_release: localize({
                    ru: 'Обновленные условия выпуска',
                    en: 'Updated Terms and Conditions'
                }),
                link: localize({
                    ru: 'Ссылка',
                    en: 'Link'
                }),
                prospect_name: localize({
                    ru: '<z-tooltip content="Условия выпуска были изменены. Для уточнения обновленных условий выпуска, пожалуйста, обратитесь к документу «Обновлённые условия выпуска»"><span>Проспект</span></z-tooltip>',
                    en: '<z-tooltip content="Terms and Conditions of the Notes have been changed. Please refer to the Updated Terms and Conditions"><span>Prospectus</span></z-tooltip>'
                }),
                isin: localize({
                    ru: 'ISIN',
                    en: 'ISIN'
                }),
                nearest: localize({
                    ru: 'Ближайшая дата отсечки для российских депозитариев<sup>2<sup/>',
                    en: 'Record dates for Russian custodians for the next coupon \\ principal payment<sup>2<sup/>'
                }),
                agreementText: {
                    start: localize({
                        ru: '«Ни документ, доступный по указанной ссылке, ни содержащаяся в нем информация не является и ни при каких обстоятельствах не будет являться публичным предложением или рекламой или приглашением сделать предложение о продаже, покупке, обмене или передаче или отчуждении иным образом ценных бумаг и других финансовых инструментов в Российской Федерации в значении законодательства Российской Федерации о ценных бумагах или в интересах физических или юридических лиц, постоянно пребывающих, зарегистрированных, учрежденных или имеющих постоянное место жительства на территории Российской Федерации, которые не являются «квалифицированными инвесторами» в значении статьи 51.2 Федерального Закона Российской Федерации «О рынке ценных бумаг» от 22.04.1996 № 39-ФЗ в последней редакции.',
                        en: '“The linked document and the information therein are not, and under no circumstances are to be construed as, a public offering, an advertisement, or a solicitation of an offer to sell, purchase, exchange or otherwise transfer or dispose of securities and other financial instruments in the Russian Federation within the meaning of Russian securities laws or for the benefit of individuals or legal entities resident, incorporated, established or having their usual residence in the Russian Federation that are not "qualified investors" within the meaning of Article 51.2 of Federal Law No. 39-FZ On the Securities Market dated 22 April 1996, as amended.'
                    }),
                    end: localize({
                        ru: 'Ценные бумаги, указанные в документе, доступном по указанной ссылке, не были и не будут зарегистрированы в Российской Федерации или допущены к размещению и/или публичному распространению в Российской Федерации. Ценные бумаги, указанные в документе, доступном по указанной ссылке, не предназначены для «предложения», «размещения» или «распространения» в соответствии с тем, как каждое из этих понятий определено законами Российской Федерации, в Российской Федерации, за исключением случаев, разрешенных законодательством Российской Федерации. Распространение документа, доступного по указанной ссылке, или содержащейся в нем информации в иных юрисдикциях может быть ограничено законом, и любые лица, получившие доступ к документу, доступному по указанной ссылке, должны ознакомиться с такими ограничениями и соблюдать их»',
                        en: 'The securities referred to in the linked document have not been and will not be registered in the Russian Federation or admitted to placement and/or public distribution in the Russian Federation. The securities referred to in the linked document are not intended for “offering”, “placement” or “distribution”, each as defined in Russian securities laws, in the Russian Federation, except as permitted by Russian laws. The distribution of the linked document or the information therein in other jurisdictions may be restricted by law, and any person with access to the linked document is required to learn about and comply with such restrictions.”'
                    })
                },
                openProspectus: localize({
                    ru: 'Проспект',
                    en: 'Prospectus'
                }),
                agreementButtonText: localize({
                    ru: 'Ознакомлен и согласен с условиями',
                    en: 'I have read and agree to the terms and conditions'
                })
            }
        }
    },
    methods: {
        openModal (href) {
            this.currentLinkModal = href
            if (!localStorage.getItem('isVerifyUser')) {
                this.$root.$bus.$emit('open-modal', {id: this.idModal})

                return
            }

            window.open(this.currentLinkModal, '_blank')
        },
        agreeProspectusTerms () {
            localStorage.setItem('isVerifyUser', 'true')
            window.open(this.currentLinkModal, '_blank')

            this.$root.$bus.$emit('close-modal', {id: this.idModal})
        }
    }
}
</script>

<style lang="scss">
.bonds-page-list {
    padding-top: $token-spacers-s;
    padding-bottom: $token-spacers-s;
    border-top: 1px solid $token-colors-gray-20;
    display: flex;
    flex-direction: column;
    gap: $token-spacers-s;

    &__body {
        padding-left: $token-spacers-2-xs;
        padding-right: $token-spacers-2-xs;
        padding-bottom: $token-spacers-s;
        border-bottom: 1px solid $token-colors-gray-20;
    }

    &__modal {
        .modal-html {
            max-width: 900px;
        }
    }

    @include breakpoint(v-tablet) {
        padding-top: $token-spacers-s;
        gap: $token-spacers-2-xs;

        &__body {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: $token-spacers-2-xs;
        }
    }
}
</style>
