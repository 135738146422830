/* eslint-disable */
require('./award_mob')
require('./award')
require('./construction_mob')
require('./construction')
require('./garbage_car_mob')
require('./garbage_car')
require('./pin-cnn_mob')
require('./pin-cnn')
require('./plan_mob')
require('./plan')
require('./reclamation_mob')
require('./reclamation')
require('./working_mob')
require('./working')
