/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.505 8.132a.4.4 0 00-.566 0l-1.835 1.835a5.4 5.4 0 00-.502 7.059l4.24 5.652a22.395 22.395 0 004.48 4.48l5.653 4.24a5.4 5.4 0 007.058-.502l1.836-1.835a.4.4 0 000-.566l-4.808-4.808a.4.4 0 00-.566 0l-1.981 1.982a1.43 1.43 0 01-1.65.267 19.677 19.677 0 01-8.8-8.8 1.43 1.43 0 01.268-1.65l1.981-1.98a.4.4 0 000-.566l-4.808-4.808zm-1.414-.849a1.6 1.6 0 012.263 0l4.808 4.808a1.6 1.6 0 010 2.263l-1.982 1.981a.23.23 0 00-.043.265 18.477 18.477 0 008.263 8.263.23.23 0 00.265-.043l1.982-1.981a1.6 1.6 0 012.262 0l4.808 4.808a1.6 1.6 0 010 2.262l-1.835 1.836a6.6 6.6 0 01-8.627.613l-5.653-4.24a23.598 23.598 0 01-4.72-4.72l-4.24-5.652a6.6 6.6 0 01.613-8.627l1.836-1.836z" _fill="#0077C8"/>'
  }
})
