<template>
    <div class="notification">
        <div class="notification__image">
            <slot name="image"/>
        </div>
        <div class="notification__description">
            <slot name="description"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'notification'
}
</script>

<style lang="scss">

.notification {
    display: flex;

    &__image {
        @include margin-level(right, 4XL);

        @include breakpoint (v-tablet) {
            margin-right: 0;
            margin-bottom: 40px;
        }
    }

    @include breakpoint (v-tablet) {
        flex-direction: column;
    }

    .z-caption__text {
        max-width: 100%;
    }
}

</style>
