<template>
    <div class="bonds-page-column" :style="`max-width: ${maxWidth}`">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'bonds-page-column',
    props: {
        maxWidth: {
            type: String,
            default: 'auto'
        }
    }
}
</script>

<style lang="scss">
.bonds-page-column {
    display: flex;
    flex-direction: column;
    gap: $token-spacers-xs;
}
</style>
