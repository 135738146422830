<template>
<div class="reports">
    <div class="reports__wrapper">
        <div class="row u-bottom-margin--s">
            <div class="col-default-3 col-tablet-4 col-v-tablet-6 col-mobile-12" v-if="data['annual-report']">
                <report-block
                    :data="{
                        image: data['annual-report'].arImg.link,
                        name: 'Годовой отчет за 2020 г.',
                        fileLink: data['annual-report'].arFile.link,
                        onlinelink: data['annual-report'].arUrl
                    }"
                />
            </div>
            <div class="col-default-3 col-tablet-4 col-v-tablet-6 col-mobile-12" v-if="data['sustability-report']">
                <report-block
                    :data="{
                        image: data['sustability-report'].csrImg.link,
                        name: 'Годовой отчет за 2020 г.',
                        fileLink: data['sustability-report'].csrFile.link,
                        onlinelink: data['sustability-report'].csrUrl
                    }"
                />
            </div>
            <div class="col-default-3 col-tablet-4 col-v-tablet-6 col-mobile-12" v-if="data['white-book']">
                <report-block
                    :data="{
                        image: data['white-book'].wbImg.link,
                        name: 'Годовой отчет за 2020 г.',
                        fileLink: data['white-book'].wbFile.link
                    }"
                />
            </div>
        </div>
    </div>

    <z-link href="#">
        <template #left>
            <z-icon
                name="round-arrow"
                dir="right"
                width="32"
                height="32"
            />
        </template>
        <template #default>Все годовые отчеты</template>
    </z-link>
</div>
</template>

<script>
export default {
    name: 'reports',
    data () {
        return {
            data: {}
        }
    },
    created () {
        if (this.$root.app.components.investors) {
            this.data = this.$root.app.components.investors['results-and-reports'] || {}
        }
    }
}
</script>

<style lang="scss" scoped>
.reports {
    &__wrapper {
        @include margin-level(left, S, true);
    }
}
</style>
