<template>
    <div class="objects-switcher">
        <div class="container">
            <div class="objects-switcher__controls">
            <span
                class="objects-switcher__title"
                :class="{'objects-switcher__title--active': !state}"
            >
                Объект программы</span>
                <z-switch id="objects" :check="state" @change="switchHandler"/>
                <span
                    class="objects-switcher__title"
                    :class="{'objects-switcher__title--active': state}"
                >
                Объекты на&nbsp;карте</span>
            </div>
        </div>
        <pseudo-slider v-show="!state"
                       :links="[
                    {
                        id: 'one',
                        name: 'Аварийный пятиэтажный дом'
                    },
                    {
                        id: 'two',
                        name: 'Бывшая база «Норильскснаба»'
                    },
                    {
                        id: 'three',
                        name: 'Депо «Север»'
                    }
                ]"
                       current="one"
        />
        <ya-map v-show="state"/>
    </div>
</template>

<script>
import PseudoSlider from '@/views/CleanNorilsk/views/Main/components/PseudoSlider/PseudoSlider.vue'

export default {
    name: 'ObjectsSwither',
    components: {
        PseudoSlider
    },
    data () {
        return {
            state: false
        }
    },
    methods: {
        switchHandler (payload) {
            this.state = payload
        }
    }
}
</script>

<style lang="scss">
.objects-switcher {
    padding-top: 152px;
    background-color: #012d83;

    @include breakpoint(laptop) {
        padding-top: 104px;
    }

    @include breakpoint(tablet) {
        padding-top: $token-spacers-2-xl;
    }

    @include breakpoint(mobile) {
        padding-top: $token-spacers-xl;
    }

    &__controls {
        display: flex;
        justify-content: flex-start;
        padding-left: 30%;
        @include margin-level(bottom, L);

        @include breakpoint(laptop) {
            padding-left: 25%;
        }

        @include breakpoint(tablet) {
            padding-left: 0;
            justify-content: flex-start;
        }

        .z-switch__controls {
            margin: 0;
        }

        .z-switch__slide {
            background-color: $token-colors-blue !important;
            background: linear-gradient(123.73deg, #0077C8 20.82%, rgba(0, 119, 200, 0) 99.26%),
            #004C97 !important;
        }
    }

    &__title {
        padding: 0;
        border: none;
        background: none;
        margin-right: 16px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
        color: #858DA6;

        &:last-child {
            margin-left: 16px;
        }

        &--active {
            color: #0290F0;
        }

        @include breakpoint(v-tablet) {
            font-size: 23px;
        }

        @include breakpoint(mobile) {
            font-size: 20px;
            width: min-content;
        }
    }
}
</style>
