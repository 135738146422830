/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'professionalism': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M44 74V39h6v3.897a.915.915 0 00.1.503c.1.4.5.6.9.6h24c.4 0 .7-.2.9-.5.2-.3.2-.7 0-1L69.2 32l6.7-10.5c.2-.3.2-.7 0-1-.2-.3-.5-.5-.9-.5H59v1.9h14.2l-6 9.5c-.2.4-.2.8 0 1.1l6 9.5H53.4l3.3-3.3c.2-.2.3-.4.3-.7V18c0-.6-.4-1-1-1H44v-5h-6v62h-8V52h4V33c0-2.8-2.2-5-5-5H17c-2.8 0-5 2.2-5 5v19h4v22h-4v2h64v-2h-4V60h-6v-4H54v4h-6v14h-4zm12-14h8v-2h-8v2zm14 2v4H50v-4h20zm-9 6h9v6H50v-6h9v2h2v-2zm-9-27.4l1.6-1.6H52v1.6zM42 18v56h-2V14h2v4zm13 19H44V19h11v18zM18 74h4V52h2v22h4V50H18v24zm0-38v12h10V36h2v14h2V33c0-1.7-1.3-3-3-3H17c-1.7 0-3 1.3-3 3v17h2V36h2zm5-10c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm42 13h-2v2h2v-2zm-6 0h2v2h-2v-2zm-37 1h2v2h-2v-2z" _fill="#0077C8"/>'
  }
})
