/* eslint-disable */
require('./danger')
require('./doc')
require('./mic')
require('./mp3')
require('./pdf')
require('./photo')
require('./presentation')
require('./press-release')
require('./rf')
require('./rub')
require('./upload')
require('./usd')
require('./video')
