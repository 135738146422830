<template>
    <div class="cmd-past-event" v-if="data">
        <z-button
            tag="a"
            :href="data.broadcast.link"
            kind="secondary"
            size="l"
            v-if="data.broadcast && data.broadcast.link"
            class="u-bottom-margin--l"
        >
            <span class="u-bold" v-html="text.broadcast"></span>
            <template v-slot:right>
                <z-icon name="external" width="24" height="24"></z-icon>
            </template>
        </z-button>

        <section v-if="data.presentations && data.presentations.length">
            <z-caption
                tag="h3"
                size="xl"
                decor-left
                icon="monitor-play"
                class="u-bototm-margin--2xs"
            >
                <span v-html="text.presentation"></span>
            </z-caption>
            <div class="row">
                <div
                    class="col-default-6 col-v-tablet-12"
                    v-for="(item, index) in data.presentations"
                    :key="index"
                >
                    <presentation-block
                        :data="{
                            link: item.link,
                            name: item.name || `${text.presentationName} ${data.year}`,
                            size: item.size,
                            type: item.type,
                            image: item.image
                        }"
                    />
                </div>
            </div>
        </section>

        <section class="u-top-margin--l" v-if="data['press-release'] && data['press-release'].detail">
            <z-caption
                tag="h3"
                size="xl"
                decor-left
                icon="file/default"
                class="u-bototm-margin--2xs"
            >
                <span v-html="text['press-release']"></span>
            </z-caption>
            <div class="cmd-past-event__content">
                <div class="u-bottom-margin--2xs" v-html="data['press-release'].detail"></div>
                <z-link :href="data['press-release'].link" size="m" v-if="data['press-release'].link">
                    <span v-html="text.more"></span>
                    <template v-slot:right>
                        <z-icon name="arrow-long" dir="right" width="16" height="16"></z-icon>
                    </template>
                </z-link>
            </div>
        </section>

        <section class="u-top-margin--l">
            <z-filelist horizontal size="l" theme="fancy">
                <z-filelist-item
                    v-if="data.photos"
                    :name="text.photo"
                    :href="data.photos"
                    icon-name="img"
                >
                </z-filelist-item>
                <z-filelist-item
                    v-if="data.videos"
                    :name="text.video"
                    :href="data.videos"
                    icon-name="video"
                >
                </z-filelist-item>
            </z-filelist>
        </section>

    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'cmd-past-event',
    props: {
        data: Object
    },
    data () {
        return {
            text: {
                broadcast: localize({
                    ru: 'Ссылка на трансляцию',
                    en: 'Link to webcast'
                }),
                presentation: localize({
                    ru: 'Презентации',
                    en: 'Presentations'
                }),
                'press-release': localize({
                    ru: 'Пресс-релиз',
                    en: 'Press-release'
                }),
                more: localize({
                    ru: 'Подробнее',
                    en: 'More'
                }),
                video: localize({
                    ru: 'Видеоархив',
                    en: 'Videos'
                }),
                photo: localize({
                    ru: 'Фотоматериалы',
                    en: 'Photos'
                }),
                presentationName: localize({
                    ru: 'День инвестора',
                    en: 'Capital Markets Day'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.cmd-past-event {
    &__content {
        @include padding-level(left, XL);

        @include breakpoint (mobile) {
            padding-left: 0;
        }
    }
}
</style>
