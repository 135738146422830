<template>
    <custom-modal
        class="suppliers-contacts-modal"
        v-slot="scope">
        <z-caption
            class="suppliers-contacts-modal__title"
            tag="h3"
            size="2xl"
            icon="message">
            {{ title }}
        </z-caption>

        <suppliers-contacts-form
            class="suppliers-contacts-modal__form"
            @closeModal="scope.closeModal"
        />
    </custom-modal>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'suppliers-contacts-modal',
    data () {
        return {
            title: localize({
                ru: 'Отправьте ваш вопрос',
                en: 'Send your question'
            })
        }
    }
}
</script>

<style lang="scss">
.suppliers-contacts-modal {
    &__form {
        max-width: 880px;
    }
}
</style>
