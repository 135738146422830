<template>
    <iframe
        :src="src"
        width="100%"
        height="500"
        frameborder="0"
        scrolling="no"
        :id="id"
    ></iframe>
</template>
<script>
export default {
    name: 'euroland',
    props: {
        src: String,
        id: String
    },
    data () {
        return {
            script: '//tools.euroland.com/tools/common/eurolandiframeautoheight/eurolandtoolsintegrationobject.js'
        }
    },
    mounted () {
        this.initELscript()
    },
    methods: {
        initELscript () {
            const apiScript = document.querySelector(`script[src="${this.script}"]`)
            if (!apiScript) {
                const script = document.createElement('script')

                script.async = true
                script.src = this.script

                document.head.appendChild(script)
            }
            /* eslint-disable no-undef */
            EurolandToolIntegrationObject.set(this.id)
        }

    }
}
</script>
