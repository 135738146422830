/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ni': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" d="M9.5 18.24A8.74 8.74 0 109.5.76a8.74 8.74 0 000 17.48z" _fill="#004C97" _stroke="#004C97" stroke-width="1.5" stroke-miterlimit="10"/><path pid="1" d="M11.322 12.92H9.933L6.711 8.512v4.408H5.272V6.161h1.48l3.13 4.246V6.16h1.44v6.759zm2.042-5.421a.776.776 0 01-.77-.77c0-.426.354-.76.77-.76.425 0 .77.334.77.76a.77.77 0 01-.77.77zm.648 5.421h-1.287V8.026h1.287v4.894z" _fill="#fff"/>'
  }
})
