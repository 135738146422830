<template>
    <div :class="classArray">
        <div class="metal-card__inner">
            <div class="metal-card__details">
                <div class="metal-card__row">
                    <div class="metal-card__col"></div>
                    <div class="metal-card__col">
                        <div class="metal-card__head">
                            <z-caption
                                v-if="head"
                                size="m"
                                weight="600"
                                line-height="1.2"
                                class="u-collapse--all success-text"
                            >
                                <vue-raw :raw="head"/>
                            </z-caption>
                            <slot name="head"/>
                        </div>
                    </div>
                </div>
                <div class="metal-card__row">
                    <div class="metal-card__col">
                        <div class="metal-card__value-title">
                            <div
                                v-if="circlePositionAuto === 'over' || circlePositionAuto === 'under'"
                                :class="`metal-card__circle-${circlePositionAuto}`"
                                :style="`background-color: ${circleColor};
                                            height: ${circleSizeAuto};
                                            width: ${circleSizeAuto}`"
                            />
                            <div class="metal-card__value">
                                <div
                                    v-if="circlePositionAuto === 'inside'"
                                    class="metal-card__circle-inside"
                                    :style="`background-color: ${circleColor};
                                            height: ${circleSizeAuto};
                                            width: ${circleSizeAuto}`"
                                />
                                <z-caption
                                    v-if="value && valueText"
                                    :theme="theme"
                                    size="m"
                                    weight="500"
                                    line-height="1.2"
                                    class="u-collapse--all"
                                >
                                    <vue-raw :raw="valueText"/>
                                </z-caption>
                            </div>
                        </div>
                    </div>
                    <div class="metal-card__col">
                        <div class="metal-card__title">
                            <z-caption
                                v-if="title"
                                :theme="theme"
                                size="3xl"
                                weight="700"
                                class="u-collapse--all"
                            >
                                <vue-raw :raw="title"/>
                            </z-caption>
                            <slot name="title"/>
                        </div>
                    </div>
                </div>
                <div class="metal-card__row">
                    <div class="metal-card__col"></div>
                    <div class="metal-card__col">
                        <div class="metal-card__subtitle">
                            <z-caption
                                v-if="subtitle"
                                :theme="theme"
                                size="s"
                                weight="400"
                                class="u-collapse--all"
                            >
                                <vue-raw :raw="subtitle"/>
                            </z-caption>
                            <slot name="subtitle"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'metal-card',
    props: {
        theme: {
            type: String,
            default: 'default',
            validator: prop => ['default', 'white']
        },
        value: [Number, String],
        valueText: String,
        head: String,
        title: String,
        subtitle: String,
        background: {
            type: Boolean,
            default: false
        },
        circleColor: String,
        circlePosition: {
            type: String,
            default: 'auto',
            validator: prop => ['auto', 'inside', 'over', 'under']
        },
        circleSize: {
            type: String,
            default: 'auto'
        }
    },
    computed: {
        circlePositionAuto () {
            if (this.circlePosition === 'auto') {
                if (this.integerValue < 10) {
                    return 'inside'
                } else if (this.integerValue > 25) {
                    return 'under'
                } else {
                    return 'over'
                }
            } else {
                return this.circlePosition
            }
        },
        circleSizeAuto () {
            if (this.circleSize === 'auto') {
                if (this.integerValue < 5) {
                    return '10px'
                } else if (this.integerValue > 45) {
                    return '90px'
                } else {
                    return `${this.integerValue * 2}px`
                }
            } else {
                return this.circleSize
            }
        },
        classArray () {
            let classArray = ['metal-card']

            classArray.push(`metal-card--theme-${this.theme}`)
            classArray.push(`metal-card--circle-${this.circlePositionAuto}`)
            if (this.background) classArray.push(`metal-card--has-background`)

            return classArray
        },
        circleClassArray () {
            let circleClassArray = ['metal-card__circle']
            circleClassArray.push(`metal-card__circle--${this.circlePositionAuto}`)

            return circleClassArray
        },
        integerValue () {
            return parseFloat(this.value)
        }
    },
    data () {
        return {
            text: {
                'text': localize({
                    ru: 'text',
                    en: 'text'
                })
            }
        }
    },
    created () {},
    methods: {

    }
}
</script>

<style lang="scss">
.metal-card {
    $parent: &;

    display: flex;
    align-items: center;
    width: 310px;
    padding: 32px;

    &__inner {
        min-height: 126px;
        display: flex;
        align-items: center;
    }

    &__details {
    }

    &__row {
        display: table-row;
    }

    &__col {
        display: table-cell;
        vertical-align: middle;

        & + & {
            padding-left: 1em;
        }
    }

    &__value-title {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__value {
        display: flex;
        align-items: center;
        padding: 4px 16px;
        border-radius: 50px;
        border: 1px solid #FFFFFF;
        z-index: 1;

        .z-caption {
            font-family: "Roboto Mono";
        }
    }

    &__circle-inside {
        display: block;
        margin-right: .5em;
        border-radius: 50%;
    }

    &__circle-over {
        display: block;
        margin-right: -.5em;
        border-radius: 50%;
    }

    &__circle-under {
        position: absolute;
        border-radius: 50%;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, 50%);
    }

    &--theme-default {
        #{$parent}__value {
            border: 1px solid black;

            .z-caption {
                color: black !important;
            }
        }
    }

    &--theme-white {}

    &--has-background {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(20px);
        border-radius: 0px $boder-radius-l $boder-radius-l $boder-radius-l;
    }

    &--circle-under {
        #{$parent}__value {
            border: 1px solid black;

            .z-caption {
                color: black !important;
            }
        }
    }

    @include breakpoint (mobile) {
        width: 100%;
        padding: 16px;

        &--has-background {
            border-radius: 0px $boder-radius-m $boder-radius-m $boder-radius-m;
        }
    }
}
</style>
