<template>
    <div
        v-if="itemsData && itemsData.length"
        :class="['news-list', classObject]"
    >
        <slot name="head"></slot>
        <div
            v-for="(item, index) in itemsData"
            :key="index"
            class="news-list__item"
        >
            <div v-if="item.date" class="news-list__item-date">
                <date-block
                    :theme="deviceWithInlineDate.includes(device) ? 'inline' : ''"
                    :date="item.date"
                ><slot /></date-block>
            </div>
            <div class="news-list__body">
                <tag
                    v-if="item.tag"
                    :interactive="false"
                    :size="theme === 'aside' ? 's' : 'm'"
                    :decoration="theme === 'aside' ? true : false"
                    theme="light-blue"
                    :name="item.tag"
                    class="news-list__item-tag"
                ></tag>
                <a
                    v-if="item.name"
                    :href="item.link"
                    class="news-list__item-title"
                    @click="handlerTrackGoal(goalNameListNews)">
                    <vue-raw :raw="item.name"/>
                </a>
                <p v-if="item.text" class="news-list__item-text">
                    <vue-raw :raw="item.text"/>
                </p>
            </div>
        </div>
        <slot name="bottom"></slot>
    </div>
</template>

<script>
// TODO enum list tags
// 'Пресс-релизы': 'Press releases' / 'Пресс-релизы'
import { mixinDevice } from '@/utils/mixin'
import { trackGoal } from '@/utils/analytics'

export default {
    name: 'news-list',
    mixins: [mixinDevice],
    props: {
        component: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'default'
        },
        items: {
            type: [Array],
            default: () => []
        },
        deviceWithInlineDate: {
            type: Array,
            default: () => ['mobile']
        },
        goalNameListNews: {
            type: String
        }
    },
    data () {
        return {
            itemsData: this.items
        }
    },
    watch: {
        items (array) {
            this.itemsData = array
        }
    },
    created () {
        if (this.component.length > 0) {
            let componentObjNewsArr = []
            switch (this.component) {
            case 'antivirusResponseNews':
                componentObjNewsArr = this.$root.app.components['antivirus-response'].news
                break
            case 'climateСhangeNews':
                componentObjNewsArr = this.$root.app.components['climate-change'].news
                break
            case 'sustainabilityPageNews':
                componentObjNewsArr = this.$root.app.components['sustainability-page'].news
                break
            case 'communitiesNews':
                componentObjNewsArr = this.$root.app.components['communities'].news
                break
            case 'peopleNorthNews':
                componentObjNewsArr = this.$root.app.components['people-north'].news
                break
            case 'environmentNews':
                componentObjNewsArr = this.$root.app.components['environment'].news
                break
            case 'sustainableMetalsNews':
                componentObjNewsArr = this.$root.app.components['sustainable-metals-page'].news
                break
            case 'innovationsNews':
                componentObjNewsArr = this.$root.app.components['innovations'].news
                break
            case 'publicationsNews':
                componentObjNewsArr = this.$root.app.components['news-detail'].publications
            }

            if (componentObjNewsArr.length > 0) {
                this.itemsData = componentObjNewsArr.map((obj) => {
                    return {
                        date: obj.activeFrom,
                        link: obj.detailPageUrl,
                        name: obj.name
                    }
                })
            }
        }
    },
    computed: {
        classObject () {
            return {
                [`news-list--theme-${this.theme}`]: this.theme
            }
        }
    },
    methods: {
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
