<template>
    <div class="tl-logo">
        <tl-line :month="month" :day="day">
            <img
                class="tl-logo__image"
                :src="`${this.image ? this.image : '/images/clean-norilsk/tl-logo.svg'}`"
            />
            <div class="tl-logo__content">
                <div class="tl-logo__title" v-html="title" v-if="title"></div>
                <tl-link :href="href" v-if="href" />
            </div>
        </tl-line>
    </div>
</template>

<script>
import TlLink from './TlLink.vue'
import TlLine from './TlLine.vue'

export default {
    name: 'tl-logo',
    components: {
        TlLink,
        TlLine
    },
    props: {
        image: String,
        title: String,
        href: String,
        month: String,
        day: String
    }
}
</script>

<style lang="scss">
.tl-logo {
    display: flex;
    flex-direction: column;
    max-width: 515px;
    min-width: 291px;
    width: 100%;

    @include breakpoint (v-tablet) {
        max-width: 534px;
        align-items: center;
    }

    @include breakpoint (mobile) {
        max-width: 288px;
        align-items: flex-start;
    }

    &__image {
        max-width: 100%;
    }

    &__content {
        width: 100%;
        margin-top: $token-spacers-m;

        @include breakpoint (v-tablet) {
            margin-top: $token-spacers-s;
        }

        @include breakpoint (mobile) {
            margin-top: $token-spacers-xs;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        color: $token-colors-black;

        @include breakpoint (v-tablet) {
            font-size: 20px;
        }
    }
}
</style>
