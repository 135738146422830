/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.316 4.648a.505.505 0 00-.432-.6 7.962 7.962 0 107.085 7.955.505.505 0 00-.546-.499.566.566 0 00-.519.565 6.897 6.897 0 11-6.208-6.97.566.566 0 00.62-.45z" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M18.969 4.297H14.59a.5.5 0 000 1h3.172l-7.108 7.108a.5.5 0 00.707.707l7.108-7.108v3.172a.5.5 0 001 0V4.803v-.506h-.5z" _fill="#0077C8"/>'
  }
})
