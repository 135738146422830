<template>
    <div class="mp-info container">
        <z-caption
            tag="h2"
            size="3xl"
            decor-left
            decor-right
            class="main-page__title u-bottom-margin--l-important"
        >
            <span data-scroll-heading v-html="text.new"></span>
        </z-caption>
        <div class="mp-info__container">
            <div class="mp-info__new info-new" data-scroll-translate>
                <div class="info-new__container">
                    <z-figure
                        v-if="lang !== 'ru'"
                        class="info-new__figure"
                        image="/images/main-page/metals.jpg"
                        :caption="text.susMetals"
                        theme="blue"
                        top-text
                        size="l"
                        link="/business/sustainable-metals/"
                        icon-name="arrow-long"
                        icon-width="24"
                        icon-height="24"
                        @click="handlerTrackGoal('main-3screen-block_new')"
                    />

                    <z-figure
                        v-if="lang === 'ru'"
                        class="info-new__figure"
                        image="/images/main-page/owl.jpg"
                        caption="Чистый Норильск&nbsp;&mdash; программа по благоустройству промышленных территорий"
                        theme="blue"
                        top-text
                        link="/clean-norilsk/"
                        icon-name="arrow-long"
                        icon-width="24"
                        icon-height="24"
                        size="l"
                        @click="handlerTrackGoal('main-3screen-block_new')"
                    />

                    <z-figure
                        class="info-new__figure"
                        :image="text.sulfur.img"
                        :caption="text.sulfur.text"
                        theme="blue"
                        link="/sustainability/projects/sulphur/"
                        icon-name="arrow-long"
                        icon-width="24"
                        icon-height="24"
                        size="l"
                        @click="handlerTrackGoal('main-3screen-block_new')"
                    />

                    <!-- <z-figure
                        class="info-new__figure"
                        :image="text.sulfur.img"
                        :caption="text.sulfur.text"
                        theme="blue"
                        size="l"
                        link="/sustainability/projects/sulphur/" /> -->
                </div>
            </div>
            <div class="mp-info__calendar info-calendar">
                <z-caption
                    tag="h2"
                    decor-left
                    decor-right
                    size="m"
                    weight="700"
                    uppercase
                    class="main-page__title u-collapse--all"
                >
                    <span data-scroll-heading v-html="text.calendar.title"></span>
                </z-caption>
                <calendar
                    data-scroll-translate
                    :items="events"
                    :can-be-closed="true"
                    :interactive="false" />
                <z-button
                    href="/investors/events/"
                    tag="a"
                    kind="text"
                    size="l"
                    class="u-align-self--start u-top-margin--2xl"
                    @click="handlerTrackGoal('main-3screen-all_events')">
                    <template v-slot:left>
                        <z-icon
                            name="arrow-long"
                            dir="right"
                            width="16"
                            height="16"></z-icon>
                    </template>
                    <span v-html="text.calendar.button"></span>
                </z-button>
            </div>
        </div>
    </div>
</template>

<script>
import Calendar from '../components/Calendar.vue'
import { localize } from '@/utils/i18n'
import { trackGoal } from '@/utils/analytics'

export default {
    name: 'section-info',
    props: {
        events: {
            type: Array,
            required: true
        }
    },
    components: {
        Calendar
    },
    data () {
        return {
            lang: this.$root.lang,
            text: {
                new: localize({
                    ru: 'Новое',
                    en: 'New materials'
                }),
                susMetals: localize({
                    ru: 'Чистые металлы',
                    en: 'Sustainable Metals'
                }),
                sulfur: {
                    text: localize({
                        ru: 'Серная программа',
                        en: 'Sulfur Programme'
                    }),
                    img: localize({
                        ru: '/images/main-page/mp-sulfur-ru.jpg',
                        en: '/images/main-page/sulfur-en.jpg'
                    })
                },
                calendar: {
                    title: localize({
                        ru: 'Календарь',
                        en: 'IR Calendar'
                    }),
                    button: localize({
                        ru: 'Все события',
                        en: 'All events'
                    })
                }
            }
        }
    },
    methods: {
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    }
}
</script>

<style lang="scss">
.mp-info {
    background-color: #f5f5f5;
    background: linear-gradient(112.26deg, #f4f6f9 8.89%, #e6e7ee 62.24%), #f5f5f5;
    @include padding-level(top, XL);
    @include padding-level(bottom, XL);

    .container {
        justify-content: start;
    }

    & > .z-caption {
        @include breakpoint(tablet) {
            @include margin-level(bottom, M, false, true);
        }
    }

    &__container {
        display: flex;

        @include breakpoint(tablet) {
            flex-direction: column;
        }
    }

    &__item {
        margin-top: 0;
    }

    &__new {
        height: inherit;
        @include margin-level(right, M);

        @include breakpoint(tablet) {
            margin-right: 0;
            @include margin-level(bottom, M);
        }
    }

    &__calendar {
        background-color: $token-colors-gray-10;
        @include padding-level(top, M);
        @include padding-level(bottom, S);
        @include padding-level(left, M);
        @include padding-level(right, M);
        border-radius: 0px 40px 0px 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 37%;

        @include breakpoint(tablet) {
            width: 100%;
        }

        .mp-calendar {
            @include breakpoint(tablet) {
                flex-direction: row;
                gap: $token-spacers-m;
                max-width: 100%;
            }
            @include breakpoint(v-tablet) {
                flex-direction: column;
                gap: 0;
            }
        }

        .z-button {
            @include padding-level(left, 2XS);

            @include breakpoint(tablet) {
                @include margin-level(top, L);
            }

            @include breakpoint(mobile) {
                @include margin-level(top, S);
            }
        }
    }
}
.info-new {
    max-width: 1224px;
    width: 100%;

    &__container {
        display: flex;
        justify-content: space-between;
        height: 100%;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            gap: 16px;
        }
    }

    &__figure {
        border-top-right-radius: 0 !important;
        margin-top: 0 !important;
        height: auto;

        .z-figure__container {
            height: 100%;
        }

        .z-figure__caption {
            border-top-right-radius: 0 !important;
        }

        .z-figure--size-l .z-figure__caption {
            @include padding-level(top, S);
            @include padding-level(bottom, S);
            @include padding-level(left, M);
            @include padding-level(right, M);
        }

        @include breakpoint(tablet) {
            .z-figure__container .z-figure__image {
                min-height: 400px;
            }

            .z-figure__container--top-text .z-figure__image {
                min-height: 456px;
            }
        }

        @include breakpoint(mobile) {
            .z-figure__container .z-figure__image {
                min-height: 290px;
            }
        }

        &--sulfur {
            overflow: hidden;

            a.z-figure__container {
                &:hover {
                    .z-figure__image {
                        transform: scale(1.1);
                    }
                }
            }

            .z-figure__caption {
                border-top-right-radius: 0 !important;
                position: absolute !important;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .z-figure__image {
                background-position: 60%;
                height: calc(100% - 40px);

                @include breakpoint(laptop) {
                    background-position: 45%;
                }
                @include breakpoint(tablet) {
                    background-position: 80%;
                }
                @include breakpoint(mobile) {
                    background-position: 40%;
                }
            }
        }
    }
}
</style>
