<template>
    <div class="general-meetings">
        <div class="general-meetings__types gm-types">
            <button
                :class="[
                    'gm-types__btn',
                    { 'gm-types__btn--active' : currentType === 'agm' }
                ]"
                @click="setCurrentType('agm')"
            >
                {{ text.agm }}
            </button>
            <button
                :class="[
                    'gm-types__btn',
                    { 'gm-types__btn--active' : currentType === 'egm' }
                ]"
                @click="setCurrentType('egm')"
            >
                {{ text.egm }}
            </button>
        </div>

        <z-tabs
            class="general-meetings__tabs"
            v-show="currentType === 'agm' && agm && agm.length"
            key="agm"
            size="m"
        >
            <template v-slot:label>
                <template v-for="(item, index) in agm">
                    <z-tabs-label
                        :key="`agm_${item.year}`"
                        :id="`agm-${item.year}`"
                        v-if="index < limit"
                    >
                        {{item.year}}
                    </z-tabs-label>
                </template>
                <z-tabs-label
                    key="agm-archive"
                    id="agm-archive"
                    v-if="agm.length > limit"
                    right-side
                >
                    {{ text.archive }}
                </z-tabs-label>
            </template>
            <template v-slot:content>
                <template v-for="(itemA, indexA) in agm">
                    <z-tabs-content
                        :key="`agm-${itemA.year}-content`"
                        :id="`agm-${itemA.year}`"
                        v-if="indexA < limit"
                    >
                        <item
                            v-for="(itemB, indexB) in itemA.items"
                            :data="itemB"
                            :key="indexB"
                        />
                    </z-tabs-content>
                </template>

                <z-tabs-content
                    key="agm-archive-content"
                    id="agm-archive"
                    v-if="agm.length > limit"
                >
                    <template v-for="(itemA, indexA) in agm">
                        <template v-if="indexA >= limit">
                            <item
                                v-for="(itemB, indexB) in itemA.items"
                                :data="itemB"
                                :key="`${indexA}-${indexB}`"
                            />
                        </template>
                    </template>
                </z-tabs-content>
            </template>
        </z-tabs>

        <z-tabs
            class="general-meetings__tabs"
            v-show="currentType === 'egm' && egm && egm.length"
            key="egm"
            size="m"
        >
            <template v-slot:label>
                <template v-for="(item, index) in egm">
                    <z-tabs-label
                        :key="`egm_${item.year}`"
                        :id="`egm-${item.year}`"
                        v-if="index < limit"
                    >
                        {{item.year}}
                    </z-tabs-label>
                </template>
                <z-tabs-label
                    key="egm-archive"
                    id="egm-archive"
                    v-if="egm.length > limit"
                    right-side
                >
                    {{ text.archive }}
                </z-tabs-label>
            </template>
            <template v-slot:content>
                <template v-for="(itemA, indexA) in egm">
                    <z-tabs-content
                        :key="`egm-${itemA.year}-content`"
                        :id="`egm-${itemA.year}`"
                        v-if="indexA < limit"
                    >
                        <item
                            v-for="(itemB, indexB) in itemA.items"
                            :data="itemB"
                            :key="indexB"
                        />
                    </z-tabs-content>
                </template>

                <z-tabs-content
                    key="egm-archive-content"
                    id="egm-archive"
                    v-if="egm.length > limit"
                >
                    <template v-for="(itemA, indexA) in agm">
                        <template v-if="indexA >= limit">
                            <item
                                v-for="(itemB, indexB) in itemA.items"
                                :data="itemB"
                                :key="`${indexA}-${indexB}`"
                            />
                        </template>
                    </template>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import Item from './components/Item.vue'

export default {
    name: 'general-meetings',
    components: {
        Item
    },
    data () {
        return {
            agm: [],
            emg: [],
            currentType: 'agm',
            limit: 7,
            text: {
                agm: localize({
                    ru: 'ГОСА',
                    en: 'AGM'
                }),
                egm: localize({
                    ru: 'ВОСА',
                    en: 'EGM'
                }),
                archive: localize({
                    ru: 'Архив',
                    en: 'Archive'
                })
            }
        }
    },
    created () {
        if (this.$root.app && this.$root.app.components['meeting-of-shareholders']) {
            this.init()
        }
    },
    methods: {
        setCurrentType (type) {
            this.currentType = type
        },
        init () {
            let agm = {}
            let egm = {}

            this.$root.app.components['meeting-of-shareholders'].agm.forEach(item => {
                if (!agm[item.year]) {
                    agm[item.year] = {}
                    agm[item.year].year = item.year
                    agm[item.year].items = []
                }
                agm[item.year].items.push(item)
            })

            this.$root.app.components['meeting-of-shareholders'].egm.forEach(item => {
                if (!egm[item.year]) {
                    egm[item.year] = {}
                    egm[item.year].year = item.year
                    egm[item.year].items = []
                }
                egm[item.year].items.push(item)
            })

            this.agm = Object.values(agm).sort((a, b) => b.year - a.year)
            this.egm = Object.values(egm).sort((a, b) => b.year - a.year)
        }
    }
}
</script>

<style lang="scss">
.gm-types {
    display: flex;

    @include breakpoint (mobile) {
        justify-content: center;
    }

    &__btn {
        padding: 16px 40px;
        font-size: 24px;
        line-height: 130%;
        letter-spacing: -0.005em;
        border-radius: 40px 40px 40px 0px;
        border: 0;
        margin-right: $token-spacers-xs;
        color: $token-colors-gray-30;
        background: $token-colors-gray-10;

       @include breakpoint (mobile) {
            padding: 10px 24px;
            font-size: 18px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: $token-colors-navy-blue;
        }

        &:focus,
        &:active {
            color: $token-colors-blue;
            background: $token-colors-blue-10;
        }

        &--active, &--active:hover, &--active:active, &--active:focus {
            color: #fff;
            background-color: $token-colors-navy-blue;
        }
    }
}

.general-meetings {
    &__tabs {
        margin-top: $token-spacers-m;

        @include breakpoint (v-tablet) {
            margin-top: $token-spacers-s;
        }
    }
}
</style>
