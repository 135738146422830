import {getContacts} from '../../api/contacts'

const app = {
    state: {
        device: 'desktop',
        siteSelectorLink: '',
        heroTitle: '',
        heroSrc: '',
        contacts: null
    },
    mutations: {
        SetSiteSelectorLink: (state, siteSelectorLink) => {
            state.siteSelectorLink = siteSelectorLink
        },
        ToggleDevice: (state, device) => {
            state.device = device
        },
        SetHeroTitle: (state, heroTitle) => {
            state.heroTitle = heroTitle
        },
        SetHeroSrc: (state, heroSrc) => {
            state.heroSrc = heroSrc
        },
        SetContacts: (state, payload) => {
            state.contacts = payload
        }
    },
    actions: {
        async SetContactsAction ({commit}) {
            const payload = await getContacts()
            commit('SetContacts', payload)
        },
        SetSiteSelectorLink ({ commit }, link) {
            commit('SetSiteSelectorLink', link)
        },
        ToggleDevice ({ commit }, device) {
            commit('ToggleDevice', device)
        },
        SetHeroTitle ({ commit }, title) {
            commit('SetHeroTitle', title)
        },
        SetHeroSrc ({ commit }, heroSrc) {
            commit('SetHeroSrc', heroSrc)
        }
    }
}

export default app
