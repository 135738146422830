/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/presentation': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31972)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M63.4 70a2.6 2.6 0 012.6-2.6h16a2.6 2.6 0 012.6 2.6v10a2.6 2.6 0 01-2.6 2.6h-7.4v2.8H78a.6.6 0 110 1.2h-8a.6.6 0 110-1.2h3.4v-2.8H66a2.6 2.6 0 01-2.6-2.6V70zM74 81.4h8a1.4 1.4 0 001.4-1.4V70a1.4 1.4 0 00-1.4-1.4H66a1.4 1.4 0 00-1.4 1.4v10a1.4 1.4 0 001.4 1.4h8z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31972" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31972"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31972" result="effect2_dropShadow_9226_31972"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31972" result="shape"/></filter></defs>'
  }
})
