<template>
    <div v-if="data.length">
        <z-table
            :columns="firstHeaderList"
            v-if="firstCommittee.length"
            class="committees-table"
            :colgroup="['33%', '33%', '33%']"
        >
            <z-table-row>
                <z-table-col
                    v-for="(committee, index) in firstCommittee"
                    :key="index"
                    :index="index"
                >
                    <z-list>
                        <z-list-item v-for="(member, index) in committee.members" :key="index">
                            <template v-if="member.director">
                                <z-link
                                    :href="member.link"
                                    :title="member.name"
                                    underline
                                    weight="600"
                                >
                                    {{ member.name }}
                                </z-link>
                                <z-caption
                                    tag="p"
                                    size="s"
                                    weight="600"
                                    class="gray-35-text u-collapse--all"
                                >{{ text.chairman }}</z-caption>
                            </template>
                            <template v-else-if="member['acting-director']">
                                <z-link
                                    :href="member.link"
                                    :title="member.name"
                                    underline
                                    weight="600"
                                >
                                    {{ member.name }}
                                </z-link>
                                <z-caption
                                    tag="p"
                                    size="s"
                                    weight="600"
                                    class="gray-35-text u-collapse--all"
                                >{{ text.actingChairman }}</z-caption>
                            </template>
                            <template v-else>
                                <z-link
                                    :href="member.link"
                                    :title="member.name"
                                    underline
                                >
                                    {{ member.name }}
                                </z-link>
                            </template>
                        </z-list-item>
                    </z-list>
                </z-table-col>
            </z-table-row>
        </z-table>
        <z-table
            :columns="secondHeaderList"
            v-if="secondCommittee.length"
            class="committees-table"
            :colgroup="['33%', '33%', '33%']"
        >
            <z-table-row>
                <z-table-col
                    v-for="(committee, index) in secondCommittee"
                    :key="index"
                    :index="index"
                >
                    <z-list>
                        <z-list-item v-for="(member, index) in committee.members" :key="index">
                            <template v-if="member.director">
                                <z-link
                                    :href="member.link"
                                    :title="member.name"
                                    underline
                                    weight="600"
                                >
                                    {{ member.name }}
                                </z-link>
                                <z-caption
                                    tag="p"
                                    size="s"
                                    weight="600"
                                    class="gray-35-text u-collapse--all"
                                >{{ text.chairman }}</z-caption>
                            </template>
                            <template v-else-if="member['acting-director']">
                                <z-link
                                    :href="member.link"
                                    :title="member.name"
                                    underline
                                    weight="600"
                                >
                                    {{ member.name }}
                                </z-link>
                                <z-caption
                                    tag="p"
                                    size="s"
                                    weight="600"
                                    class="gray-35-text u-collapse--all"
                                >{{ text.actingChairman }}</z-caption>
                            </template>
                            <template v-else>
                                <z-link
                                    :href="member.link"
                                    :title="member.name"
                                    underline
                                >
                                    {{ member.name }}
                                </z-link>
                            </template>
                        </z-list-item>
                    </z-list>
                </z-table-col>
            </z-table-row>
        </z-table>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'committees',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            columnId: ['MEMBERS_AUDIT', 'MEMBERS_GOVERNANCE', 'MEMBERS_CLIMATE', 'MEMBERS_STRATEGY', 'MEMBERS_BUDGET', 'MEMBERS_DEAL'],
            text: {
                chairman: localize({
                    ru: 'Председатель',
                    en: 'Chairman'
                }),
                actingChairman: localize({
                    ru: 'И.О. Председателя',
                    en: 'Acting Chairman'
                })
            }
        }
    },
    methods: {
        getPriority (id) {
            return this.reversedCols.findIndex((item) => item === id)
        }
    },
    computed: {
        adaptedCommittee () {
            if (this.data.length) {
                return this.data.slice().sort((itemA, itemB) => this.getPriority(itemB.id) - this.getPriority(itemA.id))
            }
            return []
        },
        reversedCols () {
            return this.columnId.slice().reverse()
        },
        tableHeaderList () {
            return this.adaptedCommittee.map((committee) => {
                return committee.name
            })
        },
        firstCommittee () {
            return this.adaptedCommittee.slice().splice(0, 3)
        },
        secondCommittee () {
            return this.adaptedCommittee.slice().splice(3)
        },
        firstHeaderList () {
            if (this.tableHeaderList.length) {
                return this.tableHeaderList.slice().splice(0, 3)
            }
            return []
        },
        secondHeaderList () {
            if (this.tableHeaderList.length) {
                return this.tableHeaderList.slice().splice(3)
            }
            return []
        }
    }
}
</script>

<style lang="scss">
.committees-table {
    .z-table-col {
        text-align: left;
        font-size: 16px;
        @include padding-level(left, XS);
        @include padding-level(right, XS);

        &:last-child,
        &:first-child {
            @include padding-level(left, S);
            @include padding-level(right, S);
        }
    }

    .z-table-col {
        vertical-align: text-top;
    }

    .z-list-item {
        @include padding-level(left, S, true);

        &:before {
            left: 0 !important;
        }
    }

    .z-table-col--head {
        vertical-align: text-top;
    }

    .z-table-row--body {
        &:hover {
            background: $token-colors-white;
        }
    }
}
</style>
