<template>
    <custom-modal
        class="contacts-nca-modal"
        v-slot="scope">
        <z-caption
            class="contacts-nca-modal__title"
            tag="h3"
            size="2xl"
            icon="message">
            {{ title }}
        </z-caption>
        <contacts-nca-form
            class="contacts-nca-modal__form"
            @closeModal="scope.closeModal" />
    </custom-modal>
</template>

<script>
import { localize } from '@/utils/i18n'
import ContactsNcaForm from './components/ContactsNcaForm'

export default {
    name: 'contacts-nca-modal',
    components: {
        ContactsNcaForm
    },
    data () {
        return {
            title: localize({
                ru: 'Отправьте ваш вопрос',
                en: 'Send your question'
            })
        }
    }
}
</script>

<style scoped lang="scss">
.contacts-nca-modal {
    &__form {
        max-width: 880px;
    }
}
</style>
