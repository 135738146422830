/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Ag': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32288)"/><path pid="2" d="M37.176 40H33.85l-1.225-3.2h-7.65L23.75 40h-3.325l6.55-16.675h3.65L37.176 40zM31.8 34.225l-3-7.95-3 7.95h6zM43.697 44.9c-2.05 0-3.575-.45-5.05-1.8l1.225-1.9c.975 1.125 2.225 1.55 3.825 1.55 1.725 0 3.575-.8 3.575-3.275v-1.45c-.925 1.2-2.3 2-3.875 2-3.075 0-5.35-2.225-5.35-6.2 0-3.9 2.25-6.2 5.35-6.2 1.525 0 2.9.7 3.875 1.975v-1.675h2.625v11.45c0 4.35-3.225 5.525-6.2 5.525zm.475-7.2c1.225 0 2.525-.725 3.1-1.625v-4.5c-.575-.9-1.875-1.625-3.1-1.625-2.075 0-3.4 1.55-3.4 3.875s1.325 3.875 3.4 3.875z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32288" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
