/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.926 8.922c.247.28.425.615.517.977.255.98.557 2.772.557 5.744s-.302 4.761-.557 5.743a2.356 2.356 0 01-1.423 1.612c-3.222 1.244-8.274 1.288-8.955 1.288h-.13c-.681 0-5.73-.044-8.955-1.288a2.357 2.357 0 01-1.423-1.612C5.302 20.406 5 18.615 5 15.643c0-2.972.303-4.764.557-5.744A2.357 2.357 0 016.98 8.29c3.34-1.29 8.641-1.29 9.012-1.289h.016c.37 0 5.672-.002 9.012 1.289.348.136.658.353.906.633zm-11.28 10.19l4.715-3.142a.392.392 0 000-.654l-4.714-3.143a.392.392 0 00-.611.327v6.286a.392.392 0 00.61.327z" _fill="#0077C8"/>'
  }
})
