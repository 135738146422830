<template>
    <div class="clean-navigation-mobile" v-if="data">
        <z-icon
            @click.prevent="toggleNav"
            width="32"
            height="32"
            name="menu"
            v-show="!opened"
        ></z-icon>
        <div class="clean-navigation-mobile__nav" v-show="opened">
            <div class="clean-navigation-mobile__top">
                <div class="container">
                    <z-link href="/clean-norilsk/">
                        <img class="clean-header__logo" src="/images/logos/clean-norilsk.svg" alt="">
                    </z-link>
                    <z-icon
                        @click.prevent="toggleNav"
                        width="40"
                        height="40"
                        name="close"
                        v-show="opened"
                    ></z-icon>
                </div>
            </div>
            <nav class="container">
                <ul class="clean-navigation-mobile__list">
                    <li
                        class="clean-navigation-mobile__item"
                        v-for="(item, index) in data"
                        :key="index"
                    >
                        <component
                            :is="item.items && item.items.length ? 'div' : 'a'"
                            :href="item.items && item.items.length ? null : item.link"
                            @click="setIndex(index)"
                            :class="['clean-navigation-mobile__link', {'clean-navigation-mobile__link--active' : item.active}]"
                        >
                            <div class="clean-navigation-mobile__title">
                                <span v-html="item.name"></span>
                                <z-icon
                                    width="24"
                                    height="24"
                                    name="arrow"
                                    dir="down"
                                    v-if="item.items && item.items.length && curIndex === index"
                                ></z-icon>
                                <z-icon
                                    width="24"
                                    height="24"
                                    name="arrow"
                                    dir="right"
                                    v-else-if="item.items && item.items.length"
                                ></z-icon>
                            </div>
                            <ul
                                class="clean-navigation-mobile__sublist"
                                v-if="item.items && item.items.length && curIndex === index"
                            >
                                <li
                                    class="clean-navigation-mobile__subitem"
                                    v-for="(subItem, i) in item.items"
                                    :key="i"
                                >
                                    <a
                                        :href="subItem.link"
                                        :class="['clean-navigation-mobile__sublink', {'clean-navigation-mobile__sublink--active' : subItem.active}]"
                                        v-html="subItem.name"
                                    ></a>
                                </li>
                            </ul>
                        </component>
                    </li>
                </ul>
                <z-button
                    size="m"
                    tag="a"
                    href="/"
                >
                    Основной сайт
                </z-button>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: 'clean-navigation-mobile',
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            opened: false,
            curIndex: null
        }
    },
    methods: {
        toggleNav () {
            if (!this.opened) {
                this.opened = true
                document.body.style.overflow = 'hidden'
            } else {
                this.opened = false
                document.body.style.overflow = ''
            }
        },
        setIndex (index) {
            if (this.curIndex === index) {
                this.curIndex = null
            } else {
                this.curIndex = index
            }
        }
    }
}
</script>

<style lang="scss">
.clean-navigation-mobile {
    $parent: &;

    svg {
        cursor: pointer;
        float: right;

        path {
            fill: $token-colors-white;
        }
    }

    &__nav {
        top: 0;
        position: fixed;
        background: $token-colors-white;
        width: 100vw;
        height: 100vh;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: $token-spacers-xs;
    }

    &__top {
        padding: 20px 0;
        background: $token-colors-navy-blue;

        > .container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @include breakpoint (v-tablet) {
            padding: 6px 0;
        }

        @include breakpoint (mobile) {
            border-bottom: 4px solid $token-colors-border-hover;
            padding: 5px 0;
        }
    }

    nav:is(.container) {
        padding-top: $token-spacers-s;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding: 0 $token-spacers-2-xs 0;
        gap: $token-spacers-xs;
        flex: 1;
        overflow-y: auto;
    }

    &__link {
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: $token-colors-blue;
        text-decoration: none;
        cursor: pointer;

        &--active {
            color: $token-colors-blue-50;

            svg path {
                fill: $token-colors-blue-50;
            }
        }
    }

    &__title {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        > svg  {
            flex-shrink: 0;

            path {
                fill: $token-colors-blue;
            }
        }
    }

    &__sublist {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-2-xs;
        padding: $token-spacers-xs;
        border-bottom: 1px solid $token-colors-blue;
        width: 100%;
    }

    &__sublink {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $token-colors-blue;
        text-decoration: none;
        cursor: pointer;

        &--active {
            color: $token-colors-blue-50;
        }
    }
}
</style>
