<template>
    <div class="description">
        <div class="description__title">
            {{ title }}
        </div>
        <div v-html="value" class="description__value">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    },
    name: 'WordDescr'
}
</script>

<style lang="scss">
.description {
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.005em;
        margin-bottom: 8px;
    }

    &__value {
        font-size: 22px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }
}

</style>
