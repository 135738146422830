/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'decline': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.8 15h59.6v42h-2V25H15.8v7h-2V15zm57.6 2v6H15.8v-6h55.6zM13.8 50v23h59.6v-2H15.8V50h-2zm4.9-29h2v-2h-2v2zm6 0h-2v-2h2v2zm2 0h2v-2h-2v2zm41.7 0H30.7v-2h37.7v2zm4.7 45c0-.507.446-1 1.1-1 .654 0 1.1.493 1.1 1s-.446 1-1.1 1c-.654 0-1.1-.493-1.1-1zm1.1-3c-.5 0-.98.117-1.406.327L62.218 53.255c.18-.378.282-.802.282-1.255 0-1.702-1.434-3-3.1-3-1.666 0-3.1 1.298-3.1 3 0 .479.114.925.314 1.32l-4.208 4.007a3.178 3.178 0 00-2.751-.029l-10.566-9.983c.199-.394.311-.838.311-1.315 0-1.702-1.434-3-3.1-3-1.665 0-3.1 1.298-3.1 3 0 .453.101.877.282 1.255l-4.276 4.072a3.178 3.178 0 00-2.746-.032l-10.542-10.04c.18-.378.282-.802.282-1.255 0-1.702-1.434-3-3.1-3-1.665 0-3.1 1.298-3.1 3s1.434 3 3.1 3c.5 0 .98-.117 1.406-.327L25.014 52.68c-.2.395-.314.841-.314 1.32 0 1.702 1.434 3 3.1 3 1.665 0 3.1-1.298 3.1-3 0-.453-.101-.877-.282-1.255l4.276-4.072a3.178 3.178 0 002.751.029l10.566 9.983c-.199.394-.311.838-.311 1.315 0 1.702 1.434 3 3.1 3 1.666 0 3.1-1.298 3.1-3 0-.453-.102-.877-.282-1.255l4.242-4.04a3.178 3.178 0 002.746-.032l10.576 10.072c-.18.378-.282.802-.282 1.255 0 1.702 1.434 3 3.1 3 1.665 0 3.1-1.298 3.1-3s-1.434-3-3.1-3zM13.1 39c-.654 0-1.1.493-1.1 1s.446 1 1.1 1c.654 0 1.1-.493 1.1-1s-.446-1-1.1-1zm22.1 7c0-.507.446-1 1.1-1 .654 0 1.1.493 1.1 1s-.446 1-1.1 1c-.654 0-1.1-.493-1.1-1zm-7.4 7c-.654 0-1.1.493-1.1 1s.446 1 1.1 1c.654 0 1.1-.493 1.1-1s-.446-1-1.1-1zm22.1 7c0-.507.446-1 1.1-1 .654 0 1.1.493 1.1 1s-.446 1-1.1 1c-.654 0-1.1-.493-1.1-1zm9.5-9c-.654 0-1.1.493-1.1 1s.446 1 1.1 1c.654 0 1.1-.493 1.1-1s-.446-1-1.1-1zM20.8 30h-2v-2h2v2zm2 0h21v-2h-21v2zm-2 4h-2v-2h2v2zm2 0h21v-2h-21v2zm-2 4h-2v-2h2v2zm2 0h11v-2h-11v2z" _fill="#0077C8"/>'
  }
})
