<template>
    <div class="property-complexes">
        <property-complexes-filter class="u-bottom-margin--l" :filters-data="filtersData" @update-filters="filtersHandler"/>

        <download-excel-btn
        class="u-bottom-margin--s"
        urlSections="assets/property-complexes"
        :filters="filters"
        :disabled="!items.length"
        />

        <template v-if="items.length && !loading">
            <property-complexes-list :items="items"/>
            <z-pagination
                v-if="nav"
                :data="{
                    currentPage: nav.current,
                    totalPages: nav.total,
                    pageRange: 1
                }"
                @change-page="pageHandler"
            />
        </template>
        <template v-else-if="loading">
            <z-preloader v-if="loading"/>
        </template>
        <template v-else>
            <z-not-found/>
        </template>
    </div>
</template>

<script>
import PropertyComplexesFilter from '@/views/PropertyComplexes/components/PropertyComplexesFilter.vue'
import PropertyComplexesList from '@/views/PropertyComplexes/components/PropertyComplexesList.vue'
import {getPropertyComplexes} from '@/api/property-complexes'
import {debounce} from 'throttle-debounce'
import {showNotyfications} from '@/utils/notifications'

export default {
    name: 'PropertyComplexesPage',
    components: {
        PropertyComplexesFilter,
        PropertyComplexesList
    },
    data () {
        return {
            filtersData: this.$root.app?.components?.['property-complexes']?.filters || [],
            items: this.$root.app?.components?.['property-complexes']?.items || [],
            nav: this.$root.app?.components?.['property-complexes']?.nav || null,
            page: 1,
            isMore: false,
            loading: false,
            filters: null
        }
    },
    methods: {
        send: debounce(300, function (params) {
            this.loading = true
            getPropertyComplexes(params)
                .then((response) => {
                    this.filtersData = response.filters
                    this.nav = response.nav
                    this.page = this.nav.current

                    if (this.isMore) {
                        this.items = [...this.items, ...response.items]
                        this.isMore = false
                    } else {
                        this.items = response.items
                    }
                })
                .catch((err) => {
                    showNotyfications('error', err)
                })
                .finally(() => {
                    this.loading = false
                })
        }),
        filtersHandler (payload) {
            this.page = 1
            this.filters = payload
            this.send({
                ...this.filters,
                page: this.page
            })
        },
        pageHandler (payload) {
            this.isMore = payload.type === 'load-more'
            this.page = payload.page
            this.send({
                ...this.filters,
                page: this.page
            })
        }
    }
}
</script>

<style lang="scss">
.property-complexes {
    max-width: 1360px;
}
</style>
