<template>
<div class="not-found">
    <div class="not-found__img">
        <img src="/images/not-found.svg" alt="">
    </div>
    <div class="not-found__info">
        <z-caption
            size="m"
            uppercase
            decor-right
            decor-left
            class="u-top-margin--m u-bottom-margin--s"
            weight="600"
        >
            <span v-html="text.caption"></span>
        </z-caption>
        <p class="text-size-xl u-normal" v-html="text.text"></p>
    </div>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'not-found',
    data () {
        return {
            text: {
                caption: localize({
                    ru: 'Мероприятий не запланировано',
                    en: 'No events planned'
                }),
                text: localize({
                    ru: 'Обновление последует в ближайшее время.',
                    en: 'An update will follow shortly.'
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.not-found {
    display: flex;

    @include breakpoint (mobile) {
        display: block;
    }

    &__img {
        flex-shrink: 0;
        width: 23%;
        text-align: center;
        max-width: 260px;

        img {
            width: 100%;
        }

        @include breakpoint (mobile) {
            width: 100%;
            margin: 0 auto;
        }
    }

    &__info {
        @include padding-level(left, 2XL);

        @include breakpoint (mobile) {
            padding-left: 0;
        }
    }
}
</style>
