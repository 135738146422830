/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/usd': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31978)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" d="M61.3 82.216c-3.258 0-4.896-1.926-4.896-4.878v-7.344h1.494v7.308c0 2.196 1.188 3.582 3.402 3.582 2.214 0 3.402-1.386 3.402-3.582v-7.308h1.494v7.326c0 2.988-1.638 4.896-4.896 4.896zm13.395 0c-2.087 0-3.617-.792-4.607-1.908l.882-1.134c.791.882 2.088 1.71 3.78 1.71 2.141 0 2.826-1.152 2.826-2.07 0-3.096-7.11-1.386-7.11-5.67 0-1.98 1.764-3.33 4.122-3.33 1.836 0 3.258.612 4.266 1.674l-.9 1.098c-.9-.99-2.16-1.44-3.474-1.44-1.44 0-2.467.774-2.467 1.908 0 2.7 7.11 1.152 7.11 5.634 0 1.746-1.188 3.528-4.428 3.528zM87.258 82h-4.104V69.994h4.104c3.726 0 6.156 2.61 6.156 6.012 0 3.42-2.43 5.994-6.156 5.994zm0-1.332c2.916 0 4.608-2.088 4.608-4.662 0-2.592-1.638-4.68-4.608-4.68h-2.61v9.342h2.61z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31978" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31978"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31978" result="effect2_dropShadow_9226_31978"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31978" result="shape"/></filter></defs>'
  }
})
