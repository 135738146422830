/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-long-2': {
    width: 23,
    height: 47,
    viewBox: '0 0 23 47',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.68 5.176V47H9.65V5.176l-7.06 7.06a1.516 1.516 0 11-2.145-2.143L10.093.444a1.516 1.516 0 012.144 0l9.648 9.649a1.516 1.516 0 11-2.144 2.144l-7.06-7.06z" _fill="#fff"/>'
  }
})
