<template>
    <div class="spoiler">
        <div class="spoiler__container">
            <slot name="default"></slot>
            <slot v-if="open" name="hidden-content"></slot>
        </div>
        <z-button kind="secondary" @click="toggleOpenState()" class="spoiler__button">
            {{ open ? text.hide : text.show }}
        </z-button>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'spoiler',
    props: {
        isOpened: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            open: false,
            text: {
                show: localize({
                    ru: 'Загрузить еще',
                    en: 'More'
                }),
                hide: localize({
                    ru: 'Скрыть',
                    en: 'Hide'
                }),
            }
        }
    },
    mounted() {
        this.open = this.isOpened
    },
    methods: {
        toggleOpenState() {
            this.open = !this.open
        }
    },
}
</script>

<style lang="scss" scoped>
.spoiler {
    width: 100%;

    &__button {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        @include margin-level(top, XS);
    }
}
</style>
