<template>
    <div :class="['tl-img-bottom', {'tl-img-bottom--no-line' : !line}]">
        <tl-line
            :month="month"
            :second-month="secondMonth"
            :day="day"
        >
            <div class="tl-img-bottom__content">
                <div class="tl-img-bottom__title" v-html="title" v-if="title"></div>
                <slot></slot>
            </div>
            <tl-link :href="href" v-if="href" />
        </tl-line>
        <a
            v-if="image"
            :class="['tl-img-bottom__image', {'tl-img-bottom__video' : video}, {'tl-img-bottom__image--full' : full}]"
            :href="video ? video : null"
            target="_blank"
            :style="{ 'background-image': 'url(' + image + ')' }"
        ></a>
    </div>
</template>

<script>
import TlLink from './TlLink.vue'
import TlLine from './TlLine.vue'

export default {
    name: 'tl-img-bottom',
    components: {
        TlLink,
        TlLine
    },
    props: {
        image: String,
        title: String,
        href: String,
        month: String,
        secondMonth: String,
        day: String,
        video: String,
        line: {
            type: Boolean,
            default: true
        },
        full: Boolean
    }
}
</script>

<style lang="scss">
.tl-img-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 515px;
    height: 100%;
    justify-content: space-between;

    @include breakpoint (v-tablet) {
        max-width: 534px;
    }

    @include breakpoint (mobile) {
        max-width: 288px;
    }

    &__content {
        display: flex;
        gap: $token-spacers-m;
        flex-direction: column;
        width: 100%;
        max-width: 460px;

        @include breakpoint (v-tablet) {
            width: 100%;
            max-width: 100%;
            gap: $token-spacers-s;
        }

        @include breakpoint (mobile) {
            gap: $token-spacers-xs;
        }
    }

    &--no-line {
        .tl-line__pattern {
            display: none;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        color: $token-colors-black;

        @include breakpoint (v-tablet) {
            font-size: 20px;
        }
    }

    &__video {
        cursor: pointer;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 100px;
            height: 100px;
            background-image: url("data:image/svg+xml,%3Csvg width='101' height='100' viewBox='0 0 101 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.8' cx='50.0215' cy='50' r='50' fill='%23004C97'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M35.6074 28.6605C35.6074 27.0782 37.3579 26.1225 38.6889 26.9781L69.986 47.0977C71.2107 47.885 71.2107 49.6751 69.986 50.4624L38.6889 70.582C37.3579 71.4376 35.6074 70.4819 35.6074 68.8996V28.6605ZM68.9045 48.7801L37.6074 28.6605V68.8996L68.9045 48.7801Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -50px;
        }
    }

    &__image {
        width: 100%;
        padding-top: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 115%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50% 50% 0 0 / 100% 100% 0 0;
        overflow: hidden;
        margin-top: 61px;

        @include breakpoint (v-tablet) {
            width: 70%;
            padding-top: 35%;
            margin-top: $token-spacers-s;
        }

        @include breakpoint (mobile) {
            width: 100%;
            padding-top: 50%;
            margin-top: $token-spacers-xs;
        }

        &--full {
            padding-top: 100%;
            border-radius: 100%;

            @include breakpoint (v-tablet) {
                padding-top: 70%;
            }
            @include breakpoint (mobile) {
                padding-top: 100%;
            }
        }
    }
}
</style>
