<template>
    <div
        class="icon-block"
        :class="classObject"
    >
        <div class="icon-block__item">
            <div class="icon-block__icon">
                <img :src="iconUrl" v-if="iconUrl">
                <z-icon :name="iconName" :width="iconSize[size]" :height="iconSize[size]" v-else></z-icon>
            </div>
            <div class="icon-block__content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'icon-block',
    props: {
        iconUrl: String,
        iconName: String,
        centered: Boolean,
        columnItem: Boolean,
        size: {
            type: String,
            validator: prop => ['l', 'ml', 'm', 's', 'xs'].includes(prop),
            default: 'm'
        }
    },
    data () {
        return {
            iconSize: {
                s: '64'
            }
        }
    },
    computed: {
        classObject () {
            return {
                'icon-block--centered': this.centered,
                'icon-block--column-style': this.columnItem,
                [`icon-block--size-${this.size}`]: this.size
            }
        }
    }
}
</script>

<style lang="scss">
    .icon-block {
        & + & {
            @include margin-level(top, L);
        }

        &__item {
            display: flex;
            align-items: flex-start;
            position: relative;

            @include breakpoint (v-tablet) {
                flex-direction: column;
            }
        }

        &__icon {
            flex-shrink: 0;

            @include breakpoint (v-tablet) {
                margin-right: 0;
                @include margin-level(bottom, XS);
            }

            svg,
            img {
                width: 100%;
                height: auto;
            }
        }

        &__content {
            line-height: 1.3;
        }

        &--centered {
            .icon-block__item {
                align-items: center;
            }

            @include breakpoint (v-tablet) {
                .icon-block__icon {
                    margin-right: 0 !important;
                }
            }
        }

        &--size {
            &-xs {
                .icon-block__icon {
                    width: 32px;
                    height: 32px;
                    margin-right: $token-spacers-2-xs;
                }
            }
            &-s {
                .icon-block__icon {
                    width: 64px;
                    height: 64px;
                    margin-right: $token-spacers-xs;
                }
            }

            &-m {
                .icon-block__icon {
                    width: 90px;
                    margin-right: $token-spacers-s;
                }
            }

            &-ml {
                .icon-block__icon {
                    width: 132px;
                    margin-right: $token-spacers-2-s;
                }
            }

            &-l {
                .icon-block__icon {
                    width: 180px;
                    margin-right: $token-spacers-xl;
                }
            }
        }

        &--column-style {
            .icon-block__item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 414px;

                .icon-block__icon + .icon-block__content{
                    margin-top: $token-spacers-3-xs;
                }
            }
        }
    }
</style>
