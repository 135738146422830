/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/xbrl': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM17.4 30h1.4l-2.49-3.43 2.34-3.24h-1.41l-1.71 2.45-1.72-2.45h-1.4l2.35 3.25-2.5 3.42h1.4l1.87-2.61L17.4 30zm2.15 0h3.37c1.25 0 1.94-.77 1.94-1.8 0-.82-.58-1.53-1.29-1.64.62-.13 1.16-.69 1.16-1.53 0-.94-.68-1.7-1.9-1.7h-3.28V30zm3.98-4.77c0 .5-.34.86-.94.86h-1.87v-1.73h1.87c.6 0 .94.37.94.87zm.13 2.81c0 .58-.38.93-1.02.93h-1.92v-1.85h1.92c.67 0 1.02.42 1.02.92zM29.846 30h1.35l-1.63-2.62c.8-.13 1.58-.77 1.58-1.96 0-1.23-.86-2.09-2.18-2.09h-2.93V30h1.17v-2.49h1.16l1.48 2.49zm.1-4.58c0 .64-.48 1.06-1.14 1.06h-1.6v-2.12h1.6c.66 0 1.14.42 1.14 1.06zm2.37 4.58h4.11v-1.03h-2.94v-5.64h-1.17V30z" _fill="#737B91"/>'
  }
})
