/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.865 3.485C5.8 2.801 4.4 3.565 4.4 4.831v14.337c0 1.266 1.4 2.03 2.465 1.346l11.15-7.169a1.6 1.6 0 000-2.691L6.866 3.485zM5.6 4.831a.4.4 0 01.616-.336l11.15 7.168a.4.4 0 010 .673l-11.15 7.168a.4.4 0 01-.616-.336V4.83z" _fill="#0077C8"/>'
  }
})
