/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vk': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M14.697 18.364V9.166h2.545v5.593l.554-.06c1.249-.137 2.464-.917 3.448-1.973a9.726 9.726 0 002.091-3.56h2.544a10.273 10.273 0 01-1.48 3.218 10.056 10.056 0 01-2.977 2.836l-.757.468.794.403c1.311.664 2.47 1.605 3.4 2.76.792.986 1.403 2.11 1.803 3.315h-2.86a7.43 7.43 0 00-2.145-3.253 7.226 7.226 0 00-3.852-1.746l-.563-.072v5.071c-3.597-.017-6.375-1.28-8.287-3.543-1.86-2.201-2.945-5.4-3.107-9.457h2.765c.256 6.209 3.027 9.062 5.46 9.682l.623.16v-.644z" _fill="#0077C8" _stroke="#0077C8"/>'
  }
})
