<template>
    <div class="slides-screen">
        <div class="slides-screen__container content-container">
            <div class="slides-screen__slide">
                <transition-fade>
                    <template v-if="checkIsActive(0)">
                        <div key="0" class="slide slide-cover">
                            <z-caption
                                size="4xl"
                                class="slide-cover__title">
                                Основы противодействия коррупции<br>для&nbsp;Контрагентов<br>Группы компаний &laquo;Норильский никель&raquo;
                            </z-caption>
                        </div>
                    </template>
                    <template v-if="checkIsActive(1)">
                        <div key="1" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-1.jpg"
                                    alt="" />
                                <div class="slide__img-text u-align-center">
                                    Норникель не&nbsp;приемлет
                                    <span class="u-bold">
                                        коррупцию
                                    </span>
                                    в&nbsp;любых формах и&nbsp;проявлениях
                                </div>
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Норникель и&nbsp;Контрагенты
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-1.jpg"
                                        alt="" />
                                    <div class="slide__img-text u-align-center">
                                        Норникель не&nbsp;приемлет
                                        <span class="u-bold">
                                            коррупцию
                                        </span>
                                        в&nbsp;любых формах и&nbsp;проявлениях
                                    </div>
                                </div>
                                <z-list>
                                    <z-list-item>Наша Компания устанавливает <span class="u-bold">высокие требования в&nbsp;отношении</span> прозрачного, этичного, ответственного <span class="u-bold">ведения бизнеса</span>, твердо привержена принципам честной конкуренции и&nbsp;открытости рынков, а&nbsp;также стремится обеспечить равные возможности всем потенциальным Контрагентам.</z-list-item>
                                    <z-list-item>Мы&nbsp;уверены, что такой подход <span class="u-bold">благоприятно влияет</span> на&nbsp;развитие доверительных отношений с&nbsp;инвесторами, партнерами, работниками и&nbsp;другими заинтересованными сторонами, а&nbsp;также <span class="u-bold">способствует улучшению социальной и&nbsp;экономической среды</span>, в&nbsp;которой работает Компания.</z-list-item>
                                    <z-list-item>Контрагенты, с&nbsp;которыми мы&nbsp;работаем, должны <span class="u-bold">разделять наши ценности</span>, следовать нашим или собственным равнозначным правилам поведения.</z-list-item>
                                </z-list>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(2)">
                        <div key="2" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-2.jpg"
                                    alt="" />
                                <div class="slide__img-text u-align-center">
                                    Вступая в&nbsp;партнерские взаимоотношения, Норникель ожидает, что у&nbsp;Контрагентов также будет внедрена <span class="u-bold">система</span>, направленная на&nbsp;<span class="u-bold">противодействие коррупции</span>
                                </div>
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Антикоррупционный комплаенс
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-2.jpg"
                                        alt="" />
                                    <div class="slide__img-text u-align-center">
                                        Вступая в&nbsp;партнерские взаимоотношения, Норникель ожидает, что у&nbsp;Контрагентов также будет внедрена <span class="u-bold">система</span>, направленная на&nbsp;<span class="u-bold">противодействие коррупции</span>
                                    </div>
                                </div>
                                <z-list>
                                    <z-list-item>Соблюдение применимого законодательства.</z-list-item>
                                    <z-list-item>Соблюдение нормативно&nbsp;&mdash; методических документов и&nbsp;процедур Норникеля.</z-list-item>
                                    <z-list-item>Соблюдение этических норм и&nbsp;правил делового поведения.</z-list-item>
                                </z-list>
                                <div class="u-top-margin--m">
                                    <p><span class="u-bold">В&nbsp;целях исключения и&nbsp;минимизации неэтичных и&nbsp;коррупционных практик</span>, в&nbsp;Компании разработана и&nbsp;внедрена система мероприятий, направленная на&nbsp;обеспечение соблюдения требований применимого антикоррупционного законодательства&nbsp;&mdash; <span class="u-bold">антикоррупционный комплаенс</span>.</p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(3)">
                        <div key="3" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-3.jpg"
                                    alt="" />
                                <div class="slide__img-text u-align-center">
                                    <span class="u-bold">Закон обязывает</span> организации принимать <span class="u-bold">меры по&nbsp;противодействию коррупции</span>
                                </div>
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Законодательное регулирование
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-3.jpg"
                                        alt="" />
                                    <div class="slide__img-text u-align-center">
                                        <span class="u-bold">Закон обязывает</span> организации принимать <span class="u-bold">меры по&nbsp;противодействию коррупции</span>
                                    </div>
                                </div>
                                <p>Норникель соблюдает <span class="u-bold">антикоррупционное законодательство</span>:</p>
                                <z-list>
                                    <z-list-item>Российской Федерации.</z-list-item>
                                    <z-list-item>Иностранных государств, на&nbsp;территории которых ведет деятельность.</z-list-item>
                                    <z-list-item>Зарубежное законодательство, имеющее экстерриториальное действие.</z-list-item>
                                </z-list>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(4)">
                        <div key="4" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-4.jpg"
                                    alt="" />
                                <div class="slide__img-text u-align-center">
                                    Ознакомиться с&nbsp;содержанием документов, регулирующих антикоррупционную деятельность в&nbsp;Компании, можно в&nbsp;разделе <span class="u-bold">&laquo;Анктикоррупция&raquo;</span> <z-link href="/company/governance/anti-corruption/" theme="pseudo" target="_blank">на&nbsp;официальном сайте</z-link>
                                </div>
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Требования внутренних документов Норникеля
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-4.jpg"
                                        alt="" />
                                    <div class="slide__img-text u-align-center">
                                        Ознакомиться с&nbsp;содержанием документов, регулирующих антикоррупционную деятельность в&nbsp;Компании, можно в&nbsp;разделе <span class="u-bold">&laquo;Анктикоррупция&raquo;</span> <z-link href="/company/governance/anti-corruption/" theme="pseudo" target="_blank">на&nbsp;официальном сайте</z-link>
                                    </div>
                                </div>
                                <z-list>
                                    <z-list-item>Компания ожидает от&nbsp;своих Контрагентов, третьих лиц (подрядчиков, агентов, представителей, посредников, поставщиков, дилеров и&nbsp;т.д.), действующих от&nbsp;имени или в&nbsp;интересах Норникеля, следования фундаментальным принципам этичного, ответственного ведения бизнеса, изложенных в&nbsp;Политике в&nbsp;области антикоррупционной деятельности и&nbsp;<z-link href="/upload/iblock/dd5/jcjiudt35559lwaorpm0hb4srigml9a7/kodeks_korporativnogo_povedeniya_postavshchika.pdf" target="_blank">Кодексе корпоративного поведения поставщика ПАО &laquo;ГМК &laquo;Норильский никель&raquo;</z-link>.</z-list-item>
                                    <z-list-item>В&nbsp;целях исключения вовлечения в&nbsp;коррупционную деятельность, Компания также ожидает, что Контрагент обеспечит соблюдение основополагающих принципов Политики в&nbsp;области антикоррупционной деятельности и&nbsp;Кодекса корпоративного поведения поставщика ПАО &laquo;ГМК &laquo;Норильский никель&raquo; своими дочерними обществами, работниками, менеджментом, подрядчиками, посредниками, агентами, поставщиками и&nbsp;иными лицами, привлекаемыми для исполнения обязательств перед Норникелем.</z-list-item>
                                </z-list>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(5)">
                        <div key="5" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-5.jpg"
                                    alt="" />
                                <div class="slide__img-text u-align-center">
                                    Основные положения Политики регулируют
                                    <span class="u-bold">
                                        вопросы противодействия коррупции и&nbsp;управления
                                        коррупционными рисками
                                    </span>
                                    в&nbsp;Компании
                                </div>
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Политика в&nbsp;области антикоррупционной деятельности
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-5.jpg"
                                        alt="" />
                                    <div class="slide__img-text u-align-center">
                                        Основные положения Политики регулируют
                                        <span class="u-bold">
                                            вопросы противодействия коррупции и&nbsp;управления
                                            коррупционными рисками
                                        </span>
                                        в&nbsp;Компании
                                    </div>
                                </div>
                                <z-caption
                                    weight="600"
                                    tag="p"
                                    size="xl">
                                    Обязанность Контрагента:
                                </z-caption>
                                <z-list>
                                    <z-list-item>Соблюдать требования законодательства.</z-list-item>
                                    <z-list-item>Избегать ситуаций конфликта интересов.</z-list-item>
                                    <z-list-item>Соблюдать правила обмена деловыми подарками.</z-list-item>
                                    <z-list-item>Сообщать о&nbsp;нарушениях.</z-list-item>
                                </z-list>
                                <div class="slide__warning">
                                    <z-icon
                                        name="faq-attention"
                                        width="40"
                                        height="40"></z-icon>
                                    <div class="slide__warning-text">
                                        <span>
                                            В&nbsp;случае нарушения требований законодательства,
                                            а&nbsp;также несоблюдения основополагающих принципов
                                            Политики, Компания вправе рассматривать это как отсутствие
                                            приверженности добросовестной работе с&nbsp;Норникелем,
                                            и&nbsp;будет действовать соответственно.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(6)">
                        <div key="6" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-6.jpg"
                                    alt="" />
                                <div class="slide__img-text u-align-center">
                                    <span class="u-bold">Наличие процедур</span>, направленных на&nbsp;противодействие коррупции, <span class="u-bold">может минимизировать риски привлечения к&nbsp;ответственности</span>
                                </div>
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Ответственность за&nbsp;коррупционные действия
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-6.jpg"
                                        alt="" />
                                    <div class="slide__img-text u-align-center">
                                        <span class="u-bold">Наличие процедур</span>, направленных на&nbsp;противодействие коррупции, <span class="u-bold">может минимизировать риски привлечения к&nbsp;ответственности</span>
                                    </div>
                                </div>
                                <z-list>
                                    <z-list-item>В&nbsp;случае, если от&nbsp;имени или в&nbsp;интересах организации осуществляются действия, направленные на&nbsp;совершение коррупционных нарушений, а&nbsp;также создаются условия для совершения таких нарушений, к&nbsp;организации могут быть применены меры ответственности в&nbsp;соответствии с&nbsp;законодательством Российской Федерации.</z-list-item>
                                </z-list>
                                <div class="slide__warning">
                                    <z-icon
                                        name="faq-attention"
                                        width="40"
                                        height="40"></z-icon>
                                    <div class="slide__warning-text">
                                        <span>
                                            Перед законом отвечают все участники передачи незаконного вознаграждения, в&nbsp;том числе лицо, которое передает его, и&nbsp;организация, от&nbsp;чьего имени вознаграждение передается. Если взятка передается через посредника, то&nbsp;он&nbsp;подлежит уголовной ответственности.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(7)">
                        <div key="7" class="slide">
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Антикоррупционная оговорка&nbsp;&mdash;
                                    </span>
                                </z-caption>
                                <div class="slide__img-text u-bottom-margin--s">
                                    <span class="u-bold">Раздел или приложение</span> к&nbsp;договору Компании, в&nbsp;которых стороны договариваются <span class="u-bold">о&nbsp;недопущении коррупционных действий</span> при&nbsp;исполнении обязательств по&nbsp;договору и&nbsp;<span class="u-bold">устанавливают ответственность за&nbsp;нарушения</span>.
                                </div>
                                <p>Компания включает Антикоррупционную оговорку во&nbsp;все договоры с&nbsp;Контрагентами.</p>
                                <div class="row">
                                    <div class="col-default-6 col-v-tablet-12">
                                        <z-caption
                                            weight="600"
                                            tag="p"
                                            size="xl">
                                            Обязательства:
                                        </z-caption>
                                        <z-list>
                                            <z-list-item>Не&nbsp;нарушать требования антикоррупционного законодательства.</z-list-item>
                                            <z-list-item>Не&nbsp;допускать нарушение антикоррупционного законодательства третьими лицами (подрядчиками, агентами, представителями, посредниками, поставщиками, дилерами и&nbsp;т.д.), привлеченных в&nbsp;целях исполнения договора.</z-list-item>
                                            <z-list-item>Сообщать о&nbsp;возможных коррупционных нарушениях и&nbsp;оказывать содействие в&nbsp;их&nbsp;расследовании.</z-list-item>
                                        </z-list>
                                    </div>
                                    <div class="col-default-6 col-v-tablet-12">
                                        <z-caption
                                            weight="600"
                                            tag="p"
                                            size="xl">
                                            Последствия нарушений:
                                        </z-caption>
                                        <z-list>
                                            <z-list-item>Односторонний отказ от&nbsp;исполнения договора.</z-list-item>
                                            <z-list-item>Возмещение убытков, в&nbsp;случае нарушения требований антикоррупционной оговорки.</z-list-item>
                                        </z-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(8)">
                        <div key="8" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-8.jpg"
                                    alt="" />
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Комплексная проверка Контрагента
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-8.jpg"
                                        alt="" />
                                </div>
                                <z-list>
                                    <z-list-item>До&nbsp;начала сотрудничества Контрагенты должны пройти комплексную проверку, позволяющую в&nbsp;том числе оценить, насколько законно, этично и&nbsp;добросовестно ведется бизнес,</z-list-item>
                                    <z-list-item>Компания ожидает, что Контрагент будет оказывать содействие при прохождении проверки и&nbsp;представлять достоверную информацию,</z-list-item>
                                    <z-list-item>Компания ожидает, что Контрагент при выборе третьего лица для исполнения обязательств по&nbsp;договору или для совершения иных действий в&nbsp;интересах Компании будет руководствоваться добросовестными деловыми практиками и&nbsp;своевременно информировать о&nbsp;своем выборе Компанию.</z-list-item>
                                </z-list>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(9)">
                        <div key="9" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-9.jpg"
                                    alt="" />
                                <div class="slide__img-text u-align-center">
                                    Подарки и&nbsp;расходы на&nbsp;деловое гостеприимство <span class="u-bold">исключены в&nbsp;период переговоров</span> по&nbsp;заключению договора, тендеров и&nbsp;принятия определенного решения о&nbsp;сделке
                                </div>
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Обмен деловыми подарками
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-9.jpg"
                                        alt="" />
                                    <div class="slide__img-text u-align-center">
                                        Подарки и&nbsp;расходы на&nbsp;деловое гостеприимство <span class="u-bold">исключены в&nbsp;период переговоров</span> по&nbsp;заключению договора, тендеров и&nbsp;принятия определенного решения о&nbsp;сделке
                                    </div>
                                </div>
                                <p><span class="u-bold">Подарки и&nbsp;представительские расходы</span>, в&nbsp;том числе на&nbsp;деловое гостеприимство от&nbsp;Контрагентов работникам или представителям Норникеля, должны:</p>
                                <z-list>
                                    <z-list-item>быть прямо связаны с&nbsp;деятельностью Компании,</z-list-item>
                                    <z-list-item>быть разумно обоснованными, соразмерными, не&nbsp;являться предметами роскоши,</z-list-item>
                                    <z-list-item>не&nbsp;создавать репутационного риска для Компании,</z-list-item>
                                    <z-list-item>не&nbsp;должны представлять собой скрытое вознаграждение за&nbsp;какую-либо услугу или попытку оказать влияние на&nbsp;получателя с&nbsp;иной незаконной или неэтичной целью.</z-list-item>
                                </z-list>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(10)">
                        <div key="10" class="slide">
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Управление конфликтом интересов
                                    </span>
                                </z-caption>
                                <z-caption
                                    weight="600"
                                    tag="p"
                                    size="xl">
                                    Конфликт интересов&nbsp;&mdash;
                                </z-caption>
                                <div class="slide__img-text">
                                    любая ситуация, когда личная заинтересованность влияет или может повлиять на&nbsp;надлежащее исполнение трудовых обязанностей в&nbsp;Компании, а&nbsp;именно, <span class="u-bold">когда появляется возможность получения работником Компании или его связанными лицами</span> (членами семьи, в&nbsp;том числе лицами, находящихся с&nbsp;ним в&nbsp;отношениях близкого родства или свойства, физическими лицами, с&nbsp;которыми работник связан имущественными, корпоративными или иными близкими отношениями) <span class="u-bold">выгод в&nbsp;виде привилегий или доходов</span>, в&nbsp;том числе денег, иного имущества.
                                </div>
                                <z-caption
                                    weight="600"
                                    tag="p"
                                    size="xl"
                                    class="u-top-margin--m">
                                    Примеры ситуаций конфликта интересов:
                                </z-caption>
                                <div class="row">
                                    <div class="col-default-4 col-tablet-12">
                                        <p class="u-light">Работник Норникеля принимает решение об&nbsp;инвестировании средств, принадлежащих Норникелю. Потенциальным объектом инвестиций является организация&nbsp;Х, принадлежащая супруге Работника.</p>
                                    </div>
                                    <div class="col-default-4 col-tablet-12">
                                        <p class="u-light">Работник Норникеля влияет&nbsp;и (или) может повлиять на&nbsp;принятие решения о&nbsp;закупке в&nbsp;пользу организации&nbsp;Х, принадлежащей родственнику Работника.</p>
                                    </div>
                                    <div class="col-default-4 col-tablet-12">
                                        <p class="u-light">Работник Норникеля, в&nbsp;должностные обязанности которого входит контроль качества товаров и&nbsp;услуг, предоставляемых Норникелю Контрагентами, получает значительную скидку на&nbsp;товары организации, которая является поставщиком Норникеля.</p>
                                    </div>
                                </div>
                                <div class="slide__warning">
                                    <z-icon
                                        name="faq-attention"
                                        width="40"
                                        height="40"></z-icon>
                                    <div class="slide__warning-text">
                                        <span>
                                            Компания ожидает, что Контрагенты будут избегать ситуаций конфликта интересов и&nbsp;информировать, если какой-либо работник Группы компаний Норникель имеет личную заинтересованность, мешающую ему действовать в&nbsp;интересах Группы компаний Норникель честно, добросовестно и&nbsp;непредвзято исполнять свои обязанности по&nbsp;отношению к&nbsp;Контрагенту, или имеет какие-либо экономические связи с&nbsp;Контрагентом.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(11)">
                        <div key="11" class="slide">
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Коммуникации
                                    </span>
                                </z-caption>
                                <z-caption
                                    weight="600"
                                    tag="p"
                                    size="xl">
                                    Деловая переписка:
                                </z-caption>
                                <z-list>
                                    <z-list-item>может быть использована как доказательство в&nbsp;суде,</z-list-item>
                                    <z-list-item>не&nbsp;должна содержать утверждений, выражений, которые могут толковаться неоднозначно, а&nbsp;также намеков, которые могут быть истолкованы как намерение или предложение предоставить или получить незаконный платеж или привилегию.</z-list-item>
                                </z-list>
                                <z-caption
                                    weight="600"
                                    tag="p"
                                    size="xl"
                                    class="u-top-margin--m">
                                    Примеры нежелательных переписок:
                                </z-caption>
                                <div class="row">
                                    <div class="col-default-4 col-tablet-12">
                                        <div class="slide__img-text u-bottom-margin--2xs">
                                            E-mail от&nbsp;Контрагента в&nbsp;адрес работника Компании
                                        </div>
                                        <p class="u-light">&laquo;Прошу покрыть расходы на&nbsp;сумму 100&nbsp;тыс. руб.&nbsp;за&nbsp;покупку подарка чиновнику&nbsp;ХХ за&nbsp;его содействие в&nbsp;получении лицензии&raquo;.</p>
                                    </div>
                                    <div class="col-default-4 col-tablet-12">
                                        <div class="slide__img-text u-bottom-margin--2xs">
                                            E-mail от&nbsp;работника Компании в&nbsp;адрес Контрагента
                                        </div>
                                        <p class="u-light">&laquo;Спасибо за&nbsp;приглашение на&nbsp;форум! Хотел&nbsp;бы уточнить, возможно&nbsp;ли получить с&nbsp;вашей стороны дополнительное место в&nbsp;поездке для моей супруги? Исключительно в&nbsp;целях дальнейшего сотрудничества&raquo;.</p>
                                    </div>
                                    <div class="col-default-4 col-tablet-12">
                                        <div class="slide__img-text u-bottom-margin--2xs">
                                            E-mail от&nbsp;Контрагента в&nbsp;адрес работника Компании
                                        </div>
                                        <p class="u-light">&laquo;Привет! Я&nbsp;жду решение по&nbsp;выделению средств на&nbsp;благотворительный проект. Мы&nbsp;с&nbsp;тобой его уже обсуждали и&nbsp;твои компенсации в&nbsp;том числе&raquo;.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(12)">
                        <div key="12" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-11.png"
                                    alt="" />
                                <div class="slide__img-text u-align-center">
                                    В&nbsp;Компании внедрена и&nbsp;действует система для сообщений о&nbsp;нарушениях&nbsp;&mdash; <span class="u-bold">служба корпоративного доверия</span>
                                </div>
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Сообщения о&nbsp;нарушениях
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-11.png"
                                        alt="" />
                                    <div class="slide__img-text u-align-center">
                                    В&nbsp;Компании внедрена и&nbsp;действует система для сообщений о&nbsp;нарушениях&nbsp;&mdash; <span class="u-bold">служба корпоративного доверия</span>
                                    </div>
                                </div>
                                <p>Контрагент и&nbsp;иные третьи лица <span class="u-bold">могут сообщать о&nbsp;нарушениях</span>, воспользовавшись любым из&nbsp;следующих доступных способов:</p>
                                <z-list>
                                    <z-list-item>по&nbsp;телефонам <z-link href="tel:+78007001941">+7 (800) 700-19-41</z-link> или <z-link href="tel:+78007001945">+7 (800) 700-19-45</z-link></z-list-item>
                                    <z-list-item>по&nbsp;электронной почте <z-link href="mailto:skd@nornik.ru">skd@nornik.ru</z-link></z-list-item>
                                    <z-list-item>заполнив форму <z-link href="/sustainability/corporate-hotline/#contacts" target="_blank">на&nbsp;сайте Компании</z-link></z-list-item>
                                </z-list>
                                <div class="u-top-margin--m">
                                    <icon-block
                                        icon-name="check"
                                        size="s"
                                        centered
                                    >
                                        <p>Прием сообщений осуществляется круглосуточно: в&nbsp;рабочее время с&nbsp;09.00 до&nbsp;18.00 по&nbsp;Московскому времени в&nbsp;режиме разговора с&nbsp;оператором СКД, в&nbsp;остальное время сообщение записывается в&nbsp;режиме автоответа.</p>
                                    </icon-block>
                                    <icon-block
                                        icon-name="check"
                                        size="s"
                                        centered
                                    >
                                        <p>Все сообщения рассматриваются конфиденциально, своевременно и&nbsp;объективно, обеспечивается анонимность заявителя.</p>
                                    </icon-block>
                                    <icon-block
                                        icon-name="check"
                                        size="s"
                                        centered
                                    >
                                        <p>Следует учесть, что наличие контактов для обратной связи дает возможность Компании выяснить необходимые детали во&nbsp;время расследования и&nbsp;быстрее предоставить обратную связь обратившемуся лицу.</p>
                                    </icon-block>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="checkIsActive(13)">
                        <div key="13" class="slide">
                            <div class="slide__img-container slide__img-container--desktop">
                                <img
                                    class="slide__img"
                                    src="/images/suppliers/presentation/slide-13.jpg"
                                    alt="" />
                            </div>
                            <div class="slide__content">
                                <z-caption
                                    size="4xl"
                                    tag="h1"
                                    class="slide__title">
                                    <span>
                                        Благодарим
                                    </span>
                                </z-caption>
                                <div class="slide__img-container slide__img-container--mobile">
                                    <img
                                        class="slide__img"
                                        src="/images/suppliers/presentation/slide-13.jpg"
                                        alt="" />
                                </div>
                                <div class="text-size-xl">
                                    За&nbsp;соблюдение антикоррупционных принципов и&nbsp;требований Группы компаний &laquo;Норильский никель&raquo;
                                </div>
                            </div>
                        </div>
                    </template>
                </transition-fade>
                <div class="slides-screen__counter" v-if="currentSlideIndex!=0">
                    <span>{{ currentSlideIndex + 1 }}/</span>
                    <span class="u-normal">{{ slidesCount }}</span>
                </div>
            </div>
        </div>
        <div class="slides-screen__controls">
            <slot name="controls"></slot>
        </div>
    </div>
</template>

<script>
import TransitionFade from './TransitionFade.vue'

export default {
    name: 'slides-screen',
    components: {
        TransitionFade
    },
    props: {
        currentSlideIndex: {
            type: Number,
            required: true
        },
        slidesCount: {
            type: Number,
            required: true
        }
    },
    data () {
        return {}
    },
    methods: {
        checkIsActive (idx) {
            return idx === this.currentSlideIndex
        }
    }
}
</script>

<style lang="scss">
.slides-screen {
    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }
    &__container {
        @include margin-level(bottom, M);
        position: relative;
        overflow: hidden;
    }

    &__counter {
        position: absolute;
        top: 48px;
        right: 80px;
        color: $token-colors-blue;
        line-height: 130%;
        @include typo-level(3XL);
        display: flex;
        align-items: baseline;

        @include breakpoint(tablet) {
            right: 64px;
        }
        @include breakpoint(mobile) {
            right: 20px;
        }
    }

    &__slide {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .slide {
        display: flex;
        width: 100%;

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }

        &__content {
            @include breakpoint(v-tablet) {
                @include margin-level(top, XL);
            }
        }

        &__img-container {
            flex: 1;
            @include margin-level(right, XL);
            max-width: 345px;

            @include breakpoint(v-tablet) {
                margin-right: 0;
                @include margin-level(bottom, XL);
                max-width: none;
            }

            &--desktop {
                display: block;

                @include breakpoint(v-tablet) {
                    display: none;
                }
            }

            &--mobile {
                display: none;

                @include breakpoint(v-tablet) {
                    display: block;
                }
            }
        }

        &__img {
            width: 345px;
            height: 345px;
            @include margin-level(bottom, XS);
            object-fit: cover;

            @include breakpoint(mobile) {
                width: 100%;
                height: 230px;
            }
        }

        &__img-text {
            color: $token-colors-blue;
            @include typo-level(XL);
            font-weight: normal;
            line-height: 140%;
            letter-spacing: 0.22px;
        }

        &__title {
            @include margin-level(bottom, S);
            padding-right: 210px;
            width: 100%;
            display: inline-block;

            @include breakpoint(tablet) {
                padding-right: 90px;
            }
            @include breakpoint(v-tablet) {
                @include padding-level(right, XL);
            }
        }

        &__warning {
            gap: 16px;
            @include margin-level(top, M);
            @include padding-level(top, XS);
            @include padding-level(bottom, XS);
            @include padding-level(left, S);
            @include padding-level(right, S);
            border-radius: 20px 20px 20px 0px;
            border: 1px solid #e7ab3b;
            display: flex;

            svg path {
                fill: #e7ab3b;
            }
        }
        &__warning-text {
            position: relative;
            top: 7px;
        }
    }

    .slide-cover {
        background-image: url('/images/suppliers/presentation/cover-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        margin: -48px -80px;
        width: auto;
        justify-content: center;
        height: 676px;

        @include breakpoint(v-tablet) {
            margin: -40px -64px;
            padding-left: 24px;
            padding-right: 24px;
        }

        @include breakpoint(mobile) {
            margin: -24px -16px;
            padding-left: 16px;
            padding-right: 16px;
        }

        &__title {
            margin-bottom: 0;
            margin-left: 64px;
            color: #214A72 !important;
            @include breakpoint(v-tablet) {
                margin-left: 24px;
            }
        }
    }
}
</style>
