<template>
    <div class="pseudo-slider">
        <div class="container">
            <div class="pseudo-slider__header">
                <button class="pseudo-slider__control pseudo-slider__control--left" :disabled="!leftControl"
                        @click="changeId(leftControl.id)">
                    <span v-if="leftControl">{{ leftControl.name }}</span>
                    <z-icon name="arrow-long-3" dir="left" width="64" height="64"/>
                </button>
                <button class="pseudo-slider__control pseudo-slider__control--right" :disabled="!rightControl"
                        @click="changeId(rightControl.id)">
                    <span v-if="rightControl">{{ rightControl.name }}</span>
                    <z-icon name="arrow-long-3" dir="right" width="64" height="64"/>
                </button>
            </div>
            <transition-group
                tag="div"
                name="pseudo-slider"
                class="pseudo-slider__content"
                mode="out-in"
            >
                <pseudo-slider-item v-if="id.value === 'one'" current-id="one" key="one">
                    <template #title>
                        Аварийный пятиэтажный дом
                    </template>
                    <template #main>
                        <before-after
                            after-image="/images/before-after/emergency-building-after.jpg"
                            before-image="/images/before-after/emergency-building-before.jpg"
                            key="one"
                        />
                    </template>
                    <template #aside>
                        <z-key-indicator
                            size="l"
                            theme="white"
                            value="29&nbsp;000"
                            value-text="м<sup>3</sup>"
                            name="объем демонтированного здания"
                        />
                        <div class="pseudo-slider-item-card__slider">
                            <simple-slider theme="white" controls-position="center">
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon class="pseudo-slider-item-card__icon" width="44" height="44" name="cn/garbage_car_mob"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        На&nbsp;объекте задействованы специальные экскаваторы с&nbsp;длинной стрелой для демонтажа высотных сооружений, а&nbsp;также специализированные погрузчики и&nbsp;самосвалы для вывоза отходов.
                                    </p>
                                </slide>
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon width="44" height="44" name="leader"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        Подрядные организации перенесли за&nbsp;пределы производственной зоны электрический кабель, который питает обсерватор.
                                    </p>
                                </slide>
                            </simple-slider>
                        </div>
                    </template>
                    <template #bottom>
                        <z-button kind="white" tag="a" href="/clean-norilsk/objects/emergency-building/">
                            Подробнее
                            <template v-slot:right>
                                <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                            </template>
                        </z-button>
                        <z-button class="hide-me--default show-me--tablet" tag="a" href="/clean-norilsk/objects/">
                            Все объекты
                        </z-button>
                    </template>
                </pseudo-slider-item>
                <pseudo-slider-item v-if="id.value === 'two'" current-id="two" key="two">
                    <template #title>
                        Бывшая база «Норильскснаба»
                    </template>
                    <template #main>
                        <before-after class="u-bottom-margin--l" after-image="/images/before-after/former-base-after.jpg" before-image="/images/before-after/former-base-before.jpg" key="two"/>
                    </template>
                    <template #aside>
                        <z-key-indicator
                            size="l"
                            theme="white"
                            value="8"
                            value-text="га"
                            name="полностью расчищена территория"
                        />
                        <div class="pseudo-slider-item-card__slider">
                            <simple-slider theme="white">
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon width="44" height="44" name="leader"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        Расчищение территории происходило в&nbsp;несколько этапов: после расчистки одного участка, на&nbsp;нем тут&nbsp;же начинали работу строители.
                                    </p>
                                </slide>
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon class="pseudo-slider-item-card__icon" width="44" height="44" name="cn/garbage_car_mob"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        Для соблюдения сроков к&nbsp;саночистке были привлечены также внешние подрядные организации со&nbsp;своей техникой и&nbsp;людьми.
                                    </p>
                                </slide>
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon width="44" height="44" name="workers"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        В&nbsp;сутки во&nbsp;всех работах было задействовано около 80&nbsp;человек на&nbsp;всех объектах с&nbsp;учетом подрядчиков.
                                    </p>
                                </slide>
                            </simple-slider>
                        </div>
                    </template>
                    <template #bottom>
                        <z-button kind="white" tag="a" href="/clean-norilsk/objects/former-base/">
                            Подробнее
                            <template v-slot:right>
                                <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                            </template>
                        </z-button>
                        <z-button class="hide-me--default show-me--tablet" tag="a" href="/clean-norilsk/objects/">
                            Все объекты
                        </z-button>
                    </template>
                </pseudo-slider-item>
                <pseudo-slider-item v-if="id.value === 'three'" current-id="three" key="three">
                    <template #title>
                        Депо «Север»
                    </template>
                    <template #main>
                        <before-after after-image="/images/before-after/depot-north-after.jpg"
                                      before-image="/images/before-after/depot-north-before.jpg" key="three"/>
                    </template>
                    <template #aside>
                        <z-key-indicator
                            size="l"
                            theme="white"
                            value="32&nbsp;000"
                            value-text="м<sup>3</sup>"
                            name="объем демонтированного здания"
                        />
                        <div class="pseudo-slider-item-card__slider">
                            <simple-slider theme="white">
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon class="pseudo-slider-item-card__icon" width="44" height="44" name="cn/garbage_car_mob"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        На&nbsp;объекте работали универсальные тяжелые экскаваторы со&nbsp;сменным навесным оборудованием: гидроножницами, разрушителем бетона со&nbsp;специальными зубьями и&nbsp;грейфером.
                                    </p>
                                </slide>
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon width="44" height="44" name="workers"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        Для переработки металлолома была задействована бригада газорезчиков. Задача по&nbsp;резке металла для вывоза усложнялась большим количеством отработанных запчастей от&nbsp;карьерных экскаваторов и&nbsp;различных тросов&nbsp;&mdash; ввиду толщины материала.
                                    </p>
                                </slide>
                            </simple-slider>
                        </div>
                    </template>
                    <template #bottom>
                        <z-button kind="white" tag="a" href="/clean-norilsk/objects/depot-north/">
                            Подробнее
                            <template v-slot:right>
                                <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                            </template>
                        </z-button>
                        <z-button class="hide-me--default show-me--tablet" tag="a" href="/clean-norilsk/objects/">
                            Все объекты
                        </z-button>
                    </template>
                </pseudo-slider-item>
                <pseudo-slider-item v-if="id.value === 'four'" current-id="four" key="four">
                    <template #title>
                        Заброшенный завод крупнопанельного домостроения
                    </template>
                    <template #main>
                        <before-after class="u-bottom-margin--l" after-image="/images/before-after/abandoned-factory-after.jpg" before-image="/images/before-after/abandoned-factory-before.jpg" key="two"/>
                    </template>
                    <template #aside>
                        <z-key-indicator
                            size="l"
                            theme="white"
                            value="47&nbsp;000"
                            value-text="м<sup>2</sup>"
                            name="территории разобрано и расчищено"
                        />
                        <div class="pseudo-slider-item-card__slider">
                            <simple-slider theme="white">
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon width="44" height="44" name="cn/garbage_car_mob"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        Для расчистки территории задействовано до&nbsp;двух десятков единиц техники: средний бульдозер, два погрузчика, два экскаватора и&nbsp;самосвалы.
                                    </p>
                                </slide>
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon width="44" height="44" name="cn/garbage_car_mob"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        Для разреза зданий и&nbsp;галерей задействованы гидроножницы, а&nbsp;также гидромолот, которым разбиваются железобетонные конструкции и&nbsp;фундаменты.
                                    </p>
                                </slide>
                                <slide>
                                    <div class="u-bottom-margin--2xs">
                                        <z-icon width="44" height="44" name="cn/garbage_car_mob"/>
                                    </div>
                                    <z-caption class="u-bottom-margin--xs-important" tag="p" size="xl" theme="white">Особенности:</z-caption>
                                    <p class="gray-20-text">
                                        Акцент делается на&nbsp;сортировку отходов непосредственно на&nbsp;месте: отделение металла от&nbsp;остального строительного мусора для дальнейшей переработки.
                                    </p>
                                </slide>
                            </simple-slider>
                        </div>
                    </template>
                    <template #bottom>
                        <z-button kind="white" tag="a" href="/clean-norilsk/objects/abandoned-factory/">
                            Подробнее
                            <template v-slot:right>
                                <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                            </template>
                        </z-button>
                        <z-button class="hide-me--default show-me--tablet" tag="a" href="/clean-norilsk/objects/">
                            Все объекты
                        </z-button>
                    </template>
                </pseudo-slider-item>
            </transition-group>
        </div>
    </div>
</template>

<script>
import PseudoSliderItem from './PseudoSliderItem.vue'
import BeforeAfter from '@/views/CleanNorilsk/views/Main/components/BeforeAfter.vue'

export default {
    name: 'pseudo-slider',
    components: {
        PseudoSliderItem,
        BeforeAfter
    },
    props: {
        links: {
            type: Array,
            default: () => []
        },
        current: {
            type: String
        }
    },
    data () {
        return {
            id: {
                value: null
            },
            duration: 350
        }
    },
    provide () {
        return {
            slider: this.id
        }
    },
    mounted () {
        this.id.value = this.current
    },
    computed: {
        currentItemIndex () {
            if (this.links.length) {
                return this.links.findIndex((item) => item.id === this.id.value)
            }
            return -1
        },
        leftControl () {
            if (this.currentItemIndex > 0) {
                return this.links[this.currentItemIndex - 1]
            }
            return null
        },
        rightControl () {
            if (this.currentItemIndex < this.links.length - 1) {
                return this.links[this.currentItemIndex + 1]
            }
            return null
        }
    },
    methods: {
        changeId (id) {
            this.id.value = id
        }
    }
}
</script>

<style lang="scss">
.pseudo-slider {
    background-color: #012d83;
    position: relative;
    $parent: &;
    @include padding-level(bottom, L);

    &__header {
        padding-left: $token-spacers-4-xl;
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 0;

        @include breakpoint(laptop) {
            padding-left: $token-spacers-l;
        }
    }

    &__content {
        position: relative;
        display: flex;
    }

    &__control {
        background-color: transparent;
        margin: 0;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 15%;
        position: relative;
        z-index: 1;
        padding: 0 $token-spacers-s;

        .z-icon {
            path {
                transition: fill 300ms ease;
            }
        }

        span {
            font-size: 20px;
            line-height: 1.4;
            color: $token-colors-blue;
            transition: color 300ms ease;
        }

        &--right {
            span {
                text-align: start;
            }
        }

        &--left {
            align-items: flex-end;

            span {
                text-align: end;
            }
        }

        &:hover {
            span {
                color: #25A7FF;
            }

            .z-icon {
                path {
                    fill: #25A7FF;
                }
            }
        }

        &:disabled {
            justify-content: flex-end;

            .z-icon {
                path {
                    fill: $token-colors-gray-35;
                }
            }
        }

        @include breakpoint(laptop) {
            width: auto;
            span {
                display: none;
            }
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

    &-enter {
        opacity: 0;
    }

    &-enter-active {
        transition: opacity 500ms;
    }

    &-leave-active {
        transition: opacity 1000ms;
        opacity: 0;
        position: absolute;
    }
}
</style>
