/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Se': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32285)"/><path pid="2" d="M29.158 40.3c-3.075 0-5.3-1.075-6.775-2.65l1.625-2.25c1.15 1.225 2.95 2.3 5.275 2.3 2.4 0 3.35-1.175 3.35-2.275 0-3.5-9.725-1.325-9.725-7.475 0-2.75 2.425-4.875 6.075-4.875 2.6 0 4.7.825 6.25 2.325l-1.675 2.175c-1.3-1.3-3.075-1.9-4.825-1.9-1.725 0-2.825.825-2.825 2.075 0 3.1 9.7 1.175 9.7 7.4 0 2.775-1.95 5.15-6.45 5.15zm14.518 0c-3.625 0-6.325-2.525-6.325-6.35 0-3.5 2.55-6.325 6.125-6.325 3.625 0 5.95 2.775 5.95 6.575v.625h-9.325c.2 1.825 1.575 3.325 3.85 3.325 1.175 0 2.575-.475 3.425-1.325l1.2 1.725c-1.2 1.15-2.975 1.75-4.9 1.75zm3.2-7.325c-.05-1.425-1.025-3.2-3.4-3.2-2.25 0-3.275 1.725-3.4 3.2h6.8z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32285" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
