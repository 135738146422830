<template>
    <filter-container :show-reset-btn="isFilterActive" :reset-text="text.resetButton" @clear="clear">
        <datepicker-range ref="date"
                          name="date"
                          :value="{
            end: params.to,
            start: params.from
            }"
                          @change="onChange"/>
        <tags-filter ref="tags" :data="tags" name="tags" :title="text.tags" show-filter @change="onChange"/>
    </filter-container>
</template>

<script>
import {debounce} from 'throttle-debounce'
import {localize} from '@/utils/i18n'

export default {
    name: 'search-filter',
    props: {
        from: {
            type: Number | String
        },
        to: {
            type: Number | String
        },
        tags: {
            type: Array,
            default: () => []
        },
        tagsParams: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            text: {
                tags: localize({
                    ru: 'Теги',
                    en: 'Tags'
                }),
                resetButton: localize({
                    ru: 'Сбросить фильтр',
                    en: 'Reset filter'
                })
            },
            params: {
                from: '',
                to: '',
                tags: []
            }
        }
    },
    created () {
        if (this.from) this.params.from = this.from
        if (this.to) this.params.to = this.to
        if (this.tagsParams.length) this.params.tags = this.tagsParams
    },
    computed: {
        isFilterActive () {
            return Boolean(
                this.params?.from ||
                this.params?.to ||
                this.params?.tags?.length
            )
        }
    },
    methods: {
        onChange (data) {
            if (data.name === 'date') {
                this.params.from = data.value.start
                this.params.to = data.value.end
            } else {
                this.params[data.name] = data.value
            }

            this.send()
        },
        clear () {
            this.params = {
                from: '',
                to: '',
                tags: []
            }

            if (this.$refs.date) this.$refs.date.clear()
            if (this.$refs.tags) this.$refs.tags.clear(false)

            this.send()
        },
        send: debounce(100, function () {
            this.$emit('change', this.params)
        })
    }
}
</script>

<style lang="scss">
</style>
