/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flagbanner': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.75 20.25V4.5H21l-4.5 5.625L21 15.75H3.75" _stroke="#0077C8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
