export default [
    {
        'id': 1,
        'selected': false,
        'disabled': false,
        'text': 'АО «Норильский комбинат»'
    },
    {
        'id': 2,
        'selected': false,
        'disabled': false,
        'text': 'АО «ХК БЫСТРА»'
    },
    {
        'id': 3,
        'selected': false,
        'disabled': false,
        'text': 'ООО «ГРК «Быстринское»'
    },
    {
        'id': 4,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Проектные сервисы»'
    },
    {
        'id': 5,
        'selected': false,
        'disabled': false,
        'text': 'ООО «АП-инвест»'
    },
    {
        'id': 6,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Арктик палладий»'
    },
    {
        'id': 7,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Масловское»'
    },
    {
        'id': 8,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Элемент 28»'
    },
    {
        'id': 9,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Интерпромлизинг»'
    },
    {
        'id': 10,
        'selected': false,
        'disabled': false,
        'text': 'ООО «КЦЗ «Норникель»'
    },
    {
        'id': 11,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Профессиональный баскетбольный клуб «ЦСКА»'
    },
    {
        'id': 12,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Твой дом»'
    },
    {
        'id': 13,
        'selected': false,
        'disabled': false,
        'text': 'ЧОУ ДПО «Корпоративный университет «Норильский никель»'
    },
    {
        'id': 14,
        'selected': false,
        'disabled': false,
        'text': 'АО «ЕРП»'
    },
    {
        'id': 15,
        'selected': false,
        'disabled': false,
        'text': 'АО «КРП»'
    },
    {
        'id': 16,
        'selected': false,
        'disabled': false,
        'text': 'АО «Лесосибирский порт»'
    },
    {
        'id': 17,
        'selected': false,
        'disabled': false,
        'text': 'ОАО «Россо»'
    },
    {
        'id': 18,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норникель - ЕРП»'
    },
    {
        'id': 19,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Единство»'
    },
    {
        'id': 20,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норникель Спутник»'
    },
    {
        'id': 21,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норникель Сфера»'
    },
    {
        'id': 22,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норсофт»'
    },
    {
        'id': 23,
        'selected': false,
        'disabled': false,
        'text': 'АО «Кольская ГМК»'
    },
    {
        'id': 24,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Валла-Тунтури»'
    },
    {
        'id': 25,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Восточная ГРК»'
    },
    {
        'id': 26,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Затундра»'
    },
    {
        'id': 27,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Институт Гипроникель»'
    },
    {
        'id': 28,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Интергеопроект»'
    },
    {
        'id': 29,
        'selected': false,
        'disabled': false,
        'text': 'ООО «НН Технические Сервисы»'
    },
    {
        'id': 30,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильская ГРК»'
    },
    {
        'id': 31,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Северная ГРК»'
    },
    {
        'id': 32,
        'selected': false,
        'disabled': false,
        'text': 'АО «Норильск Авиа»'
    },
    {
        'id': 33,
        'selected': false,
        'disabled': false,
        'text': 'АО «Полигон-техноген»'
    },
    {
        'id': 34,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Арена-Аквапарк»'
    },
    {
        'id': 35,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Аэропорт «Норильск»'
    },
    {
        'id': 36,
        'selected': false,
        'disabled': false,
        'text': 'ООО «ЗСК»'
    },
    {
        'id': 37,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Медвежий ручей»'
    },
    {
        'id': 38,
        'selected': false,
        'disabled': false,
        'text': 'ООО «МФК «Норильский никель»'
    },
    {
        'id': 39,
        'selected': false,
        'disabled': false,
        'text': 'ООО «НН Девелопмент»'
    },
    {
        'id': 40,
        'selected': false,
        'disabled': false,
        'text': 'ООО «НН-Сервис»'
    },
    {
        'id': 41,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильский обеспечивающий комплекс»'
    },
    {
        'id': 42,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильскникельремонт»'
    },
    {
        'id': 43,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильскпромтранспорт»'
    },
    {
        'id': 44,
        'selected': false,
        'disabled': false,
        'text': 'ООО «НТПО»'
    },
    {
        'id': 45,
        'selected': false,
        'disabled': false,
        'text': 'ООО «ХК «Норильск»'
    },
    {
        'id': 46,
        'selected': false,
        'disabled': false,
        'text': 'АО «НОРМЕТИМПЭКС»'
    },
    {
        'id': 47,
        'selected': false,
        'disabled': false,
        'text': 'ООО «ДжиПиЭф Инвестментс»'
    },
    {
        'id': 48,
        'selected': false,
        'disabled': false,
        'text': 'ООО «НН Маркетинг»'
    },
    {
        'id': 49,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Центр развития палладиевых технологий»'
    },
    {
        'id': 50,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Ренонс»'
    },
    {
        'id': 51,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Арктик-энерго»'
    },
    {
        'id': 52,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Колабыт»'
    },
    {
        'id': 53,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Печенгастрой»'
    },
    {
        'id': 54,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Бугдаинский рудник»'
    },
    {
        'id': 55,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Быстринская сервисная компания»'
    },
    {
        'id': 56,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Востокгеология»'
    },
    {
        'id': 57,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Ширинское»'
    },
    {
        'id': 58,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Н ТРЭВЕЛ»'
    },
    {
        'id': 59,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Нортранс-Норильск»'
    },
    {
        'id': 60,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Подмосковная Слобода»'
    },
    {
        'id': 61,
        'selected': false,
        'disabled': false,
        'text': 'ООО «С Джи М»'
    },
    {
        'id': 62,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Санаторий «Заполярье»'
    },
    {
        'id': 63,
        'selected': false,
        'disabled': false,
        'text': 'АО «Норильскгазпром»'
    },
    {
        'id': 64,
        'selected': false,
        'disabled': false,
        'text': 'АО «Норильсктрансгаз»'
    },
    {
        'id': 65,
        'selected': false,
        'disabled': false,
        'text': 'АО «НТЭК»'
    },
    {
        'id': 66,
        'selected': false,
        'disabled': false,
        'text': 'АО «ТТК»'
    },
    {
        'id': 67,
        'selected': false,
        'disabled': false,
        'text': 'ООО «Быстринская СЭС»'
    },
    {
        'id': 68,
        'selected': false,
        'disabled': false,
        'text': 'ООО «ЗаполярьеНефтеГаз»'
    },
    {
        'id': 69,
        'selected': false,
        'disabled': false,
        'text': 'ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 70,
        'selected': false,
        'disabled': false,
        'text': 'Заполярный филиал ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 71,
        'selected': false,
        'disabled': false,
        'text': 'Быстринский транспортный филиал ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 72,
        'selected': false,
        'disabled': false,
        'text': 'Норильскэнерго – филиал ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 73,
        'selected': false,
        'disabled': false,
        'text': 'Заполярный транспортный филиал ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 74,
        'selected': false,
        'disabled': false,
        'text': 'Мурманский транспортный филиал ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 75,
        'selected': false,
        'disabled': false,
        'text': 'Архангельский транспортный филиал ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 76,
        'selected': false,
        'disabled': false,
        'text': 'Красноярский транспортный филиал ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 77,
        'selected': false,
        'disabled': false,
        'text': 'Красноярское представительство ПАО «ГМК «Норильский никель»'
    }
]
