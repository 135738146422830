/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/doc': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31973)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" d="M61.699 81h-1.8l-3.834-5.112L52.23 81h-1.819l4.68-6.156-4.41-5.85h1.819l3.564 4.806 3.546-4.806h1.818l-4.393 5.832L61.7 81zm10.518 0h-7.074V68.994h1.494v10.674h5.58V81zm7.79.216c-2.089 0-3.619-.792-4.609-1.908l.883-1.134c.791.882 2.087 1.71 3.78 1.71 2.141 0 2.825-1.152 2.825-2.07 0-3.096-7.11-1.386-7.11-5.67 0-1.98 1.764-3.33 4.123-3.33 1.835 0 3.257.612 4.266 1.674l-.9 1.098c-.9-.99-2.16-1.44-3.475-1.44-1.44 0-2.466.774-2.466 1.908 0 2.7 7.11 1.152 7.11 5.634 0 1.746-1.188 3.528-4.427 3.528zM98.563 81h-1.8l-3.834-5.112L89.095 81h-1.818l4.68-6.156-4.41-5.85h1.818L92.93 73.8l3.546-4.806h1.818l-4.392 5.832L98.563 81z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31973" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31973"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31973" result="effect2_dropShadow_9226_31973"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31973" result="shape"/></filter></defs>'
  }
})
