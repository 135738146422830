/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text/usd': {
    width: 36,
    height: 22,
    viewBox: '0 0 36 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.58 12.051c0 2.952 1.638 4.878 4.896 4.878s4.896-1.908 4.896-4.896V4.707H8.878v7.308c0 2.196-1.188 3.582-3.402 3.582-2.214 0-3.402-1.386-3.402-3.582V4.707H.58v7.344zm11.883 2.97c.99 1.116 2.52 1.908 4.608 1.908 3.24 0 4.428-1.782 4.428-3.528 0-2.523-2.254-3.135-4.223-3.67-1.53-.415-2.887-.784-2.887-1.964 0-1.134 1.026-1.908 2.466-1.908 1.314 0 2.574.45 3.474 1.44l.9-1.098c-1.008-1.062-2.43-1.674-4.266-1.674-2.358 0-4.122 1.35-4.122 3.33 0 2.316 2.077 2.88 3.986 3.399 1.623.44 3.124.849 3.124 2.271 0 .918-.684 2.07-2.826 2.07-1.692 0-2.988-.828-3.78-1.71l-.882 1.134zm11.267 1.692h4.104c3.726 0 6.156-2.574 6.156-5.994 0-3.402-2.43-6.012-6.156-6.012H23.73v12.006zm8.712-5.994c0 2.574-1.692 4.662-4.608 4.662h-2.61V6.04h2.61c2.97 0 4.608 2.088 4.608 4.68z" _fill="#0077C8"/>'
  }
})
