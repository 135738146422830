<template>
    <tippy-component
        class="z-tooltip"
        :interactive="setInteractive()"
        :theme="theme"
        :arrow="arrow"
        :trigger="trigger"
        :placement="placement"
        :class="classObject"
        ref="tippy-tooltip"
        @show="stateTooltip"
        @hide="stateTooltip"
    >
        <template v-slot:trigger>
            <div class="z-tooltip__trigger" :class="getTriggerClass">
                <slot />
                <z-icon
                    v-show="!iconHidden"
                    width="16"
                    height="16"
                    :name="icon"
                    dir="down"
                    class="z-tooltip__icon"
                />
            </div>
        </template>
        <span>
            <div
                class="z-tooltip__close"
                @click="hideTooltip"
                v-show="trigger === 'click'"
            >
                <z-icon
                    width="16"
                    height="16"
                    name="close"
                />
            </div >
            <span>
                <span v-html="content" v-if="content"></span>
                <slot name="content" />
            </span>
        </span>
    </tippy-component>
</template>

<script>
import { TippyComponent } from 'vue-tippy'

export default {
    name: 'z-tooltip',
    components: {
        TippyComponent
    },
    props: {
        text: String,
        theme: {
            type: String,
            default: 'light'
        },
        arrow: {
            type: Boolean,
            default: true
        },
        placement: {
            type: String,
            default: 'right'
        },
        content: {
            type: String,
            required: true
        },
        trigger: {
            type: String,
            default: 'mouseenter'
        },
        interactive: {
            type: Boolean,
            default: false
        },
        iconHidden: Boolean,
        isShown: false,
        icon: {
            type: String,
            default: 'tooltip'
        },
        isTriggerAligned: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isInteractive: false,
            showState: false
        }
    },
    mounted () {
        this.showState = this.isShown
    },
    computed: {
        classObject () {
            return [
                { 'z-tooltip--click': this.trigger === 'click' },
                { 'z-tooltip--without-icon': this.iconHidden },
                { 'z-tooltip--empty': !this.$slots.default },
                { 'is-shown': this.showState },
                `z-tooltip--theme-${this.theme}`
            ]
        },
        getTriggerClass () {
            return this.isTriggerAligned && 'z-tooltip__trigger--aligned'
        }
    },
    methods: {
        stateTooltip () {
            const tippy = this.$refs['tippy-tooltip']
            const icon = tippy.tip.reference.querySelector('.z-tooltip__icon')

            if (!this.showState) {
                icon.classList.add('z-tooltip__icon--active')
                this.showState = true
            } else {
                icon.classList.remove('z-tooltip__icon--active')
                this.showState = false
            }
        },
        hideTooltip () {
            const tippy = this.$refs['tippy-tooltip']
            tippy.tip.hide()
        },
        setInteractive () {
            if (this.trigger === 'click' || this.interactive) {
                this.isInteractive = true
            } else {
                this.isInteractive = false
            }
            return this.isInteractive
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
