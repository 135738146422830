<template>
<div class="current-letter-wrapper">
  <span class="current-letter__letter u-bottom-margin--l">{{letter}}</span>
  <div class="current-letter__word" v-for="word in letterDescriptions" :key="word.name" :id="word.code">
    <description :title="word.name" :value="word.previewText"/>
  </div>
</div>
</template>

<script>
import Description from './WordDescr'
export default {
    props: {
        letter: String,
        descriptions: Array
    },
    components: { 'description': Description },
    name: 'CurrentLetter',
    data () {
        return {
            letterDescriptions: this.descriptions.sort()
        }
    }
}
</script>

<style lang="scss">
.current-letter-wrapper {
  margin-bottom: 80px;
}

.current-letter {
    &__letter {
      display: block;
      font-weight: 600;
      font-size: 30px;
      line-height: 120%;
      color: $token-colors-gray-30;
    }

    &__word:not(:last-child) {
      margin-bottom: 56px;
    }
}
</style>
