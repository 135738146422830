<template>
    <div
        class="central-tenders-table-item"
        :style="`max-width: ${maxWidth}`"
    >
        <span v-if="title" class="central-tenders-table-item__title">
            <span v-html="title"/>
            <button v-if="isCopy" class="central-tenders-table-item__button" @click="copyText">
                <span
                    class="central-tenders-table-item__icon"
                    :class="{'central-tenders-table-item__icon--copied': copied}"
                />
            </button>
            <span v-if="isCopy && copied" class="central-tenders-table-item__noty">
                Скопировано
            </span>
        </span>
        <div class="central-tenders-table-item__text">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CentralTendersTableItem',
    props: {
        title: {
            type: String
        },
        isCopy: {
            type: Boolean,
            default: false
        },
        copyContent: {
            type: String,
            default: ''
        },
        maxWidth: {
            type: String,
            default: 'auto'
        }
    },
    data () {
        return {
            copied: false
        }
    },
    methods: {
        async copyText () {
            if (navigator.clipboard && window.isSecureContext) {
                try {
                    await navigator.clipboard.writeText(this.copyContent)
                    this.copied = true
                } catch (error) {
                    console.log('failed copy', error)
                } finally {
                    setTimeout(() => {
                        this.copied = false
                    }, 3000)
                }
            } else {
                const textArea = document.createElement('textarea')
                textArea.value = this.copyContent

                textArea.style.position = 'absolute'
                textArea.style.left = '-999999px'

                document.body.prepend(textArea)
                textArea.select()

                try {
                    document.execCommand('copy')
                    this.copied = true
                } catch (error) {
                    console.error(error)
                } finally {
                    textArea.remove()
                    setTimeout(() => {
                        this.copied = false
                    }, 2000)
                }
            }
        }
    }
}
</script>

<style lang="scss">
.central-tenders-table-item {
    width: 100%;

    @include breakpoint(laptop) {
        width: calc(50% - 12px);
        max-width: 100% !important;
    }

    @include breakpoint(v-tablet) {
        width: 100%;
    }

    &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-bottom: $token-spacers-3-xs;
        display: flex;
        align-items: center;
        position: relative;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black;

        .z-button {
            font-family: "Proxima Nova W08", sans-serif;
            font-weight: 600;
        }

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }

    &__button {
        padding: 0;
        background: none;
        border: none;
        margin-left: $token-spacers-3-xs;
    }

    &__icon {
        display: block;
        width: 18px;
        height: 18px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.062 2.212a.6.6 0 0 0 0 1.2h9.525v9.526a.6.6 0 0 0 1.2 0V2.812a.6.6 0 0 0-.6-.6H5.062zm-2.25 2.25a.6.6 0 0 0-.6.6v10.125a.6.6 0 0 0 .6.6h10.125a.6.6 0 0 0 .6-.6V5.062a.6.6 0 0 0-.6-.6H2.812zm.6 10.125V5.662h8.925v8.925H3.412z' fill='%230077C8'/%3E%3C/svg%3E");
        position: relative;

        &:hover {
            opacity: 0.8;
        }

        &--copied {
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.424 4.924a.6.6 0 0 0-.848-.848L6.75 11.9 3.424 8.576a.6.6 0 1 0-.848.848l3.75 3.75a.6.6 0 0 0 .848 0l8.25-8.25z' fill='%230077C8'/%3E%3C/svg%3E");
        }
    }

    &__noty {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;
        padding: 8px 10px;
        color: $token-colors-gray-60;
        background-color: $token-colors-blue-20;
        box-shadow: 0 4px 4px rgba(0, 32, 51, 0.04), 0 8px 24px rgba(0, 32, 51, 0.12);
        border-radius: 4px;
        position: absolute;
        top: -40px;
        left: 72px;
        z-index: 1;
    }
}
</style>
