var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports"},[_c('div',{staticClass:"reports__wrapper"},[_c('div',{staticClass:"row u-bottom-margin--s"},[(_vm.data['annual-report'])?_c('div',{staticClass:"col-default-3 col-tablet-4 col-v-tablet-6 col-mobile-12"},[_c('report-block',{attrs:{"data":{
                        image: _vm.data['annual-report'].arImg.link,
                        name: 'Годовой отчет за 2020 г.',
                        fileLink: _vm.data['annual-report'].arFile.link,
                        onlinelink: _vm.data['annual-report'].arUrl
                    }}})],1):_vm._e(),_vm._v(" "),(_vm.data['sustability-report'])?_c('div',{staticClass:"col-default-3 col-tablet-4 col-v-tablet-6 col-mobile-12"},[_c('report-block',{attrs:{"data":{
                        image: _vm.data['sustability-report'].csrImg.link,
                        name: 'Годовой отчет за 2020 г.',
                        fileLink: _vm.data['sustability-report'].csrFile.link,
                        onlinelink: _vm.data['sustability-report'].csrUrl
                    }}})],1):_vm._e(),_vm._v(" "),(_vm.data['white-book'])?_c('div',{staticClass:"col-default-3 col-tablet-4 col-v-tablet-6 col-mobile-12"},[_c('report-block',{attrs:{"data":{
                        image: _vm.data['white-book'].wbImg.link,
                        name: 'Годовой отчет за 2020 г.',
                        fileLink: _vm.data['white-book'].wbFile.link
                    }}})],1):_vm._e()])]),_vm._v(" "),_c('z-link',{attrs:{"href":"#"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('z-icon',{attrs:{"name":"round-arrow","dir":"right","width":"32","height":"32"}})]},proxy:true},{key:"default",fn:function(){return [_vm._v("Все годовые отчеты")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }