/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/xlsx': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM17.007 30h1.4l-2.49-3.43 2.34-3.24h-1.41l-1.71 2.45-1.72-2.45h-1.4l2.35 3.25-2.5 3.42h1.4l1.87-2.61 1.87 2.61zm2.151 0h4.11v-1.03h-2.94v-5.64h-1.17V30zm4.736-.94c.59.63 1.48 1.06 2.71 1.06 1.8 0 2.58-.95 2.58-2.06 0-1.46-1.333-1.8-2.437-2.08-.779-.198-1.443-.367-1.443-.88 0-.5.44-.83 1.13-.83.7 0 1.41.24 1.93.76l.67-.87c-.62-.6-1.46-.93-2.5-.93-1.46 0-2.43.85-2.43 1.95 0 1.402 1.264 1.723 2.351 1.998.82.208 1.54.39 1.54.992 0 .44-.38.91-1.34.91-.93 0-1.65-.43-2.11-.92l-.65.9zm10.828.94h1.4l-2.49-3.43 2.34-3.24h-1.41l-1.71 2.45-1.72-2.45h-1.4l2.35 3.25-2.5 3.42h1.4l1.87-2.61 1.87 2.61z" _fill="#737B91"/>'
  }
})
