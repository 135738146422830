<template>
<div class="priority-news" v-if="data.length">
    <div class="container">
        <div class="row mobile-scroll">
            <div
                class="col-default-4"
                v-for="(item, index) in data"
                :key="index"
            >
                <announce
                    :image="item.previewPicture"
                    :date="item.activeFrom"
                    class="u-bottom-margin--xs"
                />
                <tag
                    v-if="item.newsSource && item.newsSource.value"
                    size="s"
                    :interactive="false"
                    decoration
                    theme="light-blue"
                    :name="item.newsSource.value"
                    class="priority-news__tag u-bottom-margin--2xs"
                />
                    <z-caption size="xl" tag="p">
                        <z-link class="priority-news__link" :href="item.detailPageUrl">
                            <vue-raw :raw="item.name"></vue-raw>
                        </z-link>
                    </z-caption>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'priority-news',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style lang="scss">
.priority-news {
    background: $token-colors-gray-10;
    @include padding-level(top, XL);
    @include padding-level(bottom, XL);

    &__link {
        color: $token-colors-gray-60 !important;
        display: inline !important;

        &:hover {
            color: $token-colors-navy-blue !important;
        }
    }
}
</style>
