/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_backbone_infrastructure': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<rect pid="0" width="38" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M28 13a3 3 0 10-3.75 2.907v.843a1.5 1.5 0 01-1.5 1.5H16c-.527 0-1.045.14-1.5.404v-2.747a3 3 0 10-1.5 0v6.187a3 3 0 101.5 0v-.844a1.5 1.5 0 011.5-1.5h6.75a3 3 0 003-3v-.843A3.006 3.006 0 0028 13zm-15.75 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm3 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM25 14.5a1.499 1.499 0 110-2.998 1.499 1.499 0 010 2.998z" _fill="#fff"/>'
  }
})
