/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cooperation': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M44 24c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6zm0-10c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm-1 41v21h2V55h-2zm11-21.655V33c0-2.8-2.2-5-5-5H39c-2.8 0-5 2.2-5 5v.345c2.325.828 4 3.055 4 5.655v37h-2V57h-2v19h-2V55h4V39c0-2.2-1.8-4-4-4H22c-2.2 0-4 1.8-4 4v16h4v21h-2V57h-4V39c0-3.3 2.7-6 6-6h10c0-3.9 3.1-7 7-7h10c3.9 0 7 3.1 7 7h10c3.3 0 6 2.7 6 6v18h-4v19h-2V55h4V39c0-2.2-1.8-4-4-4H56c-2.2 0-4 1.8-4 4v16h4v21h-2V57h-2v19h-2V39c0-2.6 1.675-4.827 4-5.655zM28 59h-2v17h2V59zm-6-33c0 2.8 2.2 5 5 5s5-2.2 5-5-2.2-5-5-5-5 2.2-5 5zm2 0c0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3-3-1.3-3-3zm38 33v17h-2V59h2zm-1-28c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm-1 14v2h2v-2h-2zm-34 0h2v2h-2v-2zm19-7h-2v2h2v-2zm-2 4h2v2h-2v-2z" _fill="#0077C8"/>'
  }
})
