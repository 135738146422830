/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text_doc copy': {
    width: 38,
    height: 14,
    viewBox: '0 0 38 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.899 7.007c0 3.42-2.43 5.994-6.157 5.994H.638V.995h4.104c3.726 0 6.157 2.61 6.157 6.012zm-8.767 4.662h2.61c2.916 0 4.608-2.088 4.608-4.662 0-2.592-1.638-4.68-4.608-4.68h-2.61v9.342zm22.54-4.662c0 3.546-2.412 6.21-5.958 6.21-3.564 0-5.958-2.664-5.958-6.21S15.15.797 18.714.797c3.546 0 5.958 2.664 5.958 6.21zm-10.368 0c0 2.79 1.71 4.878 4.41 4.878 2.682 0 4.41-2.088 4.41-4.878 0-2.808-1.728-4.878-4.41-4.878-2.7 0-4.41 2.07-4.41 4.878zm22.962 3.834c-1.008 1.368-2.448 2.376-4.572 2.376-3.456 0-6.174-2.52-6.174-6.21s2.718-6.21 6.174-6.21c2.124 0 3.6 1.026 4.554 2.376l-1.26.702c-.684-1.008-1.926-1.746-3.294-1.746-2.628 0-4.626 2.016-4.626 4.878 0 2.844 1.998 4.878 4.626 4.878 1.368 0 2.61-.72 3.294-1.746l1.278.702z" _fill="#0077C8"/>'
  }
})
