/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_2646_22106)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.6 5a.6.6 0 10-1.2 0v6.4H5a.6.6 0 100 1.2h6.4V19a.6.6 0 101.2 0v-6.4H19a.6.6 0 100-1.2h-6.4V5z" _fill="#0077C8"/></g><defs><clipPath id="clip0_2646_22106"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
