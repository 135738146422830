/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_elementTe': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32284)"/><path pid="2" d="M31.884 40h-2.925V25.9h-5.05v-2.575h13.025V25.9h-5.05V40zm10.267.3c-3.626 0-6.326-2.525-6.326-6.35 0-3.5 2.55-6.325 6.125-6.325 3.625 0 5.95 2.775 5.95 6.575v.625h-9.325c.2 1.825 1.575 3.325 3.85 3.325 1.175 0 2.576-.475 3.425-1.325l1.2 1.725c-1.2 1.15-2.974 1.75-4.9 1.75zm3.2-7.325c-.05-1.425-1.026-3.2-3.4-3.2-2.25 0-3.276 1.725-3.4 3.2h6.8z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32284" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
