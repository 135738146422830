<template>
    <notification v-if="currentType">
        <template #image>
            <z-image :src="currentType.image" v-if="currentType.image"/>
        </template>
        <template #description>
            <vue-raw :raw="currentType.title" v-if="currentType.title"/>
            <vue-raw :raw="currentType.description" v-if="currentType.description"/>
            <vue-raw :raw="currentType.button" v-if="currentType.button"/>
        </template>
    </notification>
</template>

<script>
import {localize} from '@/utils/i18n'
export default {
    name: 'hoc-notification',
    props: {
        email: String,
        type: {
            type: String,
            validator: (prop) => ['subscribe-message', 'edit-message', 'edited', 'confirm', 'unsubscribe'].includes(prop),
            required: true
        }
    },
    data () {
        return {
            subscribeMessage: {
                image: '/images/notifications/message-avatar.svg',
                title: localize({
                    ru: `<z-caption size="3xl">На&nbsp;почтовый адрес <z-link href="mailto:${this.email}">${this.email}</z-link> было отправлено письмо с&nbsp;подтверждением</z-caption>`,
                    en: `<z-caption size="3xl">A&nbsp;confirmation email has been sent to&nbsp;<z-link href="mailto:${this.email}">${this.email}</z-link></z-caption>`
                }),
                description: localize({
                    ru: '<p class="u-top-margin--s">В&nbsp;дальнейшем вы&nbsp;сможете менять настройки подписки на&nbsp;странице <z-link href="/news-and-media/subscription/edit/">Управление подпиской.</z-link></p>',
                    en: '<p class="u-top-margin--s">In&nbsp;the future, you can change the subscription settings on&nbsp;the <z-link href="/news-and-media/subscription/edit/">Manage Subscription page.</z-link></p>'
                }),
                button: localize({
                    ru: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Перейти к&nbsp;новостям</z-button>',
                    en: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Go&nbsp;to&nbsp;news</z-button>'
                })
            },
            editMessage: {
                image: '/images/notifications/message-avatar.svg',
                title: localize({
                    ru: `<z-caption size="3xl">На&nbsp;почтовый адрес <z-link href="mailto:${this.email}">${this.email}</z-link> было отправлено письмо со&nbsp;ссылкой для изменения настроек рассылки</z-caption>`,
                    en: `<z-caption size="3xl">An&nbsp;email was sent to&nbsp;<z-link href="mailto:${this.email}">${this.email}</z-link> with a&nbsp;link to&nbsp;change the mailing list settings</z-caption>`
                }),
                button: localize({
                    ru: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Перейти к&nbsp;новостям</z-button>',
                    en: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Go&nbsp;to&nbsp;news</z-button>'
                })
            },
            edited: {
                image: '/images/notifications/success-avatar.svg',
                title: localize({
                    ru: '<z-caption tag="h2" size="3xl">Вы&nbsp;успешно изменили подписку</z-caption>',
                    en: '<z-caption tag="h2" size="3xl">You have successfully changed your subscription</z-caption>'
                }),
                description: localize({
                    ru: '<z-button tag="a" href="/news-and-media/subscription/edit/" class="u-top-margin--s" kind="text">Посмотрите вашу подписку</z-button>',
                    en: '<z-button tag="a" href="/news-and-media/subscription/edit/" class="u-top-margin--s" kind="text">View your subscription</z-button>'
                }),
                button: localize({
                    ru: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Перейти к&nbsp;новостям</z-button>',
                    en: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Go&nbsp;to&nbsp;news</z-button>'
                })
            },
            unsubscribe: {
                image: '/images/notifications/sad-avatar.svg',
                title: localize({
                    ru: '<z-caption tag="h2" size="3xl">Сожалеем, что вы&nbsp;отписались от&nbsp;нашей рассылки</z-caption>',
                    en: '<z-caption tag="h2" size="3xl">We&nbsp;are sorry that you have unsubscribed from our mailing list.</z-caption>'
                }),
                description: localize({
                    ru: '<p class="u-top-margin--s">Нам очень жаль расставаться с&nbsp;вами. Надеемся, что вы&nbsp;скоро соскучитесь и&nbsp;вернетесь.</p>',
                    en: '<p class="u-top-margin--s">We&nbsp;are very sorry to&nbsp;part with you. We&nbsp;hope you get bored and come back soon.</p>'
                }),
                button: localize({
                    ru: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Перейти к&nbsp;новостям</z-button>',
                    en: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Go&nbsp;to&nbsp;news</z-button>'
                })
            },
            confirm: {
                image: '/images/notifications/success-avatar.svg',
                title: localize({
                    ru: '<z-caption tag="h2" size="3xl">Вы&nbsp;успешно подписались на&nbsp;рассылку новостей</z-caption>',
                    en: '<z-caption tag="h2" size="3xl">You have successfully subscribed to&nbsp;the newsletter</z-caption>'
                }),
                description: localize({
                    ru: '<p class="u-top-margin--s">Вы&nbsp;сможете менять настройки подписки на&nbsp;странице <z-link href="/news-and-media/subscription/edit/">Управление подпиской.</z-link></p>',
                    en: '<p class="u-top-margin--s">You can change your subscription settings on&nbsp;the <z-link href="/news-and-media/subscription/edit/">Manage Subscription page.</z-link></p>'
                }),
                button: localize({
                    ru: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Перейти к&nbsp;новостям</z-button>',
                    en: '<z-button tag="a" href="/news-and-media/press-releases-and-news/" class="u-top-margin--xs" size="l">Go&nbsp;to&nbsp;news</z-button>'
                })
            }
        }
    },
    computed: {
        currentType () {
            switch (this.type) {
            case ('subscribe-message'): {
                return this.subscribeMessage
            }
            case ('edit-message'): {
                return this.editMessage
            }
            case ('edited'): {
                return this.edited
            }
            case ('unsubscribe'): {
                return this.unsubscribe
            }
            case ('confirm'): {
                return this.confirm
            }
            default: {
                return null
            }
            }
        }
    }
}
</script>

<style lang="scss">
</style>
