/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_9226_31858)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.238 12.925a16.92 16.92 0 01-.53-.875L1.681 12l.027-.05a19.075 19.075 0 012.604-3.54C6.138 6.466 8.74 4.6 12 4.6s5.863 1.865 7.687 3.81a19.08 19.08 0 012.604 3.54l.028.05-.028.05a19.081 19.081 0 01-2.604 3.54C17.863 17.536 15.26 19.4 12 19.4c-3.26 0-5.863-1.864-7.687-3.81a19.077 19.077 0 01-2.075-2.665zm21.298-1.193L23 12c.537.269.537.269.536.27v.002l-.004.005-.01.02-.02.038-.018.036a18.13 18.13 0 01-.714 1.205 20.28 20.28 0 01-2.207 2.835C18.637 18.464 15.74 20.6 12 20.6s-6.637-2.136-8.563-4.19a20.28 20.28 0 01-2.774-3.773 12.172 12.172 0 01-.185-.34l-.01-.02-.003-.006-.001-.002L1 12a66.968 66.968 0 01-.537-.268v-.001l.002-.002.003-.006.01-.02a7.484 7.484 0 01.185-.34A20.274 20.274 0 013.438 7.59C5.362 5.535 8.26 3.4 12 3.4c3.74 0 6.637 2.135 8.563 4.189a20.274 20.274 0 012.774 3.773 11.962 11.962 0 01.185.34l.01.02.003.006.001.002zM23 12l.537-.268a.6.6 0 010 .537L23 12zM.463 11.732L1 12l-.537.269a.6.6 0 010-.537zM9.6 12a2.4 2.4 0 114.8 0 2.4 2.4 0 01-4.8 0zM12 8.4a3.6 3.6 0 100 7.2 3.6 3.6 0 000-7.2z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_31858"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
