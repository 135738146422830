/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people-default': {
    width: 114,
    height: 114,
    viewBox: '0 0 114 114',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.573 95.427c-21.22-21.221-21.22-55.628 0-76.85 21.222-21.22 55.629-21.22 76.85 0 21.221 21.222 21.221 55.629 0 76.85-21.221 21.222-55.628 21.222-76.85 0zm-.848-77.698c-21.69 21.69-21.69 56.857 0 78.547 21.69 21.69 56.857 21.69 78.547 0 21.689-21.69 21.689-56.857 0-78.547-21.69-21.69-56.857-21.69-78.547 0zm38.434-.159c-7.076 0-12.934 6.42-12.934 14.456 0 8.085 5.882 15.35 12.934 15.35 3.518 0 6.743-1.8 9.105-4.643s3.829-6.696 3.829-10.707c0-8.037-5.842-14.456-12.934-14.456zM42.025 32.026c0-8.576 6.279-15.656 14.134-15.656 7.873 0 14.134 7.08 14.134 15.656 0 4.295-1.568 8.418-4.106 11.474-2.54 3.056-6.081 5.077-10.028 5.077-7.914 0-14.134-8.023-14.134-16.551zm14.134 22.62c-14.85 0-26.88 12.047-26.88 26.88v6.444c0 .5.102.84.246 1.08.143.235.354.417.65.557.63.298 1.558.377 2.729.377h7.151V76.19a.6.6 0 011.2 0v13.793h29.807V76.19a.6.6 0 011.2 0v13.793h7.133c.227 0 .441.002.647.004h.001c.35.003.673.007.987-.001.492-.013.883-.053 1.188-.144.294-.088.473-.212.59-.377.121-.171.226-.457.23-.961v-6.98c0-14.85-12.03-26.88-26.88-26.88zM40.685 91.184h30.947a.586.586 0 00.05 0h7.713c.199 0 .4.002.601.004h.002c.367.003.73.007 1.062-.002.52-.013 1.04-.056 1.501-.193.472-.14.912-.39 1.226-.833.309-.436.446-.99.451-1.648v-6.987c0-15.512-12.567-28.08-28.08-28.08-15.512 0-28.079 12.586-28.079 28.08v6.445c0 .663.136 1.231.42 1.7.285.473.694.8 1.163 1.022.902.427 2.086.492 3.242.492h7.722a.586.586 0 00.06 0z" _fill="#0077C8"/>'
  }
})
