<template>
    <nav class="clean-navigation-main" v-if="data">
        <ul class="clean-navigation-main__list">
            <li
                class="clean-navigation-main__item"
                v-for="(item, index) in data"
                :key="index"
            >
                <component
                    :is="item.items && item.items.length ? 'div' : 'a'"
                    :href="item.items && item.items.length ? null : item.link"
                    @click="sticky ? setIndex(index, 'over') : null"
                    @focusout="sticky ? setIndex(index, 'leave') : null"
                    @mouseover="!sticky ? setIndex(index, 'over') : null"
                    @mouseleave="!sticky ? setIndex(index, 'leave') : null"
                    :class="['clean-navigation-main__link', {'clean-navigation-main__link--active' : item.active}]"
                    :tabindex="index"
                >
                    <span>
                        {{ item.name }}
                        <z-icon
                            v-if="item.items && item.items.length"
                            name="arrow"
                            width="20"
                            height="20"
                            dir="down"
                        />
                    </span>
                    <div
                        :class="[
                            'clean-navigation-main__sublist',
                            {'clean-navigation-main__sublist--opened' : curIndex === index}
                            ]"
                        v-if="item.items && item.items.length"
                    >
                        <div class="clean-navigation-main__sublist-wrapper">
                            <ul>
                                <li
                                    class="clean-navigation-main__subitem"
                                    v-for="(subItem, i) in item.items"
                                    :key="i"
                                >
                                    <a
                                        :href="subItem.link"
                                        :class="['clean-navigation-main__sublink', {'clean-navigation-main__sublink--active' : subItem.active}]"
                                        v-html="subItem.name"
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </component>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'clean-navigation-main',
    props: {
        data: {
            type: Array,
            required: true
        },
        sticky: Boolean
    },
    data () {
        return {
            curIndex: null
        }
    },
    methods: {
        setIndex (index, status) {
            if (status === 'leave') {
                this.curIndex = null
            } else {
                this.curIndex = index
            }
        }
    }
}
</script>

<style lang="scss">
.clean-navigation-main {
    $parent: &;
    width: 100%;

    &__list {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 75px;
        margin: 0;

        @include breakpoint (v-tablet) {
            justify-content: space-between;
            gap: 0;
        }
    }

    &__link {
        text-decoration: none;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        transition: all 0.3s;
        outline: none;

        > span {
            font-weight: 600;
            font-size: 22px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: $token-colors-white;
            transition: all 0.3s;
            display: flex;
            align-items: center;
            gap: 4px;

            path {
                fill: $token-colors-white;
            }

            @include breakpoint (v-tablet) {
                font-size: 16px;
            }
        }

        &--active {
            border-bottom: 2px solid $token-colors-link-light-active;

            > span {
                color: $token-colors-link-light-active;

                path {
                    fill: $token-colors-link-light-active;
                }
            }
        }

        &:hover {
            border-color: transparent;

            &::after {
                opacity: 1;
                visibility: visible;
            }

            > span {
                color: $token-colors-link-light-hover;

                path {
                    fill: $token-colors-link-light-hover;
                }
            }
        }
    }

    &__item:first-of-type {
        #{$parent}__link {
            justify-content: flex-start;
        }

        #{$parent}__sublist {
            &::after {
                left: 30px;
            }
        }
    }

    &__item:last-of-type {
        #{$parent}__link {
            justify-content: flex-end;
        }

        #{$parent}__sublist {
            &::after {
                left: calc(100% - 30px);
            }
        }
    }

    &__sublist {
        position: absolute;
        top: 46px;
        padding: 40px 11px 40px 22px;
        background: #FFFFFF;
        box-shadow: 0 8px 8px rgba(0, 32, 51, 0.25);
        border-radius: 0 0 30px 0;
        border-top: 4px solid $token-colors-border-hover;
        transition: all 0.3s;
        opacity: 0;
        visibility: hidden;

        &-wrapper {
            max-height: 490px;
            padding: 0 21px 0 0;
            overflow-y: auto;

            > ul {
                display: block;
                columns: 1;
                column-gap: $token-spacers-3-xs;
                width: max-content;
            }
        }

        &--opened {
            opacity: 1;
            visibility: visible;
        }

        &::after {
            content: '';
            width: 24px;
            height: 12px;
            background: url(/images/clean-norilsk/link-before.svg);
            display: block;
            position: absolute;
            top: -14px;
            left: calc(50% - 12px);
            background-size: cover;
            transition: all 0.3s;
        }

        &::before {
            content: '';
            width: 100%;
            height: 12px;
            background: transparent;
            display: block;
            position: absolute;
            top: -15px;
            left: 0;
        }
    }

    &__subitem {
        max-width: 250px;
        width: max-content;
        padding: 2px 10px 4px;
        margin-bottom: $token-spacers-3-xs;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            background: $token-colors-blue-20;

            > #{$parent}__sublink {
                color: $token-colors-button-bordered-hover;
            }
        }
    }

    &__sublink {
        display: block;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: $token-colors-blue;
        text-decoration: none;

        &--active {
            color: $token-colors-blue-50;
        }
    }
}
</style>
