/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'megaworker': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<g clip-path="url(#clip0_7831_67414)" _fill="#0077C8"><path pid="0" d="M71 76h-2V61c0-4.6-3.1-7.6-10.3-10.1l-3-1 .6-1.9 3 1C64.8 50.9 71 53.9 71 61v15zM51 41h-2v5h2v-5zM19 76h-2V61c0-7.1 6.2-10.1 11.7-11.9l3-1 .6 1.9-3 1C22.1 53.4 19 56.4 19 61v15zM39 41h-2v5h2v-5zM29 68h-2v8h2v-8zM61 68h-2v8h2v-8z"/><path pid="1" d="M44 44c-7.8 0-10.8-7.5-11-12.6l-.7-.7c-.2-.2-.3-.4-.3-.7v-4c0-.3.2-.6.4-.8l3-2c.2-.1.4-.2.6-.2h6.6l2.7-2.7c.3-.3.8-.4 1.2-.2l6 3s.1 0 .1.1l3 2c.3.2.4.5.4.8v4c0 .3-.1.5-.3.7l-.7.7C54.8 36.5 51.8 44 44 44zM34 29.6l.7.7c.2.2.3.4.3.7 0 4.3 2.4 11 9 11s9-6.7 9-11c0-.3.1-.5.3-.7l.7-.7v-3.1l-2.5-1.7-5.3-2.6-2.5 2.5c-.2.2-.4.3-.7.3h-6.7L34 26.5v3.1z"/><path pid="2" d="M55.7 26.7l-1.4-1.4.7-.7V20c0-2.2-1.8-4-4-4h-6c-.2 0-.3 0-.4-.1L41 14.1l-3.6 1.8c-.4.2-.8.1-1.2-.2l-1.1-1.1-2.1 3.7v6.3l.7.7-1.4 1.4-1-1c-.2-.2-.3-.4-.3-.7v-7c0-.2 0-.4.1-.5l3-5c.2-.3.5-.5.8-.5.3 0 .6.1.8.3l1.5 1.5 3.4-1.7c.3-.1.6-.1.9 0l3.7 1.9H51c3.3 0 6 2.7 6 6v5c0 .3-.1.5-.3.7l-1 1zM47 59h-6c-.3 0-.5-.1-.7-.3l-10-10c-.2-.2-.3-.5-.3-.9.1-.3.3-.6.5-.7l4-2c.2-.1.3-.1.5-.1h4c.3 0 .6.2.8.4l4.2 5.9 4.2-5.9c.2-.3.5-.4.8-.4h4c.2 0 .3 0 .4.1l4 2c.3.1.5.4.5.7.1.3-.1.6-.3.9l-10 10c-.1.2-.3.3-.6.3zm-5.6-2h5.2l8.7-8.7-2.5-1.3h-3.2l-4.7 6.6c-.4.5-1.3.5-1.6 0L38.5 47h-3.2l-2.5 1.3 8.6 8.7z"/></g><defs><clipPath id="clip0_7831_67414"><path pid="3" _fill="#fff" transform="translate(17 12)" d="M0 0h54v64H0z"/></clipPath></defs>'
  }
})
