var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-detail container"},[(_vm.data.item.newsSource && _vm.data.item.newsSource.xmlId)?_c('div',{staticClass:"news-detail__tags u-bottom-margin--xs"},[_c('tag',{staticClass:"news-detail__tag",attrs:{"size":"m","interactive":false,"theme":"light-blue","name":_vm.text[_vm.data.item.newsSource.xmlId]}})],1):_vm._e(),_vm._v(" "),_c('date-block',{staticClass:"u-bottom-margin--s",attrs:{"date":_vm.data.item.activeFrom,"theme":"inline"}}),_vm._v(" "),_c('layout',{scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.data.item)?_c('content-container',[(_vm.data.item.previewText)?_c('z-lead',[_c('vue-raw',{attrs:{"raw":_vm.data.item.previewText}})],1):_vm._e(),_vm._v(" "),(_vm.data.item.detailText)?_c('div',{staticClass:"news-detail__detail html-content"},[_c('vue-raw',{attrs:{"raw":_vm.data.item.detailText}})],1):_vm._e(),_vm._v(" "),(_vm.data.item.newsFile && _vm.data.item.newsFile.length)?_c('z-filelist',{staticClass:"u-top-margin--s",attrs:{"size":"l","theme":"fancy"}},_vm._l((_vm.data.item.newsFile),function(item,index){return _c('z-filelist-item',{key:index,attrs:{"name":_vm.text.download,"href":item.link,"file-size":item.size,"icon-name":"file/text-pdf"}})}),1):_vm._e(),_vm._v(" "),(
                        (_vm.data.item.newsInside && _vm.data.item.newsInside.value === 'Да') ||
                        (_vm.data.item.newsAuthor && _vm.data.item.newsAuthor.value === 'Да') ||
                        (_vm.data.item.newsAboutCompany && _vm.data.item.newsAboutCompany.value === 'Да')
                    )?_c('div',{staticClass:"bordered-block u-top-margin--m"},[(_vm.data.item.newsInside.value === 'Да')?_c('div',{staticClass:"navy-blue-text text-size-xl u-normal"},[_c('vue-raw',{attrs:{"raw":_vm.data.item.newsInsideText.value.TEXT}})],1):_vm._e(),_vm._v(" "),(_vm.data.item.newsAuthor.value === 'Да')?_c('div',{class:{ 'u-top-margin--3xs': _vm.data.item.newsInside.value === 'Да' && _vm.data.item.newsAuthor.value === 'Да' }},[_c('vue-raw',{attrs:{"raw":_vm.data.item.newsAuthorText.value.TEXT}})],1):_vm._e(),_vm._v(" "),(_vm.data.item.newsAboutCompany.value === 'Да')?_c('div',{class:{ 'u-top-margin--s': _vm.data.item.newsAboutCompany.value === 'Да' && _vm.data.item.newsAuthor.value === 'Да' }},[_c('vue-raw',{attrs:{"raw":_vm.data.item.newsAboutCompanyText.value.TEXT}})],1):_vm._e()]):_vm._e()],1):_vm._e()]},proxy:true},{key:"aside",fn:function(){return [(_vm.data.item.newsFile && _vm.data.item.newsFile.length)?_c('z-filelist',{staticClass:"u-bottom-margin--xl-important",attrs:{"size":"l","theme":"fancy"}},_vm._l((_vm.data.item.newsFile),function(item,index){return _c('z-filelist-item',{key:index,attrs:{"name":_vm.text.save,"href":item.link,"file-size":item.size,"icon-name":"upload"}})}),1):_vm._e(),_vm._v(" "),(_vm.data.item.newsShowSmiContacts && _vm.data.item.newsShowSmiContacts.value === 'Да')?[_c('z-caption',{staticClass:"u-bottom-margin--l",attrs:{"tag":"h2","decor-left":"","decor-right":"","size":"m","weight":"700","uppercase":""}},[_c('vue-raw',{attrs:{"raw":_vm.text.contactsMeida}})],1),_vm._v(" "),_c('contact-card',{staticClass:"u-bottom-margin--xl",attrs:{"phone":[{
                        text: '+7 (495) 785 58 00',
                        value: '+74957855800'
                    }],"fax":[{
                        text: '+7 (495) 785 58 00',
                        value: '+74957855800'
                    }],"email":[{
                        value: 'pr@nornik.ru',
                    }]}})]:_vm._e(),_vm._v(" "),(_vm.data.item.newsShowPressServiceContacts &&_vm.data.item.newsShowPressServiceContacts.value === 'Да')?[_c('z-caption',{staticClass:"u-bottom-margin--l",attrs:{"tag":"h2","decor-left":"","decor-right":"","size":"m","weight":"700","uppercase":""}},[_c('vue-raw',{attrs:{"raw":_vm.text.contactsPressService}})],1),_vm._v(" "),_c('contact-card',{staticClass:"u-bottom-margin--xl",attrs:{"phone":[{
                        text: '+7 (495) 785 58 00',
                        value: '+74957855800'
                    }],"fax":[{
                        text: '+7 (495) 785 58 00',
                        value: '+74957855800'
                    }],"email":[{
                        value: 'pr@nornik.ru',
                    }]}})]:_vm._e(),_vm._v(" "),(_vm.data.item.newsShowInvestorsContacts && _vm.data.item.newsShowInvestorsContacts.value === 'Да')?[_c('z-caption',{staticClass:"u-bottom-margin--l",attrs:{"tag":"h2","decor-left":"","decor-right":"","size":"m","weight":"700","uppercase":""}},[_c('vue-raw',{attrs:{"raw":_vm.text.contactsInvestors}})],1),_vm._v(" "),_c('contact-card',{staticClass:"u-bottom-margin--xl",attrs:{"phone":[{
                        text: '+7 (495) 786 83 20',
                        value: '+74957868320'
                    }],"email":[{
                        value: 'ir@nornik.ru',
                    }]}})]:_vm._e(),_vm._v(" "),(_vm.data.publications && _vm.data.publications.length)?_c('div',{staticClass:"u-bottom-margin--xl"},[_c('z-caption',{staticClass:"u-bottom-margin--l",attrs:{"tag":"h2","decor-left":"","decor-right":"","size":"m","weight":"700","uppercase":""}},[_c('vue-raw',{attrs:{"raw":_vm.text.publicationsTitle}})],1),_vm._v(" "),_c('news-list',{attrs:{"theme":"aside","component":"publicationsNews"}}),_vm._v(" "),_c('z-link',{staticClass:"u-top-margin--m",attrs:{"href":_vm.data['all-news']},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('z-icon',{attrs:{"name":"round-arrow","dir":"right","width":"32","height":"32"}})]},proxy:true}],null,false,416547810)},[_vm._v("\n                    "+_vm._s(_vm.text.allNews)+"\n                ")])],1):_vm._e(),_vm._v(" "),_vm._t("aside")]},proxy:true}],null,true)}),_vm._v(" "),(_vm.pageNav)?_c('page-changer',{staticClass:"u-top-margin--l",attrs:{"data":_vm.pageNav}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }