/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.4 6a.6.6 0 01.6-.6h18a.6.6 0 110 1.2H3a.6.6 0 01-.6-.6zm0 6a.6.6 0 01.6-.6h18a.6.6 0 110 1.2H3a.6.6 0 01-.6-.6zm.6 5.4a.6.6 0 100 1.2h18a.6.6 0 100-1.2H3z" _fill="#0077C8"/>'
  }
})
