<template>
    <div class="simple-slider" :class="[`simple-slider--${theme}`, {'simple-slider--dynamic': isDynamic}]">
        <div class="swiper-container simple-slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <div class="simple-slider__controls" :class="controlsClassArray">
            <div class="simple-slider__nav">
                <button
                    v-if="showNavigation"
                    class="simple-slider__nav-btn simple-slider__nav-btn--prev"
                    ref="prev"
                    @click="handlerTrackGoal(goalName)"
                >
                    <z-icon name="arrow" dir="left" height="24px" />
                </button>

                <div v-if="showPagination" class="simple-slider__pagination" ref="pagination" />

                <button
                    v-if="showNavigation"
                    class="simple-slider__nav-btn simple-slider__nav-btn--next"
                    ref="next"
                    @click="handlerTrackGoal(goalName)"
                >
                    <z-icon name="arrow" dir="right" height="24px" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
import { trackGoal } from '@/utils/analytics'

export default {
    name: 'simple-slider',
    props: {
        showPagination: {
            type: Boolean,
            default: true
        },
        showNavigation: {
            type: Boolean,
            default: true
        },
        slidesPerView: {
            type: [String, Number],
            default: 1
        },
        spaceBetween: {
            type: Number,
            default: 0
        },
        slidesPerGroup: {
            type: Number,
            default: 1
        },
        controlsPosition: {
            type: String,
            default: 'left',
            validator: (prop) => ['left', 'center', 'right'].includes(prop)
        },
        theme: {
            type: String,
            default: 'default',
            validator: prop => ['white', 'default'].includes(prop)
        },
        autoHeight: {
            type: Boolean
        },
        isDynamic: {
            type: Boolean,
            default: false
        },
        goalName: {
            type: String
        }
    },
    data () {
        return {
            slider: undefined
        }
    },
    methods: {
        init () {
            let options = {
                observer: true,
                observeParents: true,
                autoHeight: this.autoHeight,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                pagination: {
                    clickable: true,
                    el: this.$refs.pagination,
                    dynamicBullets: this.isDynamic
                },
                slidesPerView: this.slidesPerView,
                spaceBetween: this.spaceBetween,
                slidesPerGroup: this.slidesPerGroup,
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        slidesPerGroup: 1
                    }
                }
            }

            this.slider = new Swiper(this.$refs.slider, options)
        },
        handlerTrackGoal (goalName) {
            trackGoal(goalName)
        }
    },
    computed: {
        controlsClassArray () {
            let controlsClassArray = [
                {
                    'u-flex-justify--flex-start': this.controlsPosition === 'left',
                    'u-flex-justify--flex-end': this.controlsPosition === 'right',
                    'u-flex-justify--center': this.controlsPosition === 'center'
                }
            ]

            return controlsClassArray
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
@import '~/src/styles/base/utilities';

.simple-slider {
    $parent: &;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &--auto-height {
        height: 100%;
    }

    &__container,
    &__controls {
        flex: 0 0 100%;
        max-width: 100%;
    }

    &__controls {
        @include margin-level(top, XL);
    }

    &__nav {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -8px;
    }

    &__nav-btn,
    &__pagination {
        margin: 0 8px;
    }

    &__nav-btn {
        @extend %u-reset-button;
        display: flex;
        align-items: center;
        justify-content: center;

        .z-icon {
            transition: all $transition;
            height: 24px;
            width: 24px;
        }

        &[aria-disabled='true'] {
            cursor: default;
            pointer-events: none;

            .z-icon path {
                fill: $token-colors-gray-30 !important;
            }
        }

        &--prev:hover {
            svg {
                transform: translateX(-8px) rotate(-90deg);
            }
        }

        &--next:hover {
            svg {
                transform: translateX(8px) rotate(90deg);
            }
        }
    }

    &__pagination {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .swiper-pagination-bullet {
            margin: 0 12px;
            width: 6px;
            height: 6px;
            background-color: $token-colors-navy-blue;
            opacity: 1;
            transition: width 300ms ease, height 300ms ease, left 300ms ease;

            &-active {
                width: 12px;
                height: 12px;
                background-color: $token-colors-blue-50 !important;
            }
        }
    }

    .swiper-slide {
        .z-image {
            display: block;
            &__img {
                display: block;
                width: 100%;
            }
        }
    }

    &--dynamic {
        #{$parent}__pagination {
            margin: 0;
            white-space: nowrap;
            text-align: center;
            flex-wrap: nowrap;
            align-items: center;
        }

        .swiper-pagination-bullet {
            margin: 0 8px;
            flex-shrink: 0;
        }
    }

    &--white {
        .swiper-pagination-bullet {
            background-color: $token-colors-blue-30;
        }
        #{$parent}__nav-btn {
            .z-icon path {
                fill: $token-colors-blue-30;
            }
        }
    }
}
</style>
