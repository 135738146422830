/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_big': {
    width: 120,
    height: 120,
    viewBox: '0 0 120 120',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M58.424 41.58a.6.6 0 00-.848 0l-30 30a.6.6 0 00.848.848L58 42.852l29.576 29.576a.6.6 0 00.848-.849l-30-30z" _fill="#0077C8"/>'
  }
})
