/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'magnifier': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.75 4a6.75 6.75 0 104.213 12.024l2.757 2.757a.75.75 0 001.06-1.06l-2.756-2.757A6.75 6.75 0 0010.75 4zm3.778 10.396a5.25 5.25 0 10-.133.133.75.75 0 01.133-.133z" _fill="#0077C8"/>'
  }
})
