<template>
<div class="news-detail container">
    <div class="news-detail__tags u-bottom-margin--xs" v-if="data.item.newsSource && data.item.newsSource.xmlId">
        <tag
            size="m"
            :interactive="false"
            theme="light-blue"
            :name="text[data.item.newsSource.xmlId]"
            class="news-detail__tag"
        />

    </div>
    <date-block
        :date="data.item.activeFrom"
        theme="inline"
        class="u-bottom-margin--s"
    />
    <layout>
        <template #content>
            <content-container v-if="data.item">
                <z-lead v-if="data.item.previewText">
                    <vue-raw :raw="data.item.previewText"/>
                </z-lead>
                <div class="news-detail__detail html-content" v-if="data.item.detailText">
                    <vue-raw :raw="data.item.detailText" />
                </div>

                <z-filelist
                    v-if="data.item.newsFile && data.item.newsFile.length"
                    size="l"
                    theme="fancy"
                    class="u-top-margin--s"
                >
                    <z-filelist-item
                        v-for="(item, index) in data.item.newsFile"
                        :key="index"
                        :name="text.download"
                        :href="item.link"
                        :file-size="item.size"
                        icon-name="file/text-pdf"
                    ></z-filelist-item>
                </z-filelist>

                <div
                    class="bordered-block u-top-margin--m"
                    v-if="
                        (data.item.newsInside && data.item.newsInside.value === 'Да') ||
                        (data.item.newsAuthor && data.item.newsAuthor.value === 'Да') ||
                        (data.item.newsAboutCompany && data.item.newsAboutCompany.value === 'Да')
                    "
                >
                    <div
                        class="navy-blue-text text-size-xl u-normal"
                        v-if="data.item.newsInside.value === 'Да'"
                    >
                        <vue-raw :raw="data.item.newsInsideText.value.TEXT" />
                    </div>
                    <div
                        :class="{ 'u-top-margin--3xs': data.item.newsInside.value === 'Да' && data.item.newsAuthor.value === 'Да' }"
                        v-if="data.item.newsAuthor.value === 'Да'"
                    >
                        <vue-raw :raw="data.item.newsAuthorText.value.TEXT" />
                    </div>
                    <div
                        :class="{ 'u-top-margin--s': data.item.newsAboutCompany.value === 'Да' && data.item.newsAuthor.value === 'Да' }"
                        v-if="data.item.newsAboutCompany.value === 'Да'"
                    >
                        <vue-raw :raw="data.item.newsAboutCompanyText.value.TEXT" />
                    </div>
                </div>
            </content-container>
        </template>
        <template #aside>
            <z-filelist
                v-if="data.item.newsFile && data.item.newsFile.length"
                size="l"
                theme="fancy"
                class="u-bottom-margin--xl-important"
            >
                <z-filelist-item
                    v-for="(item, index) in data.item.newsFile"
                    :key="index"
                    :name="text.save"
                    :href="item.link"
                    :file-size="item.size"
                    icon-name="upload"
                ></z-filelist-item>
            </z-filelist>

            <template v-if="data.item.newsShowSmiContacts && data.item.newsShowSmiContacts.value === 'Да'">
                <z-caption
                    tag="h2"
                    decor-left
                    decor-right
                    size="m"
                    weight="700"
                    uppercase
                    class="u-bottom-margin--l"
                >
                    <vue-raw :raw="text.contactsMeida"/>
                </z-caption>
                <contact-card
                    :phone="[{
                        text: '+7 (495) 785 58 00',
                        value: '+74957855800'
                    }]"
                    :fax="[{
                        text: '+7 (495) 785 58 00',
                        value: '+74957855800'
                    }]"
                    :email="[{
                        value: 'pr@nornik.ru',
                    }]"
                    class="u-bottom-margin--xl"
                ></contact-card>
            </template>

            <template v-if="data.item.newsShowPressServiceContacts &&data.item.newsShowPressServiceContacts.value === 'Да'">
                <z-caption
                    tag="h2"
                    decor-left
                    decor-right
                    size="m"
                    weight="700"
                    uppercase
                    class="u-bottom-margin--l"
                >
                    <vue-raw :raw="text.contactsPressService"/>
                </z-caption>
                <contact-card
                    :phone="[{
                        text: '+7 (495) 785 58 00',
                        value: '+74957855800'
                    }]"
                    :fax="[{
                        text: '+7 (495) 785 58 00',
                        value: '+74957855800'
                    }]"
                    :email="[{
                        value: 'pr@nornik.ru',
                    }]"
                    class="u-bottom-margin--xl"
                ></contact-card>
            </template>

            <template v-if="data.item.newsShowInvestorsContacts && data.item.newsShowInvestorsContacts.value === 'Да'">
                <z-caption
                    tag="h2"
                    decor-left
                    decor-right
                    size="m"
                    weight="700"
                    uppercase
                    class="u-bottom-margin--l"
                >
                    <vue-raw :raw="text.contactsInvestors"/>
                </z-caption>
                <contact-card
                    :phone="[{
                        text: '+7 (495) 786 83 20',
                        value: '+74957868320'
                    }]"
                    :email="[{
                        value: 'ir@nornik.ru',
                    }]"
                    class="u-bottom-margin--xl"
                ></contact-card>
            </template>

            <div
                v-if="data.publications && data.publications.length"
                class="u-bottom-margin--xl"
            >
                <z-caption
                    tag="h2"
                    decor-left
                    decor-right
                    size="m"
                    weight="700"
                    uppercase
                    class="u-bottom-margin--l"
                >
                    <vue-raw :raw="text.publicationsTitle"/>
                </z-caption>
                <news-list
                    theme="aside"
                    component="publicationsNews"
                ></news-list>

                <z-link
                    class="u-top-margin--m"
                    :href="data['all-news']"
                >
                    <template #left>
                        <z-icon
                            name="round-arrow"
                            dir="right"
                            width="32"
                            height="32"
                        />
                    </template>
                    {{ text.allNews }}
                </z-link>
            </div>

            <slot name="aside" />
        </template>
    </layout>
    <page-changer
        v-if="pageNav"
        :data="pageNav"
        class="u-top-margin--l"
    />
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'news-detail',
    computed: {
        pageNav () {
            if (!(this.data.nav && (this.data.nav.next || this.data.nav.prev))) return
            let nav = Object.assign({}, this.data.nav)
            if (nav.next) nav.next.name = ''
            if (nav.prev) nav.prev.name = ''
            return nav
        }
    },
    data () {
        return {
            data: this.$root.app.components['news-detail'],
            text: {
                news: localize({
                    ru: 'Новости',
                    en: 'News'
                }),
                esg: localize({
                    ru: 'ESG новости',
                    en: 'ESG news'
                }),
                social: localize({
                    ru: 'Соц.сети',
                    en: 'Social services'
                }),
                publications: localize({
                    ru: 'Публикации в СМИ',
                    en: 'Publications'
                }),
                releases: localize({
                    ru: 'Пресс-релизы',
                    en: 'Press releases'
                }),
                save: localize({
                    ru: 'Сохранить пресс-релиз',
                    en: 'Download press release'
                }),
                download: localize({
                    ru: 'Скачать полную версию',
                    en: 'Dowload full version'
                }),
                contactsMeida: localize({
                    ru: 'Контакты для СМИ',
                    en: 'Media Contacts'
                }),
                contactsInvestors: localize({
                    ru: 'Контакты для инвесторов',
                    en: 'Contacts for investors'
                }),
                contactsPressService: localize({
                    ru: 'Пресс-служба',
                    en: 'Press service'
                }),
                publicationsTitle: localize({
                    ru: 'Публикации по теме',
                    en: 'Related publications'
                }),
                allNews: localize({
                    'ru': 'Все новости',
                    'en': 'All news'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.news-detail {
    .layout__aside {
        @include breakpoint(tablet) {
            margin-top: $token-spacers-m;
        }
    }

    .page-changer__button-wrapper {
        @include breakpoint(v-tablet) {
            flex-shrink: 0;
            width: fit-content;
            margin: 0 auto;
        }

        @include breakpoint(mobile) {
            flex-shrink: 1;
        }
    }

    .page-changer__link .page-changer__link-text {
        @include breakpoint(v-tablet) {
            display: none;
        }
    }
}
</style>
