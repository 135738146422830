/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'm2': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.317 5.126c0 2.38 4.954 4.556 6.393 5.139H12.47a.606.606 0 100 1.213h3.738a.606.606 0 100-1.213h-1.65c1.55-.588 6.804-2.757 6.804-5.14C21.363 2.533 18.278.5 14.34.5c-3.938 0-7.023 2.032-7.023 4.626zm7.025-3.413c-3.15 0-5.81 1.563-5.81 3.413 0 1.208 3.206 3.03 5.606 4.002 2.505-.952 6.014-2.814 6.014-4.002 0-1.85-2.662-3.413-5.81-3.413zm-.607 18.413c-3.706-.11-7.27-1.224-7.27-3.258 0-.182.029-.357.083-.525a.613.613 0 01-.01-.112c0-.259.002-.516.004-.768v-.006c.012-1.621.024-3.153-.588-3.712-.265-.24-.686-.33-1.288-.27a.608.608 0 01-.12-1.206c.965-.095 1.693.093 2.227.58.93.848.994 2.362.985 4.14 1.485-.917 4.045-1.39 6.583-1.39 2.538 0 5.097.471 6.582 1.389-.008-1.778.056-3.291.986-4.14.533-.486 1.261-.676 2.226-.58a.607.607 0 01-.12 1.207c-.602-.06-1.023.029-1.288.27-.611.559-.6 2.089-.588 3.712v.005c.002.253.004.508.004.769a.613.613 0 01-.01.109c.056.168.085.344.085.528 0 2.032-3.564 3.148-7.27 3.258v3.49c2.738.139 4.924.945 5.87 2.093a1.343 1.343 0 011.632 1.309 1.34 1.34 0 11-2.567-.54c-.651-.795-2.64-1.625-5.544-1.67-2.968.047-4.98.914-5.583 1.724a1.34 1.34 0 11-.99-.698c.888-1.215 3.133-2.075 5.97-2.219v-3.49zm.607-5.314c-4.068 0-6.664 1.217-6.664 2.056s2.596 2.054 6.663 2.054c4.068 0 6.664-1.217 6.664-2.054 0-.837-2.596-2.056-6.663-2.056zm-1.87-1.5h3.737a.606.606 0 100-1.213h-3.738a.606.606 0 100 1.213z" _fill="#1A1B25"/>'
  }
})
