<template>
<div class="presentations-and-briefings" v-if="data">
    <z-tabs size="m" :padding="false" v-if="data.tabs && data.tabs.length">
        <template v-slot:label>
            <z-tabs-label
                v-for="(tab, index) in data.tabs"
                :key="index"
                :id="tab.value"
            >{{ tab.name }}</z-tabs-label>
        </template>
        <template v-slot:content>
            <z-tabs-content
                v-for="(tab, index) in data.tabs"
                :key="index"
                :id="tab.value"
            >
                <z-tabs nested kind="drop" :padding="false" size="m">
                    <template v-slot:label>
                        <z-tabs-label
                            v-for="type in types"
                            :key='type'
                            :id="`${type}-${tab.name}`"
                            :disabled="isdisabledItem(tab.name, type)"
                        >
                            {{text.tabs[type]}}
                        </z-tabs-label>
                    </template>
                    <template v-slot:content>
                        <z-tabs-content v-for="type in types" :key='type' :id="`${type}-${tab.name}`">
                            <z-filelist theme="fancy" v-if="currentItems(tab.name, type).length">
                                <z-filelist-item
                                    v-for="(item, index) in currentItems(tab.name, type)"
                                    :key="index"
                                    :name="item.name"
                                    :href="item.mlType.xmlId === 'presentation' ? item.mlDocs.link : item.detailPageUrl"
                                    :file-size="item.mlType.xmlId === 'presentation' ? item.mlDocs.size : ''"
                                    :date="item.activeFrom"
                                    :icon-name="icon[item.mlType.xmlId]"
                                    :data-year="item.year"
                                    :target="item.mlType.xmlId === 'presentation' ? '_blank' : '_self'"
                                >
                                    <template #header>
                                        <div class="presentations-and-briefings__tags">
                                            <tag
                                                class="presentations-and-briefings__tag"
                                                decoration
                                                :interactive="false"
                                                theme="light-blue"
                                                size="xs"
                                                :name="text.tags[item.mlType.xmlId]"
                                                v-if="item.mlType.xmlId"
                                            />
                                        </div>
                                    </template>
                                </z-filelist-item>
                            </z-filelist>
                            <div class="presentations-and-briefings__not-found" v-else>{{text['not-found']}}</div>
                        </z-tabs-content>
                    </template>
                </z-tabs>
            </z-tabs-content>
        </template>
    </z-tabs>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'presentations-and-briefings',
    data () {
        return {
            wasInitialStateTrue: false,
            data: null,
            currentType: '',
            text: {
                presentations: localize({
                    ru: 'Презентации',
                    en: 'Presentations'
                }),
                briefings: localize({
                    ru: 'Выступления',
                    en: 'Briefings'
                }),
                tabs: {
                    'all': localize({
                        ru: 'Все',
                        en: 'All'
                    }),
                    'management-briefings': localize({
                        ru: 'Выступления менеджмента',
                        en: 'Management briefings'
                    }),
                    'esg-presentation': localize({
                        ru: 'ESG-презентации',
                        en: 'ESG-presentation'
                    }),
                    'financial-results': localize({
                        ru: 'Финансовые результаты',
                        en: 'Financial results'
                    }),
                    'strategy-day-presentation': localize({
                        ru: 'День Стратегии',
                        en: 'Strategy Day presentation'
                    }),
                    'roadshow-and-other-presentations': localize({
                        ru: 'Прочие',
                        en: 'Roadshow and other presentations'
                    })
                },
                tags: {
                    interview: localize({
                        ru: 'Выступления',
                        en: 'Briefings'
                    }),
                    presentation: localize({
                        ru: 'Презентации',
                        en: 'Presentations'
                    })
                },
                'not-found': localize({
                    ru: 'Ничего не найдено',
                    en: 'Nothing found'
                })
            },
            icon: {
                presentation: 'book-open',
                interview: 'mic'
            },
            types: ['all', 'esg-presentation', 'management-briefings', 'financial-results', 'strategy-day-presentation', 'roadshow-and-other-presentations']
        }
    },

    methods: {
        isdisabledItem (tabName, type) {
            if (type === 'all') return false
            return !this.currentItems(tabName, type).length
        },
        currentItems (tabName, type) {
            return this.filteredType(this.yearItems(tabName), type)
        },
        filteredType (items, type) {
            if (type === 'all') return items

            return items.filter(el => {
                return (el.presentationType.xmlId === '' && type === 'roadshow-and-other-presentations') || el.presentationType.xmlId === type
            })
        },
        yearItems (year) {
            return this.data.items.filter((item) => +item.year === +year)
        }
    },
    created () {
        if (this.$root.app.components['presentations-and-briefings']) {
            this.data = this.$root.app.components['presentations-and-briefings']
        }
    }
}
</script>

<style lang="scss">
.presentations-and-briefings {
    &__tag {
        margin-left: $token-spacers-3-xs/2;
        margin-right: $token-spacers-3-xs/2;
        margin-top: $token-spacers-3-xs;
    }

    &__tags {
        margin-top: -$token-spacers-3-xs;
        margin-bottom: $token-spacers-3-xs;
    }

    &__not-found {
        @include typo-level(L);
    }

    @include breakpoint (mobile) {
        .z-tabs {
            .z-tabs-content {
                padding-left: 0;
                padding-right: 0;

                .z-tabs-content {
                    @include margin-level(left, S,);
                    @include margin-level(right, S,);
                }
            }
        }
    }

    .z-tabs .z-tabs--kind-drop {
        .z-tabs-label {
            &:hover {
                color: $token-colors-navy-blue;
            }

            &--opened,
            &:active,
            &:focus {
                background-color: $token-colors-navy-blue;
                color: $token-colors-white;
            }

            &:after {
                display: none;
            }
        }
    }
}
</style>
