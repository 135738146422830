/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/txt': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM15.867 31h1.404v-6.768h2.424v-1.236h-6.252v1.236h2.424V31zm10.37 0h1.68l-2.988-4.116 2.808-3.888h-1.692l-2.052 2.94-2.064-2.94h-1.68l2.82 3.9-3 4.104h1.68l2.244-3.132L26.237 31zm4.49 0h1.404v-6.768h2.424v-1.236h-6.252v1.236h2.424V31z" _fill="#737B91"/>'
  }
})
