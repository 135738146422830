<template>
    <component :is="href ? 'a' : 'div'" :href="href" class="tl-more">
        <div class="tl-more__content">
            <p class="tl-more__subtitle" v-html="subTitle"></p>
            <p class="tl-more__title" v-html="title"></p>
        </div>
        <div class="tl-more__wrapper">
            <z-icon
                class="tl-more__arrow"
                name="round-arrow"
                width="42"
                height="42"
                dir="right"
            />
            <div class="tl-more__image" :style="`background-image: url(${this.image})`"></div>
        </div>
    </component>
</template>

<script>
export default {
    name: 'tl-more',
    props: {
        image: String,
        href: String,
        subTitle: {
            type: String,
            default: 'Посмотрите'
        },
        title: {
            type: String,
            default: 'Все события'
        }
    }
}
</script>

<style lang="scss">
.tl-more {
    position: relative;
    width: 500px;
    height: 500px;
    display: block;
    text-decoration: none;

    @include breakpoint (v-tablet) {
        width: 400px;
        height: 400px;
    }

    @include breakpoint (mobile) {
        width: 288px;
        height: auto;
    }

    &__image {
        width: 100%;
        background-color: #eee;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        overflow: hidden;
        border-radius: 50%;

        &:after {
            content: '';
            display: block;
            padding-top: 100%;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba($token-colors-white, 0.2);
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 2;
        flex-direction: column;
        padding-bottom: 122px;
        pointer-events: none;
        user-select: none;

        @include breakpoint (mobile) {
            position: relative;
            margin-bottom: 20px;
            padding-bottom: 0;
            pointer-events: all;
            height: auto;
        }
    }

    &__title {
        font-family: 'Proxima Nova Condensed';
        color: $token-colors-white;
        line-height: 1.2;
        font-weight: 300;
        font-size: 72px;
        margin: 0;

        @include breakpoint (v-tablet) {
            font-size: 48px;
        }

        @include breakpoint (mobile) {
            font-size: 24px;
            color: $token-colors-blue;
        }
    }

    &__subtitle {
        color: $token-colors-white;
        font-weight: 600;
        font-size: 30px;
        line-height: 1.2;
        margin: 0;

        @include breakpoint (mobile) {
            font-size: 16px;
            color: $token-colors-gray-30;
        }
    }

    &__arrow {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg) !important;
        z-index: 2;

        @include breakpoint (mobile) {
            bottom: 50%;
            transform: translate(-50%, 50%) rotate(90deg) !important;
        }

        path {
            fill: $token-colors-blue;
        }
    }

    &__wrapper {
        position: relative;
        border-radius: 50%;
        overflow: hidden;

        @include breakpoint (mobile) {
            width: 169px;
            height: 169px;
            margin: 0 auto;
        }

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(358.88deg, rgba(0, 119, 200, 0.8) 0.99%, rgba(95, 168, 209, 0) 74.94%);
            bottom: 0;
            position: absolute;
            z-index: 1;
        }

        &:after {
            content: '';
            opacity: 0;
            background: rgba(0, 119, 200, 0.5);
            width: 100%;
            height: 100%;
            top: 0;
            position: absolute;
            transition: opacity $transition;

            @include breakpoint (mobile) {
                display: none;
            }
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }

    .z-icon.z-fill {
        background: $token-colors-white;
        border-radius: 50%;
    }
}
</style>
