<template>
    <div class="contact-card" :class="{'contact-card--horizontal': horizontal}">
        <div class="contact-card__head u-bottom-margin--xs" v-if="photo || hasNameSlot || hasDescriptionSlot">
            <picture class="contact-card__pic" v-if="photo">
                <img :src="photo"/>
            </picture>
            <div class="contact-card__info">
                <h4 class="contact-card__name" v-if="hasNameSlot">
                    <slot name="name"></slot>
                </h4>
                <div class="contact-card__info" v-if="hasDescriptionSlot">
                    <slot name="description"></slot>
                </div>
            </div>
        </div>
        <template v-if="horizontal">
            <div class="contact-card__wrapper">
                <template v-if="Boolean(address.length)">
                    <div class="contact-card__row contact-card__row--address">
                        <div class="contact-card__link-icon">
                            <z-icon name="pin-nn" width="1.5em" height="1.5em"></z-icon>
                        </div>
                        <div class="contact_card__content">
                            <div class="contact-card__title">
                                <span>{{ text.address }}</span>
                            </div>
                            <template v-for="(item, index) in address">
                                <div class="contact-card__link" :key="`address-${index}`">
                                    <vue-raw :raw="item.value"/>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="Boolean(phone.length)">
                    <div class="contact-card__row">
                        <div class="contact-card__link-icon">
                            <z-icon
                                name="phone"
                                dir="top"
                                width="1.5em"
                                height="1.5em"
                            ></z-icon>
                        </div>
                        <div class="contact_card__content">
                            <div class="contact-card__title">
                                <span>{{ text.phone }}</span>
                            </div>
                            <template v-for="(item, index) in phone">
                                <div class="contact-card__link" :key="`phone-${index}`">
                                    <z-link class="contact-card__link" :href="`tel:${item.value}`"
                                            size="l">
                                        {{ item.text || item.value }}
                                    </z-link>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="Boolean(fax.length)">
                    <div class="contact-card__row">
                        <div class="contact-card__link-icon">
                            <z-icon
                                name="fax"
                                dir="bottom"
                                width="1.5em"
                                height="1.5em"
                            ></z-icon>
                        </div>
                        <div class="contact_card__content">
                            <div class="contact-card__title">
                                <span>{{ text.fax }}</span>
                            </div>
                            <template v-for="(item, index) in fax">
                                <div :key="`fax-${index}`">
                                    <z-link class="contact-card__link" :href="`fax:${item.value}`"
                                            size="l">
                                        {{ item.text || item.value }}
                                    </z-link>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="Boolean(email.length)">
                    <div class="contact-card__row">
                        <div class="contact-card__link-icon">
                            <z-icon
                                name="message"
                                dir="bottom"
                                width="1.5em"
                                height="1.5em"
                            ></z-icon>
                        </div>
                        <div class="contact_card__content">
                            <div class="contact-card__title">
                                <span>{{ text.email }}</span>
                            </div>
                            <template v-for="(item, index) in email">
                                <div :key="`email-${index}`">
                                    <z-link class="contact-card__link" :href="`mailto:${item.value}`" size="l">
                                        {{ item.text || item.value }}
                                    </z-link>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="Boolean(site.length)">
                        <div class="contact-card__row">
                            <div class="contact-card__link-icon">
                                <z-icon
                                    name="external"
                                    dir="bottom"
                                    width="24"
                                    height="24"
                                ></z-icon>
                            </div>
                            <div class="contact_card__content">
                                <div class="contact-card__title">
                                    <span>{{ text.site }}</span>
                                </div>
                                <template v-for="(item, index) in site">
                                    <div :key="`site-${index}`">
                                        <z-link class="contact-card__link" :href="`${item.value}`" size="l">
                                            {{ item.text || item.value }}
                                        </z-link>
                                    </div>
                                </template>
                            </div>
                        </div>
                </template>
            </div>
        </template>
        <template v-else>
            <template v-if="phone">
                <template v-for="(item, index) in phone">
                    <div class="contact-card__row" :key="`phone-${index}`">
                        <div class="contact-card__title">
                            <span v-if="index === 0">{{ text.phone }}</span>
                        </div>
                        <z-link class="contact-card__link" :href="`tel:${item.value}`" size="l">
                            <template v-slot:left>
                                <div class="contact-card__link-icon">
                                    <z-icon
                                        name="phone"
                                        dir="top"
                                        width="1.5em"
                                        height="1.5em"
                                    ></z-icon>
                                </div>
                            </template>
                            {{ item.text || item.value }}
                        </z-link>
                    </div>
                </template>
            </template>
            <template v-if="fax">
                <template v-for="(item, index) in fax">
                    <div class="contact-card__row" :key="`fax-${index}`">
                        <div class="contact-card__title">
                            <span v-if="index === 0">{{ text.fax }}</span>
                        </div>
                        <z-link class="contact-card__link" :href="`fax:${item.value}`" size="l">
                            <template v-slot:left>
                                <div class="contact-card__link-icon">
                                    <z-icon
                                        name="fax"
                                        dir="bottom"
                                        width="1.5em"
                                        height="1.5em"
                                    ></z-icon>
                                </div>
                            </template>
                            {{ item.text || item.value }}
                        </z-link>
                    </div>
                </template>
            </template>
            <template v-if="email">
                <template v-for="(item, index) in email">
                    <div class="contact-card__row" :key="`email-${index}`">
                        <div class="contact-card__title">
                            <span v-if="index === 0">{{ text.email }}</span>
                        </div>
                        <z-link class="contact-card__link" :href="`mailto:${item.value}`" size="l">
                            <template v-slot:left>
                                <div class="contact-card__link-icon">
                                    <z-icon
                                        name="message"
                                        dir="bottom"
                                        width="1.5em"
                                        height="1.5em"
                                    ></z-icon>
                                </div>
                            </template>
                            {{ item.text || item.value }}
                        </z-link>
                    </div>
                </template>
            </template>
            <template v-if="site">
                <template v-for="(item, index) in site">
                    <div class="contact-card__row" :key="`site-${index}`">
                        <div class="contact-card__title">
                            <span v-if="index === 0">{{ text.site }}</span>
                        </div>
                        <z-link class="contact-card__link" :href="`${item.value}`" size="l">
                            <template v-slot:left>
                                <div class="contact-card__link-icon">
                                    <z-icon
                                        name="external"
                                        dir="bottom"
                                        width="24"
                                        height="24"
                                    ></z-icon>
                                </div>
                            </template>
                            {{ item.text || item.value }}
                        </z-link>
                    </div>
                </template>
            </template>
            <template v-if="address">
                <template v-for="(item, index) in address">
                    <div class="contact-card__row" :key="`address-${index}`">
                        <div class="contact-card__title">
                            <span v-if="!item.text">{{ text.address }}</span>
                            <vue-raw v-else :raw="item.text"/>
                        </div>
                        <div class="contact-card__link">
                            <div class="contact-card__link-icon">
                                <z-icon name="pin-nn" width="1.5em" height="1.5em"></z-icon>
                            </div>
                            <vue-raw :raw="item.value"/>
                        </div>
                    </div>
                </template>
            </template>
        </template>
        <slot></slot>
    </div>
</template>

<script>
import {localize} from '@/utils/i18n'

export default {
    name: 'contact-card',
    props: {
        photo: String,
        phone: {
            type: Array
        },
        fax: {
            type: Array
        },
        email: {
            type: Array
        },
        site: {
            type: Array
        },
        address: {
            type: Array
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            text: {
                phone: localize({
                    ru: 'Телефон:',
                    en: 'Telephone:'
                }),
                fax: localize({
                    ru: 'Факс:',
                    en: 'Fax:'
                }),
                email: localize({
                    ru: 'E-mail:',
                    en: 'E-mail:'
                }),
                site: localize({
                    ru: 'Сайт:',
                    en: 'Website:'
                }),
                address: localize({
                    ru: 'Адрес:',
                    en: 'Address:'
                })
            }
        }
    },
    computed: {
        hasNameSlot () {
            return !!this.$slots['name']
        },
        hasDescriptionSlot () {
            return !!this.$slots['description']
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
