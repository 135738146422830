/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cn/pin-cnn_mob': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<mask id="svgicon_cn_pin-cnn_mob_a" fill="#fff"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.935 29.241l7.757-7.756a10.97 10.97 0 10-15.514 0l7.757 7.756z"/></mask><path pid="1" d="M23.692 21.485l1.06 1.06-1.06-1.06zm-7.757 7.756l-1.06 1.061 1.06 1.06 1.06-1.06-1.06-1.06zM26.07 9.531l-1.386.573 1.386-.574zm-4.04-4.924l.833-1.247-.834 1.247zm-12.19 0l.834 1.248-.833-1.248zM5.8 9.53l1.386.575-1.385-.575zm-.624 6.338l-1.47.293 1.47-.293zm3.002 5.617l-1.06 1.06 1.06-1.06zm14.453-1.06l-7.757 7.756 2.122 2.121 7.756-7.756-2.12-2.122zm2.592-4.85a9.47 9.47 0 01-2.592 4.85l2.121 2.12a12.469 12.469 0 003.413-6.384l-2.942-.585zm-.54-5.47a9.47 9.47 0 01.54 5.47l2.942.586a12.469 12.469 0 00-.71-7.205l-2.771 1.149zm-3.487-4.25a9.47 9.47 0 013.488 4.25l2.771-1.149a12.47 12.47 0 00-4.592-5.596l-1.667 2.495zm-5.26-1.596a9.47 9.47 0 015.26 1.596l1.667-2.495a12.47 12.47 0 00-6.928-2.101v3zm-5.262 1.596a9.47 9.47 0 015.261-1.596v-3A12.47 12.47 0 009.007 3.36l1.667 2.495zm-3.488 4.25a9.47 9.47 0 013.488-4.25L9.007 3.36a12.47 12.47 0 00-4.592 5.596l2.771 1.149zm-.538 5.47a9.47 9.47 0 01.538-5.47L4.415 8.956a12.47 12.47 0 00-.71 7.205l2.943-.585zm2.591 4.85a9.47 9.47 0 01-2.591-4.85l-2.943.586a12.47 12.47 0 003.413 6.384l2.121-2.12zm7.757 7.756l-7.757-7.757-2.121 2.122 7.756 7.756 2.122-2.121z" _fill="#ADD1EA" mask="url(#svgicon_cn_pin-cnn_mob_a)"/><path pid="2" d="M20.659 16.244a3.448 3.448 0 01-1.505.887v-6.675c.563.16 1.08.46 1.505.886a3.446 3.446 0 011.014 2.451c0 .927-.36 1.797-1.014 2.451zm-6.865-2.155v-3.567l4.412 2.977v3.566l-4.412-2.976zm-.947 3.042a3.437 3.437 0 01-1.505-.887 3.442 3.442 0 01-1.016-2.45c0-.926.361-1.797 1.016-2.452a3.45 3.45 0 011.505-.886v6.675zm5.36-7.751v2.977L13.792 9.38a4.414 4.414 0 100 8.827V15.23l4.413 2.977a4.414 4.414 0 100-8.827z" _fill="#ADD1EA" _stroke="#ADD1EA" stroke-width=".8"/>'
  }
})
