<template>
    <default-slider v-if="localizedQuotes.length">
        <template v-for="(item, index) in localizedQuotes">
            <slide :key="`quote-${index}`">
                <quote-block
                    :quote="item.quote"
                    :img-src="item['img-src']"
                    :title="item.title"
                    :about-text="item['about-text']"
                    :size="size"
                />
            </slide>
        </template>
    </default-slider>
</template>

<script>
import {getQuotes} from '@/api/quotes'
import {localize} from '@/utils/i18n'
export default {
    name: 'quotes-slider',
    props: {
        size: {
            type: String,
            default: 'default'
        }
    },
    data () {
        return {
            quotes: null
        }
    },
    mounted () {
        this.fetchQuotes()
    },
    computed: {
        localizedQuotes () {
            if (this.quotes === null) {
                return []
            }
            return localize({
                ru: this.quotes.ru,
                en: this.quotes.en
            })
        }
    },
    methods: {
        async fetchQuotes () {
            this.quotes = await getQuotes()
        }
    }
}
</script>

<style lang="scss">
</style>
