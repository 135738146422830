<template>
    <transition name="fade" mode="out-in">
        <slot />
    </transition>
</template>

<script>
export default {
    name: 'transition-fade'
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.35s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
