<template>
    <div class="bonds-page-table">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'bonds-page-table'
}
</script>

<style lang="scss">
.bonds-page-table {
    display: grid;
    justify-content: space-between;
    gap: $token-spacers-xs;
    font-family: $robotoMono;
    grid-template-columns: 20% 25% 20% 20% 10%;

    @include breakpoint(tablet) {
        gap: $token-spacers-3-xs;
    }

    @include breakpoint(v-tablet) {
        gap: $token-spacers-xs;
        grid-template-columns: 100%;
    }
}
</style>
