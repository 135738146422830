<template>
    <div class="clean-footer">
        <img class="clean-footer__image" src="/images/clean-norilsk/footer-top-line.png" alt="." />
        <div class="container">
            <div class="clean-footer__left">
                <div class="clean-footer__copyright">
                    © 2021-<span>{{ currentYear }}</span> ПАО «ГМК «Норильский никель». Все права защищены.
                </div>
                <z-button kind="secondary" tag="a" href="/" size="s" class="u-bold">Основной сайт
                    <template v-slot:right>
                        <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                    </template>
                </z-button>
            </div>
            <page-nav />
            <div class="clean-footer__right">
                <div class="clean-footer__links">
                    <z-link
                        v-for="(item, index) in footerLinks"
                        :key="index"
                        :href="item.link"
                        v-html="item.name"
                    >
                    </z-link>
                </div>
                <div class="clean-footer__share">
                    <share-popup
                        icon-name="share-2"
                    />
                    <!-- <div class="clean-footer__socials">
                        <z-link href="https://youtube.com">
                            <img src="/images/clean-norilsk/youtube.svg" alt=".">
                        </z-link>
                        <z-link href="https://vk.com">
                            <img src="/images/clean-norilsk/vk.svg" alt=".">
                        </z-link>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageNav from './PageNav.vue'

export default {
    name: 'clean-footer',
    data () {
        return {
            footerLinks: this.$root.app.components.navigation.bottom
        }
    },
    components: {
        PageNav
    },
    computed: {
        currentYear () {
            return new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss">
.clean-footer {
    padding: 0 0 $token-spacers-xl;
    background: #fff;
    position: relative;

    &__image {
        width: 100%;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @include breakpoint(tablet) {
        padding: 0 0 $token-spacers-xl;

        .container {
            align-items: flex-start;
        }
    }

    @include breakpoint(v-tablet) {
        padding: 0 0 $token-spacers-l;

        .container {
            flex-direction: column;
        }
    }

    @media (max-width: 400px) {
        padding: 0 0 40px;
    }

    &__left,
    &__right {
        width: 100%;

        @include breakpoint(tablet) {
            margin-top: 104px;
        }

        @include breakpoint(v-tablet) {
            margin-top: 0;
        }
    }

    &__left {
        margin-right: 40px;
        max-width: 33.3333%;

        @include breakpoint(v-tablet) {
            margin-right: 0;
            max-width: 100%;
            width: 100%;
            order: 1;
            margin-bottom: $token-spacers-s;
        }

        @include breakpoint (mobile) {
            margin-bottom: 20px;
        }
    }

    &__right {
        margin-left: 40px;
        justify-content: flex-end;
        max-width: 452px;

        @include breakpoint (tablet) {
            max-width: 33.3333%;
            display: flex;
            align-items: flex-start;
        }

        @include breakpoint(v-tablet) {
            justify-content: flex-start;
            flex-direction: column;
            margin-left: 0;
            max-width: 100%;
            order: 2;
        }

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    &__copyright {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;
        color: $token-colors-gray-35;
        margin-bottom: $token-spacers-s;

        @include breakpoint(tablet) {
            max-width: 310px;
            width: 100%;
        }

        @include breakpoint(v-tablet) {
            max-width: max-content;
            width: 100%;
        }

        @include breakpoint (mobile) {
            margin-bottom: 20px;
        }
    }

    &__links {
        margin-bottom: $token-spacers-s;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        @include breakpoint (tablet) {
            margin-bottom: 0;
            flex-direction: column;
        }

        @include breakpoint (v-tablet) {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            margin-bottom: $token-spacers-s;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
            margin-bottom: $token-spacers-xs;
        }

        .z-link {
            font-weight: 600;
            font-size: 16px;

            @include breakpoint (tablet) {
                margin-left: 0;
                margin-bottom: $token-spacers-xs;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:first-of-type {
                margin-left: 0;
            }

            @include breakpoint(v-tablet) {
                margin-left: 81px;
                margin-bottom: 0;
            }

            @include breakpoint(mobile) {
                margin-left: 0;
                margin-bottom: $token-spacers-xs;
            }
        }
    }

    &__share {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint (tablet) {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 77px;
        }

        @include breakpoint (v-tablet) {
            margin-left: 0;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        @include breakpoint(mobile) {
            justify-content: space-between;

            .share {
                flex-grow: 1;
                margin-right: $token-spacers-s;

                .z-link {
                    span {
                        display: none;
                    }
                }
            }
        }

        .z-link {
            font-weight: 600;
            font-size: 16px;

            img {
                display: block;
            }
        }

        .clean-footer__socials {
            display: flex;

            @include breakpoint (tablet) {
                margin-top: $token-spacers-s;
            }

            @include breakpoint (v-tablet) {
                margin-top: 0;
            }

            @include breakpoint (mobile) {
                justify-content: space-between;
                flex-grow: 1;
            }

            .z-link {
                + .z-link {
                    margin-left: $token-spacers-s;
                }
            }
        }
    }

    .clean-page-nav {
        margin-left: auto;
        margin-right: auto;
    }

    .z-button {
        @include breakpoint(mobile) {
            width: max-content !important;

            .z-button__icon {
                display: flex;
            }
        }
    }

}
</style>
