/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round-arrow': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16 1.2c8.174 0 14.8 6.626 14.8 14.8S24.174 30.8 16 30.8 1.2 24.174 1.2 16 7.826 1.2 16 1.2zM16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm.16 10.506a.454.454 0 00-.017.017l-4.622 4.622a.5.5 0 00.707.707l3.77-3.77v8.04a.5.5 0 101 0v-8.04l3.77 3.77a.5.5 0 10.708-.707l-4.624-4.624a.5.5 0 00-.35-.146l-.359.148.355-.148a.497.497 0 00-.337.131z" _fill="#0077C8"/>'
  }
})
