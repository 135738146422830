<template>
    <div class="default-slider">
        <div class="swiper-container default-slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <button
            v-if="showNavigation"
            class="default-slider__nav-btn default-slider__nav-btn--prev"
            ref="prev"
        >
            <z-icon name="arrow_big" dir="left"/>
        </button>
        <div v-if="showPagination" class="default-slider__pagination" ref="pagination" />
        <button
            v-if="showNavigation"
            class="default-slider__nav-btn default-slider__nav-btn--next"
            ref="next"
        >
            <z-icon name="arrow_big" dir="right"/>
        </button>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'default-slider',
    props: {
        showPagination: {
            type: Boolean,
            default: true
        },
        showNavigation: {
            type: Boolean,
            default: true
        },
        slidesPerView: {
            type: [String, Number],
            default: 1
        },
        autoHeight: Boolean
    },
    data () {
        return {
            slider: undefined
        }
    },
    methods: {
        init () {
            let options = {
                observer: true,
                observeParents: true,
                autoHeight: this.autoHeight,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                pagination: {
                    clickable: true,
                    el: this.$refs.pagination
                },
                slidesPerView: this.slidesPerView
            }

            this.slider = new Swiper(this.$refs.slider, options)
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
@import '~/src/styles/base/utilities';

.default-slider {
    $parent: &;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-left: 120px;
    padding-right: 120px;

    @include breakpoint(laptop) {
        padding-left: 80px;
        padding-right: 80px;
    }

    @include breakpoint(tablet) {
        padding-left: 48px;
        padding-right: 48px;
    }

    @include breakpoint(mobile) {
        padding-left: 0;
        padding-right: 0;
    }

    &--auto-height {
        height: 100%;
    }

    &__container {
        flex: 0 0 100%;
        max-width: 100%;
        @include margin-level(bottom, M);
    }

    &__nav-btn {
        @extend %u-reset-button;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all $transition;
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 2;

        .z-icon {
            height: 120px;
            width: 120px;

            @include breakpoint(laptop) {
                height: 80px;
                width: 80px;
            }

            @include breakpoint(tablet) {
                height: 50px;
                width: 50px;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }

        &[aria-disabled='true'] {
            cursor: default;
            pointer-events: none;

            .z-icon path {
                fill: $token-colors-gray-30;
            }
        }

        &--next {
            right: 0;
        }

        &--prev {
            left: 0;
        }
    }

    &__pagination {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;

        .swiper-pagination-bullet {
            margin: 0 12px;
            width: 6px;
            height: 6px;
            background-color: $token-colors-white;
            opacity: 1;

            @media screen and (max-width: 790px) {
                margin: 0 10px;
            }

            @media screen and (max-width: 430px) {
                width: 4px;
                height: 4px;
            }

            &-active {
                width: 12px;
                height: 12px;
                background-color: $token-colors-blue-50;

                @media screen and (max-width: 410px) {
                    width: 8px;
                    height: 8px;
                }
            }
        }
    }

    .swiper-slide {
        .z-image {
            display: block;

            &__img {
                display: block;
                width: 100%;
            }
        }
    }
}
</style>
