/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_2646_22117)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.6 7A1.4 1.4 0 013 5.6h11A1.4 1.4 0 0115.4 7v10a1.4 1.4 0 01-1.4 1.4H3A1.4 1.4 0 011.6 17V7zm15 3.835V7A2.6 2.6 0 0014 4.4H3A2.6 2.6 0 00.4 7v10A2.6 2.6 0 003 19.6h11a2.6 2.6 0 002.6-2.6v-3.834l6.051 4.323A.6.6 0 0023.6 17V7a.6.6 0 00-.949-.488L16.6 10.835zm5.8 5L17.032 12 22.4 8.166v7.668z" _fill="#0077C8"/></g><defs><clipPath id="clip0_2646_22117"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
