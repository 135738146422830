/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'point': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="12" cy="12.001" _fill="#0077C8" r="2.667"/>'
  }
})
