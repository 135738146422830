<template>
    <div class="bods-rewards" v-if="data.length">
        <z-caption
            size="m"
            tag="p"
            decor-left
            decor-right
            uppercase
            weight="600"
            class="u-collapse--all u-bottom-padding--s"
        >
            <vue-raw :raw="text.rewards"/>
        </z-caption>
        <div class="bods-rewards__list">
            <div
                class="bods-rewards__item"
                v-for="(item, index) in data"
                :key="index"
            >
                <vue-raw :raw="item"/>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'bods-rewards',
    data () {
        return {
            data: [],
            text: {
                rewards: localize({
                    ru: 'Награды',
                    en: 'Awards'
                })
            }
        }
    },
    created () {
        if (this.$root.app.components['governance-detail']) {
            this.data = this.$root.app.components['governance-detail'].rewards || []
        }
    }
}
</script>

<style lang="scss">
.bods-rewards {
    &__list {
        @include margin-level(top, S, true);
    }

    &__item {
        @include margin-level(top, S);
        font-size: 16px;
    }
}
</style>
