/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.6 2a.6.6 0 10-1.2 0v1.4H8.6V2a.6.6 0 00-1.2 0v1.4H5A2.6 2.6 0 002.4 6v14A2.6 2.6 0 005 22.6h14a2.6 2.6 0 002.6-2.6V6A2.6 2.6 0 0019 3.4h-2.4V2zm3.8 7.4V6A1.4 1.4 0 0019 4.6h-2.4V6a.6.6 0 11-1.2 0V4.6H8.6V6a.6.6 0 01-1.2 0V4.6H5A1.4 1.4 0 003.6 6v3.4h16.8zM3.6 10.6h16.8V20a1.4 1.4 0 01-1.4 1.4H5A1.4 1.4 0 013.6 20v-9.4z" _fill="#0077C8"/>'
  }
})
