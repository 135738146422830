<template>
    <div class="property-complexes-list" v-if="items.length">
        <template v-for="(item, index) in items">
            <div class="property-complexes-card" :key="`tender-${index}`">
                <div class="property-complexes-card__header">
                    <span
                        class="property-complexes-card__tag"
                        :style="`background-color: ${getColor(item.order.xmlId)}`"
                        v-html="item.order.value"
                    />
                </div>
                <div class="property-complexes-card__body">
                    <z-link :href="item.detailPageUrl">
                        <span class="property-complexes-card__text" v-html="item.name"/>
                    </z-link>
                </div>
                <property-complexes-table>
                    <property-complexes-table-item title="Основной вид деятельности" max-width="201px">
                        <span v-if="item.type && item.type.value" v-html="item.type.value"/>
                        <span v-else>—</span>
                    </property-complexes-table-item>
                    <property-complexes-table-item title="Участник, доля участия (%)" max-width="314px">
                        <span v-if="item.member && item.member.value" v-html="item.member.value"/>
                        <span v-else>—</span>
                    </property-complexes-table-item>
                    <property-complexes-table-item title="Объект продажи (акции (шт), доля в&nbsp;уставном капитале (%))" max-width="202px">
                        <span v-if="item.object && item.object.value" v-html="item.object.value"/>
                        <span v-else>—</span>
                    </property-complexes-table-item>
                    <property-complexes-table-item title="ОГРН" max-width="180px">
                        <span v-if="item.number && item.number.value" v-html="item.number.value"/>
                        <span v-else>—</span>
                    </property-complexes-table-item>
                    <property-complexes-table-item title="Начальная цена реализации, руб." max-width="201px">
                        <span v-if="item.cost && item.cost.value" v-html="item.cost.value"/>
                        <span v-else>—</span>
                    </property-complexes-table-item>
                </property-complexes-table>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment'
import {adaptDate} from '@/utils/filter'
import PropertyComplexesTable from './PropertyComplexesTable.vue'
import PropertyComplexesTableItem from './PropertyComplexesTableItem.vue'

export default {
    name: 'property-complexesList',
    components: {
        PropertyComplexesTable,
        PropertyComplexesTableItem
    },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        checkDate (timestamp) {
            const date = timestamp * 1000
            const today = moment().valueOf()
            const result = date - today
            const limit = 86400000 * 3
            return result < limit
        },
        formattedDate (date) {
            const time = adaptDate(date, 'HH:mm')
            const pattern = /^00:00$/

            if (pattern.test(time)) {
                return adaptDate(date, 'DD.MM.YYYY')
            }
            return adaptDate(date, 'DD.MM.YYYY hh:mm')
        },
        getColor (id) {
            const colors = {
                'public-sale': '#654EA3',
                'trades': '#8AD29D'
            }
            return colors[id] ? colors[id] : '#ffffff'
        }
    }
}
</script>

<style lang="scss">
.property-complexes-list {
    @include margin-level(bottom, L);
}

.property-complexes-card {
    padding: $token-spacers-s $token-spacers-2-xs;
    border-top: 1px solid $token-colors-gray-20;

    @include breakpoint(v-tablet) {
        padding: $token-spacers-s 0;
    }

    &:last-child {
        border-bottom: 1px solid $token-colors-gray-20;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: $token-spacers-xs;

        @include breakpoint(mobile) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    &__tag {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-white;
        text-align: center;
        padding: 2px 16px;
        border-radius: 20px;
        font-family: $robotoMono;
    }

    &__date-wrapper {
        margin-left: $token-spacers-xs;
        display: flex;
        align-items: center;

        @include breakpoint(mobile) {
            margin-left: 0;
            margin-top: $token-spacers-3-xs;
        }
    }

    &__date-key {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        color: $token-colors-gray-35;
        margin-right: $token-spacers-3-xs;
        font-family: $robotoMono;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    &__date-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        color: $token-colors-black;
        font-family: $robotoMono;

        &--hot {
            color: $token-colors-alert-text;
        }
    }

    &__body {
        margin-bottom: $token-spacers-xs;
    }

    &__text {
        width: 100%;
        max-width: 1200px;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        font-family: $robotoMono;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }
}
</style>
