<template>
    <section
        v-if="!isSulphurPage"
        class="sulphur container"
        :style="getBgImgSrc">
        <div class="sulphur__content">
            <z-caption
                decorLeft
                size="2xl"
                tag="h2"
                weight="600"
                class="gray-60-text sulphur__content-title"
                v-html="text.title"></z-caption>
            <z-caption
                weight="400"
                tag="p"
                class="gray-60-text sulphur__content-caption"
                size="l"
                v-html="text.caption"></z-caption>
            <z-button
                class="sulphur__content-btn"
                kind="primary"
                size="m"
                tag="a"
                href="/sustainability/projects/sulphur/">
                <template v-slot:right>
                    <z-icon
                        name="arrow-long"
                        dir="right"
                        width="18"
                        height="18"></z-icon>
                </template>
                {{ text.button }}
            </z-button>
        </div>
        <z-image
            class="sulphur__img"
            :src="getImgSrc"></z-image>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'SulphurSection',
    mixins: [mixinDevice],
    data () {
        return {
            lang: this.$root?.app?.page?.lang,
            text: {
                title: localize({
                    ru: 'Серная программа стартует на&nbsp;Надеждинском металлургическом заводе!',
                    en: 'Nornickel inaugurates Sulfur Program at&nbsp;Nadezhda Smelter!'
                }),
                caption: localize({
                    ru: 'Надеждинский металлургический завод сделал важный шаг вперед, который позволит снизить выброс диоксида серы в&nbsp;Норильске на&nbsp;45%.',
                    en: 'Nadezhda Smelter made an&nbsp;important step to&nbsp;reduce sulfur dioxide emissions in&nbsp;Norilsk by&nbsp;more than&nbsp;45%.'
                }),
                button: localize({ ru: 'Серная программа', en: 'The Sulfur Programme' })
            }
        }
    },
    computed: {
        isSulphurPage () {
            return window.location.pathname === '/sustainability/projects/sulphur/'
        },
        getImgSrc () {
            if (this.device === 'mobile') {
                return `/images/sulphur/${this.lang}/bg-mob.png`
            } else {
                return `/images/sulphur/${this.lang}/bg.jpg`
            }
        },
        getBgImgSrc () {
            return `background-image: url('/images/sulphur/${this.lang}/bg.jpg')`
        }
    }
}
</script>

<style lang="scss">
.sulphur {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 70% top;
    padding-top: 144px;
    padding-bottom: 380px;
    @include margin-level(top, 3XL);

    @include breakpoint(laptop) {
        background-position: 72% top;
        padding-bottom: 18vw;
        padding-top: 10vw;
    }

    @include breakpoint(tablet) {
        background-image: none !important;
        padding-top: 0;
        padding-bottom: 0;
    }
     @include breakpoint(mobile) {
        margin-bottom: 0;
     }

    &__img {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            width: 100%;
            padding-top: 10%;

            & .z-image__img {
                aspect-ratio: 2/1;
                object-fit: cover;
                transform: scale(1.3) translate(-10%);
                margin-bottom: 8%;
            }
        }

        @include breakpoint(mobile) {
            padding-top: 0;
            margin-left: -16px;
            margin-right: -16px;
            margin-bottom: 0;
            width: calc(100% + 32px);

            & .z-image__img {
                aspect-ratio: unset;
                transform: none;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 45%;
        max-width: 713px;
        border-radius: 0px 40px 40px 40px;
        @include padding-level(left, XL);
        @include padding-level(right, XL);
        @include padding-level(top, M);
        @include padding-level(bottom, M);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.4);

        @include breakpoint(laptop) {
            width: 36%;
            @include padding-level(left, M);
            @include padding-level(right, M);
        }

        @include breakpoint(tablet) {
            width: 100%;
            max-width: 100%;
            backdrop-filter: unset;
            background-color: transparent;
        }

        @include breakpoint(v-tablet) {
            padding-left: 0;
            padding-right: 0;
        }

        & .z-caption--decor-left:before {
            margin-right: 15px;

            @include breakpoint(v-tablet) {
                display: none;
            }
        }
    }

    &__content-title {
        @include margin-level(bottom, XL);

        @include breakpoint(tablet) {
            @include margin-level(bottom, M);
        }
    }

     &__content-caption {
        @include margin-level(bottom, L, false, true);
    }

    &__content-btn {
        width: fit-content;
    }
}
</style>
