<template>
    <div>
        <div :class="['clean-header', {'clean-header--side-page' : sidePage}]">
            <div class="clean-header__container container">
                <div class="clean-header__top">
                    <z-link href="/clean-norilsk/">
                        <img class="clean-header__logo" src="/images/logos/clean-norilsk.svg" alt="">
                    </z-link>
                    <clean-navigation-mobile :data="data" class="hide-me--default show-me--mobile" />
                    <z-button class="clean-header__button hide-me--default show-me--tablet-flex hide-me--mobile" size="s" tag="a" href="/">Основной сайт
                        <template v-slot:right>
                            <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                        </template>
                    </z-button>
                </div>
                <div class="clean-header__wrapper">
                    <z-button class="clean-header__button hide-me--tablet" size="s" tag="a" href="/">Основной сайт
                        <template v-slot:right>
                            <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                        </template>
                    </z-button>
                    <clean-navigation-main :data="data" class="u-top-margin--s" />
                </div>
            </div>
        </div>
        <div :class="['clean-header__scroll',{'clean-header__scroll--visible' : scroll}]">
            <div class="container">
                <z-link href="/clean-norilsk/">
                    <img class="clean-header__logo" src="/images/logos/clean-norilsk.svg" alt="">
                </z-link>
                <clean-navigation-main :data="data" :sticky="true" class="hide-me--v-tablet" />
                <clean-navigation-mobile :data="data" class="hide-me--default show-me--v-tablet" />
            </div>
        </div>
    </div>
</template>

<script>
import CleanNavigationMain from './CleanNavigationMain.vue'
import CleanNavigationMobile from './CleanNavigationMobile.vue'

export default {
    name: 'clean-footer',
    components: {
        CleanNavigationMain,
        CleanNavigationMobile
    },
    data () {
        return {
            scroll: false,
            data: this.$root.app.components.navigation.top
        }
    },
    methods: {
        handleScroll () {
            let posTop = window.pageYOffset
            this.scroll = posTop > 400
        }
    },
    mounted () {
        window.addEventListener('scroll', () => {
            this.handleScroll()
        })
    },
    props: {
        sidePage: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@mixin scroll-bar {
    *::-webkit-scrollbar {
        width: 10px;
    }

    *::-webkit-scrollbar-track {
        background: $token-colors-blue-20;
        border-radius: 36px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: $token-colors-blue;
        border-radius: 36px;
        border: none;
    }
}

.clean-header {
    $parent: &;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
    scrollbar-color: $token-colors-blue $token-colors-blue-20;

    @include scroll-bar;

    &--side-page {
        position: relative;
        background-color: #012d83;
        transform-style: preserve-3d;

        .container {
            transform: translateZ(2px);
        }

        &:after {
            content: '';
            height: 150px;
            width: 100%;
            background-image: url('/images/clean-norilsk/pseudo-slider-bottom-line.png');
            background-position: top left;
            background-repeat: repeat-x;
            display: block;
            position: absolute;
            bottom: 0;
            background-size: cover;
            transform: translateZ(0);

            @include breakpoint(v-tablet) {
                height: 120px;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }

        &:before {
            content: '';
            height: 32px;
            width: 100%;
            background-image: url('/images/clean-norilsk/banner-bottom-line.png');
            background-position: center left;
            background-repeat: repeat-x;
            display: block;
            position: absolute;
            bottom: -1px;
            background-size: cover;
            transform: translateZ(1px);

            @include breakpoint(v-tablet) {
                bottom: -2px;
                height: 24px;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    &--side-page {
        position: relative;
        background-color: #012d83;
        transform-style: preserve-3d;

        .container {
            transform: translateZ(2px);
        }

        &:after {
            content: '';
            height: 150px;
            width: 100%;
            background-image: url('/images/clean-norilsk/pseudo-slider-bottom-line.png');
            background-position: top left;
            background-repeat: repeat-x;
            display: block;
            position: absolute;
            bottom: 0;
            background-size: cover;
            transform: translateZ(0);

            @include breakpoint(v-tablet) {
                height: 120px;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }

        &:before {
            content: '';
            height: 32px;
            width: 100%;
            background-image: url('/images/clean-norilsk/banner-bottom-line.png');
            background-position: center left;
            background-repeat: repeat-x;
            display: block;
            position: absolute;
            bottom: -1px;
            background-size: contain;
            transform: translateZ(1px);

            @include breakpoint(tablet) {
                background-size: cover;
            }

            @include breakpoint(v-tablet) {
                bottom: -2px;
                height: 24px;
            }

            @include breakpoint(mobile) {
                display: none;
            }
        }
    }

    @media screen and (min-width: #{$breakpoint-mobile}) {
        @include padding-level(top, L);
        @include padding-level(bottom, 3XL);
    }

    &__scroll {
        position: fixed;
        background: #002D83;
        top: 0;
        width: 100%;
        opacity: 0;
        overflow: hidden;
        margin-top: -1000px;
        transition: all 0.3s;
        height: 80px;
        display: flex;
        align-items: center;
        z-index: 5;

        @include scroll-bar;

        #{$parent}__logo {
            max-width: 414px;

            @include breakpoint (tablet) {
                max-width: 331px;
            }

            @include breakpoint (v-tablet) {
                max-width: 248px;
            }
        }

        &--visible {
            opacity: 1;
            overflow: visible;
            margin-top: 0;
        }

        > .container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .clean-navigation-main__list {
            justify-content: space-between;
            margin: 0 0 0 auto;
            max-width: 703px;
            gap: 0;

            @include breakpoint (laptop) {
                max-width: 628px;
            }

            @include breakpoint (tablet) {
                max-width: 550px;
            }
        }

        .clean-navigation-main__link > span {
            @include breakpoint (tablet) {
                font-size: 20px;
            }
        }

        @include breakpoint (v-tablet) {
            height: 52px;
        }

        @include breakpoint (mobile) {
            height: 50px;
        }
    }

    &__logo {
        max-width: 660px;

        @include breakpoint (laptop) {
            max-width: 500px;
        }

        @include breakpoint (tablet) {
            max-width: 250px;
            margin-right: $token-spacers-2-xs;
        }

        @include breakpoint (mobile) {
            max-width: 248px;
            margin-right: $token-spacers-3-xs;
        }
    }

    &__container {
        display: flex;

        @include breakpoint (tablet) {
            display: block;
        }

        @include breakpoint (v-tablet) {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        @include breakpoint (mobile) {
            justify-content: space-between;
            background-color: $token-colors-navy-blue;
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex-grow: 1;

        @include breakpoint (mobile) {
            display: none;
        }
    }

    &__top {
        display: flex;
        align-items: flex-end;

        @include breakpoint (mobile) {
            align-items: center;
            justify-content: space-between;
        }
    }

    &__button {
        border-color: $token-colors-button-bordered-light-default;
        margin-left: auto;
        font-weight: 600;

        &:hover {
            border-color: $token-colors-white;
            color: $token-colors-white !important;

            .z-icon path {
                fill: $token-colors-white !important;
            }
        }
    }

}
</style>
