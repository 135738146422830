<template>
    <section>
        <manage v-if="checkComponent(['edit'])" @change-component="changeComponentHandler"/>
        <subscription v-if="checkComponent(['edit-full', 'subscribe'])"
                      :is-edit="checkComponent(['edit-full'])"
                      @change-component="changeComponentHandler"/>
        <hoc-notification v-if="checkNotification" :type="currentComponent" :email="email"/>
    </section>
</template>

<script>
import Subscription from './components/Subscription.vue'
import HocNotification from './components/HocNotification.vue'
import Manage from './components/Manage.vue'

export default {
    name: 'subscribe-page',
    components: {HocNotification, Subscription, Manage},
    props: {
        showEdit: {
            type: Boolean,
            default: false
        },
        showConfirm: {
            type: Boolean,
            default: false
        },
        showUnsubscribe: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            email: '',
            status: '',
            rubrics: this.$root.app?.components['subscribe-rubrics'] || [],
            confirmCode: this.$root.app?.system?.params?.get['CONFIRM_CODE'],
            id: this.$root.app?.system?.params?.get['ID'],
            currentComponent: '',
            components: ['edit-full', 'edit', 'subscribe'],
            notifications: ['subscribe-message', 'confirm', 'edit-message', 'unsubscribe', 'edited']
        }
    },
    mounted () {
        this.initCurrentComponent()
    },
    computed: {
        checkNotification () {
            return this.notifications.includes(this.currentComponent)
        }
    },
    methods: {
        initCurrentComponent () {
            if (this.showEdit) {
                if (this.rubrics.length && this.confirmCode && this.id) {
                    this.currentComponent = 'edit-full'
                } else {
                    this.currentComponent = 'edit'
                }
                return
            }
            if (this.showConfirm) {
                this.currentComponent = 'confirm'
                return
            }
            if (this.showUnsubscribe) {
                this.currentComponent = 'unsubscribe'
                return
            }
            this.currentComponent = 'subscribe'
        },
        checkComponent (scopes) {
            return scopes.includes(this.currentComponent)
        },
        changeComponentHandler (data) {
            this.currentComponent = data.component
            if (data.email) this.email = data.email
        }
    }
}
</script>

<style lang="scss">
</style>
