/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/mp4': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.318 4.318A4.5 4.5 0 0112.5 3h20a.5.5 0 01.354.146l8 8A.5.5 0 0141 11.5v28a4.5 4.5 0 01-4.5 4.5h-24A4.5 4.5 0 018 39.5v-32a4.5 4.5 0 011.318-3.182zM33 4.708l6.793 6.792H33V4.707zM32 12V4H12.5A3.5 3.5 0 009 7.5v32a3.5 3.5 0 003.5 3.5h24a3.5 3.5 0 003.5-3.5v-27h-7.5a.5.5 0 01-.5-.5zM21.027 22.996V31h-1.404v-5.94L17.163 31h-.6l-2.448-5.94V31h-1.404v-8.004h1.98l2.172 5.268 2.184-5.268h1.98zm3.108 5.016V31H22.73v-8.004h3.516c1.68 0 2.616 1.152 2.616 2.508s-.948 2.508-2.616 2.508h-2.112zm0-1.236h1.92c.792 0 1.368-.504 1.368-1.272s-.576-1.272-1.368-1.272h-1.92v2.544zm10.6 2.412V31h-1.403v-1.812h-3.816V28.06l3.288-5.064h1.932v4.956h1.068v1.236h-1.068zm-3.84-1.236h2.437v-3.696l-2.436 3.696z" _fill="#737B91"/>'
  }
})
