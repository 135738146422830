/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rss': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_9335_4125)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.25 7.5c0-.69.56-1.25 1.25-1.25a16.552 16.552 0 0116.552 16.552 1.25 1.25 0 01-2.5 0A14.052 14.052 0 009.5 8.75c-.69 0-1.25-.56-1.25-1.25zm1.25 3.342a1.25 1.25 0 100 2.5 9.462 9.462 0 019.462 9.461 1.25 1.25 0 002.5 0A11.962 11.962 0 009.5 10.842zm0 4.59a1.25 1.25 0 000 2.5 4.87 4.87 0 014.871 4.87 1.25 1.25 0 102.5 0 7.371 7.371 0 00-7.371-7.37zm1.53 6.989a1.148 1.148 0 11-2.295 0 1.148 1.148 0 012.295 0z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9335_4125"><path pid="1" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
