/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file-big/pdf': {
    width: 148,
    height: 168,
    viewBox: '0 0 148 168',
    data: '<g filter="url(#filter0_dd_9226_31971)"><path pid="0" d="M24 43.519C24 28.32 36.32 16 51.519 16H124v92.481C124 123.679 111.679 136 96.481 136H24V43.519z" _fill="#fff"/></g><path pid="1" d="M57.898 82h-1.494V69.994h4.824c2.412 0 3.762 1.656 3.762 3.618s-1.386 3.618-3.762 3.618h-3.33V82zm3.15-6.102c1.422 0 2.394-.936 2.394-2.286s-.972-2.286-2.394-2.286h-3.15v4.572h3.15zM72.873 82h-4.105V69.994h4.105c3.725 0 6.155 2.61 6.155 6.012 0 3.42-2.43 5.994-6.156 5.994zm0-1.332c2.915 0 4.608-2.088 4.608-4.662 0-2.592-1.638-4.68-4.608-4.68h-2.61v9.342h2.61zM84.666 82h-1.494V69.994h7.866v1.332h-6.372v3.87h6.246v1.332h-6.246V82z" _fill="#0077C8"/><defs><filter id="filter0_dd_9226_31971" x="0" y="0" width="148" height="168" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="8"/><feGaussianBlur stdDeviation="12"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.12 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_9226_31971"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0.12549 0 0 0 0 0.2 0 0 0 0.04 0"/><feBlend in2="effect1_dropShadow_9226_31971" result="effect2_dropShadow_9226_31971"/><feBlend in="SourceGraphic" in2="effect2_dropShadow_9226_31971" result="shape"/></filter></defs>'
  }
})
