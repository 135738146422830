<template>
    <label
        :for="name"
        class="z-textarea"
        :class="{
            'is-error': error,
            'is-filled': val,
            'is-focused': focus,
            'is-resizable': resize,
            'is-disabled': disabled
        }"
    >
        <p class="z-textarea__title" v-if="title" v-html="title"></p>
        <textarea
            :id="name"
            :name="name"
            :disabled="disabled"
            v-bind="$attrs"
            @blur="onBlur"
            @focus="focus = true"
            @input="onInput($event)"
            @change="onChange($event)"
            v-model="textareaValue"
            cols="30"
            rows="10"
        ></textarea>
        <span
            v-if="placeholder && !focus && !val"
            v-html="placeholder"
            class="z-textarea__placeholder"
        ></span>
        <span class="z-textarea__error" v-if="!isValid && error">{{ error }}</span>
    </label>
</template>

<script>
import { localize } from '@/utils/i18n.js'

export default {
    name: 'z-textarea',
    props: {
        title: String,
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        resize: {
            type: Boolean,
            default: false
        },
        validateOnBlur: {
            type: Boolean,
            default: false
        },
        required: Boolean,
        placeholder: String,
        value: [Number, String]
    },
    data () {
        return {
            val: this.value,
            localValue: null,
            error: false,
            focus: false,
            isValid: null,
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field'
                }
            }
        }
    },
    computed: {
        textareaValue: {
            get () {
                if (this.value !== undefined) return this.value // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.localValue = value
            }
        }
    },
    mounted () {
        this.$root.$bus.$on('clear', (name) => {
            if (this.name === name) this.change('')
        })
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', (name) => {
            if (this.name === name) this.change('')
        })
    },
    methods: {
        onInput (e) {
            this.$emit('input', e.target.value)
            this.$nextTick(this.validate)
        },
        onBlur () {
            this.focus = false
            if (this.validateOnBlur) this.validate()
        },
        onChange (e) {
            this.$emit('change', e.target.value)
        },
        validate () {
            if (this.required && !this.textareaValue) {
                this.isValid = false
                this.error = localize(this.text.error)
                return
            }

            this.textareaValue ? (this.isValid = true) : (this.isValid = null)
            this.error = ''
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
