function clearEmptyParams (data) {
    let params = Object.assign({}, data)

    Object.keys(params).forEach(function (key, index) {
        if (Array.isArray(params[key])) {
            if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
        } else if (!params[key]) {
            delete params[key]
        }
    })

    return params
}

export { clearEmptyParams }
