<template>
    <div class="tl-key">
        <tl-line :month="month">
            <div class="tl-key__head" v-if="head">
                <img src="/images/clean-norilsk/tl-key-znak.svg" />
                <div class="tl-key__title">
                    {{ title ? title : 'Итоги' }}
                </div>
                <div class="tl-key__year">
                    {{ year ? year : '2022' }}
                </div>
            </div>
            <div class="tl-key__slot">
                <div class="tl-key__text" v-html="text" v-if="text"></div>
                <slot />
            </div>
            <tl-link :href="href" v-if="href" />
        </tl-line>
    </div>
</template>

<script>
import TlLine from './TlLine.vue'
import TlLink from './TlLink.vue'

export default {
    name: 'tl-key',
    components: {
        TlLink,
        TlLine
    },
    props: {
        year: [String, Number],
        title: String,
        text: String,
        month: String,
        href: String,
        head: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss">
.tl-key {
    max-width: 794px;
    width: 100%;

    @include breakpoint (v-tablet) {
        width: 534px;
    }

    @include breakpoint (mobile) {
        width: 288px;
    }

    &--max-content {
        max-width: max-content;
    }

    &__slot {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-m;

        @include breakpoint (v-tablet) {
            width: 100%;
            gap: $token-spacers-s;
        }

        @include breakpoint (mobile) {
            gap: $token-spacers-xs;
        }
    }

    &__text {
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        color: $token-colors-black;
        @include breakpoint (v-tablet) {
            font-size: 20px;
        }
    }

    &__head {
        position: relative;
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: $token-spacers-m;
        height: 80px;

        @include breakpoint (v-tablet) {
            height: 55px;
            margin-bottom: $token-spacers-s;
        }

        @include breakpoint (mobile) {
            margin-bottom: $token-spacers-xs;
        }

        > img {
            display: block;
            position: relative;
        }
    }

    &__title {
        font-family: 'Proxima Nova Condensed';
        font-weight: 400;
        font-size: 48px;
        line-height: 100%;
        letter-spacing: -0.03em;
        background: linear-gradient(180deg, #0290F0 0%, #004C97 100%), #FFFFFF;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        position: relative;
        z-index: 1;

        @include breakpoint (v-tablet) {
            font-size: 32px;
        }

        @include breakpoint (mobile) {
            font-size: 24px;
        }
    }

    &__year {
        font-family: 'Proxima Nova Extra Condensed';
        font-weight: 250;
        font-size: 120px;
        line-height: 0.75;
        letter-spacing: -9px;
        color: $token-colors-blue-30;
        left: 60px;
        position: absolute;

        @include breakpoint (v-tablet) {
            font-size: 100px;
        }

        @include breakpoint (mobile) {
            font-size: 80px;
        }
    }

    .tl-line__slot {
        align-items: center;

        @include breakpoint (v-tablet) {
            align-items: flex-start;
        }
    }

}
</style>
