<template>
    <div class="glossary-page container">
        <div class="search u-bottom-margin--s">
            <z-input
                name="input2"
                :placeholder="text.search"
                @input="input($event)"
                v-model="search"
                class="search__input"
                icon-name="magnifier"
                :clearable="true"
                @clear="clearInput()"
            ></z-input>
        </div>

        <div class="u-bottom-margin--s">
            <row-filter
                :filters="preparedFilters"
                @change="selectItem"
                :default="this.selectedItem"
            >
            </row-filter>
        </div>
        <content-container>
            <glossary @not-found="selectAll()" :coming-data='comingData' :selected-item="selectedItem" :search-row="search"/>
        </content-container>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import Glossary from './components/Glossary'
import {defaultFilters, russianFilters} from './filters'

export default {
    name: 'glossary-page',
    components: {
        glossary: Glossary
    },
    data () {
        return {
            selectedItem: 'all',
            text: {
                title: localize({
                    ru: 'Глоссарий',
                    en: 'Glossary'
                }),
                search: localize({
                    ru: 'Введите ключевое слово',
                    en: 'Enter a keywords'
                }),
                searchBtn: localize({
                    ru: 'Искать',
                    en: 'Search'
                })
            },
            search: '',
            comingData: [],
            defaultFilters,
            russianFilters
        }
    },
    methods: {
        selectItem (item) {
            this.clearInput()
            this.select(item)
        },
        isDisable (filter) {
            if (filter.id === 'all') {
                return false
            }

            const arrayOfFirstLetters = this.comingData.map((el) => {
                return el.name[0].toLowerCase()
            })
            const letters = filter.id.split('-')
            const result = arrayOfFirstLetters.reduce((summ, cur) => {
                if (cur <= letters[1] && cur >= letters[0]) {
                    return summ + 1
                }
                return summ
            }, 0)
            return !result
        },
        input (value) {
            if (value === '') {
                this.selectAll()
            } else {
                this.selectedItem = null
            }
        },
        selectAll () {
            this.selectedItem = 'all'
        },
        select (item) {
            this.selectedItem = item
        },
        clearInput () {
            this.search = ''
            this.selectAll()
        },
        checkHash () {
            const hash = window.location.hash
            if (!(hash && document.querySelector(hash))) return
            document.querySelector(hash).scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        }
    },
    computed: {
        preparedFilters () {
            let filters = this.lang === 'ru' ? [...this.defaultFilters, ...this.russianFilters] : this.defaultFilters
            return filters.map((el) => {
                el.disabled = this.isDisable(el)
                return el
            })
        },
        lang () {
            return this.$root.app.page.lang
        }
    },
    mounted () {
        this.comingData = window.App.components['glossary-page']
        this.$nextTick(this.checkHash)
    },
    created () {
        if ('scrollRestoration' in history) {
            if (history.scrollRestoration !== 'manual') history.scrollRestoration = 'manual'
        }
    },
    beforeDestroy () {
        if ('scrollRestoration' in history) {
            if (history.scrollRestoration !== 'auto') history.scrollRestoration = 'auto'
        }
    }
}
</script>

<style lang="scss">
.glossary-page {
    .search {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        &__input {
            flex: 1 1 auto;

            @media screen and (max-width: $breakpoint-mobile){
                margin-bottom: 16px;
            }
        }

        &__button {
            flex: 0 1 auto;
        }

        @media screen and (max-width: $breakpoint-mobile){
            flex-direction: column;
        }
    }
}
</style>
