/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-jpg': {
    width: 34,
    height: 22,
    viewBox: '0 0 34 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 15.546c.828.972 1.854 1.386 3.132 1.386 2.142 0 3.816-1.224 3.816-3.834V4.71H5.454v8.37c0 1.494-.936 2.52-2.412 2.52-1.044 0-1.746-.558-2.268-1.188L0 15.546zm9.749 1.17h1.494v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618s-1.35-3.618-3.762-3.618H9.749v12.006zm7.038-8.388c0 1.35-.972 2.286-2.394 2.286h-3.15V6.042h3.15c1.422 0 2.394.936 2.394 2.286zm3.04 2.394c0 3.726 2.754 6.228 6.174 6.228 1.962 0 3.6-.828 4.716-2.07v-4.338h-5.616v1.332h4.122v2.448c-.594.594-1.782 1.296-3.222 1.296-2.628 0-4.626-2.052-4.626-4.896 0-2.862 1.998-4.878 4.626-4.878 1.44 0 2.718.684 3.474 1.656l1.188-.738c-1.062-1.314-2.538-2.25-4.662-2.25-3.42 0-6.174 2.484-6.174 6.21z" _fill="#0077C8"/>'
  }
})
