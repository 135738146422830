<template>
    <div
        class="tools u-bottom-padding--m u-top-padding--m u-left-padding--2xl u-right-padding--2xl"
        :class="pageToolClass"
    >
        <div class="tools__list">
            <div
                class="tools__item"
                v-for="(item, idx) in dataItems"
                :key="item.title"
            >
                <a
                    :href="isAnchor ? item.id : false"
                    class="tools__tool"
                    :class="{'active': item.active}"
                    @click="isTab ? emit(item.id) : false"
                >
                    <z-icon
                        class="tools__icon u-right-margin--xs"
                        :name="item.icon"
                        :width="iconSize"
                        :height="iconSize"
                    />
                    <div class="tools__text" v-if="isAnchor">
                        <div class="tools__num blue-50-text u-bold">0{{idx+1}}</div>
                        <p class="tools__value text-size-2xl u-bold black-text" v-html="item.title"></p>
                    </div>
                    <div class="tools__text" v-else>
                        <p class="tools__value text-size-m" v-html="item.title"></p>
                    </div>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'page-tool',
    data () {
        return {
            pageToolClass: null,
            dataItems: this.items
        }
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        defaultValue: {
            type: [Number, String],
            default: 1
        },
        type: {
            type: String,
            validator: (prop) => ['anchor', 'tab'].includes(prop)
        },
        iconSize: {
            type: String
        }
    },
    methods: {
        changeActive (id) {
            this.dataItems = this.dataItems.map((el) => {
                if (el.id === id) {
                    el.active = true
                    return el
                } else {
                    el.active = false
                    return el
                }
            })
        },
        emit (id) {
            this.changeActive(id)
            this.$emit('change', id)
        }
    },
    computed: {
        isAnchor () {
            return this.type === 'anchor'
        },
        isTab () {
            return this.type === 'tab'
        }
    },
    mounted () {
        switch (this.type) {
        case 'anchor':
            this.pageToolClass = 'tools--anchors'
            break
        case 'tab':
            this.pageToolClass = 'tools--tabs'
            break
        }

        this.dataItems[2].active = true

        this.changeActive(this.defaultValue)
    }

}
</script>

<style lang="scss" scoped>
.tools {
    $parrent: &;

    border-radius: 0 $boder-radius-m $boder-radius-m $boder-radius-m;
    background: $token-colors-gray-10;
    overflow: hidden;

    @include breakpoint (mobile) {
        padding: 24px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: -$token-spacers-xs;
        margin-right: -$token-spacers-s;

        @include breakpoint (tablet) {
            justify-content: start;
            flex-wrap: wrap;
        }
    }

    &__icon {
        align-self: center;
    }

    &__item {
        display: flex;
        align-items: center;
        flex-shrink: 1;
        margin-top: $token-spacers-xs;
        padding-right: $token-spacers-s;

        @include breakpoint (v-tablet) {
            width: 50%;
        }

        @include breakpoint (mobile) {
            width: 100%;
        }
    }

    &__item:last-child {
        padding-right: 0;
    }

    #{$parrent}__tool {
        display: flex;
        text-decoration: none;
    }

    &--anchors {
        #{$parrent}__tool {
            align-items: end;

            &__num {
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
            }
        }
    }

    &--tabs {
        #{$parrent}__tool {
            align-items: center;
            cursor: pointer;

            &.active {
                #{$parrent}__value {
                    font-weight: 600;
                    color: $token-colors-black;
                }

                #{$parrent}__icon {
                    color: #fff;
                    opacity: 1;
                }
            }
        }

        #{$parrent}__value {
            color: $token-colors-gray-50;
        }

        #{$parrent}__icon {
            color: #fff;
            opacity: .3;
        }
    }
}
</style>
