import { render, staticRenderFns } from "./RealtyPage.vue?vue&type=template&id=1b305ac8&"
import script from "./RealtyPage.vue?vue&type=script&lang=js&"
export * from "./RealtyPage.vue?vue&type=script&lang=js&"
import style0 from "./RealtyPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports