<template>
    <div v-if="searchRow">
        <div class="current-letter__word" v-for="word in searchedWords" :key="word.name">
            <description :title="word.name" :value="colorize(word.previewText)"/>
        </div>
        <p v-if="searchedWords.length === 0">
            {{notFound}}
        </p>
    </div>

    <div v-else class="letter-wrapper">
        <current-letter v-for="letter in lettersSorted" :key="letter" :letter="letter" :descriptions="letterDescriotions(letter)"/>
    </div>
</template>

<script>
import CurrentLetter from './CurrentLetter.vue'
import Description from './WordDescr'
import { localize } from '@/utils/i18n'

export default {
    name: 'Glossary',
    components: {
        'current-letter': CurrentLetter,
        'description': Description
    },
    props: {
        comingData: Array,
        selectedItem: String,
        searchRow: String
    },
    data () {
        return {
            searchValue: '',
            filteredData: this.comingData || [],
            letters: [],
            searchedWords: [],
            bacup: [],
            notFound: localize({
                ru: 'Извините, ничего не найдено',
                en: 'Sorry, but nothing found'
            })
        }
    },
    computed: {
        lettersSorted () {
            const letters = this.letters
            return letters.sort()
        }
    },
    methods: {
        colorize (description) {
            const index = description.toLowerCase().indexOf(this.searchRow.toLowerCase())
            let descriptionArray = description.split('')

            descriptionArray.splice(index, 0, '<b>')
            descriptionArray.splice(index + this.searchRow.length + 1, 0, '</b>')

            return descriptionArray.join('')
        },
        devidedLetters () {
            const letterArray = []
            this.filteredData.map((el) => {
                if (letterArray.includes(el.name.slice('')[0])) {
                    return
                }
                letterArray.push(el.name.slice('')[0])
            })
            this.letters = letterArray
        },
        letterDescriotions (letter) {
            const data = this.filteredData.filter(title => {
                return title.name.split('')[0] === letter
            })
            return data
        },
        slicecomingData () {
            this.filteredData = this.comingData.filter((el) => {
                if (this.selectedItem === 'all') {
                    return 1
                }

                const letters = this.selectedItem.split('-')
                const comingDataWordFirstLetter = el.name.split('')[0].toLowerCase()

                if (comingDataWordFirstLetter <= letters[1] && comingDataWordFirstLetter >= letters[0]) {
                    return 1
                } else {
                    return 0
                }
            })

            this.devidedLetters()
        }
    },
    watch: {
        selectedItem: {
            handler (value) {
                if (value) {
                    this.slicecomingData()
                }
            },
            deep: true
        },
        comingData: {
            handler () {
                this.slicecomingData()
            },
            deep: true
        },
        searchRow (value) {
            function filteredArray (array) {
                return array.filter(glossaryItem => {
                    let inWord = glossaryItem.name.toLowerCase().startsWith(value.toLowerCase())
                    const index = glossaryItem.previewText.toLowerCase().indexOf(value.toLowerCase())
                    let inDescription = index + 1

                    return inWord || inDescription
                })
            }

            let filtered = filteredArray(this.filteredData)

            if (filtered.length === 0) {
                this.$emit('not-found')

                filtered = filteredArray(this.comingData)
            }

            this.searchedWords = filtered
        }
    },
    mounted () {
        this.slicecomingData()
    }
}
</script>

<style>

</style>
