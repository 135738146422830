<template>
    <div class="circle-timer">
        <svg class="circle-timer__box" width="140" height="140" viewBox="0 0 140 140" fill="none">
            <circle cx="70" cy="70" r="67.5" ref="circle"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'circle-timer',
    props: {
        duration: {
            type: Number,
            default: 6000
        }
    },
    mounted () {
        this.$refs.circle.style.animationDuration = `${this.duration}ms`
    }
}
</script>

<style lang="scss">
.circle-timer {
    position: relative;

    &__box {
        transform: rotateY(-180deg) rotateZ(-90deg);
    }

    circle {
        fill: none;
        stroke: #ffffff;
        stroke-width: 5px;
        stroke-dasharray: 424.115px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        animation-name: countdown;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    @keyframes countdown {
        from {
            stroke-dashoffset: 0px;
        }
        to {
            stroke-dashoffset: 424.115px;
        }
    }
}
</style>
