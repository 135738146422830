<template>
    <section class="register-dishonest">
        <card-link class="u-bottom-margin--m" link="/suppliers/warning/" theme="warning" image="/images/spy.svg">
            Предупреждение о&nbsp;подложных обращениях от&nbsp;лица компании
        </card-link>
        <z-caption tag="h2">Список контрагентов*</z-caption>
        <z-input class="u-bottom-margin--s u-top-margin--s" v-model="search" name="search"
                 placeholder="Введите Наименование или ИНН компании" icon-name="magnifier"/>
        <div class="u-bottom-margin--s register-dishonest__content">
                <z-table ref="tableComp" v-if="altData.length" :columns="['Наименование', 'ИНН', 'Основание', 'Дата']" responsive>
                    <template v-for="(item, index) in altData">
                        <z-table-row :is-hidden="index > 14" :key="`table-item-${index}`">
                            <z-table-col index="0">{{ item.name }}</z-table-col>
                            <z-table-col index="1">{{ item.inn }}</z-table-col>
                            <z-table-col index="2">
                                <z-tooltip :content="item.reasonText">
                                    <span>{{ item.reason }}</span>
                                </z-tooltip>
                            </z-table-col>
                            <z-table-col index="3">{{ item.date }}</z-table-col>
                        </z-table-row>
                    </template>
                </z-table>
                <z-not-found v-else/>
        </div>
        <z-accordion class="u-bottom-margin--s">
            <z-accordion-item id="1">
                <template #header>
                    Основания включения в&nbsp;реестр:
                </template>
                <template #body>
                    <z-list tag="ol">
                        <z-list-item>
                            <p class="u-bottom-margin--2xs">Уклонение победителя закупки от&nbsp;заключения
                                договора с&nbsp;ПАО &laquo;ГМК &laquo;Норильский
                                никель&raquo; или российской организацией корпоративной
                                структуры, входящей в&nbsp;Группу компаний &laquo;Норильский
                                никель&raquo;, выражающееся, включая, но&nbsp;не&nbsp;ограничиваясь,
                                в&nbsp;следующем:
                            </p>
                            <ul>
                                <li>
                                    a) прямом письменном отказе от&nbsp;подписания договора на&nbsp;условиях,
                                    согласованных
                                    участником закупки в&nbsp;процессе ее&nbsp;проведения;
                                </li>
                                <li>
                                    б) неподписании договора (на&nbsp;условиях, согласованных участником закупки в&nbsp;процессе
                                    ее&nbsp;проведения) в&nbsp;течение срока, указанного в&nbsp;направленном уведомлении
                                    или ином документе о&nbsp;необходимости подписания договора;
                                </li>
                                <li>
                                    в) предъявлении при подписании договора требований о&nbsp;внесении изменений в&nbsp;условия
                                    договора, по&nbsp;которым в&nbsp;процессе проведения закупки участник давал свое
                                    согласие;
                                </li>
                                <li>
                                    г) непредставлении документов, обязательных к&nbsp;предоставлению до&nbsp;заключения
                                    договора и&nbsp;предусмотренных закупочной документацией&nbsp;и (или) в&nbsp;заявке
                                    такого участника.
                                </li>
                            </ul>
                        </z-list-item>
                        <z-list-item>
                            Предоставление заведомо недостоверных сведений для участия в&nbsp;закупочных процедурах
                            ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или российской организации корпоративной
                            структуры, входящей в&nbsp;Группу компаний &laquo;Норильский никель&raquo;.
                        </z-list-item>
                        <z-list-item>
                            Нарушение условий договора, повлекшее за&nbsp;собой причинение убытков, начисление
                            неустойки либо возникновение иных требований ПАО &laquo;ГМК &laquo;Норильский никель&raquo;
                            или российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский
                            никель&raquo;, к&nbsp;контрагенту, не&nbsp;урегулированных во&nbsp;внесудебном порядке,
                            установленном действующим законодательством либо договором.
                        </z-list-item>
                        <z-list-item>
                            Нарушение Контрагентом требований в&nbsp;области промышленной безопасности и&nbsp;охраны труда и&nbsp;охраны окружающей среды при выполнении работ/оказании услуг на&nbsp;территории Компании/РОКС НН, повлекшее тяжкие последствия (несчастный случай на&nbsp;производстве, авария, катастрофа, инцидент, пожар) или заведомо создавшее реальную угрозу наступления таких последствий, либо повлекшее вред имуществу Компании/РОКС НН&nbsp;на&nbsp;сумму более 5&nbsp;млн руб., либо неоднократные нарушения Контрагентом требований в&nbsp;области промышленной безопасности и&nbsp;охраны труда и&nbsp;охраны окружающей среды при выполнении работ/оказании услуг на&nbsp;территории Компании/РОКС НН.
                        </z-list-item>
                        <z-list-item>
                            Разглашение полученной от&nbsp;ПАО &laquo;ГМК &laquo;Норильский никель&raquo; или
                            российской организации корпоративной структуры, входящей в&nbsp;Группу компаний &laquo;Норильский
                            никель&raquo;, конфиденциальной информации в&nbsp;нарушение закона или соответствующего
                            соглашения.
                        </z-list-item>
                    </z-list>
                </template>
            </z-accordion-item>
        </z-accordion>
        <z-card theme="grey">
            <template #content>
                * Информация, содержащаяся в&nbsp;реестре, не&nbsp;используется при принятии решений о&nbsp;заключении
                договоров, заключаемых по&nbsp;результатам закупочных процедур, проводимых в&nbsp;соответствии с&nbsp;Федеральным
                законом &laquo;О&nbsp;закупках товаров, работ, услуг отдельными видами юридических лиц&raquo; от&nbsp;18.07.2011
                &#8470;&nbsp;223-ФЗ, и&nbsp;доходных договоров, заключение которых для ПАО &laquo;ГМК &laquo;Норильский
                никель&raquo; и&nbsp;организаций, входящих в&nbsp;его группу лиц, является обязательным в&nbsp;соответствии
                с&nbsp;требованиями действующего законодательства&nbsp;РФ.
            </template>
        </z-card>
    </section>
</template>

<script>
import { staticData } from './staticData'
import {debounce} from 'throttle-debounce'
export default {
    name: 'register-dishonest',
    data () {
        return {
            search: '',
            data: staticData,
            altData: staticData
        }
    },
    watch: {
        search () {
            if (this.search === '') {
                this.altData = this.data
                this.$nextTick(() => {
                    if (this.$refs.tableComp) this.$refs.tableComp.updateHiddenRows()
                })
                return
            }
            this.filterItems()
        }
    },
    methods: {
        filterItems: debounce(500, function () {
            this.altData = this.data.slice().filter((item) => {
                const itemName = item.name.toLowerCase()
                const itemInn = item.inn.toString().toLowerCase()
                return itemInn.includes(this.search.toLowerCase()) || itemName.includes(this.search.toLowerCase())
            })
            this.$nextTick(() => {
                if (this.$refs.tableComp) this.$refs.tableComp.updateHiddenRows()
            })
        })
    }
}
</script>

<style lang="scss">
.register-dishonest {
    ul li {
        margin-bottom: $token-spacers-3-xs;
    }

    li ul {
        @include padding-level(left, M);
    }

    &__content {
        display: flex;
        justify-content: center;
    }

    .z-table-col:first-child {
        width: 50%;
    }

    .z-input.is-focused .z-input__placeholder {
        transform: translateY(-3.1em);
    }
    .z-input.is-valid .z-input__placeholder {
        transform: translateY(-3.1em);
    }
}
</style>
