<template>
    <div class="property-complexes-table">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'PropertyComplexesTable'
}
</script>

<style lang="scss">
.property-complexes-table {
    display: flex;
    gap: $token-spacers-xs;
    font-family: $robotoMono;

    @include breakpoint(tablet) {
        gap: $token-spacers-2-xs;
    }

    @include breakpoint(v-tablet) {
        flex-direction: column;
        gap: $token-spacers-xs;
    }
}
</style>
