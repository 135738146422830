/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message': {
    width: 42,
    height: 42,
    viewBox: '0 0 42 42',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11 9.9h-.043c-.906 0-1.647 0-2.232.079-.61.082-1.14.26-1.564.683-.423.423-.6.953-.683 1.564-.078.585-.078 1.325-.078 2.231V27.543c0 .907 0 1.647.078 2.232.083.611.26 1.14.683 1.564.423.423.953.6 1.564.683.585.078 1.326.078 2.232.078h20.086c.906 0 1.647 0 2.231-.078.612-.082 1.141-.26 1.564-.683.423-.423.601-.953.683-1.564.079-.585.079-1.325.079-2.232V14.457c0-.906 0-1.646-.079-2.231-.082-.611-.26-1.141-.683-1.564-.423-.423-.952-.6-1.564-.683C32.69 9.9 31.95 9.9 31.043 9.9H11zm-2.99 1.61c.163-.162.397-.277.875-.342.496-.066 1.155-.068 2.115-.068h20c.96 0 1.62.002 2.114.068.479.065.713.18.876.342.163.163.278.398.342.876.067.495.068 1.155.068 2.114v.88l-12.774 6.386a1.4 1.4 0 01-1.252 0L7.6 15.38v-.88c0-.96.001-1.619.068-2.114.064-.478.18-.713.342-.876zm-.41 5.211v10.78c0 .96.001 1.619.068 2.114.064.478.18.713.342.875.163.163.397.278.875.343.496.066 1.155.067 2.115.067h20c.96 0 1.62 0 2.114-.067.479-.065.713-.18.876-.343.163-.162.278-.397.342-.875.067-.495.068-1.155.068-2.115V16.721L22.163 22.84a2.6 2.6 0 01-2.326 0L7.6 16.72z" _fill="#0077C8"/>'
  }
})
