/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ship': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M16.8 12V6.094a1.2 1.2 0 011.2-1.2v.002h12a1.2 1.2 0 011.2 1.2V12m-14.4 0h-6a1.2 1.2 0 00-1.2 1.2v9.751l-3.922 1.092a1.199 1.199 0 00-.763 1.668l.43.914M16.8 12h14.4m0 0h6a1.2 1.2 0 011.2 1.2v9.751l3.924 1.092a1.199 1.199 0 01.76 1.678l-.435.904m-37.304 0L9.24 34.9m-3.895-8.276l2.344-.65 15.015-4.185a4.798 4.798 0 012.577 0l17.368 4.835m-3.944 8.187l3.944-8.187" _stroke="#0077C8" stroke-width="1.5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24.605 39.266l-.625-.892-.61.901a9.154 9.154 0 01-.452.61C21.9 41.138 20.288 42.45 18 42.45c-2.29 0-3.906-1.312-4.923-2.566l-.003-.004a8.398 8.398 0 01-.366-.478l-.65-.918-.598.953a7.263 7.263 0 01-.302.436C10.196 41.154 8.573 42.45 6 42.45v1.5c2.958 0 4.924-1.42 6.12-2.873 1.186 1.373 3.123 2.873 5.88 2.873 2.85 0 4.82-1.604 5.993-3.012 1.18 1.408 3.155 3.012 6.007 3.012 2.747 0 4.68-1.49 5.868-2.859 1.199 1.468 3.174 2.894 6.155 2.859l-.017-1.5c-2.585.03-4.21-1.267-5.173-2.56a7.7 7.7 0 01-.305-.444l-.596-.942-.648.907a8.29 8.29 0 01-.356.467l-.005.006C33.906 41.138 32.289 42.45 30 42.45c-2.288 0-3.906-1.309-4.93-2.564a11.485 11.485 0 01-.465-.62z" _fill="#0077C8"/>'
  }
})
