/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Pd': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32282)"/><path pid="2" d="M26.077 40h-2.925V23.325h7.325c3.5 0 5.45 2.4 5.45 5.225 0 2.825-1.975 5.225-5.45 5.225h-4.4V40zm4-8.8c1.65 0 2.85-1.05 2.85-2.65 0-1.6-1.2-2.65-2.85-2.65h-4v5.3h4zM48.921 40h-2.625v-1.65c-.925 1.2-2.325 1.95-3.875 1.95-3.1 0-5.375-2.35-5.375-6.325 0-3.9 2.25-6.35 5.375-6.35 1.5 0 2.9.7 3.875 1.975v-6.275h2.625V40zm-5.75-2.025c1.25 0 2.525-.7 3.125-1.6v-4.8c-.6-.9-1.875-1.625-3.125-1.625-2.075 0-3.4 1.675-3.4 4.025 0 2.325 1.325 4 3.4 4z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32282" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
