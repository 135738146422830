<template>
    <form class="subscription" @submit.prevent="submitHandler">
        <z-caption tag="h2" size="3xl" v-html="isEdit ? text.manageTitle : text.title"/>
        <p v-if="!isEdit">
            <vue-raw :raw="text.description"/>
        </p>
        <z-input v-if="!isEdit" ref="email" v-model="params.email" name="email" type="email" :placeholder="text.inputPlaceholder" required :disabled="isLoading"/>
        <div class="subscription__categories row" v-if="categories.length">
            <template v-for="(item, index) in categories">
                <div class="col-default-3 col-tablet-6 col-mobile-12" :key="`category-${index}`">
                    <z-checkbox :class="{'subscription__error': categoryError}"
                                :value="params.selectedCategories"
                                :data="[{...item, value: item.id, disabled: isLoading}]"
                                v-model="params.selectedCategories"
                                name="category">
                        <template #text>
                            {{ item.name }}
                        </template>
                    </z-checkbox>
                </div>
            </template>
            <div class="col-default-3 col-tablet-6 col-mobile-12" key="category-all">
                <z-checkbox :class="{'subscription__error': categoryError}"
                            :data="[{value: 'all', disabled: isLoading}]"
                            v-model="checkedAll"
                            name="category" @change="checkedAllHandler">
                    <template #text>
                        {{ text.allRubrics }}                        
                    </template>
                </z-checkbox>
            </div>
            <p class="subscription__error-message" v-if="categoryError" v-html="errorMessage.categories"/>
        </div>
        <div class="u-bottom-margin--xl row" v-if="!isEdit">
            <div class="col-default-12">
                <z-checkbox :class="{'subscription__error': policyError}" :data="[{value: 'yes', disabled: isLoading}]"
                            v-model="params.policy" name="policy">
                    <template #text>
                        <p>
                            <vue-raw :raw="text.policy"/>
                        </p>
                    </template>
                </z-checkbox>
            </div>
            <p class="subscription__error-message" v-if="policyError" v-html="errorMessage.policy"/>
            <p class="subscription__error-message" v-if="responseMessage"><vue-raw :raw="responseMessage"/></p>
        </div>
        <div class="subscription__button-wrapper u-bottom-margin--m">
            <z-button size="l" type="submit" v-html="text.subscribe" :disabled="isLoading"/>
            <z-button v-if="isEdit" tag="a" :href="`/news-and-media/subscription/unsubscribe/?CONFIRM_CODE=${confirmCode}&ID=${id}`" class="u-left-margin--s" size="l" kind="text" v-html="text.unsubscribe"/>
        </div>
        <div>
            <z-link href="/news-and-media/press-releases-and-news/" size="l">
                <template v-slot:right>
                    <z-icon name="arrow-long" dir="right" width="24" height="24"></z-icon>
                </template>
                <span v-html="text.toNews"/>
            </z-link>
        </div>
    </form>
</template>

<script>
import {subscribe} from '@/api/subscribe'
import {subscribeEdit} from '@/api/subscribeEdit'
import {localize} from '@/utils/i18n'

export default {
    name: 'subscription',
    props: {
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isLoading: false,
            categoryError: false,
            policyError: false,
            responseMessage: '',
            checkedAll: [],
            categories: this.$root.app?.components['subscribe-rubrics'] || [],
            confirmCode: this.$root.app?.system?.params?.get['CONFIRM_CODE'],
            id: this.$root.app?.system?.params?.get['ID'],
            params: {
                email: '',
                selectedCategories: [],
                policy: []
            },
            text: {
                inputPlaceholder: localize({
                    ru: 'Введите свой email',
                    en: 'Enter your email'
                }),
                subscribe: localize({
                    ru: 'Подписаться',
                    en: 'Subscribe'
                }),
                unsubscribe: localize({
                    ru: 'Отписаться от&nbsp;рассылки',
                    en: 'Cancel subscription'
                }),
                toNews: localize({
                    ru: 'Перейти к&nbsp;новостям',
                    en: 'Go&nbsp;to&nbsp;news'
                }),
                policy: localize({
                    ru: 'Я&nbsp;ознакомлен (а) с&nbsp;<z-link href="/files/ru/corporate_documents/policies/Politika-v-oblasti-obrabotki-personalnih-dannih-2017.pdf" target="_blank">Политикой</z-link> в&nbsp;области обработки персональных данных и&nbsp;даю <z-link href="/files/ru/terms-of-usage.pdf" target="_blank">согласие</z-link> на&nbsp;обработку своих персональных данных.',
                    en: 'In&nbsp;accordance with the Federal Law of&nbsp;27.07.2006&nbsp;No. 152-FL by&nbsp;my&nbsp;free consent, at&nbsp;my&nbsp;own will, and in&nbsp;my&nbsp;interest&nbsp;I agree to&nbsp;the processing of&nbsp;my&nbsp;personal data, which is&nbsp;mentioned in&nbsp;feedback form on&nbsp;website <z-link href="https://www.nornickel.com/">www.nornickel.com</z-link>, by&nbsp;PJSC “MMC “Norilsk Nickel”.'
                }),
                description: localize({
                    ru: 'Если вы&nbsp;уже подписаны и&nbsp;хотите изменить настройки подписки&nbsp;&mdash; все нужное есть на&nbsp;странице <z-link href="/news-and-media/subscription/edit/">&laquo;Управление подпиской&raquo;.</z-link>',
                    en: 'If&nbsp;you are already subscribed and want to&nbsp;change your subscription settings, everything you need is&nbsp;there on&nbsp;the <z-link href="/news-and-media/subscription/edit/">Manage Subscription page.</z-link>'
                }),
                manageTitle: localize({
                    ru: 'Управление подпиской',
                    en: 'Subscription Management'
                }),
                title: localize({
                    ru: 'Свежие новости &laquo;Норникеля&raquo; с&nbsp;доставкой на&nbsp;электронную почту',
                    en: 'Latest Norilsk Nickel news delivered to&nbsp;your email'
                }),
                allRubrics: localize({
                    ru: 'Выбрать все',
                    en: 'All rubrics'
                })
            },
            errorMessage: {
                categories: localize({
                    ru: 'Пожалуйста, выберите хотя&nbsp;бы одну рубрику',
                    en: 'Please select at&nbsp;least one category'
                }),
                policy: localize({
                    ru: 'Без вашего согласия мы&nbsp;не&nbsp;можем сохранить ваш e-mail и&nbsp;подписать вас на&nbsp;рассылку.',
                    en: 'Without your consent, we&nbsp;cannot save your e-mail and subscribe you to&nbsp;the newsletter.'
                })
            }
        }
    },
    mounted () {
        this.checkSelectedCategories()
    },
    methods: {
        submitHandler () {
            this.policyError = false
            this.categoryError = false
            this.responseMessage = ''
            if (!this.isEdit) {
                this.$refs.email.validate()
                if (this.$refs.email.error) {
                    return
                }
                if (!this.params.policy.length) {
                    this.policyError = true
                    return
                }
            }
            if (!this.params.selectedCategories.length) {
                this.categoryError = true
                return
            }

            this.send()
        },
        send () {
            if (this.isEdit) {
                this.isLoading = true
                const formData = this.appendToFormData(new FormData(), {
                    ID: this.id,
                    'CONFIRM_CODE': this.confirmCode,
                    rubrics: this.params.selectedCategories
                })
                subscribeEdit(formData)
                    .then((response) => {
                        if (response.status === 'error') {
                            this.responseMessage = response.message
                        } else {
                            this.$emit('change-component', {
                                component: 'edited'
                            })
                        }
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            } else {
                this.isLoading = true
                const formData = this.appendToFormData(new FormData(), {
                    email: this.params.email,
                    rubrics: this.params.selectedCategories
                })
                subscribe(formData)
                    .then((response) => {
                        if (response.status === 'error') {
                            this.responseMessage = response.message
                        } else {
                            this.$emit('change-component', {
                                component: 'subscribe-message',
                                email: this.params.email
                            })
                        }
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        },
        checkSelectedCategories () {
            if (this.categories.length) {
                this.categories.slice().forEach((item) => {
                    if (item.selected) {
                        this.params.selectedCategories.push(item.id)
                    }
                })
            }
        },
        appendToFormData (formData, params) {
            for (let key in params) {
                if (Array.isArray(params[key])) {
                    params[key].forEach((item) => {
                        formData.append(`${key}[]`, item)
                    })
                } else {
                    formData.append(key, params[key])
                }
            }
            return formData
        },
        checkedAllHandler () {
            const result = []
            if (this.checkedAll.length) {
                this.categories.forEach((item) => {
                    result.push(item.id)
                })
            }
            this.params.selectedCategories = result
        }
    }
}
</script>

<style lang="scss">
.subscription {
    &__categories {
        margin-top: 0 !important;
        margin-bottom: $token-spacers-xl;
    }

    &__error {
        .z-checkbox__box {
            border-color: $token-colors-alert;
        }
    }

    &__error-message {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: $token-colors-alert-text;
        margin-top: $token-spacers-2-xs;
        padding-left: $token-spacers-2-xs;

        @include breakpoint(tablet) {
            padding-left: 12px;
        }

        a {
            color: $token-colors-alert-text;

            &:visited {
                color: $token-colors-alert-text;
            }

            &:focus,
            &:hover {
                text-decoration: none;
            }
        }
    }

    &__button-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>
