<template>
    <div class="news">
        <filters :data="filters" @change="onChangeFilters"/>

        <div class="u-top-padding--xl" ref="container">
            <results :data="items" />
            <z-preloader v-if="isLoading" />
        </div>
    </div>
</template>

<script>
import { throttle } from 'throttle-debounce'
import Filters from './components/Filters.vue'
import Results from './components/Results.vue'
import { getNews } from '@/api/news-clean-norilsk'

export default {
    name: 'news-clean-norilsk',
    components: {
        Filters,
        Results
    },
    data () {
        return {
            isLoading: false,
            params: {
                page: 1,
                year: null
            },
            items: this.$root.app.components['clean-norilsk-news']?.items,
            filters: this.$root.app.components['clean-norilsk-news']?.filters,
            nav: this.$root.app.components['clean-norilsk-news']?.nav
        }
    },
    methods: {
        onChangeFilters (params) {
            this.params = {...this.params, ...params, page: 1}

            this.submit()
        },
        submit () {
            this.isLoading = true

            getNews(this.params)
                .then(res => {
                    this.isLoading = false

                    if (this.params.page < 2) {
                        this.items = res.items
                    } else {
                        this.items = this.items.concat(res.items)
                    }
                    this.nav = res.nav
                })
                .catch(error => {
                    this.isLoading = false
                    console.log(error)
                })
        },
        listenScroll () {
            window.addEventListener('scroll', throttle(500, e => {
                if (!this.$refs.container) return
                const offsetTop = this.$refs.container.offsetTop
                const offsetHeight = this.$refs.container.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight
                if (
                    scrolled >= (bottom - 100) &&
                    !this.isLoading &&
                    this.nav.current < this.nav.total
                ) {
                    this.loadMore()
                }
            }))
        },
        loadMore () {
            if (!this.isLoading) {
                this.params.page++
                this.submit()
            }
        }
    },
    mounted () {
        this.listenScroll()
    }
}
</script>

<style lang="scss">
.news {
    @include margin-level (bottom, 2XL);
    .row {
        margin-top: -$token-spacers-3-xl;
    
        > [class*=col-] {
            margin-top: $token-spacers-3-xl;
        }
    }
}

</style>