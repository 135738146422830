/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'briefcase': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 7H4a2 2 0 00-2 2v10a2 2 0 002 2h16a2 2 0 002-2V9a2 2 0 00-2-2z" _stroke="#0077C8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M16 21V5a2 2 0 00-2-2h-4a2 2 0 00-2 2v16" _stroke="#0077C8" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
