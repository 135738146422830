<template>
    <div class="layout" :class="{'layout--without-aside': withoutAside}">
        <div v-if="!!this.$slots['top']" class="layout__top">
            <slot name="top" />
        </div>
        <div class="layout__content">
            <slot name="content" />
        </div>
        <aside class="layout__aside">
            <slot name="aside" />
        </aside>
    </div>
</template>

<script>
export default {
    name: 'layout',
    props: {
        withoutAside: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.layout {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-grow: 1;

    &__top,
    &__aside,
    &__content {
        width: 100%;
    }

    &__aside {
        max-width: calc(25% - 20px);
        flex-basis: calc(25% - 20px);
        @include margin-level(left, 2XL);

        @include breakpoint (tablet) {
            max-width: 100%;
            flex-basis: 100%;
            margin-left: 0;
        }
    }

    &__content {
        flex-grow: 1;
        max-width: calc(75% - 60px);
        flex-basis: calc(75% - 60px);

        @include breakpoint (tablet) {
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    &--without-aside {
        .layout {
            &__aside {
                display: none;
            }

            &__content {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }
}
</style>
