<template>
<div class="bonds-page">
    <div
        class="bonds-page__type"
        :class="`bonds-page__type--${index}`"
        v-for="(type, index) in data"
        :key="type.title"
    >

        <z-caption tag="h2" weight="600" decor-right>
            {{ type.title }}
        </z-caption>

        <z-tabs>
            <template v-slot:label>
                <z-tabs-label
                    v-for="bond in type.bonds"
                    :id="index + bond.id"
                    :key="index + bond.id"
                >
                    {{ bond.name }}
                </z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="bond in type.bonds"
                    :id="index + bond.id"
                    :key="index + bond.id"
                >
                    <bonds-page-list
                        :items="bond.items"
                        :isin-footnote="bond.isin_footnote"
                        :id-modal="index + bond.id"
                    ></bonds-page-list>

                    <div v-if="type.footnotes">
                        <p
                            class="bonds-page__paragraph"
                            v-for="footnote in type.footnotes"
                            :key="footnote"
                            v-html="footnote"
                        >
                        </p>
                    </div>

                    <div v-if="type.contacts">
                        <p
                            class="bonds-page__paragraph bonds-page__paragraph--link"
                            v-for="contact in type.contacts"
                            :key="contact.href"
                        >
                            {{ contact.description }}&nbsp;
                            <z-link :href="`mailto:${contact.href}`">{{ contact.href }}</z-link>
                        </p>
                    </div>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</div>
</template>

<script>
import { bondsData } from './bondsData'
import { bondsEnData } from './bondsEnData'
import BondsPageList from './components/BondsPageList.vue'
import { localize } from '@/utils/i18n'

export default {
    name: 'bonds-page',
    components: {
        BondsPageList
    },
    data () {
        return {
            lang: this.$root.app.page.lang,
            data: localize({
                ru: bondsData,
                en: bondsEnData
            }),
        }
    }
}
</script>

<style lang="scss">
.bonds-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $token-spacers-3-xl;

    @include breakpoint(v-tablet) {
        gap: $token-spacers-xl;
    }

    @include breakpoint(mobile) {
        gap: $token-spacers-m;
    }

    &__paragraph {
        color: $token-colors-gray-40;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: $token-spacers-xs;
        font-family: $robotoMono;

        sup {
            font-size: 0.7em;
            font-weight: 700;
        }

        &--link:first-of-type {
            margin-top: $token-spacers-xs;
            margin-bottom: 0;
        }
    }

    &__type--1 {
        .bonds-page-table {
            grid-template-columns: 30% 35% 0 30% 0;

            @include breakpoint(v-tablet) {
                grid-template-columns: 100%;
            }
        }
    }

    .z-tabs {
        .z-tabs-content {
            padding: $token-spacers-m 0 0;

            @include breakpoint(v-tablet) {
                padding: $token-spacers-2-xs 0 0;
            }
        }

        .z-tabs__header {
            gap: $token-spacers-s;
            border-bottom: none;

            .z-tabs-label {
                border-radius: 40px 40px 40px 0;

                @include breakpoint(v-tablet) {
                    padding: $token-spacers-2-xs;
                    font-size: 16px;
                }

                @include breakpoint(mobile) {
                    padding: $token-spacers-3-xs;
                }

                &:after {
                    display: none;
                }
            }

            @include breakpoint(v-tablet) {
                gap: $token-spacers-3-xs;
            }

            @include breakpoint(mobile) {
                gap: $token-spacers-2-xs;
                flex-direction: column;
            }
        }
    }
}
</style>
