/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element/Pt': {
    width: 72,
    height: 64,
    viewBox: '0 0 72 64',
    data: '<path pid="0" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="#004C97"/><path pid="1" d="M0 32C0 14.327 14.327 0 32 0h4c19.882 0 36 16.118 36 36v28H32C14.327 64 0 49.673 0 32z" _fill="url(#paint0_linear_9226_32277)"/><path pid="2" d="M29.166 40H26.24V23.325h7.325c3.5 0 5.45 2.4 5.45 5.225 0 2.825-1.975 5.225-5.45 5.225h-4.4V40zm4-8.8c1.65 0 2.85-1.05 2.85-2.65 0-1.6-1.2-2.65-2.85-2.65h-4v5.3h4zm11.71 9.1c-2.025 0-3.1-1.1-3.1-3.1v-6.975h-2v-2.3h2v-3.3H44.4v3.3h2.45v2.3H44.4v6.325c0 .825.4 1.425 1.15 1.425.5 0 .975-.2 1.175-.425l.625 1.975c-.475.45-1.25.775-2.475.775z" _fill="#fff"/><defs><linearGradient id="paint0_linear_9226_32277" x1="25.005" y1="0" x2="85.213" y2="45.231" gradientUnits="userSpaceOnUse"><stop stop-color="#0077C8"/><stop offset="1" stop-color="#0077C8" stop-opacity="0"/></linearGradient></defs>'
  }
})
