<template>
    <figure
        class="z-figure"
        :class="buildClass"
        @click="$emit('click')"
    >
        <component
            :is="link ? 'a' : 'div'"
            :href="link"
            class="z-figure__container"
            :class="{'z-figure__container--top-text': this.topText}"
            :target="link ? '_blank' : null"
        >
            <z-image
                v-if="image"
                class="z-figure__image"
                background
                :src="image"
            />
            <slot name="video" />
            <figcaption
                class="z-figure__caption"
                v-if="caption || $slots.caption"
            >
                <span v-if="caption" v-html="caption"></span>
                <div v-if="link && theme == 'blue'" class="z-figure__icon">
                    <z-icon
                        :name="iconName"
                        :dir="iconDir"
                        :width="iconWidth"
                        :height="iconHeight"
                        color="#fff"
                    ></z-icon>
                </div>
                <slot name="caption" />
            </figcaption>
        </component>
    </figure>
</template>

<script>
export default {
    name: 'z-figure',
    props: {
        topText: {
            type: Boolean
        },
        link: {
            type: String
        },
        image: {
            type: String
        },
        caption: {
            type: String
        },
        video: Boolean,
        theme: {
            type: String,
            default: 'default',
            validator: prop => ['default', 'simple', 'blue'].includes(prop)
        },
        size: {
            type: String,
            validator: prop => ['l', 'm'].includes(prop),
            default: 'm'
        },
        centered: Boolean,
        cornerRadius: {
            type: String,
            validator: prop => ['right', 'left'].includes(prop),
            default: 'right'
        },
        cornerRadiusOpposite: {
            type: Boolean,
            default: false
        },
        iconName: {
            type: String,
            default: 'arrow-long'
        },
        iconDir: {
            type: String,
            default: 'right'
        },
        iconWidth: {
            type: String,
            default: '22'
        },
        iconHeight: {
            type: String,
            default: '22'
        }
    },
    computed: {
        buildClass () {
            return [
                `z-figure--size-${this.size}`,
                `z-figure--theme-${this.theme}`,
                { 'z-figure--video': this.video },
                { 'z-figure--centered': this.centered },
                `z-figure--corner-radius-${this.cornerRadius}`,
                {
                    'z-figure--corner-radius-opposite': this.cornerRadiusOpposite
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
