<template>
    <div class="pseudo-slider-item" v-if="visible">
        <div class="pseudo-slider-item__header">
            <h2 class="pseudo-slider-item__title">
                <slot name="title"/>
            </h2>
        </div>
        <div class="pseudo-slider-item-card" v-if="$slots.main || $slots.aside">
            <div class="pseudo-slider-item-card__main" v-if="$slots.main">
                <slot name="main"/>
            </div>
            <div class="pseudo-slider-item-card__aside" v-if="$slots.aside">
                <slot name="aside"/>
            </div>
        </div>
        <div class="pseudo-slider-item__bottom" v-if="$slots.bottom">
            <slot name="bottom"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'pseudo-slider-item',
    inject: ['slider'],
    props: {
        currentId: {
            type: String | Number,
            required: true
        }
    },
    computed: {
        visible () {
            return this.currentId === this.slider.value
        }
    }
}
</script>

<style lang="scss">
.pseudo-slider-item {
    width: 100%;
    flex-shrink: 0;

    &__header {
        padding-left: 30%;
        padding-right: 20%;
        @include margin-level(bottom, 2XL);

        @include breakpoint(laptop) {
            padding-left: 25%;
            padding-right: 25%;
        }

        @include breakpoint(tablet) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 42px;
        line-height: 1.2;
        color: #eff6fb;
        background: linear-gradient(180deg, #FFFFFF 0%, #8BBBFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        @include breakpoint(tablet) {
            font-size: 32px;
        }

        @include breakpoint(mobile) {
            font-size: 24px;
        }
    }

    &__bottom {
        display: flex;

        .z-button {
            &:first-child {
                @include margin-level(right, L);

                @include breakpoint(mobile) {
                    margin-right: 0;

                    @include margin-level(bottom, M);
                }
            }
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }
}

.pseudo-slider-item-card {
    display: flex;
    padding-top: $token-spacers-2-xl;
    @include margin-level(bottom, L);

    @include breakpoint(laptop) {
        flex-wrap: wrap;
        padding-top: 0;
    }

    &__main {
        width: 100%;
    }

    &__aside {
        @include padding-level(top, L);
        padding-left: 80px;
        width: 40%;
        flex-shrink: 0;

        @include breakpoint(laptop) {
            display: flex;
            width: 100%;
            padding-left: 0;
        }

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        .z-key-indicator {
            @include margin-level(bottom, 2XL);

            @include breakpoint(laptop) {
                order: 1;
            }

            @include breakpoint(tablet) {
                order: 0;
            }
        }
    }

    &__icon {
        path {
            fill: $token-colors-blue;
        }
    }

    &__slider {
        @include breakpoint(laptop) {
            max-width: 40%;
            margin-right: $token-spacers-3-xl;
        }

        @include breakpoint(tablet) {
            max-width: 100%;
            margin-right: 0;
        }
    }
}
</style>
