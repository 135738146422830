import axios from 'axios'

export function getContacts () {
    return axios.get('/include/contacts/contacts.json')
        .then((response) => {
            return response.data
        })
        .catch(() => {
            console.log('Контакты отсутствуют')
            return null
        })
}
