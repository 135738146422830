<template>
  <div :class="['z-avatar', classes]">
    <picture
      v-if="photo"
    >
      <z-image
        :src="photo"
        :alt="name"
        :title="name"
      />
    </picture>
    <z-icon
        name="people-default"
        v-else
    />
  </div>
</template>
<script>
export default {
    name: 'z-avatar',
    auth: false,
    props: {
        size: {
            type: String,
            validator: prop => ['l', 'm', 's'].includes(prop),
            default: 'l'
        },
        photo: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes () {
            return [
                { 'z-avatar--disabled': this.disabled },
                `z-avatar--size-${this.size}`
            ]
        }
    }
}
</script>
<style lang="scss" src="./index.scss"></style>
