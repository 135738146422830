/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wifi': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_5706_48347)" _stroke="#0077C8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M5 12.55a11 11 0 0114.08 0M1.42 9a16 16 0 0121.16 0M8.53 16.11a6 6 0 016.95 0M12 20h.01"/></g><defs><clipPath id="clip0_5706_48347"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
