<template>
    <div class="z-site-selector">
        <z-dropdown>
            <template v-slot:trigger>
                <span
                    class="z-site-selector__active-site"
                    v-html="activeSite.lang"
                ></span>
                <z-icon
                    dir="down"
                    name="arrow"
                    width="16"
                    height="16"
                    color="#fff"
                />
            </template>
            <template v-slot:content>
                <ul>
                    <li
                        v-for="item in list"
                        v-if="!item.active"
                        :key="item.lid"
                    >
                        <a
                            :href="setUrl(item.url, item.lid, item.path)"
                            :title="item.title"
                            class="z-site-selector__site"
                            v-html="item.lang"
                        ></a>
                    </li>
                </ul>
            </template>
        </z-dropdown>
    </div>
</template>

<script>
export default {
    name: 'z-site-selector',
    data: function () {
        return {
            list: this.$root.app.components['site-selector']
        }
    },
    mounted () {
    },
    computed: {
        activeSite () {
            return this.list.filter(el => el.active)[0]
        }
    },
    methods: {
        setUrl (url, lid, path) {
            let overrideUrl = url

            if (this.$root.app.page.hasOwnProperty('override_links')) {
                const overrideLinks = this.$root.app.page.override_links

                overrideUrl = `${location.origin}${overrideLinks[lid]}`
            }

            return overrideUrl
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
