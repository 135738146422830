/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social-card': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_9435_2382)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.652 2.4h4.196c1.803 0 3.292 0 4.47.159 1.24.166 2.336.53 3.215 1.408.878.879 1.242 1.976 1.408 3.214.159 1.18.159 2.668.159 4.471v.197c0 1.803 0 3.291-.159 4.47-.166 1.24-.53 2.336-1.408 3.214-.879.879-1.975 1.242-3.214 1.409-1.18.158-2.668.158-4.47.158H9.651c-1.803 0-3.292 0-4.471-.158-1.239-.167-2.336-.53-3.214-1.409-.879-.878-1.242-1.975-1.409-3.214C.4 15.14.4 13.652.4 11.85v-.197c0-1.803 0-3.291.158-4.47.167-1.24.53-2.336 1.409-3.215.878-.878 1.975-1.242 3.214-1.408C6.36 2.4 7.849 2.4 9.65 2.4zm.041 1.2c-1.853 0-3.262.002-4.352.148-1.106.149-1.907.45-2.526 1.068-.618.618-.919 1.42-1.067 2.525-.147 1.09-.148 2.5-.148 4.353v.113c0 1.854.001 3.262.148 4.353.148 1.105.45 1.907 1.067 2.525.619.618 1.42.919 2.526 1.068 1.09.146 2.499.147 4.352.147h4.113c1.854 0 3.263 0 4.353-.147 1.106-.15 1.907-.45 2.525-1.068.619-.618.92-1.42 1.068-2.525.147-1.09.148-2.5.148-4.353v-.113c0-1.854-.001-3.263-.148-4.353-.149-1.106-.45-1.907-1.068-2.525-.618-.618-1.419-.92-2.525-1.068-1.09-.146-2.499-.148-4.353-.148H9.693zM13.15 8a.6.6 0 01.6-.6h5a.6.6 0 110 1.2h-5a.6.6 0 01-.6-.6zm1 3a.6.6 0 01.6-.6h4a.6.6 0 110 1.2h-4a.6.6 0 01-.6-.6zm1 3a.6.6 0 01.6-.6h3a.6.6 0 110 1.2h-3a.6.6 0 01-.6-.6zM5.234 8.667a2.767 2.767 0 115.533 0 2.767 2.767 0 01-5.533 0zM8 7.1a1.567 1.567 0 100 3.133A1.567 1.567 0 008 7.1zm-3.35 6.26c.863-.492 2.047-.793 3.35-.793s2.488.301 3.35.794c.862.492 1.417 1.187 1.417 1.972 0 .786-.555 1.48-1.417 1.973-.862.492-2.047.794-3.35.794s-2.487-.302-3.35-.794c-.861-.493-1.417-1.187-1.417-1.973 0-.785.556-1.48 1.418-1.972zm3.35.407c-1.133 0-2.104.264-2.754.636-.678.387-.813.757-.813.93 0 .174.135.543.813.93.65.372 1.621.637 2.754.637s2.104-.265 2.754-.636c.679-.388.813-.757.813-.93 0-.174-.134-.543-.813-.931-.65-.372-1.621-.636-2.754-.636z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9435_2382"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
