const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24
const MILLISECONDS_IN_HOUR = 1000 * 60 * 60
const MILLISECONDS_IN_MINUTE = 1000 * 60

class Timer {
    /**
     * @param {Date=} dateStart
     * */
    constructor (container, dateStart, lang = 'en') {
        if (!container) {
            return
        }
        this.container = container
        this.timerElement = this.container.querySelector('[data-timer]')
        if (!this.timerElement) {
            return
        }
        this.dateStart = dateStart
        this.lang = lang
        this.defineTextObject()
    }

    defineTextObject () {
        if (this.lang === 'ru') {
            this.textObject = {
                day: 'д',
                hour: 'ч',
                min: 'м'
            }
        } else {
            this.textObject = {
                day: 'd',
                hour: 'h',
                min: 'm'
            }
        }
    }

    startTicking () {
        this.handleTick()
        this.intervalId = setInterval(() => {
            this.handleTick()
        }, 1000)
    }

    handleTick () {
        if (!this.dateStart || !this.timerElement) {
            console.error('Start date or Timer Element are not defined', this.dateStart, this.timerElement)
            return
        }
        this.dateNow = new Date()
        const timeLeft = this.dateStart.getTime() - this.dateNow.getTime()
        if (timeLeft < 0) {
            this.handleTimeIsOver()
            return
        }

        const days = Math.floor(timeLeft / MILLISECONDS_IN_DAY)
        const hours = Math.floor((timeLeft - days * MILLISECONDS_IN_DAY) / MILLISECONDS_IN_HOUR)
        let minutes = Math.floor(
            (timeLeft - days * MILLISECONDS_IN_DAY - hours * MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE
        )

        if (minutes === 0) {
            minutes = 1
        }

        /** @param {number} num */
        function formatNumber (num) {
            return num < 10 ? `0${num}` : String(num)
        }

        this.timerElement.innerHTML = `${formatNumber(days)}${this.textObject.day}&nbsp;:&nbsp;${formatNumber(hours)}${this.textObject.hour}&nbsp;:&nbsp;${formatNumber(minutes)}${this.textObject.min}`
    }

    handleTimeIsOver () {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
        if (!this.timerElement) {
            return
        }

        // eslint-disable-next-line no-unused-expressions
        this.timeIsOverCallback?.()
    }

    /** @param {Function} callback */
    onTimeIsOver (callback) {
        this.timeIsOverCallback = callback
    }
}

export default Timer
