/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-tif': {
    width: 26,
    height: 22,
    viewBox: '0 0 26 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.955 16.713H6.45V6.039h3.816V4.707H1.157v1.332h3.798v10.674zm7.296 0h1.494V4.707h-1.494v12.006zm4.307 0h1.494v-5.472h6.246V9.909h-6.246v-3.87h6.372V4.707h-7.866v12.006z" _fill="#0077C8"/>'
  }
})
