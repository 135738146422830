/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-zip': {
    width: 27,
    height: 22,
    viewBox: '0 0 27 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.453 16.713h8.784v-1.332H2.361l6.75-9.432V4.707H.453v1.332h6.75l-6.75 9.432v1.242zm11.07 0h1.494V4.707h-1.494v12.006zm4.306 0h1.494v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618s-1.35-3.618-3.762-3.618H15.83v12.006zm7.038-8.388c0 1.35-.972 2.286-2.394 2.286h-3.15V6.039h3.15c1.422 0 2.394.936 2.394 2.286z" _fill="#0077C8"/>'
  }
})
