<template>
<div class="cmd container" v-if="data.length">
    <layout>
        <template v-slot:content>
            <content-container>
                <cmd-banner
                    :date="currentEvent.date"
                    :image="currentEvent.image"
                    class="u-bottom-margin--m"
                />

                <z-tabs @open-tab="onTabChange" size="m">
                    <template v-slot:label>
                        <z-tabs-label
                            v-for="(item, index) in data"
                            :key="index"
                            :id="String(item.year)"
                            @click="test(index)"
                        >
                            <span v-html="item.year"></span>
                        </z-tabs-label>
                    </template>
                    <template v-slot:content>
                        <z-tabs-content
                            v-for="(item, index) in data"
                            :key="index"
                            :id="String(item.year)"
                        >
                            <z-caption
                                decor-right
                                tag="h2"
                                size="3xl"
                                class="u-bottom-margin--s"
                            >
                                <span v-html="item.name"></span>
                            </z-caption>
                            <div
                                class="text-size-xl u-bottom-padding--m u-normal u-bottom-margin--m u-border-bottom"
                                v-html="item.description"
                                v-if="item.description"
                            ></div>
                            <date-block
                                class="u-bottom-margin--m"
                                :date="item.date"
                                theme="inline"
                                icon
                                v-if="item.date"
                            ><template v-if="item.location.short">, <span v-html="item.location.short"></span></template>
                            </date-block>
                            <future-event v-if="!item['is-past']" :data="item" />
                            <past-event v-else :data="item"/>
                        </z-tabs-content>
                    </template>
                </z-tabs>
            </content-container>
        </template>
        <template v-slot:aside>
            <materials
                v-if="!currentEvent['is-past']"
                class="u-bottom-margin--2xl hide-me--default show-me--tablet"
                :data="currentEvent"
            />
            <slot name="aside" />
            <materials
                v-if="!currentEvent['is-past']"
                class="u-bottom-margin--m hide-me--tablet"
                :data="currentEvent"
            />
            <div class="u-bottom-margin--xl" v-if="events && events.length">
                <z-caption
                    tag="h2"
                    decor-left
                    decor-right
                    size="m"
                    weight="700"
                    uppercase
                    href="/investors/events/"
                    class="u-bottom-margin--l"
                >
                    {{ text.eventsTitle }}
                </z-caption>

                <events-list
                    theme="aside"
                    :items="events"
                    :interactive="false"
                ></events-list>
            </div>
        </template>
    </layout>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'
import CmdBanner from './components/Banner.vue'
import FutureEvent from './components/FutureEvent.vue'
import PastEvent from './components/PastEvent.vue'
import Materials from './components/Materials.vue'

export default {
    name: 'cmd',
    components: {
        CmdBanner,
        FutureEvent,
        Materials,
        PastEvent
    },
    data () {
        return {
            text: {
                eventsTitle: localize({
                    ru: 'Календарь',
                    en: 'Calendar'
                }),
                allEvents: localize({
                    ru: 'Все события',
                    en: 'All events'
                })
            },
            data: [],
            events: [],
            currentEventIndex: 0
        }
    },
    computed: {
        currentEvent () {
            return this.data[this.currentEventIndex]
        }
    },
    created () {
        if (this.$root.app.components['strategy-day']) {
            this.data = this.$root.app.components['strategy-day'].items || []
        }

        let events = this.$root.app.components['strategy-day'].events
        if (events && events.length) {
            this.events = events.map((obj, i) => {
                return {
                    date: {
                        start: obj.dateFrom.timestamp,
                        end: obj.dateTo.timestamp
                    },
                    link: obj.detailLink.value,
                    name: obj.name
                }
            })
        }
    },
    methods: {
        onTabChange (tabId) {
            const id = tabId.replace('tabs-', '')
            const item = this.data.find(item => String(item.year) === String(id))
            const index = this.data.indexOf(item)
            if (index !== -1) this.currentEventIndex = index
        }
    }
}
</script>

<style lang="scss">
.cmd {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .z-tabs-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
        @include padding-level(top, XL, true);
    }
}
</style>
