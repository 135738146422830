<template>
    <div class="credit-rating">
        <div class="credit-rating__header" v-if="score || scoreSubtitle">
            <z-caption
                v-if="score"
                tag="span"
                class="credit-rating__title navy-blue-text u-collapse--all"
            >
                <span v-html="score"></span>
            </z-caption>
            <z-caption
                v-if="scoreSubtitle"
                tag="span"
                size="3xl"
                class="gray-20-text u-collapse--all"
            >
                <span v-html="scoreSubtitle"></span>
            </z-caption>
        </div>
        <div class="credit-rating__content">
            <div class="credit-rating__text" v-if="!!this.$slots['content']">
                <slot name="content" />
            </div>
            <div class="credit-rating__image" v-if="image">
                <z-tooltip v-if="tooltip" :content="tooltip" />
                <z-image :src="image" :alt="agencyName" />
            </div>
        </div>
        <div class="credit-rating__footer" v-if="details || !!this.$slots['footer']">
            <div class="credit-rating__text">
                <span v-if="details" v-html="details" />
                <slot v-if="!!this.$slots['footer']" name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'credit-rating',
    props: {
        score: String,
        scoreSubtitle: String,
        image: String,
        agencyName: String,
        details: String,
        tooltip: String
    }
}
</script>

<style lang="scss">
.credit-rating {
    width: 100%;

    &__header {
        display: flex;
        justify-content: center;
        align-items: baseline;
    }

    &__title {
        font-size: 48px !important;
    }

    &__image {
        position: relative;
        width: 100%;
        padding: 8px 32px;
        height: 80px;
        background: $token-colors-gray-10;
        border-radius: $boder-radius-l $boder-radius-l 0 $boder-radius-l;
        display: flex;
        align-items: center;
        justify-content: center;
        @include margin-level(top, XS);

        .z-tooltip {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;

            .z-tooltip__trigger {
                padding-right: 0;
                position: relative;
                min-height: 0;
            }
        }
    }

    &__footer {
        @include padding-level(top, XS);
    }

    &__text {
        text-align: center;
        @include typo-level(L);
        color: $token-colors-gray-35;
    }
}
</style>
