<template>
    <div :class="['result-book', {'result-book--horizontal' : horizontal}]">
        <div
            class="result-book__cover"
            :style="`background-image: url(${cover})`"
            v-if="cover"
        ></div>
        <div v-if="title || size" class="result-book__content">
            <a
                v-if="title"
                class="result-book__title"
                :href="href ? href : null"
                target="_blank"
                v-html="title"
                @click="isEmitEvent ? $emit('linkClick', $event) : null"
            ></a>
            <div v-if="date || size" class="result-book__info">
                <div
                    v-if="date"
                    class="result-book__date"
                    v-html="buildDate(date)"
                ></div>
                <div
                    v-if="size"
                    class="result-book__size"
                    v-html="size"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'result-book',
    props: {
        horizontal: {
            type: Boolean,
            default: false
        },
        title: String,
        size: String,
        date: [String, Number],
        href: String,
        cover: String,
        isEmitEvent: Boolean
    },
    methods: {
        buildDate (str) {
            return typeof str === 'number'
                ? this.$options.filters.momentFilter(str, 'DD.MM.YYYY')
                : str
        }
    }
}
</script>

<style lang="scss">
.result-book {
    $parent: &;
    display: flex;
    margin-right: $token-spacers-3-xs;

    @include breakpoint(v-tablet) {
        margin-right: 0;
    }

    > * + * {
        margin-left: 41px;

        @include breakpoint(tablet) {
            margin-left: $token-spacers-s;
        }

        @include breakpoint(v-tablet) {
            margin-left: $token-spacers-xs;
        }

        @include breakpoint(mobile) {
            margin-left: $token-spacers-3-xs;
        }
    }

    &__cover {
        width: 130px;
        height: 179px;
        background-size: cover;
        overflow: hidden;
        box-shadow: -4px 2px 8px -2px  rgba($token-colors-gray-40, 0.8);
        flex-shrink: 0;

        &:after {
            padding-top: 160%;
            content: '';
            width: 100%;
            background-image: url(/images/book-cover-2.png);
            background-repeat: no-repeat;
            background-size: 135%;
            background-position: 50% 50%;
            display: block;
        }
    }

    &--horizontal {
        @include breakpoint(mobile) {
            flex-direction: column;

            > * + * {
                margin-left: 0;
                margin-top: $token-spacers-3-xs;
            }
        }

        #{$parent}__cover {
            width: 179px;
            height: 130px;
            // background-size: 130%;
            // background-position: center;
        }
    }

    &__content > * + * {
        margin-top: $token-spacers-2-xs;

        @include breakpoint(v-tablet) {
            margin-top: $token-spacers-3-xs;
        }
    }

    &__info {
        display: flex;

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: $token-colors-gray-60;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: $token-colors-navy-blue;
        }
    }

    &__date,
    &__size {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $token-colors-gray-30;
        display: flex;
        align-items: center;
    }

    &__date::after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $token-colors-gray-30;
        margin: 0 $token-spacers-3-xs;
        flex-shrink: 0;
        display: inline-block;

        @include breakpoint(mobile) {
            display: none;
        }
    }
}
</style>
