<template>
<div class="datepicker-range">
    <z-caption
        tag="h2"
        decor-left
        decor-right
        size="m"
        weight="700"
        uppercase
        class="u-top-margin--m"
    >
        <span v-html="text.period"></span>
    </z-caption>

    <row-filter
        :filters="periodsData"
        @change="setPeriod"
        size="s"
        full-width
        class="datepicker-range__row-filter"
        ref="row-filter"
    >
    </row-filter>
    <div class="datepicker-range__period" v-if="isDatepickersShown">
        <z-date-picker
            @change="onPeriodChange"
            v-model="period.start"
            :clearable="false"
            :append-to-body="false"
        />
        <span></span>
        <z-date-picker
            @change="onPeriodChange"
            v-model="period.end"
            :clearable="false"
            :append-to-body="false"
        />
    </div>
    <z-button
        class="u-top-margin--s"
        kind="secondary"
        size="s"
        @click="clear"
        v-if="period.start || period.end"
    >
        <template #right>
            <z-icon name="close" width="16" height="16" />
        </template>
        <span class="u-bold" v-html="text.clearPeriod"></span>
    </z-button>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'
import moment from 'moment'

export default {
    name: 'datepicker-range',
    props: {
        name: {
            type: String,
            required: true
        },
        value: Object
    },
    data () {
        return {
            text: {
                period: localize({
                    ru: 'Выбрать даты',
                    en: 'Select'
                }),
                clearPeriod: localize({
                    ru: 'Сбросить период',
                    en: 'Reset period'
                })
            },
            isDatepickersShown: false,
            period: {
                start: null,
                end: null
            },
            periodsData: [
                {
                    disabled: false,
                    id: 'month',
                    selected: false,
                    text: localize({
                        ru: 'Месяц',
                        en: 'Month'
                    })
                },
                {
                    disabled: false,
                    id: '3-months',
                    selected: false,
                    text: localize({
                        ru: '3 месяца',
                        en: '3 months'
                    })
                },
                {
                    disabled: false,
                    id: 'year',
                    selected: false,
                    text: localize({
                        ru: 'Текущий год',
                        en: 'Current year'
                    })
                },
                {
                    disabled: false,
                    id: 'period',
                    selected: false,
                    text: localize({
                        ru: 'Период',
                        en: 'Period'
                    })
                }
            ]
        }
    },
    methods: {
        clear () {
            this.period = {
                start: '',
                end: ''
            }

            this.isDatepickersShown = false
            if (this.$refs['row-filter']) this.$refs['row-filter'].clear()
            this.$emit('change', {
                name: this.name,
                value: this.period
            })
        },
        onPeriodChange () {
            const current = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
            const monthStart = moment().subtract(1, 'months').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
            const threeMonthStart = moment().subtract(3, 'months').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
            const yearStart = moment().subtract(1, 'years').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)

            let start = this.period.start
            let end = this.period.end

            if (start) start = moment.unix(this.period.start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
            if (end) end = moment.unix(this.period.end).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)

            if (moment(end).isSame(moment(current)) && moment(start).isSame(moment(monthStart))) {
                this.periodsData = this.periodsData.map(item => {
                    if (item.id === 'month') return { ...item, selected: true }
                    return { ...item, selected: false }
                })
                this.isDatepickersShown = false
            } else if (moment(end).isSame(moment(current)) && moment(start).isSame(moment(threeMonthStart))) {
                this.periodsData = this.periodsData.map(item => {
                    if (item.id === '3-months') return { ...item, selected: true }
                    return { ...item, selected: false }
                })
                this.isDatepickersShown = false
            } else if (moment(end).isSame(moment(current)) && moment(start).isSame(moment(yearStart))) {
                this.periodsData = this.periodsData.map(item => {
                    if (item.id === 'year') return { ...item, selected: true }
                    return { ...item, selected: false }
                })
                this.isDatepickersShown = false
            } else if (start || end) {
                this.periodsData = this.periodsData.map(item => {
                    if (item.id === 'period') return { ...item, selected: true }
                    return { ...item, selected: false }
                })
                this.isDatepickersShown = true
            } else {
                if (this.$refs['row-filter']) this.$refs['row-filter'].clear()
                this.isDatepickersShown = false
            }

            this.$emit('change', {
                name: this.name,
                value: this.period
            })
        },
        setPeriod (data) {
            let start = ''
            let end = moment().format('X')

            switch (data) {
            case 'month':
                start = moment().subtract(1, 'months').format('X')
                this.isDatepickersShown = false
                break
            case '3-months':
                start = moment().subtract(3, 'months').format('X')
                this.isDatepickersShown = false
                break
            case 'year':
                start = moment().subtract(1, 'years').format('X')
                this.isDatepickersShown = false
                break
            case 'period':
                this.isDatepickersShown = true
                start = ''
                end = ''
                break
            default:
                this.isDatepickersShown = true
                start = ''
                end = ''
            }

            this.period = {
                start: start,
                end: end
            }

            this.$emit('change', {
                name: this.name,
                value: this.period
            })
        }
    },
    created () {
        if (this.value.start || this.value.end) {
            this.period = this.value
            this.onPeriodChange()
        }
    }
}
</script>

<style lang="scss">
.datepicker-range {
    &__period {
        margin-top: $token-spacers-xs;
        display: flex;
        align-items: center;
        position: relative;
        max-width: 500px;

        > span {
            width: 40px;
            position: relative;

            &:after {
                content: '';
                width: 16px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                height: 1px;
                background: $token-colors-gray-35;
            }
        }

        .z-date-picker-popup {
            width: 100%;
            top: calc(100% + 16px) !important;
            left: 0 !important;
        }

        .mx-calendar {
            width: 100%;
        }
    }
}
</style>
