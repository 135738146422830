/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'loader': {
    width: 100,
    height: 45,
    viewBox: '0 0 100 45',
    data: '<circle pid="0" cx="35" cy="22.5" r="2.5" _fill="#858DA6"/><circle pid="1" cx="50" cy="22.5" r="2.5" _fill="#858DA6"/><circle pid="2" cx="65" cy="22.5" r="2.5" _fill="#0290F0"/>'
  }
})
