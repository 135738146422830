/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leader': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<g clip-path="url(#clip0_3786_22200)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.707 20.14v23.527h2V19.764a4.664 4.664 0 001.55-1.143c.77-.858 1.234-1.98 1.234-3.023v-.75H23.707v-4.381c2.884-1.07 6.146-.906 8.862.655v2.276c3.034-1.138 6.296-.607 9.18 1.442l-.684-1.214-2.2-4.097 2.883-2.882c-2.883-2.049-6.145-2.58-9.18-1.442V2.853a10.561 10.561 0 00-8.861-.599V-.333h-2v15.18H8.293c-1.837 0-3.532.29-4.786 1.122-1.311.87-2.033 2.26-2.033 4.18v7.587c0 2.007 1.777 3.784 3.784 3.784v-1.5c-1.179 0-2.284-1.105-2.284-2.284v-7.587c0-1.494.53-2.379 1.362-2.93.889-.59 2.229-.871 3.957-.871h13.414v2.293H14.37v25.026h1.5V20.14h5.837zm2-2.116a3.476 3.476 0 001.162-1.677H23.707v1.677zM6.026 8.391c0-2.343 1.735-4.316 4.163-4.316 2.43 0 4.164 1.973 4.164 4.316v1.214c0 2.343-1.735 4.315-4.164 4.315-2.448 0-4.163-2.07-4.163-4.391V8.39zm4.163-2.816c-1.516 0-2.663 1.214-2.663 2.816v1.138c0 1.623 1.167 2.891 2.663 2.891 1.516 0 2.664-1.214 2.664-2.815V8.39c0-1.602-1.148-2.816-2.664-2.816zM5.267 43.667V20.149h1.5v23.518h-1.5zM9.82 30.012v13.655h1.5V30.012h-1.5z" _fill="#0077C8"/></g><defs><clipPath id="clip0_3786_22200"><path pid="1" _fill="#fff" d="M0 0h44v44H0z"/></clipPath></defs>'
  }
})
