<template>
<div class="cmd-banner" v-if="image">
    <z-image class="cmd-banner__img" :src="image" background />
    <date-block
        class="cmd-banner__date"
        :date="date"
        v-if="date"
    />
</div>
</template>

<script>
export default {
    name: 'cmd-banner',
    props: {
        image: String,
        date: [Number, String]
    }
}
</script>

<style lang="scss">
.cmd-banner {
    border-top-right-radius: $boder-radius-xl;
    overflow: hidden;
    position: relative;

    &__date {
        position: absolute;
        bottom: 0;
        left: 0;

        .date-block__container {
            background: $token-colors-white;
            border: none;
        }
    }

    &__img {
        padding-top: 36%;
        position: relative;
        background-color: $token-colors-blue-10;
    }
}
</style>
