/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'excursion': {
    width: 29,
    height: 29,
    viewBox: '0 0 29 29',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.173 1.348a.6.6 0 01.6-.6h11.525a.6.6 0 01.6.6v6.807a.6.6 0 01-.6.6H11.04l.652 2.694h16.291a.6.6 0 01.6.6v13.688a.6.6 0 01-.6.6h-9.997a.6.6 0 01-.6-.6V15.724H1.59v10.013a.6.6 0 11-1.2 0V12.05a.6.6 0 01.6-.6h6.393l.676-2.694H3.773a.6.6 0 01-.6-.6V1.348zm6.123 7.407l-.676 2.694h1.838l-.653-2.694h-.509zm-7.706 5.77h15.796v-1.876H1.59v1.875zm16.996-1.876v12.488h8.797V12.65h-8.797zM4.373 1.949v5.606h10.324V1.948H4.374zm15.604 13.175a.6.6 0 01.6-.6h4.86a.6.6 0 010 1.2h-4.86a.6.6 0 01-.6-.6zm0 3.77a.6.6 0 01.6-.6h4.86a.6.6 0 110 1.2h-4.86a.6.6 0 01-.6-.6zm0 3.755a.6.6 0 01.6-.6h4.86a.6.6 0 110 1.2h-4.86a.6.6 0 01-.6-.6z" _fill="#1A1B25"/>'
  }
})
