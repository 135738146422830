<template>
<div class="significant-facts" v-if="data">
    <slot name="title"/>
    <z-tabs size="m" @open-tab="onTabChange" v-if="data.tabs">
        <template v-slot:label>
            <z-tabs-label
                v-for="(item, index) in data.tabs"
                :key="index"
                :id="item.value"
            >
                {{ item.name }}
            </z-tabs-label>
        </template>
    </z-tabs>

    <div class="significant-facts__content u-top-padding--m">
        <template v-if="currentReports && currentReports.length">
            <z-filelist size="m">
                <z-filelist-item
                    v-for="(item, index) in currentReports"
                    icon-name="file/book"
                    :key="index"
                    :name="item.name"
                    :href="currentLang === 'ru' ? item.detailPageUrl : item.file.link"
                    :date="item.activeFrom || ''"
                    :target="currentLang === 'ru' ? '_self' : '_blank'"
                ></z-filelist-item>
            </z-filelist>
        </template>
        <z-preloader class="significant-facts__preloader" v-if="isLoading"/>
    </div>
</div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import getData from '@/api/significant-facts'

export default {
    name: 'significant-facts',
    mixins: [mixinDevice],
    data () {
        return {
            data: null,
            isLoading: false,
            currentReports: []
        }
    },
    computed: {
        isHorizontal () {
            return this.device !== 'mobile' && this.device !== 'v-tablet'
        },
        currentLang () {
            return this.$root.app.page.lang
        }
    },
    created () {
        if (this.$root.app.components['significant-facts']) {
            this.data = this.$root.app.components['significant-facts']
            this.currentReports = this.$root.app.components['significant-facts'].items || []
        }
    },
    methods: {
        onTabChange (tab) {
            const year = tab.replace('tabs-', '')
            this.isLoading = true

            getData({ year: year })
                .then(res => {
                    this.isLoading = false
                    this.currentReports = res.items
                })
                .catch(error => {
                    this.isLoading = false
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss">
.significant-facts {
    &__content {
        position: relative;
    }

    &__preloader {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($token-colors-white, 0.7);
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
