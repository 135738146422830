/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'development': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M48 65v11h-2V65h-2v-2h9c2.8 0 5-2.2 5-5V48h6v-.8l-2.706-5.863L33.9 37H32v-2h2.2l26.075 4.128L58 34.2V34c0-3.024-.68-5.898-1.896-8.474L34.1 29H32v-2h1.9l21.201-3.339C51.585 17.881 45.224 14 38.001 14c-4.8 0-9.4 1.7-13.1 4.9l-1.3-1.6c4-3.4 9.1-5.3 14.4-5.3a21.97 21.97 0 0119.256 11.322L72 21h2v2h-1.9l-13.918 2.198A21.963 21.963 0 0160 33.8l2.631 5.701L72.1 41H74v2h-2.2l-8.15-1.29L66 46.8V50h-6v8c0 3.9-3.1 7-7 7h-5zM28 37h2V26.6L24.4 21h-2.8L16 26.6V37h2v-9.6l4.4-4.4h1.2l3 3-2.6 2.6V37h2v-7.6l2-2V37zm-14 2h18v2h-2v2h2v2h-2v23h2v8h-2v-6H14v-2h14v-2H18v-2h10v-2H18v-2h10v-2H18v-2h10V45H14v-2h2v-2h-2v-2zm4 2v2h2v-2h-2zm10 2h-2v-2h2v2zm-4-2v2h-2v-2h2zm2 31h2v2h-2v-2zm-2 0h-2v2h2v-2zm-6 0h2v2h-2v-2z" _fill="#0077C8"/>'
  }
})
