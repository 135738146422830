/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'RocketLaunch': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g clip-path="url(#clip0_9129_11548)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M26.108 4.02c-1.732-.103-5.638.095-8.926 3.384L16.586 8H9.292a2 2 0 00-1.413.585L3.585 12.88a2 2 0 001.132 3.393h.003l4.81.672 5.526 5.526.671 4.808v.004a2 2 0 003.393 1.132l4.295-4.295A2 2 0 0024 22.707v-7.293l.596-.596c3.286-3.286 3.487-7.194 3.385-8.926v-.005a2 2 0 00-1.868-1.868h-.005zM22 17.413l-4.94 4.94.647 4.644L22 22.706v-5.292zM9.293 10h5.293l-4.941 4.94L5 14.294 9.293 10zm9.303-1.182c2.713-2.713 5.934-2.887 7.389-2.803.084 1.454-.093 4.678-2.803 7.389L16 20.586 11.414 16l7.182-7.182zm-9.344 12.15a1 1 0 00-.495-1.938L9 20l-.244-.97h-.001l-.004.001-.007.002-.02.005a2.543 2.543 0 00-.232.075c-.143.051-.337.129-.564.242a6.325 6.325 0 00-1.635 1.188C5.093 21.743 4 23.753 4 27a1 1 0 001 1c3.247 0 5.257-1.093 6.457-2.293a6.327 6.327 0 001.187-1.635 5.127 5.127 0 00.301-.74 2.58 2.58 0 00.016-.056l.006-.02.002-.008v-.004c.001 0 .001-.002-.969-.244l.97.242a1 1 0 00-1.938-.494l-.002.01-.026.077a3.155 3.155 0 01-.148.343 4.327 4.327 0 01-.813 1.115c-.692.692-1.9 1.464-3.996 1.66.196-2.095.968-3.304 1.66-3.996a4.326 4.326 0 011.115-.813 3.13 3.13 0 01.42-.174l.01-.002z" _fill="#fff"/></g><defs><clipPath id="clip0_9129_11548"><path pid="1" _fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs>'
  }
})
