/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_element_tourism': {
    width: 38,
    height: 38,
    viewBox: '0 0 38 38',
    data: '<rect pid="0" width="38" height="38" rx="19" _fill="#0077C8"/><path pid="1" d="M16.75 15.25v9a.75.75 0 11-1.5 0v-9a.75.75 0 111.5 0zM19 14.5a.75.75 0 00-.75.75v9a.75.75 0 101.5 0v-9a.75.75 0 00-.75-.75zm3 0a.75.75 0 00-.75.75v9a.75.75 0 101.5 0v-9a.75.75 0 00-.75-.75zm4.5-1.5v13.5A1.5 1.5 0 0125 28h-1.5v1.5a.75.75 0 11-1.5 0V28h-6v1.5a.75.75 0 11-1.5 0V28H13a1.5 1.5 0 01-1.5-1.5V13a1.5 1.5 0 011.5-1.5h2.25V9.25A2.25 2.25 0 0117.5 7h3a2.25 2.25 0 012.25 2.25v2.25H25a1.5 1.5 0 011.5 1.5zm-9.75-1.5h4.5V9.25a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v2.25zm8.25 15V13H13v13.5h12z" _fill="#fff"/>'
  }
})
