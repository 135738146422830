/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ical': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.284 5.722L2 5.947 4.976 22.97 22 19.995 19.024 2.97l-1.279.223 2.768 15.837-14.46 2.528-2.77-15.837zm8.957 4.63l.158.892 3.707-.641c-.38.613-.706 1.347-.985 2.202-.288.845-.464 1.71-.539 2.592-.074.883-.065 1.617.028 2.212l.948-.168a9.684 9.684 0 01-.01-2.137c.094-.947.307-1.877.632-2.806.326-.929.679-1.69 1.069-2.285l-.13-.716-4.878.855zm-2.064 8.026l.93-.157-1.32-7.563-.595.102c-.111.353-.325.734-.65 1.152-.325.418-.725.79-1.198 1.124l.157.892c.27-.158.567-.372.892-.65a4.94 4.94 0 00.762-.79l1.022 5.89z" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M1.818 4.743l17-2.972.943 5.4-16.999 2.972-.944-5.4zm11.157 2.448l-.511-2.936.39-.065.455 2.592 1.45-.25.065.343-1.85.316zm-1.143-2.825l-.39.065.297 1.691c.065.372.047.632-.056.78-.102.15-.288.251-.576.298a.853.853 0 01-.436-.028.5.5 0 01-.298-.232c-.065-.112-.12-.298-.167-.548L9.91 4.7l-.39.065.297 1.7c.056.306.14.548.241.715.103.167.251.279.446.344.186.065.418.074.697.028.288-.047.502-.14.66-.279a.85.85 0 00.288-.483 1.939 1.939 0 00-.019-.725l-.297-1.7zM7.976 7.155l-.344.111c.047.297.149.502.307.632.167.13.371.167.631.12a.933.933 0 00.437-.185.665.665 0 00.232-.363c.038-.139.038-.343-.009-.585l-.353-1.997-.39.065.353 2.025c.037.167.037.288.028.372a.323.323 0 01-.112.204.487.487 0 01-.241.112c-.13.018-.242 0-.335-.075-.083-.065-.158-.213-.204-.436z" _fill="#0077C8"/>'
  }
})
